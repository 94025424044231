import { ApplicationWrapper, EnvelopesWrapper, Module, Page, WithSession } from '@coinscrap/webapp-core';
import routes from 'config/config/routes';
import { startUpController } from 'helpers/startUpController';
import Animations from 'layout/Animations/Animations';
import { MainLayout } from 'layout/MainLayout';
import React, { Component } from 'react';
import { LoginClient } from 'views/00_AccessPoint/LoginClient';
import { Pbc } from 'views/05_Questionnaires/Pbc';
import { BankAccount } from 'views/06_BankAccount/BankAccount';
import { IdentityBiometricUpsert } from 'views/08_IdentityBiometricUpsert/IdentityBiometricUpsert';
import { IdentityBiometric } from 'views/09_IdentityBiometric/IdentityBiometric';
import { ActivateProduct } from 'views/10_ActivateProduct/ActivateProduct';
import { SignatureSteps } from 'views/11_SignatureSteps/SignatureSteps';
import { SignatureRead } from 'views/12_SignatureRead/SignatureRead';
import { SignatureContract } from 'views/13_SignatureContract/SignatureContract';
import { ProductCreationFinished } from 'views/14_ProductCreationFinished/ProductCreationFinished';
import { CannotConfirm } from 'views/_Common/CannotConfirm/CannotConfirm';
import { Continue } from 'views/_Handlers/00_AccessPoint/Continue';
import { LoadTarget } from 'views/_Handlers/01_LoadTarget/LoadTarget';
import WrappersComponent from 'wrappers/WrappersComponent';

import { SelectPlan } from './views/01_SelectPlan/SelectPlan';
import { MakeContribution } from './views/02_MakeContribution/MakeContribution';
import { BringPlan } from './views/03_BringPlan/BringPlan';
import { Beneficiaries } from './views/04_Beneficiaries/Beneficiaries';
import { IdentityBiometricSteps } from './views/07_IdentityBiometricSteps/IdentityBiometricSteps';



require('intersection-observer');

//APP INIT
ApplicationWrapper.init({
	projectName: 'caser',
	contextTheme: 'light',
	disableNative: true,
	onStartupParams: () => (startUpController.params = {})
});

export const MockContext = React.createContext();
export default class App extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<MockContext.Provider
				value={
					(window?.__CLIENT_ENV?.CLIENT_ENV_MOCK === 'true' || false) &&
					process.env.NODE_ENV === 'development'
				}
			>
				<WrappersComponent>
					{/*
            <FLUJOS CLIENTE>
                  B2B:
                    → /continue → /loadTarget → /simulation → ...
                  B2C:
                    → /caser (client) → /loginClient → /upsertProduct → /selectPlan → /simulation → ...
          */}

					{/*B2B CONTINUE LINK*/}
					<Page
						name={routes.continue}
						pushAnimation={Animations.push}
						backAnimation={Animations.back}
						component={Continue}
					/>

					{/* CLIENTE */}
					<Module path="/caser" defaultRoute={routes.loginClient}>
						<MainLayout>
							<Page
								name={routes.loginClient}
								component={LoginClient}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Page
								path={'/loadTarget'}
								name={routes.loadTarget}
								component={LoadTarget}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Module path="/internal" defaultRoute={routes.selectPlan}>
								<WithSession pageNoSession={routes.loginClient}>
									<Module path="/target/:targetId">
										<Page
											path={'/selectPlan'}
											name={routes.selectPlan}
											component={SelectPlan}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Page
											path={'/makeContribution'}
											name={routes.makeContribution}
											component={MakeContribution}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Page
											path={'/bringPlan'}
											name={routes.bringPlan}
											component={BringPlan}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Page
											path={'/beneficiaries'}
											name={routes.beneficiaries}
											component={Beneficiaries}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module path={'/questionnaires'} defaultRoute={routes.pbc}>
											<Page
												path={'/pbc'}
												name={routes.pbc}
												component={Pbc}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
										</Module>
										<Page
											path={'/bankAccount'}
											name={routes.bankAccount}
											component={BankAccount}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module path={'/identityDocuments'} defaultRoute={routes.identityBiometricSteps}>
											<Page
												path={'/identityBiometricSteps'}
												name={routes.identityBiometricSteps}
												component={IdentityBiometricSteps}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/identityBiometricUpsert'}
												name={routes.identityBiometricUpsert}
												component={IdentityBiometricUpsert}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/identityBiometric'}
												name={routes.identityBiometric}
												component={IdentityBiometric}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
										</Module>
										<Page
											path={'/activateProduct'}
											name={routes.activateProduct}
											component={ActivateProduct}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module path={'/sign/:envelopeId'} defaultRoute={routes.signatureSteps}>
											<EnvelopesWrapper>
												<Page
													path={'/signatureSteps'}
													name={routes.signatureSteps}
													component={SignatureSteps}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/signatureRead'}
													name={routes.signatureRead}
													component={SignatureRead}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/signatureContract'}
													name={routes.signatureContract}
													component={SignatureContract}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/productCreationFinished'}
													name={routes.productCreationFinished}
													component={ProductCreationFinished}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
											</EnvelopesWrapper>
										</Module>
									</Module>
									<Page
										path={'/cannotConfirm'}
										name={routes.cannotConfirm}
										component={CannotConfirm}
										pushAnimation={Animations.push}
										backAnimation={Animations.back}
									/>
								</WithSession>
							</Module>
						</MainLayout>
					</Module>
				</WrappersComponent>
			</MockContext.Provider >
		);
	}
}
