import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('input', {
	styles: {
		default: {
			border: 'none',
			color: CONFIG.colors.grey,
			height: '100%',
			width: 'auto',

			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.md,
			textAlign: 'center',
		},

		...CONFIG.commonStyles.styles,
	},
});
