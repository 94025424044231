import React from 'react';

import { CONFIG } from 'config/config';

import styled from 'styled-components/macro';

const OptionBtn = styled.button`
	cursor: pointer;
	font-size: ${CONFIG.fontSizes.md};
	font-family: ${(props) => {
		if (props.selected) {
			return CONFIG.fonts.semiBold;
		} else {
			return CONFIG.fonts.regular;
		}
	}};
	background-color: ${(props) => {
		if (props.selected) {
			return CONFIG.colors.primary;
		} else {
			if (props.sugested) {
				return CONFIG.colors.primaryLighter;
			}
			return CONFIG.colors.white;
		}
	}};
	color: ${(props) => {
		if (props.selected) {
			return CONFIG.colors.white;
		} else {
			return CONFIG.colors.black;
		}
	}};
	border: ${(props) => {
		if (!props.selected) {
			return `1px solid ${CONFIG.colors.black}`;
		} else {
			return `1px solid ${CONFIG.colors.primaryDark}`;
		}
	}};
	border-radius: ${(props) => {
		if (!props.pill) {
			return `3px`;
		} else {
			return '50px';
		}
	}};
	width: ${(props) => {
		if (!props.width) {
			return '170px';
		} else {
			return ` ${props.width}`;
		}
	}};
	min-width: 75px;
	padding: ${(props) => {
		if (!props.pill) {
			return `12px 12px`;
		} else {
			return '8px 8px';
		}
	}};
	text-align: center;
	outline: none;
	margin-right: ${(props) => {
		if (!props.pill) {
			return `20px`;
		} else {
			return '0px';
		}
	}};
	margin-left: ${(props) => {
		if (!props.pill) {
			return `0px`;
		} else {
			return '10px';
		}
	}};

	:focus {
		outline: none;
	}

	:hover {
		opacity: 0.7;
	}

	:disabled {
		opacity: 0.5;
		cursor: default;
	}

	transition: 0.6s all;
`;

export const OptionButton = ({ style, onClick, selected, sugested, text, width, pill = false }) => {
	return (
		<OptionBtn
			style={style}
			onClick={onClick}
			selected={selected}
			sugested={sugested}
			width={width || style?.width || '142px'}
			pill={pill}
		>
			{text}
		</OptionBtn>
	);
};
