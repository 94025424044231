import { Button, Text, UiWrapper, View } from '@coinscrap/webapp-core';
import Modal from '@material-ui/core/Modal';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import arrowRigth from 'assets/images/svgStatic/arrowRigth.svg';
import CloseButton from 'components/CloseButton/CloseButton';
import { CONFIG } from 'config/config';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

// components
// configs
const BeneficiariesInfoModal = ({ isOpen, onClose, children, onClick }) => {
	const faqRef = useRef();
	const { useBackground } = UiWrapper.use();
	useBackground(CONFIG.colors.white);
	const [isScroll, setIsScroll] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	const { t } = useTranslation();

	const observer = useRef();

	const lastPlanElementRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[entries.length - 1].isIntersecting) {
					setButtonDisabled(false);
					setIsScroll(false);
				} else {
					setIsScroll(true);
				}
			});
			if (node) observer.current.observe(node);
		},
		[observer]
	);

	return (
		<Modal open={isOpen} onClose={onClose}>
			<View
				style={{
					backgroundColor: CONFIG.colors.white,
					overflowY: 'auto'
				}}
			>
				<View style={{ justifyContent: 'flex-start', minHeight: '100vh' }}>
					<View justifyStartSt>
						<View rowSt justifyEndSt style={{ marginTop: 10 }}>
							<CloseButton
								color={CONFIG.colors.primary}
								action={() => {
									onClose();
								}}
							/>
						</View>

						<View
							justifyStartSt
							style={{
								overflowY: 'scroll',
								height: 'calc(100% - 150px)',
								maxWidth: 1000,
								padding: 10,
								width: '90%'
							}}
						>
							<Text headingPrimarySt style={{ color: CONFIG.colors.primary }}>
								{t('beneficiariesInfoModal.title')}
							</Text>
							<Text headingTertiarySt>{t('beneficiariesInfoModal.description')}</Text>
							<S.StepWrapper>
								<S.StepWrapperItem>
									<S.StepCircle>
										<img src={arrowRigth} alt={'arrow icon'} />
									</S.StepCircle>
									<Text stepsSt>{t('beneficiariesInfoModal.feature1')}</Text>
								</S.StepWrapperItem>
								<S.StepWrapperItem>
									<S.StepCircle>
										<img src={arrowRigth} alt={'arrow icon'} />
									</S.StepCircle>
									<Text stepsSt>{t('beneficiariesInfoModal.feature2')}</Text>
								</S.StepWrapperItem>
								<S.StepWrapperItem>
									<S.StepCircle>
										<img src={arrowRigth} alt={'arrow icon'} />
									</S.StepCircle>
									<Text stepsSt>{t('beneficiariesInfoModal.feature3')}</Text>
								</S.StepWrapperItem>
								<S.StepWrapperItem>
									<S.StepCircle>
										<img src={arrowRigth} alt={'arrow icon'} />
									</S.StepCircle>
									<Text stepsSt>{t('beneficiariesInfoModal.feature4')}</Text>
								</S.StepWrapperItem>
							</S.StepWrapper>
							<View ref={lastPlanElementRef} style={{ height: 10 }}>
								<View ref={faqRef} />
							</View>
						</View>

						<ArrowForwardIosIcon
							onClick={() => {
								faqRef.current.scrollIntoView({
									behavior: 'smooth'
								});
							}}
							style={{
								color: CONFIG.colors.primary,
								position: 'fixed',
								bottom: 15,
								right: 15,
								width: 20,
								height: 'auto',
								transform: 'rotate(90deg)',
								opacity: isScroll ? 1 : 0,
								cursor: 'pointer'
							}}
						/>
					</View>
					<Button
						disabled={!!buttonDisabled}
						onClick={() => {
							onClose();
						}}
					>
						Cerrar
					</Button>
				</View>
			</View>
		</Modal>
	);
};

export default BeneficiariesInfoModal;
