import {
  EnvelopesWrapper,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';
import { DocuSign } from 'components/DocuSign/DocuSign';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { useGetError } from 'hooks/useGetError';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Footer from '../../layout/components/Footer/Footer';
import Header from '../../layout/components/Header/Header';

// eslint-disable-next-line react/prop-types
export const SignatureContract = PickerHOC({
	target: {
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id'
		},
		sourcePath: 'targets',
		sourceWrapper: TargetsWrapper
	},
	envelope: {
		sourcePath: 'envelopes',
		sourceWrapper: EnvelopesWrapper,
		arrayItemMatcher: {
			type: 'param',
			paramName: 'envelopeId',
			itemPropertyPath: 'id'
		}
	},
	user: {
		sourceWrapper: UserWrapper,
		sourcePath: 'user'
	}
})(() => {
	// CORE WRAPPERS
	const { refresh, getSignUrl } = EnvelopesWrapper.use();
	const [docuSignUrl, setDocuSignUrl] = useState(null);
	const { navigateTo } = NavigationWrapper.use();
	const { loadTargets } = TargetsWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { target, envelope, user } = PickerHOC.use();
	const { getError } = useGetError();

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;

	// ESTADO
	const [loading, setLoading] = useState();
	const appStep = useMemo(() => 13, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);
	const [finished, setFinished] = useState(false);

	// NAVIGATION
	const next = async () => {
		console.log('envelope?.signStatus', envelope?.signStatus);
		console.log('target.status', target.status);
		envelope?.signStatus === 'SIGNED' && target.status === 'ACTIVE'
			? CONFIG.routing.signatureContract.next(navigateTo)
			: CONFIG.routing.signatureContract.ko(navigateTo);
	};

	useEffect(() => {
		if (finished) {
			next();
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished]);

	useEffect(() => {
		console.log('🧿 envelope', envelope);
		const signUrl = getSignUrl(envelope.id);
		setDocuSignUrl(signUrl);
		console.log('🧿 SIGN URL', signUrl);
		return () => {};
	}, [getSignUrl, target.id, envelope]);

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(!docuSignUrl || loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		console.log('📌 contributionData', contributionData);
		console.log('📌 envelope', envelope);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return docuSignUrl ? (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={100} title={sectionTitle} subtitle={sectionSubTitle}>
				<View innerWrapperSt>
					{docuSignUrl && (
						<DocuSign
							url={docuSignUrl}
							onProcessFinished={async () => {
								setLoading('Actualizando Datos');
								try {
									await refresh();
								} catch (e) {
									setLoading(undefined);
									openModal(() => <MainModal type="error" text={getError(e)} />, {
										disableClickAway: true,
										closeButton: true,
										modalClass: isMobile ? 'modal-mobile' : 'modal-main',
										key: 'modal-SignatureContract-KO'
									});
								}
								try {
									await loadTargets([target.id]);
								} catch (e) {
									console.log('❌ ERROR', e);
								}
								setLoading(undefined);
								setFinished(true);
							}}
						/>
					)}
				</View>
			</Wrapper>
			<Footer />
		</>
	) : null;
});
