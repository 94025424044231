import { CONFIG } from "config/config";

import { UiWrapper } from "@coinscrap/webapp-core";

UiWrapper.defineComponent("inputDate", {
	styles: {
		default: {
			containerComponent: {
				border: CONFIG.inputs.border.default,
				borderRadius: CONFIG.inputs.border.radius,
				padding: CONFIG.inputs.padding.date,
				maxWidth: CONFIG.inputs.maxWidth.default,
				background: CONFIG.colors.white,
			},

			inputContainerComponent: {
				border: "none",
				boxSizing: "border-box",
				background: CONFIG.colors.white,
			},

			inputComponent: {
				boxSizing: "border-box",
				paddingRight: 0,
				color: CONFIG.colors.black,
				fontFamily: CONFIG.fonts.regular,
				fontSize: CONFIG.fontSizes.md,
				paddingTop: 14,
				paddingBottom: 14,
				background: CONFIG.colors.white,
				height: 20,
			},

			iconContainerComponent: {
				padding: 0,
				height: 48,
				width: 26,
				background: CONFIG.colors.white,
			},

			dayComponent: {
				color: CONFIG.colors.grey,
				fontFamily: CONFIG.fonts.regular,
			},

			daySelectedComponent: {
				backgroundColor: CONFIG.colors.primary,
				color: CONFIG.colors.white,
			},
			actionTextComponent: {
				// display: 'none',
				fontFamily: CONFIG.fonts.regular,
			},
		},
		filledSt: {
			containerComponent: {
				border: CONFIG.inputs.border.filled,
				borderRadius: CONFIG.inputs.border.radius,
				padding: CONFIG.inputs.padding.date,
				maxWidth: CONFIG.inputs.maxWidth.default,
				background: CONFIG.colors.white,
				borderColor: CONFIG.colors.primary
			},
		},

		fullWidthSt: {
			containerComponent: {
				width: "100%",
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
