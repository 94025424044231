import React from 'react';

import { CONFIG } from 'config/config';

import * as S from './styles';

const RoundedProgressBar = ({ showLabel, step, styleWrapper = {} }) => {
	const total = Object.keys(CONFIG.project.steps.stepTitles).length;
	return (
		<S.RoundedProgressBarWrapper style={styleWrapper}>
			{showLabel && (
				<S.RoundedProgressBarLabel>{`${step}. ${
					CONFIG.project.steps.stepTitles[step - 1]
				}`}</S.RoundedProgressBarLabel>
			)}

			<S.RoundedProgressBar>
				{[...new Array(step)].map((item, index, list) => {
					return (
						<S.RoundedProgressBarItemActive
							key={`pbis-${index}`}
							index={index}
							length={list.length}
						>
							<S.RoundedProgressBarItemDot activeStep={step.toString()} />
						</S.RoundedProgressBarItemActive>
					);
				})}

				{[...new Array(total - step)].map((item, index, list) => {
					return (
						<S.RoundedProgressBarItem key={`pbi-${index}`}>
							<S.RoundedProgressBarItemDot />
						</S.RoundedProgressBarItem>
					);
				})}
			</S.RoundedProgressBar>
		</S.RoundedProgressBarWrapper>
	);
};

export default RoundedProgressBar;
