import { ProgressBar } from 'components/ProgressBar/ProgressBar/ProgressBar';
import { SectionSubTitle } from 'components/SectionSubTitle/SectionSubTitle';
import { SectionTitle } from 'components/SectionTitle/SectionTitle';
import { CONFIG } from 'config/config';
import React from 'react';

import * as S from './styles';

export const Wrapper = ({
	children,
	width = CONFIG.components.mainWrapper.width,
	style,
	showProgressBar,
	progressBarVariant = CONFIG.components.progressBar.variant,
	iconInternalStep,
	step,
	progressPercent,
	title,
	subtitle,
	tooltip,
	grow = false,
	isAgent
}) => {
	return (
		<S.Wrapper wrapperWidth={width} style={style} isAgentView={isAgent}>
			{showProgressBar && (
				<ProgressBar
					step={step}
					progressPercent={progressPercent}
					progressBarVariant={progressBarVariant}
					iconInternalStep={iconInternalStep}
					grow={grow}
				/>
			)}
			{title && <SectionTitle title={title} tooltip={tooltip} grow={grow} />}
			{subtitle && <SectionSubTitle subtitle={subtitle} tooltip={tooltip} />}
			{children}
		</S.Wrapper>
	);
};
