import { CONFIG } from 'config/config';

const { UiWrapper } = require('@coinscrap/webapp-core');

UiWrapper.defineComponent('image', {
	styles: {
		default: {},

		...CONFIG.commonStyles.styles,
	},
});
