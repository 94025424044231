import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('textField', {
	styles: {
		default: {
			color: CONFIG.colors.black,
			containerComponent: {
				width: '80%',
				backgroundColor: CONFIG.colors.grey,
			},
			inputContainerComponent: {
				borderRadius: '30px',
				paddingLeft: 0,
				backgroundColor: 'rgb(100, 100, 100, 0.35)',
				border: '1px solid ' + CONFIG.colors.primary,
				fontFamily: CONFIG.fonts.regular,
			},
			inputComponent: {
				textAlign: 'center',
				color: CONFIG.colors.black,
			},
			labelComponent: {
				fontSize: 16,
				fontWeight: 400,
				fontFamily: CONFIG.fonts.regular,
				textAlign: 'center',
				width: '90%',
				color: CONFIG.colors.grey,
			},
		},

		centerSt: {
			inputComponent: {
				textAlign: 'center',
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
