import {
	AnalyticsWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Footer from 'layout/components/Footer/Footer';
import Header from 'layout/components/Header/Header';
import moment from 'moment/moment';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import BackNextButton from '../../components/BackNextButton/BackNextButton';
import BeneficiaryBoxBank from '../../components/BeneficiaryBox/BeneficiaryBoxBank';
import { BenefeciaryBank } from '../../components/BeneficiaryModal/BeneficiaryBank';
import { useGetError } from '../../hooks/useGetError';
import { useInitialScrollToTop } from '../../hooks/useInitialScrollToTop';
import { BeneficiaryBankSelector } from './components/BeneficiaryBankSelector';
import { BeneficiaryList } from './components/BeneficiaryList';
import { BeneficiarySelector } from './components/BeneficiarySelector';

export const Beneficiaries = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { emitEvent } = AnalyticsWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { user } = UserWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { target } = PickerHOC.use();
	const { getError } = useGetError();

	const convertDateToBasicFormat = (date) => {
		//converts a DD-MM-YYYY to YYYY-MM-DD.
		let dateArray = date.split('-');
		return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
	};

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);

	// ESTADO
	const [loading, setLoading] = useState(undefined);
	const appStep = useMemo(() => 4, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	const [beneficiaryType, setBeneficiaryType] = useState(creationData?.beneficiaryType || '');
	const [beneficiaries, setBeneficiaries] = useState(
		creationData?.beneficiaries ? (creationData?.beneficiaryType === 'expreso' ? creationData?.beneficiaries : []) : []
	);
	const [showBeneficiaries, setShowBeneficiaries] = useState(beneficiaries?.length > 0 ? true : false);
	const totalPercentage = beneficiaries.reduce(function (acc, ben) {
		return acc + ben.percentage;
	}, 0);
	const [beneficiariesBank, setBeneficiariesBank] = useState(
		creationData?.beneficiariesBank
			? [
					{
						...creationData?.beneficiariesBank,
						loanExpirationDate: moment(creationData?.beneficiariesBank.loanExpirationDate).format('DD-MM-YYYY')
					}
			  ]
			: []
	);
	const [haveALoan, setHaveALoan] = useState(
		beneficiariesBank?.length > 0 ? 'yes' : creationData?.beneficiaryType !== undefined ? 'no' : ''
	);
	const [showBeneficiariesBank, setShowBeneficiariesBank] = useState(beneficiariesBank?.length > 0 ? true : false);

	useEffect(() => {
		setShowBeneficiaries(beneficiaries.length > 0 ? true : false);
		setShowBeneficiariesBank(beneficiariesBank.length > 0 ? true : false);
		return () => {};
	}, [beneficiaries.length, beneficiariesBank.length]);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.beneficiaries.back(goBack);
	};
	const nextPBC = async () => {
		try {
			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'quoter_purchase',
				holder_full_name:
					beneficiaryType === 'generico'
						? `${user.name} ${user.surname}`
						: beneficiaries
								.map((beneficiary) => {
									return `${beneficiary?.name} ${beneficiary?.surname}`;
								})
								.join(', ')
			});

			const targetPayload = {
				...creationData,
				beneficiaryType,
				beneficiaries: beneficiaries,
				beneficiariesBank:
					beneficiariesBank.length > 0
						? {
								loan: beneficiariesBank[0]?.loan,
								loanType: beneficiariesBank[0]?.loanType,
								externalLoanBankCompany: beneficiariesBank[0]?.externalLoanBankCompany,
								loanCost: parseFloat(beneficiariesBank[0]?.loanCost),
								loanExpirationDate: convertDateToBasicFormat(beneficiariesBank[0]?.loanExpirationDate),
								typeOfInterest: parseFloat(beneficiariesBank[0]?.typeOfInterest),
								amortizationPeriod: beneficiariesBank[0]?.amortizationPeriod
						  }
						: undefined
			};
			await saveTargetProperty(target.id, 'creationData', targetPayload);
			CONFIG.routing.beneficiaries.pbc(navigateTo);
			return;
		} catch (e) {
			console.log('e', e);
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-Beneficiaries-KO'
			});
		}
	};
	const isDisabled = () => {
		return (
			!beneficiaryType ||
			(beneficiaryType === 'expreso' && beneficiaries.length === 0) ||
			(beneficiaryType !== 'generico' && totalPercentage < 100)
		);
	};

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={25} title={sectionTitle} subtitle={sectionSubTitle}>
				<View rowSt marginBottomSmSt>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.primary,
							flex: 'none',
							order: 0,
							flexGrow: 0
						}}
					/>
				</View>
				<Text headingSecondarySt fullWidthSt marginBottomLgSt>
					{'Beneficiario'}
				</Text>
				<View innerWrapperSt>
					{false && (
						<BeneficiaryBankSelector
							haveALoan={haveALoan}
							setHaveALoan={setHaveALoan}
							beneficiariesBank={beneficiariesBank}
							setBeneficiariesBank={setBeneficiariesBank}
						/>
					)}

					<BeneficiarySelector
						beneficiaryType={beneficiaryType}
						setBeneficiaryType={setBeneficiaryType}
						beneficiaries={beneficiaries}
						setBeneficiaries={setBeneficiaries}
						totalPercentage={totalPercentage}
					/>

					{showBeneficiariesBank && (
						<View beneficiarySt>
							<Text labelInputSt style={{ alignSelf: 'flex-start' }}>
								Préstamo añadido
							</Text>
							{beneficiariesBank.map((ben, index) => {
								console.log(beneficiariesBank);
								return (
									console.log() || (
										<BeneficiaryBoxBank
											key={`ben-${ben.loan}`}
											ben={ben}
											onDelete={() => {
												setBeneficiariesBank(
													beneficiariesBank.filter(function (benf) {
														return benf.loan !== ben.loan;
													})
												);
												setHaveALoan('no');
											}}
											onEdit={() => {
												openModal(
													(close) => (
														<BenefeciaryBank
															close={close}
															beneficiariesBank={beneficiariesBank}
															setBeneficiariesBank={setBeneficiariesBank}
															beneficiaryBank={ben}
														/>
													),
													{
														disableClickAway: true,
														closeButton: false,
														modalClass: isMobile ? 'modal-xl' : 'modal-main',
														key: 'modal-anyadir-beneficiaries-bank'
													}
												);
											}}
										/>
									)
								);
							})}
						</View>
					)}

					{showBeneficiaries && (
						<BeneficiaryList
							beneficiaries={beneficiaries}
							setBeneficiaries={setBeneficiaries}
							totalPercentage={totalPercentage}
						/>
					)}
					{!isDisabled() && (
						<Text headingSecondarySt marginTopMdSt>
							¡Ya queda menos para terminar!
						</Text>
					)}
				</View>
				<BackNextButton
					onClickNext={nextPBC}
					onClickBack={back}
					isBackShown={true}
					isNextDisabled={isDisabled()}
					nextText={'Siguiente'}
					justifyContent={'center'}
				/>
			</Wrapper>
			<Footer />
		</>
	);
});
