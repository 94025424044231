import {
	AnalyticsWrapper,
	Button,
	NavigationWrapper,
	PickerHOC,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { MainModal } from 'components/MainModal/MainModal';
import { OptionButton } from 'components/OptionButton/OptionButton';
import { useGetError } from 'hooks/useGetError';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { useSendEmailContact } from 'hooks/useSendEmailContact';
import { Wrapper } from 'layout/components/Body/Wrapper';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';

import { Conditions } from '../../../components/Conditions/Conditions';
import TermsModal from '../../../components/TermsModal/TermsModal';
import TextSpan from '../../../components/TextSpan/TextSpan';
import { CONFIG } from '../../../config/config';
import Header from '../../../layout/components/Header/Header';

export const CannotConfirm = PickerHOC(
	{
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { openModal, useHeader, useLoading } = UiWrapper.use();
	const { query, goBack } = NavigationWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { user } = PickerHOC.use();
	const { t } = useTranslation();
	const { pageView, emitEvent, updateData, regLead } = AnalyticsWrapper.use();

	const [loading, setLoading] = useState();
	const { getError } = useGetError();

	// ESTADO
	const isAgentView = useMemo(() => false, []);
	const sendContactEmail = useSendEmailContact(user.id);

	const [showContactForm, setShowContactForm] = useState(false);
	const [showOnlyError, setShowOnlyError] = useState(false);

	const [prevPage, setPrevPage] = useState(query?.from || 'No detectada');
	const [prevView, setPrevView] = useState('No detectada');

	const [emailSent, setEmailSent] = useState(false);

	const [name, setName] = useState(user?.name?.trim() === 'NEW_USER' ? '' : user?.name?.trim() || '');

	const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');

	const [email, setEmail] = useState(user?.email?.trim() || '');

	const [isEmailCorrect, setIsEmailCorrect] = useState(true);

	const [horarioLlamadas, setHorarioLlamadas] = useState(user?.metadata?.horarioLlamadas || 'ahora');

	// CHECKS
	const [wantsInformation, setWantsInformation] = useState(user?.metadata?.acceptContact || false);
	const [privacityAccepted, setPrivacityAccepted] = useState(false);
	const [walmericDelioId, setWalmericDelioId] = useState(
		user?.metadata?.analytics?.WALMERIC_DELIO?.leadId || undefined
	);
	const [pageViewSent, setPageViewSent] = useState(false);

	// EL CORE TARDA UN POCO EN ACTUALIZAR query POR LO QUE HAY QUE ESPERAR A TENER VALORES VÁLIDOS
	useEffect(() => {
		if (
			query?.fromReason &&
			query?.fromView &&
			typeof query?.fromReason === 'string' &&
			typeof query?.fromView === 'string'
		) {
			if (query?.fromReason) {
				if (query?.fromReason === 'PBC') {
					setShowOnlyError(true);
					setShowContactForm(true);
				}
				setPrevPage(query?.fromReason);
			}
			if (query?.fromView) {
				setPrevView(query?.fromView);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	// NAVIGATION
	const back = async () => {
		// TEALIUM EVENT
		emitEvent({
			tealium_event: 'cmb_close',
			form_name: 'cmb'
		});

		goBack();
	};
	const next = async () => {
		try {
			setLoading('Guardando...');

			let leadId;
			try {
				// DELIO/WALMERIC LEAD REGISTER
				if (!walmericDelioId || (walmericDelioId && walmericDelioId?.toLowerCase()?.includes('error'))) {
					const nombreLead = encodeURIComponent(`${name} ${user?.surname || ''}`);
					leadId = await regLead({
						solicita_desde: callProcessType[prevView]?.solicita_desde || '',
						horario_llamadas: horarioLlamadas || undefined,
						phone: phoneNumber.slice(-9),
						email: email,
						name: nombreLead
					});
					setWalmericDelioId(leadId);

					// TEALIUM EVENT
					emitEvent({
						tealium_event: 'cmb_delio_lead_register',
						form_name: 'cmb'
					});
				}
			} catch (e) {
				leadId = e;
				setWalmericDelioId(leadId);
				setLoading(undefined);
			}
			//eslint-disable-next-line no-console
			console.log('🚀 Delio leadId →', leadId || walmericDelioId);

			await updateUser({
				username: {
					...user?.username,
					name
				},
				email,
				phoneNumber,
				metadata: {
					...user?.metadata,
					address: {
						...user?.metadata?.address
					},
					acceptsContact: wantsInformation,
					privacityAccepted,
					horarioLlamadas,
					analytics: {
						...user?.metadata?.analytics,
						WALMERIC_DELIO: {
							...user?.metadata?.analytics?.WALMERIC_DELIO,
							leadId: leadId && typeof leadId === 'string' ? leadId : walmericDelioId
						}
					}
				}
			});

			setLoading('Enviando...');

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'cmb_send',
				form_name: 'cmb'
			});

			const screenNames = {
				CLIENT_DATA: 'Datos del cliente',
				CUMULOS: 'Exceso de cúmulos en Caser',
				PBC: 'Preguntas de blanqueo de capitales',
				CASER_ERROR: 'Error en el WS de Caser',
				UNKNOWN: 'Error durante la simulación del producto',
				IDENTITY: 'KYC Documento identidad',
				BAD_CHARGEACCOUNT: 'Cuenta bancaria no válida',
				NOTOWNER_CHARGEACCOUNT: 'Cuenta bancaria',
				SAVE_PRODUCT_DATA: 'Datos de producto',
				ACTIVATE_PRODUCT: 'Activación de producto',
				APORTACIONES_SUPERAN_LIM: 'Activación de producto',
				SIGN_CONTRACT: 'Firma del contrato',
				CALL: 'Solicita una llamada'
			};

			const paginaPrevia = screenNames[prevPage] ?? 'No detectada';
			await sendContactEmail(name, phoneNumber, email, paginaPrevia);
			setEmailSent(true);

			openModal(
				() => (
					<MainModal
						type="success"
						text="Tu solicitud ha sido enviada con éxito. Un agente se pondrá en contacto contigo a la mayor brevedad."
					/>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-mobile' : 'modal-main',
					key: 'modal-CannotConfirm-OK'
				}
			);

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'cmb_send_success',
				form_name: 'cmb'
			});
			setLoading(undefined);
		} catch (e) {
			setLoading(undefined);

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'cmb_send_failure',
				form_name: 'cmb'
			});
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-CannotConfirm-KO'
			});
		}
	};

	const isDisabled = () => {
		return emailSent || !name || !phoneNumber || !email || !privacityAccepted;
	};

	// TEALIUM PAGE VIEW
	const callProcessType = {
		loginClient: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		selectPlan: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		makeContribution: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		bringPlan: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		beneficiaries: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		pbc: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		bankAccount: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		identityBiometricUpsert: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		identityBiometric: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		activateProduct: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		signatureSteps: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		signatureRead: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		signatureContract: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		productCreationFinished: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		cannotConfirm: { process_type: 'contratacion', solicita_desde: 'contratacion' }
	};

	const screenPageType = {
		PBC: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		},
		CLIENT_DATA: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		},
		NOTOWNER_CHARGEACCOUNT: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		},
		IDENTITY: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		},
		SAVE_PRODUCT_DATA: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		},
		SIGN_CONTRACT: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		},
		CALL: {
			page_type: 'cmb',
			process_step: 'error de servicio',
			process_type: callProcessType[prevView]?.process_type || ''
		}
	};

	useEffect(() => {
		if (prevPage !== 'No detectada' && prevView !== 'No detectada') {
			const tealiumData = {
				page_type: screenPageType[prevPage]?.page_type ?? 'Not defined',
				process_name: 'particulares planes de pensiones',
				process_step: screenPageType[prevPage]?.process_step ?? 'Not defined',
				process_type: screenPageType[prevPage]?.process_type ?? 'Not defined'
			};

			if (screenPageType[prevPage]?.page_type !== 'cmb' && pageViewSent === false) {
				setPageViewSent(true);
				pageView({
					pageType: tealiumData?.page_type ?? 'Not defined',
					processName: tealiumData?.process_name ?? 'Not defined',
					processStep: tealiumData?.process_step ?? 'Not defined',
					processType: tealiumData?.process_type ?? 'Not defined'
				});

				updateData(tealiumData);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prevPage, prevView, screenPageType]);

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	const handleInputChange = (setValue) => (e) => {
		const inputValue = e.target.value;
		if (/^[A-Za-z\s]+$/.test(inputValue)) {
			setValue(inputValue);
		}
	};

	useEffect(() => {
		console.log('📌 user', user);
		if (!CONFIG?.views?.cannotConfirm?.showPreviousCallMe) {
			setShowContactForm(true);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (showContactForm) {
		return (
			<Wrapper
				title={
					isAgentView
						? t('cannotConfirm.weCallYou')
						: {
								APORTACIONES_SUPERAN_LIM: 'Has superado el límite de aportaciones anual',
								PBC: t('cannotConfirm.weSorry'),
								CALL: t('landing.features.callUs.title')
						  }[prevPage] || '¡Lo sentimos! No es posible continuar con el proceso.'
				}
			>
				{!showOnlyError && (
					<>
						<Text headingTertiarySt isAgentView marginBottomMdSt fullWidthSt>
							Rellena el formulario y un agente se pondrá en contacto contigo a la mayor brevedad.
						</Text>
						<View inputRowOneColumnSt justifyItemsCenterSt>
							<InputLabel
								style={{ maxWidth: 350 }}
								value={name}
								onChange={handleInputChange(setName)}
								label={'Nombre'}
							/>
						</View>
						<View inputRowOneColumnSt justifyItemsCenterSt>
							<InputLabel
								style={{ maxWidth: 350 }}
								onBlur={() => {
									setIsEmailCorrect(isEmail(email));
								}}
								value={email}
								onChange={(e) => {
									if (!isEmailCorrect) {
										setIsEmailCorrect(isEmail(e.target.value));
									}
									setEmail(e.target.value);
								}}
								label={'Correo electrónico'}
								errorMsg={!isEmailCorrect && `Email inválido`}
							/>
						</View>
						<View inputRowOneColumnSt justifyItemsCenterSt>
							<InputLabel
								style={{ maxWidth: 350 }}
								value={phoneNumber}
								onChange={(value) => {
									setPhoneNumber(value);
								}}
								label={'Teléfono'}
								type="phone"
							/>
						</View>
						<View inputRowOneColumnSt justifyItemsCenterSt>
							<InputLabel
								style={{ maxWidth: 350 }}
								value={horarioLlamadas}
								onChange={(e) => {
									setHorarioLlamadas(e.target.value);
								}}
								label="¿Cuándo te llamamos?"
								type="select"
								selectOptions={[
									{ name: 'Mañana', value: 'mañana' },
									{ name: 'Ahora', value: 'ahora' },
									{ name: 'Tarde', value: 'tarde' }
								].map((item) => {
									return {
										label: item.name,
										value: item.value
									};
								})}
							/>
						</View>
						{/* {CONFIG.showMandatory && (
							<Text labelInputSt>
								{t('landing.features.callUs.mandatory')}
							</Text>
						)} */}
						{CONFIG.views.cannotConfirm.conditionsType === 'check' ? (
							<>
								{' '}
								<Conditions
									value={wantsInformation}
									onChange={() => {
										setWantsInformation(!wantsInformation);
									}}
									ConditionText={
										<TextSpan
											style={{
												color: CONFIG.colors.black
											}}
										>
											Quiero recibir información sobre productos y ofertas que me puedan beneficiar.
										</TextSpan>
									}
								/>
								<Conditions
									style={{
										marginBottom: CONFIG.spacing.md
									}}
									value={privacityAccepted}
									onChange={() => {
										setPrivacityAccepted(!privacityAccepted);
									}}
									ConditionText={
										<TextSpan
											style={{
												color: CONFIG.colors.black
											}}
											onClick={() => {
												openModal((close) => <TermsModal isOpen={true} onClose={close} />, {
													mode: 'old'
												});
											}}
										>
											Acepto la política de privacidad *
										</TextSpan>
									}
								/>
							</>
						) : (
							<>
								<View inputRowOneColumnSt justifyItemsCenterSt>
									<InputLabel
										label="Quiero recibir información sobre productos y ofertas que me puedan beneficiar."
										type="custom"
									>
										<View rowSt justifyStartSt>
											<OptionButton
												onClick={() => setWantsInformation(true)}
												selected={wantsInformation === true}
												text={'Sí'}
											/>
											<OptionButton
												onClick={() => setWantsInformation(false)}
												selected={wantsInformation === false}
												text={'No'}
											/>
										</View>
									</InputLabel>
								</View>
								<View inputRowOneColumnSt justifyItemsCenterSt>
									<InputLabel label="Acepto la política de privacidad *" type="custom">
										<View rowSt justifyStartSt>
											<OptionButton
												onClick={() => setPrivacityAccepted(true)}
												selected={privacityAccepted === true}
												text={'Sí'}
											/>
											<OptionButton
												onClick={() => setPrivacityAccepted(false)}
												selected={privacityAccepted === false}
												text={'No'}
											/>
										</View>
									</InputLabel>
								</View>
							</>
						)}

						<BackNextButton
							isBackShown={false}
							onClickBack={back}
							onClickNext={next}
							nextText={'ENVIAR'}
							isNextDisabled={isDisabled()}
						/>
					</>
				)}
			</Wrapper>
		);
	}

	return (
		<Wrapper showProgressBar={false} style={{ justifyContent: 'center', alignItems: 'center' }}>
			<Text headingPrimarySt marginBottomMdSt>
				{isAgentView
					? t('cannotConfirm.weCallYou')
					: {
							APORTACIONES_SUPERAN_LIM: 'Has superado el límite de aportaciones anual',
							PBC: t('cannotConfirm.weSorry'),
							CALL: '¿Hablamos?'
					  }[prevPage] || '¡Oops! Con los datos introducidos no podemos continuar el proceso de contratación online.'}
			</Text>
			<Text
				headingTertiarySt
				style={{
					marginBottom: 80
				}}
			>
				Rellena el formulario y un agente se pondrá en contacto contigo a la mayor brevedad.
			</Text>
			<Button
				onClick={() => setShowContactForm(true)}
				style={{
					marginBottom: CONFIG.spacing.lg
				}}
			>
				QUIERO QUE ME LLAMEN
			</Button>

			<a href={`tel:${CONFIG.project.helpPhone}`} style={{ textDecoration: 'none' }}>
				<Button onClick={() => console.log('LLAMANDO...')}>{CONFIG.project.helpPhoneFormatted}</Button>
			</a>
		</Wrapper>
	);
});
