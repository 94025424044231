import colors from './config/colors';
import commonStyles from './config/commonStyles';
import components from './config/components';
import fonts from './config/fonts';
import fontSizes from './config/fontSizes';
import inputs from './config/inputs';
import layout from './config/layout';
import project from './config/project';
import { routing } from './config/routes';
import spacing from './config/spacing';
import translations from './config/translations';
import views from './config/views';

export const CONFIG = {
	project: { ...project },
	layout: {
		topInsuranceCompanyImgsHeader: { ...layout.topInsuranceCompanyImgsHeader },
		header: { ...layout.header },
		topSection: {
			stepTitles: { ...project.steps.stepTitles },
			stepSubtitles: { ...project.steps.stepSubtitles },
			stepIcons: { ...project.steps.stepIcons },
			sectionTitle: { ...layout.topSection.sectionTitle },
			sectionSubTitle: { ...layout.topSection.sectionSubTitle },
		},
	},
	components: {
		mainWrapper: { ...components.mainWrapper },
		progressBar: { ...components.progressBar },
		backNextButton: { ...components.backNextButton },
	},
	colors: { ...colors, loadingModalBackground: colors.white },
	fonts: { ...fonts },
	fontSizes: { ...fontSizes },
	spacing: { ...spacing },
	routing: { ...routing },
	translations: { ...translations },
	commonStyles: { ...commonStyles },
	inputs: { ...inputs },
	views: {
		simulation: {
			caser: {
				B2B: {
					showTopResume: false,
					coverages: {
						IPA: true,
						IPAA: false,
						privacity: false,
					},
				},
				B2C: {
					showTopResume: true,
					coverages: {
						IPA: true,
						IPAA: false,
						privacity: true,
					},
				},
			},
			allianz: {},
		},
		activateProduct: { ...views.activateProduct },
		signatureRead: { ...views.signatureRead },
		identityBiometrics: { ...views.identityBiometrics },
		cannotConfirm: { ...views.cannotConfirm },
		stepCircles: {
			height: '50px',
			width: '50px',
		},
	},
};
