import { CONFIG } from 'config/config';

import Select from '@material-ui/core/Select';
import InputPhone from 'react-phone-number-input/input';
import styled from 'styled-components/macro';

export const InputWrapper = styled.input`
	width: 100%;
	max-width: ${CONFIG.inputs.maxWidth.default};
	color: ${CONFIG.inputs.color};
	font-family: ${CONFIG.inputs.fontFamily};
	font-size: ${CONFIG.inputs.fontSize};
	padding: ${CONFIG.inputs.padding.default};

	border: ${(props) => {
		if (!props.value) {
			return CONFIG.inputs.border.default;
		} else {
			return CONFIG.inputs.border.filled;
		}
	}};
	border-radius: ${CONFIG.inputs.border.radius};
	border-color: ${(props) => {
		if (!props.value) {
			return CONFIG.colors.greyLight;
		} else {
			return CONFIG.colors.primary;
		}
	}} !important;

	::placeholder {
		color: ${CONFIG.inputs.placeholder.color};
		font-family: ${CONFIG.inputs.placeholder.fontFamily};
		font-size: ${CONFIG.inputs.placeholder.fontSize};
	}

	transition: 0.2s all;
	&:focus {
		border-bottom: ${CONFIG.inputs.focus.borderBottom.full};
	}
`;

export const InputLabel = styled.label`
  color: ${(props) => {
		if (!props.value) {
			return 'transparent';
		} else {
			return `${CONFIG.colors.primary}`;
		}
	}};
  font-size: ${(props) => {
		if (!props.value) {
			return '16px';
		} else {
			return '14px';
		}
	}};
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: ${(props) => {
		if (!props.value) {
			return '10px';
		} else {
			return '-10px';
		}
	}};
	background-color: ${(props) => {
		if (!props.value) {
			return 'transparent';
		} else {
			return '#fff';
		}
	}};
	padding: 0px 3px;
  transition: 0.2s ease all;
//   ${InputWrapper}:focus ~ & {
// 	background-color: #fff;
//   	color: ${CONFIG.colors.primary};
//     top: -10px;
//     font-size: 14px;
//   }
`;

export const SelectWrapper = styled(Select)`
	border-color: ${(props) => {
		if (!props.value) {
			return CONFIG.colors.greyLight;
		} else {
			return CONFIG.colors.primary;
		}
	}} !important;
	border-radius: ${CONFIG.inputs.border.radius};

	transition: 0.2s all;
`;

export const InputPhoneWrapper = styled(InputPhone)`
	border-color: ${(props) => {
		if (!props.value) {
			return CONFIG.inputs.border.default;
		} else {
			return `${CONFIG.colors.primaryLight} !important`;
		}
	}};
	border-radius: ${CONFIG.inputs.border.radius};

	transition: 0.2s all;
`;

export const SuggestOption = styled.li`
	&:focus {
		color: ${CONFIG.colors.black};
		background-color: ${CONFIG.colors.primaryLighter};
		border-bottom: 4px solid ${CONFIG.colors.primary} !important;
	}
`;
