import {
	InstitutionsWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Footer from 'layout/components/Footer/Footer';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import BackNextButton from '../../components/BackNextButton/BackNextButton';
import InputLabel from '../../components/InputLabel/InputLabel';
import TextSpan from '../../components/TextSpan/TextSpan';
import { caserOnError } from '../../helpers/caserOnError';
import { getCurrency } from '../../helpers/currency';
import { useContribution } from '../../hooks/useContribution';
import { useGetError } from '../../hooks/useGetError';
import { useInitialScrollToTop } from '../../hooks/useInitialScrollToTop';

export const MakeContribution = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		institution: {
			arrayItemMatcher: {
				itemPropertyPath: 'identifier',
				type: 'value',
				value: 'caser'
			},
			sourcePath: 'institutions',
			sourceWrapper: InstitutionsWrapper
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { navigateTo } = NavigationWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { user } = UserWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { target, institution } = PickerHOC.use();
	const { getError } = useGetError();

	const minContribution = 30;
	const maxContribution = 1500;

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);

	// ESTADO
	const [loading, setLoading] = useState(undefined);
	const appStep = useMemo(() => 3, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	const [initialContribution, setInitialContribution] = useState(creationData?.initialContribution ?? null);
	const [timer, setTimer] = useState(null);
	useEffect(() => {
		return () => {
			// Limpieza: asegúrate de limpiar el temporizador cuando el componente se desmonte
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [timer]);

	const [trigger, setTrigger] = useState(0);

	const onError = (e) => {
		caserOnError(e, 'SIMULATE_PRODUCT', openModal, navigateTo, setTrigger, 0, 0, false);
	};

	const [
		setInitialContributionData,
		// eslint-disable-next-line no-unused-vars
		setSourceData,
		setStartProcess,
		savedContributionData
	] = useContribution(institution, target.id, user, onError, trigger);

	const parseContribution = (contribution) =>
		contribution && typeof contribution === 'string' ? parseInt(contribution.replace(/\D/g, '')) : contribution;

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.makeContribution.selectPlan(navigateTo);
	};
	const nextBeneficiaries = async () => {
		try {
			const initialCont = parseContribution(initialContribution);
			const targetPayload = {
				...creationData,
				initialContribution: initialCont
			};
			await saveTargetProperty(target.id, 'creationData', targetPayload);

			setInitialContributionData(initialCont);
			setStartProcess(true);
			return;
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-MakeContribution-KO'
			});
		}
	};
	useEffect(() => {
		if (savedContributionData) {
			CONFIG.routing.makeContribution.beneficiaries(navigateTo);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savedContributionData]);
	const isDisabled = () => {
		// eslint-disable-next-line eqeqeq
		return (
			!initialContribution ||
			parseContribution(initialContribution) < minContribution ||
			parseContribution(initialContribution) > maxContribution
		);
	};

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={25} title={sectionTitle} subtitle={sectionSubTitle}>
				<View rowSt marginBottomSmSt>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.primary,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0
						}}
					/>
				</View>
				<Text headingSecondarySt fullWidthSt marginBottomLgSt>
					{'Aportación inicial'}
				</Text>
				<View innerWrapperSt>
					<View inputRowSt style={{ maxWidth: isMobile ? '100%' : '40%' }}>
						<View rowSt style={{ alignItems: 'flex-start' }}>
							<InputLabel
								errorMsg={
									initialContribution
										? parseContribution(initialContribution) < minContribution
											? `La contribución mínima es de ${minContribution} €`
											: parseContribution(initialContribution) > maxContribution
											? `La contribución máxima es de ${maxContribution} €`
											: undefined
										: undefined
								}
								value={initialContribution}
								onChange={(e) => {
									const newCapital = e.target.value || '';
									const val = newCapital.replace(/\D/g, '');
									setInitialContribution(val);
									// Reiniciar el temporizador cada vez que hay un cambio
									if (timer) {
										clearTimeout(timer);
									}
									setTimer(
										setTimeout(() => {
											// Función a ejecutar después de 1 segundo sin cambios
											console.log('Ejecutando función después de 1 segundo sin cambios', val);
											setInitialContribution(val ? getCurrency(val, 0, true) : null);
										}, 1000)
									);
								}}
								label={'¿Cuánto aportarás inicialmente?'}
							/>
							<TextSpan
								style={{
									marginLeft: -35,
									marginTop: 12,
									zIndex: 1,
									color: CONFIG.colors.primary,
									fontSize: CONFIG.fontSizes.lg,
									fontWeight: 700
								}}
							>
								€
							</TextSpan>
						</View>
					</View>
					{!isDisabled() && (
						<Text headingSecondarySt marginTopMdSt>
							¡Ya queda menos para terminar!
						</Text>
					)}
				</View>
				<BackNextButton
					onClickNext={nextBeneficiaries}
					onClickBack={back}
					isBackShown={true}
					isNextDisabled={isDisabled()}
					nextText={'Siguiente'}
					justifyContent={'center'}
				/>
			</Wrapper>
			<Footer />
		</>
	);
});
