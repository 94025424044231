import { CONFIG } from 'config/config';

import { createGlobalStyle, css } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${CONFIG.fonts.regular};
}

a {
	color: ${CONFIG.colors.secondary};
  font-family: ${CONFIG.fonts.semiBold};
  text-decoration: none;
}

//MATERIAL UI
// Fuente base
.MuiInputBase-input {
  font-family: ${CONFIG.fonts.regular} !important;
  width: calc(100% - 24px) !important;
  color: ${CONFIG.inputs.color} !important;
  padding: 4.5px 0 4.5px !important;
}

	.MuiInputBase-root {
		padding: ${CONFIG.inputs.padding.select};
		max-width: ${CONFIG.inputs.maxWidth.default};

		width: ${(props) => {
		if (!props.mountExtraSpace) {
			return '100%';
		} else {
			return '85%';
		}
	}};

		border: ${(props) => {
		if (!props.value) {
			return CONFIG.inputs.border.default;
		} else {
			return CONFIG.inputs.border.filled;
		}
	}};
		border-radius: ${CONFIG.inputs.border.radius};

		::placeholder {
			color: ${CONFIG.inputs.placeholder.color};
			font-family: ${CONFIG.inputs.placeholder.fontFamily};
			font-size: ${CONFIG.inputs.placeholder.fontSize};
		}

		transition: 0.2s all;
		&:focus {
			border-bottom: ${CONFIG.inputs.focus.borderBottom.full};
		}
	}
	.MuiSelect-icon {
		top: calc(50% - 10px);
		height: auto;
		right: 19px !important;
	}

	.MuiFormControl-root {
		max-width: ${CONFIG.inputs.maxWidth.default};
		width: 100%;
	}

	.MuiFormControl-marginDense {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

// Padding para todos los inputs
.MuiFilledInput-input {
  padding-left: 0 !important;
}

// Background de iconos en inputs
.MuiFilledInput-root:hover {
  background: none !important;
}
.MuiFilledInput-root.Mui-focused {
  background: none !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: ${CONFIG.colors.primary} !important;
}

// Borde extra en algunos inputs
.MuiInput-underline.MuiInputBase-root:before {
    border-bottom: 0px solid ${CONFIG.inputs.focus.borderBottom.color} !important;
}

// Color Circular Progress
.MuiCircularProgress-colorPrimary {
  color: ${CONFIG.colors.primary} !important;
}

// Background de input select al hacer focus
.MuiSelect-select:focus {
  background: none !important;
}

//Opciones select
.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}
.MuiListItem-button.Mui-selected, .MuiListItem-root.Mui-focusVisible, .MuiListItem-button:hover {
  background: ${CONFIG.colors.primaryLighter} !important;
	opacity: 1 !important;
}
.MuiListItem-button.Mui-selected > p {
  color: ${CONFIG.colors.black} !important;
	opacity: 1 !important;
}
.MuiListItem-button.Mui-disabled {
  background: ${CONFIG.colors.primary} !important;
	opacity: 1 !important;
}
.MuiListItem-button.Mui-disabled > p {
	padding-top: 4px;
	padding-bottom: 4px;
  color: ${CONFIG.colors.white} !important;
}

// Opciones autocomplete
.suggestions {
  background: ${CONFIG.colors.white};
	border: 1px solid ${CONFIG.colors.primary};
  list-style: none;
  margin-top: 0;
  max-height: 264px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  position: absolute;
  top: 105%;
  z-index: 150;
}
.suggestions li {
  padding: 0.7rem;
  font-size: 0.9rem;
}
.suggestion-active,
.suggestions li:hover {
  background: ${CONFIG.colors.primary};
  color: ${CONFIG.colors.white};
  cursor: pointer;
  font-weight: 700;
}
.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid ${CONFIG.colors.primary};
}

// Tablas:
.MuiTableCell-body {
	border-bottom: 2px solid ${CONFIG.colors.primary} !important;
}

.MuiTooltip-popper {
  z-index: 1000 !important;
  pointer-events: all !important;
}

//REACT-PHONE-NUMBER-INPUT
.PhoneInputCountry {
	width: 50px;
	margin: 0;
}

.PhoneInputInput {
	max-width: calc(${CONFIG.inputs.maxWidth.default} - 50px);
	color: ${CONFIG.inputs.color};
	font-family: ${CONFIG.inputs.fontFamily};
	font-size: ${CONFIG.inputs.fontSize};
	padding: ${CONFIG.inputs.padding.default};

	border: ${(props) => {
		if (!props.value) {
			return CONFIG.inputs.border.default;
		} else {
			console.log('props.value', props.value)
			return CONFIG.inputs.border.filled;
		}
	}};
	border-radius: ${CONFIG.inputs.border.radius};

	::placeholder {
		color: ${CONFIG.inputs.placeholder.color};
		font-family: ${CONFIG.inputs.placeholder.fontFamily};
		font-size: ${CONFIG.inputs.placeholder.fontSize};
	}

	transition: 0.2s all;
	&:focus {
		border-bottom: ${CONFIG.inputs.focus.borderBottom.full};
	}
}

//DOC VIEWER --------------------------------------------------
.docViewDoc {
	text-align: center;
	margin-top: 20px
}
.docViewPage canvas {
	margin: 0 auto;
-webkit-box-shadow: 5px 10px 8px 3px rgba(0,0,0,0.59);
box-shadow: 5px 10px 8px 3px rgba(0,0,0,0.59);
}
`;

export const verticalFlex = css`
	align-items: center;
	display: flex;
	flex-direction: column;
`;
