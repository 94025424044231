import { Text, View } from '@coinscrap/webapp-core';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { CONFIG } from 'config/config';
import React from 'react';

export const SectionSubTitle = ({ subtitle, style, tooltip = false }) => {
	if (CONFIG.layout.topSection.sectionSubTitle.variant === 'titleAndLine') {
		return (
			<View alignItemsStartSt style={{ ...style }}>
				<View rowSt justifyStartSt>
					<Text headingTertiarySt>{subtitle}</Text> {tooltip}
				</View>
				<DashedLines />
			</View>
		);
	} else {
		return (
			<View alignItemsCenterSt style={{ ...style, maxWidth: '100%', alignSelf: 'center' }} marginBottomLgSt>
				<Text headingTertiarySt>{subtitle}</Text> {tooltip}
			</View>
		);
	}
};
