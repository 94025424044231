import project from './project';


const layout = {
	topInsuranceCompanyImgsHeader: {
		show: true,
		showShadow: false,
		height: 0,
	},
	header: {
		height: 90,
		showShadow: true,
		showNavButton: {
			B2B: true,
			B2BbuttonVariant: 'pill', // default or pill
			B2C: true,
			B2CbuttonVariant: 'transparent', // default or pill
		},
		showContactButton: {
			B2B: true,
			B2Btext: project.helpPhoneFormatted,
			B2C: true,
			B2Ctext: 'contact info in config.project',
		},
		showLangSelector: {
			B2B: false,
			B2C: false,
		},
	},
	topSection: {
		sectionTitle: {
			variant: 'onlyTitle', // onlyTitle, titleAndLine
		},
		sectionSubTitle: {
			variant: 'onlyTitle', // onlyTitle, titleAndLine
		},
	},
};
export default layout
