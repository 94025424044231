export const provincesList = [
	{
		NAME: 'Albacete',
		VALUE: '02',
	},
	{
		NAME: 'Alicante/Alacant',
		VALUE: '03',
	},
	{
		NAME: 'Almería',
		VALUE: '04',
	},
	{
		NAME: 'Araba/Álava',
		VALUE: '01',
	},
	{
		NAME: 'Asturias',
		VALUE: '33',
	},
	{
		NAME: 'Ávila',
		VALUE: '05',
	},
	{
		NAME: 'Badajoz',
		VALUE: '06',
	},
	{
		NAME: 'Balears, Illes',
		VALUE: '07',
	},
	{
		NAME: 'Barcelona',
		VALUE: '08',
	},
	{
		NAME: 'Bizkaia',
		VALUE: '48',
	},
	{
		NAME: 'Burgos',
		VALUE: '09',
	},
	{
		NAME: 'Cáceres',
		VALUE: '10',
	},
	{
		NAME: 'Cádiz',
		VALUE: '11',
	},
	{
		NAME: 'Cantabria',
		VALUE: '39',
	},
	{
		NAME: 'Castellón/Castelló',
		VALUE: '12',
	},
	{
		NAME: 'Ciudad Real',
		VALUE: '13',
	},
	{
		NAME: 'Córdoba',
		VALUE: '14',
	},
	{
		NAME: 'Coruña',
		VALUE: '15',
	},
	{
		NAME: 'Cuenca',
		VALUE: '16',
	},
	{
		NAME: 'Gipuzkoa',
		VALUE: '20',
	},
	{
		NAME: 'Girona',
		VALUE: '17',
	},
	{
		NAME: 'Granada',
		VALUE: '18',
	},
	{
		NAME: 'Guadalajara',
		VALUE: '19',
	},
	{
		NAME: 'Huelva',
		VALUE: '21',
	},
	{
		NAME: 'Huesca',
		VALUE: '22',
	},
	{
		NAME: 'Jaén',
		VALUE: '23',
	},
	{
		NAME: 'León',
		VALUE: '24',
	},
	{
		NAME: 'Lleida',
		VALUE: '25',
	},
	{
		NAME: 'Lugo',
		VALUE: '27',
	},
	{
		NAME: 'Madrid',
		VALUE: '28',
	},
	{
		NAME: 'Málaga',
		VALUE: '29',
	},
	{
		NAME: 'Murcia',
		VALUE: '30',
	},
	{
		NAME: 'Navarra',
		VALUE: '31',
	},
	{
		NAME: 'Ourense',
		VALUE: '32',
	},
	{
		NAME: 'Pontevedra',
		VALUE: '36',
	},
	{
		NAME: 'Palencia',
		VALUE: '34',
	},
	{
		NAME: 'Palmas, Las',
		VALUE: '35',
	},
	{
		NAME: 'Rioja, La',
		VALUE: '26',
	},
	{
		NAME: 'Salamanca',
		VALUE: '37',
	},
	{
		NAME: 'Santa Cruz de Tenerife',
		VALUE: '38',
	},
	{
		NAME: 'Segovia',
		VALUE: '40',
	},
	{
		NAME: 'Sevilla',
		VALUE: '41',
	},
	{
		NAME: 'Soria',
		VALUE: '42',
	},
	{
		NAME: 'Tarragona',
		VALUE: '43',
	},
	{
		NAME: 'Teruel',
		VALUE: '44',
	},
	{
		NAME: 'Toledo',
		VALUE: '45',
	},
	{
		NAME: 'Valencia/València',
		VALUE: '46',
	},
	{
		NAME: 'Valladolid',
		VALUE: '47',
	},
	{
		NAME: 'Zamora',
		VALUE: '49',
	},
	{
		NAME: 'Zaragoza',
		VALUE: '50',
	},
	{
		NAME: 'Ceuta',
		VALUE: '51',
	},
	{
		NAME: 'Melilla',
		VALUE: '52',
	},
];

export const provincesTypes = provincesList.reduce((acc, item) => {
	acc[item.NAME] = item.VALUE;
	return acc;
}, {});
