export const smokeTypesES = [
	{ label: 'Pipas', value: 'pipas' },
	{ label: 'Puros', value: 'puros' },
	{ label: 'Cigarrillos', value: 'cigarrillos' },
	{ label: 'Cigarrillos electronicos', value: 'cigarrillosElectronicos' },
];

export const alcoholTypesES = [
	{ label: 'Jerez', value: 'jerez' },
	{ label: 'Vino', value: 'vino' },
	{ label: 'Cerveza', value: 'cerveza' },
	{ label: 'Whisky', value: 'whisky' },
	{ label: 'Licor', value: 'licor' },
];
