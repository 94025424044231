import React from 'react';

import { isMobile } from 'react-device-detect';

import { Button, Text, UiWrapper, View } from '@coinscrap/webapp-core';

import BeneficiaryModal from 'components/BeneficiaryModal/BeneficiaryModal';
import InputLabel from 'components/InputLabel/InputLabel';

import BeneficiariesInfoModal from '../../../components/BeneficiariesInfoModal/BeneficiariesInfoModal';
import TooltipSimple from '../../../components/TooltipSimple/TooltipSimple';

export const BeneficiarySelector = ({
	beneficiaryType,
	setBeneficiaryType,
	beneficiaries,
	setBeneficiaries,
	totalPercentage
}) => {
	const { openModal } = UiWrapper.use();

	return (
		<View
			inputRowSt
			alignItemsEndSt
			style={{
				position: 'relative'
			}}
		>
			{' '}
			<View
				style={{
					width: '10%',
					position: 'absolute',
					top: 18,
					left: -70
				}}
				onClick={() => {
					openModal((close) => <BeneficiariesInfoModal isOpen={true} onClose={close} />, {
						mode: 'old'
					});
				}}
			>
				<TooltipSimple>
					<Text tooltipTitleSt>Información sobre designación de beneficiarios.</Text>
					<Text tooltipTextSt>Haz click para ver más información.</Text>
				</TooltipSimple>
			</View>
			<InputLabel
				value={beneficiaryType}
				onChange={(e) => {
					setBeneficiaryType(e.target.value);
					if (e.target.value !== 'expreso') {
						setBeneficiaries([]);
					}
				}}
				label={'Beneficiario'}
				type="select"
				selectOptions={[
					{
						label: 'Por defecto',
						value: 'generico'
					},
					{
						label: 'Designado expresamente',
						value: 'expreso'
					}
				]}
			/>
			{beneficiaryType === 'expreso' && totalPercentage < 100 && (
				<View
					style={{
						alignItems: 'center',
						width: 'initial'
					}}
				>
					<Button
						secondarySt
						sameHeightAsInputsSt
						style={{ width: '100%' }}
						disabled={beneficiaryType === 'generico'}
						onClick={() => {
							openModal(
								(close) => (
									<BeneficiaryModal close={close} beneficiaries={beneficiaries} setBeneficiaries={setBeneficiaries} />
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-mobile' : 'modal-main',
									key: 'modal-anyadir-new-beneficiary'
								}
							);
						}}
					>
						{'Añadir beneficiario'}
					</Button>
				</View>
			)}
		</View>
	);
};
