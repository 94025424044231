export const municipalitiesList = [
	{
		CPRO: '01',
		CMUN: '001',
		DC: '4',
		NOMBRE: 'Alegría-Dulantzi',
	},
	{
		CPRO: '01',
		CMUN: '002',
		DC: '9',
		NOMBRE: 'Amurrio',
	},
	{
		CPRO: '01',
		CMUN: '049',
		DC: '3',
		NOMBRE: 'Añana',
	},
	{
		CPRO: '01',
		CMUN: '003',
		DC: '5',
		NOMBRE: 'Aramaio',
	},
	{
		CPRO: '01',
		CMUN: '006',
		DC: '6',
		NOMBRE: 'Armiñón',
	},
	{
		CPRO: '01',
		CMUN: '037',
		DC: '6',
		NOMBRE: 'Arraia-Maeztu',
	},
	{
		CPRO: '01',
		CMUN: '008',
		DC: '8',
		NOMBRE: 'Arrazua-Ubarrundia',
	},
	{
		CPRO: '01',
		CMUN: '004',
		DC: '0',
		NOMBRE: 'Artziniega',
	},
	{
		CPRO: '01',
		CMUN: '009',
		DC: '1',
		NOMBRE: 'Asparrena',
	},
	{
		CPRO: '01',
		CMUN: '010',
		DC: '5',
		NOMBRE: 'Ayala/Aiara',
	},
	{
		CPRO: '01',
		CMUN: '011',
		DC: '2',
		NOMBRE: 'Baños de Ebro/Mañueta',
	},
	{
		CPRO: '01',
		CMUN: '013',
		DC: '3',
		NOMBRE: 'Barrundia',
	},
	{
		CPRO: '01',
		CMUN: '014',
		DC: '8',
		NOMBRE: 'Berantevilla',
	},
	{
		CPRO: '01',
		CMUN: '016',
		DC: '4',
		NOMBRE: 'Bernedo',
	},
	{
		CPRO: '01',
		CMUN: '017',
		DC: '0',
		NOMBRE: 'Campezo/Kanpezu',
	},
	{
		CPRO: '01',
		CMUN: '021',
		DC: '0',
		NOMBRE: 'Elburgo/Burgelu',
	},
	{
		CPRO: '01',
		CMUN: '022',
		DC: '5',
		NOMBRE: 'Elciego',
	},
	{
		CPRO: '01',
		CMUN: '023',
		DC: '1',
		NOMBRE: 'Elvillar/Bilar',
	},
	{
		CPRO: '01',
		CMUN: '046',
		DC: '8',
		NOMBRE: 'Erriberagoitia/Ribera Alta',
	},
	{
		CPRO: '01',
		CMUN: '056',
		DC: '5',
		NOMBRE: 'Harana/Valle de Arana',
	},
	{
		CPRO: '01',
		CMUN: '901',
		DC: '5',
		NOMBRE: 'Iruña Oka/Iruña de Oca',
	},
	{
		CPRO: '01',
		CMUN: '027',
		DC: '8',
		NOMBRE: 'Iruraiz-Gauna',
	},
	{
		CPRO: '01',
		CMUN: '019',
		DC: '9',
		NOMBRE: 'Kripan',
	},
	{
		CPRO: '01',
		CMUN: '020',
		DC: '3',
		NOMBRE: 'Kuartango',
	},
	{
		CPRO: '01',
		CMUN: '028',
		DC: '4',
		NOMBRE: 'Labastida/Bastida',
	},
	{
		CPRO: '01',
		CMUN: '030',
		DC: '1',
		NOMBRE: 'Lagrán',
	},
	{
		CPRO: '01',
		CMUN: '031',
		DC: '8',
		NOMBRE: 'Laguardia',
	},
	{
		CPRO: '01',
		CMUN: '032',
		DC: '3',
		NOMBRE: 'Lanciego/Lantziego',
	},
	{
		CPRO: '01',
		CMUN: '902',
		DC: '0',
		NOMBRE: 'Lantarón',
	},
	{
		CPRO: '01',
		CMUN: '033',
		DC: '9',
		NOMBRE: 'Lapuebla de Labarca',
	},
	{
		CPRO: '01',
		CMUN: '036',
		DC: '0',
		NOMBRE: 'Laudio/Llodio',
	},
	{
		CPRO: '01',
		CMUN: '058',
		DC: '7',
		NOMBRE: 'Legutio',
	},
	{
		CPRO: '01',
		CMUN: '034',
		DC: '4',
		NOMBRE: 'Leza',
	},
	{
		CPRO: '01',
		CMUN: '039',
		DC: '5',
		NOMBRE: 'Moreda de Álava',
	},
	{
		CPRO: '01',
		CMUN: '041',
		DC: '6',
		NOMBRE: 'Navaridas',
	},
	{
		CPRO: '01',
		CMUN: '042',
		DC: '1',
		NOMBRE: 'Okondo',
	},
	{
		CPRO: '01',
		CMUN: '043',
		DC: '7',
		NOMBRE: 'Oyón-Oion',
	},
	{
		CPRO: '01',
		CMUN: '044',
		DC: '2',
		NOMBRE: 'Peñacerrada-Urizaharra',
	},
	{
		CPRO: '01',
		CMUN: '047',
		DC: '4',
		NOMBRE: 'Ribera Baja/Erribera Beitia',
	},
	{
		CPRO: '01',
		CMUN: '051',
		DC: '3',
		NOMBRE: 'Salvatierra/Agurain',
	},
	{
		CPRO: '01',
		CMUN: '052',
		DC: '8',
		NOMBRE: 'Samaniego',
	},
	{
		CPRO: '01',
		CMUN: '053',
		DC: '4',
		NOMBRE: 'San Millán/Donemiliaga',
	},
	{
		CPRO: '01',
		CMUN: '054',
		DC: '9',
		NOMBRE: 'Urkabustaiz',
	},
	{
		CPRO: '01',
		CMUN: '055',
		DC: '2',
		NOMBRE: 'Valdegovía/Gaubea',
	},
	{
		CPRO: '01',
		CMUN: '057',
		DC: '1',
		NOMBRE: 'Villabuena de Álava/Eskuernaga',
	},
	{
		CPRO: '01',
		CMUN: '059',
		DC: '0',
		NOMBRE: 'Vitoria-Gasteiz',
	},
	{
		CPRO: '01',
		CMUN: '060',
		DC: '4',
		NOMBRE: 'Yécora/Iekora',
	},
	{
		CPRO: '01',
		CMUN: '061',
		DC: '1',
		NOMBRE: 'Zalduondo',
	},
	{
		CPRO: '01',
		CMUN: '062',
		DC: '6',
		NOMBRE: 'Zambrana',
	},
	{
		CPRO: '01',
		CMUN: '018',
		DC: '6',
		NOMBRE: 'Zigoitia',
	},
	{
		CPRO: '01',
		CMUN: '063',
		DC: '2',
		NOMBRE: 'Zuia',
	},
	{
		CPRO: '02',
		CMUN: '001',
		DC: '9',
		NOMBRE: 'Abengibre',
	},
	{
		CPRO: '02',
		CMUN: '002',
		DC: '4',
		NOMBRE: 'Alatoz',
	},
	{
		CPRO: '02',
		CMUN: '003',
		DC: '0',
		NOMBRE: 'Albacete',
	},
	{
		CPRO: '02',
		CMUN: '004',
		DC: '5',
		NOMBRE: 'Albatana',
	},
	{
		CPRO: '02',
		CMUN: '005',
		DC: '8',
		NOMBRE: 'Alborea',
	},
	{
		CPRO: '02',
		CMUN: '006',
		DC: '1',
		NOMBRE: 'Alcadozo',
	},
	{
		CPRO: '02',
		CMUN: '007',
		DC: '7',
		NOMBRE: 'Alcalá del Júcar',
	},
	{
		CPRO: '02',
		CMUN: '008',
		DC: '3',
		NOMBRE: 'Alcaraz',
	},
	{
		CPRO: '02',
		CMUN: '009',
		DC: '6',
		NOMBRE: 'Almansa',
	},
	{
		CPRO: '02',
		CMUN: '010',
		DC: '0',
		NOMBRE: 'Alpera',
	},
	{
		CPRO: '02',
		CMUN: '011',
		DC: '7',
		NOMBRE: 'Ayna',
	},
	{
		CPRO: '02',
		CMUN: '012',
		DC: '2',
		NOMBRE: 'Balazote',
	},
	{
		CPRO: '02',
		CMUN: '014',
		DC: '3',
		NOMBRE: 'Ballestero, El',
	},
	{
		CPRO: '02',
		CMUN: '013',
		DC: '8',
		NOMBRE: 'Balsa de Ves',
	},
	{
		CPRO: '02',
		CMUN: '015',
		DC: '6',
		NOMBRE: 'Barrax',
	},
	{
		CPRO: '02',
		CMUN: '016',
		DC: '9',
		NOMBRE: 'Bienservida',
	},
	{
		CPRO: '02',
		CMUN: '017',
		DC: '5',
		NOMBRE: 'Bogarra',
	},
	{
		CPRO: '02',
		CMUN: '018',
		DC: '1',
		NOMBRE: 'Bonete',
	},
	{
		CPRO: '02',
		CMUN: '019',
		DC: '4',
		NOMBRE: 'Bonillo, El',
	},
	{
		CPRO: '02',
		CMUN: '020',
		DC: '8',
		NOMBRE: 'Carcelén',
	},
	{
		CPRO: '02',
		CMUN: '021',
		DC: '5',
		NOMBRE: 'Casas de Juan Núñez',
	},
	{
		CPRO: '02',
		CMUN: '022',
		DC: '0',
		NOMBRE: 'Casas de Lázaro',
	},
	{
		CPRO: '02',
		CMUN: '023',
		DC: '6',
		NOMBRE: 'Casas de Ves',
	},
	{
		CPRO: '02',
		CMUN: '024',
		DC: '1',
		NOMBRE: 'Casas-Ibáñez',
	},
	{
		CPRO: '02',
		CMUN: '025',
		DC: '4',
		NOMBRE: 'Caudete',
	},
	{
		CPRO: '02',
		CMUN: '026',
		DC: '7',
		NOMBRE: 'Cenizate',
	},
	{
		CPRO: '02',
		CMUN: '029',
		DC: '2',
		NOMBRE: 'Chinchilla de Monte-Aragón',
	},
	{
		CPRO: '02',
		CMUN: '027',
		DC: '3',
		NOMBRE: 'Corral-Rubio',
	},
	{
		CPRO: '02',
		CMUN: '028',
		DC: '9',
		NOMBRE: 'Cotillas',
	},
	{
		CPRO: '02',
		CMUN: '030',
		DC: '6',
		NOMBRE: 'Elche de la Sierra',
	},
	{
		CPRO: '02',
		CMUN: '031',
		DC: '3',
		NOMBRE: 'Férez',
	},
	{
		CPRO: '02',
		CMUN: '032',
		DC: '8',
		NOMBRE: 'Fuensanta',
	},
	{
		CPRO: '02',
		CMUN: '033',
		DC: '4',
		NOMBRE: 'Fuente-Álamo',
	},
	{
		CPRO: '02',
		CMUN: '034',
		DC: '9',
		NOMBRE: 'Fuentealbilla',
	},
	{
		CPRO: '02',
		CMUN: '035',
		DC: '2',
		NOMBRE: 'Gineta, La',
	},
	{
		CPRO: '02',
		CMUN: '036',
		DC: '5',
		NOMBRE: 'Golosalvo',
	},
	{
		CPRO: '02',
		CMUN: '037',
		DC: '1',
		NOMBRE: 'Hellín',
	},
	{
		CPRO: '02',
		CMUN: '038',
		DC: '7',
		NOMBRE: 'Herrera, La',
	},
	{
		CPRO: '02',
		CMUN: '039',
		DC: '0',
		NOMBRE: 'Higueruela',
	},
	{
		CPRO: '02',
		CMUN: '040',
		DC: '4',
		NOMBRE: 'Hoya-Gonzalo',
	},
	{
		CPRO: '02',
		CMUN: '041',
		DC: '1',
		NOMBRE: 'Jorquera',
	},
	{
		CPRO: '02',
		CMUN: '042',
		DC: '6',
		NOMBRE: 'Letur',
	},
	{
		CPRO: '02',
		CMUN: '043',
		DC: '2',
		NOMBRE: 'Lezuza',
	},
	{
		CPRO: '02',
		CMUN: '044',
		DC: '7',
		NOMBRE: 'Liétor',
	},
	{
		CPRO: '02',
		CMUN: '045',
		DC: '0',
		NOMBRE: 'Madrigueras',
	},
	{
		CPRO: '02',
		CMUN: '046',
		DC: '3',
		NOMBRE: 'Mahora',
	},
	{
		CPRO: '02',
		CMUN: '047',
		DC: '9',
		NOMBRE: 'Masegoso',
	},
	{
		CPRO: '02',
		CMUN: '048',
		DC: '5',
		NOMBRE: 'Minaya',
	},
	{
		CPRO: '02',
		CMUN: '049',
		DC: '8',
		NOMBRE: 'Molinicos',
	},
	{
		CPRO: '02',
		CMUN: '050',
		DC: '1',
		NOMBRE: 'Montalvos',
	},
	{
		CPRO: '02',
		CMUN: '051',
		DC: '8',
		NOMBRE: 'Montealegre del Castillo',
	},
	{
		CPRO: '02',
		CMUN: '052',
		DC: '3',
		NOMBRE: 'Motilleja',
	},
	{
		CPRO: '02',
		CMUN: '053',
		DC: '9',
		NOMBRE: 'Munera',
	},
	{
		CPRO: '02',
		CMUN: '054',
		DC: '4',
		NOMBRE: 'Navas de Jorquera',
	},
	{
		CPRO: '02',
		CMUN: '055',
		DC: '7',
		NOMBRE: 'Nerpio',
	},
	{
		CPRO: '02',
		CMUN: '056',
		DC: '0',
		NOMBRE: 'Ontur',
	},
	{
		CPRO: '02',
		CMUN: '057',
		DC: '6',
		NOMBRE: 'Ossa de Montiel',
	},
	{
		CPRO: '02',
		CMUN: '058',
		DC: '2',
		NOMBRE: 'Paterna del Madera',
	},
	{
		CPRO: '02',
		CMUN: '060',
		DC: '9',
		NOMBRE: 'Peñas de San Pedro',
	},
	{
		CPRO: '02',
		CMUN: '059',
		DC: '5',
		NOMBRE: 'Peñascosa',
	},
	{
		CPRO: '02',
		CMUN: '061',
		DC: '6',
		NOMBRE: 'Pétrola',
	},
	{
		CPRO: '02',
		CMUN: '062',
		DC: '1',
		NOMBRE: 'Povedilla',
	},
	{
		CPRO: '02',
		CMUN: '901',
		DC: '0',
		NOMBRE: 'Pozo Cañada',
	},
	{
		CPRO: '02',
		CMUN: '063',
		DC: '7',
		NOMBRE: 'Pozohondo',
	},
	{
		CPRO: '02',
		CMUN: '064',
		DC: '2',
		NOMBRE: 'Pozo-Lorente',
	},
	{
		CPRO: '02',
		CMUN: '065',
		DC: '5',
		NOMBRE: 'Pozuelo',
	},
	{
		CPRO: '02',
		CMUN: '066',
		DC: '8',
		NOMBRE: 'Recueja, La',
	},
	{
		CPRO: '02',
		CMUN: '067',
		DC: '4',
		NOMBRE: 'Riópar',
	},
	{
		CPRO: '02',
		CMUN: '068',
		DC: '0',
		NOMBRE: 'Robledo',
	},
	{
		CPRO: '02',
		CMUN: '069',
		DC: '3',
		NOMBRE: 'Roda, La',
	},
	{
		CPRO: '02',
		CMUN: '070',
		DC: '7',
		NOMBRE: 'Salobre',
	},
	{
		CPRO: '02',
		CMUN: '071',
		DC: '4',
		NOMBRE: 'San Pedro',
	},
	{
		CPRO: '02',
		CMUN: '072',
		DC: '9',
		NOMBRE: 'Socovos',
	},
	{
		CPRO: '02',
		CMUN: '073',
		DC: '5',
		NOMBRE: 'Tarazona de la Mancha',
	},
	{
		CPRO: '02',
		CMUN: '074',
		DC: '0',
		NOMBRE: 'Tobarra',
	},
	{
		CPRO: '02',
		CMUN: '075',
		DC: '3',
		NOMBRE: 'Valdeganga',
	},
	{
		CPRO: '02',
		CMUN: '076',
		DC: '6',
		NOMBRE: 'Vianos',
	},
	{
		CPRO: '02',
		CMUN: '077',
		DC: '2',
		NOMBRE: 'Villa de Ves',
	},
	{
		CPRO: '02',
		CMUN: '078',
		DC: '8',
		NOMBRE: 'Villalgordo del Júcar',
	},
	{
		CPRO: '02',
		CMUN: '079',
		DC: '1',
		NOMBRE: 'Villamalea',
	},
	{
		CPRO: '02',
		CMUN: '080',
		DC: '5',
		NOMBRE: 'Villapalacios',
	},
	{
		CPRO: '02',
		CMUN: '081',
		DC: '2',
		NOMBRE: 'Villarrobledo',
	},
	{
		CPRO: '02',
		CMUN: '082',
		DC: '7',
		NOMBRE: 'Villatoya',
	},
	{
		CPRO: '02',
		CMUN: '083',
		DC: '3',
		NOMBRE: 'Villavaliente',
	},
	{
		CPRO: '02',
		CMUN: '084',
		DC: '8',
		NOMBRE: 'Villaverde de Guadalimar',
	},
	{
		CPRO: '02',
		CMUN: '085',
		DC: '1',
		NOMBRE: 'Viveros',
	},
	{
		CPRO: '02',
		CMUN: '086',
		DC: '4',
		NOMBRE: 'Yeste',
	},
	{
		CPRO: '03',
		CMUN: '001',
		DC: '5',
		NOMBRE: 'Adsubia',
	},
	{
		CPRO: '03',
		CMUN: '002',
		DC: '0',
		NOMBRE: 'Agost',
	},
	{
		CPRO: '03',
		CMUN: '003',
		DC: '6',
		NOMBRE: 'Agres',
	},
	{
		CPRO: '03',
		CMUN: '004',
		DC: '1',
		NOMBRE: 'Aigües',
	},
	{
		CPRO: '03',
		CMUN: '005',
		DC: '4',
		NOMBRE: 'Albatera',
	},
	{
		CPRO: '03',
		CMUN: '006',
		DC: '7',
		NOMBRE: 'Alcalalí',
	},
	{
		CPRO: '03',
		CMUN: '007',
		DC: '3',
		NOMBRE: 'Alcocer de Planes',
	},
	{
		CPRO: '03',
		CMUN: '008',
		DC: '9',
		NOMBRE: 'Alcoleja',
	},
	{
		CPRO: '03',
		CMUN: '009',
		DC: '2',
		NOMBRE: 'Alcoy/Alcoi',
	},
	{
		CPRO: '03',
		CMUN: '010',
		DC: '6',
		NOMBRE: 'Alfafara',
	},
	{
		CPRO: '03',
		CMUN: '011',
		DC: '3',
		NOMBRE: "Alfàs del Pi, l'",
	},
	{
		CPRO: '03',
		CMUN: '012',
		DC: '8',
		NOMBRE: 'Algorfa',
	},
	{
		CPRO: '03',
		CMUN: '013',
		DC: '4',
		NOMBRE: 'Algueña',
	},
	{
		CPRO: '03',
		CMUN: '014',
		DC: '9',
		NOMBRE: 'Alicante/Alacant',
	},
	{
		CPRO: '03',
		CMUN: '015',
		DC: '2',
		NOMBRE: 'Almoradí',
	},
	{
		CPRO: '03',
		CMUN: '016',
		DC: '5',
		NOMBRE: 'Almudaina',
	},
	{
		CPRO: '03',
		CMUN: '017',
		DC: '1',
		NOMBRE: "Alqueria d'Asnar, l'",
	},
	{
		CPRO: '03',
		CMUN: '018',
		DC: '7',
		NOMBRE: 'Altea',
	},
	{
		CPRO: '03',
		CMUN: '019',
		DC: '0',
		NOMBRE: 'Aspe',
	},
	{
		CPRO: '03',
		CMUN: '020',
		DC: '4',
		NOMBRE: 'Balones',
	},
	{
		CPRO: '03',
		CMUN: '021',
		DC: '1',
		NOMBRE: 'Banyeres de Mariola',
	},
	{
		CPRO: '03',
		CMUN: '022',
		DC: '6',
		NOMBRE: 'Benasau',
	},
	{
		CPRO: '03',
		CMUN: '023',
		DC: '2',
		NOMBRE: 'Beneixama',
	},
	{
		CPRO: '03',
		CMUN: '024',
		DC: '7',
		NOMBRE: 'Benejúzar',
	},
	{
		CPRO: '03',
		CMUN: '025',
		DC: '0',
		NOMBRE: 'Benferri',
	},
	{
		CPRO: '03',
		CMUN: '026',
		DC: '3',
		NOMBRE: 'Beniarbeig',
	},
	{
		CPRO: '03',
		CMUN: '027',
		DC: '9',
		NOMBRE: 'Beniardá',
	},
	{
		CPRO: '03',
		CMUN: '028',
		DC: '5',
		NOMBRE: 'Beniarrés',
	},
	{
		CPRO: '03',
		CMUN: '030',
		DC: '2',
		NOMBRE: 'Benidoleig',
	},
	{
		CPRO: '03',
		CMUN: '031',
		DC: '9',
		NOMBRE: 'Benidorm',
	},
	{
		CPRO: '03',
		CMUN: '032',
		DC: '4',
		NOMBRE: 'Benifallim',
	},
	{
		CPRO: '03',
		CMUN: '033',
		DC: '0',
		NOMBRE: 'Benifato',
	},
	{
		CPRO: '03',
		CMUN: '029',
		DC: '8',
		NOMBRE: 'Benigembla',
	},
	{
		CPRO: '03',
		CMUN: '034',
		DC: '5',
		NOMBRE: 'Benijófar',
	},
	{
		CPRO: '03',
		CMUN: '035',
		DC: '8',
		NOMBRE: 'Benilloba',
	},
	{
		CPRO: '03',
		CMUN: '036',
		DC: '1',
		NOMBRE: 'Benillup',
	},
	{
		CPRO: '03',
		CMUN: '037',
		DC: '7',
		NOMBRE: 'Benimantell',
	},
	{
		CPRO: '03',
		CMUN: '038',
		DC: '3',
		NOMBRE: 'Benimarfull',
	},
	{
		CPRO: '03',
		CMUN: '039',
		DC: '6',
		NOMBRE: 'Benimassot',
	},
	{
		CPRO: '03',
		CMUN: '040',
		DC: '0',
		NOMBRE: 'Benimeli',
	},
	{
		CPRO: '03',
		CMUN: '041',
		DC: '7',
		NOMBRE: 'Benissa',
	},
	{
		CPRO: '03',
		CMUN: '042',
		DC: '2',
		NOMBRE: 'Benitachell/Poble Nou de Benitatxell, el',
	},
	{
		CPRO: '03',
		CMUN: '043',
		DC: '8',
		NOMBRE: 'Biar',
	},
	{
		CPRO: '03',
		CMUN: '044',
		DC: '3',
		NOMBRE: 'Bigastro',
	},
	{
		CPRO: '03',
		CMUN: '045',
		DC: '6',
		NOMBRE: 'Bolulla',
	},
	{
		CPRO: '03',
		CMUN: '046',
		DC: '9',
		NOMBRE: 'Busot',
	},
	{
		CPRO: '03',
		CMUN: '049',
		DC: '4',
		NOMBRE: 'Callosa de Segura',
	},
	{
		CPRO: '03',
		CMUN: '048',
		DC: '1',
		NOMBRE: "Callosa d'En Sarrià",
	},
	{
		CPRO: '03',
		CMUN: '047',
		DC: '5',
		NOMBRE: 'Calp',
	},
	{
		CPRO: '03',
		CMUN: '050',
		DC: '7',
		NOMBRE: 'Campello, el',
	},
	{
		CPRO: '03',
		CMUN: '051',
		DC: '4',
		NOMBRE: 'Campo de Mirra/Camp de Mirra, el',
	},
	{
		CPRO: '03',
		CMUN: '052',
		DC: '9',
		NOMBRE: 'Cañada',
	},
	{
		CPRO: '03',
		CMUN: '053',
		DC: '5',
		NOMBRE: 'Castalla',
	},
	{
		CPRO: '03',
		CMUN: '054',
		DC: '0',
		NOMBRE: 'Castell de Castells',
	},
	{
		CPRO: '03',
		CMUN: '075',
		DC: '9',
		NOMBRE: 'Castell de Guadalest, el',
	},
	{
		CPRO: '03',
		CMUN: '055',
		DC: '3',
		NOMBRE: 'Catral',
	},
	{
		CPRO: '03',
		CMUN: '056',
		DC: '6',
		NOMBRE: 'Cocentaina',
	},
	{
		CPRO: '03',
		CMUN: '057',
		DC: '2',
		NOMBRE: 'Confrides',
	},
	{
		CPRO: '03',
		CMUN: '058',
		DC: '8',
		NOMBRE: 'Cox',
	},
	{
		CPRO: '03',
		CMUN: '059',
		DC: '1',
		NOMBRE: 'Crevillent',
	},
	{
		CPRO: '03',
		CMUN: '061',
		DC: '2',
		NOMBRE: 'Daya Nueva',
	},
	{
		CPRO: '03',
		CMUN: '062',
		DC: '7',
		NOMBRE: 'Daya Vieja',
	},
	{
		CPRO: '03',
		CMUN: '063',
		DC: '3',
		NOMBRE: 'Dénia',
	},
	{
		CPRO: '03',
		CMUN: '064',
		DC: '8',
		NOMBRE: 'Dolores',
	},
	{
		CPRO: '03',
		CMUN: '065',
		DC: '1',
		NOMBRE: 'Elche/Elx',
	},
	{
		CPRO: '03',
		CMUN: '066',
		DC: '4',
		NOMBRE: 'Elda',
	},
	{
		CPRO: '03',
		CMUN: '067',
		DC: '0',
		NOMBRE: 'Facheca',
	},
	{
		CPRO: '03',
		CMUN: '068',
		DC: '6',
		NOMBRE: 'Famorca',
	},
	{
		CPRO: '03',
		CMUN: '069',
		DC: '9',
		NOMBRE: 'Finestrat',
	},
	{
		CPRO: '03',
		CMUN: '077',
		DC: '8',
		NOMBRE: 'Fondó de les Neus, el/Hondón de las Nieves',
	},
	{
		CPRO: '03',
		CMUN: '070',
		DC: '3',
		NOMBRE: 'Formentera del Segura',
	},
	{
		CPRO: '03',
		CMUN: '072',
		DC: '5',
		NOMBRE: 'Gaianes',
	},
	{
		CPRO: '03',
		CMUN: '071',
		DC: '0',
		NOMBRE: 'Gata de Gorgos',
	},
	{
		CPRO: '03',
		CMUN: '073',
		DC: '1',
		NOMBRE: 'Gorga',
	},
	{
		CPRO: '03',
		CMUN: '074',
		DC: '6',
		NOMBRE: 'Granja de Rocamora',
	},
	{
		CPRO: '03',
		CMUN: '076',
		DC: '2',
		NOMBRE: 'Guardamar del Segura',
	},
	{
		CPRO: '03',
		CMUN: '078',
		DC: '4',
		NOMBRE: 'Hondón de los Frailes',
	},
	{
		CPRO: '03',
		CMUN: '079',
		DC: '7',
		NOMBRE: 'Ibi',
	},
	{
		CPRO: '03',
		CMUN: '080',
		DC: '1',
		NOMBRE: 'Jacarilla',
	},
	{
		CPRO: '03',
		CMUN: '082',
		DC: '3',
		NOMBRE: 'Jávea/Xàbia',
	},
	{
		CPRO: '03',
		CMUN: '083',
		DC: '9',
		NOMBRE: 'Jijona/Xixona',
	},
	{
		CPRO: '03',
		CMUN: '085',
		DC: '7',
		NOMBRE: 'Llíber',
	},
	{
		CPRO: '03',
		CMUN: '084',
		DC: '4',
		NOMBRE: "Lorcha/Orxa, l'",
	},
	{
		CPRO: '03',
		CMUN: '086',
		DC: '0',
		NOMBRE: 'Millena',
	},
	{
		CPRO: '03',
		CMUN: '088',
		DC: '2',
		NOMBRE: 'Monforte del Cid',
	},
	{
		CPRO: '03',
		CMUN: '089',
		DC: '5',
		NOMBRE: 'Monóvar/Monòver',
	},
	{
		CPRO: '03',
		CMUN: '903',
		DC: '7',
		NOMBRE: 'Montesinos, Los',
	},
	{
		CPRO: '03',
		CMUN: '091',
		DC: '6',
		NOMBRE: 'Murla',
	},
	{
		CPRO: '03',
		CMUN: '092',
		DC: '1',
		NOMBRE: 'Muro de Alcoy',
	},
	{
		CPRO: '03',
		CMUN: '090',
		DC: '9',
		NOMBRE: 'Mutxamel',
	},
	{
		CPRO: '03',
		CMUN: '093',
		DC: '7',
		NOMBRE: 'Novelda',
	},
	{
		CPRO: '03',
		CMUN: '094',
		DC: '2',
		NOMBRE: 'Nucia, la',
	},
	{
		CPRO: '03',
		CMUN: '095',
		DC: '5',
		NOMBRE: 'Ondara',
	},
	{
		CPRO: '03',
		CMUN: '096',
		DC: '8',
		NOMBRE: 'Onil',
	},
	{
		CPRO: '03',
		CMUN: '097',
		DC: '4',
		NOMBRE: 'Orba',
	},
	{
		CPRO: '03',
		CMUN: '099',
		DC: '3',
		NOMBRE: 'Orihuela',
	},
	{
		CPRO: '03',
		CMUN: '098',
		DC: '0',
		NOMBRE: 'Orxeta',
	},
	{
		CPRO: '03',
		CMUN: '100',
		DC: '7',
		NOMBRE: 'Parcent',
	},
	{
		CPRO: '03',
		CMUN: '101',
		DC: '4',
		NOMBRE: 'Pedreguer',
	},
	{
		CPRO: '03',
		CMUN: '102',
		DC: '9',
		NOMBRE: 'Pego',
	},
	{
		CPRO: '03',
		CMUN: '103',
		DC: '5',
		NOMBRE: 'Penàguila',
	},
	{
		CPRO: '03',
		CMUN: '104',
		DC: '0',
		NOMBRE: 'Petrer',
	},
	{
		CPRO: '03',
		CMUN: '902',
		DC: '1',
		NOMBRE: 'Pilar de la Horadada',
	},
	{
		CPRO: '03',
		CMUN: '105',
		DC: '3',
		NOMBRE: 'Pinós, el/Pinoso',
	},
	{
		CPRO: '03',
		CMUN: '106',
		DC: '6',
		NOMBRE: 'Planes',
	},
	{
		CPRO: '03',
		CMUN: '901',
		DC: '6',
		NOMBRE: 'Poblets, els',
	},
	{
		CPRO: '03',
		CMUN: '107',
		DC: '2',
		NOMBRE: 'Polop',
	},
	{
		CPRO: '03',
		CMUN: '060',
		DC: '5',
		NOMBRE: 'Quatretondeta',
	},
	{
		CPRO: '03',
		CMUN: '109',
		DC: '1',
		NOMBRE: 'Rafal',
	},
	{
		CPRO: '03',
		CMUN: '110',
		DC: '5',
		NOMBRE: "Ràfol d'Almúnia, el",
	},
	{
		CPRO: '03',
		CMUN: '111',
		DC: '2',
		NOMBRE: 'Redován',
	},
	{
		CPRO: '03',
		CMUN: '112',
		DC: '7',
		NOMBRE: 'Relleu',
	},
	{
		CPRO: '03',
		CMUN: '113',
		DC: '3',
		NOMBRE: 'Rojales',
	},
	{
		CPRO: '03',
		CMUN: '114',
		DC: '8',
		NOMBRE: 'Romana, la',
	},
	{
		CPRO: '03',
		CMUN: '115',
		DC: '1',
		NOMBRE: 'Sagra',
	},
	{
		CPRO: '03',
		CMUN: '116',
		DC: '4',
		NOMBRE: 'Salinas',
	},
	{
		CPRO: '03',
		CMUN: '118',
		DC: '6',
		NOMBRE: 'San Fulgencio',
	},
	{
		CPRO: '03',
		CMUN: '904',
		DC: '2',
		NOMBRE: 'San Isidro',
	},
	{
		CPRO: '03',
		CMUN: '120',
		DC: '3',
		NOMBRE: 'San Miguel de Salinas',
	},
	{
		CPRO: '03',
		CMUN: '122',
		DC: '5',
		NOMBRE: 'San Vicente del Raspeig/Sant Vicent del Raspeig',
	},
	{
		CPRO: '03',
		CMUN: '117',
		DC: '0',
		NOMBRE: 'Sanet y Negrals',
	},
	{
		CPRO: '03',
		CMUN: '119',
		DC: '9',
		NOMBRE: "Sant Joan d'Alacant",
	},
	{
		CPRO: '03',
		CMUN: '121',
		DC: '0',
		NOMBRE: 'Santa Pola',
	},
	{
		CPRO: '03',
		CMUN: '123',
		DC: '1',
		NOMBRE: 'Sax',
	},
	{
		CPRO: '03',
		CMUN: '124',
		DC: '6',
		NOMBRE: 'Sella',
	},
	{
		CPRO: '03',
		CMUN: '125',
		DC: '9',
		NOMBRE: 'Senija',
	},
	{
		CPRO: '03',
		CMUN: '127',
		DC: '8',
		NOMBRE: 'Tàrbena',
	},
	{
		CPRO: '03',
		CMUN: '128',
		DC: '4',
		NOMBRE: 'Teulada',
	},
	{
		CPRO: '03',
		CMUN: '129',
		DC: '7',
		NOMBRE: 'Tibi',
	},
	{
		CPRO: '03',
		CMUN: '130',
		DC: '1',
		NOMBRE: 'Tollos',
	},
	{
		CPRO: '03',
		CMUN: '131',
		DC: '8',
		NOMBRE: 'Tormos',
	},
	{
		CPRO: '03',
		CMUN: '132',
		DC: '3',
		NOMBRE: 'Torremanzanas/Torre de les Maçanes, la',
	},
	{
		CPRO: '03',
		CMUN: '133',
		DC: '9',
		NOMBRE: 'Torrevieja',
	},
	{
		CPRO: '03',
		CMUN: '134',
		DC: '4',
		NOMBRE: "Vall d'Alcalà, la",
	},
	{
		CPRO: '03',
		CMUN: '136',
		DC: '0',
		NOMBRE: 'Vall de Gallinera',
	},
	{
		CPRO: '03',
		CMUN: '137',
		DC: '6',
		NOMBRE: 'Vall de Laguar, la',
	},
	{
		CPRO: '03',
		CMUN: '135',
		DC: '7',
		NOMBRE: "Vall d'Ebo, la",
	},
	{
		CPRO: '03',
		CMUN: '138',
		DC: '2',
		NOMBRE: 'Verger, el',
	},
	{
		CPRO: '03',
		CMUN: '139',
		DC: '5',
		NOMBRE: 'Villajoyosa/Vila Joiosa, la',
	},
	{
		CPRO: '03',
		CMUN: '140',
		DC: '9',
		NOMBRE: 'Villena',
	},
	{
		CPRO: '03',
		CMUN: '081',
		DC: '8',
		NOMBRE: 'Xaló',
	},
	{
		CPRO: '04',
		CMUN: '001',
		DC: '0',
		NOMBRE: 'Abla',
	},
	{
		CPRO: '04',
		CMUN: '002',
		DC: '5',
		NOMBRE: 'Abrucena',
	},
	{
		CPRO: '04',
		CMUN: '003',
		DC: '1',
		NOMBRE: 'Adra',
	},
	{
		CPRO: '04',
		CMUN: '004',
		DC: '6',
		NOMBRE: 'Albánchez',
	},
	{
		CPRO: '04',
		CMUN: '005',
		DC: '9',
		NOMBRE: 'Alboloduy',
	},
	{
		CPRO: '04',
		CMUN: '006',
		DC: '2',
		NOMBRE: 'Albox',
	},
	{
		CPRO: '04',
		CMUN: '007',
		DC: '8',
		NOMBRE: 'Alcolea',
	},
	{
		CPRO: '04',
		CMUN: '008',
		DC: '4',
		NOMBRE: 'Alcóntar',
	},
	{
		CPRO: '04',
		CMUN: '009',
		DC: '7',
		NOMBRE: 'Alcudia de Monteagud',
	},
	{
		CPRO: '04',
		CMUN: '010',
		DC: '1',
		NOMBRE: 'Alhabia',
	},
	{
		CPRO: '04',
		CMUN: '011',
		DC: '8',
		NOMBRE: 'Alhama de Almería',
	},
	{
		CPRO: '04',
		CMUN: '012',
		DC: '3',
		NOMBRE: 'Alicún',
	},
	{
		CPRO: '04',
		CMUN: '013',
		DC: '9',
		NOMBRE: 'Almería',
	},
	{
		CPRO: '04',
		CMUN: '014',
		DC: '4',
		NOMBRE: 'Almócita',
	},
	{
		CPRO: '04',
		CMUN: '015',
		DC: '7',
		NOMBRE: 'Alsodux',
	},
	{
		CPRO: '04',
		CMUN: '016',
		DC: '0',
		NOMBRE: 'Antas',
	},
	{
		CPRO: '04',
		CMUN: '017',
		DC: '6',
		NOMBRE: 'Arboleas',
	},
	{
		CPRO: '04',
		CMUN: '018',
		DC: '2',
		NOMBRE: 'Armuña de Almanzora',
	},
	{
		CPRO: '04',
		CMUN: '019',
		DC: '5',
		NOMBRE: 'Bacares',
	},
	{
		CPRO: '04',
		CMUN: '020',
		DC: '9',
		NOMBRE: 'Bayárcal',
	},
	{
		CPRO: '04',
		CMUN: '021',
		DC: '6',
		NOMBRE: 'Bayarque',
	},
	{
		CPRO: '04',
		CMUN: '022',
		DC: '1',
		NOMBRE: 'Bédar',
	},
	{
		CPRO: '04',
		CMUN: '023',
		DC: '7',
		NOMBRE: 'Beires',
	},
	{
		CPRO: '04',
		CMUN: '024',
		DC: '2',
		NOMBRE: 'Benahadux',
	},
	{
		CPRO: '04',
		CMUN: '026',
		DC: '8',
		NOMBRE: 'Benitagla',
	},
	{
		CPRO: '04',
		CMUN: '027',
		DC: '4',
		NOMBRE: 'Benizalón',
	},
	{
		CPRO: '04',
		CMUN: '028',
		DC: '0',
		NOMBRE: 'Bentarique',
	},
	{
		CPRO: '04',
		CMUN: '029',
		DC: '3',
		NOMBRE: 'Berja',
	},
	{
		CPRO: '04',
		CMUN: '030',
		DC: '7',
		NOMBRE: 'Canjáyar',
	},
	{
		CPRO: '04',
		CMUN: '031',
		DC: '4',
		NOMBRE: 'Cantoria',
	},
	{
		CPRO: '04',
		CMUN: '032',
		DC: '9',
		NOMBRE: 'Carboneras',
	},
	{
		CPRO: '04',
		CMUN: '033',
		DC: '5',
		NOMBRE: 'Castro de Filabres',
	},
	{
		CPRO: '04',
		CMUN: '036',
		DC: '6',
		NOMBRE: 'Chercos',
	},
	{
		CPRO: '04',
		CMUN: '037',
		DC: '2',
		NOMBRE: 'Chirivel',
	},
	{
		CPRO: '04',
		CMUN: '034',
		DC: '0',
		NOMBRE: 'Cóbdar',
	},
	{
		CPRO: '04',
		CMUN: '035',
		DC: '3',
		NOMBRE: 'Cuevas del Almanzora',
	},
	{
		CPRO: '04',
		CMUN: '038',
		DC: '8',
		NOMBRE: 'Dalías',
	},
	{
		CPRO: '04',
		CMUN: '902',
		DC: '6',
		NOMBRE: 'Ejido, El',
	},
	{
		CPRO: '04',
		CMUN: '041',
		DC: '2',
		NOMBRE: 'Enix',
	},
	{
		CPRO: '04',
		CMUN: '043',
		DC: '3',
		NOMBRE: 'Felix',
	},
	{
		CPRO: '04',
		CMUN: '044',
		DC: '8',
		NOMBRE: 'Fines',
	},
	{
		CPRO: '04',
		CMUN: '045',
		DC: '1',
		NOMBRE: 'Fiñana',
	},
	{
		CPRO: '04',
		CMUN: '046',
		DC: '4',
		NOMBRE: 'Fondón',
	},
	{
		CPRO: '04',
		CMUN: '047',
		DC: '0',
		NOMBRE: 'Gádor',
	},
	{
		CPRO: '04',
		CMUN: '048',
		DC: '6',
		NOMBRE: 'Gallardos, Los',
	},
	{
		CPRO: '04',
		CMUN: '049',
		DC: '9',
		NOMBRE: 'Garrucha',
	},
	{
		CPRO: '04',
		CMUN: '050',
		DC: '2',
		NOMBRE: 'Gérgal',
	},
	{
		CPRO: '04',
		CMUN: '051',
		DC: '9',
		NOMBRE: 'Huécija',
	},
	{
		CPRO: '04',
		CMUN: '052',
		DC: '4',
		NOMBRE: 'Huércal de Almería',
	},
	{
		CPRO: '04',
		CMUN: '053',
		DC: '0',
		NOMBRE: 'Huércal-Overa',
	},
	{
		CPRO: '04',
		CMUN: '054',
		DC: '5',
		NOMBRE: 'Illar',
	},
	{
		CPRO: '04',
		CMUN: '055',
		DC: '8',
		NOMBRE: 'Instinción',
	},
	{
		CPRO: '04',
		CMUN: '056',
		DC: '1',
		NOMBRE: 'Laroya',
	},
	{
		CPRO: '04',
		CMUN: '057',
		DC: '7',
		NOMBRE: 'Láujar de Andarax',
	},
	{
		CPRO: '04',
		CMUN: '058',
		DC: '3',
		NOMBRE: 'Líjar',
	},
	{
		CPRO: '04',
		CMUN: '059',
		DC: '6',
		NOMBRE: 'Lubrín',
	},
	{
		CPRO: '04',
		CMUN: '060',
		DC: '0',
		NOMBRE: 'Lucainena de las Torres',
	},
	{
		CPRO: '04',
		CMUN: '061',
		DC: '7',
		NOMBRE: 'Lúcar',
	},
	{
		CPRO: '04',
		CMUN: '062',
		DC: '2',
		NOMBRE: 'Macael',
	},
	{
		CPRO: '04',
		CMUN: '063',
		DC: '8',
		NOMBRE: 'María',
	},
	{
		CPRO: '04',
		CMUN: '064',
		DC: '3',
		NOMBRE: 'Mojácar',
	},
	{
		CPRO: '04',
		CMUN: '903',
		DC: '2',
		NOMBRE: 'Mojonera, La',
	},
	{
		CPRO: '04',
		CMUN: '065',
		DC: '6',
		NOMBRE: 'Nacimiento',
	},
	{
		CPRO: '04',
		CMUN: '066',
		DC: '9',
		NOMBRE: 'Níjar',
	},
	{
		CPRO: '04',
		CMUN: '067',
		DC: '5',
		NOMBRE: 'Ohanes',
	},
	{
		CPRO: '04',
		CMUN: '068',
		DC: '1',
		NOMBRE: 'Olula de Castro',
	},
	{
		CPRO: '04',
		CMUN: '069',
		DC: '4',
		NOMBRE: 'Olula del Río',
	},
	{
		CPRO: '04',
		CMUN: '070',
		DC: '8',
		NOMBRE: 'Oria',
	},
	{
		CPRO: '04',
		CMUN: '071',
		DC: '5',
		NOMBRE: 'Padules',
	},
	{
		CPRO: '04',
		CMUN: '072',
		DC: '0',
		NOMBRE: 'Partaloa',
	},
	{
		CPRO: '04',
		CMUN: '073',
		DC: '6',
		NOMBRE: 'Paterna del Río',
	},
	{
		CPRO: '04',
		CMUN: '074',
		DC: '1',
		NOMBRE: 'Pechina',
	},
	{
		CPRO: '04',
		CMUN: '075',
		DC: '4',
		NOMBRE: 'Pulpí',
	},
	{
		CPRO: '04',
		CMUN: '076',
		DC: '7',
		NOMBRE: 'Purchena',
	},
	{
		CPRO: '04',
		CMUN: '077',
		DC: '3',
		NOMBRE: 'Rágol',
	},
	{
		CPRO: '04',
		CMUN: '078',
		DC: '9',
		NOMBRE: 'Rioja',
	},
	{
		CPRO: '04',
		CMUN: '079',
		DC: '2',
		NOMBRE: 'Roquetas de Mar',
	},
	{
		CPRO: '04',
		CMUN: '080',
		DC: '6',
		NOMBRE: 'Santa Cruz de Marchena',
	},
	{
		CPRO: '04',
		CMUN: '081',
		DC: '3',
		NOMBRE: 'Santa Fe de Mondújar',
	},
	{
		CPRO: '04',
		CMUN: '082',
		DC: '8',
		NOMBRE: 'Senés',
	},
	{
		CPRO: '04',
		CMUN: '083',
		DC: '4',
		NOMBRE: 'Serón',
	},
	{
		CPRO: '04',
		CMUN: '084',
		DC: '9',
		NOMBRE: 'Sierro',
	},
	{
		CPRO: '04',
		CMUN: '085',
		DC: '2',
		NOMBRE: 'Somontín',
	},
	{
		CPRO: '04',
		CMUN: '086',
		DC: '5',
		NOMBRE: 'Sorbas',
	},
	{
		CPRO: '04',
		CMUN: '087',
		DC: '1',
		NOMBRE: 'Suflí',
	},
	{
		CPRO: '04',
		CMUN: '088',
		DC: '7',
		NOMBRE: 'Tabernas',
	},
	{
		CPRO: '04',
		CMUN: '089',
		DC: '0',
		NOMBRE: 'Taberno',
	},
	{
		CPRO: '04',
		CMUN: '090',
		DC: '4',
		NOMBRE: 'Tahal',
	},
	{
		CPRO: '04',
		CMUN: '091',
		DC: '1',
		NOMBRE: 'Terque',
	},
	{
		CPRO: '04',
		CMUN: '092',
		DC: '6',
		NOMBRE: 'Tíjola',
	},
	{
		CPRO: '04',
		CMUN: '901',
		DC: '1',
		NOMBRE: 'Tres Villas, Las',
	},
	{
		CPRO: '04',
		CMUN: '093',
		DC: '2',
		NOMBRE: 'Turre',
	},
	{
		CPRO: '04',
		CMUN: '094',
		DC: '7',
		NOMBRE: 'Turrillas',
	},
	{
		CPRO: '04',
		CMUN: '095',
		DC: '0',
		NOMBRE: 'Uleila del Campo',
	},
	{
		CPRO: '04',
		CMUN: '096',
		DC: '3',
		NOMBRE: 'Urrácal',
	},
	{
		CPRO: '04',
		CMUN: '097',
		DC: '9',
		NOMBRE: 'Velefique',
	},
	{
		CPRO: '04',
		CMUN: '098',
		DC: '5',
		NOMBRE: 'Vélez-Blanco',
	},
	{
		CPRO: '04',
		CMUN: '099',
		DC: '8',
		NOMBRE: 'Vélez-Rubio',
	},
	{
		CPRO: '04',
		CMUN: '100',
		DC: '2',
		NOMBRE: 'Vera',
	},
	{
		CPRO: '04',
		CMUN: '101',
		DC: '9',
		NOMBRE: 'Viator',
	},
	{
		CPRO: '04',
		CMUN: '102',
		DC: '4',
		NOMBRE: 'Vícar',
	},
	{
		CPRO: '04',
		CMUN: '103',
		DC: '0',
		NOMBRE: 'Zurgena',
	},
	{
		CPRO: '05',
		CMUN: '001',
		DC: '3',
		NOMBRE: 'Adanero',
	},
	{
		CPRO: '05',
		CMUN: '002',
		DC: '8',
		NOMBRE: 'Adrada, La',
	},
	{
		CPRO: '05',
		CMUN: '005',
		DC: '2',
		NOMBRE: 'Albornos',
	},
	{
		CPRO: '05',
		CMUN: '007',
		DC: '1',
		NOMBRE: 'Aldeanueva de Santa Cruz',
	},
	{
		CPRO: '05',
		CMUN: '008',
		DC: '7',
		NOMBRE: 'Aldeaseca',
	},
	{
		CPRO: '05',
		CMUN: '010',
		DC: '4',
		NOMBRE: 'Aldehuela, La',
	},
	{
		CPRO: '05',
		CMUN: '012',
		DC: '6',
		NOMBRE: 'Amavida',
	},
	{
		CPRO: '05',
		CMUN: '013',
		DC: '2',
		NOMBRE: 'Arenal, El',
	},
	{
		CPRO: '05',
		CMUN: '014',
		DC: '7',
		NOMBRE: 'Arenas de San Pedro',
	},
	{
		CPRO: '05',
		CMUN: '015',
		DC: '0',
		NOMBRE: 'Arevalillo',
	},
	{
		CPRO: '05',
		CMUN: '016',
		DC: '3',
		NOMBRE: 'Arévalo',
	},
	{
		CPRO: '05',
		CMUN: '017',
		DC: '9',
		NOMBRE: 'Aveinte',
	},
	{
		CPRO: '05',
		CMUN: '018',
		DC: '5',
		NOMBRE: 'Avellaneda',
	},
	{
		CPRO: '05',
		CMUN: '019',
		DC: '8',
		NOMBRE: 'Ávila',
	},
	{
		CPRO: '05',
		CMUN: '021',
		DC: '9',
		NOMBRE: 'Barco de Ávila, El',
	},
	{
		CPRO: '05',
		CMUN: '022',
		DC: '4',
		NOMBRE: 'Barraco, El',
	},
	{
		CPRO: '05',
		CMUN: '023',
		DC: '0',
		NOMBRE: 'Barromán',
	},
	{
		CPRO: '05',
		CMUN: '024',
		DC: '5',
		NOMBRE: 'Becedas',
	},
	{
		CPRO: '05',
		CMUN: '025',
		DC: '8',
		NOMBRE: 'Becedillas',
	},
	{
		CPRO: '05',
		CMUN: '026',
		DC: '1',
		NOMBRE: 'Bercial de Zapardiel',
	},
	{
		CPRO: '05',
		CMUN: '027',
		DC: '7',
		NOMBRE: 'Berlanas, Las',
	},
	{
		CPRO: '05',
		CMUN: '029',
		DC: '6',
		NOMBRE: 'Bernuy-Zapardiel',
	},
	{
		CPRO: '05',
		CMUN: '030',
		DC: '0',
		NOMBRE: 'Berrocalejo de Aragona',
	},
	{
		CPRO: '05',
		CMUN: '033',
		DC: '8',
		NOMBRE: 'Blascomillán',
	},
	{
		CPRO: '05',
		CMUN: '034',
		DC: '3',
		NOMBRE: 'Blasconuño de Matacabras',
	},
	{
		CPRO: '05',
		CMUN: '035',
		DC: '6',
		NOMBRE: 'Blascosancho',
	},
	{
		CPRO: '05',
		CMUN: '036',
		DC: '9',
		NOMBRE: 'Bohodón, El',
	},
	{
		CPRO: '05',
		CMUN: '037',
		DC: '5',
		NOMBRE: 'Bohoyo',
	},
	{
		CPRO: '05',
		CMUN: '038',
		DC: '1',
		NOMBRE: 'Bonilla de la Sierra',
	},
	{
		CPRO: '05',
		CMUN: '039',
		DC: '4',
		NOMBRE: 'Brabos',
	},
	{
		CPRO: '05',
		CMUN: '040',
		DC: '8',
		NOMBRE: 'Bularros',
	},
	{
		CPRO: '05',
		CMUN: '041',
		DC: '5',
		NOMBRE: 'Burgohondo',
	},
	{
		CPRO: '05',
		CMUN: '042',
		DC: '0',
		NOMBRE: 'Cabezas de Alambre',
	},
	{
		CPRO: '05',
		CMUN: '043',
		DC: '6',
		NOMBRE: 'Cabezas del Pozo',
	},
	{
		CPRO: '05',
		CMUN: '044',
		DC: '1',
		NOMBRE: 'Cabezas del Villar',
	},
	{
		CPRO: '05',
		CMUN: '045',
		DC: '4',
		NOMBRE: 'Cabizuela',
	},
	{
		CPRO: '05',
		CMUN: '046',
		DC: '7',
		NOMBRE: 'Canales',
	},
	{
		CPRO: '05',
		CMUN: '047',
		DC: '3',
		NOMBRE: 'Candeleda',
	},
	{
		CPRO: '05',
		CMUN: '048',
		DC: '9',
		NOMBRE: 'Cantiveros',
	},
	{
		CPRO: '05',
		CMUN: '049',
		DC: '2',
		NOMBRE: 'Cardeñosa',
	},
	{
		CPRO: '05',
		CMUN: '051',
		DC: '2',
		NOMBRE: 'Carrera, La',
	},
	{
		CPRO: '05',
		CMUN: '052',
		DC: '7',
		NOMBRE: 'Casas del Puerto',
	},
	{
		CPRO: '05',
		CMUN: '053',
		DC: '3',
		NOMBRE: 'Casasola',
	},
	{
		CPRO: '05',
		CMUN: '054',
		DC: '8',
		NOMBRE: 'Casavieja',
	},
	{
		CPRO: '05',
		CMUN: '055',
		DC: '1',
		NOMBRE: 'Casillas',
	},
	{
		CPRO: '05',
		CMUN: '056',
		DC: '4',
		NOMBRE: 'Castellanos de Zapardiel',
	},
	{
		CPRO: '05',
		CMUN: '057',
		DC: '0',
		NOMBRE: 'Cebreros',
	},
	{
		CPRO: '05',
		CMUN: '058',
		DC: '6',
		NOMBRE: 'Cepeda la Mora',
	},
	{
		CPRO: '05',
		CMUN: '067',
		DC: '8',
		NOMBRE: 'Chamartín',
	},
	{
		CPRO: '05',
		CMUN: '059',
		DC: '9',
		NOMBRE: 'Cillán',
	},
	{
		CPRO: '05',
		CMUN: '060',
		DC: '3',
		NOMBRE: 'Cisla',
	},
	{
		CPRO: '05',
		CMUN: '061',
		DC: '0',
		NOMBRE: 'Colilla, La',
	},
	{
		CPRO: '05',
		CMUN: '062',
		DC: '5',
		NOMBRE: 'Collado de Contreras',
	},
	{
		CPRO: '05',
		CMUN: '063',
		DC: '1',
		NOMBRE: 'Collado del Mirón',
	},
	{
		CPRO: '05',
		CMUN: '064',
		DC: '6',
		NOMBRE: 'Constanzana',
	},
	{
		CPRO: '05',
		CMUN: '065',
		DC: '9',
		NOMBRE: 'Crespos',
	},
	{
		CPRO: '05',
		CMUN: '066',
		DC: '2',
		NOMBRE: 'Cuevas del Valle',
	},
	{
		CPRO: '05',
		CMUN: '903',
		DC: '5',
		NOMBRE: 'Diego del Carpio',
	},
	{
		CPRO: '05',
		CMUN: '069',
		DC: '7',
		NOMBRE: 'Donjimeno',
	},
	{
		CPRO: '05',
		CMUN: '070',
		DC: '1',
		NOMBRE: 'Donvidas',
	},
	{
		CPRO: '05',
		CMUN: '072',
		DC: '3',
		NOMBRE: 'Espinosa de los Caballeros',
	},
	{
		CPRO: '05',
		CMUN: '073',
		DC: '9',
		NOMBRE: 'Flores de Ávila',
	},
	{
		CPRO: '05',
		CMUN: '074',
		DC: '4',
		NOMBRE: 'Fontiveros',
	},
	{
		CPRO: '05',
		CMUN: '075',
		DC: '7',
		NOMBRE: 'Fresnedilla',
	},
	{
		CPRO: '05',
		CMUN: '076',
		DC: '0',
		NOMBRE: 'Fresno, El',
	},
	{
		CPRO: '05',
		CMUN: '077',
		DC: '6',
		NOMBRE: 'Fuente el Saúz',
	},
	{
		CPRO: '05',
		CMUN: '078',
		DC: '2',
		NOMBRE: 'Fuentes de Año',
	},
	{
		CPRO: '05',
		CMUN: '079',
		DC: '5',
		NOMBRE: 'Gallegos de Altamiros',
	},
	{
		CPRO: '05',
		CMUN: '080',
		DC: '9',
		NOMBRE: 'Gallegos de Sobrinos',
	},
	{
		CPRO: '05',
		CMUN: '081',
		DC: '6',
		NOMBRE: 'Garganta del Villar',
	},
	{
		CPRO: '05',
		CMUN: '082',
		DC: '1',
		NOMBRE: 'Gavilanes',
	},
	{
		CPRO: '05',
		CMUN: '083',
		DC: '7',
		NOMBRE: 'Gemuño',
	},
	{
		CPRO: '05',
		CMUN: '085',
		DC: '5',
		NOMBRE: 'Gil García',
	},
	{
		CPRO: '05',
		CMUN: '084',
		DC: '2',
		NOMBRE: 'Gilbuena',
	},
	{
		CPRO: '05',
		CMUN: '086',
		DC: '8',
		NOMBRE: 'Gimialcón',
	},
	{
		CPRO: '05',
		CMUN: '087',
		DC: '4',
		NOMBRE: 'Gotarrendura',
	},
	{
		CPRO: '05',
		CMUN: '088',
		DC: '0',
		NOMBRE: 'Grandes y San Martín',
	},
	{
		CPRO: '05',
		CMUN: '089',
		DC: '3',
		NOMBRE: 'Guisando',
	},
	{
		CPRO: '05',
		CMUN: '090',
		DC: '7',
		NOMBRE: 'Gutierre-Muñoz',
	},
	{
		CPRO: '05',
		CMUN: '092',
		DC: '9',
		NOMBRE: 'Hernansancho',
	},
	{
		CPRO: '05',
		CMUN: '093',
		DC: '5',
		NOMBRE: 'Herradón de Pinares',
	},
	{
		CPRO: '05',
		CMUN: '094',
		DC: '0',
		NOMBRE: 'Herreros de Suso',
	},
	{
		CPRO: '05',
		CMUN: '095',
		DC: '3',
		NOMBRE: 'Higuera de las Dueñas',
	},
	{
		CPRO: '05',
		CMUN: '096',
		DC: '6',
		NOMBRE: 'Hija de Dios, La',
	},
	{
		CPRO: '05',
		CMUN: '097',
		DC: '2',
		NOMBRE: 'Horcajada, La',
	},
	{
		CPRO: '05',
		CMUN: '099',
		DC: '1',
		NOMBRE: 'Horcajo de las Torres',
	},
	{
		CPRO: '05',
		CMUN: '100',
		DC: '5',
		NOMBRE: 'Hornillo, El',
	},
	{
		CPRO: '05',
		CMUN: '102',
		DC: '7',
		NOMBRE: 'Hoyo de Pinares, El',
	},
	{
		CPRO: '05',
		CMUN: '101',
		DC: '2',
		NOMBRE: 'Hoyocasero',
	},
	{
		CPRO: '05',
		CMUN: '103',
		DC: '3',
		NOMBRE: 'Hoyorredondo',
	},
	{
		CPRO: '05',
		CMUN: '106',
		DC: '4',
		NOMBRE: 'Hoyos de Miguel Muñoz',
	},
	{
		CPRO: '05',
		CMUN: '104',
		DC: '8',
		NOMBRE: 'Hoyos del Collado',
	},
	{
		CPRO: '05',
		CMUN: '105',
		DC: '1',
		NOMBRE: 'Hoyos del Espino',
	},
	{
		CPRO: '05',
		CMUN: '107',
		DC: '0',
		NOMBRE: 'Hurtumpascual',
	},
	{
		CPRO: '05',
		CMUN: '108',
		DC: '6',
		NOMBRE: 'Junciana',
	},
	{
		CPRO: '05',
		CMUN: '109',
		DC: '9',
		NOMBRE: 'Langa',
	},
	{
		CPRO: '05',
		CMUN: '110',
		DC: '3',
		NOMBRE: 'Lanzahíta',
	},
	{
		CPRO: '05',
		CMUN: '113',
		DC: '1',
		NOMBRE: 'Llanos de Tormes, Los',
	},
	{
		CPRO: '05',
		CMUN: '112',
		DC: '5',
		NOMBRE: 'Losar del Barco, El',
	},
	{
		CPRO: '05',
		CMUN: '114',
		DC: '6',
		NOMBRE: 'Madrigal de las Altas Torres',
	},
	{
		CPRO: '05',
		CMUN: '115',
		DC: '9',
		NOMBRE: 'Maello',
	},
	{
		CPRO: '05',
		CMUN: '116',
		DC: '2',
		NOMBRE: 'Malpartida de Corneja',
	},
	{
		CPRO: '05',
		CMUN: '117',
		DC: '8',
		NOMBRE: 'Mamblas',
	},
	{
		CPRO: '05',
		CMUN: '118',
		DC: '4',
		NOMBRE: 'Mancera de Arriba',
	},
	{
		CPRO: '05',
		CMUN: '119',
		DC: '7',
		NOMBRE: 'Manjabálago',
	},
	{
		CPRO: '05',
		CMUN: '120',
		DC: '1',
		NOMBRE: 'Marlín',
	},
	{
		CPRO: '05',
		CMUN: '121',
		DC: '8',
		NOMBRE: 'Martiherrero',
	},
	{
		CPRO: '05',
		CMUN: '122',
		DC: '3',
		NOMBRE: 'Martínez',
	},
	{
		CPRO: '05',
		CMUN: '123',
		DC: '9',
		NOMBRE: 'Mediana de Voltoya',
	},
	{
		CPRO: '05',
		CMUN: '124',
		DC: '4',
		NOMBRE: 'Medinilla',
	},
	{
		CPRO: '05',
		CMUN: '125',
		DC: '7',
		NOMBRE: 'Mengamuñoz',
	},
	{
		CPRO: '05',
		CMUN: '126',
		DC: '0',
		NOMBRE: 'Mesegar de Corneja',
	},
	{
		CPRO: '05',
		CMUN: '127',
		DC: '6',
		NOMBRE: 'Mijares',
	},
	{
		CPRO: '05',
		CMUN: '128',
		DC: '2',
		NOMBRE: 'Mingorría',
	},
	{
		CPRO: '05',
		CMUN: '129',
		DC: '5',
		NOMBRE: 'Mirón, El',
	},
	{
		CPRO: '05',
		CMUN: '130',
		DC: '9',
		NOMBRE: 'Mironcillo',
	},
	{
		CPRO: '05',
		CMUN: '131',
		DC: '6',
		NOMBRE: 'Mirueña de los Infanzones',
	},
	{
		CPRO: '05',
		CMUN: '132',
		DC: '1',
		NOMBRE: 'Mombeltrán',
	},
	{
		CPRO: '05',
		CMUN: '133',
		DC: '7',
		NOMBRE: 'Monsalupe',
	},
	{
		CPRO: '05',
		CMUN: '134',
		DC: '2',
		NOMBRE: 'Moraleja de Matacabras',
	},
	{
		CPRO: '05',
		CMUN: '135',
		DC: '5',
		NOMBRE: 'Muñana',
	},
	{
		CPRO: '05',
		CMUN: '136',
		DC: '8',
		NOMBRE: 'Muñico',
	},
	{
		CPRO: '05',
		CMUN: '138',
		DC: '0',
		NOMBRE: 'Muñogalindo',
	},
	{
		CPRO: '05',
		CMUN: '139',
		DC: '3',
		NOMBRE: 'Muñogrande',
	},
	{
		CPRO: '05',
		CMUN: '140',
		DC: '7',
		NOMBRE: 'Muñomer del Peco',
	},
	{
		CPRO: '05',
		CMUN: '141',
		DC: '4',
		NOMBRE: 'Muñopepe',
	},
	{
		CPRO: '05',
		CMUN: '142',
		DC: '9',
		NOMBRE: 'Muñosancho',
	},
	{
		CPRO: '05',
		CMUN: '143',
		DC: '5',
		NOMBRE: 'Muñotello',
	},
	{
		CPRO: '05',
		CMUN: '144',
		DC: '0',
		NOMBRE: 'Narrillos del Álamo',
	},
	{
		CPRO: '05',
		CMUN: '145',
		DC: '3',
		NOMBRE: 'Narrillos del Rebollar',
	},
	{
		CPRO: '05',
		CMUN: '149',
		DC: '1',
		NOMBRE: 'Narros de Saldueña',
	},
	{
		CPRO: '05',
		CMUN: '147',
		DC: '2',
		NOMBRE: 'Narros del Castillo',
	},
	{
		CPRO: '05',
		CMUN: '148',
		DC: '8',
		NOMBRE: 'Narros del Puerto',
	},
	{
		CPRO: '05',
		CMUN: '152',
		DC: '6',
		NOMBRE: 'Nava de Arévalo',
	},
	{
		CPRO: '05',
		CMUN: '153',
		DC: '2',
		NOMBRE: 'Nava del Barco',
	},
	{
		CPRO: '05',
		CMUN: '151',
		DC: '1',
		NOMBRE: 'Navacepedilla de Corneja',
	},
	{
		CPRO: '05',
		CMUN: '154',
		DC: '7',
		NOMBRE: 'Navadijos',
	},
	{
		CPRO: '05',
		CMUN: '155',
		DC: '0',
		NOMBRE: 'Navaescurial',
	},
	{
		CPRO: '05',
		CMUN: '156',
		DC: '3',
		NOMBRE: 'Navahondilla',
	},
	{
		CPRO: '05',
		CMUN: '157',
		DC: '9',
		NOMBRE: 'Navalacruz',
	},
	{
		CPRO: '05',
		CMUN: '158',
		DC: '5',
		NOMBRE: 'Navalmoral',
	},
	{
		CPRO: '05',
		CMUN: '159',
		DC: '8',
		NOMBRE: 'Navalonguilla',
	},
	{
		CPRO: '05',
		CMUN: '160',
		DC: '2',
		NOMBRE: 'Navalosa',
	},
	{
		CPRO: '05',
		CMUN: '161',
		DC: '9',
		NOMBRE: 'Navalperal de Pinares',
	},
	{
		CPRO: '05',
		CMUN: '162',
		DC: '4',
		NOMBRE: 'Navalperal de Tormes',
	},
	{
		CPRO: '05',
		CMUN: '163',
		DC: '0',
		NOMBRE: 'Navaluenga',
	},
	{
		CPRO: '05',
		CMUN: '164',
		DC: '5',
		NOMBRE: 'Navaquesera',
	},
	{
		CPRO: '05',
		CMUN: '165',
		DC: '8',
		NOMBRE: 'Navarredonda de Gredos',
	},
	{
		CPRO: '05',
		CMUN: '166',
		DC: '1',
		NOMBRE: 'Navarredondilla',
	},
	{
		CPRO: '05',
		CMUN: '167',
		DC: '7',
		NOMBRE: 'Navarrevisca',
	},
	{
		CPRO: '05',
		CMUN: '168',
		DC: '3',
		NOMBRE: 'Navas del Marqués, Las',
	},
	{
		CPRO: '05',
		CMUN: '169',
		DC: '6',
		NOMBRE: 'Navatalgordo',
	},
	{
		CPRO: '05',
		CMUN: '170',
		DC: '0',
		NOMBRE: 'Navatejares',
	},
	{
		CPRO: '05',
		CMUN: '171',
		DC: '7',
		NOMBRE: 'Neila de San Miguel',
	},
	{
		CPRO: '05',
		CMUN: '172',
		DC: '2',
		NOMBRE: 'Niharra',
	},
	{
		CPRO: '05',
		CMUN: '173',
		DC: '8',
		NOMBRE: 'Ojos-Albos',
	},
	{
		CPRO: '05',
		CMUN: '174',
		DC: '3',
		NOMBRE: 'Orbita',
	},
	{
		CPRO: '05',
		CMUN: '175',
		DC: '6',
		NOMBRE: 'Oso, El',
	},
	{
		CPRO: '05',
		CMUN: '176',
		DC: '9',
		NOMBRE: 'Padiernos',
	},
	{
		CPRO: '05',
		CMUN: '177',
		DC: '5',
		NOMBRE: 'Pajares de Adaja',
	},
	{
		CPRO: '05',
		CMUN: '178',
		DC: '1',
		NOMBRE: 'Palacios de Goda',
	},
	{
		CPRO: '05',
		CMUN: '179',
		DC: '4',
		NOMBRE: 'Papatrigo',
	},
	{
		CPRO: '05',
		CMUN: '180',
		DC: '8',
		NOMBRE: 'Parral, El',
	},
	{
		CPRO: '05',
		CMUN: '181',
		DC: '5',
		NOMBRE: 'Pascualcobo',
	},
	{
		CPRO: '05',
		CMUN: '182',
		DC: '0',
		NOMBRE: 'Pedro Bernardo',
	},
	{
		CPRO: '05',
		CMUN: '183',
		DC: '6',
		NOMBRE: 'Pedro-Rodríguez',
	},
	{
		CPRO: '05',
		CMUN: '184',
		DC: '1',
		NOMBRE: 'Peguerinos',
	},
	{
		CPRO: '05',
		CMUN: '185',
		DC: '4',
		NOMBRE: 'Peñalba de Ávila',
	},
	{
		CPRO: '05',
		CMUN: '186',
		DC: '7',
		NOMBRE: 'Piedrahíta',
	},
	{
		CPRO: '05',
		CMUN: '187',
		DC: '3',
		NOMBRE: 'Piedralaves',
	},
	{
		CPRO: '05',
		CMUN: '188',
		DC: '9',
		NOMBRE: 'Poveda',
	},
	{
		CPRO: '05',
		CMUN: '189',
		DC: '2',
		NOMBRE: 'Poyales del Hoyo',
	},
	{
		CPRO: '05',
		CMUN: '190',
		DC: '6',
		NOMBRE: 'Pozanco',
	},
	{
		CPRO: '05',
		CMUN: '191',
		DC: '3',
		NOMBRE: 'Pradosegar',
	},
	{
		CPRO: '05',
		CMUN: '192',
		DC: '8',
		NOMBRE: 'Puerto Castilla',
	},
	{
		CPRO: '05',
		CMUN: '193',
		DC: '4',
		NOMBRE: 'Rasueros',
	},
	{
		CPRO: '05',
		CMUN: '194',
		DC: '9',
		NOMBRE: 'Riocabado',
	},
	{
		CPRO: '05',
		CMUN: '195',
		DC: '2',
		NOMBRE: 'Riofrío',
	},
	{
		CPRO: '05',
		CMUN: '196',
		DC: '5',
		NOMBRE: 'Rivilla de Barajas',
	},
	{
		CPRO: '05',
		CMUN: '197',
		DC: '1',
		NOMBRE: 'Salobral',
	},
	{
		CPRO: '05',
		CMUN: '198',
		DC: '7',
		NOMBRE: 'Salvadiós',
	},
	{
		CPRO: '05',
		CMUN: '199',
		DC: '0',
		NOMBRE: 'San Bartolomé de Béjar',
	},
	{
		CPRO: '05',
		CMUN: '200',
		DC: '4',
		NOMBRE: 'San Bartolomé de Corneja',
	},
	{
		CPRO: '05',
		CMUN: '201',
		DC: '1',
		NOMBRE: 'San Bartolomé de Pinares',
	},
	{
		CPRO: '05',
		CMUN: '206',
		DC: '3',
		NOMBRE: 'San Esteban de los Patos',
	},
	{
		CPRO: '05',
		CMUN: '208',
		DC: '5',
		NOMBRE: 'San Esteban de Zapardiel',
	},
	{
		CPRO: '05',
		CMUN: '207',
		DC: '9',
		NOMBRE: 'San Esteban del Valle',
	},
	{
		CPRO: '05',
		CMUN: '209',
		DC: '8',
		NOMBRE: 'San García de Ingelmos',
	},
	{
		CPRO: '05',
		CMUN: '901',
		DC: '4',
		NOMBRE: 'San Juan de Gredos',
	},
	{
		CPRO: '05',
		CMUN: '210',
		DC: '2',
		NOMBRE: 'San Juan de la Encinilla',
	},
	{
		CPRO: '05',
		CMUN: '211',
		DC: '9',
		NOMBRE: 'San Juan de la Nava',
	},
	{
		CPRO: '05',
		CMUN: '212',
		DC: '4',
		NOMBRE: 'San Juan del Molinillo',
	},
	{
		CPRO: '05',
		CMUN: '213',
		DC: '0',
		NOMBRE: 'San Juan del Olmo',
	},
	{
		CPRO: '05',
		CMUN: '214',
		DC: '5',
		NOMBRE: 'San Lorenzo de Tormes',
	},
	{
		CPRO: '05',
		CMUN: '215',
		DC: '8',
		NOMBRE: 'San Martín de la Vega del Alberche',
	},
	{
		CPRO: '05',
		CMUN: '216',
		DC: '1',
		NOMBRE: 'San Martín del Pimpollar',
	},
	{
		CPRO: '05',
		CMUN: '217',
		DC: '7',
		NOMBRE: 'San Miguel de Corneja',
	},
	{
		CPRO: '05',
		CMUN: '218',
		DC: '3',
		NOMBRE: 'San Miguel de Serrezuela',
	},
	{
		CPRO: '05',
		CMUN: '219',
		DC: '6',
		NOMBRE: 'San Pascual',
	},
	{
		CPRO: '05',
		CMUN: '220',
		DC: '0',
		NOMBRE: 'San Pedro del Arroyo',
	},
	{
		CPRO: '05',
		CMUN: '231',
		DC: '5',
		NOMBRE: 'San Vicente de Arévalo',
	},
	{
		CPRO: '05',
		CMUN: '204',
		DC: '7',
		NOMBRE: 'Sanchidrián',
	},
	{
		CPRO: '05',
		CMUN: '205',
		DC: '0',
		NOMBRE: 'Sanchorreja',
	},
	{
		CPRO: '05',
		CMUN: '222',
		DC: '2',
		NOMBRE: 'Santa Cruz de Pinares',
	},
	{
		CPRO: '05',
		CMUN: '221',
		DC: '7',
		NOMBRE: 'Santa Cruz del Valle',
	},
	{
		CPRO: '05',
		CMUN: '226',
		DC: '9',
		NOMBRE: 'Santa María de los Caballeros',
	},
	{
		CPRO: '05',
		CMUN: '224',
		DC: '3',
		NOMBRE: 'Santa María del Arroyo',
	},
	{
		CPRO: '05',
		CMUN: '225',
		DC: '6',
		NOMBRE: 'Santa María del Berrocal',
	},
	{
		CPRO: '05',
		CMUN: '902',
		DC: '9',
		NOMBRE: 'Santa María del Cubillo',
	},
	{
		CPRO: '05',
		CMUN: '227',
		DC: '5',
		NOMBRE: 'Santa María del Tiétar',
	},
	{
		CPRO: '05',
		CMUN: '228',
		DC: '1',
		NOMBRE: 'Santiago del Collado',
	},
	{
		CPRO: '05',
		CMUN: '904',
		DC: '0',
		NOMBRE: 'Santiago del Tormes',
	},
	{
		CPRO: '05',
		CMUN: '229',
		DC: '4',
		NOMBRE: 'Santo Domingo de las Posadas',
	},
	{
		CPRO: '05',
		CMUN: '230',
		DC: '8',
		NOMBRE: 'Santo Tomé de Zabarcos',
	},
	{
		CPRO: '05',
		CMUN: '232',
		DC: '0',
		NOMBRE: 'Serrada, La',
	},
	{
		CPRO: '05',
		CMUN: '233',
		DC: '6',
		NOMBRE: 'Serranillos',
	},
	{
		CPRO: '05',
		CMUN: '234',
		DC: '1',
		NOMBRE: 'Sigeres',
	},
	{
		CPRO: '05',
		CMUN: '235',
		DC: '4',
		NOMBRE: 'Sinlabajos',
	},
	{
		CPRO: '05',
		CMUN: '236',
		DC: '7',
		NOMBRE: 'Solana de Ávila',
	},
	{
		CPRO: '05',
		CMUN: '237',
		DC: '3',
		NOMBRE: 'Solana de Rioalmar',
	},
	{
		CPRO: '05',
		CMUN: '238',
		DC: '9',
		NOMBRE: 'Solosancho',
	},
	{
		CPRO: '05',
		CMUN: '239',
		DC: '2',
		NOMBRE: 'Sotalbo',
	},
	{
		CPRO: '05',
		CMUN: '240',
		DC: '6',
		NOMBRE: 'Sotillo de la Adrada',
	},
	{
		CPRO: '05',
		CMUN: '241',
		DC: '3',
		NOMBRE: 'Tiemblo, El',
	},
	{
		CPRO: '05',
		CMUN: '242',
		DC: '8',
		NOMBRE: 'Tiñosillos',
	},
	{
		CPRO: '05',
		CMUN: '243',
		DC: '4',
		NOMBRE: 'Tolbaños',
	},
	{
		CPRO: '05',
		CMUN: '244',
		DC: '9',
		NOMBRE: 'Tormellas',
	},
	{
		CPRO: '05',
		CMUN: '245',
		DC: '2',
		NOMBRE: 'Tornadizos de Ávila',
	},
	{
		CPRO: '05',
		CMUN: '247',
		DC: '1',
		NOMBRE: 'Torre, La',
	},
	{
		CPRO: '05',
		CMUN: '246',
		DC: '5',
		NOMBRE: 'Tórtoles',
	},
	{
		CPRO: '05',
		CMUN: '249',
		DC: '0',
		NOMBRE: 'Umbrías',
	},
	{
		CPRO: '05',
		CMUN: '251',
		DC: '0',
		NOMBRE: 'Vadillo de la Sierra',
	},
	{
		CPRO: '05',
		CMUN: '252',
		DC: '5',
		NOMBRE: 'Valdecasa',
	},
	{
		CPRO: '05',
		CMUN: '253',
		DC: '1',
		NOMBRE: 'Vega de Santa María',
	},
	{
		CPRO: '05',
		CMUN: '254',
		DC: '6',
		NOMBRE: 'Velayos',
	},
	{
		CPRO: '05',
		CMUN: '256',
		DC: '2',
		NOMBRE: 'Villaflor',
	},
	{
		CPRO: '05',
		CMUN: '257',
		DC: '8',
		NOMBRE: 'Villafranca de la Sierra',
	},
	{
		CPRO: '05',
		CMUN: '905',
		DC: '3',
		NOMBRE: 'Villanueva de Ávila',
	},
	{
		CPRO: '05',
		CMUN: '258',
		DC: '4',
		NOMBRE: 'Villanueva de Gómez',
	},
	{
		CPRO: '05',
		CMUN: '259',
		DC: '7',
		NOMBRE: 'Villanueva del Aceral',
	},
	{
		CPRO: '05',
		CMUN: '260',
		DC: '1',
		NOMBRE: 'Villanueva del Campillo',
	},
	{
		CPRO: '05',
		CMUN: '261',
		DC: '8',
		NOMBRE: 'Villar de Corneja',
	},
	{
		CPRO: '05',
		CMUN: '262',
		DC: '3',
		NOMBRE: 'Villarejo del Valle',
	},
	{
		CPRO: '05',
		CMUN: '263',
		DC: '9',
		NOMBRE: 'Villatoro',
	},
	{
		CPRO: '05',
		CMUN: '264',
		DC: '4',
		NOMBRE: 'Viñegra de Moraña',
	},
	{
		CPRO: '05',
		CMUN: '265',
		DC: '7',
		NOMBRE: 'Vita',
	},
	{
		CPRO: '05',
		CMUN: '266',
		DC: '0',
		NOMBRE: 'Zapardiel de la Cañada',
	},
	{
		CPRO: '05',
		CMUN: '267',
		DC: '6',
		NOMBRE: 'Zapardiel de la Ribera',
	},
	{
		CPRO: '06',
		CMUN: '001',
		DC: '6',
		NOMBRE: 'Acedera',
	},
	{
		CPRO: '06',
		CMUN: '002',
		DC: '1',
		NOMBRE: 'Aceuchal',
	},
	{
		CPRO: '06',
		CMUN: '003',
		DC: '7',
		NOMBRE: 'Ahillones',
	},
	{
		CPRO: '06',
		CMUN: '004',
		DC: '2',
		NOMBRE: 'Alange',
	},
	{
		CPRO: '06',
		CMUN: '005',
		DC: '5',
		NOMBRE: 'Albuera, La',
	},
	{
		CPRO: '06',
		CMUN: '006',
		DC: '8',
		NOMBRE: 'Alburquerque',
	},
	{
		CPRO: '06',
		CMUN: '007',
		DC: '4',
		NOMBRE: 'Alconchel',
	},
	{
		CPRO: '06',
		CMUN: '008',
		DC: '0',
		NOMBRE: 'Alconera',
	},
	{
		CPRO: '06',
		CMUN: '009',
		DC: '3',
		NOMBRE: 'Aljucén',
	},
	{
		CPRO: '06',
		CMUN: '010',
		DC: '7',
		NOMBRE: 'Almendral',
	},
	{
		CPRO: '06',
		CMUN: '011',
		DC: '4',
		NOMBRE: 'Almendralejo',
	},
	{
		CPRO: '06',
		CMUN: '012',
		DC: '9',
		NOMBRE: 'Arroyo de San Serván',
	},
	{
		CPRO: '06',
		CMUN: '013',
		DC: '5',
		NOMBRE: 'Atalaya',
	},
	{
		CPRO: '06',
		CMUN: '014',
		DC: '0',
		NOMBRE: 'Azuaga',
	},
	{
		CPRO: '06',
		CMUN: '015',
		DC: '3',
		NOMBRE: 'Badajoz',
	},
	{
		CPRO: '06',
		CMUN: '016',
		DC: '6',
		NOMBRE: 'Barcarrota',
	},
	{
		CPRO: '06',
		CMUN: '017',
		DC: '2',
		NOMBRE: 'Baterno',
	},
	{
		CPRO: '06',
		CMUN: '018',
		DC: '8',
		NOMBRE: 'Benquerencia de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '019',
		DC: '1',
		NOMBRE: 'Berlanga',
	},
	{
		CPRO: '06',
		CMUN: '020',
		DC: '5',
		NOMBRE: 'Bienvenida',
	},
	{
		CPRO: '06',
		CMUN: '021',
		DC: '2',
		NOMBRE: 'Bodonal de la Sierra',
	},
	{
		CPRO: '06',
		CMUN: '022',
		DC: '7',
		NOMBRE: 'Burguillos del Cerro',
	},
	{
		CPRO: '06',
		CMUN: '023',
		DC: '3',
		NOMBRE: 'Cabeza del Buey',
	},
	{
		CPRO: '06',
		CMUN: '024',
		DC: '8',
		NOMBRE: 'Cabeza la Vaca',
	},
	{
		CPRO: '06',
		CMUN: '025',
		DC: '1',
		NOMBRE: 'Calamonte',
	},
	{
		CPRO: '06',
		CMUN: '026',
		DC: '4',
		NOMBRE: 'Calera de León',
	},
	{
		CPRO: '06',
		CMUN: '027',
		DC: '0',
		NOMBRE: 'Calzadilla de los Barros',
	},
	{
		CPRO: '06',
		CMUN: '028',
		DC: '6',
		NOMBRE: 'Campanario',
	},
	{
		CPRO: '06',
		CMUN: '029',
		DC: '9',
		NOMBRE: 'Campillo de Llerena',
	},
	{
		CPRO: '06',
		CMUN: '030',
		DC: '3',
		NOMBRE: 'Capilla',
	},
	{
		CPRO: '06',
		CMUN: '031',
		DC: '0',
		NOMBRE: 'Carmonita',
	},
	{
		CPRO: '06',
		CMUN: '032',
		DC: '5',
		NOMBRE: 'Carrascalejo, El',
	},
	{
		CPRO: '06',
		CMUN: '033',
		DC: '1',
		NOMBRE: 'Casas de Don Pedro',
	},
	{
		CPRO: '06',
		CMUN: '034',
		DC: '6',
		NOMBRE: 'Casas de Reina',
	},
	{
		CPRO: '06',
		CMUN: '035',
		DC: '9',
		NOMBRE: 'Castilblanco',
	},
	{
		CPRO: '06',
		CMUN: '036',
		DC: '2',
		NOMBRE: 'Castuera',
	},
	{
		CPRO: '06',
		CMUN: '042',
		DC: '3',
		NOMBRE: 'Cheles',
	},
	{
		CPRO: '06',
		CMUN: '037',
		DC: '8',
		NOMBRE: 'Codosera, La',
	},
	{
		CPRO: '06',
		CMUN: '038',
		DC: '4',
		NOMBRE: 'Cordobilla de Lácara',
	},
	{
		CPRO: '06',
		CMUN: '039',
		DC: '7',
		NOMBRE: 'Coronada, La',
	},
	{
		CPRO: '06',
		CMUN: '040',
		DC: '1',
		NOMBRE: 'Corte de Peleas',
	},
	{
		CPRO: '06',
		CMUN: '041',
		DC: '8',
		NOMBRE: 'Cristina',
	},
	{
		CPRO: '06',
		CMUN: '043',
		DC: '9',
		NOMBRE: 'Don Álvaro',
	},
	{
		CPRO: '06',
		CMUN: '044',
		DC: '4',
		NOMBRE: 'Don Benito',
	},
	{
		CPRO: '06',
		CMUN: '045',
		DC: '7',
		NOMBRE: 'Entrín Bajo',
	},
	{
		CPRO: '06',
		CMUN: '046',
		DC: '0',
		NOMBRE: 'Esparragalejo',
	},
	{
		CPRO: '06',
		CMUN: '047',
		DC: '6',
		NOMBRE: 'Esparragosa de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '048',
		DC: '2',
		NOMBRE: 'Esparragosa de Lares',
	},
	{
		CPRO: '06',
		CMUN: '049',
		DC: '5',
		NOMBRE: 'Feria',
	},
	{
		CPRO: '06',
		CMUN: '050',
		DC: '8',
		NOMBRE: 'Fregenal de la Sierra',
	},
	{
		CPRO: '06',
		CMUN: '051',
		DC: '5',
		NOMBRE: 'Fuenlabrada de los Montes',
	},
	{
		CPRO: '06',
		CMUN: '052',
		DC: '0',
		NOMBRE: 'Fuente de Cantos',
	},
	{
		CPRO: '06',
		CMUN: '053',
		DC: '6',
		NOMBRE: 'Fuente del Arco',
	},
	{
		CPRO: '06',
		CMUN: '054',
		DC: '1',
		NOMBRE: 'Fuente del Maestre',
	},
	{
		CPRO: '06',
		CMUN: '055',
		DC: '4',
		NOMBRE: 'Fuentes de León',
	},
	{
		CPRO: '06',
		CMUN: '056',
		DC: '7',
		NOMBRE: 'Garbayuela',
	},
	{
		CPRO: '06',
		CMUN: '057',
		DC: '3',
		NOMBRE: 'Garlitos',
	},
	{
		CPRO: '06',
		CMUN: '058',
		DC: '9',
		NOMBRE: 'Garrovilla, La',
	},
	{
		CPRO: '06',
		CMUN: '059',
		DC: '2',
		NOMBRE: 'Granja de Torrehermosa',
	},
	{
		CPRO: '06',
		CMUN: '060',
		DC: '6',
		NOMBRE: 'Guareña',
	},
	{
		CPRO: '06',
		CMUN: '061',
		DC: '3',
		NOMBRE: 'Haba, La',
	},
	{
		CPRO: '06',
		CMUN: '062',
		DC: '8',
		NOMBRE: 'Helechosa de los Montes',
	},
	{
		CPRO: '06',
		CMUN: '063',
		DC: '4',
		NOMBRE: 'Herrera del Duque',
	},
	{
		CPRO: '06',
		CMUN: '064',
		DC: '9',
		NOMBRE: 'Higuera de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '065',
		DC: '2',
		NOMBRE: 'Higuera de Llerena',
	},
	{
		CPRO: '06',
		CMUN: '066',
		DC: '5',
		NOMBRE: 'Higuera de Vargas',
	},
	{
		CPRO: '06',
		CMUN: '067',
		DC: '1',
		NOMBRE: 'Higuera la Real',
	},
	{
		CPRO: '06',
		CMUN: '068',
		DC: '7',
		NOMBRE: 'Hinojosa del Valle',
	},
	{
		CPRO: '06',
		CMUN: '069',
		DC: '0',
		NOMBRE: 'Hornachos',
	},
	{
		CPRO: '06',
		CMUN: '070',
		DC: '4',
		NOMBRE: 'Jerez de los Caballeros',
	},
	{
		CPRO: '06',
		CMUN: '071',
		DC: '1',
		NOMBRE: 'Lapa, La',
	},
	{
		CPRO: '06',
		CMUN: '073',
		DC: '2',
		NOMBRE: 'Llera',
	},
	{
		CPRO: '06',
		CMUN: '074',
		DC: '7',
		NOMBRE: 'Llerena',
	},
	{
		CPRO: '06',
		CMUN: '072',
		DC: '6',
		NOMBRE: 'Lobón',
	},
	{
		CPRO: '06',
		CMUN: '075',
		DC: '0',
		NOMBRE: 'Magacela',
	},
	{
		CPRO: '06',
		CMUN: '076',
		DC: '3',
		NOMBRE: 'Maguilla',
	},
	{
		CPRO: '06',
		CMUN: '077',
		DC: '9',
		NOMBRE: 'Malcocinado',
	},
	{
		CPRO: '06',
		CMUN: '078',
		DC: '5',
		NOMBRE: 'Malpartida de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '079',
		DC: '8',
		NOMBRE: 'Manchita',
	},
	{
		CPRO: '06',
		CMUN: '080',
		DC: '2',
		NOMBRE: 'Medellín',
	},
	{
		CPRO: '06',
		CMUN: '081',
		DC: '9',
		NOMBRE: 'Medina de las Torres',
	},
	{
		CPRO: '06',
		CMUN: '082',
		DC: '4',
		NOMBRE: 'Mengabril',
	},
	{
		CPRO: '06',
		CMUN: '083',
		DC: '0',
		NOMBRE: 'Mérida',
	},
	{
		CPRO: '06',
		CMUN: '084',
		DC: '5',
		NOMBRE: 'Mirandilla',
	},
	{
		CPRO: '06',
		CMUN: '085',
		DC: '8',
		NOMBRE: 'Monesterio',
	},
	{
		CPRO: '06',
		CMUN: '086',
		DC: '1',
		NOMBRE: 'Montemolín',
	},
	{
		CPRO: '06',
		CMUN: '087',
		DC: '7',
		NOMBRE: 'Monterrubio de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '088',
		DC: '3',
		NOMBRE: 'Montijo',
	},
	{
		CPRO: '06',
		CMUN: '089',
		DC: '6',
		NOMBRE: 'Morera, La',
	},
	{
		CPRO: '06',
		CMUN: '090',
		DC: '0',
		NOMBRE: 'Nava de Santiago, La',
	},
	{
		CPRO: '06',
		CMUN: '091',
		DC: '7',
		NOMBRE: 'Navalvillar de Pela',
	},
	{
		CPRO: '06',
		CMUN: '092',
		DC: '2',
		NOMBRE: 'Nogales',
	},
	{
		CPRO: '06',
		CMUN: '093',
		DC: '8',
		NOMBRE: 'Oliva de la Frontera',
	},
	{
		CPRO: '06',
		CMUN: '094',
		DC: '3',
		NOMBRE: 'Oliva de Mérida',
	},
	{
		CPRO: '06',
		CMUN: '095',
		DC: '6',
		NOMBRE: 'Olivenza',
	},
	{
		CPRO: '06',
		CMUN: '096',
		DC: '9',
		NOMBRE: 'Orellana de la Sierra',
	},
	{
		CPRO: '06',
		CMUN: '097',
		DC: '5',
		NOMBRE: 'Orellana la Vieja',
	},
	{
		CPRO: '06',
		CMUN: '098',
		DC: '1',
		NOMBRE: 'Palomas',
	},
	{
		CPRO: '06',
		CMUN: '099',
		DC: '4',
		NOMBRE: 'Parra, La',
	},
	{
		CPRO: '06',
		CMUN: '100',
		DC: '8',
		NOMBRE: 'Peñalsordo',
	},
	{
		CPRO: '06',
		CMUN: '101',
		DC: '5',
		NOMBRE: 'Peraleda del Zaucejo',
	},
	{
		CPRO: '06',
		CMUN: '102',
		DC: '0',
		NOMBRE: 'Puebla de Alcocer',
	},
	{
		CPRO: '06',
		CMUN: '103',
		DC: '6',
		NOMBRE: 'Puebla de la Calzada',
	},
	{
		CPRO: '06',
		CMUN: '104',
		DC: '1',
		NOMBRE: 'Puebla de la Reina',
	},
	{
		CPRO: '06',
		CMUN: '107',
		DC: '3',
		NOMBRE: 'Puebla de Obando',
	},
	{
		CPRO: '06',
		CMUN: '108',
		DC: '9',
		NOMBRE: 'Puebla de Sancho Pérez',
	},
	{
		CPRO: '06',
		CMUN: '105',
		DC: '4',
		NOMBRE: 'Puebla del Maestre',
	},
	{
		CPRO: '06',
		CMUN: '106',
		DC: '7',
		NOMBRE: 'Puebla del Prior',
	},
	{
		CPRO: '06',
		CMUN: '902',
		DC: '2',
		NOMBRE: 'Pueblonuevo del Guadiana',
	},
	{
		CPRO: '06',
		CMUN: '109',
		DC: '2',
		NOMBRE: 'Quintana de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '110',
		DC: '6',
		NOMBRE: 'Reina',
	},
	{
		CPRO: '06',
		CMUN: '111',
		DC: '3',
		NOMBRE: 'Rena',
	},
	{
		CPRO: '06',
		CMUN: '112',
		DC: '8',
		NOMBRE: 'Retamal de Llerena',
	},
	{
		CPRO: '06',
		CMUN: '113',
		DC: '4',
		NOMBRE: 'Ribera del Fresno',
	},
	{
		CPRO: '06',
		CMUN: '114',
		DC: '9',
		NOMBRE: 'Risco',
	},
	{
		CPRO: '06',
		CMUN: '115',
		DC: '2',
		NOMBRE: 'Roca de la Sierra, La',
	},
	{
		CPRO: '06',
		CMUN: '116',
		DC: '5',
		NOMBRE: 'Salvaleón',
	},
	{
		CPRO: '06',
		CMUN: '117',
		DC: '1',
		NOMBRE: 'Salvatierra de los Barros',
	},
	{
		CPRO: '06',
		CMUN: '119',
		DC: '0',
		NOMBRE: 'San Pedro de Mérida',
	},
	{
		CPRO: '06',
		CMUN: '123',
		DC: '2',
		NOMBRE: 'San Vicente de Alcántara',
	},
	{
		CPRO: '06',
		CMUN: '118',
		DC: '7',
		NOMBRE: 'Sancti-Spíritus',
	},
	{
		CPRO: '06',
		CMUN: '120',
		DC: '4',
		NOMBRE: 'Santa Amalia',
	},
	{
		CPRO: '06',
		CMUN: '121',
		DC: '1',
		NOMBRE: 'Santa Marta',
	},
	{
		CPRO: '06',
		CMUN: '122',
		DC: '6',
		NOMBRE: 'Santos de Maimona, Los',
	},
	{
		CPRO: '06',
		CMUN: '124',
		DC: '7',
		NOMBRE: 'Segura de León',
	},
	{
		CPRO: '06',
		CMUN: '125',
		DC: '0',
		NOMBRE: 'Siruela',
	},
	{
		CPRO: '06',
		CMUN: '126',
		DC: '3',
		NOMBRE: 'Solana de los Barros',
	},
	{
		CPRO: '06',
		CMUN: '127',
		DC: '9',
		NOMBRE: 'Talarrubias',
	},
	{
		CPRO: '06',
		CMUN: '128',
		DC: '5',
		NOMBRE: 'Talavera la Real',
	},
	{
		CPRO: '06',
		CMUN: '129',
		DC: '8',
		NOMBRE: 'Táliga',
	},
	{
		CPRO: '06',
		CMUN: '130',
		DC: '2',
		NOMBRE: 'Tamurejo',
	},
	{
		CPRO: '06',
		CMUN: '131',
		DC: '9',
		NOMBRE: 'Torre de Miguel Sesmero',
	},
	{
		CPRO: '06',
		CMUN: '132',
		DC: '4',
		NOMBRE: 'Torremayor',
	},
	{
		CPRO: '06',
		CMUN: '133',
		DC: '0',
		NOMBRE: 'Torremejía',
	},
	{
		CPRO: '06',
		CMUN: '134',
		DC: '5',
		NOMBRE: 'Trasierra',
	},
	{
		CPRO: '06',
		CMUN: '135',
		DC: '8',
		NOMBRE: 'Trujillanos',
	},
	{
		CPRO: '06',
		CMUN: '136',
		DC: '1',
		NOMBRE: 'Usagre',
	},
	{
		CPRO: '06',
		CMUN: '137',
		DC: '7',
		NOMBRE: 'Valdecaballeros',
	},
	{
		CPRO: '06',
		CMUN: '901',
		DC: '7',
		NOMBRE: 'Valdelacalzada',
	},
	{
		CPRO: '06',
		CMUN: '138',
		DC: '3',
		NOMBRE: 'Valdetorres',
	},
	{
		CPRO: '06',
		CMUN: '139',
		DC: '6',
		NOMBRE: 'Valencia de las Torres',
	},
	{
		CPRO: '06',
		CMUN: '140',
		DC: '0',
		NOMBRE: 'Valencia del Mombuey',
	},
	{
		CPRO: '06',
		CMUN: '141',
		DC: '7',
		NOMBRE: 'Valencia del Ventoso',
	},
	{
		CPRO: '06',
		CMUN: '146',
		DC: '9',
		NOMBRE: 'Valle de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '147',
		DC: '5',
		NOMBRE: 'Valle de Matamoros',
	},
	{
		CPRO: '06',
		CMUN: '148',
		DC: '1',
		NOMBRE: 'Valle de Santa Ana',
	},
	{
		CPRO: '06',
		CMUN: '142',
		DC: '2',
		NOMBRE: 'Valverde de Burguillos',
	},
	{
		CPRO: '06',
		CMUN: '143',
		DC: '8',
		NOMBRE: 'Valverde de Leganés',
	},
	{
		CPRO: '06',
		CMUN: '144',
		DC: '3',
		NOMBRE: 'Valverde de Llerena',
	},
	{
		CPRO: '06',
		CMUN: '145',
		DC: '6',
		NOMBRE: 'Valverde de Mérida',
	},
	{
		CPRO: '06',
		CMUN: '149',
		DC: '4',
		NOMBRE: 'Villafranca de los Barros',
	},
	{
		CPRO: '06',
		CMUN: '150',
		DC: '7',
		NOMBRE: 'Villagarcía de la Torre',
	},
	{
		CPRO: '06',
		CMUN: '151',
		DC: '4',
		NOMBRE: 'Villagonzalo',
	},
	{
		CPRO: '06',
		CMUN: '152',
		DC: '9',
		NOMBRE: 'Villalba de los Barros',
	},
	{
		CPRO: '06',
		CMUN: '153',
		DC: '5',
		NOMBRE: 'Villanueva de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '154',
		DC: '0',
		NOMBRE: 'Villanueva del Fresno',
	},
	{
		CPRO: '06',
		CMUN: '156',
		DC: '6',
		NOMBRE: 'Villar de Rena',
	},
	{
		CPRO: '06',
		CMUN: '155',
		DC: '3',
		NOMBRE: 'Villar del Rey',
	},
	{
		CPRO: '06',
		CMUN: '157',
		DC: '2',
		NOMBRE: 'Villarta de los Montes',
	},
	{
		CPRO: '06',
		CMUN: '158',
		DC: '8',
		NOMBRE: 'Zafra',
	},
	{
		CPRO: '06',
		CMUN: '159',
		DC: '1',
		NOMBRE: 'Zahínos',
	},
	{
		CPRO: '06',
		CMUN: '160',
		DC: '5',
		NOMBRE: 'Zalamea de la Serena',
	},
	{
		CPRO: '06',
		CMUN: '162',
		DC: '7',
		NOMBRE: 'Zarza, La',
	},
	{
		CPRO: '06',
		CMUN: '161',
		DC: '2',
		NOMBRE: 'Zarza-Capilla',
	},
	{
		CPRO: '07',
		CMUN: '002',
		DC: '7',
		NOMBRE: 'Alaior',
	},
	{
		CPRO: '07',
		CMUN: '001',
		DC: '2',
		NOMBRE: 'Alaró',
	},
	{
		CPRO: '07',
		CMUN: '003',
		DC: '3',
		NOMBRE: 'Alcúdia',
	},
	{
		CPRO: '07',
		CMUN: '004',
		DC: '8',
		NOMBRE: 'Algaida',
	},
	{
		CPRO: '07',
		CMUN: '005',
		DC: '1',
		NOMBRE: 'Andratx',
	},
	{
		CPRO: '07',
		CMUN: '901',
		DC: '3',
		NOMBRE: 'Ariany',
	},
	{
		CPRO: '07',
		CMUN: '006',
		DC: '4',
		NOMBRE: 'Artà',
	},
	{
		CPRO: '07',
		CMUN: '007',
		DC: '0',
		NOMBRE: 'Banyalbufar',
	},
	{
		CPRO: '07',
		CMUN: '008',
		DC: '6',
		NOMBRE: 'Binissalem',
	},
	{
		CPRO: '07',
		CMUN: '009',
		DC: '9',
		NOMBRE: 'Búger',
	},
	{
		CPRO: '07',
		CMUN: '010',
		DC: '3',
		NOMBRE: 'Bunyola',
	},
	{
		CPRO: '07',
		CMUN: '011',
		DC: '0',
		NOMBRE: 'Calvià',
	},
	{
		CPRO: '07',
		CMUN: '012',
		DC: '5',
		NOMBRE: 'Campanet',
	},
	{
		CPRO: '07',
		CMUN: '013',
		DC: '1',
		NOMBRE: 'Campos',
	},
	{
		CPRO: '07',
		CMUN: '014',
		DC: '6',
		NOMBRE: 'Capdepera',
	},
	{
		CPRO: '07',
		CMUN: '064',
		DC: '5',
		NOMBRE: 'Castell, Es',
	},
	{
		CPRO: '07',
		CMUN: '015',
		DC: '9',
		NOMBRE: 'Ciutadella de Menorca',
	},
	{
		CPRO: '07',
		CMUN: '016',
		DC: '2',
		NOMBRE: 'Consell',
	},
	{
		CPRO: '07',
		CMUN: '017',
		DC: '8',
		NOMBRE: 'Costitx',
	},
	{
		CPRO: '07',
		CMUN: '018',
		DC: '4',
		NOMBRE: 'Deyá',
	},
	{
		CPRO: '07',
		CMUN: '026',
		DC: '0',
		NOMBRE: 'Eivissa',
	},
	{
		CPRO: '07',
		CMUN: '019',
		DC: '7',
		NOMBRE: 'Escorca',
	},
	{
		CPRO: '07',
		CMUN: '020',
		DC: '1',
		NOMBRE: 'Esporles',
	},
	{
		CPRO: '07',
		CMUN: '021',
		DC: '8',
		NOMBRE: 'Estellencs',
	},
	{
		CPRO: '07',
		CMUN: '022',
		DC: '3',
		NOMBRE: 'Felanitx',
	},
	{
		CPRO: '07',
		CMUN: '023',
		DC: '9',
		NOMBRE: 'Ferreries',
	},
	{
		CPRO: '07',
		CMUN: '024',
		DC: '4',
		NOMBRE: 'Formentera',
	},
	{
		CPRO: '07',
		CMUN: '025',
		DC: '7',
		NOMBRE: 'Fornalutx',
	},
	{
		CPRO: '07',
		CMUN: '027',
		DC: '6',
		NOMBRE: 'Inca',
	},
	{
		CPRO: '07',
		CMUN: '028',
		DC: '2',
		NOMBRE: 'Lloret de Vistalegre',
	},
	{
		CPRO: '07',
		CMUN: '029',
		DC: '5',
		NOMBRE: 'Lloseta',
	},
	{
		CPRO: '07',
		CMUN: '030',
		DC: '9',
		NOMBRE: 'Llubí',
	},
	{
		CPRO: '07',
		CMUN: '031',
		DC: '6',
		NOMBRE: 'Llucmajor',
	},
	{
		CPRO: '07',
		CMUN: '033',
		DC: '7',
		NOMBRE: 'Manacor',
	},
	{
		CPRO: '07',
		CMUN: '034',
		DC: '2',
		NOMBRE: 'Mancor de la Vall',
	},
	{
		CPRO: '07',
		CMUN: '032',
		DC: '1',
		NOMBRE: 'Maó',
	},
	{
		CPRO: '07',
		CMUN: '035',
		DC: '5',
		NOMBRE: 'Maria de la Salut',
	},
	{
		CPRO: '07',
		CMUN: '036',
		DC: '8',
		NOMBRE: 'Marratxí',
	},
	{
		CPRO: '07',
		CMUN: '037',
		DC: '4',
		NOMBRE: 'Mercadal, Es',
	},
	{
		CPRO: '07',
		CMUN: '902',
		DC: '8',
		NOMBRE: 'Migjorn Gran, Es',
	},
	{
		CPRO: '07',
		CMUN: '038',
		DC: '0',
		NOMBRE: 'Montuïri',
	},
	{
		CPRO: '07',
		CMUN: '039',
		DC: '3',
		NOMBRE: 'Muro',
	},
	{
		CPRO: '07',
		CMUN: '040',
		DC: '7',
		NOMBRE: 'Palma',
	},
	{
		CPRO: '07',
		CMUN: '041',
		DC: '4',
		NOMBRE: 'Petra',
	},
	{
		CPRO: '07',
		CMUN: '044',
		DC: '0',
		NOMBRE: 'Pobla, Sa',
	},
	{
		CPRO: '07',
		CMUN: '042',
		DC: '9',
		NOMBRE: 'Pollença',
	},
	{
		CPRO: '07',
		CMUN: '043',
		DC: '5',
		NOMBRE: 'Porreres',
	},
	{
		CPRO: '07',
		CMUN: '045',
		DC: '3',
		NOMBRE: 'Puigpunyent',
	},
	{
		CPRO: '07',
		CMUN: '059',
		DC: '8',
		NOMBRE: 'Salines, Ses',
	},
	{
		CPRO: '07',
		CMUN: '046',
		DC: '6',
		NOMBRE: 'Sant Antoni de Portmany',
	},
	{
		CPRO: '07',
		CMUN: '049',
		DC: '1',
		NOMBRE: 'Sant Joan',
	},
	{
		CPRO: '07',
		CMUN: '050',
		DC: '4',
		NOMBRE: 'Sant Joan de Labritja',
	},
	{
		CPRO: '07',
		CMUN: '048',
		DC: '8',
		NOMBRE: 'Sant Josep de sa Talaia',
	},
	{
		CPRO: '07',
		CMUN: '051',
		DC: '1',
		NOMBRE: 'Sant Llorenç des Cardassar',
	},
	{
		CPRO: '07',
		CMUN: '052',
		DC: '6',
		NOMBRE: 'Sant Lluís',
	},
	{
		CPRO: '07',
		CMUN: '053',
		DC: '2',
		NOMBRE: 'Santa Eugènia',
	},
	{
		CPRO: '07',
		CMUN: '054',
		DC: '7',
		NOMBRE: 'Santa Eulalia del Río',
	},
	{
		CPRO: '07',
		CMUN: '055',
		DC: '0',
		NOMBRE: 'Santa Margalida',
	},
	{
		CPRO: '07',
		CMUN: '056',
		DC: '3',
		NOMBRE: 'Santa María del Camí',
	},
	{
		CPRO: '07',
		CMUN: '057',
		DC: '9',
		NOMBRE: 'Santanyí',
	},
	{
		CPRO: '07',
		CMUN: '058',
		DC: '5',
		NOMBRE: 'Selva',
	},
	{
		CPRO: '07',
		CMUN: '047',
		DC: '2',
		NOMBRE: 'Sencelles',
	},
	{
		CPRO: '07',
		CMUN: '060',
		DC: '2',
		NOMBRE: 'Sineu',
	},
	{
		CPRO: '07',
		CMUN: '061',
		DC: '9',
		NOMBRE: 'Sóller',
	},
	{
		CPRO: '07',
		CMUN: '062',
		DC: '4',
		NOMBRE: 'Son Servera',
	},
	{
		CPRO: '07',
		CMUN: '063',
		DC: '0',
		NOMBRE: 'Valldemossa',
	},
	{
		CPRO: '07',
		CMUN: '065',
		DC: '8',
		NOMBRE: 'Vilafranca de Bonany',
	},
	{
		CPRO: '08',
		CMUN: '001',
		DC: '8',
		NOMBRE: 'Abrera',
	},
	{
		CPRO: '08',
		CMUN: '002',
		DC: '3',
		NOMBRE: 'Aguilar de Segarra',
	},
	{
		CPRO: '08',
		CMUN: '014',
		DC: '2',
		NOMBRE: 'Aiguafreda',
	},
	{
		CPRO: '08',
		CMUN: '003',
		DC: '9',
		NOMBRE: 'Alella',
	},
	{
		CPRO: '08',
		CMUN: '004',
		DC: '4',
		NOMBRE: 'Alpens',
	},
	{
		CPRO: '08',
		CMUN: '005',
		DC: '7',
		NOMBRE: "Ametlla del Vallès, L'",
	},
	{
		CPRO: '08',
		CMUN: '006',
		DC: '0',
		NOMBRE: 'Arenys de Mar',
	},
	{
		CPRO: '08',
		CMUN: '007',
		DC: '6',
		NOMBRE: 'Arenys de Munt',
	},
	{
		CPRO: '08',
		CMUN: '008',
		DC: '2',
		NOMBRE: 'Argençola',
	},
	{
		CPRO: '08',
		CMUN: '009',
		DC: '5',
		NOMBRE: 'Argentona',
	},
	{
		CPRO: '08',
		CMUN: '010',
		DC: '9',
		NOMBRE: 'Artés',
	},
	{
		CPRO: '08',
		CMUN: '011',
		DC: '6',
		NOMBRE: 'Avià',
	},
	{
		CPRO: '08',
		CMUN: '012',
		DC: '1',
		NOMBRE: 'Avinyó',
	},
	{
		CPRO: '08',
		CMUN: '013',
		DC: '7',
		NOMBRE: 'Avinyonet del Penedès',
	},
	{
		CPRO: '08',
		CMUN: '015',
		DC: '5',
		NOMBRE: 'Badalona',
	},
	{
		CPRO: '08',
		CMUN: '904',
		DC: '5',
		NOMBRE: 'Badia del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '016',
		DC: '8',
		NOMBRE: 'Bagà',
	},
	{
		CPRO: '08',
		CMUN: '017',
		DC: '4',
		NOMBRE: 'Balenyà',
	},
	{
		CPRO: '08',
		CMUN: '018',
		DC: '0',
		NOMBRE: 'Balsareny',
	},
	{
		CPRO: '08',
		CMUN: '252',
		DC: '0',
		NOMBRE: 'Barberà del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '019',
		DC: '3',
		NOMBRE: 'Barcelona',
	},
	{
		CPRO: '08',
		CMUN: '020',
		DC: '7',
		NOMBRE: 'Begues',
	},
	{
		CPRO: '08',
		CMUN: '021',
		DC: '4',
		NOMBRE: 'Bellprat',
	},
	{
		CPRO: '08',
		CMUN: '022',
		DC: '9',
		NOMBRE: 'Berga',
	},
	{
		CPRO: '08',
		CMUN: '023',
		DC: '5',
		NOMBRE: 'Bigues i Riells',
	},
	{
		CPRO: '08',
		CMUN: '024',
		DC: '0',
		NOMBRE: 'Borredà',
	},
	{
		CPRO: '08',
		CMUN: '025',
		DC: '3',
		NOMBRE: 'Bruc, El',
	},
	{
		CPRO: '08',
		CMUN: '026',
		DC: '6',
		NOMBRE: 'Brull, El',
	},
	{
		CPRO: '08',
		CMUN: '027',
		DC: '2',
		NOMBRE: 'Cabanyes, Les',
	},
	{
		CPRO: '08',
		CMUN: '028',
		DC: '8',
		NOMBRE: "Cabrera d'Anoia",
	},
	{
		CPRO: '08',
		CMUN: '029',
		DC: '1',
		NOMBRE: 'Cabrera de Mar',
	},
	{
		CPRO: '08',
		CMUN: '030',
		DC: '5',
		NOMBRE: 'Cabrils',
	},
	{
		CPRO: '08',
		CMUN: '031',
		DC: '2',
		NOMBRE: 'Calaf',
	},
	{
		CPRO: '08',
		CMUN: '034',
		DC: '8',
		NOMBRE: 'Calders',
	},
	{
		CPRO: '08',
		CMUN: '033',
		DC: '3',
		NOMBRE: 'Caldes de Montbui',
	},
	{
		CPRO: '08',
		CMUN: '032',
		DC: '7',
		NOMBRE: "Caldes d'Estrac",
	},
	{
		CPRO: '08',
		CMUN: '035',
		DC: '1',
		NOMBRE: 'Calella',
	},
	{
		CPRO: '08',
		CMUN: '037',
		DC: '0',
		NOMBRE: 'Calldetenes',
	},
	{
		CPRO: '08',
		CMUN: '038',
		DC: '6',
		NOMBRE: 'Callús',
	},
	{
		CPRO: '08',
		CMUN: '036',
		DC: '4',
		NOMBRE: 'Calonge de Segarra',
	},
	{
		CPRO: '08',
		CMUN: '039',
		DC: '9',
		NOMBRE: 'Campins',
	},
	{
		CPRO: '08',
		CMUN: '040',
		DC: '3',
		NOMBRE: 'Canet de Mar',
	},
	{
		CPRO: '08',
		CMUN: '041',
		DC: '0',
		NOMBRE: 'Canovelles',
	},
	{
		CPRO: '08',
		CMUN: '042',
		DC: '5',
		NOMBRE: 'Cànoves i Samalús',
	},
	{
		CPRO: '08',
		CMUN: '043',
		DC: '1',
		NOMBRE: 'Canyelles',
	},
	{
		CPRO: '08',
		CMUN: '044',
		DC: '6',
		NOMBRE: 'Capellades',
	},
	{
		CPRO: '08',
		CMUN: '045',
		DC: '9',
		NOMBRE: 'Capolat',
	},
	{
		CPRO: '08',
		CMUN: '046',
		DC: '2',
		NOMBRE: 'Cardedeu',
	},
	{
		CPRO: '08',
		CMUN: '047',
		DC: '8',
		NOMBRE: 'Cardona',
	},
	{
		CPRO: '08',
		CMUN: '048',
		DC: '4',
		NOMBRE: 'Carme',
	},
	{
		CPRO: '08',
		CMUN: '049',
		DC: '7',
		NOMBRE: 'Casserres',
	},
	{
		CPRO: '08',
		CMUN: '057',
		DC: '5',
		NOMBRE: "Castell de l'Areny",
	},
	{
		CPRO: '08',
		CMUN: '052',
		DC: '2',
		NOMBRE: "Castellar de n'Hug",
	},
	{
		CPRO: '08',
		CMUN: '050',
		DC: '0',
		NOMBRE: 'Castellar del Riu',
	},
	{
		CPRO: '08',
		CMUN: '051',
		DC: '7',
		NOMBRE: 'Castellar del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '053',
		DC: '8',
		NOMBRE: 'Castellbell i el Vilar',
	},
	{
		CPRO: '08',
		CMUN: '054',
		DC: '3',
		NOMBRE: 'Castellbisbal',
	},
	{
		CPRO: '08',
		CMUN: '055',
		DC: '6',
		NOMBRE: 'Castellcir',
	},
	{
		CPRO: '08',
		CMUN: '056',
		DC: '9',
		NOMBRE: 'Castelldefels',
	},
	{
		CPRO: '08',
		CMUN: '058',
		DC: '1',
		NOMBRE: 'Castellet i la Gornal',
	},
	{
		CPRO: '08',
		CMUN: '060',
		DC: '8',
		NOMBRE: 'Castellfollit de Riubregós',
	},
	{
		CPRO: '08',
		CMUN: '059',
		DC: '4',
		NOMBRE: 'Castellfollit del Boix',
	},
	{
		CPRO: '08',
		CMUN: '061',
		DC: '5',
		NOMBRE: 'Castellgalí',
	},
	{
		CPRO: '08',
		CMUN: '062',
		DC: '0',
		NOMBRE: 'Castellnou de Bages',
	},
	{
		CPRO: '08',
		CMUN: '063',
		DC: '6',
		NOMBRE: 'Castellolí',
	},
	{
		CPRO: '08',
		CMUN: '064',
		DC: '1',
		NOMBRE: 'Castellterçol',
	},
	{
		CPRO: '08',
		CMUN: '065',
		DC: '4',
		NOMBRE: 'Castellví de la Marca',
	},
	{
		CPRO: '08',
		CMUN: '066',
		DC: '7',
		NOMBRE: 'Castellví de Rosanes',
	},
	{
		CPRO: '08',
		CMUN: '067',
		DC: '3',
		NOMBRE: 'Centelles',
	},
	{
		CPRO: '08',
		CMUN: '268',
		DC: '7',
		NOMBRE: 'Cercs',
	},
	{
		CPRO: '08',
		CMUN: '266',
		DC: '5',
		NOMBRE: 'Cerdanyola del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '068',
		DC: '9',
		NOMBRE: 'Cervelló',
	},
	{
		CPRO: '08',
		CMUN: '069',
		DC: '2',
		NOMBRE: 'Collbató',
	},
	{
		CPRO: '08',
		CMUN: '070',
		DC: '6',
		NOMBRE: 'Collsuspina',
	},
	{
		CPRO: '08',
		CMUN: '071',
		DC: '3',
		NOMBRE: 'Copons',
	},
	{
		CPRO: '08',
		CMUN: '072',
		DC: '8',
		NOMBRE: 'Corbera de Llobregat',
	},
	{
		CPRO: '08',
		CMUN: '073',
		DC: '4',
		NOMBRE: 'Cornellà de Llobregat',
	},
	{
		CPRO: '08',
		CMUN: '074',
		DC: '9',
		NOMBRE: 'Cubelles',
	},
	{
		CPRO: '08',
		CMUN: '075',
		DC: '2',
		NOMBRE: 'Dosrius',
	},
	{
		CPRO: '08',
		CMUN: '076',
		DC: '5',
		NOMBRE: 'Esparreguera',
	},
	{
		CPRO: '08',
		CMUN: '077',
		DC: '1',
		NOMBRE: 'Esplugues de Llobregat',
	},
	{
		CPRO: '08',
		CMUN: '078',
		DC: '7',
		NOMBRE: "Espunyola, L'",
	},
	{
		CPRO: '08',
		CMUN: '079',
		DC: '0',
		NOMBRE: "Estany, L'",
	},
	{
		CPRO: '08',
		CMUN: '134',
		DC: '7',
		NOMBRE: 'Figaró-Montmany',
	},
	{
		CPRO: '08',
		CMUN: '080',
		DC: '4',
		NOMBRE: 'Fígols',
	},
	{
		CPRO: '08',
		CMUN: '082',
		DC: '6',
		NOMBRE: 'Fogars de la Selva',
	},
	{
		CPRO: '08',
		CMUN: '081',
		DC: '1',
		NOMBRE: 'Fogars de Montclús',
	},
	{
		CPRO: '08',
		CMUN: '083',
		DC: '2',
		NOMBRE: 'Folgueroles',
	},
	{
		CPRO: '08',
		CMUN: '084',
		DC: '7',
		NOMBRE: 'Fonollosa',
	},
	{
		CPRO: '08',
		CMUN: '085',
		DC: '0',
		NOMBRE: 'Font-rubí',
	},
	{
		CPRO: '08',
		CMUN: '086',
		DC: '3',
		NOMBRE: 'Franqueses del Vallès, Les',
	},
	{
		CPRO: '08',
		CMUN: '090',
		DC: '2',
		NOMBRE: 'Gaià',
	},
	{
		CPRO: '08',
		CMUN: '087',
		DC: '9',
		NOMBRE: 'Gallifa',
	},
	{
		CPRO: '08',
		CMUN: '088',
		DC: '5',
		NOMBRE: 'Garriga, La',
	},
	{
		CPRO: '08',
		CMUN: '089',
		DC: '8',
		NOMBRE: 'Gavà',
	},
	{
		CPRO: '08',
		CMUN: '091',
		DC: '9',
		NOMBRE: 'Gelida',
	},
	{
		CPRO: '08',
		CMUN: '092',
		DC: '4',
		NOMBRE: 'Gironella',
	},
	{
		CPRO: '08',
		CMUN: '093',
		DC: '0',
		NOMBRE: 'Gisclareny',
	},
	{
		CPRO: '08',
		CMUN: '094',
		DC: '5',
		NOMBRE: 'Granada, La',
	},
	{
		CPRO: '08',
		CMUN: '095',
		DC: '8',
		NOMBRE: 'Granera',
	},
	{
		CPRO: '08',
		CMUN: '096',
		DC: '1',
		NOMBRE: 'Granollers',
	},
	{
		CPRO: '08',
		CMUN: '097',
		DC: '7',
		NOMBRE: 'Gualba',
	},
	{
		CPRO: '08',
		CMUN: '099',
		DC: '6',
		NOMBRE: 'Guardiola de Berguedà',
	},
	{
		CPRO: '08',
		CMUN: '100',
		DC: '0',
		NOMBRE: 'Gurb',
	},
	{
		CPRO: '08',
		CMUN: '101',
		DC: '7',
		NOMBRE: "Hospitalet de Llobregat, L'",
	},
	{
		CPRO: '08',
		CMUN: '162',
		DC: '9',
		NOMBRE: 'Hostalets de Pierola, Els',
	},
	{
		CPRO: '08',
		CMUN: '102',
		DC: '2',
		NOMBRE: 'Igualada',
	},
	{
		CPRO: '08',
		CMUN: '103',
		DC: '8',
		NOMBRE: 'Jorba',
	},
	{
		CPRO: '08',
		CMUN: '104',
		DC: '3',
		NOMBRE: 'Llacuna, La',
	},
	{
		CPRO: '08',
		CMUN: '105',
		DC: '6',
		NOMBRE: 'Llagosta, La',
	},
	{
		CPRO: '08',
		CMUN: '107',
		DC: '5',
		NOMBRE: "Lliçà d'Amunt",
	},
	{
		CPRO: '08',
		CMUN: '108',
		DC: '1',
		NOMBRE: 'Lliçà de Vall',
	},
	{
		CPRO: '08',
		CMUN: '106',
		DC: '9',
		NOMBRE: 'Llinars del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '109',
		DC: '4',
		NOMBRE: 'Lluçà',
	},
	{
		CPRO: '08',
		CMUN: '110',
		DC: '8',
		NOMBRE: 'Malgrat de Mar',
	},
	{
		CPRO: '08',
		CMUN: '111',
		DC: '5',
		NOMBRE: 'Malla',
	},
	{
		CPRO: '08',
		CMUN: '112',
		DC: '0',
		NOMBRE: 'Manlleu',
	},
	{
		CPRO: '08',
		CMUN: '113',
		DC: '6',
		NOMBRE: 'Manresa',
	},
	{
		CPRO: '08',
		CMUN: '242',
		DC: '3',
		NOMBRE: 'Marganell',
	},
	{
		CPRO: '08',
		CMUN: '114',
		DC: '1',
		NOMBRE: 'Martorell',
	},
	{
		CPRO: '08',
		CMUN: '115',
		DC: '4',
		NOMBRE: 'Martorelles',
	},
	{
		CPRO: '08',
		CMUN: '116',
		DC: '7',
		NOMBRE: 'Masies de Roda, Les',
	},
	{
		CPRO: '08',
		CMUN: '117',
		DC: '3',
		NOMBRE: 'Masies de Voltregà, Les',
	},
	{
		CPRO: '08',
		CMUN: '118',
		DC: '9',
		NOMBRE: 'Masnou, El',
	},
	{
		CPRO: '08',
		CMUN: '119',
		DC: '2',
		NOMBRE: 'Masquefa',
	},
	{
		CPRO: '08',
		CMUN: '120',
		DC: '6',
		NOMBRE: 'Matadepera',
	},
	{
		CPRO: '08',
		CMUN: '121',
		DC: '3',
		NOMBRE: 'Mataró',
	},
	{
		CPRO: '08',
		CMUN: '122',
		DC: '8',
		NOMBRE: 'Mediona',
	},
	{
		CPRO: '08',
		CMUN: '138',
		DC: '5',
		NOMBRE: 'Moià',
	},
	{
		CPRO: '08',
		CMUN: '123',
		DC: '4',
		NOMBRE: 'Molins de Rei',
	},
	{
		CPRO: '08',
		CMUN: '124',
		DC: '9',
		NOMBRE: 'Mollet del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '128',
		DC: '7',
		NOMBRE: 'Monistrol de Calders',
	},
	{
		CPRO: '08',
		CMUN: '127',
		DC: '1',
		NOMBRE: 'Monistrol de Montserrat',
	},
	{
		CPRO: '08',
		CMUN: '125',
		DC: '2',
		NOMBRE: 'Montcada i Reixac',
	},
	{
		CPRO: '08',
		CMUN: '130',
		DC: '4',
		NOMBRE: 'Montclar',
	},
	{
		CPRO: '08',
		CMUN: '131',
		DC: '1',
		NOMBRE: 'Montesquiu',
	},
	{
		CPRO: '08',
		CMUN: '126',
		DC: '5',
		NOMBRE: 'Montgat',
	},
	{
		CPRO: '08',
		CMUN: '132',
		DC: '6',
		NOMBRE: 'Montmajor',
	},
	{
		CPRO: '08',
		CMUN: '133',
		DC: '2',
		NOMBRE: 'Montmaneu',
	},
	{
		CPRO: '08',
		CMUN: '135',
		DC: '0',
		NOMBRE: 'Montmeló',
	},
	{
		CPRO: '08',
		CMUN: '136',
		DC: '3',
		NOMBRE: 'Montornès del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '137',
		DC: '9',
		NOMBRE: 'Montseny',
	},
	{
		CPRO: '08',
		CMUN: '129',
		DC: '0',
		NOMBRE: 'Muntanyola',
	},
	{
		CPRO: '08',
		CMUN: '139',
		DC: '8',
		NOMBRE: 'Mura',
	},
	{
		CPRO: '08',
		CMUN: '140',
		DC: '2',
		NOMBRE: 'Navarcles',
	},
	{
		CPRO: '08',
		CMUN: '141',
		DC: '9',
		NOMBRE: 'Navàs',
	},
	{
		CPRO: '08',
		CMUN: '142',
		DC: '4',
		NOMBRE: 'Nou de Berguedà, La',
	},
	{
		CPRO: '08',
		CMUN: '143',
		DC: '0',
		NOMBRE: 'Òdena',
	},
	{
		CPRO: '08',
		CMUN: '145',
		DC: '8',
		NOMBRE: 'Olèrdola',
	},
	{
		CPRO: '08',
		CMUN: '146',
		DC: '1',
		NOMBRE: 'Olesa de Bonesvalls',
	},
	{
		CPRO: '08',
		CMUN: '147',
		DC: '7',
		NOMBRE: 'Olesa de Montserrat',
	},
	{
		CPRO: '08',
		CMUN: '148',
		DC: '3',
		NOMBRE: 'Olivella',
	},
	{
		CPRO: '08',
		CMUN: '149',
		DC: '6',
		NOMBRE: 'Olost',
	},
	{
		CPRO: '08',
		CMUN: '144',
		DC: '5',
		NOMBRE: 'Olvan',
	},
	{
		CPRO: '08',
		CMUN: '150',
		DC: '9',
		NOMBRE: 'Orís',
	},
	{
		CPRO: '08',
		CMUN: '151',
		DC: '6',
		NOMBRE: 'Oristà',
	},
	{
		CPRO: '08',
		CMUN: '152',
		DC: '1',
		NOMBRE: 'Orpí',
	},
	{
		CPRO: '08',
		CMUN: '153',
		DC: '7',
		NOMBRE: 'Òrrius',
	},
	{
		CPRO: '08',
		CMUN: '154',
		DC: '2',
		NOMBRE: 'Pacs del Penedès',
	},
	{
		CPRO: '08',
		CMUN: '155',
		DC: '5',
		NOMBRE: 'Palafolls',
	},
	{
		CPRO: '08',
		CMUN: '156',
		DC: '8',
		NOMBRE: 'Palau-solità i Plegamans',
	},
	{
		CPRO: '08',
		CMUN: '157',
		DC: '4',
		NOMBRE: 'Pallejà',
	},
	{
		CPRO: '08',
		CMUN: '905',
		DC: '8',
		NOMBRE: 'Palma de Cervelló, La',
	},
	{
		CPRO: '08',
		CMUN: '158',
		DC: '0',
		NOMBRE: 'Papiol, El',
	},
	{
		CPRO: '08',
		CMUN: '159',
		DC: '3',
		NOMBRE: 'Parets del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '160',
		DC: '7',
		NOMBRE: 'Perafita',
	},
	{
		CPRO: '08',
		CMUN: '161',
		DC: '4',
		NOMBRE: 'Piera',
	},
	{
		CPRO: '08',
		CMUN: '163',
		DC: '5',
		NOMBRE: 'Pineda de Mar',
	},
	{
		CPRO: '08',
		CMUN: '164',
		DC: '0',
		NOMBRE: 'Pla del Penedès, El',
	},
	{
		CPRO: '08',
		CMUN: '165',
		DC: '3',
		NOMBRE: 'Pobla de Claramunt, La',
	},
	{
		CPRO: '08',
		CMUN: '166',
		DC: '6',
		NOMBRE: 'Pobla de Lillet, La',
	},
	{
		CPRO: '08',
		CMUN: '167',
		DC: '2',
		NOMBRE: 'Polinyà',
	},
	{
		CPRO: '08',
		CMUN: '182',
		DC: '5',
		NOMBRE: 'Pont de Vilomara i Rocafort, El',
	},
	{
		CPRO: '08',
		CMUN: '168',
		DC: '8',
		NOMBRE: 'Pontons',
	},
	{
		CPRO: '08',
		CMUN: '169',
		DC: '1',
		NOMBRE: 'Prat de Llobregat, El',
	},
	{
		CPRO: '08',
		CMUN: '171',
		DC: '2',
		NOMBRE: 'Prats de Lluçanès',
	},
	{
		CPRO: '08',
		CMUN: '170',
		DC: '5',
		NOMBRE: 'Prats de Rei, Els',
	},
	{
		CPRO: '08',
		CMUN: '230',
		DC: '3',
		NOMBRE: 'Premià de Dalt',
	},
	{
		CPRO: '08',
		CMUN: '172',
		DC: '7',
		NOMBRE: 'Premià de Mar',
	},
	{
		CPRO: '08',
		CMUN: '174',
		DC: '8',
		NOMBRE: 'Puigdàlber',
	},
	{
		CPRO: '08',
		CMUN: '175',
		DC: '1',
		NOMBRE: 'Puig-reig',
	},
	{
		CPRO: '08',
		CMUN: '176',
		DC: '4',
		NOMBRE: 'Pujalt',
	},
	{
		CPRO: '08',
		CMUN: '177',
		DC: '0',
		NOMBRE: 'Quar, La',
	},
	{
		CPRO: '08',
		CMUN: '178',
		DC: '6',
		NOMBRE: 'Rajadell',
	},
	{
		CPRO: '08',
		CMUN: '179',
		DC: '9',
		NOMBRE: 'Rellinars',
	},
	{
		CPRO: '08',
		CMUN: '180',
		DC: '3',
		NOMBRE: 'Ripollet',
	},
	{
		CPRO: '08',
		CMUN: '181',
		DC: '0',
		NOMBRE: 'Roca del Vallès, La',
	},
	{
		CPRO: '08',
		CMUN: '183',
		DC: '1',
		NOMBRE: 'Roda de Ter',
	},
	{
		CPRO: '08',
		CMUN: '184',
		DC: '6',
		NOMBRE: 'Rubí',
	},
	{
		CPRO: '08',
		CMUN: '185',
		DC: '9',
		NOMBRE: 'Rubió',
	},
	{
		CPRO: '08',
		CMUN: '901',
		DC: '9',
		NOMBRE: 'Rupit i Pruit',
	},
	{
		CPRO: '08',
		CMUN: '187',
		DC: '8',
		NOMBRE: 'Sabadell',
	},
	{
		CPRO: '08',
		CMUN: '188',
		DC: '4',
		NOMBRE: 'Sagàs',
	},
	{
		CPRO: '08',
		CMUN: '190',
		DC: '1',
		NOMBRE: 'Saldes',
	},
	{
		CPRO: '08',
		CMUN: '191',
		DC: '8',
		NOMBRE: 'Sallent',
	},
	{
		CPRO: '08',
		CMUN: '194',
		DC: '4',
		NOMBRE: 'Sant Adrià de Besòs',
	},
	{
		CPRO: '08',
		CMUN: '195',
		DC: '7',
		NOMBRE: 'Sant Agustí de Lluçanès',
	},
	{
		CPRO: '08',
		CMUN: '196',
		DC: '0',
		NOMBRE: 'Sant Andreu de la Barca',
	},
	{
		CPRO: '08',
		CMUN: '197',
		DC: '6',
		NOMBRE: 'Sant Andreu de Llavaneres',
	},
	{
		CPRO: '08',
		CMUN: '198',
		DC: '2',
		NOMBRE: 'Sant Antoni de Vilamajor',
	},
	{
		CPRO: '08',
		CMUN: '199',
		DC: '5',
		NOMBRE: 'Sant Bartomeu del Grau',
	},
	{
		CPRO: '08',
		CMUN: '200',
		DC: '9',
		NOMBRE: 'Sant Boi de Llobregat',
	},
	{
		CPRO: '08',
		CMUN: '201',
		DC: '6',
		NOMBRE: 'Sant Boi de Lluçanès',
	},
	{
		CPRO: '08',
		CMUN: '203',
		DC: '7',
		NOMBRE: 'Sant Cebrià de Vallalta',
	},
	{
		CPRO: '08',
		CMUN: '202',
		DC: '1',
		NOMBRE: 'Sant Celoni',
	},
	{
		CPRO: '08',
		CMUN: '204',
		DC: '2',
		NOMBRE: 'Sant Climent de Llobregat',
	},
	{
		CPRO: '08',
		CMUN: '205',
		DC: '5',
		NOMBRE: 'Sant Cugat del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '206',
		DC: '8',
		NOMBRE: 'Sant Cugat Sesgarrigues',
	},
	{
		CPRO: '08',
		CMUN: '207',
		DC: '4',
		NOMBRE: 'Sant Esteve de Palautordera',
	},
	{
		CPRO: '08',
		CMUN: '208',
		DC: '0',
		NOMBRE: 'Sant Esteve Sesrovires',
	},
	{
		CPRO: '08',
		CMUN: '210',
		DC: '7',
		NOMBRE: 'Sant Feliu de Codines',
	},
	{
		CPRO: '08',
		CMUN: '211',
		DC: '4',
		NOMBRE: 'Sant Feliu de Llobregat',
	},
	{
		CPRO: '08',
		CMUN: '212',
		DC: '9',
		NOMBRE: 'Sant Feliu Sasserra',
	},
	{
		CPRO: '08',
		CMUN: '209',
		DC: '3',
		NOMBRE: 'Sant Fost de Campsentelles',
	},
	{
		CPRO: '08',
		CMUN: '213',
		DC: '5',
		NOMBRE: 'Sant Fruitós de Bages',
	},
	{
		CPRO: '08',
		CMUN: '215',
		DC: '3',
		NOMBRE: 'Sant Hipòlit de Voltregà',
	},
	{
		CPRO: '08',
		CMUN: '193',
		DC: '9',
		NOMBRE: 'Sant Iscle de Vallalta',
	},
	{
		CPRO: '08',
		CMUN: '216',
		DC: '6',
		NOMBRE: 'Sant Jaume de Frontanyà',
	},
	{
		CPRO: '08',
		CMUN: '218',
		DC: '8',
		NOMBRE: 'Sant Joan de Vilatorrada',
	},
	{
		CPRO: '08',
		CMUN: '217',
		DC: '2',
		NOMBRE: 'Sant Joan Despí',
	},
	{
		CPRO: '08',
		CMUN: '903',
		DC: '0',
		NOMBRE: 'Sant Julià de Cerdanyola',
	},
	{
		CPRO: '08',
		CMUN: '220',
		DC: '5',
		NOMBRE: 'Sant Julià de Vilatorta',
	},
	{
		CPRO: '08',
		CMUN: '221',
		DC: '2',
		NOMBRE: 'Sant Just Desvern',
	},
	{
		CPRO: '08',
		CMUN: '222',
		DC: '7',
		NOMBRE: "Sant Llorenç d'Hortons",
	},
	{
		CPRO: '08',
		CMUN: '223',
		DC: '3',
		NOMBRE: 'Sant Llorenç Savall',
	},
	{
		CPRO: '08',
		CMUN: '225',
		DC: '1',
		NOMBRE: "Sant Martí d'Albars",
	},
	{
		CPRO: '08',
		CMUN: '224',
		DC: '8',
		NOMBRE: 'Sant Martí de Centelles',
	},
	{
		CPRO: '08',
		CMUN: '226',
		DC: '4',
		NOMBRE: 'Sant Martí de Tous',
	},
	{
		CPRO: '08',
		CMUN: '227',
		DC: '0',
		NOMBRE: 'Sant Martí Sarroca',
	},
	{
		CPRO: '08',
		CMUN: '228',
		DC: '6',
		NOMBRE: 'Sant Martí Sesgueioles',
	},
	{
		CPRO: '08',
		CMUN: '229',
		DC: '9',
		NOMBRE: 'Sant Mateu de Bages',
	},
	{
		CPRO: '08',
		CMUN: '231',
		DC: '0',
		NOMBRE: 'Sant Pere de Ribes',
	},
	{
		CPRO: '08',
		CMUN: '232',
		DC: '5',
		NOMBRE: 'Sant Pere de Riudebitlles',
	},
	{
		CPRO: '08',
		CMUN: '233',
		DC: '1',
		NOMBRE: 'Sant Pere de Torelló',
	},
	{
		CPRO: '08',
		CMUN: '234',
		DC: '6',
		NOMBRE: 'Sant Pere de Vilamajor',
	},
	{
		CPRO: '08',
		CMUN: '189',
		DC: '7',
		NOMBRE: 'Sant Pere Sallavinera',
	},
	{
		CPRO: '08',
		CMUN: '235',
		DC: '9',
		NOMBRE: 'Sant Pol de Mar',
	},
	{
		CPRO: '08',
		CMUN: '236',
		DC: '2',
		NOMBRE: 'Sant Quintí de Mediona',
	},
	{
		CPRO: '08',
		CMUN: '237',
		DC: '8',
		NOMBRE: 'Sant Quirze de Besora',
	},
	{
		CPRO: '08',
		CMUN: '238',
		DC: '4',
		NOMBRE: 'Sant Quirze del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '239',
		DC: '7',
		NOMBRE: 'Sant Quirze Safaja',
	},
	{
		CPRO: '08',
		CMUN: '240',
		DC: '1',
		NOMBRE: "Sant Sadurní d'Anoia",
	},
	{
		CPRO: '08',
		CMUN: '241',
		DC: '8',
		NOMBRE: "Sant Sadurní d'Osormort",
	},
	{
		CPRO: '08',
		CMUN: '098',
		DC: '3',
		NOMBRE: 'Sant Salvador de Guardiola',
	},
	{
		CPRO: '08',
		CMUN: '262',
		DC: '8',
		NOMBRE: 'Sant Vicenç de Castellet',
	},
	{
		CPRO: '08',
		CMUN: '264',
		DC: '9',
		NOMBRE: 'Sant Vicenç de Montalt',
	},
	{
		CPRO: '08',
		CMUN: '265',
		DC: '2',
		NOMBRE: 'Sant Vicenç de Torelló',
	},
	{
		CPRO: '08',
		CMUN: '263',
		DC: '4',
		NOMBRE: 'Sant Vicenç dels Horts',
	},
	{
		CPRO: '08',
		CMUN: '243',
		DC: '9',
		NOMBRE: 'Santa Cecília de Voltregà',
	},
	{
		CPRO: '08',
		CMUN: '244',
		DC: '4',
		NOMBRE: 'Santa Coloma de Cervelló',
	},
	{
		CPRO: '08',
		CMUN: '245',
		DC: '7',
		NOMBRE: 'Santa Coloma de Gramenet',
	},
	{
		CPRO: '08',
		CMUN: '246',
		DC: '0',
		NOMBRE: 'Santa Eugènia de Berga',
	},
	{
		CPRO: '08',
		CMUN: '247',
		DC: '6',
		NOMBRE: 'Santa Eulàlia de Riuprimer',
	},
	{
		CPRO: '08',
		CMUN: '248',
		DC: '2',
		NOMBRE: 'Santa Eulàlia de Ronçana',
	},
	{
		CPRO: '08',
		CMUN: '249',
		DC: '5',
		NOMBRE: 'Santa Fe del Penedès',
	},
	{
		CPRO: '08',
		CMUN: '250',
		DC: '8',
		NOMBRE: 'Santa Margarida de Montbui',
	},
	{
		CPRO: '08',
		CMUN: '251',
		DC: '5',
		NOMBRE: 'Santa Margarida i els Monjos',
	},
	{
		CPRO: '08',
		CMUN: '253',
		DC: '6',
		NOMBRE: 'Santa Maria de Besora',
	},
	{
		CPRO: '08',
		CMUN: '254',
		DC: '1',
		NOMBRE: 'Santa Maria de Corcó',
	},
	{
		CPRO: '08',
		CMUN: '256',
		DC: '7',
		NOMBRE: 'Santa Maria de Martorelles',
	},
	{
		CPRO: '08',
		CMUN: '255',
		DC: '4',
		NOMBRE: 'Santa Maria de Merlès',
	},
	{
		CPRO: '08',
		CMUN: '257',
		DC: '3',
		NOMBRE: 'Santa Maria de Miralles',
	},
	{
		CPRO: '08',
		CMUN: '259',
		DC: '2',
		NOMBRE: 'Santa Maria de Palautordera',
	},
	{
		CPRO: '08',
		CMUN: '258',
		DC: '9',
		NOMBRE: "Santa Maria d'Oló",
	},
	{
		CPRO: '08',
		CMUN: '260',
		DC: '6',
		NOMBRE: 'Santa Perpètua de Mogoda',
	},
	{
		CPRO: '08',
		CMUN: '261',
		DC: '3',
		NOMBRE: 'Santa Susanna',
	},
	{
		CPRO: '08',
		CMUN: '192',
		DC: '3',
		NOMBRE: 'Santpedor',
	},
	{
		CPRO: '08',
		CMUN: '267',
		DC: '1',
		NOMBRE: 'Sentmenat',
	},
	{
		CPRO: '08',
		CMUN: '269',
		DC: '0',
		NOMBRE: 'Seva',
	},
	{
		CPRO: '08',
		CMUN: '270',
		DC: '4',
		NOMBRE: 'Sitges',
	},
	{
		CPRO: '08',
		CMUN: '271',
		DC: '1',
		NOMBRE: 'Sobremunt',
	},
	{
		CPRO: '08',
		CMUN: '272',
		DC: '6',
		NOMBRE: 'Sora',
	},
	{
		CPRO: '08',
		CMUN: '273',
		DC: '2',
		NOMBRE: 'Subirats',
	},
	{
		CPRO: '08',
		CMUN: '274',
		DC: '7',
		NOMBRE: 'Súria',
	},
	{
		CPRO: '08',
		CMUN: '276',
		DC: '3',
		NOMBRE: 'Tagamanent',
	},
	{
		CPRO: '08',
		CMUN: '277',
		DC: '9',
		NOMBRE: 'Talamanca',
	},
	{
		CPRO: '08',
		CMUN: '278',
		DC: '5',
		NOMBRE: 'Taradell',
	},
	{
		CPRO: '08',
		CMUN: '275',
		DC: '0',
		NOMBRE: 'Tavèrnoles',
	},
	{
		CPRO: '08',
		CMUN: '280',
		DC: '2',
		NOMBRE: 'Tavertet',
	},
	{
		CPRO: '08',
		CMUN: '281',
		DC: '9',
		NOMBRE: 'Teià',
	},
	{
		CPRO: '08',
		CMUN: '279',
		DC: '8',
		NOMBRE: 'Terrassa',
	},
	{
		CPRO: '08',
		CMUN: '282',
		DC: '4',
		NOMBRE: 'Tiana',
	},
	{
		CPRO: '08',
		CMUN: '283',
		DC: '0',
		NOMBRE: 'Tona',
	},
	{
		CPRO: '08',
		CMUN: '284',
		DC: '5',
		NOMBRE: 'Tordera',
	},
	{
		CPRO: '08',
		CMUN: '285',
		DC: '8',
		NOMBRE: 'Torelló',
	},
	{
		CPRO: '08',
		CMUN: '286',
		DC: '1',
		NOMBRE: 'Torre de Claramunt, La',
	},
	{
		CPRO: '08',
		CMUN: '287',
		DC: '7',
		NOMBRE: 'Torrelavit',
	},
	{
		CPRO: '08',
		CMUN: '288',
		DC: '3',
		NOMBRE: 'Torrelles de Foix',
	},
	{
		CPRO: '08',
		CMUN: '289',
		DC: '6',
		NOMBRE: 'Torrelles de Llobregat',
	},
	{
		CPRO: '08',
		CMUN: '290',
		DC: '0',
		NOMBRE: 'Ullastrell',
	},
	{
		CPRO: '08',
		CMUN: '291',
		DC: '7',
		NOMBRE: 'Vacarisses',
	},
	{
		CPRO: '08',
		CMUN: '292',
		DC: '2',
		NOMBRE: "Vallbona d'Anoia",
	},
	{
		CPRO: '08',
		CMUN: '293',
		DC: '8',
		NOMBRE: 'Vallcebre',
	},
	{
		CPRO: '08',
		CMUN: '294',
		DC: '3',
		NOMBRE: 'Vallgorguina',
	},
	{
		CPRO: '08',
		CMUN: '295',
		DC: '6',
		NOMBRE: 'Vallirana',
	},
	{
		CPRO: '08',
		CMUN: '296',
		DC: '9',
		NOMBRE: 'Vallromanes',
	},
	{
		CPRO: '08',
		CMUN: '297',
		DC: '5',
		NOMBRE: 'Veciana',
	},
	{
		CPRO: '08',
		CMUN: '298',
		DC: '1',
		NOMBRE: 'Vic',
	},
	{
		CPRO: '08',
		CMUN: '299',
		DC: '4',
		NOMBRE: 'Vilada',
	},
	{
		CPRO: '08',
		CMUN: '301',
		DC: '5',
		NOMBRE: 'Viladecans',
	},
	{
		CPRO: '08',
		CMUN: '300',
		DC: '8',
		NOMBRE: 'Viladecavalls',
	},
	{
		CPRO: '08',
		CMUN: '305',
		DC: '4',
		NOMBRE: 'Vilafranca del Penedès',
	},
	{
		CPRO: '08',
		CMUN: '306',
		DC: '7',
		NOMBRE: 'Vilalba Sasserra',
	},
	{
		CPRO: '08',
		CMUN: '303',
		DC: '6',
		NOMBRE: 'Vilanova de Sau',
	},
	{
		CPRO: '08',
		CMUN: '302',
		DC: '0',
		NOMBRE: 'Vilanova del Camí',
	},
	{
		CPRO: '08',
		CMUN: '902',
		DC: '4',
		NOMBRE: 'Vilanova del Vallès',
	},
	{
		CPRO: '08',
		CMUN: '307',
		DC: '3',
		NOMBRE: 'Vilanova i la Geltrú',
	},
	{
		CPRO: '08',
		CMUN: '214',
		DC: '0',
		NOMBRE: 'Vilassar de Dalt',
	},
	{
		CPRO: '08',
		CMUN: '219',
		DC: '1',
		NOMBRE: 'Vilassar de Mar',
	},
	{
		CPRO: '08',
		CMUN: '304',
		DC: '1',
		NOMBRE: 'Vilobí del Penedès',
	},
	{
		CPRO: '08',
		CMUN: '308',
		DC: '9',
		NOMBRE: 'Viver i Serrateix',
	},
	{
		CPRO: '09',
		CMUN: '001',
		DC: '1',
		NOMBRE: 'Abajas',
	},
	{
		CPRO: '09',
		CMUN: '003',
		DC: '2',
		NOMBRE: 'Adrada de Haza',
	},
	{
		CPRO: '09',
		CMUN: '006',
		DC: '3',
		NOMBRE: 'Aguas Cándidas',
	},
	{
		CPRO: '09',
		CMUN: '007',
		DC: '9',
		NOMBRE: 'Aguilar de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '009',
		DC: '8',
		NOMBRE: 'Albillos',
	},
	{
		CPRO: '09',
		CMUN: '010',
		DC: '2',
		NOMBRE: 'Alcocero de Mola',
	},
	{
		CPRO: '09',
		CMUN: '011',
		DC: '9',
		NOMBRE: 'Alfoz de Bricia',
	},
	{
		CPRO: '09',
		CMUN: '907',
		DC: '0',
		NOMBRE: 'Alfoz de Quintanadueñas',
	},
	{
		CPRO: '09',
		CMUN: '012',
		DC: '4',
		NOMBRE: 'Alfoz de Santa Gadea',
	},
	{
		CPRO: '09',
		CMUN: '013',
		DC: '0',
		NOMBRE: 'Altable',
	},
	{
		CPRO: '09',
		CMUN: '014',
		DC: '5',
		NOMBRE: 'Altos, Los',
	},
	{
		CPRO: '09',
		CMUN: '016',
		DC: '1',
		NOMBRE: 'Ameyugo',
	},
	{
		CPRO: '09',
		CMUN: '017',
		DC: '7',
		NOMBRE: 'Anguix',
	},
	{
		CPRO: '09',
		CMUN: '018',
		DC: '3',
		NOMBRE: 'Aranda de Duero',
	},
	{
		CPRO: '09',
		CMUN: '019',
		DC: '6',
		NOMBRE: 'Arandilla',
	},
	{
		CPRO: '09',
		CMUN: '020',
		DC: '0',
		NOMBRE: 'Arauzo de Miel',
	},
	{
		CPRO: '09',
		CMUN: '021',
		DC: '7',
		NOMBRE: 'Arauzo de Salce',
	},
	{
		CPRO: '09',
		CMUN: '022',
		DC: '2',
		NOMBRE: 'Arauzo de Torre',
	},
	{
		CPRO: '09',
		CMUN: '023',
		DC: '8',
		NOMBRE: 'Arcos',
	},
	{
		CPRO: '09',
		CMUN: '024',
		DC: '3',
		NOMBRE: 'Arenillas de Riopisuerga',
	},
	{
		CPRO: '09',
		CMUN: '025',
		DC: '6',
		NOMBRE: 'Arija',
	},
	{
		CPRO: '09',
		CMUN: '026',
		DC: '9',
		NOMBRE: 'Arlanzón',
	},
	{
		CPRO: '09',
		CMUN: '027',
		DC: '5',
		NOMBRE: 'Arraya de Oca',
	},
	{
		CPRO: '09',
		CMUN: '029',
		DC: '4',
		NOMBRE: 'Atapuerca',
	},
	{
		CPRO: '09',
		CMUN: '030',
		DC: '8',
		NOMBRE: 'Ausines, Los',
	},
	{
		CPRO: '09',
		CMUN: '032',
		DC: '0',
		NOMBRE: 'Avellanosa de Muñó',
	},
	{
		CPRO: '09',
		CMUN: '033',
		DC: '6',
		NOMBRE: 'Bahabón de Esgueva',
	},
	{
		CPRO: '09',
		CMUN: '034',
		DC: '1',
		NOMBRE: 'Balbases, Los',
	},
	{
		CPRO: '09',
		CMUN: '035',
		DC: '4',
		NOMBRE: 'Baños de Valdearados',
	},
	{
		CPRO: '09',
		CMUN: '036',
		DC: '7',
		NOMBRE: 'Bañuelos de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '037',
		DC: '3',
		NOMBRE: 'Barbadillo de Herreros',
	},
	{
		CPRO: '09',
		CMUN: '038',
		DC: '9',
		NOMBRE: 'Barbadillo del Mercado',
	},
	{
		CPRO: '09',
		CMUN: '039',
		DC: '2',
		NOMBRE: 'Barbadillo del Pez',
	},
	{
		CPRO: '09',
		CMUN: '041',
		DC: '3',
		NOMBRE: 'Barrio de Muñó',
	},
	{
		CPRO: '09',
		CMUN: '043',
		DC: '4',
		NOMBRE: 'Barrios de Bureba, Los',
	},
	{
		CPRO: '09',
		CMUN: '044',
		DC: '9',
		NOMBRE: 'Barrios de Colina',
	},
	{
		CPRO: '09',
		CMUN: '045',
		DC: '2',
		NOMBRE: 'Basconcillos del Tozo',
	},
	{
		CPRO: '09',
		CMUN: '046',
		DC: '5',
		NOMBRE: 'Bascuñana',
	},
	{
		CPRO: '09',
		CMUN: '047',
		DC: '1',
		NOMBRE: 'Belbimbre',
	},
	{
		CPRO: '09',
		CMUN: '048',
		DC: '7',
		NOMBRE: 'Belorado',
	},
	{
		CPRO: '09',
		CMUN: '050',
		DC: '3',
		NOMBRE: 'Berberana',
	},
	{
		CPRO: '09',
		CMUN: '051',
		DC: '0',
		NOMBRE: 'Berlangas de Roa',
	},
	{
		CPRO: '09',
		CMUN: '052',
		DC: '5',
		NOMBRE: 'Berzosa de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '054',
		DC: '6',
		NOMBRE: 'Bozoó',
	},
	{
		CPRO: '09',
		CMUN: '055',
		DC: '9',
		NOMBRE: 'Brazacorta',
	},
	{
		CPRO: '09',
		CMUN: '056',
		DC: '2',
		NOMBRE: 'Briviesca',
	},
	{
		CPRO: '09',
		CMUN: '057',
		DC: '8',
		NOMBRE: 'Bugedo',
	},
	{
		CPRO: '09',
		CMUN: '058',
		DC: '4',
		NOMBRE: 'Buniel',
	},
	{
		CPRO: '09',
		CMUN: '059',
		DC: '7',
		NOMBRE: 'Burgos',
	},
	{
		CPRO: '09',
		CMUN: '060',
		DC: '1',
		NOMBRE: 'Busto de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '061',
		DC: '8',
		NOMBRE: 'Cabañes de Esgueva',
	},
	{
		CPRO: '09',
		CMUN: '062',
		DC: '3',
		NOMBRE: 'Cabezón de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '064',
		DC: '4',
		NOMBRE: 'Caleruega',
	},
	{
		CPRO: '09',
		CMUN: '065',
		DC: '7',
		NOMBRE: 'Campillo de Aranda',
	},
	{
		CPRO: '09',
		CMUN: '066',
		DC: '0',
		NOMBRE: 'Campolara',
	},
	{
		CPRO: '09',
		CMUN: '067',
		DC: '6',
		NOMBRE: 'Canicosa de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '068',
		DC: '2',
		NOMBRE: 'Cantabrana',
	},
	{
		CPRO: '09',
		CMUN: '070',
		DC: '9',
		NOMBRE: 'Carazo',
	},
	{
		CPRO: '09',
		CMUN: '071',
		DC: '6',
		NOMBRE: 'Carcedo de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '072',
		DC: '1',
		NOMBRE: 'Carcedo de Burgos',
	},
	{
		CPRO: '09',
		CMUN: '073',
		DC: '7',
		NOMBRE: 'Cardeñadijo',
	},
	{
		CPRO: '09',
		CMUN: '074',
		DC: '2',
		NOMBRE: 'Cardeñajimeno',
	},
	{
		CPRO: '09',
		CMUN: '075',
		DC: '5',
		NOMBRE: 'Cardeñuela Riopico',
	},
	{
		CPRO: '09',
		CMUN: '076',
		DC: '8',
		NOMBRE: 'Carrias',
	},
	{
		CPRO: '09',
		CMUN: '077',
		DC: '4',
		NOMBRE: 'Cascajares de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '078',
		DC: '0',
		NOMBRE: 'Cascajares de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '079',
		DC: '3',
		NOMBRE: 'Castellanos de Castro',
	},
	{
		CPRO: '09',
		CMUN: '083',
		DC: '5',
		NOMBRE: 'Castil de Peones',
	},
	{
		CPRO: '09',
		CMUN: '082',
		DC: '9',
		NOMBRE: 'Castildelgado',
	},
	{
		CPRO: '09',
		CMUN: '084',
		DC: '0',
		NOMBRE: 'Castrillo de la Reina',
	},
	{
		CPRO: '09',
		CMUN: '085',
		DC: '3',
		NOMBRE: 'Castrillo de la Vega',
	},
	{
		CPRO: '09',
		CMUN: '088',
		DC: '8',
		NOMBRE: 'Castrillo de Riopisuerga',
	},
	{
		CPRO: '09',
		CMUN: '086',
		DC: '6',
		NOMBRE: 'Castrillo del Val',
	},
	{
		CPRO: '09',
		CMUN: '090',
		DC: '5',
		NOMBRE: 'Castrillo Matajudíos',
	},
	{
		CPRO: '09',
		CMUN: '091',
		DC: '2',
		NOMBRE: 'Castrojeriz',
	},
	{
		CPRO: '09',
		CMUN: '063',
		DC: '9',
		NOMBRE: 'Cavia',
	},
	{
		CPRO: '09',
		CMUN: '093',
		DC: '3',
		NOMBRE: 'Cayuela',
	},
	{
		CPRO: '09',
		CMUN: '094',
		DC: '8',
		NOMBRE: 'Cebrecos',
	},
	{
		CPRO: '09',
		CMUN: '095',
		DC: '1',
		NOMBRE: 'Celada del Camino',
	},
	{
		CPRO: '09',
		CMUN: '098',
		DC: '6',
		NOMBRE: 'Cerezo de Río Tirón',
	},
	{
		CPRO: '09',
		CMUN: '100',
		DC: '3',
		NOMBRE: 'Cerratón de Juarros',
	},
	{
		CPRO: '09',
		CMUN: '101',
		DC: '0',
		NOMBRE: 'Ciadoncha',
	},
	{
		CPRO: '09',
		CMUN: '102',
		DC: '5',
		NOMBRE: 'Cillaperlata',
	},
	{
		CPRO: '09',
		CMUN: '103',
		DC: '1',
		NOMBRE: 'Cilleruelo de Abajo',
	},
	{
		CPRO: '09',
		CMUN: '104',
		DC: '6',
		NOMBRE: 'Cilleruelo de Arriba',
	},
	{
		CPRO: '09',
		CMUN: '105',
		DC: '9',
		NOMBRE: 'Ciruelos de Cervera',
	},
	{
		CPRO: '09',
		CMUN: '108',
		DC: '4',
		NOMBRE: 'Cogollos',
	},
	{
		CPRO: '09',
		CMUN: '109',
		DC: '7',
		NOMBRE: 'Condado de Treviño',
	},
	{
		CPRO: '09',
		CMUN: '110',
		DC: '1',
		NOMBRE: 'Contreras',
	},
	{
		CPRO: '09',
		CMUN: '112',
		DC: '3',
		NOMBRE: 'Coruña del Conde',
	},
	{
		CPRO: '09',
		CMUN: '113',
		DC: '9',
		NOMBRE: 'Covarrubias',
	},
	{
		CPRO: '09',
		CMUN: '114',
		DC: '4',
		NOMBRE: 'Cubillo del Campo',
	},
	{
		CPRO: '09',
		CMUN: '115',
		DC: '7',
		NOMBRE: 'Cubo de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '117',
		DC: '6',
		NOMBRE: 'Cueva de Roa, La',
	},
	{
		CPRO: '09',
		CMUN: '119',
		DC: '5',
		NOMBRE: 'Cuevas de San Clemente',
	},
	{
		CPRO: '09',
		CMUN: '120',
		DC: '9',
		NOMBRE: 'Encío',
	},
	{
		CPRO: '09',
		CMUN: '122',
		DC: '1',
		NOMBRE: 'Espinosa de Cervera',
	},
	{
		CPRO: '09',
		CMUN: '124',
		DC: '2',
		NOMBRE: 'Espinosa de los Monteros',
	},
	{
		CPRO: '09',
		CMUN: '123',
		DC: '7',
		NOMBRE: 'Espinosa del Camino',
	},
	{
		CPRO: '09',
		CMUN: '125',
		DC: '5',
		NOMBRE: 'Estépar',
	},
	{
		CPRO: '09',
		CMUN: '127',
		DC: '4',
		NOMBRE: 'Fontioso',
	},
	{
		CPRO: '09',
		CMUN: '128',
		DC: '0',
		NOMBRE: 'Frandovínez',
	},
	{
		CPRO: '09',
		CMUN: '129',
		DC: '3',
		NOMBRE: 'Fresneda de la Sierra Tirón',
	},
	{
		CPRO: '09',
		CMUN: '130',
		DC: '7',
		NOMBRE: 'Fresneña',
	},
	{
		CPRO: '09',
		CMUN: '131',
		DC: '4',
		NOMBRE: 'Fresnillo de las Dueñas',
	},
	{
		CPRO: '09',
		CMUN: '132',
		DC: '9',
		NOMBRE: 'Fresno de Río Tirón',
	},
	{
		CPRO: '09',
		CMUN: '133',
		DC: '5',
		NOMBRE: 'Fresno de Rodilla',
	},
	{
		CPRO: '09',
		CMUN: '134',
		DC: '0',
		NOMBRE: 'Frías',
	},
	{
		CPRO: '09',
		CMUN: '135',
		DC: '3',
		NOMBRE: 'Fuentebureba',
	},
	{
		CPRO: '09',
		CMUN: '136',
		DC: '6',
		NOMBRE: 'Fuentecén',
	},
	{
		CPRO: '09',
		CMUN: '137',
		DC: '2',
		NOMBRE: 'Fuentelcésped',
	},
	{
		CPRO: '09',
		CMUN: '138',
		DC: '8',
		NOMBRE: 'Fuentelisendo',
	},
	{
		CPRO: '09',
		CMUN: '139',
		DC: '1',
		NOMBRE: 'Fuentemolinos',
	},
	{
		CPRO: '09',
		CMUN: '140',
		DC: '5',
		NOMBRE: 'Fuentenebro',
	},
	{
		CPRO: '09',
		CMUN: '141',
		DC: '2',
		NOMBRE: 'Fuentespina',
	},
	{
		CPRO: '09',
		CMUN: '143',
		DC: '3',
		NOMBRE: 'Galbarros',
	},
	{
		CPRO: '09',
		CMUN: '144',
		DC: '8',
		NOMBRE: 'Gallega, La',
	},
	{
		CPRO: '09',
		CMUN: '148',
		DC: '6',
		NOMBRE: 'Grijalba',
	},
	{
		CPRO: '09',
		CMUN: '149',
		DC: '9',
		NOMBRE: 'Grisaleña',
	},
	{
		CPRO: '09',
		CMUN: '151',
		DC: '9',
		NOMBRE: 'Gumiel de Izán',
	},
	{
		CPRO: '09',
		CMUN: '152',
		DC: '4',
		NOMBRE: 'Gumiel de Mercado',
	},
	{
		CPRO: '09',
		CMUN: '154',
		DC: '5',
		NOMBRE: 'Hacinas',
	},
	{
		CPRO: '09',
		CMUN: '155',
		DC: '8',
		NOMBRE: 'Haza',
	},
	{
		CPRO: '09',
		CMUN: '159',
		DC: '6',
		NOMBRE: 'Hontanas',
	},
	{
		CPRO: '09',
		CMUN: '160',
		DC: '0',
		NOMBRE: 'Hontangas',
	},
	{
		CPRO: '09',
		CMUN: '162',
		DC: '2',
		NOMBRE: 'Hontoria de la Cantera',
	},
	{
		CPRO: '09',
		CMUN: '164',
		DC: '3',
		NOMBRE: 'Hontoria de Valdearados',
	},
	{
		CPRO: '09',
		CMUN: '163',
		DC: '8',
		NOMBRE: 'Hontoria del Pinar',
	},
	{
		CPRO: '09',
		CMUN: '166',
		DC: '9',
		NOMBRE: 'Hormazas, Las',
	},
	{
		CPRO: '09',
		CMUN: '167',
		DC: '5',
		NOMBRE: 'Hornillos del Camino',
	},
	{
		CPRO: '09',
		CMUN: '168',
		DC: '1',
		NOMBRE: 'Horra, La',
	},
	{
		CPRO: '09',
		CMUN: '169',
		DC: '4',
		NOMBRE: 'Hortigüela',
	},
	{
		CPRO: '09',
		CMUN: '170',
		DC: '8',
		NOMBRE: 'Hoyales de Roa',
	},
	{
		CPRO: '09',
		CMUN: '172',
		DC: '0',
		NOMBRE: 'Huérmeces',
	},
	{
		CPRO: '09',
		CMUN: '173',
		DC: '6',
		NOMBRE: 'Huerta de Arriba',
	},
	{
		CPRO: '09',
		CMUN: '174',
		DC: '1',
		NOMBRE: 'Huerta de Rey',
	},
	{
		CPRO: '09',
		CMUN: '175',
		DC: '4',
		NOMBRE: 'Humada',
	},
	{
		CPRO: '09',
		CMUN: '176',
		DC: '7',
		NOMBRE: 'Hurones',
	},
	{
		CPRO: '09',
		CMUN: '177',
		DC: '3',
		NOMBRE: 'Ibeas de Juarros',
	},
	{
		CPRO: '09',
		CMUN: '178',
		DC: '9',
		NOMBRE: 'Ibrillos',
	},
	{
		CPRO: '09',
		CMUN: '179',
		DC: '2',
		NOMBRE: 'Iglesiarrubia',
	},
	{
		CPRO: '09',
		CMUN: '180',
		DC: '6',
		NOMBRE: 'Iglesias',
	},
	{
		CPRO: '09',
		CMUN: '181',
		DC: '3',
		NOMBRE: 'Isar',
	},
	{
		CPRO: '09',
		CMUN: '182',
		DC: '8',
		NOMBRE: 'Itero del Castillo',
	},
	{
		CPRO: '09',
		CMUN: '183',
		DC: '4',
		NOMBRE: 'Jaramillo de la Fuente',
	},
	{
		CPRO: '09',
		CMUN: '184',
		DC: '9',
		NOMBRE: 'Jaramillo Quemado',
	},
	{
		CPRO: '09',
		CMUN: '189',
		DC: '0',
		NOMBRE: 'Junta de Traslaloma',
	},
	{
		CPRO: '09',
		CMUN: '190',
		DC: '4',
		NOMBRE: 'Junta de Villalba de Losa',
	},
	{
		CPRO: '09',
		CMUN: '191',
		DC: '1',
		NOMBRE: 'Jurisdicción de Lara',
	},
	{
		CPRO: '09',
		CMUN: '192',
		DC: '6',
		NOMBRE: 'Jurisdicción de San Zadornil',
	},
	{
		CPRO: '09',
		CMUN: '194',
		DC: '7',
		NOMBRE: 'Lerma',
	},
	{
		CPRO: '09',
		CMUN: '195',
		DC: '0',
		NOMBRE: 'Llano de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '196',
		DC: '3',
		NOMBRE: 'Madrigal del Monte',
	},
	{
		CPRO: '09',
		CMUN: '197',
		DC: '9',
		NOMBRE: 'Madrigalejo del Monte',
	},
	{
		CPRO: '09',
		CMUN: '198',
		DC: '5',
		NOMBRE: 'Mahamud',
	},
	{
		CPRO: '09',
		CMUN: '199',
		DC: '8',
		NOMBRE: 'Mambrilla de Castrejón',
	},
	{
		CPRO: '09',
		CMUN: '200',
		DC: '2',
		NOMBRE: 'Mambrillas de Lara',
	},
	{
		CPRO: '09',
		CMUN: '201',
		DC: '9',
		NOMBRE: 'Mamolar',
	},
	{
		CPRO: '09',
		CMUN: '202',
		DC: '4',
		NOMBRE: 'Manciles',
	},
	{
		CPRO: '09',
		CMUN: '206',
		DC: '1',
		NOMBRE: 'Mazuela',
	},
	{
		CPRO: '09',
		CMUN: '208',
		DC: '3',
		NOMBRE: 'Mecerreyes',
	},
	{
		CPRO: '09',
		CMUN: '209',
		DC: '6',
		NOMBRE: 'Medina de Pomar',
	},
	{
		CPRO: '09',
		CMUN: '211',
		DC: '7',
		NOMBRE: 'Melgar de Fernamental',
	},
	{
		CPRO: '09',
		CMUN: '213',
		DC: '8',
		NOMBRE: 'Merindad de Cuesta-Urria',
	},
	{
		CPRO: '09',
		CMUN: '214',
		DC: '3',
		NOMBRE: 'Merindad de Montija',
	},
	{
		CPRO: '09',
		CMUN: '906',
		DC: '4',
		NOMBRE: 'Merindad de Río Ubierna',
	},
	{
		CPRO: '09',
		CMUN: '215',
		DC: '6',
		NOMBRE: 'Merindad de Sotoscueva',
	},
	{
		CPRO: '09',
		CMUN: '216',
		DC: '9',
		NOMBRE: 'Merindad de Valdeporres',
	},
	{
		CPRO: '09',
		CMUN: '217',
		DC: '5',
		NOMBRE: 'Merindad de Valdivielso',
	},
	{
		CPRO: '09',
		CMUN: '218',
		DC: '1',
		NOMBRE: 'Milagros',
	},
	{
		CPRO: '09',
		CMUN: '219',
		DC: '4',
		NOMBRE: 'Miranda de Ebro',
	},
	{
		CPRO: '09',
		CMUN: '220',
		DC: '8',
		NOMBRE: 'Miraveche',
	},
	{
		CPRO: '09',
		CMUN: '221',
		DC: '5',
		NOMBRE: 'Modúbar de la Emparedada',
	},
	{
		CPRO: '09',
		CMUN: '223',
		DC: '6',
		NOMBRE: 'Monasterio de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '224',
		DC: '1',
		NOMBRE: 'Monasterio de Rodilla',
	},
	{
		CPRO: '09',
		CMUN: '225',
		DC: '4',
		NOMBRE: 'Moncalvillo',
	},
	{
		CPRO: '09',
		CMUN: '226',
		DC: '7',
		NOMBRE: 'Monterrubio de la Demanda',
	},
	{
		CPRO: '09',
		CMUN: '227',
		DC: '3',
		NOMBRE: 'Montorio',
	},
	{
		CPRO: '09',
		CMUN: '228',
		DC: '9',
		NOMBRE: 'Moradillo de Roa',
	},
	{
		CPRO: '09',
		CMUN: '229',
		DC: '2',
		NOMBRE: 'Nava de Roa',
	},
	{
		CPRO: '09',
		CMUN: '230',
		DC: '6',
		NOMBRE: 'Navas de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '231',
		DC: '3',
		NOMBRE: 'Nebreda',
	},
	{
		CPRO: '09',
		CMUN: '232',
		DC: '8',
		NOMBRE: 'Neila',
	},
	{
		CPRO: '09',
		CMUN: '235',
		DC: '2',
		NOMBRE: 'Olmedillo de Roa',
	},
	{
		CPRO: '09',
		CMUN: '236',
		DC: '5',
		NOMBRE: 'Olmillos de Muñó',
	},
	{
		CPRO: '09',
		CMUN: '238',
		DC: '7',
		NOMBRE: 'Oña',
	},
	{
		CPRO: '09',
		CMUN: '239',
		DC: '0',
		NOMBRE: 'Oquillas',
	},
	{
		CPRO: '09',
		CMUN: '241',
		DC: '1',
		NOMBRE: 'Orbaneja Riopico',
	},
	{
		CPRO: '09',
		CMUN: '242',
		DC: '6',
		NOMBRE: 'Padilla de Abajo',
	},
	{
		CPRO: '09',
		CMUN: '243',
		DC: '2',
		NOMBRE: 'Padilla de Arriba',
	},
	{
		CPRO: '09',
		CMUN: '244',
		DC: '7',
		NOMBRE: 'Padrones de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '246',
		DC: '3',
		NOMBRE: 'Palacios de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '247',
		DC: '9',
		NOMBRE: 'Palacios de Riopisuerga',
	},
	{
		CPRO: '09',
		CMUN: '248',
		DC: '5',
		NOMBRE: 'Palazuelos de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '249',
		DC: '8',
		NOMBRE: 'Palazuelos de Muñó',
	},
	{
		CPRO: '09',
		CMUN: '250',
		DC: '1',
		NOMBRE: 'Pampliega',
	},
	{
		CPRO: '09',
		CMUN: '251',
		DC: '8',
		NOMBRE: 'Pancorbo',
	},
	{
		CPRO: '09',
		CMUN: '253',
		DC: '9',
		NOMBRE: 'Pardilla',
	},
	{
		CPRO: '09',
		CMUN: '255',
		DC: '7',
		NOMBRE: 'Partido de la Sierra en Tobalina',
	},
	{
		CPRO: '09',
		CMUN: '256',
		DC: '0',
		NOMBRE: 'Pedrosa de Duero',
	},
	{
		CPRO: '09',
		CMUN: '259',
		DC: '5',
		NOMBRE: 'Pedrosa de Río Úrbel',
	},
	{
		CPRO: '09',
		CMUN: '257',
		DC: '6',
		NOMBRE: 'Pedrosa del Páramo',
	},
	{
		CPRO: '09',
		CMUN: '258',
		DC: '2',
		NOMBRE: 'Pedrosa del Príncipe',
	},
	{
		CPRO: '09',
		CMUN: '261',
		DC: '6',
		NOMBRE: 'Peñaranda de Duero',
	},
	{
		CPRO: '09',
		CMUN: '262',
		DC: '1',
		NOMBRE: 'Peral de Arlanza',
	},
	{
		CPRO: '09',
		CMUN: '265',
		DC: '5',
		NOMBRE: 'Piérnigas',
	},
	{
		CPRO: '09',
		CMUN: '266',
		DC: '8',
		NOMBRE: 'Pineda de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '267',
		DC: '4',
		NOMBRE: 'Pineda Trasmonte',
	},
	{
		CPRO: '09',
		CMUN: '268',
		DC: '0',
		NOMBRE: 'Pinilla de los Barruecos',
	},
	{
		CPRO: '09',
		CMUN: '269',
		DC: '3',
		NOMBRE: 'Pinilla de los Moros',
	},
	{
		CPRO: '09',
		CMUN: '270',
		DC: '7',
		NOMBRE: 'Pinilla Trasmonte',
	},
	{
		CPRO: '09',
		CMUN: '272',
		DC: '9',
		NOMBRE: 'Poza de la Sal',
	},
	{
		CPRO: '09',
		CMUN: '273',
		DC: '5',
		NOMBRE: 'Prádanos de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '274',
		DC: '0',
		NOMBRE: 'Pradoluengo',
	},
	{
		CPRO: '09',
		CMUN: '275',
		DC: '3',
		NOMBRE: 'Presencio',
	},
	{
		CPRO: '09',
		CMUN: '276',
		DC: '6',
		NOMBRE: 'Puebla de Arganzón, La',
	},
	{
		CPRO: '09',
		CMUN: '277',
		DC: '2',
		NOMBRE: 'Puentedura',
	},
	{
		CPRO: '09',
		CMUN: '279',
		DC: '1',
		NOMBRE: 'Quemada',
	},
	{
		CPRO: '09',
		CMUN: '281',
		DC: '2',
		NOMBRE: 'Quintana del Pidio',
	},
	{
		CPRO: '09',
		CMUN: '280',
		DC: '5',
		NOMBRE: 'Quintanabureba',
	},
	{
		CPRO: '09',
		CMUN: '283',
		DC: '3',
		NOMBRE: 'Quintanaélez',
	},
	{
		CPRO: '09',
		CMUN: '287',
		DC: '0',
		NOMBRE: 'Quintanaortuño',
	},
	{
		CPRO: '09',
		CMUN: '288',
		DC: '6',
		NOMBRE: 'Quintanapalla',
	},
	{
		CPRO: '09',
		CMUN: '289',
		DC: '9',
		NOMBRE: 'Quintanar de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '292',
		DC: '5',
		NOMBRE: 'Quintanavides',
	},
	{
		CPRO: '09',
		CMUN: '294',
		DC: '6',
		NOMBRE: 'Quintanilla de la Mata',
	},
	{
		CPRO: '09',
		CMUN: '901',
		DC: '2',
		NOMBRE: 'Quintanilla del Agua y Tordueles',
	},
	{
		CPRO: '09',
		CMUN: '295',
		DC: '9',
		NOMBRE: 'Quintanilla del Coco',
	},
	{
		CPRO: '09',
		CMUN: '298',
		DC: '4',
		NOMBRE: 'Quintanilla San García',
	},
	{
		CPRO: '09',
		CMUN: '301',
		DC: '8',
		NOMBRE: 'Quintanilla Vivar',
	},
	{
		CPRO: '09',
		CMUN: '297',
		DC: '8',
		NOMBRE: 'Quintanillas, Las',
	},
	{
		CPRO: '09',
		CMUN: '302',
		DC: '3',
		NOMBRE: 'Rabanera del Pinar',
	},
	{
		CPRO: '09',
		CMUN: '303',
		DC: '9',
		NOMBRE: 'Rábanos',
	},
	{
		CPRO: '09',
		CMUN: '304',
		DC: '4',
		NOMBRE: 'Rabé de las Calzadas',
	},
	{
		CPRO: '09',
		CMUN: '306',
		DC: '0',
		NOMBRE: 'Rebolledo de la Torre',
	},
	{
		CPRO: '09',
		CMUN: '307',
		DC: '6',
		NOMBRE: 'Redecilla del Camino',
	},
	{
		CPRO: '09',
		CMUN: '308',
		DC: '2',
		NOMBRE: 'Redecilla del Campo',
	},
	{
		CPRO: '09',
		CMUN: '309',
		DC: '5',
		NOMBRE: 'Regumiel de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '310',
		DC: '9',
		NOMBRE: 'Reinoso',
	},
	{
		CPRO: '09',
		CMUN: '311',
		DC: '6',
		NOMBRE: 'Retuerta',
	},
	{
		CPRO: '09',
		CMUN: '314',
		DC: '2',
		NOMBRE: 'Revilla del Campo',
	},
	{
		CPRO: '09',
		CMUN: '316',
		DC: '8',
		NOMBRE: 'Revilla Vallejera',
	},
	{
		CPRO: '09',
		CMUN: '312',
		DC: '1',
		NOMBRE: 'Revilla y Ahedo, La',
	},
	{
		CPRO: '09',
		CMUN: '315',
		DC: '5',
		NOMBRE: 'Revillarruz',
	},
	{
		CPRO: '09',
		CMUN: '317',
		DC: '4',
		NOMBRE: 'Rezmondo',
	},
	{
		CPRO: '09',
		CMUN: '318',
		DC: '0',
		NOMBRE: 'Riocavado de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '321',
		DC: '4',
		NOMBRE: 'Roa',
	},
	{
		CPRO: '09',
		CMUN: '323',
		DC: '5',
		NOMBRE: 'Rojas',
	},
	{
		CPRO: '09',
		CMUN: '325',
		DC: '3',
		NOMBRE: 'Royuela de Río Franco',
	},
	{
		CPRO: '09',
		CMUN: '326',
		DC: '6',
		NOMBRE: 'Rubena',
	},
	{
		CPRO: '09',
		CMUN: '327',
		DC: '2',
		NOMBRE: 'Rublacedo de Abajo',
	},
	{
		CPRO: '09',
		CMUN: '328',
		DC: '8',
		NOMBRE: 'Rucandio',
	},
	{
		CPRO: '09',
		CMUN: '329',
		DC: '1',
		NOMBRE: 'Salas de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '330',
		DC: '5',
		NOMBRE: 'Salas de los Infantes',
	},
	{
		CPRO: '09',
		CMUN: '332',
		DC: '7',
		NOMBRE: 'Saldaña de Burgos',
	},
	{
		CPRO: '09',
		CMUN: '334',
		DC: '8',
		NOMBRE: 'Salinillas de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '335',
		DC: '1',
		NOMBRE: 'San Adrián de Juarros',
	},
	{
		CPRO: '09',
		CMUN: '337',
		DC: '0',
		NOMBRE: 'San Juan del Monte',
	},
	{
		CPRO: '09',
		CMUN: '338',
		DC: '6',
		NOMBRE: 'San Mamés de Burgos',
	},
	{
		CPRO: '09',
		CMUN: '339',
		DC: '9',
		NOMBRE: 'San Martín de Rubiales',
	},
	{
		CPRO: '09',
		CMUN: '340',
		DC: '3',
		NOMBRE: 'San Millán de Lara',
	},
	{
		CPRO: '09',
		CMUN: '360',
		DC: '8',
		NOMBRE: 'San Vicente del Valle',
	},
	{
		CPRO: '09',
		CMUN: '343',
		DC: '1',
		NOMBRE: 'Santa Cecilia',
	},
	{
		CPRO: '09',
		CMUN: '345',
		DC: '9',
		NOMBRE: 'Santa Cruz de la Salceda',
	},
	{
		CPRO: '09',
		CMUN: '346',
		DC: '2',
		NOMBRE: 'Santa Cruz del Valle Urbión',
	},
	{
		CPRO: '09',
		CMUN: '347',
		DC: '8',
		NOMBRE: 'Santa Gadea del Cid',
	},
	{
		CPRO: '09',
		CMUN: '348',
		DC: '4',
		NOMBRE: 'Santa Inés',
	},
	{
		CPRO: '09',
		CMUN: '350',
		DC: '0',
		NOMBRE: 'Santa María del Campo',
	},
	{
		CPRO: '09',
		CMUN: '351',
		DC: '7',
		NOMBRE: 'Santa María del Invierno',
	},
	{
		CPRO: '09',
		CMUN: '352',
		DC: '2',
		NOMBRE: 'Santa María del Mercadillo',
	},
	{
		CPRO: '09',
		CMUN: '353',
		DC: '8',
		NOMBRE: 'Santa María Rivarredonda',
	},
	{
		CPRO: '09',
		CMUN: '354',
		DC: '3',
		NOMBRE: 'Santa Olalla de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '355',
		DC: '6',
		NOMBRE: 'Santibáñez de Esgueva',
	},
	{
		CPRO: '09',
		CMUN: '356',
		DC: '9',
		NOMBRE: 'Santibáñez del Val',
	},
	{
		CPRO: '09',
		CMUN: '358',
		DC: '1',
		NOMBRE: 'Santo Domingo de Silos',
	},
	{
		CPRO: '09',
		CMUN: '361',
		DC: '5',
		NOMBRE: 'Sargentes de la Lora',
	},
	{
		CPRO: '09',
		CMUN: '362',
		DC: '0',
		NOMBRE: 'Sarracín',
	},
	{
		CPRO: '09',
		CMUN: '363',
		DC: '6',
		NOMBRE: 'Sasamón',
	},
	{
		CPRO: '09',
		CMUN: '365',
		DC: '4',
		NOMBRE: 'Sequera de Haza, La',
	},
	{
		CPRO: '09',
		CMUN: '366',
		DC: '7',
		NOMBRE: 'Solarana',
	},
	{
		CPRO: '09',
		CMUN: '368',
		DC: '9',
		NOMBRE: 'Sordillos',
	},
	{
		CPRO: '09',
		CMUN: '369',
		DC: '2',
		NOMBRE: 'Sotillo de la Ribera',
	},
	{
		CPRO: '09',
		CMUN: '372',
		DC: '8',
		NOMBRE: 'Sotragero',
	},
	{
		CPRO: '09',
		CMUN: '373',
		DC: '4',
		NOMBRE: 'Sotresgudo',
	},
	{
		CPRO: '09',
		CMUN: '374',
		DC: '9',
		NOMBRE: 'Susinos del Páramo',
	},
	{
		CPRO: '09',
		CMUN: '375',
		DC: '2',
		NOMBRE: 'Tamarón',
	},
	{
		CPRO: '09',
		CMUN: '377',
		DC: '1',
		NOMBRE: 'Tardajos',
	},
	{
		CPRO: '09',
		CMUN: '378',
		DC: '7',
		NOMBRE: 'Tejada',
	},
	{
		CPRO: '09',
		CMUN: '380',
		DC: '4',
		NOMBRE: 'Terradillos de Esgueva',
	},
	{
		CPRO: '09',
		CMUN: '381',
		DC: '1',
		NOMBRE: 'Tinieblas de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '382',
		DC: '6',
		NOMBRE: 'Tobar',
	},
	{
		CPRO: '09',
		CMUN: '384',
		DC: '7',
		NOMBRE: 'Tordómar',
	},
	{
		CPRO: '09',
		CMUN: '386',
		DC: '3',
		NOMBRE: 'Torrecilla del Monte',
	},
	{
		CPRO: '09',
		CMUN: '387',
		DC: '9',
		NOMBRE: 'Torregalindo',
	},
	{
		CPRO: '09',
		CMUN: '388',
		DC: '5',
		NOMBRE: 'Torrelara',
	},
	{
		CPRO: '09',
		CMUN: '389',
		DC: '8',
		NOMBRE: 'Torrepadre',
	},
	{
		CPRO: '09',
		CMUN: '390',
		DC: '2',
		NOMBRE: 'Torresandino',
	},
	{
		CPRO: '09',
		CMUN: '391',
		DC: '9',
		NOMBRE: 'Tórtoles de Esgueva',
	},
	{
		CPRO: '09',
		CMUN: '392',
		DC: '4',
		NOMBRE: 'Tosantos',
	},
	{
		CPRO: '09',
		CMUN: '394',
		DC: '5',
		NOMBRE: 'Trespaderne',
	},
	{
		CPRO: '09',
		CMUN: '395',
		DC: '8',
		NOMBRE: 'Tubilla del Agua',
	},
	{
		CPRO: '09',
		CMUN: '396',
		DC: '1',
		NOMBRE: 'Tubilla del Lago',
	},
	{
		CPRO: '09',
		CMUN: '398',
		DC: '3',
		NOMBRE: 'Úrbel del Castillo',
	},
	{
		CPRO: '09',
		CMUN: '400',
		DC: '0',
		NOMBRE: 'Vadocondes',
	},
	{
		CPRO: '09',
		CMUN: '403',
		DC: '8',
		NOMBRE: 'Valdeande',
	},
	{
		CPRO: '09',
		CMUN: '405',
		DC: '6',
		NOMBRE: 'Valdezate',
	},
	{
		CPRO: '09',
		CMUN: '406',
		DC: '9',
		NOMBRE: 'Valdorros',
	},
	{
		CPRO: '09',
		CMUN: '408',
		DC: '1',
		NOMBRE: 'Vallarta de Bureba',
	},
	{
		CPRO: '09',
		CMUN: '904',
		DC: '8',
		NOMBRE: 'Valle de las Navas',
	},
	{
		CPRO: '09',
		CMUN: '908',
		DC: '6',
		NOMBRE: 'Valle de Losa',
	},
	{
		CPRO: '09',
		CMUN: '409',
		DC: '4',
		NOMBRE: 'Valle de Manzanedo',
	},
	{
		CPRO: '09',
		CMUN: '410',
		DC: '8',
		NOMBRE: 'Valle de Mena',
	},
	{
		CPRO: '09',
		CMUN: '411',
		DC: '5',
		NOMBRE: 'Valle de Oca',
	},
	{
		CPRO: '09',
		CMUN: '902',
		DC: '7',
		NOMBRE: 'Valle de Santibáñez',
	},
	{
		CPRO: '09',
		CMUN: '905',
		DC: '1',
		NOMBRE: 'Valle de Sedano',
	},
	{
		CPRO: '09',
		CMUN: '412',
		DC: '0',
		NOMBRE: 'Valle de Tobalina',
	},
	{
		CPRO: '09',
		CMUN: '413',
		DC: '6',
		NOMBRE: 'Valle de Valdebezana',
	},
	{
		CPRO: '09',
		CMUN: '414',
		DC: '1',
		NOMBRE: 'Valle de Valdelaguna',
	},
	{
		CPRO: '09',
		CMUN: '415',
		DC: '4',
		NOMBRE: 'Valle de Valdelucio',
	},
	{
		CPRO: '09',
		CMUN: '416',
		DC: '7',
		NOMBRE: 'Valle de Zamanzas',
	},
	{
		CPRO: '09',
		CMUN: '417',
		DC: '3',
		NOMBRE: 'Vallejera',
	},
	{
		CPRO: '09',
		CMUN: '418',
		DC: '9',
		NOMBRE: 'Valles de Palenzuela',
	},
	{
		CPRO: '09',
		CMUN: '419',
		DC: '2',
		NOMBRE: 'Valluércanes',
	},
	{
		CPRO: '09',
		CMUN: '407',
		DC: '5',
		NOMBRE: 'Valmala',
	},
	{
		CPRO: '09',
		CMUN: '422',
		DC: '8',
		NOMBRE: 'Vid de Bureba, La',
	},
	{
		CPRO: '09',
		CMUN: '421',
		DC: '3',
		NOMBRE: 'Vid y Barrios, La',
	},
	{
		CPRO: '09',
		CMUN: '423',
		DC: '4',
		NOMBRE: 'Vileña',
	},
	{
		CPRO: '09',
		CMUN: '427',
		DC: '1',
		NOMBRE: 'Villadiego',
	},
	{
		CPRO: '09',
		CMUN: '428',
		DC: '7',
		NOMBRE: 'Villaescusa de Roa',
	},
	{
		CPRO: '09',
		CMUN: '429',
		DC: '0',
		NOMBRE: 'Villaescusa la Sombría',
	},
	{
		CPRO: '09',
		CMUN: '430',
		DC: '4',
		NOMBRE: 'Villaespasa',
	},
	{
		CPRO: '09',
		CMUN: '431',
		DC: '1',
		NOMBRE: 'Villafranca Montes de Oca',
	},
	{
		CPRO: '09',
		CMUN: '432',
		DC: '6',
		NOMBRE: 'Villafruela',
	},
	{
		CPRO: '09',
		CMUN: '433',
		DC: '2',
		NOMBRE: 'Villagalijo',
	},
	{
		CPRO: '09',
		CMUN: '434',
		DC: '7',
		NOMBRE: 'Villagonzalo Pedernales',
	},
	{
		CPRO: '09',
		CMUN: '437',
		DC: '9',
		NOMBRE: 'Villahoz',
	},
	{
		CPRO: '09',
		CMUN: '438',
		DC: '5',
		NOMBRE: 'Villalba de Duero',
	},
	{
		CPRO: '09',
		CMUN: '439',
		DC: '8',
		NOMBRE: 'Villalbilla de Burgos',
	},
	{
		CPRO: '09',
		CMUN: '440',
		DC: '2',
		NOMBRE: 'Villalbilla de Gumiel',
	},
	{
		CPRO: '09',
		CMUN: '441',
		DC: '9',
		NOMBRE: 'Villaldemiro',
	},
	{
		CPRO: '09',
		CMUN: '442',
		DC: '4',
		NOMBRE: 'Villalmanzo',
	},
	{
		CPRO: '09',
		CMUN: '443',
		DC: '0',
		NOMBRE: 'Villamayor de los Montes',
	},
	{
		CPRO: '09',
		CMUN: '444',
		DC: '5',
		NOMBRE: 'Villamayor de Treviño',
	},
	{
		CPRO: '09',
		CMUN: '445',
		DC: '8',
		NOMBRE: 'Villambistia',
	},
	{
		CPRO: '09',
		CMUN: '446',
		DC: '1',
		NOMBRE: 'Villamedianilla',
	},
	{
		CPRO: '09',
		CMUN: '447',
		DC: '7',
		NOMBRE: 'Villamiel de la Sierra',
	},
	{
		CPRO: '09',
		CMUN: '448',
		DC: '3',
		NOMBRE: 'Villangómez',
	},
	{
		CPRO: '09',
		CMUN: '449',
		DC: '6',
		NOMBRE: 'Villanueva de Argaño',
	},
	{
		CPRO: '09',
		CMUN: '450',
		DC: '9',
		NOMBRE: 'Villanueva de Carazo',
	},
	{
		CPRO: '09',
		CMUN: '451',
		DC: '6',
		NOMBRE: 'Villanueva de Gumiel',
	},
	{
		CPRO: '09',
		CMUN: '454',
		DC: '2',
		NOMBRE: 'Villanueva de Teba',
	},
	{
		CPRO: '09',
		CMUN: '455',
		DC: '5',
		NOMBRE: 'Villaquirán de la Puebla',
	},
	{
		CPRO: '09',
		CMUN: '456',
		DC: '8',
		NOMBRE: 'Villaquirán de los Infantes',
	},
	{
		CPRO: '09',
		CMUN: '903',
		DC: '3',
		NOMBRE: 'Villarcayo de Merindad de Castilla la Vieja',
	},
	{
		CPRO: '09',
		CMUN: '458',
		DC: '0',
		NOMBRE: 'Villariezo',
	},
	{
		CPRO: '09',
		CMUN: '460',
		DC: '7',
		NOMBRE: 'Villasandino',
	},
	{
		CPRO: '09',
		CMUN: '463',
		DC: '5',
		NOMBRE: 'Villasur de Herreros',
	},
	{
		CPRO: '09',
		CMUN: '464',
		DC: '0',
		NOMBRE: 'Villatuelda',
	},
	{
		CPRO: '09',
		CMUN: '466',
		DC: '6',
		NOMBRE: 'Villaverde del Monte',
	},
	{
		CPRO: '09',
		CMUN: '467',
		DC: '2',
		NOMBRE: 'Villaverde-Mogina',
	},
	{
		CPRO: '09',
		CMUN: '471',
		DC: '2',
		NOMBRE: 'Villayerno Morquillas',
	},
	{
		CPRO: '09',
		CMUN: '472',
		DC: '7',
		NOMBRE: 'Villazopeque',
	},
	{
		CPRO: '09',
		CMUN: '473',
		DC: '3',
		NOMBRE: 'Villegas',
	},
	{
		CPRO: '09',
		CMUN: '476',
		DC: '4',
		NOMBRE: 'Villoruebo',
	},
	{
		CPRO: '09',
		CMUN: '424',
		DC: '9',
		NOMBRE: 'Viloria de Rioja',
	},
	{
		CPRO: '09',
		CMUN: '425',
		DC: '2',
		NOMBRE: 'Vilviestre del Pinar',
	},
	{
		CPRO: '09',
		CMUN: '478',
		DC: '6',
		NOMBRE: 'Vizcaínos',
	},
	{
		CPRO: '09',
		CMUN: '480',
		DC: '3',
		NOMBRE: 'Zael',
	},
	{
		CPRO: '09',
		CMUN: '482',
		DC: '5',
		NOMBRE: 'Zarzosa de Río Pisuerga',
	},
	{
		CPRO: '09',
		CMUN: '483',
		DC: '1',
		NOMBRE: 'Zazuar',
	},
	{
		CPRO: '09',
		CMUN: '485',
		DC: '9',
		NOMBRE: 'Zuñeda',
	},
	{
		CPRO: '10',
		CMUN: '001',
		DC: '5',
		NOMBRE: 'Abadía',
	},
	{
		CPRO: '10',
		CMUN: '002',
		DC: '0',
		NOMBRE: 'Abertura',
	},
	{
		CPRO: '10',
		CMUN: '003',
		DC: '6',
		NOMBRE: 'Acebo',
	},
	{
		CPRO: '10',
		CMUN: '004',
		DC: '1',
		NOMBRE: 'Acehúche',
	},
	{
		CPRO: '10',
		CMUN: '005',
		DC: '4',
		NOMBRE: 'Aceituna',
	},
	{
		CPRO: '10',
		CMUN: '006',
		DC: '7',
		NOMBRE: 'Ahigal',
	},
	{
		CPRO: '10',
		CMUN: '903',
		DC: '7',
		NOMBRE: 'Alagón del Río',
	},
	{
		CPRO: '10',
		CMUN: '007',
		DC: '3',
		NOMBRE: 'Albalá',
	},
	{
		CPRO: '10',
		CMUN: '008',
		DC: '9',
		NOMBRE: 'Alcántara',
	},
	{
		CPRO: '10',
		CMUN: '009',
		DC: '2',
		NOMBRE: 'Alcollarín',
	},
	{
		CPRO: '10',
		CMUN: '010',
		DC: '6',
		NOMBRE: 'Alcuéscar',
	},
	{
		CPRO: '10',
		CMUN: '012',
		DC: '8',
		NOMBRE: 'Aldea del Cano',
	},
	{
		CPRO: '10',
		CMUN: '013',
		DC: '4',
		NOMBRE: 'Aldea del Obispo, La',
	},
	{
		CPRO: '10',
		CMUN: '011',
		DC: '3',
		NOMBRE: 'Aldeacentenera',
	},
	{
		CPRO: '10',
		CMUN: '014',
		DC: '9',
		NOMBRE: 'Aldeanueva de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '015',
		DC: '2',
		NOMBRE: 'Aldeanueva del Camino',
	},
	{
		CPRO: '10',
		CMUN: '016',
		DC: '5',
		NOMBRE: 'Aldehuela de Jerte',
	},
	{
		CPRO: '10',
		CMUN: '017',
		DC: '1',
		NOMBRE: 'Alía',
	},
	{
		CPRO: '10',
		CMUN: '018',
		DC: '7',
		NOMBRE: 'Aliseda',
	},
	{
		CPRO: '10',
		CMUN: '019',
		DC: '0',
		NOMBRE: 'Almaraz',
	},
	{
		CPRO: '10',
		CMUN: '020',
		DC: '4',
		NOMBRE: 'Almoharín',
	},
	{
		CPRO: '10',
		CMUN: '021',
		DC: '1',
		NOMBRE: 'Arroyo de la Luz',
	},
	{
		CPRO: '10',
		CMUN: '023',
		DC: '2',
		NOMBRE: 'Arroyomolinos',
	},
	{
		CPRO: '10',
		CMUN: '022',
		DC: '6',
		NOMBRE: 'Arroyomolinos de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '024',
		DC: '7',
		NOMBRE: 'Baños de Montemayor',
	},
	{
		CPRO: '10',
		CMUN: '025',
		DC: '0',
		NOMBRE: 'Barrado',
	},
	{
		CPRO: '10',
		CMUN: '026',
		DC: '3',
		NOMBRE: 'Belvís de Monroy',
	},
	{
		CPRO: '10',
		CMUN: '027',
		DC: '9',
		NOMBRE: 'Benquerencia',
	},
	{
		CPRO: '10',
		CMUN: '028',
		DC: '5',
		NOMBRE: 'Berrocalejo',
	},
	{
		CPRO: '10',
		CMUN: '029',
		DC: '8',
		NOMBRE: 'Berzocana',
	},
	{
		CPRO: '10',
		CMUN: '030',
		DC: '2',
		NOMBRE: 'Bohonal de Ibor',
	},
	{
		CPRO: '10',
		CMUN: '031',
		DC: '9',
		NOMBRE: 'Botija',
	},
	{
		CPRO: '10',
		CMUN: '032',
		DC: '4',
		NOMBRE: 'Brozas',
	},
	{
		CPRO: '10',
		CMUN: '033',
		DC: '0',
		NOMBRE: 'Cabañas del Castillo',
	},
	{
		CPRO: '10',
		CMUN: '034',
		DC: '5',
		NOMBRE: 'Cabezabellosa',
	},
	{
		CPRO: '10',
		CMUN: '035',
		DC: '8',
		NOMBRE: 'Cabezuela del Valle',
	},
	{
		CPRO: '10',
		CMUN: '036',
		DC: '1',
		NOMBRE: 'Cabrero',
	},
	{
		CPRO: '10',
		CMUN: '037',
		DC: '7',
		NOMBRE: 'Cáceres',
	},
	{
		CPRO: '10',
		CMUN: '038',
		DC: '3',
		NOMBRE: 'Cachorrilla',
	},
	{
		CPRO: '10',
		CMUN: '039',
		DC: '6',
		NOMBRE: 'Cadalso',
	},
	{
		CPRO: '10',
		CMUN: '040',
		DC: '0',
		NOMBRE: 'Calzadilla',
	},
	{
		CPRO: '10',
		CMUN: '041',
		DC: '7',
		NOMBRE: 'Caminomorisco',
	},
	{
		CPRO: '10',
		CMUN: '042',
		DC: '2',
		NOMBRE: 'Campillo de Deleitosa',
	},
	{
		CPRO: '10',
		CMUN: '043',
		DC: '8',
		NOMBRE: 'Campo Lugar',
	},
	{
		CPRO: '10',
		CMUN: '044',
		DC: '3',
		NOMBRE: 'Cañamero',
	},
	{
		CPRO: '10',
		CMUN: '045',
		DC: '6',
		NOMBRE: 'Cañaveral',
	},
	{
		CPRO: '10',
		CMUN: '046',
		DC: '9',
		NOMBRE: 'Carbajo',
	},
	{
		CPRO: '10',
		CMUN: '047',
		DC: '5',
		NOMBRE: 'Carcaboso',
	},
	{
		CPRO: '10',
		CMUN: '048',
		DC: '1',
		NOMBRE: 'Carrascalejo',
	},
	{
		CPRO: '10',
		CMUN: '049',
		DC: '4',
		NOMBRE: 'Casar de Cáceres',
	},
	{
		CPRO: '10',
		CMUN: '050',
		DC: '7',
		NOMBRE: 'Casar de Palomero',
	},
	{
		CPRO: '10',
		CMUN: '051',
		DC: '4',
		NOMBRE: 'Casares de las Hurdes',
	},
	{
		CPRO: '10',
		CMUN: '052',
		DC: '9',
		NOMBRE: 'Casas de Don Antonio',
	},
	{
		CPRO: '10',
		CMUN: '053',
		DC: '5',
		NOMBRE: 'Casas de Don Gómez',
	},
	{
		CPRO: '10',
		CMUN: '056',
		DC: '6',
		NOMBRE: 'Casas de Millán',
	},
	{
		CPRO: '10',
		CMUN: '057',
		DC: '2',
		NOMBRE: 'Casas de Miravete',
	},
	{
		CPRO: '10',
		CMUN: '054',
		DC: '0',
		NOMBRE: 'Casas del Castañar',
	},
	{
		CPRO: '10',
		CMUN: '055',
		DC: '3',
		NOMBRE: 'Casas del Monte',
	},
	{
		CPRO: '10',
		CMUN: '058',
		DC: '8',
		NOMBRE: 'Casatejada',
	},
	{
		CPRO: '10',
		CMUN: '059',
		DC: '1',
		NOMBRE: 'Casillas de Coria',
	},
	{
		CPRO: '10',
		CMUN: '060',
		DC: '5',
		NOMBRE: 'Castañar de Ibor',
	},
	{
		CPRO: '10',
		CMUN: '061',
		DC: '2',
		NOMBRE: 'Ceclavín',
	},
	{
		CPRO: '10',
		CMUN: '062',
		DC: '7',
		NOMBRE: 'Cedillo',
	},
	{
		CPRO: '10',
		CMUN: '063',
		DC: '3',
		NOMBRE: 'Cerezo',
	},
	{
		CPRO: '10',
		CMUN: '064',
		DC: '8',
		NOMBRE: 'Cilleros',
	},
	{
		CPRO: '10',
		CMUN: '065',
		DC: '1',
		NOMBRE: 'Collado',
	},
	{
		CPRO: '10',
		CMUN: '066',
		DC: '4',
		NOMBRE: 'Conquista de la Sierra',
	},
	{
		CPRO: '10',
		CMUN: '067',
		DC: '0',
		NOMBRE: 'Coria',
	},
	{
		CPRO: '10',
		CMUN: '068',
		DC: '6',
		NOMBRE: 'Cuacos de Yuste',
	},
	{
		CPRO: '10',
		CMUN: '069',
		DC: '9',
		NOMBRE: 'Cumbre, La',
	},
	{
		CPRO: '10',
		CMUN: '070',
		DC: '3',
		NOMBRE: 'Deleitosa',
	},
	{
		CPRO: '10',
		CMUN: '071',
		DC: '0',
		NOMBRE: 'Descargamaría',
	},
	{
		CPRO: '10',
		CMUN: '072',
		DC: '5',
		NOMBRE: 'Eljas',
	},
	{
		CPRO: '10',
		CMUN: '073',
		DC: '1',
		NOMBRE: 'Escurial',
	},
	{
		CPRO: '10',
		CMUN: '075',
		DC: '9',
		NOMBRE: 'Fresnedoso de Ibor',
	},
	{
		CPRO: '10',
		CMUN: '076',
		DC: '2',
		NOMBRE: 'Galisteo',
	},
	{
		CPRO: '10',
		CMUN: '077',
		DC: '8',
		NOMBRE: 'Garciaz',
	},
	{
		CPRO: '10',
		CMUN: '078',
		DC: '4',
		NOMBRE: 'Garganta, La',
	},
	{
		CPRO: '10',
		CMUN: '079',
		DC: '7',
		NOMBRE: 'Garganta la Olla',
	},
	{
		CPRO: '10',
		CMUN: '080',
		DC: '1',
		NOMBRE: 'Gargantilla',
	},
	{
		CPRO: '10',
		CMUN: '081',
		DC: '8',
		NOMBRE: 'Gargüera',
	},
	{
		CPRO: '10',
		CMUN: '082',
		DC: '3',
		NOMBRE: 'Garrovillas de Alconétar',
	},
	{
		CPRO: '10',
		CMUN: '083',
		DC: '9',
		NOMBRE: 'Garvín',
	},
	{
		CPRO: '10',
		CMUN: '084',
		DC: '4',
		NOMBRE: 'Gata',
	},
	{
		CPRO: '10',
		CMUN: '085',
		DC: '7',
		NOMBRE: 'Gordo, El',
	},
	{
		CPRO: '10',
		CMUN: '086',
		DC: '0',
		NOMBRE: 'Granja, La',
	},
	{
		CPRO: '10',
		CMUN: '087',
		DC: '6',
		NOMBRE: 'Guadalupe',
	},
	{
		CPRO: '10',
		CMUN: '088',
		DC: '2',
		NOMBRE: 'Guijo de Coria',
	},
	{
		CPRO: '10',
		CMUN: '089',
		DC: '5',
		NOMBRE: 'Guijo de Galisteo',
	},
	{
		CPRO: '10',
		CMUN: '090',
		DC: '9',
		NOMBRE: 'Guijo de Granadilla',
	},
	{
		CPRO: '10',
		CMUN: '091',
		DC: '6',
		NOMBRE: 'Guijo de Santa Bárbara',
	},
	{
		CPRO: '10',
		CMUN: '092',
		DC: '1',
		NOMBRE: 'Herguijuela',
	},
	{
		CPRO: '10',
		CMUN: '093',
		DC: '7',
		NOMBRE: 'Hernán-Pérez',
	},
	{
		CPRO: '10',
		CMUN: '094',
		DC: '2',
		NOMBRE: 'Herrera de Alcántara',
	},
	{
		CPRO: '10',
		CMUN: '095',
		DC: '5',
		NOMBRE: 'Herreruela',
	},
	{
		CPRO: '10',
		CMUN: '096',
		DC: '8',
		NOMBRE: 'Hervás',
	},
	{
		CPRO: '10',
		CMUN: '097',
		DC: '4',
		NOMBRE: 'Higuera',
	},
	{
		CPRO: '10',
		CMUN: '098',
		DC: '0',
		NOMBRE: 'Hinojal',
	},
	{
		CPRO: '10',
		CMUN: '099',
		DC: '3',
		NOMBRE: 'Holguera',
	},
	{
		CPRO: '10',
		CMUN: '100',
		DC: '7',
		NOMBRE: 'Hoyos',
	},
	{
		CPRO: '10',
		CMUN: '101',
		DC: '4',
		NOMBRE: 'Huélaga',
	},
	{
		CPRO: '10',
		CMUN: '102',
		DC: '9',
		NOMBRE: 'Ibahernando',
	},
	{
		CPRO: '10',
		CMUN: '103',
		DC: '5',
		NOMBRE: 'Jaraicejo',
	},
	{
		CPRO: '10',
		CMUN: '104',
		DC: '0',
		NOMBRE: 'Jaraíz de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '105',
		DC: '3',
		NOMBRE: 'Jarandilla de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '106',
		DC: '6',
		NOMBRE: 'Jarilla',
	},
	{
		CPRO: '10',
		CMUN: '107',
		DC: '2',
		NOMBRE: 'Jerte',
	},
	{
		CPRO: '10',
		CMUN: '108',
		DC: '8',
		NOMBRE: 'Ladrillar',
	},
	{
		CPRO: '10',
		CMUN: '109',
		DC: '1',
		NOMBRE: 'Logrosán',
	},
	{
		CPRO: '10',
		CMUN: '110',
		DC: '5',
		NOMBRE: 'Losar de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '111',
		DC: '2',
		NOMBRE: 'Madrigal de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '112',
		DC: '7',
		NOMBRE: 'Madrigalejo',
	},
	{
		CPRO: '10',
		CMUN: '113',
		DC: '3',
		NOMBRE: 'Madroñera',
	},
	{
		CPRO: '10',
		CMUN: '114',
		DC: '8',
		NOMBRE: 'Majadas',
	},
	{
		CPRO: '10',
		CMUN: '115',
		DC: '1',
		NOMBRE: 'Malpartida de Cáceres',
	},
	{
		CPRO: '10',
		CMUN: '116',
		DC: '4',
		NOMBRE: 'Malpartida de Plasencia',
	},
	{
		CPRO: '10',
		CMUN: '117',
		DC: '0',
		NOMBRE: 'Marchagaz',
	},
	{
		CPRO: '10',
		CMUN: '118',
		DC: '6',
		NOMBRE: 'Mata de Alcántara',
	},
	{
		CPRO: '10',
		CMUN: '119',
		DC: '9',
		NOMBRE: 'Membrío',
	},
	{
		CPRO: '10',
		CMUN: '120',
		DC: '3',
		NOMBRE: 'Mesas de Ibor',
	},
	{
		CPRO: '10',
		CMUN: '121',
		DC: '0',
		NOMBRE: 'Miajadas',
	},
	{
		CPRO: '10',
		CMUN: '122',
		DC: '5',
		NOMBRE: 'Millanes',
	},
	{
		CPRO: '10',
		CMUN: '123',
		DC: '1',
		NOMBRE: 'Mirabel',
	},
	{
		CPRO: '10',
		CMUN: '124',
		DC: '6',
		NOMBRE: 'Mohedas de Granadilla',
	},
	{
		CPRO: '10',
		CMUN: '125',
		DC: '9',
		NOMBRE: 'Monroy',
	},
	{
		CPRO: '10',
		CMUN: '126',
		DC: '2',
		NOMBRE: 'Montánchez',
	},
	{
		CPRO: '10',
		CMUN: '127',
		DC: '8',
		NOMBRE: 'Montehermoso',
	},
	{
		CPRO: '10',
		CMUN: '128',
		DC: '4',
		NOMBRE: 'Moraleja',
	},
	{
		CPRO: '10',
		CMUN: '129',
		DC: '7',
		NOMBRE: 'Morcillo',
	},
	{
		CPRO: '10',
		CMUN: '130',
		DC: '1',
		NOMBRE: 'Navaconcejo',
	},
	{
		CPRO: '10',
		CMUN: '131',
		DC: '8',
		NOMBRE: 'Navalmoral de la Mata',
	},
	{
		CPRO: '10',
		CMUN: '132',
		DC: '3',
		NOMBRE: 'Navalvillar de Ibor',
	},
	{
		CPRO: '10',
		CMUN: '133',
		DC: '9',
		NOMBRE: 'Navas del Madroño',
	},
	{
		CPRO: '10',
		CMUN: '134',
		DC: '4',
		NOMBRE: 'Navezuelas',
	},
	{
		CPRO: '10',
		CMUN: '135',
		DC: '7',
		NOMBRE: 'Nuñomoral',
	},
	{
		CPRO: '10',
		CMUN: '136',
		DC: '0',
		NOMBRE: 'Oliva de Plasencia',
	},
	{
		CPRO: '10',
		CMUN: '137',
		DC: '6',
		NOMBRE: 'Palomero',
	},
	{
		CPRO: '10',
		CMUN: '138',
		DC: '2',
		NOMBRE: 'Pasarón de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '139',
		DC: '5',
		NOMBRE: 'Pedroso de Acim',
	},
	{
		CPRO: '10',
		CMUN: '140',
		DC: '9',
		NOMBRE: 'Peraleda de la Mata',
	},
	{
		CPRO: '10',
		CMUN: '141',
		DC: '6',
		NOMBRE: 'Peraleda de San Román',
	},
	{
		CPRO: '10',
		CMUN: '142',
		DC: '1',
		NOMBRE: 'Perales del Puerto',
	},
	{
		CPRO: '10',
		CMUN: '143',
		DC: '7',
		NOMBRE: 'Pescueza',
	},
	{
		CPRO: '10',
		CMUN: '144',
		DC: '2',
		NOMBRE: 'Pesga, La',
	},
	{
		CPRO: '10',
		CMUN: '145',
		DC: '5',
		NOMBRE: 'Piedras Albas',
	},
	{
		CPRO: '10',
		CMUN: '146',
		DC: '8',
		NOMBRE: 'Pinofranqueado',
	},
	{
		CPRO: '10',
		CMUN: '147',
		DC: '4',
		NOMBRE: 'Piornal',
	},
	{
		CPRO: '10',
		CMUN: '148',
		DC: '0',
		NOMBRE: 'Plasencia',
	},
	{
		CPRO: '10',
		CMUN: '149',
		DC: '3',
		NOMBRE: 'Plasenzuela',
	},
	{
		CPRO: '10',
		CMUN: '150',
		DC: '6',
		NOMBRE: 'Portaje',
	},
	{
		CPRO: '10',
		CMUN: '151',
		DC: '3',
		NOMBRE: 'Portezuelo',
	},
	{
		CPRO: '10',
		CMUN: '152',
		DC: '8',
		NOMBRE: 'Pozuelo de Zarzón',
	},
	{
		CPRO: '10',
		CMUN: '153',
		DC: '4',
		NOMBRE: 'Puerto de Santa Cruz',
	},
	{
		CPRO: '10',
		CMUN: '154',
		DC: '9',
		NOMBRE: 'Rebollar',
	},
	{
		CPRO: '10',
		CMUN: '155',
		DC: '2',
		NOMBRE: 'Riolobos',
	},
	{
		CPRO: '10',
		CMUN: '156',
		DC: '5',
		NOMBRE: 'Robledillo de Gata',
	},
	{
		CPRO: '10',
		CMUN: '157',
		DC: '1',
		NOMBRE: 'Robledillo de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '158',
		DC: '7',
		NOMBRE: 'Robledillo de Trujillo',
	},
	{
		CPRO: '10',
		CMUN: '159',
		DC: '0',
		NOMBRE: 'Robledollano',
	},
	{
		CPRO: '10',
		CMUN: '160',
		DC: '4',
		NOMBRE: 'Romangordo',
	},
	{
		CPRO: '10',
		CMUN: '901',
		DC: '6',
		NOMBRE: 'Rosalejo',
	},
	{
		CPRO: '10',
		CMUN: '161',
		DC: '1',
		NOMBRE: 'Ruanes',
	},
	{
		CPRO: '10',
		CMUN: '162',
		DC: '6',
		NOMBRE: 'Salorino',
	},
	{
		CPRO: '10',
		CMUN: '163',
		DC: '2',
		NOMBRE: 'Salvatierra de Santiago',
	},
	{
		CPRO: '10',
		CMUN: '164',
		DC: '7',
		NOMBRE: 'San Martín de Trevejo',
	},
	{
		CPRO: '10',
		CMUN: '165',
		DC: '0',
		NOMBRE: 'Santa Ana',
	},
	{
		CPRO: '10',
		CMUN: '166',
		DC: '3',
		NOMBRE: 'Santa Cruz de la Sierra',
	},
	{
		CPRO: '10',
		CMUN: '167',
		DC: '9',
		NOMBRE: 'Santa Cruz de Paniagua',
	},
	{
		CPRO: '10',
		CMUN: '168',
		DC: '5',
		NOMBRE: 'Santa Marta de Magasca',
	},
	{
		CPRO: '10',
		CMUN: '169',
		DC: '8',
		NOMBRE: 'Santiago de Alcántara',
	},
	{
		CPRO: '10',
		CMUN: '170',
		DC: '2',
		NOMBRE: 'Santiago del Campo',
	},
	{
		CPRO: '10',
		CMUN: '171',
		DC: '9',
		NOMBRE: 'Santibáñez el Alto',
	},
	{
		CPRO: '10',
		CMUN: '172',
		DC: '4',
		NOMBRE: 'Santibáñez el Bajo',
	},
	{
		CPRO: '10',
		CMUN: '173',
		DC: '0',
		NOMBRE: 'Saucedilla',
	},
	{
		CPRO: '10',
		CMUN: '174',
		DC: '5',
		NOMBRE: 'Segura de Toro',
	},
	{
		CPRO: '10',
		CMUN: '175',
		DC: '8',
		NOMBRE: 'Serradilla',
	},
	{
		CPRO: '10',
		CMUN: '176',
		DC: '1',
		NOMBRE: 'Serrejón',
	},
	{
		CPRO: '10',
		CMUN: '177',
		DC: '7',
		NOMBRE: 'Sierra de Fuentes',
	},
	{
		CPRO: '10',
		CMUN: '178',
		DC: '3',
		NOMBRE: 'Talaván',
	},
	{
		CPRO: '10',
		CMUN: '179',
		DC: '6',
		NOMBRE: 'Talaveruela de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '180',
		DC: '0',
		NOMBRE: 'Talayuela',
	},
	{
		CPRO: '10',
		CMUN: '181',
		DC: '7',
		NOMBRE: 'Tejeda de Tiétar',
	},
	{
		CPRO: '10',
		CMUN: '182',
		DC: '2',
		NOMBRE: 'Toril',
	},
	{
		CPRO: '10',
		CMUN: '183',
		DC: '8',
		NOMBRE: 'Tornavacas',
	},
	{
		CPRO: '10',
		CMUN: '184',
		DC: '3',
		NOMBRE: 'Torno, El',
	},
	{
		CPRO: '10',
		CMUN: '187',
		DC: '5',
		NOMBRE: 'Torre de Don Miguel',
	},
	{
		CPRO: '10',
		CMUN: '188',
		DC: '1',
		NOMBRE: 'Torre de Santa María',
	},
	{
		CPRO: '10',
		CMUN: '185',
		DC: '6',
		NOMBRE: 'Torrecilla de los Ángeles',
	},
	{
		CPRO: '10',
		CMUN: '186',
		DC: '9',
		NOMBRE: 'Torrecillas de la Tiesa',
	},
	{
		CPRO: '10',
		CMUN: '190',
		DC: '8',
		NOMBRE: 'Torrejón el Rubio',
	},
	{
		CPRO: '10',
		CMUN: '189',
		DC: '4',
		NOMBRE: 'Torrejoncillo',
	},
	{
		CPRO: '10',
		CMUN: '191',
		DC: '5',
		NOMBRE: 'Torremenga',
	},
	{
		CPRO: '10',
		CMUN: '192',
		DC: '0',
		NOMBRE: 'Torremocha',
	},
	{
		CPRO: '10',
		CMUN: '193',
		DC: '6',
		NOMBRE: 'Torreorgaz',
	},
	{
		CPRO: '10',
		CMUN: '194',
		DC: '1',
		NOMBRE: 'Torrequemada',
	},
	{
		CPRO: '10',
		CMUN: '195',
		DC: '4',
		NOMBRE: 'Trujillo',
	},
	{
		CPRO: '10',
		CMUN: '196',
		DC: '7',
		NOMBRE: 'Valdastillas',
	},
	{
		CPRO: '10',
		CMUN: '197',
		DC: '3',
		NOMBRE: 'Valdecañas de Tajo',
	},
	{
		CPRO: '10',
		CMUN: '198',
		DC: '9',
		NOMBRE: 'Valdefuentes',
	},
	{
		CPRO: '10',
		CMUN: '199',
		DC: '2',
		NOMBRE: 'Valdehúncar',
	},
	{
		CPRO: '10',
		CMUN: '200',
		DC: '6',
		NOMBRE: 'Valdelacasa de Tajo',
	},
	{
		CPRO: '10',
		CMUN: '201',
		DC: '3',
		NOMBRE: 'Valdemorales',
	},
	{
		CPRO: '10',
		CMUN: '202',
		DC: '8',
		NOMBRE: 'Valdeobispo',
	},
	{
		CPRO: '10',
		CMUN: '203',
		DC: '4',
		NOMBRE: 'Valencia de Alcántara',
	},
	{
		CPRO: '10',
		CMUN: '204',
		DC: '9',
		NOMBRE: 'Valverde de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '205',
		DC: '2',
		NOMBRE: 'Valverde del Fresno',
	},
	{
		CPRO: '10',
		CMUN: '902',
		DC: '1',
		NOMBRE: 'Vegaviana',
	},
	{
		CPRO: '10',
		CMUN: '206',
		DC: '5',
		NOMBRE: 'Viandar de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '207',
		DC: '1',
		NOMBRE: 'Villa del Campo',
	},
	{
		CPRO: '10',
		CMUN: '208',
		DC: '7',
		NOMBRE: 'Villa del Rey',
	},
	{
		CPRO: '10',
		CMUN: '209',
		DC: '0',
		NOMBRE: 'Villamesías',
	},
	{
		CPRO: '10',
		CMUN: '210',
		DC: '4',
		NOMBRE: 'Villamiel',
	},
	{
		CPRO: '10',
		CMUN: '211',
		DC: '1',
		NOMBRE: 'Villanueva de la Sierra',
	},
	{
		CPRO: '10',
		CMUN: '212',
		DC: '6',
		NOMBRE: 'Villanueva de la Vera',
	},
	{
		CPRO: '10',
		CMUN: '214',
		DC: '7',
		NOMBRE: 'Villar de Plasencia',
	},
	{
		CPRO: '10',
		CMUN: '213',
		DC: '2',
		NOMBRE: 'Villar del Pedroso',
	},
	{
		CPRO: '10',
		CMUN: '215',
		DC: '0',
		NOMBRE: 'Villasbuenas de Gata',
	},
	{
		CPRO: '10',
		CMUN: '216',
		DC: '3',
		NOMBRE: 'Zarza de Granadilla',
	},
	{
		CPRO: '10',
		CMUN: '217',
		DC: '9',
		NOMBRE: 'Zarza de Montánchez',
	},
	{
		CPRO: '10',
		CMUN: '218',
		DC: '5',
		NOMBRE: 'Zarza la Mayor',
	},
	{
		CPRO: '10',
		CMUN: '219',
		DC: '8',
		NOMBRE: 'Zorita',
	},
	{
		CPRO: '11',
		CMUN: '001',
		DC: '2',
		NOMBRE: 'Alcalá de los Gazules',
	},
	{
		CPRO: '11',
		CMUN: '002',
		DC: '7',
		NOMBRE: 'Alcalá del Valle',
	},
	{
		CPRO: '11',
		CMUN: '003',
		DC: '3',
		NOMBRE: 'Algar',
	},
	{
		CPRO: '11',
		CMUN: '004',
		DC: '8',
		NOMBRE: 'Algeciras',
	},
	{
		CPRO: '11',
		CMUN: '005',
		DC: '1',
		NOMBRE: 'Algodonales',
	},
	{
		CPRO: '11',
		CMUN: '006',
		DC: '4',
		NOMBRE: 'Arcos de la Frontera',
	},
	{
		CPRO: '11',
		CMUN: '007',
		DC: '0',
		NOMBRE: 'Barbate',
	},
	{
		CPRO: '11',
		CMUN: '008',
		DC: '6',
		NOMBRE: 'Barrios, Los',
	},
	{
		CPRO: '11',
		CMUN: '901',
		DC: '3',
		NOMBRE: 'Benalup-Casas Viejas',
	},
	{
		CPRO: '11',
		CMUN: '009',
		DC: '9',
		NOMBRE: 'Benaocaz',
	},
	{
		CPRO: '11',
		CMUN: '010',
		DC: '3',
		NOMBRE: 'Bornos',
	},
	{
		CPRO: '11',
		CMUN: '011',
		DC: '0',
		NOMBRE: 'Bosque, El',
	},
	{
		CPRO: '11',
		CMUN: '012',
		DC: '5',
		NOMBRE: 'Cádiz',
	},
	{
		CPRO: '11',
		CMUN: '013',
		DC: '1',
		NOMBRE: 'Castellar de la Frontera',
	},
	{
		CPRO: '11',
		CMUN: '015',
		DC: '9',
		NOMBRE: 'Chiclana de la Frontera',
	},
	{
		CPRO: '11',
		CMUN: '016',
		DC: '2',
		NOMBRE: 'Chipiona',
	},
	{
		CPRO: '11',
		CMUN: '014',
		DC: '6',
		NOMBRE: 'Conil de la Frontera',
	},
	{
		CPRO: '11',
		CMUN: '017',
		DC: '8',
		NOMBRE: 'Espera',
	},
	{
		CPRO: '11',
		CMUN: '018',
		DC: '4',
		NOMBRE: 'Gastor, El',
	},
	{
		CPRO: '11',
		CMUN: '019',
		DC: '7',
		NOMBRE: 'Grazalema',
	},
	{
		CPRO: '11',
		CMUN: '020',
		DC: '1',
		NOMBRE: 'Jerez de la Frontera',
	},
	{
		CPRO: '11',
		CMUN: '021',
		DC: '8',
		NOMBRE: 'Jimena de la Frontera',
	},
	{
		CPRO: '11',
		CMUN: '022',
		DC: '3',
		NOMBRE: 'Línea de la Concepción, La',
	},
	{
		CPRO: '11',
		CMUN: '023',
		DC: '9',
		NOMBRE: 'Medina-Sidonia',
	},
	{
		CPRO: '11',
		CMUN: '024',
		DC: '4',
		NOMBRE: 'Olvera',
	},
	{
		CPRO: '11',
		CMUN: '025',
		DC: '7',
		NOMBRE: 'Paterna de Rivera',
	},
	{
		CPRO: '11',
		CMUN: '026',
		DC: '0',
		NOMBRE: 'Prado del Rey',
	},
	{
		CPRO: '11',
		CMUN: '027',
		DC: '6',
		NOMBRE: 'Puerto de Santa María, El',
	},
	{
		CPRO: '11',
		CMUN: '028',
		DC: '2',
		NOMBRE: 'Puerto Real',
	},
	{
		CPRO: '11',
		CMUN: '029',
		DC: '5',
		NOMBRE: 'Puerto Serrano',
	},
	{
		CPRO: '11',
		CMUN: '030',
		DC: '9',
		NOMBRE: 'Rota',
	},
	{
		CPRO: '11',
		CMUN: '031',
		DC: '6',
		NOMBRE: 'San Fernando',
	},
	{
		CPRO: '11',
		CMUN: '902',
		DC: '8',
		NOMBRE: 'San José del Valle',
	},
	{
		CPRO: '11',
		CMUN: '033',
		DC: '7',
		NOMBRE: 'San Roque',
	},
	{
		CPRO: '11',
		CMUN: '032',
		DC: '1',
		NOMBRE: 'Sanlúcar de Barrameda',
	},
	{
		CPRO: '11',
		CMUN: '034',
		DC: '2',
		NOMBRE: 'Setenil de las Bodegas',
	},
	{
		CPRO: '11',
		CMUN: '035',
		DC: '5',
		NOMBRE: 'Tarifa',
	},
	{
		CPRO: '11',
		CMUN: '036',
		DC: '8',
		NOMBRE: 'Torre Alháquime',
	},
	{
		CPRO: '11',
		CMUN: '037',
		DC: '4',
		NOMBRE: 'Trebujena',
	},
	{
		CPRO: '11',
		CMUN: '038',
		DC: '0',
		NOMBRE: 'Ubrique',
	},
	{
		CPRO: '11',
		CMUN: '039',
		DC: '3',
		NOMBRE: 'Vejer de la Frontera',
	},
	{
		CPRO: '11',
		CMUN: '040',
		DC: '7',
		NOMBRE: 'Villaluenga del Rosario',
	},
	{
		CPRO: '11',
		CMUN: '041',
		DC: '4',
		NOMBRE: 'Villamartín',
	},
	{
		CPRO: '11',
		CMUN: '042',
		DC: '9',
		NOMBRE: 'Zahara',
	},
	{
		CPRO: '12',
		CMUN: '002',
		DC: '2',
		NOMBRE: 'Aín',
	},
	{
		CPRO: '12',
		CMUN: '003',
		DC: '8',
		NOMBRE: 'Albocàsser',
	},
	{
		CPRO: '12',
		CMUN: '004',
		DC: '3',
		NOMBRE: 'Alcalà de Xivert',
	},
	{
		CPRO: '12',
		CMUN: '005',
		DC: '6',
		NOMBRE: "Alcora, l'",
	},
	{
		CPRO: '12',
		CMUN: '006',
		DC: '9',
		NOMBRE: 'Alcudia de Veo',
	},
	{
		CPRO: '12',
		CMUN: '007',
		DC: '5',
		NOMBRE: 'Alfondeguilla',
	},
	{
		CPRO: '12',
		CMUN: '008',
		DC: '1',
		NOMBRE: 'Algimia de Almonacid',
	},
	{
		CPRO: '12',
		CMUN: '009',
		DC: '4',
		NOMBRE: 'Almazora/Almassora',
	},
	{
		CPRO: '12',
		CMUN: '010',
		DC: '8',
		NOMBRE: 'Almedíjar',
	},
	{
		CPRO: '12',
		CMUN: '011',
		DC: '5',
		NOMBRE: 'Almenara',
	},
	{
		CPRO: '12',
		CMUN: '901',
		DC: '8',
		NOMBRE: 'Alquerías del Niño Perdido',
	},
	{
		CPRO: '12',
		CMUN: '012',
		DC: '0',
		NOMBRE: 'Altura',
	},
	{
		CPRO: '12',
		CMUN: '013',
		DC: '6',
		NOMBRE: 'Arañuel',
	},
	{
		CPRO: '12',
		CMUN: '014',
		DC: '1',
		NOMBRE: 'Ares del Maestrat',
	},
	{
		CPRO: '12',
		CMUN: '015',
		DC: '4',
		NOMBRE: 'Argelita',
	},
	{
		CPRO: '12',
		CMUN: '016',
		DC: '7',
		NOMBRE: 'Artana',
	},
	{
		CPRO: '12',
		CMUN: '001',
		DC: '7',
		NOMBRE: 'Atzeneta del Maestrat',
	},
	{
		CPRO: '12',
		CMUN: '017',
		DC: '3',
		NOMBRE: 'Ayódar',
	},
	{
		CPRO: '12',
		CMUN: '018',
		DC: '9',
		NOMBRE: 'Azuébar',
	},
	{
		CPRO: '12',
		CMUN: '020',
		DC: '6',
		NOMBRE: 'Barracas',
	},
	{
		CPRO: '12',
		CMUN: '022',
		DC: '8',
		NOMBRE: 'Bejís',
	},
	{
		CPRO: '12',
		CMUN: '024',
		DC: '9',
		NOMBRE: 'Benafer',
	},
	{
		CPRO: '12',
		CMUN: '025',
		DC: '2',
		NOMBRE: 'Benafigos',
	},
	{
		CPRO: '12',
		CMUN: '026',
		DC: '5',
		NOMBRE: 'Benasal',
	},
	{
		CPRO: '12',
		CMUN: '027',
		DC: '1',
		NOMBRE: 'Benicarló',
	},
	{
		CPRO: '12',
		CMUN: '028',
		DC: '7',
		NOMBRE: 'Benicasim/Benicàssim',
	},
	{
		CPRO: '12',
		CMUN: '029',
		DC: '0',
		NOMBRE: 'Benlloch',
	},
	{
		CPRO: '12',
		CMUN: '021',
		DC: '3',
		NOMBRE: 'Betxí',
	},
	{
		CPRO: '12',
		CMUN: '032',
		DC: '6',
		NOMBRE: 'Borriana/Burriana',
	},
	{
		CPRO: '12',
		CMUN: '031',
		DC: '1',
		NOMBRE: 'Borriol',
	},
	{
		CPRO: '12',
		CMUN: '033',
		DC: '2',
		NOMBRE: 'Cabanes',
	},
	{
		CPRO: '12',
		CMUN: '034',
		DC: '7',
		NOMBRE: 'Càlig',
	},
	{
		CPRO: '12',
		CMUN: '036',
		DC: '3',
		NOMBRE: 'Canet lo Roig',
	},
	{
		CPRO: '12',
		CMUN: '037',
		DC: '9',
		NOMBRE: 'Castell de Cabres',
	},
	{
		CPRO: '12',
		CMUN: '038',
		DC: '5',
		NOMBRE: 'Castellfort',
	},
	{
		CPRO: '12',
		CMUN: '039',
		DC: '8',
		NOMBRE: 'Castellnovo',
	},
	{
		CPRO: '12',
		CMUN: '040',
		DC: '2',
		NOMBRE: 'Castellón de la Plana/Castelló de la Plana',
	},
	{
		CPRO: '12',
		CMUN: '041',
		DC: '9',
		NOMBRE: 'Castillo de Villamalefa',
	},
	{
		CPRO: '12',
		CMUN: '042',
		DC: '4',
		NOMBRE: 'Catí',
	},
	{
		CPRO: '12',
		CMUN: '043',
		DC: '0',
		NOMBRE: 'Caudiel',
	},
	{
		CPRO: '12',
		CMUN: '044',
		DC: '5',
		NOMBRE: 'Cervera del Maestre',
	},
	{
		CPRO: '12',
		CMUN: '052',
		DC: '1',
		NOMBRE: 'Chert/Xert',
	},
	{
		CPRO: '12',
		CMUN: '053',
		DC: '7',
		NOMBRE: 'Chilches/Xilxes',
	},
	{
		CPRO: '12',
		CMUN: '055',
		DC: '5',
		NOMBRE: 'Chodos/Xodos',
	},
	{
		CPRO: '12',
		CMUN: '056',
		DC: '8',
		NOMBRE: 'Chóvar',
	},
	{
		CPRO: '12',
		CMUN: '045',
		DC: '8',
		NOMBRE: 'Cinctorres',
	},
	{
		CPRO: '12',
		CMUN: '046',
		DC: '1',
		NOMBRE: 'Cirat',
	},
	{
		CPRO: '12',
		CMUN: '048',
		DC: '3',
		NOMBRE: 'Cortes de Arenoso',
	},
	{
		CPRO: '12',
		CMUN: '049',
		DC: '6',
		NOMBRE: 'Costur',
	},
	{
		CPRO: '12',
		CMUN: '050',
		DC: '9',
		NOMBRE: 'Coves de Vinromà, les',
	},
	{
		CPRO: '12',
		CMUN: '051',
		DC: '6',
		NOMBRE: 'Culla',
	},
	{
		CPRO: '12',
		CMUN: '057',
		DC: '4',
		NOMBRE: 'Eslida',
	},
	{
		CPRO: '12',
		CMUN: '058',
		DC: '0',
		NOMBRE: 'Espadilla',
	},
	{
		CPRO: '12',
		CMUN: '059',
		DC: '3',
		NOMBRE: 'Fanzara',
	},
	{
		CPRO: '12',
		CMUN: '060',
		DC: '7',
		NOMBRE: 'Figueroles',
	},
	{
		CPRO: '12',
		CMUN: '061',
		DC: '4',
		NOMBRE: 'Forcall',
	},
	{
		CPRO: '12',
		CMUN: '063',
		DC: '5',
		NOMBRE: 'Fuente la Reina',
	},
	{
		CPRO: '12',
		CMUN: '064',
		DC: '0',
		NOMBRE: 'Fuentes de Ayódar',
	},
	{
		CPRO: '12',
		CMUN: '065',
		DC: '3',
		NOMBRE: 'Gaibiel',
	},
	{
		CPRO: '12',
		CMUN: '067',
		DC: '2',
		NOMBRE: 'Geldo',
	},
	{
		CPRO: '12',
		CMUN: '068',
		DC: '8',
		NOMBRE: 'Herbés',
	},
	{
		CPRO: '12',
		CMUN: '069',
		DC: '1',
		NOMBRE: 'Higueras',
	},
	{
		CPRO: '12',
		CMUN: '070',
		DC: '5',
		NOMBRE: 'Jana, la',
	},
	{
		CPRO: '12',
		CMUN: '071',
		DC: '2',
		NOMBRE: 'Jérica',
	},
	{
		CPRO: '12',
		CMUN: '074',
		DC: '8',
		NOMBRE: 'Llosa, la',
	},
	{
		CPRO: '12',
		CMUN: '072',
		DC: '7',
		NOMBRE: 'Lucena del Cid',
	},
	{
		CPRO: '12',
		CMUN: '073',
		DC: '3',
		NOMBRE: 'Ludiente',
	},
	{
		CPRO: '12',
		CMUN: '075',
		DC: '1',
		NOMBRE: 'Mata de Morella, la',
	},
	{
		CPRO: '12',
		CMUN: '076',
		DC: '4',
		NOMBRE: 'Matet',
	},
	{
		CPRO: '12',
		CMUN: '077',
		DC: '0',
		NOMBRE: 'Moncofa',
	},
	{
		CPRO: '12',
		CMUN: '078',
		DC: '6',
		NOMBRE: 'Montán',
	},
	{
		CPRO: '12',
		CMUN: '079',
		DC: '9',
		NOMBRE: 'Montanejos',
	},
	{
		CPRO: '12',
		CMUN: '080',
		DC: '3',
		NOMBRE: 'Morella',
	},
	{
		CPRO: '12',
		CMUN: '081',
		DC: '0',
		NOMBRE: 'Navajas',
	},
	{
		CPRO: '12',
		CMUN: '082',
		DC: '5',
		NOMBRE: 'Nules',
	},
	{
		CPRO: '12',
		CMUN: '083',
		DC: '1',
		NOMBRE: 'Olocau del Rey',
	},
	{
		CPRO: '12',
		CMUN: '084',
		DC: '6',
		NOMBRE: 'Onda',
	},
	{
		CPRO: '12',
		CMUN: '085',
		DC: '9',
		NOMBRE: 'Oropesa del Mar/Orpesa',
	},
	{
		CPRO: '12',
		CMUN: '087',
		DC: '8',
		NOMBRE: 'Palanques',
	},
	{
		CPRO: '12',
		CMUN: '088',
		DC: '4',
		NOMBRE: 'Pavías',
	},
	{
		CPRO: '12',
		CMUN: '089',
		DC: '7',
		NOMBRE: 'Peníscola/Peñíscola',
	},
	{
		CPRO: '12',
		CMUN: '090',
		DC: '1',
		NOMBRE: 'Pina de Montalgrao',
	},
	{
		CPRO: '12',
		CMUN: '093',
		DC: '9',
		NOMBRE: 'Pobla de Benifassà, la',
	},
	{
		CPRO: '12',
		CMUN: '094',
		DC: '4',
		NOMBRE: 'Pobla Tornesa, la',
	},
	{
		CPRO: '12',
		CMUN: '091',
		DC: '8',
		NOMBRE: 'Portell de Morella',
	},
	{
		CPRO: '12',
		CMUN: '092',
		DC: '3',
		NOMBRE: 'Puebla de Arenoso',
	},
	{
		CPRO: '12',
		CMUN: '095',
		DC: '7',
		NOMBRE: 'Ribesalbes',
	},
	{
		CPRO: '12',
		CMUN: '096',
		DC: '0',
		NOMBRE: 'Rossell',
	},
	{
		CPRO: '12',
		CMUN: '097',
		DC: '6',
		NOMBRE: 'Sacañet',
	},
	{
		CPRO: '12',
		CMUN: '098',
		DC: '2',
		NOMBRE: 'Salzadella, la',
	},
	{
		CPRO: '12',
		CMUN: '101',
		DC: '6',
		NOMBRE: 'San Rafael del Río',
	},
	{
		CPRO: '12',
		CMUN: '902',
		DC: '3',
		NOMBRE: 'Sant Joan de Moró',
	},
	{
		CPRO: '12',
		CMUN: '099',
		DC: '5',
		NOMBRE: 'Sant Jordi/San Jorge',
	},
	{
		CPRO: '12',
		CMUN: '100',
		DC: '9',
		NOMBRE: 'Sant Mateu',
	},
	{
		CPRO: '12',
		CMUN: '102',
		DC: '1',
		NOMBRE: 'Santa Magdalena de Pulpis',
	},
	{
		CPRO: '12',
		CMUN: '103',
		DC: '7',
		NOMBRE: 'Sarratella',
	},
	{
		CPRO: '12',
		CMUN: '104',
		DC: '2',
		NOMBRE: 'Segorbe',
	},
	{
		CPRO: '12',
		CMUN: '105',
		DC: '5',
		NOMBRE: 'Sierra Engarcerán',
	},
	{
		CPRO: '12',
		CMUN: '106',
		DC: '8',
		NOMBRE: 'Soneja',
	},
	{
		CPRO: '12',
		CMUN: '107',
		DC: '4',
		NOMBRE: 'Sot de Ferrer',
	},
	{
		CPRO: '12',
		CMUN: '108',
		DC: '0',
		NOMBRE: 'Sueras/Suera',
	},
	{
		CPRO: '12',
		CMUN: '109',
		DC: '3',
		NOMBRE: 'Tales',
	},
	{
		CPRO: '12',
		CMUN: '110',
		DC: '7',
		NOMBRE: 'Teresa',
	},
	{
		CPRO: '12',
		CMUN: '111',
		DC: '4',
		NOMBRE: 'Tírig',
	},
	{
		CPRO: '12',
		CMUN: '112',
		DC: '9',
		NOMBRE: 'Todolella',
	},
	{
		CPRO: '12',
		CMUN: '113',
		DC: '5',
		NOMBRE: 'Toga',
	},
	{
		CPRO: '12',
		CMUN: '114',
		DC: '0',
		NOMBRE: 'Torás',
	},
	{
		CPRO: '12',
		CMUN: '115',
		DC: '3',
		NOMBRE: 'Toro, El',
	},
	{
		CPRO: '12',
		CMUN: '116',
		DC: '6',
		NOMBRE: 'Torralba del Pinar',
	},
	{
		CPRO: '12',
		CMUN: '119',
		DC: '1',
		NOMBRE: "Torre d'En Besora, la",
	},
	{
		CPRO: '12',
		CMUN: '120',
		DC: '5',
		NOMBRE: "Torre d'en Doménec, la",
	},
	{
		CPRO: '12',
		CMUN: '117',
		DC: '2',
		NOMBRE: 'Torreblanca',
	},
	{
		CPRO: '12',
		CMUN: '118',
		DC: '8',
		NOMBRE: 'Torrechiva',
	},
	{
		CPRO: '12',
		CMUN: '121',
		DC: '2',
		NOMBRE: 'Traiguera',
	},
	{
		CPRO: '12',
		CMUN: '122',
		DC: '7',
		NOMBRE: 'Useras/Useres, les',
	},
	{
		CPRO: '12',
		CMUN: '124',
		DC: '8',
		NOMBRE: "Vall d'Alba",
	},
	{
		CPRO: '12',
		CMUN: '125',
		DC: '1',
		NOMBRE: 'Vall de Almonacid',
	},
	{
		CPRO: '12',
		CMUN: '126',
		DC: '4',
		NOMBRE: "Vall d'Uixó, la",
	},
	{
		CPRO: '12',
		CMUN: '123',
		DC: '3',
		NOMBRE: 'Vallat',
	},
	{
		CPRO: '12',
		CMUN: '127',
		DC: '0',
		NOMBRE: 'Vallibona',
	},
	{
		CPRO: '12',
		CMUN: '128',
		DC: '6',
		NOMBRE: 'Vilafamés',
	},
	{
		CPRO: '12',
		CMUN: '132',
		DC: '5',
		NOMBRE: "Vilanova d'Alcolea",
	},
	{
		CPRO: '12',
		CMUN: '134',
		DC: '6',
		NOMBRE: 'Vilar de Canes',
	},
	{
		CPRO: '12',
		CMUN: '135',
		DC: '9',
		NOMBRE: 'Vila-real',
	},
	{
		CPRO: '12',
		CMUN: '136',
		DC: '2',
		NOMBRE: 'Vilavella, la',
	},
	{
		CPRO: '12',
		CMUN: '129',
		DC: '9',
		NOMBRE: 'Villafranca del Cid/Vilafranca',
	},
	{
		CPRO: '12',
		CMUN: '130',
		DC: '3',
		NOMBRE: 'Villahermosa del Río',
	},
	{
		CPRO: '12',
		CMUN: '131',
		DC: '0',
		NOMBRE: 'Villamalur',
	},
	{
		CPRO: '12',
		CMUN: '133',
		DC: '1',
		NOMBRE: 'Villanueva de Viver',
	},
	{
		CPRO: '12',
		CMUN: '137',
		DC: '8',
		NOMBRE: 'Villores',
	},
	{
		CPRO: '12',
		CMUN: '138',
		DC: '4',
		NOMBRE: 'Vinaròs',
	},
	{
		CPRO: '12',
		CMUN: '139',
		DC: '7',
		NOMBRE: 'Vistabella del Maestrazgo',
	},
	{
		CPRO: '12',
		CMUN: '140',
		DC: '1',
		NOMBRE: 'Viver',
	},
	{
		CPRO: '12',
		CMUN: '141',
		DC: '8',
		NOMBRE: 'Zorita del Maestrazgo',
	},
	{
		CPRO: '12',
		CMUN: '142',
		DC: '3',
		NOMBRE: 'Zucaina',
	},
	{
		CPRO: '13',
		CMUN: '001',
		DC: '3',
		NOMBRE: 'Abenójar',
	},
	{
		CPRO: '13',
		CMUN: '002',
		DC: '8',
		NOMBRE: 'Agudo',
	},
	{
		CPRO: '13',
		CMUN: '003',
		DC: '4',
		NOMBRE: 'Alamillo',
	},
	{
		CPRO: '13',
		CMUN: '004',
		DC: '9',
		NOMBRE: 'Albaladejo',
	},
	{
		CPRO: '13',
		CMUN: '005',
		DC: '2',
		NOMBRE: 'Alcázar de San Juan',
	},
	{
		CPRO: '13',
		CMUN: '006',
		DC: '5',
		NOMBRE: 'Alcoba',
	},
	{
		CPRO: '13',
		CMUN: '007',
		DC: '1',
		NOMBRE: 'Alcolea de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '008',
		DC: '7',
		NOMBRE: 'Alcubillas',
	},
	{
		CPRO: '13',
		CMUN: '009',
		DC: '0',
		NOMBRE: 'Aldea del Rey',
	},
	{
		CPRO: '13',
		CMUN: '010',
		DC: '4',
		NOMBRE: 'Alhambra',
	},
	{
		CPRO: '13',
		CMUN: '011',
		DC: '1',
		NOMBRE: 'Almadén',
	},
	{
		CPRO: '13',
		CMUN: '012',
		DC: '6',
		NOMBRE: 'Almadenejos',
	},
	{
		CPRO: '13',
		CMUN: '013',
		DC: '2',
		NOMBRE: 'Almagro',
	},
	{
		CPRO: '13',
		CMUN: '014',
		DC: '7',
		NOMBRE: 'Almedina',
	},
	{
		CPRO: '13',
		CMUN: '015',
		DC: '0',
		NOMBRE: 'Almodóvar del Campo',
	},
	{
		CPRO: '13',
		CMUN: '016',
		DC: '3',
		NOMBRE: 'Almuradiel',
	},
	{
		CPRO: '13',
		CMUN: '017',
		DC: '9',
		NOMBRE: 'Anchuras',
	},
	{
		CPRO: '13',
		CMUN: '903',
		DC: '5',
		NOMBRE: 'Arenales de San Gregorio',
	},
	{
		CPRO: '13',
		CMUN: '018',
		DC: '5',
		NOMBRE: 'Arenas de San Juan',
	},
	{
		CPRO: '13',
		CMUN: '019',
		DC: '8',
		NOMBRE: 'Argamasilla de Alba',
	},
	{
		CPRO: '13',
		CMUN: '020',
		DC: '2',
		NOMBRE: 'Argamasilla de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '021',
		DC: '9',
		NOMBRE: 'Arroba de los Montes',
	},
	{
		CPRO: '13',
		CMUN: '022',
		DC: '4',
		NOMBRE: 'Ballesteros de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '023',
		DC: '0',
		NOMBRE: 'Bolaños de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '024',
		DC: '5',
		NOMBRE: 'Brazatortas',
	},
	{
		CPRO: '13',
		CMUN: '025',
		DC: '8',
		NOMBRE: 'Cabezarados',
	},
	{
		CPRO: '13',
		CMUN: '026',
		DC: '1',
		NOMBRE: 'Cabezarrubias del Puerto',
	},
	{
		CPRO: '13',
		CMUN: '027',
		DC: '7',
		NOMBRE: 'Calzada de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '028',
		DC: '3',
		NOMBRE: 'Campo de Criptana',
	},
	{
		CPRO: '13',
		CMUN: '029',
		DC: '6',
		NOMBRE: 'Cañada de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '030',
		DC: '0',
		NOMBRE: 'Caracuel de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '031',
		DC: '7',
		NOMBRE: 'Carrión de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '032',
		DC: '2',
		NOMBRE: 'Carrizosa',
	},
	{
		CPRO: '13',
		CMUN: '033',
		DC: '8',
		NOMBRE: 'Castellar de Santiago',
	},
	{
		CPRO: '13',
		CMUN: '038',
		DC: '1',
		NOMBRE: 'Chillón',
	},
	{
		CPRO: '13',
		CMUN: '034',
		DC: '3',
		NOMBRE: 'Ciudad Real',
	},
	{
		CPRO: '13',
		CMUN: '035',
		DC: '6',
		NOMBRE: 'Corral de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '036',
		DC: '9',
		NOMBRE: 'Cortijos, Los',
	},
	{
		CPRO: '13',
		CMUN: '037',
		DC: '5',
		NOMBRE: 'Cózar',
	},
	{
		CPRO: '13',
		CMUN: '039',
		DC: '4',
		NOMBRE: 'Daimiel',
	},
	{
		CPRO: '13',
		CMUN: '040',
		DC: '8',
		NOMBRE: 'Fernán Caballero',
	},
	{
		CPRO: '13',
		CMUN: '041',
		DC: '5',
		NOMBRE: 'Fontanarejo',
	},
	{
		CPRO: '13',
		CMUN: '042',
		DC: '0',
		NOMBRE: 'Fuencaliente',
	},
	{
		CPRO: '13',
		CMUN: '043',
		DC: '6',
		NOMBRE: 'Fuenllana',
	},
	{
		CPRO: '13',
		CMUN: '044',
		DC: '1',
		NOMBRE: 'Fuente el Fresno',
	},
	{
		CPRO: '13',
		CMUN: '045',
		DC: '4',
		NOMBRE: 'Granátula de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '046',
		DC: '7',
		NOMBRE: 'Guadalmez',
	},
	{
		CPRO: '13',
		CMUN: '047',
		DC: '3',
		NOMBRE: 'Herencia',
	},
	{
		CPRO: '13',
		CMUN: '048',
		DC: '9',
		NOMBRE: 'Hinojosas de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '049',
		DC: '2',
		NOMBRE: 'Horcajo de los Montes',
	},
	{
		CPRO: '13',
		CMUN: '050',
		DC: '5',
		NOMBRE: 'Labores, Las',
	},
	{
		CPRO: '13',
		CMUN: '904',
		DC: '0',
		NOMBRE: 'Llanos del Caudillo',
	},
	{
		CPRO: '13',
		CMUN: '051',
		DC: '2',
		NOMBRE: 'Luciana',
	},
	{
		CPRO: '13',
		CMUN: '052',
		DC: '7',
		NOMBRE: 'Malagón',
	},
	{
		CPRO: '13',
		CMUN: '053',
		DC: '3',
		NOMBRE: 'Manzanares',
	},
	{
		CPRO: '13',
		CMUN: '054',
		DC: '8',
		NOMBRE: 'Membrilla',
	},
	{
		CPRO: '13',
		CMUN: '055',
		DC: '1',
		NOMBRE: 'Mestanza',
	},
	{
		CPRO: '13',
		CMUN: '056',
		DC: '4',
		NOMBRE: 'Miguelturra',
	},
	{
		CPRO: '13',
		CMUN: '057',
		DC: '0',
		NOMBRE: 'Montiel',
	},
	{
		CPRO: '13',
		CMUN: '058',
		DC: '6',
		NOMBRE: 'Moral de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '059',
		DC: '9',
		NOMBRE: 'Navalpino',
	},
	{
		CPRO: '13',
		CMUN: '060',
		DC: '3',
		NOMBRE: 'Navas de Estena',
	},
	{
		CPRO: '13',
		CMUN: '061',
		DC: '0',
		NOMBRE: 'Pedro Muñoz',
	},
	{
		CPRO: '13',
		CMUN: '062',
		DC: '5',
		NOMBRE: 'Picón',
	},
	{
		CPRO: '13',
		CMUN: '063',
		DC: '1',
		NOMBRE: 'Piedrabuena',
	},
	{
		CPRO: '13',
		CMUN: '064',
		DC: '6',
		NOMBRE: 'Poblete',
	},
	{
		CPRO: '13',
		CMUN: '065',
		DC: '9',
		NOMBRE: 'Porzuna',
	},
	{
		CPRO: '13',
		CMUN: '066',
		DC: '2',
		NOMBRE: 'Pozuelo de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '067',
		DC: '8',
		NOMBRE: 'Pozuelos de Calatrava, Los',
	},
	{
		CPRO: '13',
		CMUN: '068',
		DC: '4',
		NOMBRE: 'Puebla de Don Rodrigo',
	},
	{
		CPRO: '13',
		CMUN: '069',
		DC: '7',
		NOMBRE: 'Puebla del Príncipe',
	},
	{
		CPRO: '13',
		CMUN: '070',
		DC: '1',
		NOMBRE: 'Puerto Lápice',
	},
	{
		CPRO: '13',
		CMUN: '071',
		DC: '8',
		NOMBRE: 'Puertollano',
	},
	{
		CPRO: '13',
		CMUN: '072',
		DC: '3',
		NOMBRE: 'Retuerta del Bullaque',
	},
	{
		CPRO: '13',
		CMUN: '901',
		DC: '4',
		NOMBRE: 'Robledo, El',
	},
	{
		CPRO: '13',
		CMUN: '902',
		DC: '9',
		NOMBRE: 'Ruidera',
	},
	{
		CPRO: '13',
		CMUN: '073',
		DC: '9',
		NOMBRE: 'Saceruela',
	},
	{
		CPRO: '13',
		CMUN: '074',
		DC: '4',
		NOMBRE: 'San Carlos del Valle',
	},
	{
		CPRO: '13',
		CMUN: '075',
		DC: '7',
		NOMBRE: 'San Lorenzo de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '076',
		DC: '0',
		NOMBRE: 'Santa Cruz de los Cáñamos',
	},
	{
		CPRO: '13',
		CMUN: '077',
		DC: '6',
		NOMBRE: 'Santa Cruz de Mudela',
	},
	{
		CPRO: '13',
		CMUN: '078',
		DC: '2',
		NOMBRE: 'Socuéllamos',
	},
	{
		CPRO: '13',
		CMUN: '079',
		DC: '5',
		NOMBRE: 'Solana, La',
	},
	{
		CPRO: '13',
		CMUN: '080',
		DC: '9',
		NOMBRE: 'Solana del Pino',
	},
	{
		CPRO: '13',
		CMUN: '081',
		DC: '6',
		NOMBRE: 'Terrinches',
	},
	{
		CPRO: '13',
		CMUN: '082',
		DC: '1',
		NOMBRE: 'Tomelloso',
	},
	{
		CPRO: '13',
		CMUN: '083',
		DC: '7',
		NOMBRE: 'Torralba de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '084',
		DC: '2',
		NOMBRE: 'Torre de Juan Abad',
	},
	{
		CPRO: '13',
		CMUN: '085',
		DC: '5',
		NOMBRE: 'Torrenueva',
	},
	{
		CPRO: '13',
		CMUN: '086',
		DC: '8',
		NOMBRE: 'Valdemanco del Esteras',
	},
	{
		CPRO: '13',
		CMUN: '087',
		DC: '4',
		NOMBRE: 'Valdepeñas',
	},
	{
		CPRO: '13',
		CMUN: '088',
		DC: '0',
		NOMBRE: 'Valenzuela de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '089',
		DC: '3',
		NOMBRE: 'Villahermosa',
	},
	{
		CPRO: '13',
		CMUN: '090',
		DC: '7',
		NOMBRE: 'Villamanrique',
	},
	{
		CPRO: '13',
		CMUN: '091',
		DC: '4',
		NOMBRE: 'Villamayor de Calatrava',
	},
	{
		CPRO: '13',
		CMUN: '092',
		DC: '9',
		NOMBRE: 'Villanueva de la Fuente',
	},
	{
		CPRO: '13',
		CMUN: '093',
		DC: '5',
		NOMBRE: 'Villanueva de los Infantes',
	},
	{
		CPRO: '13',
		CMUN: '094',
		DC: '0',
		NOMBRE: 'Villanueva de San Carlos',
	},
	{
		CPRO: '13',
		CMUN: '095',
		DC: '3',
		NOMBRE: 'Villar del Pozo',
	},
	{
		CPRO: '13',
		CMUN: '096',
		DC: '6',
		NOMBRE: 'Villarrubia de los Ojos',
	},
	{
		CPRO: '13',
		CMUN: '097',
		DC: '2',
		NOMBRE: 'Villarta de San Juan',
	},
	{
		CPRO: '13',
		CMUN: '098',
		DC: '8',
		NOMBRE: 'Viso del Marqués',
	},
	{
		CPRO: '14',
		CMUN: '001',
		DC: '8',
		NOMBRE: 'Adamuz',
	},
	{
		CPRO: '14',
		CMUN: '002',
		DC: '3',
		NOMBRE: 'Aguilar de la Frontera',
	},
	{
		CPRO: '14',
		CMUN: '003',
		DC: '9',
		NOMBRE: 'Alcaracejos',
	},
	{
		CPRO: '14',
		CMUN: '004',
		DC: '4',
		NOMBRE: 'Almedinilla',
	},
	{
		CPRO: '14',
		CMUN: '005',
		DC: '7',
		NOMBRE: 'Almodóvar del Río',
	},
	{
		CPRO: '14',
		CMUN: '006',
		DC: '0',
		NOMBRE: 'Añora',
	},
	{
		CPRO: '14',
		CMUN: '007',
		DC: '6',
		NOMBRE: 'Baena',
	},
	{
		CPRO: '14',
		CMUN: '008',
		DC: '2',
		NOMBRE: 'Belalcázar',
	},
	{
		CPRO: '14',
		CMUN: '009',
		DC: '5',
		NOMBRE: 'Belmez',
	},
	{
		CPRO: '14',
		CMUN: '010',
		DC: '9',
		NOMBRE: 'Benamejí',
	},
	{
		CPRO: '14',
		CMUN: '011',
		DC: '6',
		NOMBRE: 'Blázquez, Los',
	},
	{
		CPRO: '14',
		CMUN: '012',
		DC: '1',
		NOMBRE: 'Bujalance',
	},
	{
		CPRO: '14',
		CMUN: '013',
		DC: '7',
		NOMBRE: 'Cabra',
	},
	{
		CPRO: '14',
		CMUN: '014',
		DC: '2',
		NOMBRE: 'Cañete de las Torres',
	},
	{
		CPRO: '14',
		CMUN: '015',
		DC: '5',
		NOMBRE: 'Carcabuey',
	},
	{
		CPRO: '14',
		CMUN: '016',
		DC: '8',
		NOMBRE: 'Cardeña',
	},
	{
		CPRO: '14',
		CMUN: '017',
		DC: '4',
		NOMBRE: 'Carlota, La',
	},
	{
		CPRO: '14',
		CMUN: '018',
		DC: '0',
		NOMBRE: 'Carpio, El',
	},
	{
		CPRO: '14',
		CMUN: '019',
		DC: '3',
		NOMBRE: 'Castro del Río',
	},
	{
		CPRO: '14',
		CMUN: '020',
		DC: '7',
		NOMBRE: 'Conquista',
	},
	{
		CPRO: '14',
		CMUN: '021',
		DC: '4',
		NOMBRE: 'Córdoba',
	},
	{
		CPRO: '14',
		CMUN: '022',
		DC: '9',
		NOMBRE: 'Doña Mencía',
	},
	{
		CPRO: '14',
		CMUN: '023',
		DC: '5',
		NOMBRE: 'Dos Torres',
	},
	{
		CPRO: '14',
		CMUN: '024',
		DC: '0',
		NOMBRE: 'Encinas Reales',
	},
	{
		CPRO: '14',
		CMUN: '025',
		DC: '3',
		NOMBRE: 'Espejo',
	},
	{
		CPRO: '14',
		CMUN: '026',
		DC: '6',
		NOMBRE: 'Espiel',
	},
	{
		CPRO: '14',
		CMUN: '027',
		DC: '2',
		NOMBRE: 'Fernán-Núñez',
	},
	{
		CPRO: '14',
		CMUN: '028',
		DC: '8',
		NOMBRE: 'Fuente la Lancha',
	},
	{
		CPRO: '14',
		CMUN: '029',
		DC: '1',
		NOMBRE: 'Fuente Obejuna',
	},
	{
		CPRO: '14',
		CMUN: '030',
		DC: '5',
		NOMBRE: 'Fuente Palmera',
	},
	{
		CPRO: '14',
		CMUN: '031',
		DC: '2',
		NOMBRE: 'Fuente-Tójar',
	},
	{
		CPRO: '14',
		CMUN: '032',
		DC: '7',
		NOMBRE: 'Granjuela, La',
	},
	{
		CPRO: '14',
		CMUN: '033',
		DC: '3',
		NOMBRE: 'Guadalcázar',
	},
	{
		CPRO: '14',
		CMUN: '034',
		DC: '8',
		NOMBRE: 'Guijo, El',
	},
	{
		CPRO: '14',
		CMUN: '035',
		DC: '1',
		NOMBRE: 'Hinojosa del Duque',
	},
	{
		CPRO: '14',
		CMUN: '036',
		DC: '4',
		NOMBRE: 'Hornachuelos',
	},
	{
		CPRO: '14',
		CMUN: '037',
		DC: '0',
		NOMBRE: 'Iznájar',
	},
	{
		CPRO: '14',
		CMUN: '038',
		DC: '6',
		NOMBRE: 'Lucena',
	},
	{
		CPRO: '14',
		CMUN: '039',
		DC: '9',
		NOMBRE: 'Luque',
	},
	{
		CPRO: '14',
		CMUN: '040',
		DC: '3',
		NOMBRE: 'Montalbán de Córdoba',
	},
	{
		CPRO: '14',
		CMUN: '041',
		DC: '0',
		NOMBRE: 'Montemayor',
	},
	{
		CPRO: '14',
		CMUN: '042',
		DC: '5',
		NOMBRE: 'Montilla',
	},
	{
		CPRO: '14',
		CMUN: '043',
		DC: '1',
		NOMBRE: 'Montoro',
	},
	{
		CPRO: '14',
		CMUN: '044',
		DC: '6',
		NOMBRE: 'Monturque',
	},
	{
		CPRO: '14',
		CMUN: '045',
		DC: '9',
		NOMBRE: 'Moriles',
	},
	{
		CPRO: '14',
		CMUN: '046',
		DC: '2',
		NOMBRE: 'Nueva Carteya',
	},
	{
		CPRO: '14',
		CMUN: '047',
		DC: '8',
		NOMBRE: 'Obejo',
	},
	{
		CPRO: '14',
		CMUN: '048',
		DC: '4',
		NOMBRE: 'Palenciana',
	},
	{
		CPRO: '14',
		CMUN: '049',
		DC: '7',
		NOMBRE: 'Palma del Río',
	},
	{
		CPRO: '14',
		CMUN: '050',
		DC: '0',
		NOMBRE: 'Pedro Abad',
	},
	{
		CPRO: '14',
		CMUN: '051',
		DC: '7',
		NOMBRE: 'Pedroche',
	},
	{
		CPRO: '14',
		CMUN: '052',
		DC: '2',
		NOMBRE: 'Peñarroya-Pueblonuevo',
	},
	{
		CPRO: '14',
		CMUN: '053',
		DC: '8',
		NOMBRE: 'Posadas',
	},
	{
		CPRO: '14',
		CMUN: '054',
		DC: '3',
		NOMBRE: 'Pozoblanco',
	},
	{
		CPRO: '14',
		CMUN: '055',
		DC: '6',
		NOMBRE: 'Priego de Córdoba',
	},
	{
		CPRO: '14',
		CMUN: '056',
		DC: '9',
		NOMBRE: 'Puente Genil',
	},
	{
		CPRO: '14',
		CMUN: '057',
		DC: '5',
		NOMBRE: 'Rambla, La',
	},
	{
		CPRO: '14',
		CMUN: '058',
		DC: '1',
		NOMBRE: 'Rute',
	},
	{
		CPRO: '14',
		CMUN: '059',
		DC: '4',
		NOMBRE: 'San Sebastián de los Ballesteros',
	},
	{
		CPRO: '14',
		CMUN: '061',
		DC: '5',
		NOMBRE: 'Santa Eufemia',
	},
	{
		CPRO: '14',
		CMUN: '060',
		DC: '8',
		NOMBRE: 'Santaella',
	},
	{
		CPRO: '14',
		CMUN: '062',
		DC: '0',
		NOMBRE: 'Torrecampo',
	},
	{
		CPRO: '14',
		CMUN: '063',
		DC: '6',
		NOMBRE: 'Valenzuela',
	},
	{
		CPRO: '14',
		CMUN: '064',
		DC: '1',
		NOMBRE: 'Valsequillo',
	},
	{
		CPRO: '14',
		CMUN: '065',
		DC: '4',
		NOMBRE: 'Victoria, La',
	},
	{
		CPRO: '14',
		CMUN: '066',
		DC: '7',
		NOMBRE: 'Villa del Río',
	},
	{
		CPRO: '14',
		CMUN: '067',
		DC: '3',
		NOMBRE: 'Villafranca de Córdoba',
	},
	{
		CPRO: '14',
		CMUN: '068',
		DC: '9',
		NOMBRE: 'Villaharta',
	},
	{
		CPRO: '14',
		CMUN: '069',
		DC: '2',
		NOMBRE: 'Villanueva de Córdoba',
	},
	{
		CPRO: '14',
		CMUN: '070',
		DC: '6',
		NOMBRE: 'Villanueva del Duque',
	},
	{
		CPRO: '14',
		CMUN: '071',
		DC: '3',
		NOMBRE: 'Villanueva del Rey',
	},
	{
		CPRO: '14',
		CMUN: '072',
		DC: '8',
		NOMBRE: 'Villaralto',
	},
	{
		CPRO: '14',
		CMUN: '073',
		DC: '4',
		NOMBRE: 'Villaviciosa de Córdoba',
	},
	{
		CPRO: '14',
		CMUN: '074',
		DC: '9',
		NOMBRE: 'Viso, El',
	},
	{
		CPRO: '14',
		CMUN: '075',
		DC: '2',
		NOMBRE: 'Zuheros',
	},
	{
		CPRO: '15',
		CMUN: '001',
		DC: '1',
		NOMBRE: 'Abegondo',
	},
	{
		CPRO: '15',
		CMUN: '002',
		DC: '6',
		NOMBRE: 'Ames',
	},
	{
		CPRO: '15',
		CMUN: '003',
		DC: '2',
		NOMBRE: 'Aranga',
	},
	{
		CPRO: '15',
		CMUN: '004',
		DC: '7',
		NOMBRE: 'Ares',
	},
	{
		CPRO: '15',
		CMUN: '005',
		DC: '0',
		NOMBRE: 'Arteixo',
	},
	{
		CPRO: '15',
		CMUN: '006',
		DC: '3',
		NOMBRE: 'Arzúa',
	},
	{
		CPRO: '15',
		CMUN: '007',
		DC: '9',
		NOMBRE: 'Baña, A',
	},
	{
		CPRO: '15',
		CMUN: '008',
		DC: '5',
		NOMBRE: 'Bergondo',
	},
	{
		CPRO: '15',
		CMUN: '009',
		DC: '8',
		NOMBRE: 'Betanzos',
	},
	{
		CPRO: '15',
		CMUN: '010',
		DC: '2',
		NOMBRE: 'Boimorto',
	},
	{
		CPRO: '15',
		CMUN: '011',
		DC: '9',
		NOMBRE: 'Boiro',
	},
	{
		CPRO: '15',
		CMUN: '012',
		DC: '4',
		NOMBRE: 'Boqueixón',
	},
	{
		CPRO: '15',
		CMUN: '013',
		DC: '0',
		NOMBRE: 'Brión',
	},
	{
		CPRO: '15',
		CMUN: '014',
		DC: '5',
		NOMBRE: 'Cabana de Bergantiños',
	},
	{
		CPRO: '15',
		CMUN: '015',
		DC: '8',
		NOMBRE: 'Cabanas',
	},
	{
		CPRO: '15',
		CMUN: '016',
		DC: '1',
		NOMBRE: 'Camariñas',
	},
	{
		CPRO: '15',
		CMUN: '017',
		DC: '7',
		NOMBRE: 'Cambre',
	},
	{
		CPRO: '15',
		CMUN: '018',
		DC: '3',
		NOMBRE: 'Capela, A',
	},
	{
		CPRO: '15',
		CMUN: '019',
		DC: '6',
		NOMBRE: 'Carballo',
	},
	{
		CPRO: '15',
		CMUN: '901',
		DC: '2',
		NOMBRE: 'Cariño',
	},
	{
		CPRO: '15',
		CMUN: '020',
		DC: '0',
		NOMBRE: 'Carnota',
	},
	{
		CPRO: '15',
		CMUN: '021',
		DC: '7',
		NOMBRE: 'Carral',
	},
	{
		CPRO: '15',
		CMUN: '022',
		DC: '2',
		NOMBRE: 'Cedeira',
	},
	{
		CPRO: '15',
		CMUN: '023',
		DC: '8',
		NOMBRE: 'Cee',
	},
	{
		CPRO: '15',
		CMUN: '024',
		DC: '3',
		NOMBRE: 'Cerceda',
	},
	{
		CPRO: '15',
		CMUN: '025',
		DC: '6',
		NOMBRE: 'Cerdido',
	},
	{
		CPRO: '15',
		CMUN: '026',
		DC: '9',
		NOMBRE: 'Cesuras',
	},
	{
		CPRO: '15',
		CMUN: '027',
		DC: '5',
		NOMBRE: 'Coirós',
	},
	{
		CPRO: '15',
		CMUN: '028',
		DC: '1',
		NOMBRE: 'Corcubión',
	},
	{
		CPRO: '15',
		CMUN: '029',
		DC: '4',
		NOMBRE: 'Coristanco',
	},
	{
		CPRO: '15',
		CMUN: '030',
		DC: '8',
		NOMBRE: 'Coruña, A',
	},
	{
		CPRO: '15',
		CMUN: '031',
		DC: '5',
		NOMBRE: 'Culleredo',
	},
	{
		CPRO: '15',
		CMUN: '032',
		DC: '0',
		NOMBRE: 'Curtis',
	},
	{
		CPRO: '15',
		CMUN: '033',
		DC: '6',
		NOMBRE: 'Dodro',
	},
	{
		CPRO: '15',
		CMUN: '034',
		DC: '1',
		NOMBRE: 'Dumbría',
	},
	{
		CPRO: '15',
		CMUN: '035',
		DC: '4',
		NOMBRE: 'Fene',
	},
	{
		CPRO: '15',
		CMUN: '036',
		DC: '7',
		NOMBRE: 'Ferrol',
	},
	{
		CPRO: '15',
		CMUN: '037',
		DC: '3',
		NOMBRE: 'Fisterra',
	},
	{
		CPRO: '15',
		CMUN: '038',
		DC: '9',
		NOMBRE: 'Frades',
	},
	{
		CPRO: '15',
		CMUN: '039',
		DC: '2',
		NOMBRE: 'Irixoa',
	},
	{
		CPRO: '15',
		CMUN: '041',
		DC: '3',
		NOMBRE: 'Laracha, A',
	},
	{
		CPRO: '15',
		CMUN: '040',
		DC: '6',
		NOMBRE: 'Laxe',
	},
	{
		CPRO: '15',
		CMUN: '042',
		DC: '8',
		NOMBRE: 'Lousame',
	},
	{
		CPRO: '15',
		CMUN: '043',
		DC: '4',
		NOMBRE: 'Malpica de Bergantiños',
	},
	{
		CPRO: '15',
		CMUN: '044',
		DC: '9',
		NOMBRE: 'Mañón',
	},
	{
		CPRO: '15',
		CMUN: '045',
		DC: '2',
		NOMBRE: 'Mazaricos',
	},
	{
		CPRO: '15',
		CMUN: '046',
		DC: '5',
		NOMBRE: 'Melide',
	},
	{
		CPRO: '15',
		CMUN: '047',
		DC: '1',
		NOMBRE: 'Mesía',
	},
	{
		CPRO: '15',
		CMUN: '048',
		DC: '7',
		NOMBRE: 'Miño',
	},
	{
		CPRO: '15',
		CMUN: '049',
		DC: '0',
		NOMBRE: 'Moeche',
	},
	{
		CPRO: '15',
		CMUN: '050',
		DC: '3',
		NOMBRE: 'Monfero',
	},
	{
		CPRO: '15',
		CMUN: '051',
		DC: '0',
		NOMBRE: 'Mugardos',
	},
	{
		CPRO: '15',
		CMUN: '053',
		DC: '1',
		NOMBRE: 'Muros',
	},
	{
		CPRO: '15',
		CMUN: '052',
		DC: '5',
		NOMBRE: 'Muxía',
	},
	{
		CPRO: '15',
		CMUN: '054',
		DC: '6',
		NOMBRE: 'Narón',
	},
	{
		CPRO: '15',
		CMUN: '055',
		DC: '9',
		NOMBRE: 'Neda',
	},
	{
		CPRO: '15',
		CMUN: '056',
		DC: '2',
		NOMBRE: 'Negreira',
	},
	{
		CPRO: '15',
		CMUN: '057',
		DC: '8',
		NOMBRE: 'Noia',
	},
	{
		CPRO: '15',
		CMUN: '058',
		DC: '4',
		NOMBRE: 'Oleiros',
	},
	{
		CPRO: '15',
		CMUN: '059',
		DC: '7',
		NOMBRE: 'Ordes',
	},
	{
		CPRO: '15',
		CMUN: '060',
		DC: '1',
		NOMBRE: 'Oroso',
	},
	{
		CPRO: '15',
		CMUN: '061',
		DC: '8',
		NOMBRE: 'Ortigueira',
	},
	{
		CPRO: '15',
		CMUN: '062',
		DC: '3',
		NOMBRE: 'Outes',
	},
	{
		CPRO: '15',
		CMUN: '063',
		DC: '9',
		NOMBRE: 'Oza dos Ríos',
	},
	{
		CPRO: '15',
		CMUN: '064',
		DC: '4',
		NOMBRE: 'Paderne',
	},
	{
		CPRO: '15',
		CMUN: '065',
		DC: '7',
		NOMBRE: 'Padrón',
	},
	{
		CPRO: '15',
		CMUN: '066',
		DC: '0',
		NOMBRE: 'Pino, O',
	},
	{
		CPRO: '15',
		CMUN: '067',
		DC: '6',
		NOMBRE: 'Pobra do Caramiñal, A',
	},
	{
		CPRO: '15',
		CMUN: '068',
		DC: '2',
		NOMBRE: 'Ponteceso',
	},
	{
		CPRO: '15',
		CMUN: '069',
		DC: '5',
		NOMBRE: 'Pontedeume',
	},
	{
		CPRO: '15',
		CMUN: '070',
		DC: '9',
		NOMBRE: 'Pontes de García Rodríguez, As',
	},
	{
		CPRO: '15',
		CMUN: '071',
		DC: '6',
		NOMBRE: 'Porto do Son',
	},
	{
		CPRO: '15',
		CMUN: '072',
		DC: '1',
		NOMBRE: 'Rianxo',
	},
	{
		CPRO: '15',
		CMUN: '073',
		DC: '7',
		NOMBRE: 'Ribeira',
	},
	{
		CPRO: '15',
		CMUN: '074',
		DC: '2',
		NOMBRE: 'Rois',
	},
	{
		CPRO: '15',
		CMUN: '075',
		DC: '5',
		NOMBRE: 'Sada',
	},
	{
		CPRO: '15',
		CMUN: '076',
		DC: '8',
		NOMBRE: 'San Sadurniño',
	},
	{
		CPRO: '15',
		CMUN: '077',
		DC: '4',
		NOMBRE: 'Santa Comba',
	},
	{
		CPRO: '15',
		CMUN: '078',
		DC: '0',
		NOMBRE: 'Santiago de Compostela',
	},
	{
		CPRO: '15',
		CMUN: '079',
		DC: '3',
		NOMBRE: 'Santiso',
	},
	{
		CPRO: '15',
		CMUN: '080',
		DC: '7',
		NOMBRE: 'Sobrado',
	},
	{
		CPRO: '15',
		CMUN: '081',
		DC: '4',
		NOMBRE: 'Somozas, As',
	},
	{
		CPRO: '15',
		CMUN: '082',
		DC: '9',
		NOMBRE: 'Teo',
	},
	{
		CPRO: '15',
		CMUN: '083',
		DC: '5',
		NOMBRE: 'Toques',
	},
	{
		CPRO: '15',
		CMUN: '084',
		DC: '0',
		NOMBRE: 'Tordoia',
	},
	{
		CPRO: '15',
		CMUN: '085',
		DC: '3',
		NOMBRE: 'Touro',
	},
	{
		CPRO: '15',
		CMUN: '086',
		DC: '6',
		NOMBRE: 'Trazo',
	},
	{
		CPRO: '15',
		CMUN: '088',
		DC: '8',
		NOMBRE: 'Val do Dubra',
	},
	{
		CPRO: '15',
		CMUN: '087',
		DC: '2',
		NOMBRE: 'Valdoviño',
	},
	{
		CPRO: '15',
		CMUN: '089',
		DC: '1',
		NOMBRE: 'Vedra',
	},
	{
		CPRO: '15',
		CMUN: '091',
		DC: '2',
		NOMBRE: 'Vilarmaior',
	},
	{
		CPRO: '15',
		CMUN: '090',
		DC: '5',
		NOMBRE: 'Vilasantar',
	},
	{
		CPRO: '15',
		CMUN: '092',
		DC: '7',
		NOMBRE: 'Vimianzo',
	},
	{
		CPRO: '15',
		CMUN: '093',
		DC: '3',
		NOMBRE: 'Zas',
	},
	{
		CPRO: '16',
		CMUN: '001',
		DC: '4',
		NOMBRE: 'Abia de la Obispalía',
	},
	{
		CPRO: '16',
		CMUN: '002',
		DC: '9',
		NOMBRE: 'Acebrón, El',
	},
	{
		CPRO: '16',
		CMUN: '003',
		DC: '5',
		NOMBRE: 'Alarcón',
	},
	{
		CPRO: '16',
		CMUN: '004',
		DC: '0',
		NOMBRE: 'Albaladejo del Cuende',
	},
	{
		CPRO: '16',
		CMUN: '005',
		DC: '3',
		NOMBRE: 'Albalate de las Nogueras',
	},
	{
		CPRO: '16',
		CMUN: '006',
		DC: '6',
		NOMBRE: 'Albendea',
	},
	{
		CPRO: '16',
		CMUN: '007',
		DC: '2',
		NOMBRE: 'Alberca de Záncara, La',
	},
	{
		CPRO: '16',
		CMUN: '008',
		DC: '8',
		NOMBRE: 'Alcalá de la Vega',
	},
	{
		CPRO: '16',
		CMUN: '009',
		DC: '1',
		NOMBRE: 'Alcantud',
	},
	{
		CPRO: '16',
		CMUN: '010',
		DC: '5',
		NOMBRE: 'Alcázar del Rey',
	},
	{
		CPRO: '16',
		CMUN: '011',
		DC: '2',
		NOMBRE: 'Alcohujate',
	},
	{
		CPRO: '16',
		CMUN: '012',
		DC: '7',
		NOMBRE: 'Alconchel de la Estrella',
	},
	{
		CPRO: '16',
		CMUN: '013',
		DC: '3',
		NOMBRE: 'Algarra',
	},
	{
		CPRO: '16',
		CMUN: '014',
		DC: '8',
		NOMBRE: 'Aliaguilla',
	},
	{
		CPRO: '16',
		CMUN: '015',
		DC: '1',
		NOMBRE: 'Almarcha, La',
	},
	{
		CPRO: '16',
		CMUN: '016',
		DC: '4',
		NOMBRE: 'Almendros',
	},
	{
		CPRO: '16',
		CMUN: '017',
		DC: '0',
		NOMBRE: 'Almodóvar del Pinar',
	},
	{
		CPRO: '16',
		CMUN: '018',
		DC: '6',
		NOMBRE: 'Almonacid del Marquesado',
	},
	{
		CPRO: '16',
		CMUN: '019',
		DC: '9',
		NOMBRE: 'Altarejos',
	},
	{
		CPRO: '16',
		CMUN: '020',
		DC: '3',
		NOMBRE: 'Arandilla del Arroyo',
	},
	{
		CPRO: '16',
		CMUN: '905',
		DC: '4',
		NOMBRE: 'Arcas del Villar',
	},
	{
		CPRO: '16',
		CMUN: '022',
		DC: '5',
		NOMBRE: 'Arcos de la Sierra',
	},
	{
		CPRO: '16',
		CMUN: '024',
		DC: '6',
		NOMBRE: 'Arguisuelas',
	},
	{
		CPRO: '16',
		CMUN: '025',
		DC: '9',
		NOMBRE: 'Arrancacepas',
	},
	{
		CPRO: '16',
		CMUN: '026',
		DC: '2',
		NOMBRE: 'Atalaya del Cañavate',
	},
	{
		CPRO: '16',
		CMUN: '027',
		DC: '8',
		NOMBRE: 'Barajas de Melo',
	},
	{
		CPRO: '16',
		CMUN: '029',
		DC: '7',
		NOMBRE: 'Barchín del Hoyo',
	},
	{
		CPRO: '16',
		CMUN: '030',
		DC: '1',
		NOMBRE: 'Bascuñana de San Pedro',
	},
	{
		CPRO: '16',
		CMUN: '031',
		DC: '8',
		NOMBRE: 'Beamud',
	},
	{
		CPRO: '16',
		CMUN: '032',
		DC: '3',
		NOMBRE: 'Belinchón',
	},
	{
		CPRO: '16',
		CMUN: '033',
		DC: '9',
		NOMBRE: 'Belmonte',
	},
	{
		CPRO: '16',
		CMUN: '034',
		DC: '4',
		NOMBRE: 'Belmontejo',
	},
	{
		CPRO: '16',
		CMUN: '035',
		DC: '7',
		NOMBRE: 'Beteta',
	},
	{
		CPRO: '16',
		CMUN: '036',
		DC: '0',
		NOMBRE: 'Boniches',
	},
	{
		CPRO: '16',
		CMUN: '038',
		DC: '2',
		NOMBRE: 'Buciegas',
	},
	{
		CPRO: '16',
		CMUN: '039',
		DC: '5',
		NOMBRE: 'Buenache de Alarcón',
	},
	{
		CPRO: '16',
		CMUN: '040',
		DC: '9',
		NOMBRE: 'Buenache de la Sierra',
	},
	{
		CPRO: '16',
		CMUN: '041',
		DC: '6',
		NOMBRE: 'Buendía',
	},
	{
		CPRO: '16',
		CMUN: '042',
		DC: '1',
		NOMBRE: 'Campillo de Altobuey',
	},
	{
		CPRO: '16',
		CMUN: '043',
		DC: '7',
		NOMBRE: 'Campillos-Paravientos',
	},
	{
		CPRO: '16',
		CMUN: '044',
		DC: '2',
		NOMBRE: 'Campillos-Sierra',
	},
	{
		CPRO: '16',
		CMUN: '901',
		DC: '5',
		NOMBRE: 'Campos del Paraíso',
	},
	{
		CPRO: '16',
		CMUN: '045',
		DC: '5',
		NOMBRE: 'Canalejas del Arroyo',
	},
	{
		CPRO: '16',
		CMUN: '046',
		DC: '8',
		NOMBRE: 'Cañada del Hoyo',
	},
	{
		CPRO: '16',
		CMUN: '047',
		DC: '4',
		NOMBRE: 'Cañada Juncosa',
	},
	{
		CPRO: '16',
		CMUN: '048',
		DC: '0',
		NOMBRE: 'Cañamares',
	},
	{
		CPRO: '16',
		CMUN: '049',
		DC: '3',
		NOMBRE: 'Cañavate, El',
	},
	{
		CPRO: '16',
		CMUN: '050',
		DC: '6',
		NOMBRE: 'Cañaveras',
	},
	{
		CPRO: '16',
		CMUN: '051',
		DC: '3',
		NOMBRE: 'Cañaveruelas',
	},
	{
		CPRO: '16',
		CMUN: '052',
		DC: '8',
		NOMBRE: 'Cañete',
	},
	{
		CPRO: '16',
		CMUN: '053',
		DC: '4',
		NOMBRE: 'Cañizares',
	},
	{
		CPRO: '16',
		CMUN: '055',
		DC: '2',
		NOMBRE: 'Carboneras de Guadazaón',
	},
	{
		CPRO: '16',
		CMUN: '056',
		DC: '5',
		NOMBRE: 'Cardenete',
	},
	{
		CPRO: '16',
		CMUN: '057',
		DC: '1',
		NOMBRE: 'Carrascosa',
	},
	{
		CPRO: '16',
		CMUN: '058',
		DC: '7',
		NOMBRE: 'Carrascosa de Haro',
	},
	{
		CPRO: '16',
		CMUN: '060',
		DC: '4',
		NOMBRE: 'Casas de Benítez',
	},
	{
		CPRO: '16',
		CMUN: '061',
		DC: '1',
		NOMBRE: 'Casas de Fernando Alonso',
	},
	{
		CPRO: '16',
		CMUN: '062',
		DC: '6',
		NOMBRE: 'Casas de Garcimolina',
	},
	{
		CPRO: '16',
		CMUN: '063',
		DC: '2',
		NOMBRE: 'Casas de Guijarro',
	},
	{
		CPRO: '16',
		CMUN: '064',
		DC: '7',
		NOMBRE: 'Casas de Haro',
	},
	{
		CPRO: '16',
		CMUN: '065',
		DC: '0',
		NOMBRE: 'Casas de los Pinos',
	},
	{
		CPRO: '16',
		CMUN: '066',
		DC: '3',
		NOMBRE: 'Casasimarro',
	},
	{
		CPRO: '16',
		CMUN: '067',
		DC: '9',
		NOMBRE: 'Castejón',
	},
	{
		CPRO: '16',
		CMUN: '068',
		DC: '5',
		NOMBRE: 'Castillejo de Iniesta',
	},
	{
		CPRO: '16',
		CMUN: '070',
		DC: '2',
		NOMBRE: 'Castillejo-Sierra',
	},
	{
		CPRO: '16',
		CMUN: '072',
		DC: '4',
		NOMBRE: 'Castillo de Garcimuñoz',
	},
	{
		CPRO: '16',
		CMUN: '071',
		DC: '9',
		NOMBRE: 'Castillo-Albaráñez',
	},
	{
		CPRO: '16',
		CMUN: '073',
		DC: '0',
		NOMBRE: 'Cervera del Llano',
	},
	{
		CPRO: '16',
		CMUN: '023',
		DC: '1',
		NOMBRE: 'Chillarón de Cuenca',
	},
	{
		CPRO: '16',
		CMUN: '081',
		DC: '7',
		NOMBRE: 'Chumillas',
	},
	{
		CPRO: '16',
		CMUN: '074',
		DC: '5',
		NOMBRE: 'Cierva, La',
	},
	{
		CPRO: '16',
		CMUN: '078',
		DC: '3',
		NOMBRE: 'Cuenca',
	},
	{
		CPRO: '16',
		CMUN: '079',
		DC: '6',
		NOMBRE: 'Cueva del Hierro',
	},
	{
		CPRO: '16',
		CMUN: '082',
		DC: '2',
		NOMBRE: 'Enguídanos',
	},
	{
		CPRO: '16',
		CMUN: '083',
		DC: '8',
		NOMBRE: 'Fresneda de Altarejos',
	},
	{
		CPRO: '16',
		CMUN: '084',
		DC: '3',
		NOMBRE: 'Fresneda de la Sierra',
	},
	{
		CPRO: '16',
		CMUN: '085',
		DC: '6',
		NOMBRE: 'Frontera, La',
	},
	{
		CPRO: '16',
		CMUN: '086',
		DC: '9',
		NOMBRE: 'Fuente de Pedro Naharro',
	},
	{
		CPRO: '16',
		CMUN: '087',
		DC: '5',
		NOMBRE: 'Fuentelespino de Haro',
	},
	{
		CPRO: '16',
		CMUN: '088',
		DC: '1',
		NOMBRE: 'Fuentelespino de Moya',
	},
	{
		CPRO: '16',
		CMUN: '904',
		DC: '1',
		NOMBRE: 'Fuentenava de Jábaga',
	},
	{
		CPRO: '16',
		CMUN: '089',
		DC: '4',
		NOMBRE: 'Fuentes',
	},
	{
		CPRO: '16',
		CMUN: '091',
		DC: '5',
		NOMBRE: 'Fuertescusa',
	},
	{
		CPRO: '16',
		CMUN: '092',
		DC: '0',
		NOMBRE: 'Gabaldón',
	},
	{
		CPRO: '16',
		CMUN: '093',
		DC: '6',
		NOMBRE: 'Garaballa',
	},
	{
		CPRO: '16',
		CMUN: '094',
		DC: '1',
		NOMBRE: 'Gascueña',
	},
	{
		CPRO: '16',
		CMUN: '095',
		DC: '4',
		NOMBRE: 'Graja de Campalbo',
	},
	{
		CPRO: '16',
		CMUN: '096',
		DC: '7',
		NOMBRE: 'Graja de Iniesta',
	},
	{
		CPRO: '16',
		CMUN: '097',
		DC: '3',
		NOMBRE: 'Henarejos',
	},
	{
		CPRO: '16',
		CMUN: '098',
		DC: '9',
		NOMBRE: 'Herrumblar, El',
	},
	{
		CPRO: '16',
		CMUN: '099',
		DC: '2',
		NOMBRE: 'Hinojosa, La',
	},
	{
		CPRO: '16',
		CMUN: '100',
		DC: '6',
		NOMBRE: 'Hinojosos, Los',
	},
	{
		CPRO: '16',
		CMUN: '101',
		DC: '3',
		NOMBRE: 'Hito, El',
	},
	{
		CPRO: '16',
		CMUN: '102',
		DC: '8',
		NOMBRE: 'Honrubia',
	},
	{
		CPRO: '16',
		CMUN: '103',
		DC: '4',
		NOMBRE: 'Hontanaya',
	},
	{
		CPRO: '16',
		CMUN: '104',
		DC: '9',
		NOMBRE: 'Hontecillas',
	},
	{
		CPRO: '16',
		CMUN: '106',
		DC: '5',
		NOMBRE: 'Horcajo de Santiago',
	},
	{
		CPRO: '16',
		CMUN: '107',
		DC: '1',
		NOMBRE: 'Huélamo',
	},
	{
		CPRO: '16',
		CMUN: '108',
		DC: '7',
		NOMBRE: 'Huelves',
	},
	{
		CPRO: '16',
		CMUN: '109',
		DC: '0',
		NOMBRE: 'Huérguina',
	},
	{
		CPRO: '16',
		CMUN: '110',
		DC: '4',
		NOMBRE: 'Huerta de la Obispalía',
	},
	{
		CPRO: '16',
		CMUN: '111',
		DC: '1',
		NOMBRE: 'Huerta del Marquesado',
	},
	{
		CPRO: '16',
		CMUN: '112',
		DC: '6',
		NOMBRE: 'Huete',
	},
	{
		CPRO: '16',
		CMUN: '113',
		DC: '2',
		NOMBRE: 'Iniesta',
	},
	{
		CPRO: '16',
		CMUN: '115',
		DC: '0',
		NOMBRE: 'Laguna del Marquesado',
	},
	{
		CPRO: '16',
		CMUN: '116',
		DC: '3',
		NOMBRE: 'Lagunaseca',
	},
	{
		CPRO: '16',
		CMUN: '117',
		DC: '9',
		NOMBRE: 'Landete',
	},
	{
		CPRO: '16',
		CMUN: '118',
		DC: '5',
		NOMBRE: 'Ledaña',
	},
	{
		CPRO: '16',
		CMUN: '119',
		DC: '8',
		NOMBRE: 'Leganiel',
	},
	{
		CPRO: '16',
		CMUN: '121',
		DC: '9',
		NOMBRE: 'Majadas, Las',
	},
	{
		CPRO: '16',
		CMUN: '122',
		DC: '4',
		NOMBRE: 'Mariana',
	},
	{
		CPRO: '16',
		CMUN: '123',
		DC: '0',
		NOMBRE: 'Masegosa',
	},
	{
		CPRO: '16',
		CMUN: '124',
		DC: '5',
		NOMBRE: 'Mesas, Las',
	},
	{
		CPRO: '16',
		CMUN: '125',
		DC: '8',
		NOMBRE: 'Minglanilla',
	},
	{
		CPRO: '16',
		CMUN: '126',
		DC: '1',
		NOMBRE: 'Mira',
	},
	{
		CPRO: '16',
		CMUN: '128',
		DC: '3',
		NOMBRE: 'Monreal del Llano',
	},
	{
		CPRO: '16',
		CMUN: '129',
		DC: '6',
		NOMBRE: 'Montalbanejo',
	},
	{
		CPRO: '16',
		CMUN: '130',
		DC: '0',
		NOMBRE: 'Montalbo',
	},
	{
		CPRO: '16',
		CMUN: '131',
		DC: '7',
		NOMBRE: 'Monteagudo de las Salinas',
	},
	{
		CPRO: '16',
		CMUN: '132',
		DC: '2',
		NOMBRE: 'Mota de Altarejos',
	},
	{
		CPRO: '16',
		CMUN: '133',
		DC: '8',
		NOMBRE: 'Mota del Cuervo',
	},
	{
		CPRO: '16',
		CMUN: '134',
		DC: '3',
		NOMBRE: 'Motilla del Palancar',
	},
	{
		CPRO: '16',
		CMUN: '135',
		DC: '6',
		NOMBRE: 'Moya',
	},
	{
		CPRO: '16',
		CMUN: '137',
		DC: '5',
		NOMBRE: 'Narboneta',
	},
	{
		CPRO: '16',
		CMUN: '139',
		DC: '4',
		NOMBRE: 'Olivares de Júcar',
	},
	{
		CPRO: '16',
		CMUN: '140',
		DC: '8',
		NOMBRE: 'Olmeda de la Cuesta',
	},
	{
		CPRO: '16',
		CMUN: '141',
		DC: '5',
		NOMBRE: 'Olmeda del Rey',
	},
	{
		CPRO: '16',
		CMUN: '142',
		DC: '0',
		NOMBRE: 'Olmedilla de Alarcón',
	},
	{
		CPRO: '16',
		CMUN: '143',
		DC: '6',
		NOMBRE: 'Olmedilla de Eliz',
	},
	{
		CPRO: '16',
		CMUN: '145',
		DC: '4',
		NOMBRE: 'Osa de la Vega',
	},
	{
		CPRO: '16',
		CMUN: '146',
		DC: '7',
		NOMBRE: 'Pajarón',
	},
	{
		CPRO: '16',
		CMUN: '147',
		DC: '3',
		NOMBRE: 'Pajaroncillo',
	},
	{
		CPRO: '16',
		CMUN: '148',
		DC: '9',
		NOMBRE: 'Palomares del Campo',
	},
	{
		CPRO: '16',
		CMUN: '149',
		DC: '2',
		NOMBRE: 'Palomera',
	},
	{
		CPRO: '16',
		CMUN: '150',
		DC: '5',
		NOMBRE: 'Paracuellos',
	},
	{
		CPRO: '16',
		CMUN: '151',
		DC: '2',
		NOMBRE: 'Paredes',
	},
	{
		CPRO: '16',
		CMUN: '152',
		DC: '7',
		NOMBRE: 'Parra de las Vegas, La',
	},
	{
		CPRO: '16',
		CMUN: '153',
		DC: '3',
		NOMBRE: 'Pedernoso, El',
	},
	{
		CPRO: '16',
		CMUN: '154',
		DC: '8',
		NOMBRE: 'Pedroñeras, Las',
	},
	{
		CPRO: '16',
		CMUN: '155',
		DC: '1',
		NOMBRE: 'Peral, El',
	},
	{
		CPRO: '16',
		CMUN: '156',
		DC: '4',
		NOMBRE: 'Peraleja, La',
	},
	{
		CPRO: '16',
		CMUN: '157',
		DC: '0',
		NOMBRE: 'Pesquera, La',
	},
	{
		CPRO: '16',
		CMUN: '158',
		DC: '6',
		NOMBRE: 'Picazo, El',
	},
	{
		CPRO: '16',
		CMUN: '159',
		DC: '9',
		NOMBRE: 'Pinarejo',
	},
	{
		CPRO: '16',
		CMUN: '160',
		DC: '3',
		NOMBRE: 'Pineda de Gigüela',
	},
	{
		CPRO: '16',
		CMUN: '161',
		DC: '0',
		NOMBRE: 'Piqueras del Castillo',
	},
	{
		CPRO: '16',
		CMUN: '162',
		DC: '5',
		NOMBRE: 'Portalrubio de Guadamejud',
	},
	{
		CPRO: '16',
		CMUN: '163',
		DC: '1',
		NOMBRE: 'Portilla',
	},
	{
		CPRO: '16',
		CMUN: '165',
		DC: '9',
		NOMBRE: 'Poyatos',
	},
	{
		CPRO: '16',
		CMUN: '166',
		DC: '2',
		NOMBRE: 'Pozoamargo',
	},
	{
		CPRO: '16',
		CMUN: '908',
		DC: '9',
		NOMBRE: 'Pozorrubielos de la Mancha',
	},
	{
		CPRO: '16',
		CMUN: '167',
		DC: '8',
		NOMBRE: 'Pozorrubio',
	},
	{
		CPRO: '16',
		CMUN: '169',
		DC: '7',
		NOMBRE: 'Pozuelo, El',
	},
	{
		CPRO: '16',
		CMUN: '170',
		DC: '1',
		NOMBRE: 'Priego',
	},
	{
		CPRO: '16',
		CMUN: '171',
		DC: '8',
		NOMBRE: 'Provencio, El',
	},
	{
		CPRO: '16',
		CMUN: '172',
		DC: '3',
		NOMBRE: 'Puebla de Almenara',
	},
	{
		CPRO: '16',
		CMUN: '174',
		DC: '4',
		NOMBRE: 'Puebla del Salvador',
	},
	{
		CPRO: '16',
		CMUN: '175',
		DC: '7',
		NOMBRE: 'Quintanar del Rey',
	},
	{
		CPRO: '16',
		CMUN: '176',
		DC: '0',
		NOMBRE: 'Rada de Haro',
	},
	{
		CPRO: '16',
		CMUN: '177',
		DC: '6',
		NOMBRE: 'Reíllo',
	},
	{
		CPRO: '16',
		CMUN: '181',
		DC: '6',
		NOMBRE: 'Rozalén del Monte',
	},
	{
		CPRO: '16',
		CMUN: '185',
		DC: '5',
		NOMBRE: 'Saceda-Trasierra',
	},
	{
		CPRO: '16',
		CMUN: '186',
		DC: '8',
		NOMBRE: 'Saelices',
	},
	{
		CPRO: '16',
		CMUN: '187',
		DC: '4',
		NOMBRE: 'Salinas del Manzano',
	},
	{
		CPRO: '16',
		CMUN: '188',
		DC: '0',
		NOMBRE: 'Salmeroncillos',
	},
	{
		CPRO: '16',
		CMUN: '189',
		DC: '3',
		NOMBRE: 'Salvacañete',
	},
	{
		CPRO: '16',
		CMUN: '190',
		DC: '7',
		NOMBRE: 'San Clemente',
	},
	{
		CPRO: '16',
		CMUN: '191',
		DC: '4',
		NOMBRE: 'San Lorenzo de la Parrilla',
	},
	{
		CPRO: '16',
		CMUN: '192',
		DC: '9',
		NOMBRE: 'San Martín de Boniches',
	},
	{
		CPRO: '16',
		CMUN: '193',
		DC: '5',
		NOMBRE: 'San Pedro Palmiches',
	},
	{
		CPRO: '16',
		CMUN: '194',
		DC: '0',
		NOMBRE: 'Santa Cruz de Moya',
	},
	{
		CPRO: '16',
		CMUN: '196',
		DC: '6',
		NOMBRE: 'Santa María de los Llanos',
	},
	{
		CPRO: '16',
		CMUN: '195',
		DC: '3',
		NOMBRE: 'Santa María del Campo Rus',
	},
	{
		CPRO: '16',
		CMUN: '197',
		DC: '2',
		NOMBRE: 'Santa María del Val',
	},
	{
		CPRO: '16',
		CMUN: '198',
		DC: '8',
		NOMBRE: 'Sisante',
	},
	{
		CPRO: '16',
		CMUN: '199',
		DC: '1',
		NOMBRE: 'Solera de Gabaldón',
	},
	{
		CPRO: '16',
		CMUN: '909',
		DC: '2',
		NOMBRE: 'Sotorribas',
	},
	{
		CPRO: '16',
		CMUN: '202',
		DC: '7',
		NOMBRE: 'Talayuelas',
	},
	{
		CPRO: '16',
		CMUN: '203',
		DC: '3',
		NOMBRE: 'Tarancón',
	},
	{
		CPRO: '16',
		CMUN: '204',
		DC: '8',
		NOMBRE: 'Tébar',
	},
	{
		CPRO: '16',
		CMUN: '205',
		DC: '1',
		NOMBRE: 'Tejadillos',
	},
	{
		CPRO: '16',
		CMUN: '206',
		DC: '4',
		NOMBRE: 'Tinajas',
	},
	{
		CPRO: '16',
		CMUN: '209',
		DC: '9',
		NOMBRE: 'Torralba',
	},
	{
		CPRO: '16',
		CMUN: '211',
		DC: '0',
		NOMBRE: 'Torrejoncillo del Rey',
	},
	{
		CPRO: '16',
		CMUN: '212',
		DC: '5',
		NOMBRE: 'Torrubia del Campo',
	},
	{
		CPRO: '16',
		CMUN: '213',
		DC: '1',
		NOMBRE: 'Torrubia del Castillo',
	},
	{
		CPRO: '16',
		CMUN: '215',
		DC: '9',
		NOMBRE: 'Tragacete',
	},
	{
		CPRO: '16',
		CMUN: '216',
		DC: '2',
		NOMBRE: 'Tresjuncos',
	},
	{
		CPRO: '16',
		CMUN: '217',
		DC: '8',
		NOMBRE: 'Tribaldos',
	},
	{
		CPRO: '16',
		CMUN: '218',
		DC: '4',
		NOMBRE: 'Uclés',
	},
	{
		CPRO: '16',
		CMUN: '219',
		DC: '7',
		NOMBRE: 'Uña',
	},
	{
		CPRO: '16',
		CMUN: '906',
		DC: '7',
		NOMBRE: 'Valdecolmenas, Los',
	},
	{
		CPRO: '16',
		CMUN: '224',
		DC: '4',
		NOMBRE: 'Valdemeca',
	},
	{
		CPRO: '16',
		CMUN: '225',
		DC: '7',
		NOMBRE: 'Valdemorillo de la Sierra',
	},
	{
		CPRO: '16',
		CMUN: '227',
		DC: '6',
		NOMBRE: 'Valdemoro-Sierra',
	},
	{
		CPRO: '16',
		CMUN: '228',
		DC: '2',
		NOMBRE: 'Valdeolivas',
	},
	{
		CPRO: '16',
		CMUN: '902',
		DC: '0',
		NOMBRE: 'Valdetórtola',
	},
	{
		CPRO: '16',
		CMUN: '903',
		DC: '6',
		NOMBRE: 'Valeras, Las',
	},
	{
		CPRO: '16',
		CMUN: '231',
		DC: '6',
		NOMBRE: 'Valhermoso de la Fuente',
	},
	{
		CPRO: '16',
		CMUN: '173',
		DC: '9',
		NOMBRE: 'Valle de Altomira, El',
	},
	{
		CPRO: '16',
		CMUN: '234',
		DC: '2',
		NOMBRE: 'Valsalobre',
	},
	{
		CPRO: '16',
		CMUN: '236',
		DC: '8',
		NOMBRE: 'Valverde de Júcar',
	},
	{
		CPRO: '16',
		CMUN: '237',
		DC: '4',
		NOMBRE: 'Valverdejo',
	},
	{
		CPRO: '16',
		CMUN: '238',
		DC: '0',
		NOMBRE: 'Vara de Rey',
	},
	{
		CPRO: '16',
		CMUN: '239',
		DC: '3',
		NOMBRE: 'Vega del Codorno',
	},
	{
		CPRO: '16',
		CMUN: '240',
		DC: '7',
		NOMBRE: 'Vellisca',
	},
	{
		CPRO: '16',
		CMUN: '242',
		DC: '9',
		NOMBRE: 'Villaconejos de Trabaque',
	},
	{
		CPRO: '16',
		CMUN: '243',
		DC: '5',
		NOMBRE: 'Villaescusa de Haro',
	},
	{
		CPRO: '16',
		CMUN: '244',
		DC: '0',
		NOMBRE: 'Villagarcía del Llano',
	},
	{
		CPRO: '16',
		CMUN: '245',
		DC: '3',
		NOMBRE: 'Villalba de la Sierra',
	},
	{
		CPRO: '16',
		CMUN: '246',
		DC: '6',
		NOMBRE: 'Villalba del Rey',
	},
	{
		CPRO: '16',
		CMUN: '247',
		DC: '2',
		NOMBRE: 'Villalgordo del Marquesado',
	},
	{
		CPRO: '16',
		CMUN: '248',
		DC: '8',
		NOMBRE: 'Villalpardo',
	},
	{
		CPRO: '16',
		CMUN: '249',
		DC: '1',
		NOMBRE: 'Villamayor de Santiago',
	},
	{
		CPRO: '16',
		CMUN: '250',
		DC: '4',
		NOMBRE: 'Villanueva de Guadamejud',
	},
	{
		CPRO: '16',
		CMUN: '251',
		DC: '1',
		NOMBRE: 'Villanueva de la Jara',
	},
	{
		CPRO: '16',
		CMUN: '253',
		DC: '2',
		NOMBRE: 'Villar de Cañas',
	},
	{
		CPRO: '16',
		CMUN: '254',
		DC: '7',
		NOMBRE: 'Villar de Domingo García',
	},
	{
		CPRO: '16',
		CMUN: '255',
		DC: '0',
		NOMBRE: 'Villar de la Encina',
	},
	{
		CPRO: '16',
		CMUN: '263',
		DC: '0',
		NOMBRE: 'Villar de Olalla',
	},
	{
		CPRO: '16',
		CMUN: '258',
		DC: '5',
		NOMBRE: 'Villar del Humo',
	},
	{
		CPRO: '16',
		CMUN: '259',
		DC: '8',
		NOMBRE: 'Villar del Infantado',
	},
	{
		CPRO: '16',
		CMUN: '910',
		DC: '6',
		NOMBRE: 'Villar y Velasco',
	},
	{
		CPRO: '16',
		CMUN: '264',
		DC: '5',
		NOMBRE: 'Villarejo de Fuentes',
	},
	{
		CPRO: '16',
		CMUN: '265',
		DC: '8',
		NOMBRE: 'Villarejo de la Peñuela',
	},
	{
		CPRO: '16',
		CMUN: '266',
		DC: '1',
		NOMBRE: 'Villarejo-Periesteban',
	},
	{
		CPRO: '16',
		CMUN: '269',
		DC: '6',
		NOMBRE: 'Villares del Saz',
	},
	{
		CPRO: '16',
		CMUN: '270',
		DC: '0',
		NOMBRE: 'Villarrubio',
	},
	{
		CPRO: '16',
		CMUN: '271',
		DC: '7',
		NOMBRE: 'Villarta',
	},
	{
		CPRO: '16',
		CMUN: '272',
		DC: '2',
		NOMBRE: 'Villas de la Ventosa',
	},
	{
		CPRO: '16',
		CMUN: '273',
		DC: '8',
		NOMBRE: 'Villaverde y Pasaconsol',
	},
	{
		CPRO: '16',
		CMUN: '274',
		DC: '3',
		NOMBRE: 'Víllora',
	},
	{
		CPRO: '16',
		CMUN: '275',
		DC: '6',
		NOMBRE: 'Vindel',
	},
	{
		CPRO: '16',
		CMUN: '276',
		DC: '9',
		NOMBRE: 'Yémeda',
	},
	{
		CPRO: '16',
		CMUN: '277',
		DC: '5',
		NOMBRE: 'Zafra de Záncara',
	},
	{
		CPRO: '16',
		CMUN: '278',
		DC: '1',
		NOMBRE: 'Zafrilla',
	},
	{
		CPRO: '16',
		CMUN: '279',
		DC: '4',
		NOMBRE: 'Zarza de Tajo',
	},
	{
		CPRO: '16',
		CMUN: '280',
		DC: '8',
		NOMBRE: 'Zarzuela',
	},
	{
		CPRO: '17',
		CMUN: '001',
		DC: '0',
		NOMBRE: 'Agullana',
	},
	{
		CPRO: '17',
		CMUN: '002',
		DC: '5',
		NOMBRE: 'Aiguaviva',
	},
	{
		CPRO: '17',
		CMUN: '003',
		DC: '1',
		NOMBRE: 'Albanyà',
	},
	{
		CPRO: '17',
		CMUN: '004',
		DC: '6',
		NOMBRE: 'Albons',
	},
	{
		CPRO: '17',
		CMUN: '006',
		DC: '2',
		NOMBRE: 'Alp',
	},
	{
		CPRO: '17',
		CMUN: '007',
		DC: '8',
		NOMBRE: 'Amer',
	},
	{
		CPRO: '17',
		CMUN: '008',
		DC: '4',
		NOMBRE: 'Anglès',
	},
	{
		CPRO: '17',
		CMUN: '009',
		DC: '7',
		NOMBRE: 'Arbúcies',
	},
	{
		CPRO: '17',
		CMUN: '010',
		DC: '1',
		NOMBRE: 'Argelaguer',
	},
	{
		CPRO: '17',
		CMUN: '011',
		DC: '8',
		NOMBRE: "Armentera, L'",
	},
	{
		CPRO: '17',
		CMUN: '012',
		DC: '3',
		NOMBRE: 'Avinyonet de Puigventós',
	},
	{
		CPRO: '17',
		CMUN: '015',
		DC: '7',
		NOMBRE: 'Banyoles',
	},
	{
		CPRO: '17',
		CMUN: '016',
		DC: '0',
		NOMBRE: 'Bàscara',
	},
	{
		CPRO: '17',
		CMUN: '013',
		DC: '9',
		NOMBRE: 'Begur',
	},
	{
		CPRO: '17',
		CMUN: '018',
		DC: '2',
		NOMBRE: "Bellcaire d'Empordà",
	},
	{
		CPRO: '17',
		CMUN: '019',
		DC: '5',
		NOMBRE: 'Besalú',
	},
	{
		CPRO: '17',
		CMUN: '020',
		DC: '9',
		NOMBRE: 'Bescanó',
	},
	{
		CPRO: '17',
		CMUN: '021',
		DC: '6',
		NOMBRE: 'Beuda',
	},
	{
		CPRO: '17',
		CMUN: '022',
		DC: '1',
		NOMBRE: "Bisbal d'Empordà, La",
	},
	{
		CPRO: '17',
		CMUN: '234',
		DC: '8',
		NOMBRE: 'Biure',
	},
	{
		CPRO: '17',
		CMUN: '023',
		DC: '7',
		NOMBRE: 'Blanes',
	},
	{
		CPRO: '17',
		CMUN: '029',
		DC: '3',
		NOMBRE: 'Boadella i les Escaules',
	},
	{
		CPRO: '17',
		CMUN: '024',
		DC: '2',
		NOMBRE: 'Bolvir',
	},
	{
		CPRO: '17',
		CMUN: '025',
		DC: '5',
		NOMBRE: 'Bordils',
	},
	{
		CPRO: '17',
		CMUN: '026',
		DC: '8',
		NOMBRE: 'Borrassà',
	},
	{
		CPRO: '17',
		CMUN: '027',
		DC: '4',
		NOMBRE: 'Breda',
	},
	{
		CPRO: '17',
		CMUN: '028',
		DC: '0',
		NOMBRE: 'Brunyola',
	},
	{
		CPRO: '17',
		CMUN: '031',
		DC: '4',
		NOMBRE: 'Cabanelles',
	},
	{
		CPRO: '17',
		CMUN: '030',
		DC: '7',
		NOMBRE: 'Cabanes',
	},
	{
		CPRO: '17',
		CMUN: '032',
		DC: '9',
		NOMBRE: 'Cadaqués',
	},
	{
		CPRO: '17',
		CMUN: '033',
		DC: '5',
		NOMBRE: 'Caldes de Malavella',
	},
	{
		CPRO: '17',
		CMUN: '034',
		DC: '0',
		NOMBRE: 'Calonge',
	},
	{
		CPRO: '17',
		CMUN: '035',
		DC: '3',
		NOMBRE: 'Camós',
	},
	{
		CPRO: '17',
		CMUN: '036',
		DC: '6',
		NOMBRE: 'Campdevànol',
	},
	{
		CPRO: '17',
		CMUN: '037',
		DC: '2',
		NOMBRE: 'Campelles',
	},
	{
		CPRO: '17',
		CMUN: '038',
		DC: '8',
		NOMBRE: 'Campllong',
	},
	{
		CPRO: '17',
		CMUN: '039',
		DC: '1',
		NOMBRE: 'Camprodon',
	},
	{
		CPRO: '17',
		CMUN: '040',
		DC: '5',
		NOMBRE: "Canet d'Adri",
	},
	{
		CPRO: '17',
		CMUN: '041',
		DC: '2',
		NOMBRE: 'Cantallops',
	},
	{
		CPRO: '17',
		CMUN: '042',
		DC: '7',
		NOMBRE: 'Capmany',
	},
	{
		CPRO: '17',
		CMUN: '044',
		DC: '8',
		NOMBRE: 'Cassà de la Selva',
	},
	{
		CPRO: '17',
		CMUN: '046',
		DC: '4',
		NOMBRE: 'Castellfollit de la Roca',
	},
	{
		CPRO: '17',
		CMUN: '047',
		DC: '0',
		NOMBRE: "Castelló d'Empúries",
	},
	{
		CPRO: '17',
		CMUN: '048',
		DC: '6',
		NOMBRE: "Castell-Platja d'Aro",
	},
	{
		CPRO: '17',
		CMUN: '189',
		DC: '9',
		NOMBRE: 'Cellera de Ter, La',
	},
	{
		CPRO: '17',
		CMUN: '049',
		DC: '9',
		NOMBRE: 'Celrà',
	},
	{
		CPRO: '17',
		CMUN: '050',
		DC: '2',
		NOMBRE: 'Cervià de Ter',
	},
	{
		CPRO: '17',
		CMUN: '051',
		DC: '9',
		NOMBRE: 'Cistella',
	},
	{
		CPRO: '17',
		CMUN: '054',
		DC: '5',
		NOMBRE: 'Colera',
	},
	{
		CPRO: '17',
		CMUN: '055',
		DC: '8',
		NOMBRE: 'Colomers',
	},
	{
		CPRO: '17',
		CMUN: '057',
		DC: '7',
		NOMBRE: 'Corçà',
	},
	{
		CPRO: '17',
		CMUN: '056',
		DC: '1',
		NOMBRE: 'Cornellà del Terri',
	},
	{
		CPRO: '17',
		CMUN: '058',
		DC: '3',
		NOMBRE: 'Crespià',
	},
	{
		CPRO: '17',
		CMUN: '901',
		DC: '1',
		NOMBRE: "Cruïlles, Monells i Sant Sadurní de l'Heura",
	},
	{
		CPRO: '17',
		CMUN: '060',
		DC: '0',
		NOMBRE: 'Darnius',
	},
	{
		CPRO: '17',
		CMUN: '061',
		DC: '7',
		NOMBRE: 'Das',
	},
	{
		CPRO: '17',
		CMUN: '062',
		DC: '2',
		NOMBRE: "Escala, L'",
	},
	{
		CPRO: '17',
		CMUN: '063',
		DC: '8',
		NOMBRE: 'Espinelves',
	},
	{
		CPRO: '17',
		CMUN: '064',
		DC: '3',
		NOMBRE: 'Espolla',
	},
	{
		CPRO: '17',
		CMUN: '065',
		DC: '6',
		NOMBRE: 'Esponellà',
	},
	{
		CPRO: '17',
		CMUN: '005',
		DC: '9',
		NOMBRE: "Far d'Empordà, El",
	},
	{
		CPRO: '17',
		CMUN: '066',
		DC: '9',
		NOMBRE: 'Figueres',
	},
	{
		CPRO: '17',
		CMUN: '067',
		DC: '5',
		NOMBRE: 'Flaçà',
	},
	{
		CPRO: '17',
		CMUN: '068',
		DC: '1',
		NOMBRE: 'Foixà',
	},
	{
		CPRO: '17',
		CMUN: '069',
		DC: '4',
		NOMBRE: 'Fontanals de Cerdanya',
	},
	{
		CPRO: '17',
		CMUN: '070',
		DC: '8',
		NOMBRE: 'Fontanilles',
	},
	{
		CPRO: '17',
		CMUN: '071',
		DC: '5',
		NOMBRE: 'Fontcoberta',
	},
	{
		CPRO: '17',
		CMUN: '902',
		DC: '6',
		NOMBRE: 'Forallac',
	},
	{
		CPRO: '17',
		CMUN: '073',
		DC: '6',
		NOMBRE: 'Fornells de la Selva',
	},
	{
		CPRO: '17',
		CMUN: '074',
		DC: '1',
		NOMBRE: 'Fortià',
	},
	{
		CPRO: '17',
		CMUN: '075',
		DC: '4',
		NOMBRE: 'Garrigàs',
	},
	{
		CPRO: '17',
		CMUN: '076',
		DC: '7',
		NOMBRE: 'Garrigoles',
	},
	{
		CPRO: '17',
		CMUN: '077',
		DC: '3',
		NOMBRE: 'Garriguella',
	},
	{
		CPRO: '17',
		CMUN: '078',
		DC: '9',
		NOMBRE: 'Ger',
	},
	{
		CPRO: '17',
		CMUN: '079',
		DC: '2',
		NOMBRE: 'Girona',
	},
	{
		CPRO: '17',
		CMUN: '080',
		DC: '6',
		NOMBRE: 'Gombrèn',
	},
	{
		CPRO: '17',
		CMUN: '081',
		DC: '3',
		NOMBRE: 'Gualta',
	},
	{
		CPRO: '17',
		CMUN: '082',
		DC: '8',
		NOMBRE: 'Guils de Cerdanya',
	},
	{
		CPRO: '17',
		CMUN: '083',
		DC: '4',
		NOMBRE: 'Hostalric',
	},
	{
		CPRO: '17',
		CMUN: '084',
		DC: '9',
		NOMBRE: 'Isòvol',
	},
	{
		CPRO: '17',
		CMUN: '085',
		DC: '2',
		NOMBRE: 'Jafre',
	},
	{
		CPRO: '17',
		CMUN: '086',
		DC: '5',
		NOMBRE: 'Jonquera, La',
	},
	{
		CPRO: '17',
		CMUN: '087',
		DC: '1',
		NOMBRE: 'Juià',
	},
	{
		CPRO: '17',
		CMUN: '088',
		DC: '7',
		NOMBRE: 'Lladó',
	},
	{
		CPRO: '17',
		CMUN: '089',
		DC: '0',
		NOMBRE: 'Llagostera',
	},
	{
		CPRO: '17',
		CMUN: '090',
		DC: '4',
		NOMBRE: 'Llambilles',
	},
	{
		CPRO: '17',
		CMUN: '091',
		DC: '1',
		NOMBRE: 'Llanars',
	},
	{
		CPRO: '17',
		CMUN: '092',
		DC: '6',
		NOMBRE: 'Llançà',
	},
	{
		CPRO: '17',
		CMUN: '093',
		DC: '2',
		NOMBRE: 'Llers',
	},
	{
		CPRO: '17',
		CMUN: '094',
		DC: '7',
		NOMBRE: 'Llívia',
	},
	{
		CPRO: '17',
		CMUN: '095',
		DC: '0',
		NOMBRE: 'Lloret de Mar',
	},
	{
		CPRO: '17',
		CMUN: '096',
		DC: '3',
		NOMBRE: 'Llosses, Les',
	},
	{
		CPRO: '17',
		CMUN: '102',
		DC: '4',
		NOMBRE: 'Maçanet de Cabrenys',
	},
	{
		CPRO: '17',
		CMUN: '103',
		DC: '0',
		NOMBRE: 'Maçanet de la Selva',
	},
	{
		CPRO: '17',
		CMUN: '097',
		DC: '9',
		NOMBRE: 'Madremanya',
	},
	{
		CPRO: '17',
		CMUN: '098',
		DC: '5',
		NOMBRE: 'Maià de Montcal',
	},
	{
		CPRO: '17',
		CMUN: '100',
		DC: '2',
		NOMBRE: 'Masarac',
	},
	{
		CPRO: '17',
		CMUN: '101',
		DC: '9',
		NOMBRE: 'Massanes',
	},
	{
		CPRO: '17',
		CMUN: '099',
		DC: '8',
		NOMBRE: 'Meranges',
	},
	{
		CPRO: '17',
		CMUN: '105',
		DC: '8',
		NOMBRE: 'Mieres',
	},
	{
		CPRO: '17',
		CMUN: '106',
		DC: '1',
		NOMBRE: 'Mollet de Peralada',
	},
	{
		CPRO: '17',
		CMUN: '107',
		DC: '7',
		NOMBRE: 'Molló',
	},
	{
		CPRO: '17',
		CMUN: '109',
		DC: '6',
		NOMBRE: 'Montagut i Oix',
	},
	{
		CPRO: '17',
		CMUN: '110',
		DC: '0',
		NOMBRE: 'Mont-ras',
	},
	{
		CPRO: '17',
		CMUN: '111',
		DC: '7',
		NOMBRE: 'Navata',
	},
	{
		CPRO: '17',
		CMUN: '112',
		DC: '2',
		NOMBRE: 'Ogassa',
	},
	{
		CPRO: '17',
		CMUN: '114',
		DC: '3',
		NOMBRE: 'Olot',
	},
	{
		CPRO: '17',
		CMUN: '115',
		DC: '6',
		NOMBRE: 'Ordis',
	},
	{
		CPRO: '17',
		CMUN: '116',
		DC: '9',
		NOMBRE: 'Osor',
	},
	{
		CPRO: '17',
		CMUN: '117',
		DC: '5',
		NOMBRE: 'Palafrugell',
	},
	{
		CPRO: '17',
		CMUN: '118',
		DC: '1',
		NOMBRE: 'Palamós',
	},
	{
		CPRO: '17',
		CMUN: '119',
		DC: '4',
		NOMBRE: 'Palau de Santa Eulàlia',
	},
	{
		CPRO: '17',
		CMUN: '121',
		DC: '5',
		NOMBRE: 'Palau-sator',
	},
	{
		CPRO: '17',
		CMUN: '120',
		DC: '8',
		NOMBRE: 'Palau-saverdera',
	},
	{
		CPRO: '17',
		CMUN: '123',
		DC: '6',
		NOMBRE: 'Palol de Revardit',
	},
	{
		CPRO: '17',
		CMUN: '124',
		DC: '1',
		NOMBRE: 'Pals',
	},
	{
		CPRO: '17',
		CMUN: '125',
		DC: '4',
		NOMBRE: 'Pardines',
	},
	{
		CPRO: '17',
		CMUN: '126',
		DC: '7',
		NOMBRE: 'Parlavà',
	},
	{
		CPRO: '17',
		CMUN: '128',
		DC: '9',
		NOMBRE: 'Pau',
	},
	{
		CPRO: '17',
		CMUN: '129',
		DC: '2',
		NOMBRE: 'Pedret i Marzà',
	},
	{
		CPRO: '17',
		CMUN: '130',
		DC: '6',
		NOMBRE: 'Pera, La',
	},
	{
		CPRO: '17',
		CMUN: '132',
		DC: '8',
		NOMBRE: 'Peralada',
	},
	{
		CPRO: '17',
		CMUN: '133',
		DC: '4',
		NOMBRE: "Planes d'Hostoles, Les",
	},
	{
		CPRO: '17',
		CMUN: '134',
		DC: '9',
		NOMBRE: 'Planoles',
	},
	{
		CPRO: '17',
		CMUN: '135',
		DC: '2',
		NOMBRE: 'Pont de Molins',
	},
	{
		CPRO: '17',
		CMUN: '136',
		DC: '5',
		NOMBRE: 'Pontós',
	},
	{
		CPRO: '17',
		CMUN: '137',
		DC: '1',
		NOMBRE: 'Porqueres',
	},
	{
		CPRO: '17',
		CMUN: '140',
		DC: '4',
		NOMBRE: 'Port de la Selva, El',
	},
	{
		CPRO: '17',
		CMUN: '138',
		DC: '7',
		NOMBRE: 'Portbou',
	},
	{
		CPRO: '17',
		CMUN: '139',
		DC: '0',
		NOMBRE: 'Preses, Les',
	},
	{
		CPRO: '17',
		CMUN: '141',
		DC: '1',
		NOMBRE: 'Puigcerdà',
	},
	{
		CPRO: '17',
		CMUN: '142',
		DC: '6',
		NOMBRE: 'Quart',
	},
	{
		CPRO: '17',
		CMUN: '043',
		DC: '3',
		NOMBRE: 'Queralbs',
	},
	{
		CPRO: '17',
		CMUN: '143',
		DC: '2',
		NOMBRE: 'Rabós',
	},
	{
		CPRO: '17',
		CMUN: '144',
		DC: '7',
		NOMBRE: 'Regencós',
	},
	{
		CPRO: '17',
		CMUN: '145',
		DC: '0',
		NOMBRE: 'Ribes de Freser',
	},
	{
		CPRO: '17',
		CMUN: '146',
		DC: '3',
		NOMBRE: 'Riells i Viabrea',
	},
	{
		CPRO: '17',
		CMUN: '147',
		DC: '9',
		NOMBRE: 'Ripoll',
	},
	{
		CPRO: '17',
		CMUN: '148',
		DC: '5',
		NOMBRE: 'Riudarenes',
	},
	{
		CPRO: '17',
		CMUN: '149',
		DC: '8',
		NOMBRE: 'Riudaura',
	},
	{
		CPRO: '17',
		CMUN: '150',
		DC: '1',
		NOMBRE: 'Riudellots de la Selva',
	},
	{
		CPRO: '17',
		CMUN: '151',
		DC: '8',
		NOMBRE: 'Riumors',
	},
	{
		CPRO: '17',
		CMUN: '152',
		DC: '3',
		NOMBRE: 'Roses',
	},
	{
		CPRO: '17',
		CMUN: '153',
		DC: '9',
		NOMBRE: 'Rupià',
	},
	{
		CPRO: '17',
		CMUN: '154',
		DC: '4',
		NOMBRE: 'Sales de Llierca',
	},
	{
		CPRO: '17',
		CMUN: '155',
		DC: '7',
		NOMBRE: 'Salt',
	},
	{
		CPRO: '17',
		CMUN: '157',
		DC: '6',
		NOMBRE: 'Sant Andreu Salou',
	},
	{
		CPRO: '17',
		CMUN: '183',
		DC: '3',
		NOMBRE: 'Sant Aniol de Finestres',
	},
	{
		CPRO: '17',
		CMUN: '158',
		DC: '2',
		NOMBRE: 'Sant Climent Sescebes',
	},
	{
		CPRO: '17',
		CMUN: '159',
		DC: '5',
		NOMBRE: 'Sant Feliu de Buixalleu',
	},
	{
		CPRO: '17',
		CMUN: '160',
		DC: '9',
		NOMBRE: 'Sant Feliu de Guíxols',
	},
	{
		CPRO: '17',
		CMUN: '161',
		DC: '6',
		NOMBRE: 'Sant Feliu de Pallerols',
	},
	{
		CPRO: '17',
		CMUN: '162',
		DC: '1',
		NOMBRE: 'Sant Ferriol',
	},
	{
		CPRO: '17',
		CMUN: '163',
		DC: '7',
		NOMBRE: 'Sant Gregori',
	},
	{
		CPRO: '17',
		CMUN: '164',
		DC: '2',
		NOMBRE: 'Sant Hilari Sacalm',
	},
	{
		CPRO: '17',
		CMUN: '165',
		DC: '5',
		NOMBRE: 'Sant Jaume de Llierca',
	},
	{
		CPRO: '17',
		CMUN: '167',
		DC: '4',
		NOMBRE: 'Sant Joan de les Abadesses',
	},
	{
		CPRO: '17',
		CMUN: '168',
		DC: '0',
		NOMBRE: 'Sant Joan de Mollet',
	},
	{
		CPRO: '17',
		CMUN: '185',
		DC: '1',
		NOMBRE: 'Sant Joan les Fonts',
	},
	{
		CPRO: '17',
		CMUN: '166',
		DC: '8',
		NOMBRE: 'Sant Jordi Desvalls',
	},
	{
		CPRO: '17',
		CMUN: '169',
		DC: '3',
		NOMBRE: 'Sant Julià de Ramis',
	},
	{
		CPRO: '17',
		CMUN: '903',
		DC: '2',
		NOMBRE: 'Sant Julià del Llor i Bonmatí',
	},
	{
		CPRO: '17',
		CMUN: '171',
		DC: '4',
		NOMBRE: 'Sant Llorenç de la Muga',
	},
	{
		CPRO: '17',
		CMUN: '172',
		DC: '9',
		NOMBRE: 'Sant Martí de Llémena',
	},
	{
		CPRO: '17',
		CMUN: '173',
		DC: '5',
		NOMBRE: 'Sant Martí Vell',
	},
	{
		CPRO: '17',
		CMUN: '174',
		DC: '0',
		NOMBRE: 'Sant Miquel de Campmajor',
	},
	{
		CPRO: '17',
		CMUN: '175',
		DC: '3',
		NOMBRE: 'Sant Miquel de Fluvià',
	},
	{
		CPRO: '17',
		CMUN: '176',
		DC: '6',
		NOMBRE: 'Sant Mori',
	},
	{
		CPRO: '17',
		CMUN: '177',
		DC: '2',
		NOMBRE: 'Sant Pau de Segúries',
	},
	{
		CPRO: '17',
		CMUN: '178',
		DC: '8',
		NOMBRE: 'Sant Pere Pescador',
	},
	{
		CPRO: '17',
		CMUN: '180',
		DC: '5',
		NOMBRE: 'Santa Coloma de Farners',
	},
	{
		CPRO: '17',
		CMUN: '181',
		DC: '2',
		NOMBRE: "Santa Cristina d'Aro",
	},
	{
		CPRO: '17',
		CMUN: '182',
		DC: '7',
		NOMBRE: "Santa Llogaia d'Àlguema",
	},
	{
		CPRO: '17',
		CMUN: '184',
		DC: '8',
		NOMBRE: 'Santa Pau',
	},
	{
		CPRO: '17',
		CMUN: '186',
		DC: '4',
		NOMBRE: 'Sarrià de Ter',
	},
	{
		CPRO: '17',
		CMUN: '187',
		DC: '0',
		NOMBRE: 'Saus, Camallera i Llampaies',
	},
	{
		CPRO: '17',
		CMUN: '188',
		DC: '6',
		NOMBRE: 'Selva de Mar, La',
	},
	{
		CPRO: '17',
		CMUN: '190',
		DC: '3',
		NOMBRE: 'Serinyà',
	},
	{
		CPRO: '17',
		CMUN: '191',
		DC: '0',
		NOMBRE: 'Serra de Daró',
	},
	{
		CPRO: '17',
		CMUN: '192',
		DC: '5',
		NOMBRE: 'Setcases',
	},
	{
		CPRO: '17',
		CMUN: '193',
		DC: '1',
		NOMBRE: 'Sils',
	},
	{
		CPRO: '17',
		CMUN: '052',
		DC: '4',
		NOMBRE: 'Siurana',
	},
	{
		CPRO: '17',
		CMUN: '194',
		DC: '6',
		NOMBRE: 'Susqueda',
	},
	{
		CPRO: '17',
		CMUN: '195',
		DC: '9',
		NOMBRE: "Tallada d'Empordà, La",
	},
	{
		CPRO: '17',
		CMUN: '196',
		DC: '2',
		NOMBRE: 'Terrades',
	},
	{
		CPRO: '17',
		CMUN: '197',
		DC: '8',
		NOMBRE: 'Torrent',
	},
	{
		CPRO: '17',
		CMUN: '198',
		DC: '4',
		NOMBRE: 'Torroella de Fluvià',
	},
	{
		CPRO: '17',
		CMUN: '199',
		DC: '7',
		NOMBRE: 'Torroella de Montgrí',
	},
	{
		CPRO: '17',
		CMUN: '200',
		DC: '1',
		NOMBRE: 'Tortellà',
	},
	{
		CPRO: '17',
		CMUN: '201',
		DC: '8',
		NOMBRE: 'Toses',
	},
	{
		CPRO: '17',
		CMUN: '202',
		DC: '3',
		NOMBRE: 'Tossa de Mar',
	},
	{
		CPRO: '17',
		CMUN: '204',
		DC: '4',
		NOMBRE: 'Ullà',
	},
	{
		CPRO: '17',
		CMUN: '205',
		DC: '7',
		NOMBRE: 'Ullastret',
	},
	{
		CPRO: '17',
		CMUN: '203',
		DC: '9',
		NOMBRE: 'Ultramort',
	},
	{
		CPRO: '17',
		CMUN: '206',
		DC: '0',
		NOMBRE: 'Urús',
	},
	{
		CPRO: '17',
		CMUN: '014',
		DC: '4',
		NOMBRE: 'Vajol, La',
	},
	{
		CPRO: '17',
		CMUN: '208',
		DC: '2',
		NOMBRE: 'Vall de Bianya, La',
	},
	{
		CPRO: '17',
		CMUN: '207',
		DC: '6',
		NOMBRE: "Vall d'en Bas, La",
	},
	{
		CPRO: '17',
		CMUN: '170',
		DC: '7',
		NOMBRE: 'Vallfogona de Ripollès',
	},
	{
		CPRO: '17',
		CMUN: '209',
		DC: '5',
		NOMBRE: 'Vall-llobrega',
	},
	{
		CPRO: '17',
		CMUN: '210',
		DC: '9',
		NOMBRE: 'Ventalló',
	},
	{
		CPRO: '17',
		CMUN: '211',
		DC: '6',
		NOMBRE: 'Verges',
	},
	{
		CPRO: '17',
		CMUN: '212',
		DC: '1',
		NOMBRE: 'Vidrà',
	},
	{
		CPRO: '17',
		CMUN: '213',
		DC: '7',
		NOMBRE: 'Vidreres',
	},
	{
		CPRO: '17',
		CMUN: '214',
		DC: '2',
		NOMBRE: 'Vilabertran',
	},
	{
		CPRO: '17',
		CMUN: '215',
		DC: '5',
		NOMBRE: 'Vilablareix',
	},
	{
		CPRO: '17',
		CMUN: '217',
		DC: '4',
		NOMBRE: 'Viladamat',
	},
	{
		CPRO: '17',
		CMUN: '216',
		DC: '8',
		NOMBRE: 'Viladasens',
	},
	{
		CPRO: '17',
		CMUN: '218',
		DC: '0',
		NOMBRE: 'Vilademuls',
	},
	{
		CPRO: '17',
		CMUN: '220',
		DC: '7',
		NOMBRE: 'Viladrau',
	},
	{
		CPRO: '17',
		CMUN: '221',
		DC: '4',
		NOMBRE: 'Vilafant',
	},
	{
		CPRO: '17',
		CMUN: '223',
		DC: '5',
		NOMBRE: 'Vilajuïga',
	},
	{
		CPRO: '17',
		CMUN: '224',
		DC: '0',
		NOMBRE: 'Vilallonga de Ter',
	},
	{
		CPRO: '17',
		CMUN: '225',
		DC: '3',
		NOMBRE: 'Vilamacolum',
	},
	{
		CPRO: '17',
		CMUN: '226',
		DC: '6',
		NOMBRE: 'Vilamalla',
	},
	{
		CPRO: '17',
		CMUN: '227',
		DC: '2',
		NOMBRE: 'Vilamaniscle',
	},
	{
		CPRO: '17',
		CMUN: '228',
		DC: '8',
		NOMBRE: 'Vilanant',
	},
	{
		CPRO: '17',
		CMUN: '230',
		DC: '5',
		NOMBRE: 'Vila-sacra',
	},
	{
		CPRO: '17',
		CMUN: '222',
		DC: '9',
		NOMBRE: 'Vilaür',
	},
	{
		CPRO: '17',
		CMUN: '233',
		DC: '3',
		NOMBRE: "Vilobí d'Onyar",
	},
	{
		CPRO: '17',
		CMUN: '232',
		DC: '7',
		NOMBRE: 'Vilopriu',
	},
	{
		CPRO: '18',
		CMUN: '001',
		DC: '6',
		NOMBRE: 'Agrón',
	},
	{
		CPRO: '18',
		CMUN: '002',
		DC: '1',
		NOMBRE: 'Alamedilla',
	},
	{
		CPRO: '18',
		CMUN: '003',
		DC: '7',
		NOMBRE: 'Albolote',
	},
	{
		CPRO: '18',
		CMUN: '004',
		DC: '2',
		NOMBRE: 'Albondón',
	},
	{
		CPRO: '18',
		CMUN: '005',
		DC: '5',
		NOMBRE: 'Albuñán',
	},
	{
		CPRO: '18',
		CMUN: '006',
		DC: '8',
		NOMBRE: 'Albuñol',
	},
	{
		CPRO: '18',
		CMUN: '007',
		DC: '4',
		NOMBRE: 'Albuñuelas',
	},
	{
		CPRO: '18',
		CMUN: '010',
		DC: '7',
		NOMBRE: 'Aldeire',
	},
	{
		CPRO: '18',
		CMUN: '011',
		DC: '4',
		NOMBRE: 'Alfacar',
	},
	{
		CPRO: '18',
		CMUN: '012',
		DC: '9',
		NOMBRE: 'Algarinejo',
	},
	{
		CPRO: '18',
		CMUN: '013',
		DC: '5',
		NOMBRE: 'Alhama de Granada',
	},
	{
		CPRO: '18',
		CMUN: '014',
		DC: '0',
		NOMBRE: 'Alhendín',
	},
	{
		CPRO: '18',
		CMUN: '015',
		DC: '3',
		NOMBRE: 'Alicún de Ortega',
	},
	{
		CPRO: '18',
		CMUN: '016',
		DC: '6',
		NOMBRE: 'Almegíjar',
	},
	{
		CPRO: '18',
		CMUN: '017',
		DC: '2',
		NOMBRE: 'Almuñécar',
	},
	{
		CPRO: '18',
		CMUN: '904',
		DC: '3',
		NOMBRE: 'Alpujarra de la Sierra',
	},
	{
		CPRO: '18',
		CMUN: '018',
		DC: '8',
		NOMBRE: 'Alquife',
	},
	{
		CPRO: '18',
		CMUN: '020',
		DC: '5',
		NOMBRE: 'Arenas del Rey',
	},
	{
		CPRO: '18',
		CMUN: '021',
		DC: '2',
		NOMBRE: 'Armilla',
	},
	{
		CPRO: '18',
		CMUN: '022',
		DC: '7',
		NOMBRE: 'Atarfe',
	},
	{
		CPRO: '18',
		CMUN: '023',
		DC: '3',
		NOMBRE: 'Baza',
	},
	{
		CPRO: '18',
		CMUN: '024',
		DC: '8',
		NOMBRE: 'Beas de Granada',
	},
	{
		CPRO: '18',
		CMUN: '025',
		DC: '1',
		NOMBRE: 'Beas de Guadix',
	},
	{
		CPRO: '18',
		CMUN: '027',
		DC: '0',
		NOMBRE: 'Benalúa',
	},
	{
		CPRO: '18',
		CMUN: '028',
		DC: '6',
		NOMBRE: 'Benalúa de las Villas',
	},
	{
		CPRO: '18',
		CMUN: '029',
		DC: '9',
		NOMBRE: 'Benamaurel',
	},
	{
		CPRO: '18',
		CMUN: '030',
		DC: '3',
		NOMBRE: 'Bérchules',
	},
	{
		CPRO: '18',
		CMUN: '032',
		DC: '5',
		NOMBRE: 'Bubión',
	},
	{
		CPRO: '18',
		CMUN: '033',
		DC: '1',
		NOMBRE: 'Busquístar',
	},
	{
		CPRO: '18',
		CMUN: '034',
		DC: '6',
		NOMBRE: 'Cacín',
	},
	{
		CPRO: '18',
		CMUN: '035',
		DC: '9',
		NOMBRE: 'Cádiar',
	},
	{
		CPRO: '18',
		CMUN: '036',
		DC: '2',
		NOMBRE: 'Cájar',
	},
	{
		CPRO: '18',
		CMUN: '114',
		DC: '9',
		NOMBRE: 'Calahorra, La',
	},
	{
		CPRO: '18',
		CMUN: '037',
		DC: '8',
		NOMBRE: 'Calicasas',
	},
	{
		CPRO: '18',
		CMUN: '038',
		DC: '4',
		NOMBRE: 'Campotéjar',
	},
	{
		CPRO: '18',
		CMUN: '039',
		DC: '7',
		NOMBRE: 'Caniles',
	},
	{
		CPRO: '18',
		CMUN: '040',
		DC: '1',
		NOMBRE: 'Cáñar',
	},
	{
		CPRO: '18',
		CMUN: '042',
		DC: '3',
		NOMBRE: 'Capileira',
	},
	{
		CPRO: '18',
		CMUN: '043',
		DC: '9',
		NOMBRE: 'Carataunas',
	},
	{
		CPRO: '18',
		CMUN: '044',
		DC: '4',
		NOMBRE: 'Cástaras',
	},
	{
		CPRO: '18',
		CMUN: '045',
		DC: '7',
		NOMBRE: 'Castilléjar',
	},
	{
		CPRO: '18',
		CMUN: '046',
		DC: '0',
		NOMBRE: 'Castril',
	},
	{
		CPRO: '18',
		CMUN: '047',
		DC: '6',
		NOMBRE: 'Cenes de la Vega',
	},
	{
		CPRO: '18',
		CMUN: '059',
		DC: '2',
		NOMBRE: 'Chauchina',
	},
	{
		CPRO: '18',
		CMUN: '061',
		DC: '3',
		NOMBRE: 'Chimeneas',
	},
	{
		CPRO: '18',
		CMUN: '062',
		DC: '8',
		NOMBRE: 'Churriana de la Vega',
	},
	{
		CPRO: '18',
		CMUN: '048',
		DC: '2',
		NOMBRE: 'Cijuela',
	},
	{
		CPRO: '18',
		CMUN: '049',
		DC: '5',
		NOMBRE: 'Cogollos de Guadix',
	},
	{
		CPRO: '18',
		CMUN: '050',
		DC: '8',
		NOMBRE: 'Cogollos de la Vega',
	},
	{
		CPRO: '18',
		CMUN: '051',
		DC: '5',
		NOMBRE: 'Colomera',
	},
	{
		CPRO: '18',
		CMUN: '053',
		DC: '6',
		NOMBRE: 'Cortes de Baza',
	},
	{
		CPRO: '18',
		CMUN: '054',
		DC: '1',
		NOMBRE: 'Cortes y Graena',
	},
	{
		CPRO: '18',
		CMUN: '912',
		DC: '0',
		NOMBRE: 'Cuevas del Campo',
	},
	{
		CPRO: '18',
		CMUN: '056',
		DC: '7',
		NOMBRE: 'Cúllar',
	},
	{
		CPRO: '18',
		CMUN: '057',
		DC: '3',
		NOMBRE: 'Cúllar Vega',
	},
	{
		CPRO: '18',
		CMUN: '063',
		DC: '4',
		NOMBRE: 'Darro',
	},
	{
		CPRO: '18',
		CMUN: '064',
		DC: '9',
		NOMBRE: 'Dehesas de Guadix',
	},
	{
		CPRO: '18',
		CMUN: '066',
		DC: '5',
		NOMBRE: 'Deifontes',
	},
	{
		CPRO: '18',
		CMUN: '067',
		DC: '1',
		NOMBRE: 'Diezma',
	},
	{
		CPRO: '18',
		CMUN: '068',
		DC: '7',
		NOMBRE: 'Dílar',
	},
	{
		CPRO: '18',
		CMUN: '069',
		DC: '0',
		NOMBRE: 'Dólar',
	},
	{
		CPRO: '18',
		CMUN: '070',
		DC: '4',
		NOMBRE: 'Dúdar',
	},
	{
		CPRO: '18',
		CMUN: '071',
		DC: '1',
		NOMBRE: 'Dúrcal',
	},
	{
		CPRO: '18',
		CMUN: '072',
		DC: '6',
		NOMBRE: 'Escúzar',
	},
	{
		CPRO: '18',
		CMUN: '074',
		DC: '7',
		NOMBRE: 'Ferreira',
	},
	{
		CPRO: '18',
		CMUN: '076',
		DC: '3',
		NOMBRE: 'Fonelas',
	},
	{
		CPRO: '18',
		CMUN: '078',
		DC: '5',
		NOMBRE: 'Freila',
	},
	{
		CPRO: '18',
		CMUN: '079',
		DC: '8',
		NOMBRE: 'Fuente Vaqueros',
	},
	{
		CPRO: '18',
		CMUN: '905',
		DC: '6',
		NOMBRE: 'Gabias, Las',
	},
	{
		CPRO: '18',
		CMUN: '082',
		DC: '4',
		NOMBRE: 'Galera',
	},
	{
		CPRO: '18',
		CMUN: '083',
		DC: '0',
		NOMBRE: 'Gobernador',
	},
	{
		CPRO: '18',
		CMUN: '084',
		DC: '5',
		NOMBRE: 'Gójar',
	},
	{
		CPRO: '18',
		CMUN: '085',
		DC: '8',
		NOMBRE: 'Gor',
	},
	{
		CPRO: '18',
		CMUN: '086',
		DC: '1',
		NOMBRE: 'Gorafe',
	},
	{
		CPRO: '18',
		CMUN: '087',
		DC: '7',
		NOMBRE: 'Granada',
	},
	{
		CPRO: '18',
		CMUN: '088',
		DC: '3',
		NOMBRE: 'Guadahortuna',
	},
	{
		CPRO: '18',
		CMUN: '089',
		DC: '6',
		NOMBRE: 'Guadix',
	},
	{
		CPRO: '18',
		CMUN: '906',
		DC: '9',
		NOMBRE: 'Guajares, Los',
	},
	{
		CPRO: '18',
		CMUN: '093',
		DC: '8',
		NOMBRE: 'Gualchos',
	},
	{
		CPRO: '18',
		CMUN: '094',
		DC: '3',
		NOMBRE: 'Güejar Sierra',
	},
	{
		CPRO: '18',
		CMUN: '095',
		DC: '6',
		NOMBRE: 'Güevéjar',
	},
	{
		CPRO: '18',
		CMUN: '096',
		DC: '9',
		NOMBRE: 'Huélago',
	},
	{
		CPRO: '18',
		CMUN: '097',
		DC: '5',
		NOMBRE: 'Huéneja',
	},
	{
		CPRO: '18',
		CMUN: '098',
		DC: '1',
		NOMBRE: 'Huéscar',
	},
	{
		CPRO: '18',
		CMUN: '099',
		DC: '4',
		NOMBRE: 'Huétor de Santillán',
	},
	{
		CPRO: '18',
		CMUN: '100',
		DC: '8',
		NOMBRE: 'Huétor Tájar',
	},
	{
		CPRO: '18',
		CMUN: '101',
		DC: '5',
		NOMBRE: 'Huétor Vega',
	},
	{
		CPRO: '18',
		CMUN: '102',
		DC: '0',
		NOMBRE: 'Illora',
	},
	{
		CPRO: '18',
		CMUN: '103',
		DC: '6',
		NOMBRE: 'Itrabo',
	},
	{
		CPRO: '18',
		CMUN: '105',
		DC: '4',
		NOMBRE: 'Iznalloz',
	},
	{
		CPRO: '18',
		CMUN: '107',
		DC: '3',
		NOMBRE: 'Jayena',
	},
	{
		CPRO: '18',
		CMUN: '108',
		DC: '9',
		NOMBRE: 'Jerez del Marquesado',
	},
	{
		CPRO: '18',
		CMUN: '109',
		DC: '2',
		NOMBRE: 'Jete',
	},
	{
		CPRO: '18',
		CMUN: '111',
		DC: '3',
		NOMBRE: 'Jun',
	},
	{
		CPRO: '18',
		CMUN: '112',
		DC: '8',
		NOMBRE: 'Juviles',
	},
	{
		CPRO: '18',
		CMUN: '115',
		DC: '2',
		NOMBRE: 'Láchar',
	},
	{
		CPRO: '18',
		CMUN: '116',
		DC: '5',
		NOMBRE: 'Lanjarón',
	},
	{
		CPRO: '18',
		CMUN: '117',
		DC: '1',
		NOMBRE: 'Lanteira',
	},
	{
		CPRO: '18',
		CMUN: '119',
		DC: '0',
		NOMBRE: 'Lecrín',
	},
	{
		CPRO: '18',
		CMUN: '120',
		DC: '4',
		NOMBRE: 'Lentegí',
	},
	{
		CPRO: '18',
		CMUN: '121',
		DC: '1',
		NOMBRE: 'Lobras',
	},
	{
		CPRO: '18',
		CMUN: '122',
		DC: '6',
		NOMBRE: 'Loja',
	},
	{
		CPRO: '18',
		CMUN: '123',
		DC: '2',
		NOMBRE: 'Lugros',
	},
	{
		CPRO: '18',
		CMUN: '124',
		DC: '7',
		NOMBRE: 'Lújar',
	},
	{
		CPRO: '18',
		CMUN: '126',
		DC: '3',
		NOMBRE: 'Malahá, La',
	},
	{
		CPRO: '18',
		CMUN: '127',
		DC: '9',
		NOMBRE: 'Maracena',
	},
	{
		CPRO: '18',
		CMUN: '128',
		DC: '5',
		NOMBRE: 'Marchal',
	},
	{
		CPRO: '18',
		CMUN: '132',
		DC: '4',
		NOMBRE: 'Moclín',
	},
	{
		CPRO: '18',
		CMUN: '133',
		DC: '0',
		NOMBRE: 'Molvízar',
	},
	{
		CPRO: '18',
		CMUN: '134',
		DC: '5',
		NOMBRE: 'Monachil',
	},
	{
		CPRO: '18',
		CMUN: '135',
		DC: '8',
		NOMBRE: 'Montefrío',
	},
	{
		CPRO: '18',
		CMUN: '136',
		DC: '1',
		NOMBRE: 'Montejícar',
	},
	{
		CPRO: '18',
		CMUN: '137',
		DC: '7',
		NOMBRE: 'Montillana',
	},
	{
		CPRO: '18',
		CMUN: '138',
		DC: '3',
		NOMBRE: 'Moraleda de Zafayona',
	},
	{
		CPRO: '18',
		CMUN: '909',
		DC: '4',
		NOMBRE: 'Morelábor',
	},
	{
		CPRO: '18',
		CMUN: '140',
		DC: '0',
		NOMBRE: 'Motril',
	},
	{
		CPRO: '18',
		CMUN: '141',
		DC: '7',
		NOMBRE: 'Murtas',
	},
	{
		CPRO: '18',
		CMUN: '903',
		DC: '8',
		NOMBRE: 'Nevada',
	},
	{
		CPRO: '18',
		CMUN: '143',
		DC: '8',
		NOMBRE: 'Nigüelas',
	},
	{
		CPRO: '18',
		CMUN: '144',
		DC: '3',
		NOMBRE: 'Nívar',
	},
	{
		CPRO: '18',
		CMUN: '145',
		DC: '6',
		NOMBRE: 'Ogíjares',
	},
	{
		CPRO: '18',
		CMUN: '146',
		DC: '9',
		NOMBRE: 'Orce',
	},
	{
		CPRO: '18',
		CMUN: '147',
		DC: '5',
		NOMBRE: 'Órgiva',
	},
	{
		CPRO: '18',
		CMUN: '148',
		DC: '1',
		NOMBRE: 'Otívar',
	},
	{
		CPRO: '18',
		CMUN: '149',
		DC: '4',
		NOMBRE: 'Otura',
	},
	{
		CPRO: '18',
		CMUN: '150',
		DC: '7',
		NOMBRE: 'Padul',
	},
	{
		CPRO: '18',
		CMUN: '151',
		DC: '4',
		NOMBRE: 'Pampaneira',
	},
	{
		CPRO: '18',
		CMUN: '152',
		DC: '9',
		NOMBRE: 'Pedro Martínez',
	},
	{
		CPRO: '18',
		CMUN: '153',
		DC: '5',
		NOMBRE: 'Peligros',
	},
	{
		CPRO: '18',
		CMUN: '154',
		DC: '0',
		NOMBRE: 'Peza, La',
	},
	{
		CPRO: '18',
		CMUN: '910',
		DC: '8',
		NOMBRE: 'Pinar, El',
	},
	{
		CPRO: '18',
		CMUN: '157',
		DC: '2',
		NOMBRE: 'Pinos Genil',
	},
	{
		CPRO: '18',
		CMUN: '158',
		DC: '8',
		NOMBRE: 'Pinos Puente',
	},
	{
		CPRO: '18',
		CMUN: '159',
		DC: '1',
		NOMBRE: 'Píñar',
	},
	{
		CPRO: '18',
		CMUN: '161',
		DC: '2',
		NOMBRE: 'Polícar',
	},
	{
		CPRO: '18',
		CMUN: '162',
		DC: '7',
		NOMBRE: 'Polopos',
	},
	{
		CPRO: '18',
		CMUN: '163',
		DC: '3',
		NOMBRE: 'Pórtugos',
	},
	{
		CPRO: '18',
		CMUN: '164',
		DC: '8',
		NOMBRE: 'Puebla de Don Fadrique',
	},
	{
		CPRO: '18',
		CMUN: '165',
		DC: '1',
		NOMBRE: 'Pulianas',
	},
	{
		CPRO: '18',
		CMUN: '167',
		DC: '0',
		NOMBRE: 'Purullena',
	},
	{
		CPRO: '18',
		CMUN: '168',
		DC: '6',
		NOMBRE: 'Quéntar',
	},
	{
		CPRO: '18',
		CMUN: '170',
		DC: '3',
		NOMBRE: 'Rubite',
	},
	{
		CPRO: '18',
		CMUN: '171',
		DC: '0',
		NOMBRE: 'Salar',
	},
	{
		CPRO: '18',
		CMUN: '173',
		DC: '1',
		NOMBRE: 'Salobreña',
	},
	{
		CPRO: '18',
		CMUN: '174',
		DC: '6',
		NOMBRE: 'Santa Cruz del Comercio',
	},
	{
		CPRO: '18',
		CMUN: '175',
		DC: '9',
		NOMBRE: 'Santa Fe',
	},
	{
		CPRO: '18',
		CMUN: '176',
		DC: '2',
		NOMBRE: 'Soportújar',
	},
	{
		CPRO: '18',
		CMUN: '177',
		DC: '8',
		NOMBRE: 'Sorvilán',
	},
	{
		CPRO: '18',
		CMUN: '901',
		DC: '7',
		NOMBRE: 'Taha, La',
	},
	{
		CPRO: '18',
		CMUN: '178',
		DC: '4',
		NOMBRE: 'Torre-Cardela',
	},
	{
		CPRO: '18',
		CMUN: '179',
		DC: '7',
		NOMBRE: 'Torvizcón',
	},
	{
		CPRO: '18',
		CMUN: '180',
		DC: '1',
		NOMBRE: 'Trevélez',
	},
	{
		CPRO: '18',
		CMUN: '181',
		DC: '8',
		NOMBRE: 'Turón',
	},
	{
		CPRO: '18',
		CMUN: '182',
		DC: '3',
		NOMBRE: 'Ugíjar',
	},
	{
		CPRO: '18',
		CMUN: '902',
		DC: '2',
		NOMBRE: 'Valle, El',
	},
	{
		CPRO: '18',
		CMUN: '907',
		DC: '5',
		NOMBRE: 'Valle del Zalabí',
	},
	{
		CPRO: '18',
		CMUN: '183',
		DC: '9',
		NOMBRE: 'Válor',
	},
	{
		CPRO: '18',
		CMUN: '911',
		DC: '5',
		NOMBRE: 'Vegas del Genil',
	},
	{
		CPRO: '18',
		CMUN: '184',
		DC: '4',
		NOMBRE: 'Vélez de Benaudalla',
	},
	{
		CPRO: '18',
		CMUN: '185',
		DC: '7',
		NOMBRE: 'Ventas de Huelma',
	},
	{
		CPRO: '18',
		CMUN: '908',
		DC: '1',
		NOMBRE: 'Villamena',
	},
	{
		CPRO: '18',
		CMUN: '187',
		DC: '6',
		NOMBRE: 'Villanueva de las Torres',
	},
	{
		CPRO: '18',
		CMUN: '188',
		DC: '2',
		NOMBRE: 'Villanueva Mesía',
	},
	{
		CPRO: '18',
		CMUN: '189',
		DC: '5',
		NOMBRE: 'Víznar',
	},
	{
		CPRO: '18',
		CMUN: '192',
		DC: '1',
		NOMBRE: 'Zafarraya',
	},
	{
		CPRO: '18',
		CMUN: '913',
		DC: '6',
		NOMBRE: 'Zagra',
	},
	{
		CPRO: '18',
		CMUN: '193',
		DC: '7',
		NOMBRE: 'Zubia, La',
	},
	{
		CPRO: '18',
		CMUN: '194',
		DC: '2',
		NOMBRE: 'Zújar',
	},
	{
		CPRO: '19',
		CMUN: '001',
		DC: '9',
		NOMBRE: 'Abánades',
	},
	{
		CPRO: '19',
		CMUN: '002',
		DC: '4',
		NOMBRE: 'Ablanque',
	},
	{
		CPRO: '19',
		CMUN: '003',
		DC: '0',
		NOMBRE: 'Adobes',
	},
	{
		CPRO: '19',
		CMUN: '004',
		DC: '5',
		NOMBRE: 'Alaminos',
	},
	{
		CPRO: '19',
		CMUN: '005',
		DC: '8',
		NOMBRE: 'Alarilla',
	},
	{
		CPRO: '19',
		CMUN: '006',
		DC: '1',
		NOMBRE: 'Albalate de Zorita',
	},
	{
		CPRO: '19',
		CMUN: '007',
		DC: '7',
		NOMBRE: 'Albares',
	},
	{
		CPRO: '19',
		CMUN: '008',
		DC: '3',
		NOMBRE: 'Albendiego',
	},
	{
		CPRO: '19',
		CMUN: '009',
		DC: '6',
		NOMBRE: 'Alcocer',
	},
	{
		CPRO: '19',
		CMUN: '010',
		DC: '0',
		NOMBRE: 'Alcolea de las Peñas',
	},
	{
		CPRO: '19',
		CMUN: '011',
		DC: '7',
		NOMBRE: 'Alcolea del Pinar',
	},
	{
		CPRO: '19',
		CMUN: '013',
		DC: '8',
		NOMBRE: 'Alcoroches',
	},
	{
		CPRO: '19',
		CMUN: '015',
		DC: '6',
		NOMBRE: 'Aldeanueva de Guadalajara',
	},
	{
		CPRO: '19',
		CMUN: '016',
		DC: '9',
		NOMBRE: 'Algar de Mesa',
	},
	{
		CPRO: '19',
		CMUN: '017',
		DC: '5',
		NOMBRE: 'Algora',
	},
	{
		CPRO: '19',
		CMUN: '018',
		DC: '1',
		NOMBRE: 'Alhóndiga',
	},
	{
		CPRO: '19',
		CMUN: '019',
		DC: '4',
		NOMBRE: 'Alique',
	},
	{
		CPRO: '19',
		CMUN: '020',
		DC: '8',
		NOMBRE: 'Almadrones',
	},
	{
		CPRO: '19',
		CMUN: '021',
		DC: '5',
		NOMBRE: 'Almoguera',
	},
	{
		CPRO: '19',
		CMUN: '022',
		DC: '0',
		NOMBRE: 'Almonacid de Zorita',
	},
	{
		CPRO: '19',
		CMUN: '023',
		DC: '6',
		NOMBRE: 'Alocén',
	},
	{
		CPRO: '19',
		CMUN: '024',
		DC: '1',
		NOMBRE: 'Alovera',
	},
	{
		CPRO: '19',
		CMUN: '027',
		DC: '3',
		NOMBRE: 'Alustante',
	},
	{
		CPRO: '19',
		CMUN: '031',
		DC: '3',
		NOMBRE: 'Angón',
	},
	{
		CPRO: '19',
		CMUN: '032',
		DC: '8',
		NOMBRE: 'Anguita',
	},
	{
		CPRO: '19',
		CMUN: '033',
		DC: '4',
		NOMBRE: 'Anquela del Ducado',
	},
	{
		CPRO: '19',
		CMUN: '034',
		DC: '9',
		NOMBRE: 'Anquela del Pedregal',
	},
	{
		CPRO: '19',
		CMUN: '036',
		DC: '5',
		NOMBRE: 'Aranzueque',
	},
	{
		CPRO: '19',
		CMUN: '037',
		DC: '1',
		NOMBRE: 'Arbancón',
	},
	{
		CPRO: '19',
		CMUN: '038',
		DC: '7',
		NOMBRE: 'Arbeteta',
	},
	{
		CPRO: '19',
		CMUN: '039',
		DC: '0',
		NOMBRE: 'Argecilla',
	},
	{
		CPRO: '19',
		CMUN: '040',
		DC: '4',
		NOMBRE: 'Armallones',
	},
	{
		CPRO: '19',
		CMUN: '041',
		DC: '1',
		NOMBRE: 'Armuña de Tajuña',
	},
	{
		CPRO: '19',
		CMUN: '042',
		DC: '6',
		NOMBRE: 'Arroyo de las Fraguas',
	},
	{
		CPRO: '19',
		CMUN: '043',
		DC: '2',
		NOMBRE: 'Atanzón',
	},
	{
		CPRO: '19',
		CMUN: '044',
		DC: '7',
		NOMBRE: 'Atienza',
	},
	{
		CPRO: '19',
		CMUN: '045',
		DC: '0',
		NOMBRE: 'Auñón',
	},
	{
		CPRO: '19',
		CMUN: '046',
		DC: '3',
		NOMBRE: 'Azuqueca de Henares',
	},
	{
		CPRO: '19',
		CMUN: '047',
		DC: '9',
		NOMBRE: 'Baides',
	},
	{
		CPRO: '19',
		CMUN: '048',
		DC: '5',
		NOMBRE: 'Baños de Tajo',
	},
	{
		CPRO: '19',
		CMUN: '049',
		DC: '8',
		NOMBRE: 'Bañuelos',
	},
	{
		CPRO: '19',
		CMUN: '050',
		DC: '1',
		NOMBRE: 'Barriopedro',
	},
	{
		CPRO: '19',
		CMUN: '051',
		DC: '8',
		NOMBRE: 'Berninches',
	},
	{
		CPRO: '19',
		CMUN: '052',
		DC: '3',
		NOMBRE: 'Bodera, La',
	},
	{
		CPRO: '19',
		CMUN: '053',
		DC: '9',
		NOMBRE: 'Brihuega',
	},
	{
		CPRO: '19',
		CMUN: '054',
		DC: '4',
		NOMBRE: 'Budia',
	},
	{
		CPRO: '19',
		CMUN: '055',
		DC: '7',
		NOMBRE: 'Bujalaro',
	},
	{
		CPRO: '19',
		CMUN: '057',
		DC: '6',
		NOMBRE: 'Bustares',
	},
	{
		CPRO: '19',
		CMUN: '058',
		DC: '2',
		NOMBRE: 'Cabanillas del Campo',
	},
	{
		CPRO: '19',
		CMUN: '059',
		DC: '5',
		NOMBRE: 'Campillo de Dueñas',
	},
	{
		CPRO: '19',
		CMUN: '060',
		DC: '9',
		NOMBRE: 'Campillo de Ranas',
	},
	{
		CPRO: '19',
		CMUN: '061',
		DC: '6',
		NOMBRE: 'Campisábalos',
	},
	{
		CPRO: '19',
		CMUN: '064',
		DC: '2',
		NOMBRE: 'Canredondo',
	},
	{
		CPRO: '19',
		CMUN: '065',
		DC: '5',
		NOMBRE: 'Cantalojas',
	},
	{
		CPRO: '19',
		CMUN: '066',
		DC: '8',
		NOMBRE: 'Cañizar',
	},
	{
		CPRO: '19',
		CMUN: '067',
		DC: '4',
		NOMBRE: 'Cardoso de la Sierra, El',
	},
	{
		CPRO: '19',
		CMUN: '070',
		DC: '7',
		NOMBRE: 'Casa de Uceda',
	},
	{
		CPRO: '19',
		CMUN: '071',
		DC: '4',
		NOMBRE: 'Casar, El',
	},
	{
		CPRO: '19',
		CMUN: '073',
		DC: '5',
		NOMBRE: 'Casas de San Galindo',
	},
	{
		CPRO: '19',
		CMUN: '074',
		DC: '0',
		NOMBRE: 'Caspueñas',
	},
	{
		CPRO: '19',
		CMUN: '075',
		DC: '3',
		NOMBRE: 'Castejón de Henares',
	},
	{
		CPRO: '19',
		CMUN: '076',
		DC: '6',
		NOMBRE: 'Castellar de la Muela',
	},
	{
		CPRO: '19',
		CMUN: '078',
		DC: '8',
		NOMBRE: 'Castilforte',
	},
	{
		CPRO: '19',
		CMUN: '079',
		DC: '1',
		NOMBRE: 'Castilnuevo',
	},
	{
		CPRO: '19',
		CMUN: '080',
		DC: '5',
		NOMBRE: 'Cendejas de Enmedio',
	},
	{
		CPRO: '19',
		CMUN: '081',
		DC: '2',
		NOMBRE: 'Cendejas de la Torre',
	},
	{
		CPRO: '19',
		CMUN: '082',
		DC: '7',
		NOMBRE: 'Centenera',
	},
	{
		CPRO: '19',
		CMUN: '103',
		DC: '9',
		NOMBRE: 'Checa',
	},
	{
		CPRO: '19',
		CMUN: '104',
		DC: '4',
		NOMBRE: 'Chequilla',
	},
	{
		CPRO: '19',
		CMUN: '106',
		DC: '0',
		NOMBRE: 'Chillarón del Rey',
	},
	{
		CPRO: '19',
		CMUN: '105',
		DC: '7',
		NOMBRE: 'Chiloeches',
	},
	{
		CPRO: '19',
		CMUN: '086',
		DC: '4',
		NOMBRE: 'Cifuentes',
	},
	{
		CPRO: '19',
		CMUN: '087',
		DC: '0',
		NOMBRE: 'Cincovillas',
	},
	{
		CPRO: '19',
		CMUN: '088',
		DC: '6',
		NOMBRE: 'Ciruelas',
	},
	{
		CPRO: '19',
		CMUN: '089',
		DC: '9',
		NOMBRE: 'Ciruelos del Pinar',
	},
	{
		CPRO: '19',
		CMUN: '090',
		DC: '3',
		NOMBRE: 'Cobeta',
	},
	{
		CPRO: '19',
		CMUN: '091',
		DC: '0',
		NOMBRE: 'Cogollor',
	},
	{
		CPRO: '19',
		CMUN: '092',
		DC: '5',
		NOMBRE: 'Cogolludo',
	},
	{
		CPRO: '19',
		CMUN: '095',
		DC: '9',
		NOMBRE: 'Condemios de Abajo',
	},
	{
		CPRO: '19',
		CMUN: '096',
		DC: '2',
		NOMBRE: 'Condemios de Arriba',
	},
	{
		CPRO: '19',
		CMUN: '097',
		DC: '8',
		NOMBRE: 'Congostrina',
	},
	{
		CPRO: '19',
		CMUN: '098',
		DC: '4',
		NOMBRE: 'Copernal',
	},
	{
		CPRO: '19',
		CMUN: '099',
		DC: '7',
		NOMBRE: 'Corduente',
	},
	{
		CPRO: '19',
		CMUN: '102',
		DC: '3',
		NOMBRE: 'Cubillo de Uceda, El',
	},
	{
		CPRO: '19',
		CMUN: '107',
		DC: '6',
		NOMBRE: 'Driebes',
	},
	{
		CPRO: '19',
		CMUN: '108',
		DC: '2',
		NOMBRE: 'Durón',
	},
	{
		CPRO: '19',
		CMUN: '109',
		DC: '5',
		NOMBRE: 'Embid',
	},
	{
		CPRO: '19',
		CMUN: '110',
		DC: '9',
		NOMBRE: 'Escamilla',
	},
	{
		CPRO: '19',
		CMUN: '111',
		DC: '6',
		NOMBRE: 'Escariche',
	},
	{
		CPRO: '19',
		CMUN: '112',
		DC: '1',
		NOMBRE: 'Escopete',
	},
	{
		CPRO: '19',
		CMUN: '113',
		DC: '7',
		NOMBRE: 'Espinosa de Henares',
	},
	{
		CPRO: '19',
		CMUN: '114',
		DC: '2',
		NOMBRE: 'Esplegares',
	},
	{
		CPRO: '19',
		CMUN: '115',
		DC: '5',
		NOMBRE: 'Establés',
	},
	{
		CPRO: '19',
		CMUN: '116',
		DC: '8',
		NOMBRE: 'Estriégana',
	},
	{
		CPRO: '19',
		CMUN: '117',
		DC: '4',
		NOMBRE: 'Fontanar',
	},
	{
		CPRO: '19',
		CMUN: '118',
		DC: '0',
		NOMBRE: 'Fuembellida',
	},
	{
		CPRO: '19',
		CMUN: '119',
		DC: '3',
		NOMBRE: 'Fuencemillán',
	},
	{
		CPRO: '19',
		CMUN: '120',
		DC: '7',
		NOMBRE: 'Fuentelahiguera de Albatages',
	},
	{
		CPRO: '19',
		CMUN: '121',
		DC: '4',
		NOMBRE: 'Fuentelencina',
	},
	{
		CPRO: '19',
		CMUN: '122',
		DC: '9',
		NOMBRE: 'Fuentelsaz',
	},
	{
		CPRO: '19',
		CMUN: '123',
		DC: '5',
		NOMBRE: 'Fuentelviejo',
	},
	{
		CPRO: '19',
		CMUN: '124',
		DC: '0',
		NOMBRE: 'Fuentenovilla',
	},
	{
		CPRO: '19',
		CMUN: '125',
		DC: '3',
		NOMBRE: 'Gajanejos',
	},
	{
		CPRO: '19',
		CMUN: '126',
		DC: '6',
		NOMBRE: 'Galápagos',
	},
	{
		CPRO: '19',
		CMUN: '127',
		DC: '2',
		NOMBRE: 'Galve de Sorbe',
	},
	{
		CPRO: '19',
		CMUN: '129',
		DC: '1',
		NOMBRE: 'Gascueña de Bornova',
	},
	{
		CPRO: '19',
		CMUN: '130',
		DC: '5',
		NOMBRE: 'Guadalajara',
	},
	{
		CPRO: '19',
		CMUN: '132',
		DC: '7',
		NOMBRE: 'Henche',
	},
	{
		CPRO: '19',
		CMUN: '133',
		DC: '3',
		NOMBRE: 'Heras de Ayuso',
	},
	{
		CPRO: '19',
		CMUN: '134',
		DC: '8',
		NOMBRE: 'Herrería',
	},
	{
		CPRO: '19',
		CMUN: '135',
		DC: '1',
		NOMBRE: 'Hiendelaencina',
	},
	{
		CPRO: '19',
		CMUN: '136',
		DC: '4',
		NOMBRE: 'Hijes',
	},
	{
		CPRO: '19',
		CMUN: '138',
		DC: '6',
		NOMBRE: 'Hita',
	},
	{
		CPRO: '19',
		CMUN: '139',
		DC: '9',
		NOMBRE: 'Hombrados',
	},
	{
		CPRO: '19',
		CMUN: '142',
		DC: '5',
		NOMBRE: 'Hontoba',
	},
	{
		CPRO: '19',
		CMUN: '143',
		DC: '1',
		NOMBRE: 'Horche',
	},
	{
		CPRO: '19',
		CMUN: '145',
		DC: '9',
		NOMBRE: 'Hortezuela de Océn',
	},
	{
		CPRO: '19',
		CMUN: '146',
		DC: '2',
		NOMBRE: 'Huerce, La',
	},
	{
		CPRO: '19',
		CMUN: '147',
		DC: '8',
		NOMBRE: 'Huérmeces del Cerro',
	},
	{
		CPRO: '19',
		CMUN: '148',
		DC: '4',
		NOMBRE: 'Huertahernando',
	},
	{
		CPRO: '19',
		CMUN: '150',
		DC: '0',
		NOMBRE: 'Hueva',
	},
	{
		CPRO: '19',
		CMUN: '151',
		DC: '7',
		NOMBRE: 'Humanes',
	},
	{
		CPRO: '19',
		CMUN: '152',
		DC: '2',
		NOMBRE: 'Illana',
	},
	{
		CPRO: '19',
		CMUN: '153',
		DC: '8',
		NOMBRE: 'Iniéstola',
	},
	{
		CPRO: '19',
		CMUN: '154',
		DC: '3',
		NOMBRE: 'Inviernas, Las',
	},
	{
		CPRO: '19',
		CMUN: '155',
		DC: '6',
		NOMBRE: 'Irueste',
	},
	{
		CPRO: '19',
		CMUN: '156',
		DC: '9',
		NOMBRE: 'Jadraque',
	},
	{
		CPRO: '19',
		CMUN: '157',
		DC: '5',
		NOMBRE: 'Jirueque',
	},
	{
		CPRO: '19',
		CMUN: '159',
		DC: '4',
		NOMBRE: 'Ledanca',
	},
	{
		CPRO: '19',
		CMUN: '160',
		DC: '8',
		NOMBRE: 'Loranca de Tajuña',
	},
	{
		CPRO: '19',
		CMUN: '161',
		DC: '5',
		NOMBRE: 'Lupiana',
	},
	{
		CPRO: '19',
		CMUN: '162',
		DC: '0',
		NOMBRE: 'Luzaga',
	},
	{
		CPRO: '19',
		CMUN: '163',
		DC: '6',
		NOMBRE: 'Luzón',
	},
	{
		CPRO: '19',
		CMUN: '165',
		DC: '4',
		NOMBRE: 'Majaelrayo',
	},
	{
		CPRO: '19',
		CMUN: '166',
		DC: '7',
		NOMBRE: 'Málaga del Fresno',
	},
	{
		CPRO: '19',
		CMUN: '167',
		DC: '3',
		NOMBRE: 'Malaguilla',
	},
	{
		CPRO: '19',
		CMUN: '168',
		DC: '9',
		NOMBRE: 'Mandayona',
	},
	{
		CPRO: '19',
		CMUN: '169',
		DC: '2',
		NOMBRE: 'Mantiel',
	},
	{
		CPRO: '19',
		CMUN: '170',
		DC: '6',
		NOMBRE: 'Maranchón',
	},
	{
		CPRO: '19',
		CMUN: '171',
		DC: '3',
		NOMBRE: 'Marchamalo',
	},
	{
		CPRO: '19',
		CMUN: '172',
		DC: '8',
		NOMBRE: 'Masegoso de Tajuña',
	},
	{
		CPRO: '19',
		CMUN: '173',
		DC: '4',
		NOMBRE: 'Matarrubia',
	},
	{
		CPRO: '19',
		CMUN: '174',
		DC: '9',
		NOMBRE: 'Matillas',
	},
	{
		CPRO: '19',
		CMUN: '175',
		DC: '2',
		NOMBRE: 'Mazarete',
	},
	{
		CPRO: '19',
		CMUN: '176',
		DC: '5',
		NOMBRE: 'Mazuecos',
	},
	{
		CPRO: '19',
		CMUN: '177',
		DC: '1',
		NOMBRE: 'Medranda',
	},
	{
		CPRO: '19',
		CMUN: '178',
		DC: '7',
		NOMBRE: 'Megina',
	},
	{
		CPRO: '19',
		CMUN: '179',
		DC: '0',
		NOMBRE: 'Membrillera',
	},
	{
		CPRO: '19',
		CMUN: '181',
		DC: '1',
		NOMBRE: 'Miedes de Atienza',
	},
	{
		CPRO: '19',
		CMUN: '182',
		DC: '6',
		NOMBRE: 'Mierla, La',
	},
	{
		CPRO: '19',
		CMUN: '184',
		DC: '7',
		NOMBRE: 'Millana',
	},
	{
		CPRO: '19',
		CMUN: '183',
		DC: '2',
		NOMBRE: 'Milmarcos',
	},
	{
		CPRO: '19',
		CMUN: '185',
		DC: '0',
		NOMBRE: 'Miñosa, La',
	},
	{
		CPRO: '19',
		CMUN: '186',
		DC: '3',
		NOMBRE: 'Mirabueno',
	},
	{
		CPRO: '19',
		CMUN: '187',
		DC: '9',
		NOMBRE: 'Miralrío',
	},
	{
		CPRO: '19',
		CMUN: '188',
		DC: '5',
		NOMBRE: 'Mochales',
	},
	{
		CPRO: '19',
		CMUN: '189',
		DC: '8',
		NOMBRE: 'Mohernando',
	},
	{
		CPRO: '19',
		CMUN: '190',
		DC: '2',
		NOMBRE: 'Molina de Aragón',
	},
	{
		CPRO: '19',
		CMUN: '191',
		DC: '9',
		NOMBRE: 'Monasterio',
	},
	{
		CPRO: '19',
		CMUN: '192',
		DC: '4',
		NOMBRE: 'Mondéjar',
	},
	{
		CPRO: '19',
		CMUN: '193',
		DC: '0',
		NOMBRE: 'Montarrón',
	},
	{
		CPRO: '19',
		CMUN: '194',
		DC: '5',
		NOMBRE: 'Moratilla de los Meleros',
	},
	{
		CPRO: '19',
		CMUN: '195',
		DC: '8',
		NOMBRE: 'Morenilla',
	},
	{
		CPRO: '19',
		CMUN: '196',
		DC: '1',
		NOMBRE: 'Muduex',
	},
	{
		CPRO: '19',
		CMUN: '197',
		DC: '7',
		NOMBRE: 'Navas de Jadraque, Las',
	},
	{
		CPRO: '19',
		CMUN: '198',
		DC: '3',
		NOMBRE: 'Negredo',
	},
	{
		CPRO: '19',
		CMUN: '199',
		DC: '6',
		NOMBRE: 'Ocentejo',
	},
	{
		CPRO: '19',
		CMUN: '200',
		DC: '0',
		NOMBRE: 'Olivar, El',
	},
	{
		CPRO: '19',
		CMUN: '201',
		DC: '7',
		NOMBRE: 'Olmeda de Cobeta',
	},
	{
		CPRO: '19',
		CMUN: '202',
		DC: '2',
		NOMBRE: 'Olmeda de Jadraque, La',
	},
	{
		CPRO: '19',
		CMUN: '203',
		DC: '8',
		NOMBRE: 'Ordial, El',
	},
	{
		CPRO: '19',
		CMUN: '204',
		DC: '3',
		NOMBRE: 'Orea',
	},
	{
		CPRO: '19',
		CMUN: '208',
		DC: '1',
		NOMBRE: 'Pálmaces de Jadraque',
	},
	{
		CPRO: '19',
		CMUN: '209',
		DC: '4',
		NOMBRE: 'Pardos',
	},
	{
		CPRO: '19',
		CMUN: '210',
		DC: '8',
		NOMBRE: 'Paredes de Sigüenza',
	},
	{
		CPRO: '19',
		CMUN: '211',
		DC: '5',
		NOMBRE: 'Pareja',
	},
	{
		CPRO: '19',
		CMUN: '212',
		DC: '0',
		NOMBRE: 'Pastrana',
	},
	{
		CPRO: '19',
		CMUN: '213',
		DC: '6',
		NOMBRE: 'Pedregal, El',
	},
	{
		CPRO: '19',
		CMUN: '214',
		DC: '1',
		NOMBRE: 'Peñalén',
	},
	{
		CPRO: '19',
		CMUN: '215',
		DC: '4',
		NOMBRE: 'Peñalver',
	},
	{
		CPRO: '19',
		CMUN: '216',
		DC: '7',
		NOMBRE: 'Peralejos de las Truchas',
	},
	{
		CPRO: '19',
		CMUN: '217',
		DC: '3',
		NOMBRE: 'Peralveche',
	},
	{
		CPRO: '19',
		CMUN: '218',
		DC: '9',
		NOMBRE: 'Pinilla de Jadraque',
	},
	{
		CPRO: '19',
		CMUN: '219',
		DC: '2',
		NOMBRE: 'Pinilla de Molina',
	},
	{
		CPRO: '19',
		CMUN: '220',
		DC: '6',
		NOMBRE: 'Pioz',
	},
	{
		CPRO: '19',
		CMUN: '221',
		DC: '3',
		NOMBRE: 'Piqueras',
	},
	{
		CPRO: '19',
		CMUN: '222',
		DC: '8',
		NOMBRE: 'Pobo de Dueñas, El',
	},
	{
		CPRO: '19',
		CMUN: '223',
		DC: '4',
		NOMBRE: 'Poveda de la Sierra',
	},
	{
		CPRO: '19',
		CMUN: '224',
		DC: '9',
		NOMBRE: 'Pozo de Almoguera',
	},
	{
		CPRO: '19',
		CMUN: '225',
		DC: '2',
		NOMBRE: 'Pozo de Guadalajara',
	},
	{
		CPRO: '19',
		CMUN: '226',
		DC: '5',
		NOMBRE: 'Prádena de Atienza',
	},
	{
		CPRO: '19',
		CMUN: '227',
		DC: '1',
		NOMBRE: 'Prados Redondos',
	},
	{
		CPRO: '19',
		CMUN: '228',
		DC: '7',
		NOMBRE: 'Puebla de Beleña',
	},
	{
		CPRO: '19',
		CMUN: '229',
		DC: '0',
		NOMBRE: 'Puebla de Valles',
	},
	{
		CPRO: '19',
		CMUN: '230',
		DC: '4',
		NOMBRE: 'Quer',
	},
	{
		CPRO: '19',
		CMUN: '231',
		DC: '1',
		NOMBRE: 'Rebollosa de Jadraque',
	},
	{
		CPRO: '19',
		CMUN: '232',
		DC: '6',
		NOMBRE: 'Recuenco, El',
	},
	{
		CPRO: '19',
		CMUN: '233',
		DC: '2',
		NOMBRE: 'Renera',
	},
	{
		CPRO: '19',
		CMUN: '234',
		DC: '7',
		NOMBRE: 'Retiendas',
	},
	{
		CPRO: '19',
		CMUN: '235',
		DC: '0',
		NOMBRE: 'Riba de Saelices',
	},
	{
		CPRO: '19',
		CMUN: '237',
		DC: '9',
		NOMBRE: 'Rillo de Gallo',
	},
	{
		CPRO: '19',
		CMUN: '238',
		DC: '5',
		NOMBRE: 'Riofrío del Llano',
	},
	{
		CPRO: '19',
		CMUN: '239',
		DC: '8',
		NOMBRE: 'Robledillo de Mohernando',
	},
	{
		CPRO: '19',
		CMUN: '240',
		DC: '2',
		NOMBRE: 'Robledo de Corpes',
	},
	{
		CPRO: '19',
		CMUN: '241',
		DC: '9',
		NOMBRE: 'Romanillos de Atienza',
	},
	{
		CPRO: '19',
		CMUN: '242',
		DC: '4',
		NOMBRE: 'Romanones',
	},
	{
		CPRO: '19',
		CMUN: '243',
		DC: '0',
		NOMBRE: 'Rueda de la Sierra',
	},
	{
		CPRO: '19',
		CMUN: '244',
		DC: '5',
		NOMBRE: 'Sacecorbo',
	},
	{
		CPRO: '19',
		CMUN: '245',
		DC: '8',
		NOMBRE: 'Sacedón',
	},
	{
		CPRO: '19',
		CMUN: '246',
		DC: '1',
		NOMBRE: 'Saelices de la Sal',
	},
	{
		CPRO: '19',
		CMUN: '247',
		DC: '7',
		NOMBRE: 'Salmerón',
	},
	{
		CPRO: '19',
		CMUN: '248',
		DC: '3',
		NOMBRE: 'San Andrés del Congosto',
	},
	{
		CPRO: '19',
		CMUN: '249',
		DC: '6',
		NOMBRE: 'San Andrés del Rey',
	},
	{
		CPRO: '19',
		CMUN: '250',
		DC: '9',
		NOMBRE: 'Santiuste',
	},
	{
		CPRO: '19',
		CMUN: '251',
		DC: '6',
		NOMBRE: 'Saúca',
	},
	{
		CPRO: '19',
		CMUN: '252',
		DC: '1',
		NOMBRE: 'Sayatón',
	},
	{
		CPRO: '19',
		CMUN: '254',
		DC: '2',
		NOMBRE: 'Selas',
	},
	{
		CPRO: '19',
		CMUN: '901',
		DC: '0',
		NOMBRE: 'Semillas',
	},
	{
		CPRO: '19',
		CMUN: '255',
		DC: '5',
		NOMBRE: 'Setiles',
	},
	{
		CPRO: '19',
		CMUN: '256',
		DC: '8',
		NOMBRE: 'Sienes',
	},
	{
		CPRO: '19',
		CMUN: '257',
		DC: '4',
		NOMBRE: 'Sigüenza',
	},
	{
		CPRO: '19',
		CMUN: '258',
		DC: '0',
		NOMBRE: 'Solanillos del Extremo',
	},
	{
		CPRO: '19',
		CMUN: '259',
		DC: '3',
		NOMBRE: 'Somolinos',
	},
	{
		CPRO: '19',
		CMUN: '260',
		DC: '7',
		NOMBRE: 'Sotillo, El',
	},
	{
		CPRO: '19',
		CMUN: '261',
		DC: '4',
		NOMBRE: 'Sotodosos',
	},
	{
		CPRO: '19',
		CMUN: '262',
		DC: '9',
		NOMBRE: 'Tamajón',
	},
	{
		CPRO: '19',
		CMUN: '263',
		DC: '5',
		NOMBRE: 'Taragudo',
	},
	{
		CPRO: '19',
		CMUN: '264',
		DC: '0',
		NOMBRE: 'Taravilla',
	},
	{
		CPRO: '19',
		CMUN: '265',
		DC: '3',
		NOMBRE: 'Tartanedo',
	},
	{
		CPRO: '19',
		CMUN: '266',
		DC: '6',
		NOMBRE: 'Tendilla',
	},
	{
		CPRO: '19',
		CMUN: '267',
		DC: '2',
		NOMBRE: 'Terzaga',
	},
	{
		CPRO: '19',
		CMUN: '268',
		DC: '8',
		NOMBRE: 'Tierzo',
	},
	{
		CPRO: '19',
		CMUN: '269',
		DC: '1',
		NOMBRE: 'Toba, La',
	},
	{
		CPRO: '19',
		CMUN: '271',
		DC: '2',
		NOMBRE: 'Tordellego',
	},
	{
		CPRO: '19',
		CMUN: '270',
		DC: '5',
		NOMBRE: 'Tordelrábano',
	},
	{
		CPRO: '19',
		CMUN: '272',
		DC: '7',
		NOMBRE: 'Tordesilos',
	},
	{
		CPRO: '19',
		CMUN: '274',
		DC: '8',
		NOMBRE: 'Torija',
	},
	{
		CPRO: '19',
		CMUN: '279',
		DC: '9',
		NOMBRE: 'Torre del Burgo',
	},
	{
		CPRO: '19',
		CMUN: '277',
		DC: '0',
		NOMBRE: 'Torrecuadrada de Molina',
	},
	{
		CPRO: '19',
		CMUN: '278',
		DC: '6',
		NOMBRE: 'Torrecuadradilla',
	},
	{
		CPRO: '19',
		CMUN: '280',
		DC: '3',
		NOMBRE: 'Torrejón del Rey',
	},
	{
		CPRO: '19',
		CMUN: '281',
		DC: '0',
		NOMBRE: 'Torremocha de Jadraque',
	},
	{
		CPRO: '19',
		CMUN: '282',
		DC: '5',
		NOMBRE: 'Torremocha del Campo',
	},
	{
		CPRO: '19',
		CMUN: '283',
		DC: '1',
		NOMBRE: 'Torremocha del Pinar',
	},
	{
		CPRO: '19',
		CMUN: '284',
		DC: '6',
		NOMBRE: 'Torremochuela',
	},
	{
		CPRO: '19',
		CMUN: '285',
		DC: '9',
		NOMBRE: 'Torrubia',
	},
	{
		CPRO: '19',
		CMUN: '286',
		DC: '2',
		NOMBRE: 'Tórtola de Henares',
	},
	{
		CPRO: '19',
		CMUN: '287',
		DC: '8',
		NOMBRE: 'Tortuera',
	},
	{
		CPRO: '19',
		CMUN: '288',
		DC: '4',
		NOMBRE: 'Tortuero',
	},
	{
		CPRO: '19',
		CMUN: '289',
		DC: '7',
		NOMBRE: 'Traíd',
	},
	{
		CPRO: '19',
		CMUN: '290',
		DC: '1',
		NOMBRE: 'Trijueque',
	},
	{
		CPRO: '19',
		CMUN: '291',
		DC: '8',
		NOMBRE: 'Trillo',
	},
	{
		CPRO: '19',
		CMUN: '293',
		DC: '9',
		NOMBRE: 'Uceda',
	},
	{
		CPRO: '19',
		CMUN: '294',
		DC: '4',
		NOMBRE: 'Ujados',
	},
	{
		CPRO: '19',
		CMUN: '296',
		DC: '0',
		NOMBRE: 'Utande',
	},
	{
		CPRO: '19',
		CMUN: '297',
		DC: '6',
		NOMBRE: 'Valdarachas',
	},
	{
		CPRO: '19',
		CMUN: '298',
		DC: '2',
		NOMBRE: 'Valdearenas',
	},
	{
		CPRO: '19',
		CMUN: '299',
		DC: '5',
		NOMBRE: 'Valdeavellano',
	},
	{
		CPRO: '19',
		CMUN: '300',
		DC: '9',
		NOMBRE: 'Valdeaveruelo',
	},
	{
		CPRO: '19',
		CMUN: '301',
		DC: '6',
		NOMBRE: 'Valdeconcha',
	},
	{
		CPRO: '19',
		CMUN: '302',
		DC: '1',
		NOMBRE: 'Valdegrudas',
	},
	{
		CPRO: '19',
		CMUN: '303',
		DC: '7',
		NOMBRE: 'Valdelcubo',
	},
	{
		CPRO: '19',
		CMUN: '304',
		DC: '2',
		NOMBRE: 'Valdenuño Fernández',
	},
	{
		CPRO: '19',
		CMUN: '305',
		DC: '5',
		NOMBRE: 'Valdepeñas de la Sierra',
	},
	{
		CPRO: '19',
		CMUN: '306',
		DC: '8',
		NOMBRE: 'Valderrebollo',
	},
	{
		CPRO: '19',
		CMUN: '307',
		DC: '4',
		NOMBRE: 'Valdesotos',
	},
	{
		CPRO: '19',
		CMUN: '308',
		DC: '0',
		NOMBRE: 'Valfermoso de Tajuña',
	},
	{
		CPRO: '19',
		CMUN: '309',
		DC: '3',
		NOMBRE: 'Valhermoso',
	},
	{
		CPRO: '19',
		CMUN: '310',
		DC: '7',
		NOMBRE: 'Valtablado del Río',
	},
	{
		CPRO: '19',
		CMUN: '311',
		DC: '4',
		NOMBRE: 'Valverde de los Arroyos',
	},
	{
		CPRO: '19',
		CMUN: '314',
		DC: '0',
		NOMBRE: 'Viana de Jadraque',
	},
	{
		CPRO: '19',
		CMUN: '317',
		DC: '2',
		NOMBRE: 'Villanueva de Alcorón',
	},
	{
		CPRO: '19',
		CMUN: '318',
		DC: '8',
		NOMBRE: 'Villanueva de Argecilla',
	},
	{
		CPRO: '19',
		CMUN: '319',
		DC: '1',
		NOMBRE: 'Villanueva de la Torre',
	},
	{
		CPRO: '19',
		CMUN: '321',
		DC: '2',
		NOMBRE: 'Villares de Jadraque',
	},
	{
		CPRO: '19',
		CMUN: '322',
		DC: '7',
		NOMBRE: 'Villaseca de Henares',
	},
	{
		CPRO: '19',
		CMUN: '323',
		DC: '3',
		NOMBRE: 'Villaseca de Uceda',
	},
	{
		CPRO: '19',
		CMUN: '324',
		DC: '8',
		NOMBRE: 'Villel de Mesa',
	},
	{
		CPRO: '19',
		CMUN: '325',
		DC: '1',
		NOMBRE: 'Viñuelas',
	},
	{
		CPRO: '19',
		CMUN: '326',
		DC: '4',
		NOMBRE: 'Yebes',
	},
	{
		CPRO: '19',
		CMUN: '327',
		DC: '0',
		NOMBRE: 'Yebra',
	},
	{
		CPRO: '19',
		CMUN: '329',
		DC: '9',
		NOMBRE: 'Yélamos de Abajo',
	},
	{
		CPRO: '19',
		CMUN: '330',
		DC: '3',
		NOMBRE: 'Yélamos de Arriba',
	},
	{
		CPRO: '19',
		CMUN: '331',
		DC: '0',
		NOMBRE: 'Yunquera de Henares',
	},
	{
		CPRO: '19',
		CMUN: '332',
		DC: '5',
		NOMBRE: 'Yunta, La',
	},
	{
		CPRO: '19',
		CMUN: '333',
		DC: '1',
		NOMBRE: 'Zaorejas',
	},
	{
		CPRO: '19',
		CMUN: '334',
		DC: '6',
		NOMBRE: 'Zarzuela de Jadraque',
	},
	{
		CPRO: '19',
		CMUN: '335',
		DC: '9',
		NOMBRE: 'Zorita de los Canes',
	},
	{
		CPRO: '20',
		CMUN: '001',
		DC: '3',
		NOMBRE: 'Abaltzisketa',
	},
	{
		CPRO: '20',
		CMUN: '002',
		DC: '8',
		NOMBRE: 'Aduna',
	},
	{
		CPRO: '20',
		CMUN: '016',
		DC: '3',
		NOMBRE: 'Aia',
	},
	{
		CPRO: '20',
		CMUN: '003',
		DC: '4',
		NOMBRE: 'Aizarnazabal',
	},
	{
		CPRO: '20',
		CMUN: '004',
		DC: '9',
		NOMBRE: 'Albiztur',
	},
	{
		CPRO: '20',
		CMUN: '005',
		DC: '2',
		NOMBRE: 'Alegia',
	},
	{
		CPRO: '20',
		CMUN: '006',
		DC: '5',
		NOMBRE: 'Alkiza',
	},
	{
		CPRO: '20',
		CMUN: '906',
		DC: '6',
		NOMBRE: 'Altzaga',
	},
	{
		CPRO: '20',
		CMUN: '007',
		DC: '1',
		NOMBRE: 'Altzo',
	},
	{
		CPRO: '20',
		CMUN: '008',
		DC: '7',
		NOMBRE: 'Amezketa',
	},
	{
		CPRO: '20',
		CMUN: '009',
		DC: '0',
		NOMBRE: 'Andoain',
	},
	{
		CPRO: '20',
		CMUN: '010',
		DC: '4',
		NOMBRE: 'Anoeta',
	},
	{
		CPRO: '20',
		CMUN: '011',
		DC: '1',
		NOMBRE: 'Antzuola',
	},
	{
		CPRO: '20',
		CMUN: '012',
		DC: '6',
		NOMBRE: 'Arama',
	},
	{
		CPRO: '20',
		CMUN: '013',
		DC: '2',
		NOMBRE: 'Aretxabaleta',
	},
	{
		CPRO: '20',
		CMUN: '055',
		DC: '1',
		NOMBRE: 'Arrasate/Mondragón',
	},
	{
		CPRO: '20',
		CMUN: '014',
		DC: '7',
		NOMBRE: 'Asteasu',
	},
	{
		CPRO: '20',
		CMUN: '903',
		DC: '5',
		NOMBRE: 'Astigarraga',
	},
	{
		CPRO: '20',
		CMUN: '015',
		DC: '0',
		NOMBRE: 'Ataun',
	},
	{
		CPRO: '20',
		CMUN: '017',
		DC: '9',
		NOMBRE: 'Azkoitia',
	},
	{
		CPRO: '20',
		CMUN: '018',
		DC: '5',
		NOMBRE: 'Azpeitia',
	},
	{
		CPRO: '20',
		CMUN: '904',
		DC: '0',
		NOMBRE: 'Baliarrain',
	},
	{
		CPRO: '20',
		CMUN: '019',
		DC: '8',
		NOMBRE: 'Beasain',
	},
	{
		CPRO: '20',
		CMUN: '020',
		DC: '2',
		NOMBRE: 'Beizama',
	},
	{
		CPRO: '20',
		CMUN: '021',
		DC: '9',
		NOMBRE: 'Belauntza',
	},
	{
		CPRO: '20',
		CMUN: '022',
		DC: '4',
		NOMBRE: 'Berastegi',
	},
	{
		CPRO: '20',
		CMUN: '074',
		DC: '4',
		NOMBRE: 'Bergara',
	},
	{
		CPRO: '20',
		CMUN: '023',
		DC: '0',
		NOMBRE: 'Berrobi',
	},
	{
		CPRO: '20',
		CMUN: '024',
		DC: '5',
		NOMBRE: 'Bidegoian',
	},
	{
		CPRO: '20',
		CMUN: '029',
		DC: '6',
		NOMBRE: 'Deba',
	},
	{
		CPRO: '20',
		CMUN: '069',
		DC: '7',
		NOMBRE: 'Donostia-San Sebastián',
	},
	{
		CPRO: '20',
		CMUN: '030',
		DC: '0',
		NOMBRE: 'Eibar',
	},
	{
		CPRO: '20',
		CMUN: '031',
		DC: '7',
		NOMBRE: 'Elduain',
	},
	{
		CPRO: '20',
		CMUN: '033',
		DC: '8',
		NOMBRE: 'Elgeta',
	},
	{
		CPRO: '20',
		CMUN: '032',
		DC: '2',
		NOMBRE: 'Elgoibar',
	},
	{
		CPRO: '20',
		CMUN: '067',
		DC: '8',
		NOMBRE: 'Errenteria',
	},
	{
		CPRO: '20',
		CMUN: '066',
		DC: '2',
		NOMBRE: 'Errezil',
	},
	{
		CPRO: '20',
		CMUN: '034',
		DC: '3',
		NOMBRE: 'Eskoriatza',
	},
	{
		CPRO: '20',
		CMUN: '035',
		DC: '6',
		NOMBRE: 'Ezkio-Itsaso',
	},
	{
		CPRO: '20',
		CMUN: '038',
		DC: '1',
		NOMBRE: 'Gabiria',
	},
	{
		CPRO: '20',
		CMUN: '037',
		DC: '5',
		NOMBRE: 'Gaintza',
	},
	{
		CPRO: '20',
		CMUN: '907',
		DC: '2',
		NOMBRE: 'Gaztelu',
	},
	{
		CPRO: '20',
		CMUN: '039',
		DC: '4',
		NOMBRE: 'Getaria',
	},
	{
		CPRO: '20',
		CMUN: '040',
		DC: '8',
		NOMBRE: 'Hernani',
	},
	{
		CPRO: '20',
		CMUN: '041',
		DC: '5',
		NOMBRE: 'Hernialde',
	},
	{
		CPRO: '20',
		CMUN: '036',
		DC: '9',
		NOMBRE: 'Hondarribia',
	},
	{
		CPRO: '20',
		CMUN: '042',
		DC: '0',
		NOMBRE: 'Ibarra',
	},
	{
		CPRO: '20',
		CMUN: '043',
		DC: '6',
		NOMBRE: 'Idiazabal',
	},
	{
		CPRO: '20',
		CMUN: '044',
		DC: '1',
		NOMBRE: 'Ikaztegieta',
	},
	{
		CPRO: '20',
		CMUN: '045',
		DC: '4',
		NOMBRE: 'Irun',
	},
	{
		CPRO: '20',
		CMUN: '046',
		DC: '7',
		NOMBRE: 'Irura',
	},
	{
		CPRO: '20',
		CMUN: '047',
		DC: '3',
		NOMBRE: 'Itsasondo',
	},
	{
		CPRO: '20',
		CMUN: '048',
		DC: '9',
		NOMBRE: 'Larraul',
	},
	{
		CPRO: '20',
		CMUN: '902',
		DC: '9',
		NOMBRE: 'Lasarte-Oria',
	},
	{
		CPRO: '20',
		CMUN: '049',
		DC: '2',
		NOMBRE: 'Lazkao',
	},
	{
		CPRO: '20',
		CMUN: '050',
		DC: '5',
		NOMBRE: 'Leaburu',
	},
	{
		CPRO: '20',
		CMUN: '051',
		DC: '2',
		NOMBRE: 'Legazpi',
	},
	{
		CPRO: '20',
		CMUN: '052',
		DC: '7',
		NOMBRE: 'Legorreta',
	},
	{
		CPRO: '20',
		CMUN: '068',
		DC: '4',
		NOMBRE: 'Leintz-Gatzaga',
	},
	{
		CPRO: '20',
		CMUN: '053',
		DC: '3',
		NOMBRE: 'Lezo',
	},
	{
		CPRO: '20',
		CMUN: '054',
		DC: '8',
		NOMBRE: 'Lizartza',
	},
	{
		CPRO: '20',
		CMUN: '901',
		DC: '4',
		NOMBRE: 'Mendaro',
	},
	{
		CPRO: '20',
		CMUN: '057',
		DC: '0',
		NOMBRE: 'Mutiloa',
	},
	{
		CPRO: '20',
		CMUN: '056',
		DC: '4',
		NOMBRE: 'Mutriku',
	},
	{
		CPRO: '20',
		CMUN: '063',
		DC: '1',
		NOMBRE: 'Oiartzun',
	},
	{
		CPRO: '20',
		CMUN: '058',
		DC: '6',
		NOMBRE: 'Olaberria',
	},
	{
		CPRO: '20',
		CMUN: '059',
		DC: '9',
		NOMBRE: 'Oñati',
	},
	{
		CPRO: '20',
		CMUN: '076',
		DC: '0',
		NOMBRE: 'Ordizia',
	},
	{
		CPRO: '20',
		CMUN: '905',
		DC: '3',
		NOMBRE: 'Orendain',
	},
	{
		CPRO: '20',
		CMUN: '060',
		DC: '3',
		NOMBRE: 'Orexa',
	},
	{
		CPRO: '20',
		CMUN: '061',
		DC: '0',
		NOMBRE: 'Orio',
	},
	{
		CPRO: '20',
		CMUN: '062',
		DC: '5',
		NOMBRE: 'Ormaiztegi',
	},
	{
		CPRO: '20',
		CMUN: '064',
		DC: '6',
		NOMBRE: 'Pasaia',
	},
	{
		CPRO: '20',
		CMUN: '070',
		DC: '1',
		NOMBRE: 'Segura',
	},
	{
		CPRO: '20',
		CMUN: '065',
		DC: '9',
		NOMBRE: 'Soraluze/Placencia de las Armas',
	},
	{
		CPRO: '20',
		CMUN: '071',
		DC: '8',
		NOMBRE: 'Tolosa',
	},
	{
		CPRO: '20',
		CMUN: '072',
		DC: '3',
		NOMBRE: 'Urnieta',
	},
	{
		CPRO: '20',
		CMUN: '077',
		DC: '6',
		NOMBRE: 'Urretxu',
	},
	{
		CPRO: '20',
		CMUN: '073',
		DC: '9',
		NOMBRE: 'Usurbil',
	},
	{
		CPRO: '20',
		CMUN: '075',
		DC: '7',
		NOMBRE: 'Villabona',
	},
	{
		CPRO: '20',
		CMUN: '078',
		DC: '2',
		NOMBRE: 'Zaldibia',
	},
	{
		CPRO: '20',
		CMUN: '079',
		DC: '5',
		NOMBRE: 'Zarautz',
	},
	{
		CPRO: '20',
		CMUN: '025',
		DC: '8',
		NOMBRE: 'Zegama',
	},
	{
		CPRO: '20',
		CMUN: '026',
		DC: '1',
		NOMBRE: 'Zerain',
	},
	{
		CPRO: '20',
		CMUN: '027',
		DC: '7',
		NOMBRE: 'Zestoa',
	},
	{
		CPRO: '20',
		CMUN: '028',
		DC: '3',
		NOMBRE: 'Zizurkil',
	},
	{
		CPRO: '20',
		CMUN: '081',
		DC: '6',
		NOMBRE: 'Zumaia',
	},
	{
		CPRO: '20',
		CMUN: '080',
		DC: '9',
		NOMBRE: 'Zumarraga',
	},
	{
		CPRO: '21',
		CMUN: '001',
		DC: '0',
		NOMBRE: 'Alájar',
	},
	{
		CPRO: '21',
		CMUN: '002',
		DC: '5',
		NOMBRE: 'Aljaraque',
	},
	{
		CPRO: '21',
		CMUN: '003',
		DC: '1',
		NOMBRE: 'Almendro, El',
	},
	{
		CPRO: '21',
		CMUN: '004',
		DC: '6',
		NOMBRE: 'Almonaster la Real',
	},
	{
		CPRO: '21',
		CMUN: '005',
		DC: '9',
		NOMBRE: 'Almonte',
	},
	{
		CPRO: '21',
		CMUN: '006',
		DC: '2',
		NOMBRE: 'Alosno',
	},
	{
		CPRO: '21',
		CMUN: '007',
		DC: '8',
		NOMBRE: 'Aracena',
	},
	{
		CPRO: '21',
		CMUN: '008',
		DC: '4',
		NOMBRE: 'Aroche',
	},
	{
		CPRO: '21',
		CMUN: '009',
		DC: '7',
		NOMBRE: 'Arroyomolinos de León',
	},
	{
		CPRO: '21',
		CMUN: '010',
		DC: '1',
		NOMBRE: 'Ayamonte',
	},
	{
		CPRO: '21',
		CMUN: '011',
		DC: '8',
		NOMBRE: 'Beas',
	},
	{
		CPRO: '21',
		CMUN: '012',
		DC: '3',
		NOMBRE: 'Berrocal',
	},
	{
		CPRO: '21',
		CMUN: '013',
		DC: '9',
		NOMBRE: 'Bollullos Par del Condado',
	},
	{
		CPRO: '21',
		CMUN: '014',
		DC: '4',
		NOMBRE: 'Bonares',
	},
	{
		CPRO: '21',
		CMUN: '015',
		DC: '7',
		NOMBRE: 'Cabezas Rubias',
	},
	{
		CPRO: '21',
		CMUN: '016',
		DC: '0',
		NOMBRE: 'Cala',
	},
	{
		CPRO: '21',
		CMUN: '017',
		DC: '6',
		NOMBRE: 'Calañas',
	},
	{
		CPRO: '21',
		CMUN: '018',
		DC: '2',
		NOMBRE: 'Campillo, El',
	},
	{
		CPRO: '21',
		CMUN: '019',
		DC: '5',
		NOMBRE: 'Campofrío',
	},
	{
		CPRO: '21',
		CMUN: '020',
		DC: '9',
		NOMBRE: 'Cañaveral de León',
	},
	{
		CPRO: '21',
		CMUN: '021',
		DC: '6',
		NOMBRE: 'Cartaya',
	},
	{
		CPRO: '21',
		CMUN: '022',
		DC: '1',
		NOMBRE: 'Castaño del Robledo',
	},
	{
		CPRO: '21',
		CMUN: '023',
		DC: '7',
		NOMBRE: 'Cerro de Andévalo, El',
	},
	{
		CPRO: '21',
		CMUN: '030',
		DC: '7',
		NOMBRE: 'Chucena',
	},
	{
		CPRO: '21',
		CMUN: '024',
		DC: '2',
		NOMBRE: 'Corteconcepción',
	},
	{
		CPRO: '21',
		CMUN: '025',
		DC: '5',
		NOMBRE: 'Cortegana',
	},
	{
		CPRO: '21',
		CMUN: '026',
		DC: '8',
		NOMBRE: 'Cortelazor',
	},
	{
		CPRO: '21',
		CMUN: '027',
		DC: '4',
		NOMBRE: 'Cumbres de Enmedio',
	},
	{
		CPRO: '21',
		CMUN: '028',
		DC: '0',
		NOMBRE: 'Cumbres de San Bartolomé',
	},
	{
		CPRO: '21',
		CMUN: '029',
		DC: '3',
		NOMBRE: 'Cumbres Mayores',
	},
	{
		CPRO: '21',
		CMUN: '031',
		DC: '4',
		NOMBRE: 'Encinasola',
	},
	{
		CPRO: '21',
		CMUN: '032',
		DC: '9',
		NOMBRE: 'Escacena del Campo',
	},
	{
		CPRO: '21',
		CMUN: '033',
		DC: '5',
		NOMBRE: 'Fuenteheridos',
	},
	{
		CPRO: '21',
		CMUN: '034',
		DC: '0',
		NOMBRE: 'Galaroza',
	},
	{
		CPRO: '21',
		CMUN: '035',
		DC: '3',
		NOMBRE: 'Gibraleón',
	},
	{
		CPRO: '21',
		CMUN: '036',
		DC: '6',
		NOMBRE: 'Granada de Río-Tinto, La',
	},
	{
		CPRO: '21',
		CMUN: '037',
		DC: '2',
		NOMBRE: 'Granado, El',
	},
	{
		CPRO: '21',
		CMUN: '038',
		DC: '8',
		NOMBRE: 'Higuera de la Sierra',
	},
	{
		CPRO: '21',
		CMUN: '039',
		DC: '1',
		NOMBRE: 'Hinojales',
	},
	{
		CPRO: '21',
		CMUN: '040',
		DC: '5',
		NOMBRE: 'Hinojos',
	},
	{
		CPRO: '21',
		CMUN: '041',
		DC: '2',
		NOMBRE: 'Huelva',
	},
	{
		CPRO: '21',
		CMUN: '042',
		DC: '7',
		NOMBRE: 'Isla Cristina',
	},
	{
		CPRO: '21',
		CMUN: '043',
		DC: '3',
		NOMBRE: 'Jabugo',
	},
	{
		CPRO: '21',
		CMUN: '044',
		DC: '8',
		NOMBRE: 'Lepe',
	},
	{
		CPRO: '21',
		CMUN: '045',
		DC: '1',
		NOMBRE: 'Linares de la Sierra',
	},
	{
		CPRO: '21',
		CMUN: '046',
		DC: '4',
		NOMBRE: 'Lucena del Puerto',
	},
	{
		CPRO: '21',
		CMUN: '047',
		DC: '0',
		NOMBRE: 'Manzanilla',
	},
	{
		CPRO: '21',
		CMUN: '048',
		DC: '6',
		NOMBRE: 'Marines, Los',
	},
	{
		CPRO: '21',
		CMUN: '049',
		DC: '9',
		NOMBRE: 'Minas de Riotinto',
	},
	{
		CPRO: '21',
		CMUN: '050',
		DC: '2',
		NOMBRE: 'Moguer',
	},
	{
		CPRO: '21',
		CMUN: '051',
		DC: '9',
		NOMBRE: 'Nava, La',
	},
	{
		CPRO: '21',
		CMUN: '052',
		DC: '4',
		NOMBRE: 'Nerva',
	},
	{
		CPRO: '21',
		CMUN: '053',
		DC: '0',
		NOMBRE: 'Niebla',
	},
	{
		CPRO: '21',
		CMUN: '054',
		DC: '5',
		NOMBRE: 'Palma del Condado, La',
	},
	{
		CPRO: '21',
		CMUN: '055',
		DC: '8',
		NOMBRE: 'Palos de la Frontera',
	},
	{
		CPRO: '21',
		CMUN: '056',
		DC: '1',
		NOMBRE: 'Paterna del Campo',
	},
	{
		CPRO: '21',
		CMUN: '057',
		DC: '7',
		NOMBRE: 'Paymogo',
	},
	{
		CPRO: '21',
		CMUN: '058',
		DC: '3',
		NOMBRE: 'Puebla de Guzmán',
	},
	{
		CPRO: '21',
		CMUN: '059',
		DC: '6',
		NOMBRE: 'Puerto Moral',
	},
	{
		CPRO: '21',
		CMUN: '060',
		DC: '0',
		NOMBRE: 'Punta Umbría',
	},
	{
		CPRO: '21',
		CMUN: '061',
		DC: '7',
		NOMBRE: 'Rociana del Condado',
	},
	{
		CPRO: '21',
		CMUN: '062',
		DC: '2',
		NOMBRE: 'Rosal de la Frontera',
	},
	{
		CPRO: '21',
		CMUN: '063',
		DC: '8',
		NOMBRE: 'San Bartolomé de la Torre',
	},
	{
		CPRO: '21',
		CMUN: '064',
		DC: '3',
		NOMBRE: 'San Juan del Puerto',
	},
	{
		CPRO: '21',
		CMUN: '066',
		DC: '9',
		NOMBRE: 'San Silvestre de Guzmán',
	},
	{
		CPRO: '21',
		CMUN: '065',
		DC: '6',
		NOMBRE: 'Sanlúcar de Guadiana',
	},
	{
		CPRO: '21',
		CMUN: '067',
		DC: '5',
		NOMBRE: 'Santa Ana la Real',
	},
	{
		CPRO: '21',
		CMUN: '068',
		DC: '1',
		NOMBRE: 'Santa Bárbara de Casa',
	},
	{
		CPRO: '21',
		CMUN: '069',
		DC: '4',
		NOMBRE: 'Santa Olalla del Cala',
	},
	{
		CPRO: '21',
		CMUN: '070',
		DC: '8',
		NOMBRE: 'Trigueros',
	},
	{
		CPRO: '21',
		CMUN: '071',
		DC: '5',
		NOMBRE: 'Valdelarco',
	},
	{
		CPRO: '21',
		CMUN: '072',
		DC: '0',
		NOMBRE: 'Valverde del Camino',
	},
	{
		CPRO: '21',
		CMUN: '073',
		DC: '6',
		NOMBRE: 'Villablanca',
	},
	{
		CPRO: '21',
		CMUN: '074',
		DC: '1',
		NOMBRE: 'Villalba del Alcor',
	},
	{
		CPRO: '21',
		CMUN: '075',
		DC: '4',
		NOMBRE: 'Villanueva de las Cruces',
	},
	{
		CPRO: '21',
		CMUN: '076',
		DC: '7',
		NOMBRE: 'Villanueva de los Castillejos',
	},
	{
		CPRO: '21',
		CMUN: '077',
		DC: '3',
		NOMBRE: 'Villarrasa',
	},
	{
		CPRO: '21',
		CMUN: '078',
		DC: '9',
		NOMBRE: 'Zalamea la Real',
	},
	{
		CPRO: '21',
		CMUN: '079',
		DC: '2',
		NOMBRE: 'Zufre',
	},
	{
		CPRO: '22',
		CMUN: '001',
		DC: '5',
		NOMBRE: 'Abiego',
	},
	{
		CPRO: '22',
		CMUN: '002',
		DC: '0',
		NOMBRE: 'Abizanda',
	},
	{
		CPRO: '22',
		CMUN: '003',
		DC: '6',
		NOMBRE: 'Adahuesca',
	},
	{
		CPRO: '22',
		CMUN: '004',
		DC: '1',
		NOMBRE: 'Agüero',
	},
	{
		CPRO: '22',
		CMUN: '907',
		DC: '4',
		NOMBRE: 'Aínsa-Sobrarbe',
	},
	{
		CPRO: '22',
		CMUN: '006',
		DC: '7',
		NOMBRE: 'Aisa',
	},
	{
		CPRO: '22',
		CMUN: '007',
		DC: '3',
		NOMBRE: 'Albalate de Cinca',
	},
	{
		CPRO: '22',
		CMUN: '008',
		DC: '9',
		NOMBRE: 'Albalatillo',
	},
	{
		CPRO: '22',
		CMUN: '009',
		DC: '2',
		NOMBRE: 'Albelda',
	},
	{
		CPRO: '22',
		CMUN: '011',
		DC: '3',
		NOMBRE: 'Albero Alto',
	},
	{
		CPRO: '22',
		CMUN: '012',
		DC: '8',
		NOMBRE: 'Albero Bajo',
	},
	{
		CPRO: '22',
		CMUN: '013',
		DC: '4',
		NOMBRE: 'Alberuela de Tubo',
	},
	{
		CPRO: '22',
		CMUN: '014',
		DC: '9',
		NOMBRE: 'Alcalá de Gurrea',
	},
	{
		CPRO: '22',
		CMUN: '015',
		DC: '2',
		NOMBRE: 'Alcalá del Obispo',
	},
	{
		CPRO: '22',
		CMUN: '016',
		DC: '5',
		NOMBRE: 'Alcampell',
	},
	{
		CPRO: '22',
		CMUN: '017',
		DC: '1',
		NOMBRE: 'Alcolea de Cinca',
	},
	{
		CPRO: '22',
		CMUN: '018',
		DC: '7',
		NOMBRE: 'Alcubierre',
	},
	{
		CPRO: '22',
		CMUN: '019',
		DC: '0',
		NOMBRE: 'Alerre',
	},
	{
		CPRO: '22',
		CMUN: '020',
		DC: '4',
		NOMBRE: 'Alfántega',
	},
	{
		CPRO: '22',
		CMUN: '021',
		DC: '1',
		NOMBRE: 'Almudévar',
	},
	{
		CPRO: '22',
		CMUN: '022',
		DC: '6',
		NOMBRE: 'Almunia de San Juan',
	},
	{
		CPRO: '22',
		CMUN: '023',
		DC: '2',
		NOMBRE: 'Almuniente',
	},
	{
		CPRO: '22',
		CMUN: '024',
		DC: '7',
		NOMBRE: 'Alquézar',
	},
	{
		CPRO: '22',
		CMUN: '025',
		DC: '0',
		NOMBRE: 'Altorricón',
	},
	{
		CPRO: '22',
		CMUN: '027',
		DC: '9',
		NOMBRE: 'Angüés',
	},
	{
		CPRO: '22',
		CMUN: '028',
		DC: '5',
		NOMBRE: 'Ansó',
	},
	{
		CPRO: '22',
		CMUN: '029',
		DC: '8',
		NOMBRE: 'Antillón',
	},
	{
		CPRO: '22',
		CMUN: '032',
		DC: '4',
		NOMBRE: 'Aragüés del Puerto',
	},
	{
		CPRO: '22',
		CMUN: '035',
		DC: '8',
		NOMBRE: 'Arén',
	},
	{
		CPRO: '22',
		CMUN: '036',
		DC: '1',
		NOMBRE: 'Argavieso',
	},
	{
		CPRO: '22',
		CMUN: '037',
		DC: '7',
		NOMBRE: 'Arguis',
	},
	{
		CPRO: '22',
		CMUN: '039',
		DC: '6',
		NOMBRE: 'Ayerbe',
	},
	{
		CPRO: '22',
		CMUN: '040',
		DC: '0',
		NOMBRE: 'Azanuy-Alins',
	},
	{
		CPRO: '22',
		CMUN: '041',
		DC: '7',
		NOMBRE: 'Azara',
	},
	{
		CPRO: '22',
		CMUN: '042',
		DC: '2',
		NOMBRE: 'Azlor',
	},
	{
		CPRO: '22',
		CMUN: '043',
		DC: '8',
		NOMBRE: 'Baélls',
	},
	{
		CPRO: '22',
		CMUN: '044',
		DC: '3',
		NOMBRE: 'Bailo',
	},
	{
		CPRO: '22',
		CMUN: '045',
		DC: '6',
		NOMBRE: 'Baldellou',
	},
	{
		CPRO: '22',
		CMUN: '046',
		DC: '9',
		NOMBRE: 'Ballobar',
	},
	{
		CPRO: '22',
		CMUN: '047',
		DC: '5',
		NOMBRE: 'Banastás',
	},
	{
		CPRO: '22',
		CMUN: '048',
		DC: '1',
		NOMBRE: 'Barbastro',
	},
	{
		CPRO: '22',
		CMUN: '049',
		DC: '4',
		NOMBRE: 'Barbués',
	},
	{
		CPRO: '22',
		CMUN: '050',
		DC: '7',
		NOMBRE: 'Barbuñales',
	},
	{
		CPRO: '22',
		CMUN: '051',
		DC: '4',
		NOMBRE: 'Bárcabo',
	},
	{
		CPRO: '22',
		CMUN: '052',
		DC: '9',
		NOMBRE: 'Belver de Cinca',
	},
	{
		CPRO: '22',
		CMUN: '053',
		DC: '5',
		NOMBRE: 'Benabarre',
	},
	{
		CPRO: '22',
		CMUN: '054',
		DC: '0',
		NOMBRE: 'Benasque',
	},
	{
		CPRO: '22',
		CMUN: '055',
		DC: '3',
		NOMBRE: 'Berbegal',
	},
	{
		CPRO: '22',
		CMUN: '057',
		DC: '2',
		NOMBRE: 'Bielsa',
	},
	{
		CPRO: '22',
		CMUN: '058',
		DC: '8',
		NOMBRE: 'Bierge',
	},
	{
		CPRO: '22',
		CMUN: '059',
		DC: '1',
		NOMBRE: 'Biescas',
	},
	{
		CPRO: '22',
		CMUN: '060',
		DC: '5',
		NOMBRE: 'Binaced',
	},
	{
		CPRO: '22',
		CMUN: '061',
		DC: '2',
		NOMBRE: 'Binéfar',
	},
	{
		CPRO: '22',
		CMUN: '062',
		DC: '7',
		NOMBRE: 'Bisaurri',
	},
	{
		CPRO: '22',
		CMUN: '063',
		DC: '3',
		NOMBRE: 'Biscarrués',
	},
	{
		CPRO: '22',
		CMUN: '064',
		DC: '8',
		NOMBRE: 'Blecua y Torres',
	},
	{
		CPRO: '22',
		CMUN: '066',
		DC: '4',
		NOMBRE: 'Boltaña',
	},
	{
		CPRO: '22',
		CMUN: '067',
		DC: '0',
		NOMBRE: 'Bonansa',
	},
	{
		CPRO: '22',
		CMUN: '068',
		DC: '6',
		NOMBRE: 'Borau',
	},
	{
		CPRO: '22',
		CMUN: '069',
		DC: '9',
		NOMBRE: 'Broto',
	},
	{
		CPRO: '22',
		CMUN: '072',
		DC: '5',
		NOMBRE: 'Caldearenas',
	},
	{
		CPRO: '22',
		CMUN: '074',
		DC: '6',
		NOMBRE: 'Campo',
	},
	{
		CPRO: '22',
		CMUN: '075',
		DC: '9',
		NOMBRE: 'Camporrélls',
	},
	{
		CPRO: '22',
		CMUN: '076',
		DC: '2',
		NOMBRE: 'Canal de Berdún',
	},
	{
		CPRO: '22',
		CMUN: '077',
		DC: '8',
		NOMBRE: 'Candasnos',
	},
	{
		CPRO: '22',
		CMUN: '078',
		DC: '4',
		NOMBRE: 'Canfranc',
	},
	{
		CPRO: '22',
		CMUN: '079',
		DC: '7',
		NOMBRE: 'Capdesaso',
	},
	{
		CPRO: '22',
		CMUN: '080',
		DC: '1',
		NOMBRE: 'Capella',
	},
	{
		CPRO: '22',
		CMUN: '081',
		DC: '8',
		NOMBRE: 'Casbas de Huesca',
	},
	{
		CPRO: '22',
		CMUN: '083',
		DC: '9',
		NOMBRE: 'Castejón de Monegros',
	},
	{
		CPRO: '22',
		CMUN: '084',
		DC: '4',
		NOMBRE: 'Castejón de Sos',
	},
	{
		CPRO: '22',
		CMUN: '082',
		DC: '3',
		NOMBRE: 'Castejón del Puente',
	},
	{
		CPRO: '22',
		CMUN: '085',
		DC: '7',
		NOMBRE: 'Castelflorite',
	},
	{
		CPRO: '22',
		CMUN: '086',
		DC: '0',
		NOMBRE: 'Castiello de Jaca',
	},
	{
		CPRO: '22',
		CMUN: '087',
		DC: '6',
		NOMBRE: 'Castigaleu',
	},
	{
		CPRO: '22',
		CMUN: '088',
		DC: '2',
		NOMBRE: 'Castillazuelo',
	},
	{
		CPRO: '22',
		CMUN: '089',
		DC: '5',
		NOMBRE: 'Castillonroy',
	},
	{
		CPRO: '22',
		CMUN: '094',
		DC: '2',
		NOMBRE: 'Chalamera',
	},
	{
		CPRO: '22',
		CMUN: '095',
		DC: '5',
		NOMBRE: 'Chía',
	},
	{
		CPRO: '22',
		CMUN: '096',
		DC: '8',
		NOMBRE: 'Chimillas',
	},
	{
		CPRO: '22',
		CMUN: '090',
		DC: '9',
		NOMBRE: 'Colungo',
	},
	{
		CPRO: '22',
		CMUN: '099',
		DC: '3',
		NOMBRE: 'Esplús',
	},
	{
		CPRO: '22',
		CMUN: '102',
		DC: '9',
		NOMBRE: 'Estada',
	},
	{
		CPRO: '22',
		CMUN: '103',
		DC: '5',
		NOMBRE: 'Estadilla',
	},
	{
		CPRO: '22',
		CMUN: '105',
		DC: '3',
		NOMBRE: 'Estopiñán del Castillo',
	},
	{
		CPRO: '22',
		CMUN: '106',
		DC: '6',
		NOMBRE: 'Fago',
	},
	{
		CPRO: '22',
		CMUN: '107',
		DC: '2',
		NOMBRE: 'Fanlo',
	},
	{
		CPRO: '22',
		CMUN: '109',
		DC: '1',
		NOMBRE: 'Fiscal',
	},
	{
		CPRO: '22',
		CMUN: '110',
		DC: '5',
		NOMBRE: 'Fonz',
	},
	{
		CPRO: '22',
		CMUN: '111',
		DC: '2',
		NOMBRE: 'Foradada del Toscar',
	},
	{
		CPRO: '22',
		CMUN: '112',
		DC: '7',
		NOMBRE: 'Fraga',
	},
	{
		CPRO: '22',
		CMUN: '113',
		DC: '3',
		NOMBRE: 'Fueva, La',
	},
	{
		CPRO: '22',
		CMUN: '114',
		DC: '8',
		NOMBRE: 'Gistaín',
	},
	{
		CPRO: '22',
		CMUN: '115',
		DC: '1',
		NOMBRE: 'Grado, El',
	},
	{
		CPRO: '22',
		CMUN: '116',
		DC: '4',
		NOMBRE: 'Grañén',
	},
	{
		CPRO: '22',
		CMUN: '117',
		DC: '0',
		NOMBRE: 'Graus',
	},
	{
		CPRO: '22',
		CMUN: '119',
		DC: '9',
		NOMBRE: 'Gurrea de Gállego',
	},
	{
		CPRO: '22',
		CMUN: '122',
		DC: '5',
		NOMBRE: 'Hoz de Jaca',
	},
	{
		CPRO: '22',
		CMUN: '908',
		DC: '0',
		NOMBRE: 'Hoz y Costean',
	},
	{
		CPRO: '22',
		CMUN: '124',
		DC: '6',
		NOMBRE: 'Huerto',
	},
	{
		CPRO: '22',
		CMUN: '125',
		DC: '9',
		NOMBRE: 'Huesca',
	},
	{
		CPRO: '22',
		CMUN: '126',
		DC: '2',
		NOMBRE: 'Ibieca',
	},
	{
		CPRO: '22',
		CMUN: '127',
		DC: '8',
		NOMBRE: 'Igriés',
	},
	{
		CPRO: '22',
		CMUN: '128',
		DC: '4',
		NOMBRE: 'Ilche',
	},
	{
		CPRO: '22',
		CMUN: '129',
		DC: '7',
		NOMBRE: 'Isábena',
	},
	{
		CPRO: '22',
		CMUN: '130',
		DC: '1',
		NOMBRE: 'Jaca',
	},
	{
		CPRO: '22',
		CMUN: '131',
		DC: '8',
		NOMBRE: 'Jasa',
	},
	{
		CPRO: '22',
		CMUN: '133',
		DC: '9',
		NOMBRE: 'Labuerda',
	},
	{
		CPRO: '22',
		CMUN: '135',
		DC: '7',
		NOMBRE: 'Laluenga',
	},
	{
		CPRO: '22',
		CMUN: '136',
		DC: '0',
		NOMBRE: 'Lalueza',
	},
	{
		CPRO: '22',
		CMUN: '137',
		DC: '6',
		NOMBRE: 'Lanaja',
	},
	{
		CPRO: '22',
		CMUN: '139',
		DC: '5',
		NOMBRE: 'Laperdiguera',
	},
	{
		CPRO: '22',
		CMUN: '141',
		DC: '6',
		NOMBRE: 'Lascellas-Ponzano',
	},
	{
		CPRO: '22',
		CMUN: '142',
		DC: '1',
		NOMBRE: 'Lascuarre',
	},
	{
		CPRO: '22',
		CMUN: '143',
		DC: '7',
		NOMBRE: 'Laspaúles',
	},
	{
		CPRO: '22',
		CMUN: '144',
		DC: '2',
		NOMBRE: 'Laspuña',
	},
	{
		CPRO: '22',
		CMUN: '149',
		DC: '3',
		NOMBRE: 'Loarre',
	},
	{
		CPRO: '22',
		CMUN: '150',
		DC: '6',
		NOMBRE: 'Loporzano',
	},
	{
		CPRO: '22',
		CMUN: '151',
		DC: '3',
		NOMBRE: 'Loscorrales',
	},
	{
		CPRO: '22',
		CMUN: '905',
		DC: '5',
		NOMBRE: 'Lupiñén-Ortilla',
	},
	{
		CPRO: '22',
		CMUN: '155',
		DC: '2',
		NOMBRE: 'Monesma y Cajigar',
	},
	{
		CPRO: '22',
		CMUN: '156',
		DC: '5',
		NOMBRE: 'Monflorite-Lascasas',
	},
	{
		CPRO: '22',
		CMUN: '157',
		DC: '1',
		NOMBRE: 'Montanuy',
	},
	{
		CPRO: '22',
		CMUN: '158',
		DC: '7',
		NOMBRE: 'Monzón',
	},
	{
		CPRO: '22',
		CMUN: '160',
		DC: '4',
		NOMBRE: 'Naval',
	},
	{
		CPRO: '22',
		CMUN: '162',
		DC: '6',
		NOMBRE: 'Novales',
	},
	{
		CPRO: '22',
		CMUN: '163',
		DC: '2',
		NOMBRE: 'Nueno',
	},
	{
		CPRO: '22',
		CMUN: '164',
		DC: '7',
		NOMBRE: 'Olvena',
	},
	{
		CPRO: '22',
		CMUN: '165',
		DC: '0',
		NOMBRE: 'Ontiñena',
	},
	{
		CPRO: '22',
		CMUN: '167',
		DC: '9',
		NOMBRE: 'Osso de Cinca',
	},
	{
		CPRO: '22',
		CMUN: '168',
		DC: '5',
		NOMBRE: 'Palo',
	},
	{
		CPRO: '22',
		CMUN: '170',
		DC: '2',
		NOMBRE: 'Panticosa',
	},
	{
		CPRO: '22',
		CMUN: '172',
		DC: '4',
		NOMBRE: 'Peñalba',
	},
	{
		CPRO: '22',
		CMUN: '173',
		DC: '0',
		NOMBRE: 'Peñas de Riglos, Las',
	},
	{
		CPRO: '22',
		CMUN: '174',
		DC: '5',
		NOMBRE: 'Peralta de Alcofea',
	},
	{
		CPRO: '22',
		CMUN: '175',
		DC: '8',
		NOMBRE: 'Peralta de Calasanz',
	},
	{
		CPRO: '22',
		CMUN: '176',
		DC: '1',
		NOMBRE: 'Peraltilla',
	},
	{
		CPRO: '22',
		CMUN: '177',
		DC: '7',
		NOMBRE: 'Perarrúa',
	},
	{
		CPRO: '22',
		CMUN: '178',
		DC: '3',
		NOMBRE: 'Pertusa',
	},
	{
		CPRO: '22',
		CMUN: '181',
		DC: '7',
		NOMBRE: 'Piracés',
	},
	{
		CPRO: '22',
		CMUN: '182',
		DC: '2',
		NOMBRE: 'Plan',
	},
	{
		CPRO: '22',
		CMUN: '184',
		DC: '3',
		NOMBRE: 'Poleñino',
	},
	{
		CPRO: '22',
		CMUN: '186',
		DC: '9',
		NOMBRE: 'Pozán de Vero',
	},
	{
		CPRO: '22',
		CMUN: '187',
		DC: '5',
		NOMBRE: 'Puebla de Castro, La',
	},
	{
		CPRO: '22',
		CMUN: '188',
		DC: '1',
		NOMBRE: 'Puente de Montañana',
	},
	{
		CPRO: '22',
		CMUN: '902',
		DC: '1',
		NOMBRE: 'Puente la Reina de Jaca',
	},
	{
		CPRO: '22',
		CMUN: '189',
		DC: '4',
		NOMBRE: 'Puértolas',
	},
	{
		CPRO: '22',
		CMUN: '190',
		DC: '8',
		NOMBRE: 'Pueyo de Araguás, El',
	},
	{
		CPRO: '22',
		CMUN: '193',
		DC: '6',
		NOMBRE: 'Pueyo de Santa Cruz',
	},
	{
		CPRO: '22',
		CMUN: '195',
		DC: '4',
		NOMBRE: 'Quicena',
	},
	{
		CPRO: '22',
		CMUN: '197',
		DC: '3',
		NOMBRE: 'Robres',
	},
	{
		CPRO: '22',
		CMUN: '199',
		DC: '2',
		NOMBRE: 'Sabiñánigo',
	},
	{
		CPRO: '22',
		CMUN: '200',
		DC: '6',
		NOMBRE: 'Sahún',
	},
	{
		CPRO: '22',
		CMUN: '201',
		DC: '3',
		NOMBRE: 'Salas Altas',
	},
	{
		CPRO: '22',
		CMUN: '202',
		DC: '8',
		NOMBRE: 'Salas Bajas',
	},
	{
		CPRO: '22',
		CMUN: '203',
		DC: '4',
		NOMBRE: 'Salillas',
	},
	{
		CPRO: '22',
		CMUN: '204',
		DC: '9',
		NOMBRE: 'Sallent de Gállego',
	},
	{
		CPRO: '22',
		CMUN: '205',
		DC: '2',
		NOMBRE: 'San Esteban de Litera',
	},
	{
		CPRO: '22',
		CMUN: '207',
		DC: '1',
		NOMBRE: 'San Juan de Plan',
	},
	{
		CPRO: '22',
		CMUN: '903',
		DC: '7',
		NOMBRE: 'San Miguel del Cinca',
	},
	{
		CPRO: '22',
		CMUN: '206',
		DC: '5',
		NOMBRE: 'Sangarrén',
	},
	{
		CPRO: '22',
		CMUN: '208',
		DC: '7',
		NOMBRE: 'Santa Cilia',
	},
	{
		CPRO: '22',
		CMUN: '209',
		DC: '0',
		NOMBRE: 'Santa Cruz de la Serós',
	},
	{
		CPRO: '22',
		CMUN: '906',
		DC: '8',
		NOMBRE: 'Santa María de Dulcis',
	},
	{
		CPRO: '22',
		CMUN: '212',
		DC: '6',
		NOMBRE: 'Santaliestra y San Quílez',
	},
	{
		CPRO: '22',
		CMUN: '213',
		DC: '2',
		NOMBRE: 'Sariñena',
	},
	{
		CPRO: '22',
		CMUN: '214',
		DC: '7',
		NOMBRE: 'Secastilla',
	},
	{
		CPRO: '22',
		CMUN: '215',
		DC: '0',
		NOMBRE: 'Seira',
	},
	{
		CPRO: '22',
		CMUN: '217',
		DC: '9',
		NOMBRE: 'Sena',
	},
	{
		CPRO: '22',
		CMUN: '218',
		DC: '5',
		NOMBRE: 'Senés de Alcubierre',
	},
	{
		CPRO: '22',
		CMUN: '220',
		DC: '2',
		NOMBRE: 'Sesa',
	},
	{
		CPRO: '22',
		CMUN: '221',
		DC: '9',
		NOMBRE: 'Sesué',
	},
	{
		CPRO: '22',
		CMUN: '222',
		DC: '4',
		NOMBRE: 'Siétamo',
	},
	{
		CPRO: '22',
		CMUN: '223',
		DC: '0',
		NOMBRE: 'Sopeira',
	},
	{
		CPRO: '22',
		CMUN: '904',
		DC: '2',
		NOMBRE: 'Sotonera, La',
	},
	{
		CPRO: '22',
		CMUN: '225',
		DC: '8',
		NOMBRE: 'Tamarite de Litera',
	},
	{
		CPRO: '22',
		CMUN: '226',
		DC: '1',
		NOMBRE: 'Tardienta',
	},
	{
		CPRO: '22',
		CMUN: '227',
		DC: '7',
		NOMBRE: 'Tella-Sin',
	},
	{
		CPRO: '22',
		CMUN: '228',
		DC: '3',
		NOMBRE: 'Tierz',
	},
	{
		CPRO: '22',
		CMUN: '229',
		DC: '6',
		NOMBRE: 'Tolva',
	},
	{
		CPRO: '22',
		CMUN: '230',
		DC: '0',
		NOMBRE: 'Torla',
	},
	{
		CPRO: '22',
		CMUN: '232',
		DC: '2',
		NOMBRE: 'Torralba de Aragón',
	},
	{
		CPRO: '22',
		CMUN: '233',
		DC: '8',
		NOMBRE: 'Torre la Ribera',
	},
	{
		CPRO: '22',
		CMUN: '234',
		DC: '3',
		NOMBRE: 'Torrente de Cinca',
	},
	{
		CPRO: '22',
		CMUN: '235',
		DC: '6',
		NOMBRE: 'Torres de Alcanadre',
	},
	{
		CPRO: '22',
		CMUN: '236',
		DC: '9',
		NOMBRE: 'Torres de Barbués',
	},
	{
		CPRO: '22',
		CMUN: '239',
		DC: '4',
		NOMBRE: 'Tramaced',
	},
	{
		CPRO: '22',
		CMUN: '242',
		DC: '0',
		NOMBRE: 'Valfarta',
	},
	{
		CPRO: '22',
		CMUN: '243',
		DC: '6',
		NOMBRE: 'Valle de Bardají',
	},
	{
		CPRO: '22',
		CMUN: '901',
		DC: '6',
		NOMBRE: 'Valle de Hecho',
	},
	{
		CPRO: '22',
		CMUN: '244',
		DC: '1',
		NOMBRE: 'Valle de Lierp',
	},
	{
		CPRO: '22',
		CMUN: '245',
		DC: '4',
		NOMBRE: 'Velilla de Cinca',
	},
	{
		CPRO: '22',
		CMUN: '909',
		DC: '3',
		NOMBRE: 'Vencillón',
	},
	{
		CPRO: '22',
		CMUN: '246',
		DC: '7',
		NOMBRE: 'Veracruz',
	},
	{
		CPRO: '22',
		CMUN: '247',
		DC: '3',
		NOMBRE: 'Viacamp y Litera',
	},
	{
		CPRO: '22',
		CMUN: '248',
		DC: '9',
		NOMBRE: 'Vicién',
	},
	{
		CPRO: '22',
		CMUN: '249',
		DC: '2',
		NOMBRE: 'Villanova',
	},
	{
		CPRO: '22',
		CMUN: '250',
		DC: '5',
		NOMBRE: 'Villanúa',
	},
	{
		CPRO: '22',
		CMUN: '251',
		DC: '2',
		NOMBRE: 'Villanueva de Sigena',
	},
	{
		CPRO: '22',
		CMUN: '252',
		DC: '7',
		NOMBRE: 'Yebra de Basa',
	},
	{
		CPRO: '22',
		CMUN: '253',
		DC: '3',
		NOMBRE: 'Yésero',
	},
	{
		CPRO: '22',
		CMUN: '254',
		DC: '8',
		NOMBRE: 'Zaidín',
	},
	{
		CPRO: '23',
		CMUN: '001',
		DC: '1',
		NOMBRE: 'Albanchez de Mágina',
	},
	{
		CPRO: '23',
		CMUN: '002',
		DC: '6',
		NOMBRE: 'Alcalá la Real',
	},
	{
		CPRO: '23',
		CMUN: '003',
		DC: '2',
		NOMBRE: 'Alcaudete',
	},
	{
		CPRO: '23',
		CMUN: '004',
		DC: '7',
		NOMBRE: 'Aldeaquemada',
	},
	{
		CPRO: '23',
		CMUN: '005',
		DC: '0',
		NOMBRE: 'Andújar',
	},
	{
		CPRO: '23',
		CMUN: '006',
		DC: '3',
		NOMBRE: 'Arjona',
	},
	{
		CPRO: '23',
		CMUN: '007',
		DC: '9',
		NOMBRE: 'Arjonilla',
	},
	{
		CPRO: '23',
		CMUN: '008',
		DC: '5',
		NOMBRE: 'Arquillos',
	},
	{
		CPRO: '23',
		CMUN: '905',
		DC: '1',
		NOMBRE: 'Arroyo del Ojanco',
	},
	{
		CPRO: '23',
		CMUN: '009',
		DC: '8',
		NOMBRE: 'Baeza',
	},
	{
		CPRO: '23',
		CMUN: '010',
		DC: '2',
		NOMBRE: 'Bailén',
	},
	{
		CPRO: '23',
		CMUN: '011',
		DC: '9',
		NOMBRE: 'Baños de la Encina',
	},
	{
		CPRO: '23',
		CMUN: '012',
		DC: '4',
		NOMBRE: 'Beas de Segura',
	},
	{
		CPRO: '23',
		CMUN: '902',
		DC: '7',
		NOMBRE: 'Bedmar y Garcíez',
	},
	{
		CPRO: '23',
		CMUN: '014',
		DC: '5',
		NOMBRE: 'Begíjar',
	},
	{
		CPRO: '23',
		CMUN: '015',
		DC: '8',
		NOMBRE: 'Bélmez de la Moraleda',
	},
	{
		CPRO: '23',
		CMUN: '016',
		DC: '1',
		NOMBRE: 'Benatae',
	},
	{
		CPRO: '23',
		CMUN: '017',
		DC: '7',
		NOMBRE: 'Cabra del Santo Cristo',
	},
	{
		CPRO: '23',
		CMUN: '018',
		DC: '3',
		NOMBRE: 'Cambil',
	},
	{
		CPRO: '23',
		CMUN: '019',
		DC: '6',
		NOMBRE: 'Campillo de Arenas',
	},
	{
		CPRO: '23',
		CMUN: '020',
		DC: '0',
		NOMBRE: 'Canena',
	},
	{
		CPRO: '23',
		CMUN: '021',
		DC: '7',
		NOMBRE: 'Carboneros',
	},
	{
		CPRO: '23',
		CMUN: '901',
		DC: '2',
		NOMBRE: 'Cárcheles',
	},
	{
		CPRO: '23',
		CMUN: '024',
		DC: '3',
		NOMBRE: 'Carolina, La',
	},
	{
		CPRO: '23',
		CMUN: '025',
		DC: '6',
		NOMBRE: 'Castellar',
	},
	{
		CPRO: '23',
		CMUN: '026',
		DC: '9',
		NOMBRE: 'Castillo de Locubín',
	},
	{
		CPRO: '23',
		CMUN: '027',
		DC: '5',
		NOMBRE: 'Cazalilla',
	},
	{
		CPRO: '23',
		CMUN: '028',
		DC: '1',
		NOMBRE: 'Cazorla',
	},
	{
		CPRO: '23',
		CMUN: '029',
		DC: '4',
		NOMBRE: 'Chiclana de Segura',
	},
	{
		CPRO: '23',
		CMUN: '030',
		DC: '8',
		NOMBRE: 'Chilluévar',
	},
	{
		CPRO: '23',
		CMUN: '031',
		DC: '5',
		NOMBRE: 'Escañuela',
	},
	{
		CPRO: '23',
		CMUN: '032',
		DC: '0',
		NOMBRE: 'Espelúy',
	},
	{
		CPRO: '23',
		CMUN: '033',
		DC: '6',
		NOMBRE: 'Frailes',
	},
	{
		CPRO: '23',
		CMUN: '034',
		DC: '1',
		NOMBRE: 'Fuensanta de Martos',
	},
	{
		CPRO: '23',
		CMUN: '035',
		DC: '4',
		NOMBRE: 'Fuerte del Rey',
	},
	{
		CPRO: '23',
		CMUN: '037',
		DC: '3',
		NOMBRE: 'Génave',
	},
	{
		CPRO: '23',
		CMUN: '038',
		DC: '9',
		NOMBRE: 'Guardia de Jaén, La',
	},
	{
		CPRO: '23',
		CMUN: '039',
		DC: '2',
		NOMBRE: 'Guarromán',
	},
	{
		CPRO: '23',
		CMUN: '041',
		DC: '3',
		NOMBRE: 'Higuera de Calatrava',
	},
	{
		CPRO: '23',
		CMUN: '042',
		DC: '8',
		NOMBRE: 'Hinojares',
	},
	{
		CPRO: '23',
		CMUN: '043',
		DC: '4',
		NOMBRE: 'Hornos',
	},
	{
		CPRO: '23',
		CMUN: '044',
		DC: '9',
		NOMBRE: 'Huelma',
	},
	{
		CPRO: '23',
		CMUN: '045',
		DC: '2',
		NOMBRE: 'Huesa',
	},
	{
		CPRO: '23',
		CMUN: '046',
		DC: '5',
		NOMBRE: 'Ibros',
	},
	{
		CPRO: '23',
		CMUN: '047',
		DC: '1',
		NOMBRE: 'Iruela, La',
	},
	{
		CPRO: '23',
		CMUN: '048',
		DC: '7',
		NOMBRE: 'Iznatoraf',
	},
	{
		CPRO: '23',
		CMUN: '049',
		DC: '0',
		NOMBRE: 'Jabalquinto',
	},
	{
		CPRO: '23',
		CMUN: '050',
		DC: '3',
		NOMBRE: 'Jaén',
	},
	{
		CPRO: '23',
		CMUN: '051',
		DC: '0',
		NOMBRE: 'Jamilena',
	},
	{
		CPRO: '23',
		CMUN: '052',
		DC: '5',
		NOMBRE: 'Jimena',
	},
	{
		CPRO: '23',
		CMUN: '053',
		DC: '1',
		NOMBRE: 'Jódar',
	},
	{
		CPRO: '23',
		CMUN: '040',
		DC: '6',
		NOMBRE: 'Lahiguera',
	},
	{
		CPRO: '23',
		CMUN: '054',
		DC: '6',
		NOMBRE: 'Larva',
	},
	{
		CPRO: '23',
		CMUN: '055',
		DC: '9',
		NOMBRE: 'Linares',
	},
	{
		CPRO: '23',
		CMUN: '056',
		DC: '2',
		NOMBRE: 'Lopera',
	},
	{
		CPRO: '23',
		CMUN: '057',
		DC: '8',
		NOMBRE: 'Lupión',
	},
	{
		CPRO: '23',
		CMUN: '058',
		DC: '4',
		NOMBRE: 'Mancha Real',
	},
	{
		CPRO: '23',
		CMUN: '059',
		DC: '7',
		NOMBRE: 'Marmolejo',
	},
	{
		CPRO: '23',
		CMUN: '060',
		DC: '1',
		NOMBRE: 'Martos',
	},
	{
		CPRO: '23',
		CMUN: '061',
		DC: '8',
		NOMBRE: 'Mengíbar',
	},
	{
		CPRO: '23',
		CMUN: '062',
		DC: '3',
		NOMBRE: 'Montizón',
	},
	{
		CPRO: '23',
		CMUN: '063',
		DC: '9',
		NOMBRE: 'Navas de San Juan',
	},
	{
		CPRO: '23',
		CMUN: '064',
		DC: '4',
		NOMBRE: 'Noalejo',
	},
	{
		CPRO: '23',
		CMUN: '065',
		DC: '7',
		NOMBRE: 'Orcera',
	},
	{
		CPRO: '23',
		CMUN: '066',
		DC: '0',
		NOMBRE: 'Peal de Becerro',
	},
	{
		CPRO: '23',
		CMUN: '067',
		DC: '6',
		NOMBRE: 'Pegalajar',
	},
	{
		CPRO: '23',
		CMUN: '069',
		DC: '5',
		NOMBRE: 'Porcuna',
	},
	{
		CPRO: '23',
		CMUN: '070',
		DC: '9',
		NOMBRE: 'Pozo Alcón',
	},
	{
		CPRO: '23',
		CMUN: '071',
		DC: '6',
		NOMBRE: 'Puente de Génave',
	},
	{
		CPRO: '23',
		CMUN: '072',
		DC: '1',
		NOMBRE: 'Puerta de Segura, La',
	},
	{
		CPRO: '23',
		CMUN: '073',
		DC: '7',
		NOMBRE: 'Quesada',
	},
	{
		CPRO: '23',
		CMUN: '074',
		DC: '2',
		NOMBRE: 'Rus',
	},
	{
		CPRO: '23',
		CMUN: '075',
		DC: '5',
		NOMBRE: 'Sabiote',
	},
	{
		CPRO: '23',
		CMUN: '076',
		DC: '8',
		NOMBRE: 'Santa Elena',
	},
	{
		CPRO: '23',
		CMUN: '077',
		DC: '4',
		NOMBRE: 'Santiago de Calatrava',
	},
	{
		CPRO: '23',
		CMUN: '904',
		DC: '8',
		NOMBRE: 'Santiago-Pontones',
	},
	{
		CPRO: '23',
		CMUN: '079',
		DC: '3',
		NOMBRE: 'Santisteban del Puerto',
	},
	{
		CPRO: '23',
		CMUN: '080',
		DC: '7',
		NOMBRE: 'Santo Tomé',
	},
	{
		CPRO: '23',
		CMUN: '081',
		DC: '4',
		NOMBRE: 'Segura de la Sierra',
	},
	{
		CPRO: '23',
		CMUN: '082',
		DC: '9',
		NOMBRE: 'Siles',
	},
	{
		CPRO: '23',
		CMUN: '084',
		DC: '0',
		NOMBRE: 'Sorihuela del Guadalimar',
	},
	{
		CPRO: '23',
		CMUN: '086',
		DC: '6',
		NOMBRE: 'Torre del Campo',
	},
	{
		CPRO: '23',
		CMUN: '085',
		DC: '3',
		NOMBRE: 'Torreblascopedro',
	},
	{
		CPRO: '23',
		CMUN: '087',
		DC: '2',
		NOMBRE: 'Torredonjimeno',
	},
	{
		CPRO: '23',
		CMUN: '088',
		DC: '8',
		NOMBRE: 'Torreperogil',
	},
	{
		CPRO: '23',
		CMUN: '090',
		DC: '5',
		NOMBRE: 'Torres',
	},
	{
		CPRO: '23',
		CMUN: '091',
		DC: '2',
		NOMBRE: 'Torres de Albánchez',
	},
	{
		CPRO: '23',
		CMUN: '092',
		DC: '7',
		NOMBRE: 'Úbeda',
	},
	{
		CPRO: '23',
		CMUN: '093',
		DC: '3',
		NOMBRE: 'Valdepeñas de Jaén',
	},
	{
		CPRO: '23',
		CMUN: '094',
		DC: '8',
		NOMBRE: 'Vilches',
	},
	{
		CPRO: '23',
		CMUN: '095',
		DC: '1',
		NOMBRE: 'Villacarrillo',
	},
	{
		CPRO: '23',
		CMUN: '096',
		DC: '4',
		NOMBRE: 'Villanueva de la Reina',
	},
	{
		CPRO: '23',
		CMUN: '097',
		DC: '0',
		NOMBRE: 'Villanueva del Arzobispo',
	},
	{
		CPRO: '23',
		CMUN: '098',
		DC: '6',
		NOMBRE: 'Villardompardo',
	},
	{
		CPRO: '23',
		CMUN: '099',
		DC: '9',
		NOMBRE: 'Villares, Los',
	},
	{
		CPRO: '23',
		CMUN: '101',
		DC: '0',
		NOMBRE: 'Villarrodrigo',
	},
	{
		CPRO: '23',
		CMUN: '903',
		DC: '3',
		NOMBRE: 'Villatorres',
	},
	{
		CPRO: '24',
		CMUN: '001',
		DC: '6',
		NOMBRE: 'Acebedo',
	},
	{
		CPRO: '24',
		CMUN: '002',
		DC: '1',
		NOMBRE: 'Algadefe',
	},
	{
		CPRO: '24',
		CMUN: '003',
		DC: '7',
		NOMBRE: 'Alija del Infantado',
	},
	{
		CPRO: '24',
		CMUN: '004',
		DC: '2',
		NOMBRE: 'Almanza',
	},
	{
		CPRO: '24',
		CMUN: '005',
		DC: '5',
		NOMBRE: 'Antigua, La',
	},
	{
		CPRO: '24',
		CMUN: '006',
		DC: '8',
		NOMBRE: 'Ardón',
	},
	{
		CPRO: '24',
		CMUN: '007',
		DC: '4',
		NOMBRE: 'Arganza',
	},
	{
		CPRO: '24',
		CMUN: '008',
		DC: '0',
		NOMBRE: 'Astorga',
	},
	{
		CPRO: '24',
		CMUN: '009',
		DC: '3',
		NOMBRE: 'Balboa',
	},
	{
		CPRO: '24',
		CMUN: '010',
		DC: '7',
		NOMBRE: 'Bañeza, La',
	},
	{
		CPRO: '24',
		CMUN: '011',
		DC: '4',
		NOMBRE: 'Barjas',
	},
	{
		CPRO: '24',
		CMUN: '012',
		DC: '9',
		NOMBRE: 'Barrios de Luna, Los',
	},
	{
		CPRO: '24',
		CMUN: '014',
		DC: '0',
		NOMBRE: 'Bembibre',
	},
	{
		CPRO: '24',
		CMUN: '015',
		DC: '3',
		NOMBRE: 'Benavides',
	},
	{
		CPRO: '24',
		CMUN: '016',
		DC: '6',
		NOMBRE: 'Benuza',
	},
	{
		CPRO: '24',
		CMUN: '017',
		DC: '2',
		NOMBRE: 'Bercianos del Páramo',
	},
	{
		CPRO: '24',
		CMUN: '018',
		DC: '8',
		NOMBRE: 'Bercianos del Real Camino',
	},
	{
		CPRO: '24',
		CMUN: '019',
		DC: '1',
		NOMBRE: 'Berlanga del Bierzo',
	},
	{
		CPRO: '24',
		CMUN: '020',
		DC: '5',
		NOMBRE: 'Boca de Huérgano',
	},
	{
		CPRO: '24',
		CMUN: '021',
		DC: '2',
		NOMBRE: 'Boñar',
	},
	{
		CPRO: '24',
		CMUN: '022',
		DC: '7',
		NOMBRE: 'Borrenes',
	},
	{
		CPRO: '24',
		CMUN: '023',
		DC: '3',
		NOMBRE: 'Brazuelo',
	},
	{
		CPRO: '24',
		CMUN: '024',
		DC: '8',
		NOMBRE: 'Burgo Ranero, El',
	},
	{
		CPRO: '24',
		CMUN: '025',
		DC: '1',
		NOMBRE: 'Burón',
	},
	{
		CPRO: '24',
		CMUN: '026',
		DC: '4',
		NOMBRE: 'Bustillo del Páramo',
	},
	{
		CPRO: '24',
		CMUN: '027',
		DC: '0',
		NOMBRE: 'Cabañas Raras',
	},
	{
		CPRO: '24',
		CMUN: '028',
		DC: '6',
		NOMBRE: 'Cabreros del Río',
	},
	{
		CPRO: '24',
		CMUN: '029',
		DC: '9',
		NOMBRE: 'Cabrillanes',
	},
	{
		CPRO: '24',
		CMUN: '030',
		DC: '3',
		NOMBRE: 'Cacabelos',
	},
	{
		CPRO: '24',
		CMUN: '031',
		DC: '0',
		NOMBRE: 'Calzada del Coto',
	},
	{
		CPRO: '24',
		CMUN: '032',
		DC: '5',
		NOMBRE: 'Campazas',
	},
	{
		CPRO: '24',
		CMUN: '033',
		DC: '1',
		NOMBRE: 'Campo de Villavidel',
	},
	{
		CPRO: '24',
		CMUN: '034',
		DC: '6',
		NOMBRE: 'Camponaraya',
	},
	{
		CPRO: '24',
		CMUN: '036',
		DC: '2',
		NOMBRE: 'Candín',
	},
	{
		CPRO: '24',
		CMUN: '037',
		DC: '8',
		NOMBRE: 'Cármenes',
	},
	{
		CPRO: '24',
		CMUN: '038',
		DC: '4',
		NOMBRE: 'Carracedelo',
	},
	{
		CPRO: '24',
		CMUN: '039',
		DC: '7',
		NOMBRE: 'Carrizo',
	},
	{
		CPRO: '24',
		CMUN: '040',
		DC: '1',
		NOMBRE: 'Carrocera',
	},
	{
		CPRO: '24',
		CMUN: '041',
		DC: '8',
		NOMBRE: 'Carucedo',
	},
	{
		CPRO: '24',
		CMUN: '042',
		DC: '3',
		NOMBRE: 'Castilfalé',
	},
	{
		CPRO: '24',
		CMUN: '043',
		DC: '9',
		NOMBRE: 'Castrillo de Cabrera',
	},
	{
		CPRO: '24',
		CMUN: '044',
		DC: '4',
		NOMBRE: 'Castrillo de la Valduerna',
	},
	{
		CPRO: '24',
		CMUN: '046',
		DC: '0',
		NOMBRE: 'Castrocalbón',
	},
	{
		CPRO: '24',
		CMUN: '047',
		DC: '6',
		NOMBRE: 'Castrocontrigo',
	},
	{
		CPRO: '24',
		CMUN: '049',
		DC: '5',
		NOMBRE: 'Castropodame',
	},
	{
		CPRO: '24',
		CMUN: '050',
		DC: '8',
		NOMBRE: 'Castrotierra de Valmadrigal',
	},
	{
		CPRO: '24',
		CMUN: '051',
		DC: '5',
		NOMBRE: 'Cea',
	},
	{
		CPRO: '24',
		CMUN: '052',
		DC: '0',
		NOMBRE: 'Cebanico',
	},
	{
		CPRO: '24',
		CMUN: '053',
		DC: '6',
		NOMBRE: 'Cebrones del Río',
	},
	{
		CPRO: '24',
		CMUN: '065',
		DC: '2',
		NOMBRE: 'Chozas de Abajo',
	},
	{
		CPRO: '24',
		CMUN: '054',
		DC: '1',
		NOMBRE: 'Cimanes de la Vega',
	},
	{
		CPRO: '24',
		CMUN: '055',
		DC: '4',
		NOMBRE: 'Cimanes del Tejar',
	},
	{
		CPRO: '24',
		CMUN: '056',
		DC: '7',
		NOMBRE: 'Cistierna',
	},
	{
		CPRO: '24',
		CMUN: '057',
		DC: '3',
		NOMBRE: 'Congosto',
	},
	{
		CPRO: '24',
		CMUN: '058',
		DC: '9',
		NOMBRE: 'Corbillos de los Oteros',
	},
	{
		CPRO: '24',
		CMUN: '059',
		DC: '2',
		NOMBRE: 'Corullón',
	},
	{
		CPRO: '24',
		CMUN: '060',
		DC: '6',
		NOMBRE: 'Crémenes',
	},
	{
		CPRO: '24',
		CMUN: '061',
		DC: '3',
		NOMBRE: 'Cuadros',
	},
	{
		CPRO: '24',
		CMUN: '062',
		DC: '8',
		NOMBRE: 'Cubillas de los Oteros',
	},
	{
		CPRO: '24',
		CMUN: '063',
		DC: '4',
		NOMBRE: 'Cubillas de Rueda',
	},
	{
		CPRO: '24',
		CMUN: '064',
		DC: '9',
		NOMBRE: 'Cubillos del Sil',
	},
	{
		CPRO: '24',
		CMUN: '066',
		DC: '5',
		NOMBRE: 'Destriana',
	},
	{
		CPRO: '24',
		CMUN: '067',
		DC: '1',
		NOMBRE: 'Encinedo',
	},
	{
		CPRO: '24',
		CMUN: '068',
		DC: '7',
		NOMBRE: 'Ercina, La',
	},
	{
		CPRO: '24',
		CMUN: '069',
		DC: '0',
		NOMBRE: 'Escobar de Campos',
	},
	{
		CPRO: '24',
		CMUN: '070',
		DC: '4',
		NOMBRE: 'Fabero',
	},
	{
		CPRO: '24',
		CMUN: '071',
		DC: '1',
		NOMBRE: 'Folgoso de la Ribera',
	},
	{
		CPRO: '24',
		CMUN: '073',
		DC: '2',
		NOMBRE: 'Fresno de la Vega',
	},
	{
		CPRO: '24',
		CMUN: '074',
		DC: '7',
		NOMBRE: 'Fuentes de Carbajal',
	},
	{
		CPRO: '24',
		CMUN: '076',
		DC: '3',
		NOMBRE: 'Garrafe de Torío',
	},
	{
		CPRO: '24',
		CMUN: '077',
		DC: '9',
		NOMBRE: 'Gordaliza del Pino',
	},
	{
		CPRO: '24',
		CMUN: '078',
		DC: '5',
		NOMBRE: 'Gordoncillo',
	},
	{
		CPRO: '24',
		CMUN: '079',
		DC: '8',
		NOMBRE: 'Gradefes',
	},
	{
		CPRO: '24',
		CMUN: '080',
		DC: '2',
		NOMBRE: 'Grajal de Campos',
	},
	{
		CPRO: '24',
		CMUN: '081',
		DC: '9',
		NOMBRE: 'Gusendos de los Oteros',
	},
	{
		CPRO: '24',
		CMUN: '082',
		DC: '4',
		NOMBRE: 'Hospital de Órbigo',
	},
	{
		CPRO: '24',
		CMUN: '083',
		DC: '0',
		NOMBRE: 'Igüeña',
	},
	{
		CPRO: '24',
		CMUN: '084',
		DC: '5',
		NOMBRE: 'Izagre',
	},
	{
		CPRO: '24',
		CMUN: '086',
		DC: '1',
		NOMBRE: 'Joarilla de las Matas',
	},
	{
		CPRO: '24',
		CMUN: '087',
		DC: '7',
		NOMBRE: 'Laguna Dalga',
	},
	{
		CPRO: '24',
		CMUN: '088',
		DC: '3',
		NOMBRE: 'Laguna de Negrillos',
	},
	{
		CPRO: '24',
		CMUN: '089',
		DC: '6',
		NOMBRE: 'León',
	},
	{
		CPRO: '24',
		CMUN: '092',
		DC: '2',
		NOMBRE: 'Llamas de la Ribera',
	},
	{
		CPRO: '24',
		CMUN: '090',
		DC: '0',
		NOMBRE: 'Lucillo',
	},
	{
		CPRO: '24',
		CMUN: '091',
		DC: '7',
		NOMBRE: 'Luyego',
	},
	{
		CPRO: '24',
		CMUN: '093',
		DC: '8',
		NOMBRE: 'Magaz de Cepeda',
	},
	{
		CPRO: '24',
		CMUN: '094',
		DC: '3',
		NOMBRE: 'Mansilla de las Mulas',
	},
	{
		CPRO: '24',
		CMUN: '095',
		DC: '6',
		NOMBRE: 'Mansilla Mayor',
	},
	{
		CPRO: '24',
		CMUN: '096',
		DC: '9',
		NOMBRE: 'Maraña',
	},
	{
		CPRO: '24',
		CMUN: '097',
		DC: '5',
		NOMBRE: 'Matadeón de los Oteros',
	},
	{
		CPRO: '24',
		CMUN: '098',
		DC: '1',
		NOMBRE: 'Matallana de Torío',
	},
	{
		CPRO: '24',
		CMUN: '099',
		DC: '4',
		NOMBRE: 'Matanza',
	},
	{
		CPRO: '24',
		CMUN: '100',
		DC: '8',
		NOMBRE: 'Molinaseca',
	},
	{
		CPRO: '24',
		CMUN: '101',
		DC: '5',
		NOMBRE: 'Murias de Paredes',
	},
	{
		CPRO: '24',
		CMUN: '102',
		DC: '0',
		NOMBRE: 'Noceda del Bierzo',
	},
	{
		CPRO: '24',
		CMUN: '103',
		DC: '6',
		NOMBRE: 'Oencia',
	},
	{
		CPRO: '24',
		CMUN: '104',
		DC: '1',
		NOMBRE: 'Omañas, Las',
	},
	{
		CPRO: '24',
		CMUN: '105',
		DC: '4',
		NOMBRE: 'Onzonilla',
	},
	{
		CPRO: '24',
		CMUN: '106',
		DC: '7',
		NOMBRE: 'Oseja de Sajambre',
	},
	{
		CPRO: '24',
		CMUN: '107',
		DC: '3',
		NOMBRE: 'Pajares de los Oteros',
	},
	{
		CPRO: '24',
		CMUN: '108',
		DC: '9',
		NOMBRE: 'Palacios de la Valduerna',
	},
	{
		CPRO: '24',
		CMUN: '109',
		DC: '2',
		NOMBRE: 'Palacios del Sil',
	},
	{
		CPRO: '24',
		CMUN: '110',
		DC: '6',
		NOMBRE: 'Páramo del Sil',
	},
	{
		CPRO: '24',
		CMUN: '112',
		DC: '8',
		NOMBRE: 'Peranzanes',
	},
	{
		CPRO: '24',
		CMUN: '113',
		DC: '4',
		NOMBRE: 'Pobladura de Pelayo García',
	},
	{
		CPRO: '24',
		CMUN: '114',
		DC: '9',
		NOMBRE: 'Pola de Gordón, La',
	},
	{
		CPRO: '24',
		CMUN: '115',
		DC: '2',
		NOMBRE: 'Ponferrada',
	},
	{
		CPRO: '24',
		CMUN: '116',
		DC: '5',
		NOMBRE: 'Posada de Valdeón',
	},
	{
		CPRO: '24',
		CMUN: '117',
		DC: '1',
		NOMBRE: 'Pozuelo del Páramo',
	},
	{
		CPRO: '24',
		CMUN: '118',
		DC: '7',
		NOMBRE: 'Prado de la Guzpeña',
	},
	{
		CPRO: '24',
		CMUN: '119',
		DC: '0',
		NOMBRE: 'Priaranza del Bierzo',
	},
	{
		CPRO: '24',
		CMUN: '120',
		DC: '4',
		NOMBRE: 'Prioro',
	},
	{
		CPRO: '24',
		CMUN: '121',
		DC: '1',
		NOMBRE: 'Puebla de Lillo',
	},
	{
		CPRO: '24',
		CMUN: '122',
		DC: '6',
		NOMBRE: 'Puente de Domingo Flórez',
	},
	{
		CPRO: '24',
		CMUN: '123',
		DC: '2',
		NOMBRE: 'Quintana del Castillo',
	},
	{
		CPRO: '24',
		CMUN: '124',
		DC: '7',
		NOMBRE: 'Quintana del Marco',
	},
	{
		CPRO: '24',
		CMUN: '125',
		DC: '0',
		NOMBRE: 'Quintana y Congosto',
	},
	{
		CPRO: '24',
		CMUN: '127',
		DC: '9',
		NOMBRE: 'Regueras de Arriba',
	},
	{
		CPRO: '24',
		CMUN: '129',
		DC: '8',
		NOMBRE: 'Reyero',
	},
	{
		CPRO: '24',
		CMUN: '130',
		DC: '2',
		NOMBRE: 'Riaño',
	},
	{
		CPRO: '24',
		CMUN: '131',
		DC: '9',
		NOMBRE: 'Riego de la Vega',
	},
	{
		CPRO: '24',
		CMUN: '132',
		DC: '4',
		NOMBRE: 'Riello',
	},
	{
		CPRO: '24',
		CMUN: '133',
		DC: '0',
		NOMBRE: 'Rioseco de Tapia',
	},
	{
		CPRO: '24',
		CMUN: '134',
		DC: '5',
		NOMBRE: 'Robla, La',
	},
	{
		CPRO: '24',
		CMUN: '136',
		DC: '1',
		NOMBRE: 'Roperuelos del Páramo',
	},
	{
		CPRO: '24',
		CMUN: '137',
		DC: '7',
		NOMBRE: 'Sabero',
	},
	{
		CPRO: '24',
		CMUN: '139',
		DC: '6',
		NOMBRE: 'Sahagún',
	},
	{
		CPRO: '24',
		CMUN: '141',
		DC: '7',
		NOMBRE: 'San Adrián del Valle',
	},
	{
		CPRO: '24',
		CMUN: '142',
		DC: '2',
		NOMBRE: 'San Andrés del Rabanedo',
	},
	{
		CPRO: '24',
		CMUN: '144',
		DC: '3',
		NOMBRE: 'San Cristóbal de la Polantera',
	},
	{
		CPRO: '24',
		CMUN: '145',
		DC: '6',
		NOMBRE: 'San Emiliano',
	},
	{
		CPRO: '24',
		CMUN: '146',
		DC: '9',
		NOMBRE: 'San Esteban de Nogales',
	},
	{
		CPRO: '24',
		CMUN: '148',
		DC: '1',
		NOMBRE: 'San Justo de la Vega',
	},
	{
		CPRO: '24',
		CMUN: '149',
		DC: '4',
		NOMBRE: 'San Millán de los Caballeros',
	},
	{
		CPRO: '24',
		CMUN: '150',
		DC: '7',
		NOMBRE: 'San Pedro Bercianos',
	},
	{
		CPRO: '24',
		CMUN: '143',
		DC: '8',
		NOMBRE: 'Sancedo',
	},
	{
		CPRO: '24',
		CMUN: '151',
		DC: '4',
		NOMBRE: 'Santa Colomba de Curueño',
	},
	{
		CPRO: '24',
		CMUN: '152',
		DC: '9',
		NOMBRE: 'Santa Colomba de Somoza',
	},
	{
		CPRO: '24',
		CMUN: '153',
		DC: '5',
		NOMBRE: 'Santa Cristina de Valmadrigal',
	},
	{
		CPRO: '24',
		CMUN: '154',
		DC: '0',
		NOMBRE: 'Santa Elena de Jamuz',
	},
	{
		CPRO: '24',
		CMUN: '155',
		DC: '3',
		NOMBRE: 'Santa María de la Isla',
	},
	{
		CPRO: '24',
		CMUN: '158',
		DC: '8',
		NOMBRE: 'Santa María de Ordás',
	},
	{
		CPRO: '24',
		CMUN: '156',
		DC: '6',
		NOMBRE: 'Santa María del Monte de Cea',
	},
	{
		CPRO: '24',
		CMUN: '157',
		DC: '2',
		NOMBRE: 'Santa María del Páramo',
	},
	{
		CPRO: '24',
		CMUN: '159',
		DC: '1',
		NOMBRE: 'Santa Marina del Rey',
	},
	{
		CPRO: '24',
		CMUN: '160',
		DC: '5',
		NOMBRE: 'Santas Martas',
	},
	{
		CPRO: '24',
		CMUN: '161',
		DC: '2',
		NOMBRE: 'Santiago Millas',
	},
	{
		CPRO: '24',
		CMUN: '162',
		DC: '7',
		NOMBRE: 'Santovenia de la Valdoncina',
	},
	{
		CPRO: '24',
		CMUN: '163',
		DC: '3',
		NOMBRE: 'Sariegos',
	},
	{
		CPRO: '24',
		CMUN: '164',
		DC: '8',
		NOMBRE: 'Sena de Luna',
	},
	{
		CPRO: '24',
		CMUN: '165',
		DC: '1',
		NOMBRE: 'Sobrado',
	},
	{
		CPRO: '24',
		CMUN: '166',
		DC: '4',
		NOMBRE: 'Soto de la Vega',
	},
	{
		CPRO: '24',
		CMUN: '167',
		DC: '0',
		NOMBRE: 'Soto y Amío',
	},
	{
		CPRO: '24',
		CMUN: '168',
		DC: '6',
		NOMBRE: 'Toral de los Guzmanes',
	},
	{
		CPRO: '24',
		CMUN: '206',
		DC: '6',
		NOMBRE: 'Toral de los Vados',
	},
	{
		CPRO: '24',
		CMUN: '169',
		DC: '9',
		NOMBRE: 'Toreno',
	},
	{
		CPRO: '24',
		CMUN: '170',
		DC: '3',
		NOMBRE: 'Torre del Bierzo',
	},
	{
		CPRO: '24',
		CMUN: '171',
		DC: '0',
		NOMBRE: 'Trabadelo',
	},
	{
		CPRO: '24',
		CMUN: '172',
		DC: '5',
		NOMBRE: 'Truchas',
	},
	{
		CPRO: '24',
		CMUN: '173',
		DC: '1',
		NOMBRE: 'Turcia',
	},
	{
		CPRO: '24',
		CMUN: '174',
		DC: '6',
		NOMBRE: 'Urdiales del Páramo',
	},
	{
		CPRO: '24',
		CMUN: '185',
		DC: '7',
		NOMBRE: 'Val de San Lorenzo',
	},
	{
		CPRO: '24',
		CMUN: '175',
		DC: '9',
		NOMBRE: 'Valdefresno',
	},
	{
		CPRO: '24',
		CMUN: '176',
		DC: '2',
		NOMBRE: 'Valdefuentes del Páramo',
	},
	{
		CPRO: '24',
		CMUN: '177',
		DC: '8',
		NOMBRE: 'Valdelugueros',
	},
	{
		CPRO: '24',
		CMUN: '178',
		DC: '4',
		NOMBRE: 'Valdemora',
	},
	{
		CPRO: '24',
		CMUN: '179',
		DC: '7',
		NOMBRE: 'Valdepiélago',
	},
	{
		CPRO: '24',
		CMUN: '180',
		DC: '1',
		NOMBRE: 'Valdepolo',
	},
	{
		CPRO: '24',
		CMUN: '181',
		DC: '8',
		NOMBRE: 'Valderas',
	},
	{
		CPRO: '24',
		CMUN: '182',
		DC: '3',
		NOMBRE: 'Valderrey',
	},
	{
		CPRO: '24',
		CMUN: '183',
		DC: '9',
		NOMBRE: 'Valderrueda',
	},
	{
		CPRO: '24',
		CMUN: '184',
		DC: '4',
		NOMBRE: 'Valdesamario',
	},
	{
		CPRO: '24',
		CMUN: '187',
		DC: '6',
		NOMBRE: 'Valdevimbre',
	},
	{
		CPRO: '24',
		CMUN: '188',
		DC: '2',
		NOMBRE: 'Valencia de Don Juan',
	},
	{
		CPRO: '24',
		CMUN: '191',
		DC: '6',
		NOMBRE: 'Vallecillo',
	},
	{
		CPRO: '24',
		CMUN: '189',
		DC: '5',
		NOMBRE: 'Valverde de la Virgen',
	},
	{
		CPRO: '24',
		CMUN: '190',
		DC: '9',
		NOMBRE: 'Valverde-Enrique',
	},
	{
		CPRO: '24',
		CMUN: '193',
		DC: '7',
		NOMBRE: 'Vecilla, La',
	},
	{
		CPRO: '24',
		CMUN: '196',
		DC: '8',
		NOMBRE: 'Vega de Espinareda',
	},
	{
		CPRO: '24',
		CMUN: '197',
		DC: '4',
		NOMBRE: 'Vega de Infanzones',
	},
	{
		CPRO: '24',
		CMUN: '198',
		DC: '0',
		NOMBRE: 'Vega de Valcarce',
	},
	{
		CPRO: '24',
		CMUN: '194',
		DC: '2',
		NOMBRE: 'Vegacervera',
	},
	{
		CPRO: '24',
		CMUN: '199',
		DC: '3',
		NOMBRE: 'Vegaquemada',
	},
	{
		CPRO: '24',
		CMUN: '201',
		DC: '4',
		NOMBRE: 'Vegas del Condado',
	},
	{
		CPRO: '24',
		CMUN: '202',
		DC: '9',
		NOMBRE: 'Villablino',
	},
	{
		CPRO: '24',
		CMUN: '203',
		DC: '5',
		NOMBRE: 'Villabraz',
	},
	{
		CPRO: '24',
		CMUN: '205',
		DC: '3',
		NOMBRE: 'Villadangos del Páramo',
	},
	{
		CPRO: '24',
		CMUN: '207',
		DC: '2',
		NOMBRE: 'Villademor de la Vega',
	},
	{
		CPRO: '24',
		CMUN: '209',
		DC: '1',
		NOMBRE: 'Villafranca del Bierzo',
	},
	{
		CPRO: '24',
		CMUN: '210',
		DC: '5',
		NOMBRE: 'Villagatón',
	},
	{
		CPRO: '24',
		CMUN: '211',
		DC: '2',
		NOMBRE: 'Villamandos',
	},
	{
		CPRO: '24',
		CMUN: '901',
		DC: '7',
		NOMBRE: 'Villamanín',
	},
	{
		CPRO: '24',
		CMUN: '212',
		DC: '7',
		NOMBRE: 'Villamañán',
	},
	{
		CPRO: '24',
		CMUN: '213',
		DC: '3',
		NOMBRE: 'Villamartín de Don Sancho',
	},
	{
		CPRO: '24',
		CMUN: '214',
		DC: '8',
		NOMBRE: 'Villamejil',
	},
	{
		CPRO: '24',
		CMUN: '215',
		DC: '1',
		NOMBRE: 'Villamol',
	},
	{
		CPRO: '24',
		CMUN: '216',
		DC: '4',
		NOMBRE: 'Villamontán de la Valduerna',
	},
	{
		CPRO: '24',
		CMUN: '217',
		DC: '0',
		NOMBRE: 'Villamoratiel de las Matas',
	},
	{
		CPRO: '24',
		CMUN: '218',
		DC: '6',
		NOMBRE: 'Villanueva de las Manzanas',
	},
	{
		CPRO: '24',
		CMUN: '219',
		DC: '9',
		NOMBRE: 'Villaobispo de Otero',
	},
	{
		CPRO: '24',
		CMUN: '902',
		DC: '2',
		NOMBRE: 'Villaornate y Castro',
	},
	{
		CPRO: '24',
		CMUN: '221',
		DC: '0',
		NOMBRE: 'Villaquejida',
	},
	{
		CPRO: '24',
		CMUN: '222',
		DC: '5',
		NOMBRE: 'Villaquilambre',
	},
	{
		CPRO: '24',
		CMUN: '223',
		DC: '1',
		NOMBRE: 'Villarejo de Órbigo',
	},
	{
		CPRO: '24',
		CMUN: '224',
		DC: '6',
		NOMBRE: 'Villares de Órbigo',
	},
	{
		CPRO: '24',
		CMUN: '225',
		DC: '9',
		NOMBRE: 'Villasabariego',
	},
	{
		CPRO: '24',
		CMUN: '226',
		DC: '2',
		NOMBRE: 'Villaselán',
	},
	{
		CPRO: '24',
		CMUN: '227',
		DC: '8',
		NOMBRE: 'Villaturiel',
	},
	{
		CPRO: '24',
		CMUN: '228',
		DC: '4',
		NOMBRE: 'Villazala',
	},
	{
		CPRO: '24',
		CMUN: '229',
		DC: '7',
		NOMBRE: 'Villazanzo de Valderaduey',
	},
	{
		CPRO: '24',
		CMUN: '230',
		DC: '1',
		NOMBRE: 'Zotes del Páramo',
	},
	{
		CPRO: '25',
		CMUN: '001',
		DC: '9',
		NOMBRE: 'Abella de la Conca',
	},
	{
		CPRO: '25',
		CMUN: '002',
		DC: '4',
		NOMBRE: 'Àger',
	},
	{
		CPRO: '25',
		CMUN: '003',
		DC: '0',
		NOMBRE: 'Agramunt',
	},
	{
		CPRO: '25',
		CMUN: '038',
		DC: '7',
		NOMBRE: 'Aitona',
	},
	{
		CPRO: '25',
		CMUN: '004',
		DC: '5',
		NOMBRE: 'Alamús, Els',
	},
	{
		CPRO: '25',
		CMUN: '005',
		DC: '8',
		NOMBRE: 'Alàs i Cerc',
	},
	{
		CPRO: '25',
		CMUN: '006',
		DC: '1',
		NOMBRE: "Albagés, L'",
	},
	{
		CPRO: '25',
		CMUN: '007',
		DC: '7',
		NOMBRE: 'Albatàrrec',
	},
	{
		CPRO: '25',
		CMUN: '008',
		DC: '3',
		NOMBRE: 'Albesa',
	},
	{
		CPRO: '25',
		CMUN: '009',
		DC: '6',
		NOMBRE: "Albi, L'",
	},
	{
		CPRO: '25',
		CMUN: '010',
		DC: '0',
		NOMBRE: 'Alcanó',
	},
	{
		CPRO: '25',
		CMUN: '011',
		DC: '7',
		NOMBRE: 'Alcarràs',
	},
	{
		CPRO: '25',
		CMUN: '012',
		DC: '2',
		NOMBRE: 'Alcoletge',
	},
	{
		CPRO: '25',
		CMUN: '013',
		DC: '8',
		NOMBRE: 'Alfarràs',
	},
	{
		CPRO: '25',
		CMUN: '014',
		DC: '3',
		NOMBRE: 'Alfés',
	},
	{
		CPRO: '25',
		CMUN: '015',
		DC: '6',
		NOMBRE: 'Algerri',
	},
	{
		CPRO: '25',
		CMUN: '016',
		DC: '9',
		NOMBRE: 'Alguaire',
	},
	{
		CPRO: '25',
		CMUN: '017',
		DC: '5',
		NOMBRE: 'Alins',
	},
	{
		CPRO: '25',
		CMUN: '019',
		DC: '4',
		NOMBRE: 'Almacelles',
	},
	{
		CPRO: '25',
		CMUN: '020',
		DC: '8',
		NOMBRE: 'Almatret',
	},
	{
		CPRO: '25',
		CMUN: '021',
		DC: '5',
		NOMBRE: 'Almenar',
	},
	{
		CPRO: '25',
		CMUN: '022',
		DC: '0',
		NOMBRE: 'Alòs de Balaguer',
	},
	{
		CPRO: '25',
		CMUN: '023',
		DC: '6',
		NOMBRE: 'Alpicat',
	},
	{
		CPRO: '25',
		CMUN: '024',
		DC: '1',
		NOMBRE: 'Alt Àneu',
	},
	{
		CPRO: '25',
		CMUN: '027',
		DC: '3',
		NOMBRE: 'Anglesola',
	},
	{
		CPRO: '25',
		CMUN: '029',
		DC: '2',
		NOMBRE: 'Arbeca',
	},
	{
		CPRO: '25',
		CMUN: '031',
		DC: '3',
		NOMBRE: 'Arres',
	},
	{
		CPRO: '25',
		CMUN: '032',
		DC: '8',
		NOMBRE: 'Arsèguel',
	},
	{
		CPRO: '25',
		CMUN: '033',
		DC: '4',
		NOMBRE: 'Artesa de Lleida',
	},
	{
		CPRO: '25',
		CMUN: '034',
		DC: '9',
		NOMBRE: 'Artesa de Segre',
	},
	{
		CPRO: '25',
		CMUN: '036',
		DC: '5',
		NOMBRE: 'Aspa',
	},
	{
		CPRO: '25',
		CMUN: '037',
		DC: '1',
		NOMBRE: 'Avellanes i Santa Linya, Les',
	},
	{
		CPRO: '25',
		CMUN: '039',
		DC: '0',
		NOMBRE: 'Baix Pallars',
	},
	{
		CPRO: '25',
		CMUN: '040',
		DC: '4',
		NOMBRE: 'Balaguer',
	},
	{
		CPRO: '25',
		CMUN: '041',
		DC: '1',
		NOMBRE: 'Barbens',
	},
	{
		CPRO: '25',
		CMUN: '042',
		DC: '6',
		NOMBRE: 'Baronia de Rialb, La',
	},
	{
		CPRO: '25',
		CMUN: '044',
		DC: '7',
		NOMBRE: 'Bassella',
	},
	{
		CPRO: '25',
		CMUN: '045',
		DC: '0',
		NOMBRE: 'Bausen',
	},
	{
		CPRO: '25',
		CMUN: '046',
		DC: '3',
		NOMBRE: 'Belianes',
	},
	{
		CPRO: '25',
		CMUN: '170',
		DC: '6',
		NOMBRE: 'Bellaguarda',
	},
	{
		CPRO: '25',
		CMUN: '047',
		DC: '9',
		NOMBRE: "Bellcaire d'Urgell",
	},
	{
		CPRO: '25',
		CMUN: '048',
		DC: '5',
		NOMBRE: "Bell-lloc d'Urgell",
	},
	{
		CPRO: '25',
		CMUN: '049',
		DC: '8',
		NOMBRE: "Bellmunt d'Urgell",
	},
	{
		CPRO: '25',
		CMUN: '050',
		DC: '1',
		NOMBRE: 'Bellpuig',
	},
	{
		CPRO: '25',
		CMUN: '051',
		DC: '8',
		NOMBRE: 'Bellver de Cerdanya',
	},
	{
		CPRO: '25',
		CMUN: '052',
		DC: '3',
		NOMBRE: 'Bellvís',
	},
	{
		CPRO: '25',
		CMUN: '053',
		DC: '9',
		NOMBRE: 'Benavent de Segrià',
	},
	{
		CPRO: '25',
		CMUN: '055',
		DC: '7',
		NOMBRE: 'Biosca',
	},
	{
		CPRO: '25',
		CMUN: '057',
		DC: '6',
		NOMBRE: 'Bòrdes, Es',
	},
	{
		CPRO: '25',
		CMUN: '058',
		DC: '2',
		NOMBRE: 'Borges Blanques, Les',
	},
	{
		CPRO: '25',
		CMUN: '059',
		DC: '5',
		NOMBRE: 'Bossòst',
	},
	{
		CPRO: '25',
		CMUN: '056',
		DC: '0',
		NOMBRE: 'Bovera',
	},
	{
		CPRO: '25',
		CMUN: '060',
		DC: '9',
		NOMBRE: 'Cabanabona',
	},
	{
		CPRO: '25',
		CMUN: '061',
		DC: '6',
		NOMBRE: 'Cabó',
	},
	{
		CPRO: '25',
		CMUN: '062',
		DC: '1',
		NOMBRE: 'Camarasa',
	},
	{
		CPRO: '25',
		CMUN: '063',
		DC: '7',
		NOMBRE: 'Canejan',
	},
	{
		CPRO: '25',
		CMUN: '904',
		DC: '6',
		NOMBRE: 'Castell de Mur',
	},
	{
		CPRO: '25',
		CMUN: '064',
		DC: '2',
		NOMBRE: 'Castellar de la Ribera',
	},
	{
		CPRO: '25',
		CMUN: '067',
		DC: '4',
		NOMBRE: 'Castelldans',
	},
	{
		CPRO: '25',
		CMUN: '068',
		DC: '0',
		NOMBRE: 'Castellnou de Seana',
	},
	{
		CPRO: '25',
		CMUN: '069',
		DC: '3',
		NOMBRE: 'Castelló de Farfanya',
	},
	{
		CPRO: '25',
		CMUN: '070',
		DC: '7',
		NOMBRE: 'Castellserà',
	},
	{
		CPRO: '25',
		CMUN: '071',
		DC: '4',
		NOMBRE: 'Cava',
	},
	{
		CPRO: '25',
		CMUN: '072',
		DC: '9',
		NOMBRE: 'Cervera',
	},
	{
		CPRO: '25',
		CMUN: '073',
		DC: '5',
		NOMBRE: 'Cervià de les Garrigues',
	},
	{
		CPRO: '25',
		CMUN: '074',
		DC: '0',
		NOMBRE: 'Ciutadilla',
	},
	{
		CPRO: '25',
		CMUN: '075',
		DC: '3',
		NOMBRE: 'Clariana de Cardener',
	},
	{
		CPRO: '25',
		CMUN: '076',
		DC: '6',
		NOMBRE: 'Cogul, El',
	},
	{
		CPRO: '25',
		CMUN: '077',
		DC: '2',
		NOMBRE: 'Coll de Nargó',
	},
	{
		CPRO: '25',
		CMUN: '163',
		DC: '6',
		NOMBRE: 'Coma i la Pedra, La',
	},
	{
		CPRO: '25',
		CMUN: '161',
		DC: '5',
		NOMBRE: 'Conca de Dalt',
	},
	{
		CPRO: '25',
		CMUN: '078',
		DC: '8',
		NOMBRE: 'Corbins',
	},
	{
		CPRO: '25',
		CMUN: '079',
		DC: '1',
		NOMBRE: 'Cubells',
	},
	{
		CPRO: '25',
		CMUN: '081',
		DC: '2',
		NOMBRE: "Espluga Calba, L'",
	},
	{
		CPRO: '25',
		CMUN: '082',
		DC: '7',
		NOMBRE: 'Espot',
	},
	{
		CPRO: '25',
		CMUN: '088',
		DC: '6',
		NOMBRE: 'Estamariu',
	},
	{
		CPRO: '25',
		CMUN: '085',
		DC: '1',
		NOMBRE: 'Estaràs',
	},
	{
		CPRO: '25',
		CMUN: '086',
		DC: '4',
		NOMBRE: "Esterri d'Àneu",
	},
	{
		CPRO: '25',
		CMUN: '087',
		DC: '0',
		NOMBRE: 'Esterri de Cardós',
	},
	{
		CPRO: '25',
		CMUN: '089',
		DC: '9',
		NOMBRE: 'Farrera',
	},
	{
		CPRO: '25',
		CMUN: '908',
		DC: '4',
		NOMBRE: 'Fígols i Alinyà',
	},
	{
		CPRO: '25',
		CMUN: '092',
		DC: '5',
		NOMBRE: 'Floresta, La',
	},
	{
		CPRO: '25',
		CMUN: '093',
		DC: '1',
		NOMBRE: 'Fondarella',
	},
	{
		CPRO: '25',
		CMUN: '094',
		DC: '6',
		NOMBRE: 'Foradada',
	},
	{
		CPRO: '25',
		CMUN: '096',
		DC: '2',
		NOMBRE: 'Fuliola, La',
	},
	{
		CPRO: '25',
		CMUN: '097',
		DC: '8',
		NOMBRE: 'Fulleda',
	},
	{
		CPRO: '25',
		CMUN: '098',
		DC: '4',
		NOMBRE: 'Gavet de la Conca',
	},
	{
		CPRO: '25',
		CMUN: '912',
		DC: '3',
		NOMBRE: 'Gimenells i el Pla de la Font',
	},
	{
		CPRO: '25',
		CMUN: '099',
		DC: '7',
		NOMBRE: 'Golmés',
	},
	{
		CPRO: '25',
		CMUN: '100',
		DC: '1',
		NOMBRE: 'Gósol',
	},
	{
		CPRO: '25',
		CMUN: '101',
		DC: '8',
		NOMBRE: 'Granadella, La',
	},
	{
		CPRO: '25',
		CMUN: '102',
		DC: '3',
		NOMBRE: "Granja d'Escarp, La",
	},
	{
		CPRO: '25',
		CMUN: '103',
		DC: '9',
		NOMBRE: 'Granyanella',
	},
	{
		CPRO: '25',
		CMUN: '105',
		DC: '7',
		NOMBRE: 'Granyena de les Garrigues',
	},
	{
		CPRO: '25',
		CMUN: '104',
		DC: '4',
		NOMBRE: 'Granyena de Segarra',
	},
	{
		CPRO: '25',
		CMUN: '109',
		DC: '5',
		NOMBRE: 'Guimerà',
	},
	{
		CPRO: '25',
		CMUN: '903',
		DC: '1',
		NOMBRE: "Guingueta d'Àneu, La",
	},
	{
		CPRO: '25',
		CMUN: '110',
		DC: '9',
		NOMBRE: 'Guissona',
	},
	{
		CPRO: '25',
		CMUN: '111',
		DC: '6',
		NOMBRE: 'Guixers',
	},
	{
		CPRO: '25',
		CMUN: '115',
		DC: '5',
		NOMBRE: 'Isona i Conca Dellà',
	},
	{
		CPRO: '25',
		CMUN: '112',
		DC: '1',
		NOMBRE: 'Ivars de Noguera',
	},
	{
		CPRO: '25',
		CMUN: '113',
		DC: '7',
		NOMBRE: "Ivars d'Urgell",
	},
	{
		CPRO: '25',
		CMUN: '114',
		DC: '2',
		NOMBRE: 'Ivorra',
	},
	{
		CPRO: '25',
		CMUN: '910',
		DC: '1',
		NOMBRE: 'Josa i Tuixén',
	},
	{
		CPRO: '25',
		CMUN: '118',
		DC: '0',
		NOMBRE: 'Juncosa',
	},
	{
		CPRO: '25',
		CMUN: '119',
		DC: '3',
		NOMBRE: 'Juneda',
	},
	{
		CPRO: '25',
		CMUN: '121',
		DC: '4',
		NOMBRE: 'Les',
	},
	{
		CPRO: '25',
		CMUN: '122',
		DC: '9',
		NOMBRE: 'Linyola',
	},
	{
		CPRO: '25',
		CMUN: '123',
		DC: '5',
		NOMBRE: 'Lladorre',
	},
	{
		CPRO: '25',
		CMUN: '124',
		DC: '0',
		NOMBRE: 'Lladurs',
	},
	{
		CPRO: '25',
		CMUN: '125',
		DC: '3',
		NOMBRE: 'Llardecans',
	},
	{
		CPRO: '25',
		CMUN: '126',
		DC: '6',
		NOMBRE: 'Llavorsí',
	},
	{
		CPRO: '25',
		CMUN: '120',
		DC: '7',
		NOMBRE: 'Lleida',
	},
	{
		CPRO: '25',
		CMUN: '127',
		DC: '2',
		NOMBRE: 'Lles de Cerdanya',
	},
	{
		CPRO: '25',
		CMUN: '128',
		DC: '8',
		NOMBRE: 'Llimiana',
	},
	{
		CPRO: '25',
		CMUN: '129',
		DC: '1',
		NOMBRE: 'Llobera',
	},
	{
		CPRO: '25',
		CMUN: '133',
		DC: '3',
		NOMBRE: 'Maials',
	},
	{
		CPRO: '25',
		CMUN: '130',
		DC: '5',
		NOMBRE: 'Maldà',
	},
	{
		CPRO: '25',
		CMUN: '131',
		DC: '2',
		NOMBRE: 'Massalcoreig',
	},
	{
		CPRO: '25',
		CMUN: '132',
		DC: '7',
		NOMBRE: 'Massoteres',
	},
	{
		CPRO: '25',
		CMUN: '134',
		DC: '8',
		NOMBRE: 'Menàrguens',
	},
	{
		CPRO: '25',
		CMUN: '135',
		DC: '1',
		NOMBRE: 'Miralcamp',
	},
	{
		CPRO: '25',
		CMUN: '137',
		DC: '0',
		NOMBRE: 'Mollerussa',
	},
	{
		CPRO: '25',
		CMUN: '136',
		DC: '4',
		NOMBRE: 'Molsosa, La',
	},
	{
		CPRO: '25',
		CMUN: '139',
		DC: '9',
		NOMBRE: 'Montellà i Martinet',
	},
	{
		CPRO: '25',
		CMUN: '140',
		DC: '3',
		NOMBRE: 'Montferrer i Castellbò',
	},
	{
		CPRO: '25',
		CMUN: '138',
		DC: '6',
		NOMBRE: 'Montgai',
	},
	{
		CPRO: '25',
		CMUN: '142',
		DC: '5',
		NOMBRE: 'Montoliu de Lleida',
	},
	{
		CPRO: '25',
		CMUN: '141',
		DC: '0',
		NOMBRE: 'Montoliu de Segarra',
	},
	{
		CPRO: '25',
		CMUN: '143',
		DC: '1',
		NOMBRE: 'Montornès de Segarra',
	},
	{
		CPRO: '25',
		CMUN: '145',
		DC: '9',
		NOMBRE: 'Nalec',
	},
	{
		CPRO: '25',
		CMUN: '025',
		DC: '4',
		NOMBRE: 'Naut Aran',
	},
	{
		CPRO: '25',
		CMUN: '146',
		DC: '2',
		NOMBRE: 'Navès',
	},
	{
		CPRO: '25',
		CMUN: '148',
		DC: '4',
		NOMBRE: 'Odèn',
	},
	{
		CPRO: '25',
		CMUN: '149',
		DC: '7',
		NOMBRE: 'Oliana',
	},
	{
		CPRO: '25',
		CMUN: '150',
		DC: '0',
		NOMBRE: 'Oliola',
	},
	{
		CPRO: '25',
		CMUN: '151',
		DC: '7',
		NOMBRE: 'Olius',
	},
	{
		CPRO: '25',
		CMUN: '152',
		DC: '2',
		NOMBRE: 'Oluges, Les',
	},
	{
		CPRO: '25',
		CMUN: '153',
		DC: '8',
		NOMBRE: 'Omellons, Els',
	},
	{
		CPRO: '25',
		CMUN: '154',
		DC: '3',
		NOMBRE: 'Omells de na Gaia, Els',
	},
	{
		CPRO: '25',
		CMUN: '155',
		DC: '6',
		NOMBRE: 'Organyà',
	},
	{
		CPRO: '25',
		CMUN: '156',
		DC: '9',
		NOMBRE: 'Os de Balaguer',
	},
	{
		CPRO: '25',
		CMUN: '157',
		DC: '5',
		NOMBRE: 'Ossó de Sió',
	},
	{
		CPRO: '25',
		CMUN: '158',
		DC: '1',
		NOMBRE: "Palau d'Anglesola, El",
	},
	{
		CPRO: '25',
		CMUN: '164',
		DC: '1',
		NOMBRE: 'Penelles',
	},
	{
		CPRO: '25',
		CMUN: '165',
		DC: '4',
		NOMBRE: 'Peramola',
	},
	{
		CPRO: '25',
		CMUN: '166',
		DC: '7',
		NOMBRE: 'Pinell de Solsonès',
	},
	{
		CPRO: '25',
		CMUN: '167',
		DC: '3',
		NOMBRE: 'Pinós',
	},
	{
		CPRO: '25',
		CMUN: '911',
		DC: '8',
		NOMBRE: 'Plans de Sió, Els',
	},
	{
		CPRO: '25',
		CMUN: '168',
		DC: '9',
		NOMBRE: 'Poal, El',
	},
	{
		CPRO: '25',
		CMUN: '169',
		DC: '2',
		NOMBRE: 'Pobla de Cérvoles, La',
	},
	{
		CPRO: '25',
		CMUN: '171',
		DC: '3',
		NOMBRE: 'Pobla de Segur, La',
	},
	{
		CPRO: '25',
		CMUN: '030',
		DC: '6',
		NOMBRE: 'Pont de Bar, El',
	},
	{
		CPRO: '25',
		CMUN: '173',
		DC: '4',
		NOMBRE: 'Pont de Suert, El',
	},
	{
		CPRO: '25',
		CMUN: '172',
		DC: '8',
		NOMBRE: 'Ponts',
	},
	{
		CPRO: '25',
		CMUN: '174',
		DC: '9',
		NOMBRE: 'Portella, La',
	},
	{
		CPRO: '25',
		CMUN: '175',
		DC: '2',
		NOMBRE: 'Prats i Sansor',
	},
	{
		CPRO: '25',
		CMUN: '176',
		DC: '5',
		NOMBRE: 'Preixana',
	},
	{
		CPRO: '25',
		CMUN: '177',
		DC: '1',
		NOMBRE: 'Preixens',
	},
	{
		CPRO: '25',
		CMUN: '179',
		DC: '0',
		NOMBRE: 'Prullans',
	},
	{
		CPRO: '25',
		CMUN: '180',
		DC: '4',
		NOMBRE: 'Puiggròs',
	},
	{
		CPRO: '25',
		CMUN: '181',
		DC: '1',
		NOMBRE: "Puigverd d'Agramunt",
	},
	{
		CPRO: '25',
		CMUN: '182',
		DC: '6',
		NOMBRE: 'Puigverd de Lleida',
	},
	{
		CPRO: '25',
		CMUN: '183',
		DC: '2',
		NOMBRE: 'Rialp',
	},
	{
		CPRO: '25',
		CMUN: '905',
		DC: '9',
		NOMBRE: "Ribera d'Ondara",
	},
	{
		CPRO: '25',
		CMUN: '185',
		DC: '0',
		NOMBRE: "Ribera d'Urgellet",
	},
	{
		CPRO: '25',
		CMUN: '186',
		DC: '3',
		NOMBRE: 'Riner',
	},
	{
		CPRO: '25',
		CMUN: '913',
		DC: '9',
		NOMBRE: 'Riu de Cerdanya',
	},
	{
		CPRO: '25',
		CMUN: '189',
		DC: '8',
		NOMBRE: 'Rosselló',
	},
	{
		CPRO: '25',
		CMUN: '190',
		DC: '2',
		NOMBRE: 'Salàs de Pallars',
	},
	{
		CPRO: '25',
		CMUN: '191',
		DC: '9',
		NOMBRE: 'Sanaüja',
	},
	{
		CPRO: '25',
		CMUN: '196',
		DC: '1',
		NOMBRE: 'Sant Esteve de la Sarga',
	},
	{
		CPRO: '25',
		CMUN: '192',
		DC: '4',
		NOMBRE: 'Sant Guim de Freixenet',
	},
	{
		CPRO: '25',
		CMUN: '197',
		DC: '7',
		NOMBRE: 'Sant Guim de la Plana',
	},
	{
		CPRO: '25',
		CMUN: '193',
		DC: '0',
		NOMBRE: 'Sant Llorenç de Morunys',
	},
	{
		CPRO: '25',
		CMUN: '902',
		DC: '5',
		NOMBRE: 'Sant Martí de Riucorb',
	},
	{
		CPRO: '25',
		CMUN: '194',
		DC: '5',
		NOMBRE: 'Sant Ramon',
	},
	{
		CPRO: '25',
		CMUN: '201',
		DC: '7',
		NOMBRE: 'Sarroca de Bellera',
	},
	{
		CPRO: '25',
		CMUN: '200',
		DC: '0',
		NOMBRE: 'Sarroca de Lleida',
	},
	{
		CPRO: '25',
		CMUN: '202',
		DC: '2',
		NOMBRE: 'Senterada',
	},
	{
		CPRO: '25',
		CMUN: '035',
		DC: '2',
		NOMBRE: 'Sentiu de Sió, La',
	},
	{
		CPRO: '25',
		CMUN: '204',
		DC: '3',
		NOMBRE: 'Seròs',
	},
	{
		CPRO: '25',
		CMUN: '203',
		DC: '8',
		NOMBRE: "Seu d'Urgell, La",
	},
	{
		CPRO: '25',
		CMUN: '205',
		DC: '6',
		NOMBRE: 'Sidamon',
	},
	{
		CPRO: '25',
		CMUN: '206',
		DC: '9',
		NOMBRE: 'Soleràs, El',
	},
	{
		CPRO: '25',
		CMUN: '207',
		DC: '5',
		NOMBRE: 'Solsona',
	},
	{
		CPRO: '25',
		CMUN: '208',
		DC: '1',
		NOMBRE: 'Soriguera',
	},
	{
		CPRO: '25',
		CMUN: '209',
		DC: '4',
		NOMBRE: 'Sort',
	},
	{
		CPRO: '25',
		CMUN: '210',
		DC: '8',
		NOMBRE: 'Soses',
	},
	{
		CPRO: '25',
		CMUN: '211',
		DC: '5',
		NOMBRE: 'Sudanell',
	},
	{
		CPRO: '25',
		CMUN: '212',
		DC: '0',
		NOMBRE: 'Sunyer',
	},
	{
		CPRO: '25',
		CMUN: '215',
		DC: '4',
		NOMBRE: 'Talarn',
	},
	{
		CPRO: '25',
		CMUN: '216',
		DC: '7',
		NOMBRE: 'Talavera',
	},
	{
		CPRO: '25',
		CMUN: '217',
		DC: '3',
		NOMBRE: 'Tàrrega',
	},
	{
		CPRO: '25',
		CMUN: '218',
		DC: '9',
		NOMBRE: 'Tarrés',
	},
	{
		CPRO: '25',
		CMUN: '219',
		DC: '2',
		NOMBRE: 'Tarroja de Segarra',
	},
	{
		CPRO: '25',
		CMUN: '220',
		DC: '6',
		NOMBRE: 'Térmens',
	},
	{
		CPRO: '25',
		CMUN: '221',
		DC: '3',
		NOMBRE: 'Tírvia',
	},
	{
		CPRO: '25',
		CMUN: '222',
		DC: '8',
		NOMBRE: 'Tiurana',
	},
	{
		CPRO: '25',
		CMUN: '223',
		DC: '4',
		NOMBRE: 'Torà',
	},
	{
		CPRO: '25',
		CMUN: '224',
		DC: '9',
		NOMBRE: 'Torms, Els',
	},
	{
		CPRO: '25',
		CMUN: '225',
		DC: '2',
		NOMBRE: 'Tornabous',
	},
	{
		CPRO: '25',
		CMUN: '227',
		DC: '1',
		NOMBRE: 'Torre de Cabdella, La',
	},
	{
		CPRO: '25',
		CMUN: '226',
		DC: '5',
		NOMBRE: 'Torrebesses',
	},
	{
		CPRO: '25',
		CMUN: '228',
		DC: '7',
		NOMBRE: 'Torrefarrera',
	},
	{
		CPRO: '25',
		CMUN: '907',
		DC: '8',
		NOMBRE: 'Torrefeta i Florejacs',
	},
	{
		CPRO: '25',
		CMUN: '230',
		DC: '4',
		NOMBRE: 'Torregrossa',
	},
	{
		CPRO: '25',
		CMUN: '231',
		DC: '1',
		NOMBRE: 'Torrelameu',
	},
	{
		CPRO: '25',
		CMUN: '232',
		DC: '6',
		NOMBRE: 'Torres de Segre',
	},
	{
		CPRO: '25',
		CMUN: '233',
		DC: '2',
		NOMBRE: 'Torre-serona',
	},
	{
		CPRO: '25',
		CMUN: '234',
		DC: '7',
		NOMBRE: 'Tremp',
	},
	{
		CPRO: '25',
		CMUN: '043',
		DC: '2',
		NOMBRE: 'Vall de Boí, La',
	},
	{
		CPRO: '25',
		CMUN: '901',
		DC: '0',
		NOMBRE: 'Vall de Cardós',
	},
	{
		CPRO: '25',
		CMUN: '238',
		DC: '5',
		NOMBRE: 'Vallbona de les Monges',
	},
	{
		CPRO: '25',
		CMUN: '240',
		DC: '2',
		NOMBRE: 'Vallfogona de Balaguer',
	},
	{
		CPRO: '25',
		CMUN: '906',
		DC: '2',
		NOMBRE: "Valls d'Aguilar, Les",
	},
	{
		CPRO: '25',
		CMUN: '239',
		DC: '8',
		NOMBRE: 'Valls de Valira, Les',
	},
	{
		CPRO: '25',
		CMUN: '909',
		DC: '7',
		NOMBRE: 'Vansa i Fórnols, La',
	},
	{
		CPRO: '25',
		CMUN: '242',
		DC: '4',
		NOMBRE: 'Verdú',
	},
	{
		CPRO: '25',
		CMUN: '243',
		DC: '0',
		NOMBRE: 'Vielha e Mijaran',
	},
	{
		CPRO: '25',
		CMUN: '244',
		DC: '5',
		NOMBRE: 'Vilagrassa',
	},
	{
		CPRO: '25',
		CMUN: '245',
		DC: '8',
		NOMBRE: 'Vilaller',
	},
	{
		CPRO: '25',
		CMUN: '247',
		DC: '7',
		NOMBRE: 'Vilamòs',
	},
	{
		CPRO: '25',
		CMUN: '248',
		DC: '3',
		NOMBRE: 'Vilanova de Bellpuig',
	},
	{
		CPRO: '25',
		CMUN: '254',
		DC: '2',
		NOMBRE: 'Vilanova de la Barca',
	},
	{
		CPRO: '25',
		CMUN: '249',
		DC: '6',
		NOMBRE: "Vilanova de l'Aguda",
	},
	{
		CPRO: '25',
		CMUN: '250',
		DC: '9',
		NOMBRE: 'Vilanova de Meià',
	},
	{
		CPRO: '25',
		CMUN: '251',
		DC: '6',
		NOMBRE: 'Vilanova de Segrià',
	},
	{
		CPRO: '25',
		CMUN: '252',
		DC: '1',
		NOMBRE: 'Vila-sana',
	},
	{
		CPRO: '25',
		CMUN: '253',
		DC: '7',
		NOMBRE: 'Vilosell, El',
	},
	{
		CPRO: '25',
		CMUN: '255',
		DC: '5',
		NOMBRE: 'Vinaixa',
	},
	{
		CPRO: '26',
		CMUN: '001',
		DC: '2',
		NOMBRE: 'Ábalos',
	},
	{
		CPRO: '26',
		CMUN: '002',
		DC: '7',
		NOMBRE: 'Agoncillo',
	},
	{
		CPRO: '26',
		CMUN: '003',
		DC: '3',
		NOMBRE: 'Aguilar del Río Alhama',
	},
	{
		CPRO: '26',
		CMUN: '004',
		DC: '8',
		NOMBRE: 'Ajamil de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '005',
		DC: '1',
		NOMBRE: 'Albelda de Iregua',
	},
	{
		CPRO: '26',
		CMUN: '006',
		DC: '4',
		NOMBRE: 'Alberite',
	},
	{
		CPRO: '26',
		CMUN: '007',
		DC: '0',
		NOMBRE: 'Alcanadre',
	},
	{
		CPRO: '26',
		CMUN: '008',
		DC: '6',
		NOMBRE: 'Aldeanueva de Ebro',
	},
	{
		CPRO: '26',
		CMUN: '009',
		DC: '9',
		NOMBRE: 'Alesanco',
	},
	{
		CPRO: '26',
		CMUN: '010',
		DC: '3',
		NOMBRE: 'Alesón',
	},
	{
		CPRO: '26',
		CMUN: '011',
		DC: '0',
		NOMBRE: 'Alfaro',
	},
	{
		CPRO: '26',
		CMUN: '012',
		DC: '5',
		NOMBRE: 'Almarza de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '013',
		DC: '1',
		NOMBRE: 'Anguciana',
	},
	{
		CPRO: '26',
		CMUN: '014',
		DC: '6',
		NOMBRE: 'Anguiano',
	},
	{
		CPRO: '26',
		CMUN: '015',
		DC: '9',
		NOMBRE: 'Arenzana de Abajo',
	},
	{
		CPRO: '26',
		CMUN: '016',
		DC: '2',
		NOMBRE: 'Arenzana de Arriba',
	},
	{
		CPRO: '26',
		CMUN: '017',
		DC: '8',
		NOMBRE: 'Arnedillo',
	},
	{
		CPRO: '26',
		CMUN: '018',
		DC: '4',
		NOMBRE: 'Arnedo',
	},
	{
		CPRO: '26',
		CMUN: '019',
		DC: '7',
		NOMBRE: 'Arrúbal',
	},
	{
		CPRO: '26',
		CMUN: '020',
		DC: '1',
		NOMBRE: 'Ausejo',
	},
	{
		CPRO: '26',
		CMUN: '021',
		DC: '8',
		NOMBRE: 'Autol',
	},
	{
		CPRO: '26',
		CMUN: '022',
		DC: '3',
		NOMBRE: 'Azofra',
	},
	{
		CPRO: '26',
		CMUN: '023',
		DC: '9',
		NOMBRE: 'Badarán',
	},
	{
		CPRO: '26',
		CMUN: '024',
		DC: '4',
		NOMBRE: 'Bañares',
	},
	{
		CPRO: '26',
		CMUN: '026',
		DC: '0',
		NOMBRE: 'Baños de Río Tobía',
	},
	{
		CPRO: '26',
		CMUN: '025',
		DC: '7',
		NOMBRE: 'Baños de Rioja',
	},
	{
		CPRO: '26',
		CMUN: '027',
		DC: '6',
		NOMBRE: 'Berceo',
	},
	{
		CPRO: '26',
		CMUN: '028',
		DC: '2',
		NOMBRE: 'Bergasa',
	},
	{
		CPRO: '26',
		CMUN: '029',
		DC: '5',
		NOMBRE: 'Bergasillas Bajera',
	},
	{
		CPRO: '26',
		CMUN: '030',
		DC: '9',
		NOMBRE: 'Bezares',
	},
	{
		CPRO: '26',
		CMUN: '031',
		DC: '6',
		NOMBRE: 'Bobadilla',
	},
	{
		CPRO: '26',
		CMUN: '032',
		DC: '1',
		NOMBRE: 'Brieva de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '033',
		DC: '7',
		NOMBRE: 'Briñas',
	},
	{
		CPRO: '26',
		CMUN: '034',
		DC: '2',
		NOMBRE: 'Briones',
	},
	{
		CPRO: '26',
		CMUN: '035',
		DC: '5',
		NOMBRE: 'Cabezón de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '036',
		DC: '8',
		NOMBRE: 'Calahorra',
	},
	{
		CPRO: '26',
		CMUN: '037',
		DC: '4',
		NOMBRE: 'Camprovín',
	},
	{
		CPRO: '26',
		CMUN: '038',
		DC: '0',
		NOMBRE: 'Canales de la Sierra',
	},
	{
		CPRO: '26',
		CMUN: '039',
		DC: '3',
		NOMBRE: 'Canillas de Río Tuerto',
	},
	{
		CPRO: '26',
		CMUN: '040',
		DC: '7',
		NOMBRE: 'Cañas',
	},
	{
		CPRO: '26',
		CMUN: '041',
		DC: '4',
		NOMBRE: 'Cárdenas',
	},
	{
		CPRO: '26',
		CMUN: '042',
		DC: '9',
		NOMBRE: 'Casalarreina',
	},
	{
		CPRO: '26',
		CMUN: '043',
		DC: '5',
		NOMBRE: 'Castañares de Rioja',
	},
	{
		CPRO: '26',
		CMUN: '044',
		DC: '0',
		NOMBRE: 'Castroviejo',
	},
	{
		CPRO: '26',
		CMUN: '045',
		DC: '3',
		NOMBRE: 'Cellorigo',
	},
	{
		CPRO: '26',
		CMUN: '046',
		DC: '6',
		NOMBRE: 'Cenicero',
	},
	{
		CPRO: '26',
		CMUN: '047',
		DC: '2',
		NOMBRE: 'Cervera del Río Alhama',
	},
	{
		CPRO: '26',
		CMUN: '048',
		DC: '8',
		NOMBRE: 'Cidamón',
	},
	{
		CPRO: '26',
		CMUN: '049',
		DC: '1',
		NOMBRE: 'Cihuri',
	},
	{
		CPRO: '26',
		CMUN: '050',
		DC: '4',
		NOMBRE: 'Cirueña',
	},
	{
		CPRO: '26',
		CMUN: '051',
		DC: '1',
		NOMBRE: 'Clavijo',
	},
	{
		CPRO: '26',
		CMUN: '052',
		DC: '6',
		NOMBRE: 'Cordovín',
	},
	{
		CPRO: '26',
		CMUN: '053',
		DC: '2',
		NOMBRE: 'Corera',
	},
	{
		CPRO: '26',
		CMUN: '054',
		DC: '7',
		NOMBRE: 'Cornago',
	},
	{
		CPRO: '26',
		CMUN: '055',
		DC: '0',
		NOMBRE: 'Corporales',
	},
	{
		CPRO: '26',
		CMUN: '056',
		DC: '3',
		NOMBRE: 'Cuzcurrita de Río Tirón',
	},
	{
		CPRO: '26',
		CMUN: '057',
		DC: '9',
		NOMBRE: 'Daroca de Rioja',
	},
	{
		CPRO: '26',
		CMUN: '058',
		DC: '5',
		NOMBRE: 'Enciso',
	},
	{
		CPRO: '26',
		CMUN: '059',
		DC: '8',
		NOMBRE: 'Entrena',
	},
	{
		CPRO: '26',
		CMUN: '060',
		DC: '2',
		NOMBRE: 'Estollo',
	},
	{
		CPRO: '26',
		CMUN: '061',
		DC: '9',
		NOMBRE: 'Ezcaray',
	},
	{
		CPRO: '26',
		CMUN: '062',
		DC: '4',
		NOMBRE: 'Foncea',
	},
	{
		CPRO: '26',
		CMUN: '063',
		DC: '0',
		NOMBRE: 'Fonzaleche',
	},
	{
		CPRO: '26',
		CMUN: '064',
		DC: '5',
		NOMBRE: 'Fuenmayor',
	},
	{
		CPRO: '26',
		CMUN: '065',
		DC: '8',
		NOMBRE: 'Galbárruli',
	},
	{
		CPRO: '26',
		CMUN: '066',
		DC: '1',
		NOMBRE: 'Galilea',
	},
	{
		CPRO: '26',
		CMUN: '067',
		DC: '7',
		NOMBRE: 'Gallinero de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '068',
		DC: '3',
		NOMBRE: 'Gimileo',
	},
	{
		CPRO: '26',
		CMUN: '069',
		DC: '6',
		NOMBRE: 'Grañón',
	},
	{
		CPRO: '26',
		CMUN: '070',
		DC: '0',
		NOMBRE: 'Grávalos',
	},
	{
		CPRO: '26',
		CMUN: '071',
		DC: '7',
		NOMBRE: 'Haro',
	},
	{
		CPRO: '26',
		CMUN: '072',
		DC: '2',
		NOMBRE: 'Herce',
	},
	{
		CPRO: '26',
		CMUN: '073',
		DC: '8',
		NOMBRE: 'Herramélluri',
	},
	{
		CPRO: '26',
		CMUN: '074',
		DC: '3',
		NOMBRE: 'Hervías',
	},
	{
		CPRO: '26',
		CMUN: '075',
		DC: '6',
		NOMBRE: 'Hormilla',
	},
	{
		CPRO: '26',
		CMUN: '076',
		DC: '9',
		NOMBRE: 'Hormilleja',
	},
	{
		CPRO: '26',
		CMUN: '077',
		DC: '5',
		NOMBRE: 'Hornillos de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '078',
		DC: '1',
		NOMBRE: 'Hornos de Moncalvillo',
	},
	{
		CPRO: '26',
		CMUN: '079',
		DC: '4',
		NOMBRE: 'Huércanos',
	},
	{
		CPRO: '26',
		CMUN: '080',
		DC: '8',
		NOMBRE: 'Igea',
	},
	{
		CPRO: '26',
		CMUN: '081',
		DC: '5',
		NOMBRE: 'Jalón de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '082',
		DC: '0',
		NOMBRE: 'Laguna de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '083',
		DC: '6',
		NOMBRE: 'Lagunilla del Jubera',
	},
	{
		CPRO: '26',
		CMUN: '084',
		DC: '1',
		NOMBRE: 'Lardero',
	},
	{
		CPRO: '26',
		CMUN: '086',
		DC: '7',
		NOMBRE: 'Ledesma de la Cogolla',
	},
	{
		CPRO: '26',
		CMUN: '087',
		DC: '3',
		NOMBRE: 'Leiva',
	},
	{
		CPRO: '26',
		CMUN: '088',
		DC: '9',
		NOMBRE: 'Leza de Río Leza',
	},
	{
		CPRO: '26',
		CMUN: '089',
		DC: '2',
		NOMBRE: 'Logroño',
	},
	{
		CPRO: '26',
		CMUN: '091',
		DC: '3',
		NOMBRE: 'Lumbreras',
	},
	{
		CPRO: '26',
		CMUN: '092',
		DC: '8',
		NOMBRE: 'Manjarrés',
	},
	{
		CPRO: '26',
		CMUN: '093',
		DC: '4',
		NOMBRE: 'Mansilla de la Sierra',
	},
	{
		CPRO: '26',
		CMUN: '094',
		DC: '9',
		NOMBRE: 'Manzanares de Rioja',
	},
	{
		CPRO: '26',
		CMUN: '095',
		DC: '2',
		NOMBRE: 'Matute',
	},
	{
		CPRO: '26',
		CMUN: '096',
		DC: '5',
		NOMBRE: 'Medrano',
	},
	{
		CPRO: '26',
		CMUN: '098',
		DC: '7',
		NOMBRE: 'Munilla',
	},
	{
		CPRO: '26',
		CMUN: '099',
		DC: '0',
		NOMBRE: 'Murillo de Río Leza',
	},
	{
		CPRO: '26',
		CMUN: '100',
		DC: '4',
		NOMBRE: 'Muro de Aguas',
	},
	{
		CPRO: '26',
		CMUN: '101',
		DC: '1',
		NOMBRE: 'Muro en Cameros',
	},
	{
		CPRO: '26',
		CMUN: '102',
		DC: '6',
		NOMBRE: 'Nájera',
	},
	{
		CPRO: '26',
		CMUN: '103',
		DC: '2',
		NOMBRE: 'Nalda',
	},
	{
		CPRO: '26',
		CMUN: '104',
		DC: '7',
		NOMBRE: 'Navajún',
	},
	{
		CPRO: '26',
		CMUN: '105',
		DC: '0',
		NOMBRE: 'Navarrete',
	},
	{
		CPRO: '26',
		CMUN: '106',
		DC: '3',
		NOMBRE: 'Nestares',
	},
	{
		CPRO: '26',
		CMUN: '107',
		DC: '9',
		NOMBRE: 'Nieva de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '109',
		DC: '8',
		NOMBRE: 'Ochánduri',
	},
	{
		CPRO: '26',
		CMUN: '108',
		DC: '5',
		NOMBRE: 'Ocón',
	},
	{
		CPRO: '26',
		CMUN: '110',
		DC: '2',
		NOMBRE: 'Ojacastro',
	},
	{
		CPRO: '26',
		CMUN: '111',
		DC: '9',
		NOMBRE: 'Ollauri',
	},
	{
		CPRO: '26',
		CMUN: '112',
		DC: '4',
		NOMBRE: 'Ortigosa de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '113',
		DC: '0',
		NOMBRE: 'Pazuengos',
	},
	{
		CPRO: '26',
		CMUN: '114',
		DC: '5',
		NOMBRE: 'Pedroso',
	},
	{
		CPRO: '26',
		CMUN: '115',
		DC: '8',
		NOMBRE: 'Pinillos',
	},
	{
		CPRO: '26',
		CMUN: '117',
		DC: '7',
		NOMBRE: 'Pradejón',
	},
	{
		CPRO: '26',
		CMUN: '118',
		DC: '3',
		NOMBRE: 'Pradillo',
	},
	{
		CPRO: '26',
		CMUN: '119',
		DC: '6',
		NOMBRE: 'Préjano',
	},
	{
		CPRO: '26',
		CMUN: '120',
		DC: '0',
		NOMBRE: 'Quel',
	},
	{
		CPRO: '26',
		CMUN: '121',
		DC: '7',
		NOMBRE: 'Rabanera',
	},
	{
		CPRO: '26',
		CMUN: '122',
		DC: '2',
		NOMBRE: 'Rasillo de Cameros, El',
	},
	{
		CPRO: '26',
		CMUN: '123',
		DC: '8',
		NOMBRE: 'Redal, El',
	},
	{
		CPRO: '26',
		CMUN: '124',
		DC: '3',
		NOMBRE: 'Ribafrecha',
	},
	{
		CPRO: '26',
		CMUN: '125',
		DC: '6',
		NOMBRE: 'Rincón de Soto',
	},
	{
		CPRO: '26',
		CMUN: '126',
		DC: '9',
		NOMBRE: 'Robres del Castillo',
	},
	{
		CPRO: '26',
		CMUN: '127',
		DC: '5',
		NOMBRE: 'Rodezno',
	},
	{
		CPRO: '26',
		CMUN: '128',
		DC: '1',
		NOMBRE: 'Sajazarra',
	},
	{
		CPRO: '26',
		CMUN: '129',
		DC: '4',
		NOMBRE: 'San Asensio',
	},
	{
		CPRO: '26',
		CMUN: '130',
		DC: '8',
		NOMBRE: 'San Millán de la Cogolla',
	},
	{
		CPRO: '26',
		CMUN: '131',
		DC: '5',
		NOMBRE: 'San Millán de Yécora',
	},
	{
		CPRO: '26',
		CMUN: '132',
		DC: '0',
		NOMBRE: 'San Román de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '139',
		DC: '2',
		NOMBRE: 'San Torcuato',
	},
	{
		CPRO: '26',
		CMUN: '142',
		DC: '8',
		NOMBRE: 'San Vicente de la Sonsierra',
	},
	{
		CPRO: '26',
		CMUN: '134',
		DC: '1',
		NOMBRE: 'Santa Coloma',
	},
	{
		CPRO: '26',
		CMUN: '135',
		DC: '4',
		NOMBRE: 'Santa Engracia del Jubera',
	},
	{
		CPRO: '26',
		CMUN: '136',
		DC: '7',
		NOMBRE: 'Santa Eulalia Bajera',
	},
	{
		CPRO: '26',
		CMUN: '138',
		DC: '9',
		NOMBRE: 'Santo Domingo de la Calzada',
	},
	{
		CPRO: '26',
		CMUN: '140',
		DC: '6',
		NOMBRE: 'Santurde de Rioja',
	},
	{
		CPRO: '26',
		CMUN: '141',
		DC: '3',
		NOMBRE: 'Santurdejo',
	},
	{
		CPRO: '26',
		CMUN: '143',
		DC: '4',
		NOMBRE: 'Sojuela',
	},
	{
		CPRO: '26',
		CMUN: '144',
		DC: '9',
		NOMBRE: 'Sorzano',
	},
	{
		CPRO: '26',
		CMUN: '145',
		DC: '2',
		NOMBRE: 'Sotés',
	},
	{
		CPRO: '26',
		CMUN: '146',
		DC: '5',
		NOMBRE: 'Soto en Cameros',
	},
	{
		CPRO: '26',
		CMUN: '147',
		DC: '1',
		NOMBRE: 'Terroba',
	},
	{
		CPRO: '26',
		CMUN: '148',
		DC: '7',
		NOMBRE: 'Tirgo',
	},
	{
		CPRO: '26',
		CMUN: '149',
		DC: '0',
		NOMBRE: 'Tobía',
	},
	{
		CPRO: '26',
		CMUN: '150',
		DC: '3',
		NOMBRE: 'Tormantos',
	},
	{
		CPRO: '26',
		CMUN: '153',
		DC: '1',
		NOMBRE: 'Torre en Cameros',
	},
	{
		CPRO: '26',
		CMUN: '151',
		DC: '0',
		NOMBRE: 'Torrecilla en Cameros',
	},
	{
		CPRO: '26',
		CMUN: '152',
		DC: '5',
		NOMBRE: 'Torrecilla sobre Alesanco',
	},
	{
		CPRO: '26',
		CMUN: '154',
		DC: '6',
		NOMBRE: 'Torremontalbo',
	},
	{
		CPRO: '26',
		CMUN: '155',
		DC: '9',
		NOMBRE: 'Treviana',
	},
	{
		CPRO: '26',
		CMUN: '157',
		DC: '8',
		NOMBRE: 'Tricio',
	},
	{
		CPRO: '26',
		CMUN: '158',
		DC: '4',
		NOMBRE: 'Tudelilla',
	},
	{
		CPRO: '26',
		CMUN: '160',
		DC: '1',
		NOMBRE: 'Uruñuela',
	},
	{
		CPRO: '26',
		CMUN: '161',
		DC: '8',
		NOMBRE: 'Valdemadera',
	},
	{
		CPRO: '26',
		CMUN: '162',
		DC: '3',
		NOMBRE: 'Valgañón',
	},
	{
		CPRO: '26',
		CMUN: '163',
		DC: '9',
		NOMBRE: 'Ventosa',
	},
	{
		CPRO: '26',
		CMUN: '164',
		DC: '4',
		NOMBRE: 'Ventrosa',
	},
	{
		CPRO: '26',
		CMUN: '165',
		DC: '7',
		NOMBRE: 'Viguera',
	},
	{
		CPRO: '26',
		CMUN: '166',
		DC: '0',
		NOMBRE: 'Villalba de Rioja',
	},
	{
		CPRO: '26',
		CMUN: '167',
		DC: '6',
		NOMBRE: 'Villalobar de Rioja',
	},
	{
		CPRO: '26',
		CMUN: '168',
		DC: '2',
		NOMBRE: 'Villamediana de Iregua',
	},
	{
		CPRO: '26',
		CMUN: '169',
		DC: '5',
		NOMBRE: 'Villanueva de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '170',
		DC: '9',
		NOMBRE: 'Villar de Arnedo, El',
	},
	{
		CPRO: '26',
		CMUN: '171',
		DC: '6',
		NOMBRE: 'Villar de Torre',
	},
	{
		CPRO: '26',
		CMUN: '172',
		DC: '1',
		NOMBRE: 'Villarejo',
	},
	{
		CPRO: '26',
		CMUN: '173',
		DC: '7',
		NOMBRE: 'Villarroya',
	},
	{
		CPRO: '26',
		CMUN: '174',
		DC: '2',
		NOMBRE: 'Villarta-Quintana',
	},
	{
		CPRO: '26',
		CMUN: '175',
		DC: '5',
		NOMBRE: 'Villavelayo',
	},
	{
		CPRO: '26',
		CMUN: '176',
		DC: '8',
		NOMBRE: 'Villaverde de Rioja',
	},
	{
		CPRO: '26',
		CMUN: '177',
		DC: '4',
		NOMBRE: 'Villoslada de Cameros',
	},
	{
		CPRO: '26',
		CMUN: '178',
		DC: '0',
		NOMBRE: 'Viniegra de Abajo',
	},
	{
		CPRO: '26',
		CMUN: '179',
		DC: '3',
		NOMBRE: 'Viniegra de Arriba',
	},
	{
		CPRO: '26',
		CMUN: '180',
		DC: '7',
		NOMBRE: 'Zarratón',
	},
	{
		CPRO: '26',
		CMUN: '181',
		DC: '4',
		NOMBRE: 'Zarzosa',
	},
	{
		CPRO: '26',
		CMUN: '183',
		DC: '5',
		NOMBRE: 'Zorraquín',
	},
	{
		CPRO: '27',
		CMUN: '001',
		DC: '8',
		NOMBRE: 'Abadín',
	},
	{
		CPRO: '27',
		CMUN: '002',
		DC: '3',
		NOMBRE: 'Alfoz',
	},
	{
		CPRO: '27',
		CMUN: '003',
		DC: '9',
		NOMBRE: 'Antas de Ulla',
	},
	{
		CPRO: '27',
		CMUN: '004',
		DC: '4',
		NOMBRE: 'Baleira',
	},
	{
		CPRO: '27',
		CMUN: '901',
		DC: '9',
		NOMBRE: 'Baralla',
	},
	{
		CPRO: '27',
		CMUN: '005',
		DC: '7',
		NOMBRE: 'Barreiros',
	},
	{
		CPRO: '27',
		CMUN: '006',
		DC: '0',
		NOMBRE: 'Becerreá',
	},
	{
		CPRO: '27',
		CMUN: '007',
		DC: '6',
		NOMBRE: 'Begonte',
	},
	{
		CPRO: '27',
		CMUN: '008',
		DC: '2',
		NOMBRE: 'Bóveda',
	},
	{
		CPRO: '27',
		CMUN: '902',
		DC: '4',
		NOMBRE: 'Burela',
	},
	{
		CPRO: '27',
		CMUN: '009',
		DC: '5',
		NOMBRE: 'Carballedo',
	},
	{
		CPRO: '27',
		CMUN: '010',
		DC: '9',
		NOMBRE: 'Castro de Rei',
	},
	{
		CPRO: '27',
		CMUN: '011',
		DC: '6',
		NOMBRE: 'Castroverde',
	},
	{
		CPRO: '27',
		CMUN: '012',
		DC: '1',
		NOMBRE: 'Cervantes',
	},
	{
		CPRO: '27',
		CMUN: '013',
		DC: '7',
		NOMBRE: 'Cervo',
	},
	{
		CPRO: '27',
		CMUN: '016',
		DC: '8',
		NOMBRE: 'Chantada',
	},
	{
		CPRO: '27',
		CMUN: '014',
		DC: '2',
		NOMBRE: 'Corgo, O',
	},
	{
		CPRO: '27',
		CMUN: '015',
		DC: '5',
		NOMBRE: 'Cospeito',
	},
	{
		CPRO: '27',
		CMUN: '017',
		DC: '4',
		NOMBRE: 'Folgoso do Courel',
	},
	{
		CPRO: '27',
		CMUN: '018',
		DC: '0',
		NOMBRE: 'Fonsagrada, A',
	},
	{
		CPRO: '27',
		CMUN: '019',
		DC: '3',
		NOMBRE: 'Foz',
	},
	{
		CPRO: '27',
		CMUN: '020',
		DC: '7',
		NOMBRE: 'Friol',
	},
	{
		CPRO: '27',
		CMUN: '022',
		DC: '9',
		NOMBRE: 'Guitiriz',
	},
	{
		CPRO: '27',
		CMUN: '023',
		DC: '5',
		NOMBRE: 'Guntín',
	},
	{
		CPRO: '27',
		CMUN: '024',
		DC: '0',
		NOMBRE: 'Incio, O',
	},
	{
		CPRO: '27',
		CMUN: '026',
		DC: '6',
		NOMBRE: 'Láncara',
	},
	{
		CPRO: '27',
		CMUN: '027',
		DC: '2',
		NOMBRE: 'Lourenzá',
	},
	{
		CPRO: '27',
		CMUN: '028',
		DC: '8',
		NOMBRE: 'Lugo',
	},
	{
		CPRO: '27',
		CMUN: '029',
		DC: '1',
		NOMBRE: 'Meira',
	},
	{
		CPRO: '27',
		CMUN: '030',
		DC: '5',
		NOMBRE: 'Mondoñedo',
	},
	{
		CPRO: '27',
		CMUN: '031',
		DC: '2',
		NOMBRE: 'Monforte de Lemos',
	},
	{
		CPRO: '27',
		CMUN: '032',
		DC: '7',
		NOMBRE: 'Monterroso',
	},
	{
		CPRO: '27',
		CMUN: '033',
		DC: '3',
		NOMBRE: 'Muras',
	},
	{
		CPRO: '27',
		CMUN: '034',
		DC: '8',
		NOMBRE: 'Navia de Suarna',
	},
	{
		CPRO: '27',
		CMUN: '035',
		DC: '1',
		NOMBRE: 'Negueira de Muñiz',
	},
	{
		CPRO: '27',
		CMUN: '037',
		DC: '0',
		NOMBRE: 'Nogais, As',
	},
	{
		CPRO: '27',
		CMUN: '038',
		DC: '6',
		NOMBRE: 'Ourol',
	},
	{
		CPRO: '27',
		CMUN: '039',
		DC: '9',
		NOMBRE: 'Outeiro de Rei',
	},
	{
		CPRO: '27',
		CMUN: '040',
		DC: '3',
		NOMBRE: 'Palas de Rei',
	},
	{
		CPRO: '27',
		CMUN: '041',
		DC: '0',
		NOMBRE: 'Pantón',
	},
	{
		CPRO: '27',
		CMUN: '042',
		DC: '5',
		NOMBRE: 'Paradela',
	},
	{
		CPRO: '27',
		CMUN: '043',
		DC: '1',
		NOMBRE: 'Páramo, O',
	},
	{
		CPRO: '27',
		CMUN: '044',
		DC: '6',
		NOMBRE: 'Pastoriza, A',
	},
	{
		CPRO: '27',
		CMUN: '045',
		DC: '9',
		NOMBRE: 'Pedrafita do Cebreiro',
	},
	{
		CPRO: '27',
		CMUN: '047',
		DC: '8',
		NOMBRE: 'Pobra do Brollón, A',
	},
	{
		CPRO: '27',
		CMUN: '046',
		DC: '2',
		NOMBRE: 'Pol',
	},
	{
		CPRO: '27',
		CMUN: '048',
		DC: '4',
		NOMBRE: 'Pontenova, A',
	},
	{
		CPRO: '27',
		CMUN: '049',
		DC: '7',
		NOMBRE: 'Portomarín',
	},
	{
		CPRO: '27',
		CMUN: '050',
		DC: '0',
		NOMBRE: 'Quiroga',
	},
	{
		CPRO: '27',
		CMUN: '056',
		DC: '9',
		NOMBRE: 'Rábade',
	},
	{
		CPRO: '27',
		CMUN: '051',
		DC: '7',
		NOMBRE: 'Ribadeo',
	},
	{
		CPRO: '27',
		CMUN: '052',
		DC: '2',
		NOMBRE: 'Ribas de Sil',
	},
	{
		CPRO: '27',
		CMUN: '053',
		DC: '8',
		NOMBRE: 'Ribeira de Piquín',
	},
	{
		CPRO: '27',
		CMUN: '054',
		DC: '3',
		NOMBRE: 'Riotorto',
	},
	{
		CPRO: '27',
		CMUN: '055',
		DC: '6',
		NOMBRE: 'Samos',
	},
	{
		CPRO: '27',
		CMUN: '057',
		DC: '5',
		NOMBRE: 'Sarria',
	},
	{
		CPRO: '27',
		CMUN: '058',
		DC: '1',
		NOMBRE: 'Saviñao, O',
	},
	{
		CPRO: '27',
		CMUN: '059',
		DC: '4',
		NOMBRE: 'Sober',
	},
	{
		CPRO: '27',
		CMUN: '060',
		DC: '8',
		NOMBRE: 'Taboada',
	},
	{
		CPRO: '27',
		CMUN: '061',
		DC: '5',
		NOMBRE: 'Trabada',
	},
	{
		CPRO: '27',
		CMUN: '062',
		DC: '0',
		NOMBRE: 'Triacastela',
	},
	{
		CPRO: '27',
		CMUN: '063',
		DC: '6',
		NOMBRE: 'Valadouro, O',
	},
	{
		CPRO: '27',
		CMUN: '064',
		DC: '1',
		NOMBRE: 'Vicedo, O',
	},
	{
		CPRO: '27',
		CMUN: '065',
		DC: '4',
		NOMBRE: 'Vilalba',
	},
	{
		CPRO: '27',
		CMUN: '066',
		DC: '7',
		NOMBRE: 'Viveiro',
	},
	{
		CPRO: '27',
		CMUN: '021',
		DC: '4',
		NOMBRE: 'Xermade',
	},
	{
		CPRO: '27',
		CMUN: '025',
		DC: '3',
		NOMBRE: 'Xove',
	},
	{
		CPRO: '28',
		CMUN: '001',
		DC: '4',
		NOMBRE: 'Acebeda, La',
	},
	{
		CPRO: '28',
		CMUN: '002',
		DC: '9',
		NOMBRE: 'Ajalvir',
	},
	{
		CPRO: '28',
		CMUN: '003',
		DC: '5',
		NOMBRE: 'Alameda del Valle',
	},
	{
		CPRO: '28',
		CMUN: '004',
		DC: '0',
		NOMBRE: 'Álamo, El',
	},
	{
		CPRO: '28',
		CMUN: '005',
		DC: '3',
		NOMBRE: 'Alcalá de Henares',
	},
	{
		CPRO: '28',
		CMUN: '006',
		DC: '6',
		NOMBRE: 'Alcobendas',
	},
	{
		CPRO: '28',
		CMUN: '007',
		DC: '2',
		NOMBRE: 'Alcorcón',
	},
	{
		CPRO: '28',
		CMUN: '008',
		DC: '8',
		NOMBRE: 'Aldea del Fresno',
	},
	{
		CPRO: '28',
		CMUN: '009',
		DC: '1',
		NOMBRE: 'Algete',
	},
	{
		CPRO: '28',
		CMUN: '010',
		DC: '5',
		NOMBRE: 'Alpedrete',
	},
	{
		CPRO: '28',
		CMUN: '011',
		DC: '2',
		NOMBRE: 'Ambite',
	},
	{
		CPRO: '28',
		CMUN: '012',
		DC: '7',
		NOMBRE: 'Anchuelo',
	},
	{
		CPRO: '28',
		CMUN: '013',
		DC: '3',
		NOMBRE: 'Aranjuez',
	},
	{
		CPRO: '28',
		CMUN: '014',
		DC: '8',
		NOMBRE: 'Arganda del Rey',
	},
	{
		CPRO: '28',
		CMUN: '015',
		DC: '1',
		NOMBRE: 'Arroyomolinos',
	},
	{
		CPRO: '28',
		CMUN: '016',
		DC: '4',
		NOMBRE: 'Atazar, El',
	},
	{
		CPRO: '28',
		CMUN: '017',
		DC: '0',
		NOMBRE: 'Batres',
	},
	{
		CPRO: '28',
		CMUN: '018',
		DC: '6',
		NOMBRE: 'Becerril de la Sierra',
	},
	{
		CPRO: '28',
		CMUN: '019',
		DC: '9',
		NOMBRE: 'Belmonte de Tajo',
	},
	{
		CPRO: '28',
		CMUN: '021',
		DC: '0',
		NOMBRE: 'Berrueco, El',
	},
	{
		CPRO: '28',
		CMUN: '020',
		DC: '3',
		NOMBRE: 'Berzosa del Lozoya',
	},
	{
		CPRO: '28',
		CMUN: '022',
		DC: '5',
		NOMBRE: 'Boadilla del Monte',
	},
	{
		CPRO: '28',
		CMUN: '023',
		DC: '1',
		NOMBRE: 'Boalo, El',
	},
	{
		CPRO: '28',
		CMUN: '024',
		DC: '6',
		NOMBRE: 'Braojos',
	},
	{
		CPRO: '28',
		CMUN: '025',
		DC: '9',
		NOMBRE: 'Brea de Tajo',
	},
	{
		CPRO: '28',
		CMUN: '026',
		DC: '2',
		NOMBRE: 'Brunete',
	},
	{
		CPRO: '28',
		CMUN: '027',
		DC: '8',
		NOMBRE: 'Buitrago del Lozoya',
	},
	{
		CPRO: '28',
		CMUN: '028',
		DC: '4',
		NOMBRE: 'Bustarviejo',
	},
	{
		CPRO: '28',
		CMUN: '029',
		DC: '7',
		NOMBRE: 'Cabanillas de la Sierra',
	},
	{
		CPRO: '28',
		CMUN: '030',
		DC: '1',
		NOMBRE: 'Cabrera, La',
	},
	{
		CPRO: '28',
		CMUN: '031',
		DC: '8',
		NOMBRE: 'Cadalso de los Vidrios',
	},
	{
		CPRO: '28',
		CMUN: '032',
		DC: '3',
		NOMBRE: 'Camarma de Esteruelas',
	},
	{
		CPRO: '28',
		CMUN: '033',
		DC: '9',
		NOMBRE: 'Campo Real',
	},
	{
		CPRO: '28',
		CMUN: '034',
		DC: '4',
		NOMBRE: 'Canencia',
	},
	{
		CPRO: '28',
		CMUN: '035',
		DC: '7',
		NOMBRE: 'Carabaña',
	},
	{
		CPRO: '28',
		CMUN: '036',
		DC: '0',
		NOMBRE: 'Casarrubuelos',
	},
	{
		CPRO: '28',
		CMUN: '037',
		DC: '6',
		NOMBRE: 'Cenicientos',
	},
	{
		CPRO: '28',
		CMUN: '038',
		DC: '2',
		NOMBRE: 'Cercedilla',
	},
	{
		CPRO: '28',
		CMUN: '039',
		DC: '5',
		NOMBRE: 'Cervera de Buitrago',
	},
	{
		CPRO: '28',
		CMUN: '051',
		DC: '3',
		NOMBRE: 'Chapinería',
	},
	{
		CPRO: '28',
		CMUN: '052',
		DC: '8',
		NOMBRE: 'Chinchón',
	},
	{
		CPRO: '28',
		CMUN: '040',
		DC: '9',
		NOMBRE: 'Ciempozuelos',
	},
	{
		CPRO: '28',
		CMUN: '041',
		DC: '6',
		NOMBRE: 'Cobeña',
	},
	{
		CPRO: '28',
		CMUN: '046',
		DC: '8',
		NOMBRE: 'Collado Mediano',
	},
	{
		CPRO: '28',
		CMUN: '047',
		DC: '4',
		NOMBRE: 'Collado Villalba',
	},
	{
		CPRO: '28',
		CMUN: '043',
		DC: '7',
		NOMBRE: 'Colmenar de Oreja',
	},
	{
		CPRO: '28',
		CMUN: '042',
		DC: '1',
		NOMBRE: 'Colmenar del Arroyo',
	},
	{
		CPRO: '28',
		CMUN: '045',
		DC: '5',
		NOMBRE: 'Colmenar Viejo',
	},
	{
		CPRO: '28',
		CMUN: '044',
		DC: '2',
		NOMBRE: 'Colmenarejo',
	},
	{
		CPRO: '28',
		CMUN: '048',
		DC: '0',
		NOMBRE: 'Corpa',
	},
	{
		CPRO: '28',
		CMUN: '049',
		DC: '3',
		NOMBRE: 'Coslada',
	},
	{
		CPRO: '28',
		CMUN: '050',
		DC: '6',
		NOMBRE: 'Cubas de la Sagra',
	},
	{
		CPRO: '28',
		CMUN: '053',
		DC: '4',
		NOMBRE: 'Daganzo de Arriba',
	},
	{
		CPRO: '28',
		CMUN: '054',
		DC: '9',
		NOMBRE: 'Escorial, El',
	},
	{
		CPRO: '28',
		CMUN: '055',
		DC: '2',
		NOMBRE: 'Estremera',
	},
	{
		CPRO: '28',
		CMUN: '056',
		DC: '5',
		NOMBRE: 'Fresnedillas de la Oliva',
	},
	{
		CPRO: '28',
		CMUN: '057',
		DC: '1',
		NOMBRE: 'Fresno de Torote',
	},
	{
		CPRO: '28',
		CMUN: '058',
		DC: '7',
		NOMBRE: 'Fuenlabrada',
	},
	{
		CPRO: '28',
		CMUN: '059',
		DC: '0',
		NOMBRE: 'Fuente el Saz de Jarama',
	},
	{
		CPRO: '28',
		CMUN: '060',
		DC: '4',
		NOMBRE: 'Fuentidueña de Tajo',
	},
	{
		CPRO: '28',
		CMUN: '061',
		DC: '1',
		NOMBRE: 'Galapagar',
	},
	{
		CPRO: '28',
		CMUN: '062',
		DC: '6',
		NOMBRE: 'Garganta de los Montes',
	},
	{
		CPRO: '28',
		CMUN: '063',
		DC: '2',
		NOMBRE: 'Gargantilla del Lozoya y Pinilla de Buitrago',
	},
	{
		CPRO: '28',
		CMUN: '064',
		DC: '7',
		NOMBRE: 'Gascones',
	},
	{
		CPRO: '28',
		CMUN: '065',
		DC: '0',
		NOMBRE: 'Getafe',
	},
	{
		CPRO: '28',
		CMUN: '066',
		DC: '3',
		NOMBRE: 'Griñón',
	},
	{
		CPRO: '28',
		CMUN: '067',
		DC: '9',
		NOMBRE: 'Guadalix de la Sierra',
	},
	{
		CPRO: '28',
		CMUN: '068',
		DC: '5',
		NOMBRE: 'Guadarrama',
	},
	{
		CPRO: '28',
		CMUN: '069',
		DC: '8',
		NOMBRE: 'Hiruela, La',
	},
	{
		CPRO: '28',
		CMUN: '070',
		DC: '2',
		NOMBRE: 'Horcajo de la Sierra-Aoslos',
	},
	{
		CPRO: '28',
		CMUN: '071',
		DC: '9',
		NOMBRE: 'Horcajuelo de la Sierra',
	},
	{
		CPRO: '28',
		CMUN: '072',
		DC: '4',
		NOMBRE: 'Hoyo de Manzanares',
	},
	{
		CPRO: '28',
		CMUN: '073',
		DC: '0',
		NOMBRE: 'Humanes de Madrid',
	},
	{
		CPRO: '28',
		CMUN: '074',
		DC: '5',
		NOMBRE: 'Leganés',
	},
	{
		CPRO: '28',
		CMUN: '075',
		DC: '8',
		NOMBRE: 'Loeches',
	},
	{
		CPRO: '28',
		CMUN: '076',
		DC: '1',
		NOMBRE: 'Lozoya',
	},
	{
		CPRO: '28',
		CMUN: '901',
		DC: '5',
		NOMBRE: 'Lozoyuela-Navas-Sieteiglesias',
	},
	{
		CPRO: '28',
		CMUN: '078',
		DC: '3',
		NOMBRE: 'Madarcos',
	},
	{
		CPRO: '28',
		CMUN: '079',
		DC: '6',
		NOMBRE: 'Madrid',
	},
	{
		CPRO: '28',
		CMUN: '080',
		DC: '0',
		NOMBRE: 'Majadahonda',
	},
	{
		CPRO: '28',
		CMUN: '082',
		DC: '2',
		NOMBRE: 'Manzanares el Real',
	},
	{
		CPRO: '28',
		CMUN: '083',
		DC: '8',
		NOMBRE: 'Meco',
	},
	{
		CPRO: '28',
		CMUN: '084',
		DC: '3',
		NOMBRE: 'Mejorada del Campo',
	},
	{
		CPRO: '28',
		CMUN: '085',
		DC: '6',
		NOMBRE: 'Miraflores de la Sierra',
	},
	{
		CPRO: '28',
		CMUN: '086',
		DC: '9',
		NOMBRE: 'Molar, El',
	},
	{
		CPRO: '28',
		CMUN: '087',
		DC: '5',
		NOMBRE: 'Molinos, Los',
	},
	{
		CPRO: '28',
		CMUN: '088',
		DC: '1',
		NOMBRE: 'Montejo de la Sierra',
	},
	{
		CPRO: '28',
		CMUN: '089',
		DC: '4',
		NOMBRE: 'Moraleja de Enmedio',
	},
	{
		CPRO: '28',
		CMUN: '090',
		DC: '8',
		NOMBRE: 'Moralzarzal',
	},
	{
		CPRO: '28',
		CMUN: '091',
		DC: '5',
		NOMBRE: 'Morata de Tajuña',
	},
	{
		CPRO: '28',
		CMUN: '092',
		DC: '0',
		NOMBRE: 'Móstoles',
	},
	{
		CPRO: '28',
		CMUN: '093',
		DC: '6',
		NOMBRE: 'Navacerrada',
	},
	{
		CPRO: '28',
		CMUN: '094',
		DC: '1',
		NOMBRE: 'Navalafuente',
	},
	{
		CPRO: '28',
		CMUN: '095',
		DC: '4',
		NOMBRE: 'Navalagamella',
	},
	{
		CPRO: '28',
		CMUN: '096',
		DC: '7',
		NOMBRE: 'Navalcarnero',
	},
	{
		CPRO: '28',
		CMUN: '097',
		DC: '3',
		NOMBRE: 'Navarredonda y San Mamés',
	},
	{
		CPRO: '28',
		CMUN: '099',
		DC: '2',
		NOMBRE: 'Navas del Rey',
	},
	{
		CPRO: '28',
		CMUN: '100',
		DC: '6',
		NOMBRE: 'Nuevo Baztán',
	},
	{
		CPRO: '28',
		CMUN: '101',
		DC: '3',
		NOMBRE: 'Olmeda de las Fuentes',
	},
	{
		CPRO: '28',
		CMUN: '102',
		DC: '8',
		NOMBRE: 'Orusco de Tajuña',
	},
	{
		CPRO: '28',
		CMUN: '104',
		DC: '9',
		NOMBRE: 'Paracuellos de Jarama',
	},
	{
		CPRO: '28',
		CMUN: '106',
		DC: '5',
		NOMBRE: 'Parla',
	},
	{
		CPRO: '28',
		CMUN: '107',
		DC: '1',
		NOMBRE: 'Patones',
	},
	{
		CPRO: '28',
		CMUN: '108',
		DC: '7',
		NOMBRE: 'Pedrezuela',
	},
	{
		CPRO: '28',
		CMUN: '109',
		DC: '0',
		NOMBRE: 'Pelayos de la Presa',
	},
	{
		CPRO: '28',
		CMUN: '110',
		DC: '4',
		NOMBRE: 'Perales de Tajuña',
	},
	{
		CPRO: '28',
		CMUN: '111',
		DC: '1',
		NOMBRE: 'Pezuela de las Torres',
	},
	{
		CPRO: '28',
		CMUN: '112',
		DC: '6',
		NOMBRE: 'Pinilla del Valle',
	},
	{
		CPRO: '28',
		CMUN: '113',
		DC: '2',
		NOMBRE: 'Pinto',
	},
	{
		CPRO: '28',
		CMUN: '114',
		DC: '7',
		NOMBRE: 'Piñuécar-Gandullas',
	},
	{
		CPRO: '28',
		CMUN: '115',
		DC: '0',
		NOMBRE: 'Pozuelo de Alarcón',
	},
	{
		CPRO: '28',
		CMUN: '116',
		DC: '3',
		NOMBRE: 'Pozuelo del Rey',
	},
	{
		CPRO: '28',
		CMUN: '117',
		DC: '9',
		NOMBRE: 'Prádena del Rincón',
	},
	{
		CPRO: '28',
		CMUN: '118',
		DC: '5',
		NOMBRE: 'Puebla de la Sierra',
	},
	{
		CPRO: '28',
		CMUN: '902',
		DC: '0',
		NOMBRE: 'Puentes Viejas',
	},
	{
		CPRO: '28',
		CMUN: '119',
		DC: '8',
		NOMBRE: 'Quijorna',
	},
	{
		CPRO: '28',
		CMUN: '120',
		DC: '2',
		NOMBRE: 'Rascafría',
	},
	{
		CPRO: '28',
		CMUN: '121',
		DC: '9',
		NOMBRE: 'Redueña',
	},
	{
		CPRO: '28',
		CMUN: '122',
		DC: '4',
		NOMBRE: 'Ribatejada',
	},
	{
		CPRO: '28',
		CMUN: '123',
		DC: '0',
		NOMBRE: 'Rivas-Vaciamadrid',
	},
	{
		CPRO: '28',
		CMUN: '124',
		DC: '5',
		NOMBRE: 'Robledillo de la Jara',
	},
	{
		CPRO: '28',
		CMUN: '125',
		DC: '8',
		NOMBRE: 'Robledo de Chavela',
	},
	{
		CPRO: '28',
		CMUN: '126',
		DC: '1',
		NOMBRE: 'Robregordo',
	},
	{
		CPRO: '28',
		CMUN: '127',
		DC: '7',
		NOMBRE: 'Rozas de Madrid, Las',
	},
	{
		CPRO: '28',
		CMUN: '128',
		DC: '3',
		NOMBRE: 'Rozas de Puerto Real',
	},
	{
		CPRO: '28',
		CMUN: '129',
		DC: '6',
		NOMBRE: 'San Agustín del Guadalix',
	},
	{
		CPRO: '28',
		CMUN: '130',
		DC: '0',
		NOMBRE: 'San Fernando de Henares',
	},
	{
		CPRO: '28',
		CMUN: '131',
		DC: '7',
		NOMBRE: 'San Lorenzo de El Escorial',
	},
	{
		CPRO: '28',
		CMUN: '132',
		DC: '2',
		NOMBRE: 'San Martín de la Vega',
	},
	{
		CPRO: '28',
		CMUN: '133',
		DC: '8',
		NOMBRE: 'San Martín de Valdeiglesias',
	},
	{
		CPRO: '28',
		CMUN: '134',
		DC: '3',
		NOMBRE: 'San Sebastián de los Reyes',
	},
	{
		CPRO: '28',
		CMUN: '135',
		DC: '6',
		NOMBRE: 'Santa María de la Alameda',
	},
	{
		CPRO: '28',
		CMUN: '136',
		DC: '9',
		NOMBRE: 'Santorcaz',
	},
	{
		CPRO: '28',
		CMUN: '137',
		DC: '5',
		NOMBRE: 'Santos de la Humosa, Los',
	},
	{
		CPRO: '28',
		CMUN: '138',
		DC: '1',
		NOMBRE: 'Serna del Monte, La',
	},
	{
		CPRO: '28',
		CMUN: '140',
		DC: '8',
		NOMBRE: 'Serranillos del Valle',
	},
	{
		CPRO: '28',
		CMUN: '141',
		DC: '5',
		NOMBRE: 'Sevilla la Nueva',
	},
	{
		CPRO: '28',
		CMUN: '143',
		DC: '6',
		NOMBRE: 'Somosierra',
	},
	{
		CPRO: '28',
		CMUN: '144',
		DC: '1',
		NOMBRE: 'Soto del Real',
	},
	{
		CPRO: '28',
		CMUN: '145',
		DC: '4',
		NOMBRE: 'Talamanca de Jarama',
	},
	{
		CPRO: '28',
		CMUN: '146',
		DC: '7',
		NOMBRE: 'Tielmes',
	},
	{
		CPRO: '28',
		CMUN: '147',
		DC: '3',
		NOMBRE: 'Titulcia',
	},
	{
		CPRO: '28',
		CMUN: '148',
		DC: '9',
		NOMBRE: 'Torrejón de Ardoz',
	},
	{
		CPRO: '28',
		CMUN: '149',
		DC: '2',
		NOMBRE: 'Torrejón de la Calzada',
	},
	{
		CPRO: '28',
		CMUN: '150',
		DC: '5',
		NOMBRE: 'Torrejón de Velasco',
	},
	{
		CPRO: '28',
		CMUN: '151',
		DC: '2',
		NOMBRE: 'Torrelaguna',
	},
	{
		CPRO: '28',
		CMUN: '152',
		DC: '7',
		NOMBRE: 'Torrelodones',
	},
	{
		CPRO: '28',
		CMUN: '153',
		DC: '3',
		NOMBRE: 'Torremocha de Jarama',
	},
	{
		CPRO: '28',
		CMUN: '154',
		DC: '8',
		NOMBRE: 'Torres de la Alameda',
	},
	{
		CPRO: '28',
		CMUN: '903',
		DC: '6',
		NOMBRE: 'Tres Cantos',
	},
	{
		CPRO: '28',
		CMUN: '155',
		DC: '1',
		NOMBRE: 'Valdaracete',
	},
	{
		CPRO: '28',
		CMUN: '156',
		DC: '4',
		NOMBRE: 'Valdeavero',
	},
	{
		CPRO: '28',
		CMUN: '157',
		DC: '0',
		NOMBRE: 'Valdelaguna',
	},
	{
		CPRO: '28',
		CMUN: '158',
		DC: '6',
		NOMBRE: 'Valdemanco',
	},
	{
		CPRO: '28',
		CMUN: '159',
		DC: '9',
		NOMBRE: 'Valdemaqueda',
	},
	{
		CPRO: '28',
		CMUN: '160',
		DC: '3',
		NOMBRE: 'Valdemorillo',
	},
	{
		CPRO: '28',
		CMUN: '161',
		DC: '0',
		NOMBRE: 'Valdemoro',
	},
	{
		CPRO: '28',
		CMUN: '162',
		DC: '5',
		NOMBRE: 'Valdeolmos-Alalpardo',
	},
	{
		CPRO: '28',
		CMUN: '163',
		DC: '1',
		NOMBRE: 'Valdepiélagos',
	},
	{
		CPRO: '28',
		CMUN: '164',
		DC: '6',
		NOMBRE: 'Valdetorres de Jarama',
	},
	{
		CPRO: '28',
		CMUN: '165',
		DC: '9',
		NOMBRE: 'Valdilecha',
	},
	{
		CPRO: '28',
		CMUN: '166',
		DC: '2',
		NOMBRE: 'Valverde de Alcalá',
	},
	{
		CPRO: '28',
		CMUN: '167',
		DC: '8',
		NOMBRE: 'Velilla de San Antonio',
	},
	{
		CPRO: '28',
		CMUN: '168',
		DC: '4',
		NOMBRE: 'Vellón, El',
	},
	{
		CPRO: '28',
		CMUN: '169',
		DC: '7',
		NOMBRE: 'Venturada',
	},
	{
		CPRO: '28',
		CMUN: '171',
		DC: '8',
		NOMBRE: 'Villa del Prado',
	},
	{
		CPRO: '28',
		CMUN: '170',
		DC: '1',
		NOMBRE: 'Villaconejos',
	},
	{
		CPRO: '28',
		CMUN: '172',
		DC: '3',
		NOMBRE: 'Villalbilla',
	},
	{
		CPRO: '28',
		CMUN: '173',
		DC: '9',
		NOMBRE: 'Villamanrique de Tajo',
	},
	{
		CPRO: '28',
		CMUN: '174',
		DC: '4',
		NOMBRE: 'Villamanta',
	},
	{
		CPRO: '28',
		CMUN: '175',
		DC: '7',
		NOMBRE: 'Villamantilla',
	},
	{
		CPRO: '28',
		CMUN: '176',
		DC: '0',
		NOMBRE: 'Villanueva de la Cañada',
	},
	{
		CPRO: '28',
		CMUN: '178',
		DC: '2',
		NOMBRE: 'Villanueva de Perales',
	},
	{
		CPRO: '28',
		CMUN: '177',
		DC: '6',
		NOMBRE: 'Villanueva del Pardillo',
	},
	{
		CPRO: '28',
		CMUN: '179',
		DC: '5',
		NOMBRE: 'Villar del Olmo',
	},
	{
		CPRO: '28',
		CMUN: '180',
		DC: '9',
		NOMBRE: 'Villarejo de Salvanés',
	},
	{
		CPRO: '28',
		CMUN: '181',
		DC: '6',
		NOMBRE: 'Villaviciosa de Odón',
	},
	{
		CPRO: '28',
		CMUN: '182',
		DC: '1',
		NOMBRE: 'Villavieja del Lozoya',
	},
	{
		CPRO: '28',
		CMUN: '183',
		DC: '7',
		NOMBRE: 'Zarzalejo',
	},
	{
		CPRO: '29',
		CMUN: '001',
		DC: '7',
		NOMBRE: 'Alameda',
	},
	{
		CPRO: '29',
		CMUN: '002',
		DC: '2',
		NOMBRE: 'Alcaucín',
	},
	{
		CPRO: '29',
		CMUN: '003',
		DC: '8',
		NOMBRE: 'Alfarnate',
	},
	{
		CPRO: '29',
		CMUN: '004',
		DC: '3',
		NOMBRE: 'Alfarnatejo',
	},
	{
		CPRO: '29',
		CMUN: '005',
		DC: '6',
		NOMBRE: 'Algarrobo',
	},
	{
		CPRO: '29',
		CMUN: '006',
		DC: '9',
		NOMBRE: 'Algatocín',
	},
	{
		CPRO: '29',
		CMUN: '007',
		DC: '5',
		NOMBRE: 'Alhaurín de la Torre',
	},
	{
		CPRO: '29',
		CMUN: '008',
		DC: '1',
		NOMBRE: 'Alhaurín el Grande',
	},
	{
		CPRO: '29',
		CMUN: '009',
		DC: '4',
		NOMBRE: 'Almáchar',
	},
	{
		CPRO: '29',
		CMUN: '010',
		DC: '8',
		NOMBRE: 'Almargen',
	},
	{
		CPRO: '29',
		CMUN: '011',
		DC: '5',
		NOMBRE: 'Almogía',
	},
	{
		CPRO: '29',
		CMUN: '012',
		DC: '0',
		NOMBRE: 'Álora',
	},
	{
		CPRO: '29',
		CMUN: '013',
		DC: '6',
		NOMBRE: 'Alozaina',
	},
	{
		CPRO: '29',
		CMUN: '014',
		DC: '1',
		NOMBRE: 'Alpandeire',
	},
	{
		CPRO: '29',
		CMUN: '015',
		DC: '4',
		NOMBRE: 'Antequera',
	},
	{
		CPRO: '29',
		CMUN: '016',
		DC: '7',
		NOMBRE: 'Árchez',
	},
	{
		CPRO: '29',
		CMUN: '017',
		DC: '3',
		NOMBRE: 'Archidona',
	},
	{
		CPRO: '29',
		CMUN: '018',
		DC: '9',
		NOMBRE: 'Ardales',
	},
	{
		CPRO: '29',
		CMUN: '019',
		DC: '2',
		NOMBRE: 'Arenas',
	},
	{
		CPRO: '29',
		CMUN: '020',
		DC: '6',
		NOMBRE: 'Arriate',
	},
	{
		CPRO: '29',
		CMUN: '021',
		DC: '3',
		NOMBRE: 'Atajate',
	},
	{
		CPRO: '29',
		CMUN: '022',
		DC: '8',
		NOMBRE: 'Benadalid',
	},
	{
		CPRO: '29',
		CMUN: '023',
		DC: '4',
		NOMBRE: 'Benahavís',
	},
	{
		CPRO: '29',
		CMUN: '024',
		DC: '9',
		NOMBRE: 'Benalauría',
	},
	{
		CPRO: '29',
		CMUN: '025',
		DC: '2',
		NOMBRE: 'Benalmádena',
	},
	{
		CPRO: '29',
		CMUN: '026',
		DC: '5',
		NOMBRE: 'Benamargosa',
	},
	{
		CPRO: '29',
		CMUN: '027',
		DC: '1',
		NOMBRE: 'Benamocarra',
	},
	{
		CPRO: '29',
		CMUN: '028',
		DC: '7',
		NOMBRE: 'Benaoján',
	},
	{
		CPRO: '29',
		CMUN: '029',
		DC: '0',
		NOMBRE: 'Benarrabá',
	},
	{
		CPRO: '29',
		CMUN: '030',
		DC: '4',
		NOMBRE: 'Borge, El',
	},
	{
		CPRO: '29',
		CMUN: '031',
		DC: '1',
		NOMBRE: 'Burgo, El',
	},
	{
		CPRO: '29',
		CMUN: '032',
		DC: '6',
		NOMBRE: 'Campillos',
	},
	{
		CPRO: '29',
		CMUN: '033',
		DC: '2',
		NOMBRE: 'Canillas de Aceituno',
	},
	{
		CPRO: '29',
		CMUN: '034',
		DC: '7',
		NOMBRE: 'Canillas de Albaida',
	},
	{
		CPRO: '29',
		CMUN: '035',
		DC: '0',
		NOMBRE: 'Cañete la Real',
	},
	{
		CPRO: '29',
		CMUN: '036',
		DC: '3',
		NOMBRE: 'Carratraca',
	},
	{
		CPRO: '29',
		CMUN: '037',
		DC: '9',
		NOMBRE: 'Cartajima',
	},
	{
		CPRO: '29',
		CMUN: '038',
		DC: '5',
		NOMBRE: 'Cártama',
	},
	{
		CPRO: '29',
		CMUN: '039',
		DC: '8',
		NOMBRE: 'Casabermeja',
	},
	{
		CPRO: '29',
		CMUN: '040',
		DC: '2',
		NOMBRE: 'Casarabonela',
	},
	{
		CPRO: '29',
		CMUN: '041',
		DC: '9',
		NOMBRE: 'Casares',
	},
	{
		CPRO: '29',
		CMUN: '042',
		DC: '4',
		NOMBRE: 'Coín',
	},
	{
		CPRO: '29',
		CMUN: '043',
		DC: '0',
		NOMBRE: 'Colmenar',
	},
	{
		CPRO: '29',
		CMUN: '044',
		DC: '5',
		NOMBRE: 'Comares',
	},
	{
		CPRO: '29',
		CMUN: '045',
		DC: '8',
		NOMBRE: 'Cómpeta',
	},
	{
		CPRO: '29',
		CMUN: '046',
		DC: '1',
		NOMBRE: 'Cortes de la Frontera',
	},
	{
		CPRO: '29',
		CMUN: '047',
		DC: '7',
		NOMBRE: 'Cuevas Bajas',
	},
	{
		CPRO: '29',
		CMUN: '049',
		DC: '6',
		NOMBRE: 'Cuevas de San Marcos',
	},
	{
		CPRO: '29',
		CMUN: '048',
		DC: '3',
		NOMBRE: 'Cuevas del Becerro',
	},
	{
		CPRO: '29',
		CMUN: '050',
		DC: '9',
		NOMBRE: 'Cútar',
	},
	{
		CPRO: '29',
		CMUN: '051',
		DC: '6',
		NOMBRE: 'Estepona',
	},
	{
		CPRO: '29',
		CMUN: '052',
		DC: '1',
		NOMBRE: 'Faraján',
	},
	{
		CPRO: '29',
		CMUN: '053',
		DC: '7',
		NOMBRE: 'Frigiliana',
	},
	{
		CPRO: '29',
		CMUN: '054',
		DC: '2',
		NOMBRE: 'Fuengirola',
	},
	{
		CPRO: '29',
		CMUN: '055',
		DC: '5',
		NOMBRE: 'Fuente de Piedra',
	},
	{
		CPRO: '29',
		CMUN: '056',
		DC: '8',
		NOMBRE: 'Gaucín',
	},
	{
		CPRO: '29',
		CMUN: '057',
		DC: '4',
		NOMBRE: 'Genalguacil',
	},
	{
		CPRO: '29',
		CMUN: '058',
		DC: '0',
		NOMBRE: 'Guaro',
	},
	{
		CPRO: '29',
		CMUN: '059',
		DC: '3',
		NOMBRE: 'Humilladero',
	},
	{
		CPRO: '29',
		CMUN: '060',
		DC: '7',
		NOMBRE: 'Igualeja',
	},
	{
		CPRO: '29',
		CMUN: '061',
		DC: '4',
		NOMBRE: 'Istán',
	},
	{
		CPRO: '29',
		CMUN: '062',
		DC: '9',
		NOMBRE: 'Iznate',
	},
	{
		CPRO: '29',
		CMUN: '063',
		DC: '5',
		NOMBRE: 'Jimera de Líbar',
	},
	{
		CPRO: '29',
		CMUN: '064',
		DC: '0',
		NOMBRE: 'Jubrique',
	},
	{
		CPRO: '29',
		CMUN: '065',
		DC: '3',
		NOMBRE: 'Júzcar',
	},
	{
		CPRO: '29',
		CMUN: '066',
		DC: '6',
		NOMBRE: 'Macharaviaya',
	},
	{
		CPRO: '29',
		CMUN: '067',
		DC: '2',
		NOMBRE: 'Málaga',
	},
	{
		CPRO: '29',
		CMUN: '068',
		DC: '8',
		NOMBRE: 'Manilva',
	},
	{
		CPRO: '29',
		CMUN: '069',
		DC: '1',
		NOMBRE: 'Marbella',
	},
	{
		CPRO: '29',
		CMUN: '070',
		DC: '5',
		NOMBRE: 'Mijas',
	},
	{
		CPRO: '29',
		CMUN: '071',
		DC: '2',
		NOMBRE: 'Moclinejo',
	},
	{
		CPRO: '29',
		CMUN: '072',
		DC: '7',
		NOMBRE: 'Mollina',
	},
	{
		CPRO: '29',
		CMUN: '073',
		DC: '3',
		NOMBRE: 'Monda',
	},
	{
		CPRO: '29',
		CMUN: '074',
		DC: '8',
		NOMBRE: 'Montejaque',
	},
	{
		CPRO: '29',
		CMUN: '075',
		DC: '1',
		NOMBRE: 'Nerja',
	},
	{
		CPRO: '29',
		CMUN: '076',
		DC: '4',
		NOMBRE: 'Ojén',
	},
	{
		CPRO: '29',
		CMUN: '077',
		DC: '0',
		NOMBRE: 'Parauta',
	},
	{
		CPRO: '29',
		CMUN: '079',
		DC: '9',
		NOMBRE: 'Periana',
	},
	{
		CPRO: '29',
		CMUN: '080',
		DC: '3',
		NOMBRE: 'Pizarra',
	},
	{
		CPRO: '29',
		CMUN: '081',
		DC: '0',
		NOMBRE: 'Pujerra',
	},
	{
		CPRO: '29',
		CMUN: '082',
		DC: '5',
		NOMBRE: 'Rincón de la Victoria',
	},
	{
		CPRO: '29',
		CMUN: '083',
		DC: '1',
		NOMBRE: 'Riogordo',
	},
	{
		CPRO: '29',
		CMUN: '084',
		DC: '6',
		NOMBRE: 'Ronda',
	},
	{
		CPRO: '29',
		CMUN: '085',
		DC: '9',
		NOMBRE: 'Salares',
	},
	{
		CPRO: '29',
		CMUN: '086',
		DC: '2',
		NOMBRE: 'Sayalonga',
	},
	{
		CPRO: '29',
		CMUN: '087',
		DC: '8',
		NOMBRE: 'Sedella',
	},
	{
		CPRO: '29',
		CMUN: '088',
		DC: '4',
		NOMBRE: 'Sierra de Yeguas',
	},
	{
		CPRO: '29',
		CMUN: '089',
		DC: '7',
		NOMBRE: 'Teba',
	},
	{
		CPRO: '29',
		CMUN: '090',
		DC: '1',
		NOMBRE: 'Tolox',
	},
	{
		CPRO: '29',
		CMUN: '901',
		DC: '8',
		NOMBRE: 'Torremolinos',
	},
	{
		CPRO: '29',
		CMUN: '091',
		DC: '8',
		NOMBRE: 'Torrox',
	},
	{
		CPRO: '29',
		CMUN: '092',
		DC: '3',
		NOMBRE: 'Totalán',
	},
	{
		CPRO: '29',
		CMUN: '093',
		DC: '9',
		NOMBRE: 'Valle de Abdalajís',
	},
	{
		CPRO: '29',
		CMUN: '094',
		DC: '4',
		NOMBRE: 'Vélez-Málaga',
	},
	{
		CPRO: '29',
		CMUN: '095',
		DC: '7',
		NOMBRE: 'Villanueva de Algaidas',
	},
	{
		CPRO: '29',
		CMUN: '902',
		DC: '3',
		NOMBRE: 'Villanueva de la Concepción',
	},
	{
		CPRO: '29',
		CMUN: '098',
		DC: '2',
		NOMBRE: 'Villanueva de Tapia',
	},
	{
		CPRO: '29',
		CMUN: '096',
		DC: '0',
		NOMBRE: 'Villanueva del Rosario',
	},
	{
		CPRO: '29',
		CMUN: '097',
		DC: '6',
		NOMBRE: 'Villanueva del Trabuco',
	},
	{
		CPRO: '29',
		CMUN: '099',
		DC: '5',
		NOMBRE: 'Viñuela',
	},
	{
		CPRO: '29',
		CMUN: '100',
		DC: '9',
		NOMBRE: 'Yunquera',
	},
	{
		CPRO: '30',
		CMUN: '001',
		DC: '1',
		NOMBRE: 'Abanilla',
	},
	{
		CPRO: '30',
		CMUN: '002',
		DC: '6',
		NOMBRE: 'Abarán',
	},
	{
		CPRO: '30',
		CMUN: '003',
		DC: '2',
		NOMBRE: 'Águilas',
	},
	{
		CPRO: '30',
		CMUN: '004',
		DC: '7',
		NOMBRE: 'Albudeite',
	},
	{
		CPRO: '30',
		CMUN: '005',
		DC: '0',
		NOMBRE: 'Alcantarilla',
	},
	{
		CPRO: '30',
		CMUN: '902',
		DC: '7',
		NOMBRE: 'Alcázares, Los',
	},
	{
		CPRO: '30',
		CMUN: '006',
		DC: '3',
		NOMBRE: 'Aledo',
	},
	{
		CPRO: '30',
		CMUN: '007',
		DC: '9',
		NOMBRE: 'Alguazas',
	},
	{
		CPRO: '30',
		CMUN: '008',
		DC: '5',
		NOMBRE: 'Alhama de Murcia',
	},
	{
		CPRO: '30',
		CMUN: '009',
		DC: '8',
		NOMBRE: 'Archena',
	},
	{
		CPRO: '30',
		CMUN: '010',
		DC: '2',
		NOMBRE: 'Beniel',
	},
	{
		CPRO: '30',
		CMUN: '011',
		DC: '9',
		NOMBRE: 'Blanca',
	},
	{
		CPRO: '30',
		CMUN: '012',
		DC: '4',
		NOMBRE: 'Bullas',
	},
	{
		CPRO: '30',
		CMUN: '013',
		DC: '0',
		NOMBRE: 'Calasparra',
	},
	{
		CPRO: '30',
		CMUN: '014',
		DC: '5',
		NOMBRE: 'Campos del Río',
	},
	{
		CPRO: '30',
		CMUN: '015',
		DC: '8',
		NOMBRE: 'Caravaca de la Cruz',
	},
	{
		CPRO: '30',
		CMUN: '016',
		DC: '1',
		NOMBRE: 'Cartagena',
	},
	{
		CPRO: '30',
		CMUN: '017',
		DC: '7',
		NOMBRE: 'Cehegín',
	},
	{
		CPRO: '30',
		CMUN: '018',
		DC: '3',
		NOMBRE: 'Ceutí',
	},
	{
		CPRO: '30',
		CMUN: '019',
		DC: '6',
		NOMBRE: 'Cieza',
	},
	{
		CPRO: '30',
		CMUN: '020',
		DC: '0',
		NOMBRE: 'Fortuna',
	},
	{
		CPRO: '30',
		CMUN: '021',
		DC: '7',
		NOMBRE: 'Fuente Álamo de Murcia',
	},
	{
		CPRO: '30',
		CMUN: '022',
		DC: '2',
		NOMBRE: 'Jumilla',
	},
	{
		CPRO: '30',
		CMUN: '023',
		DC: '8',
		NOMBRE: 'Librilla',
	},
	{
		CPRO: '30',
		CMUN: '024',
		DC: '3',
		NOMBRE: 'Lorca',
	},
	{
		CPRO: '30',
		CMUN: '025',
		DC: '6',
		NOMBRE: 'Lorquí',
	},
	{
		CPRO: '30',
		CMUN: '026',
		DC: '9',
		NOMBRE: 'Mazarrón',
	},
	{
		CPRO: '30',
		CMUN: '027',
		DC: '5',
		NOMBRE: 'Molina de Segura',
	},
	{
		CPRO: '30',
		CMUN: '028',
		DC: '1',
		NOMBRE: 'Moratalla',
	},
	{
		CPRO: '30',
		CMUN: '029',
		DC: '4',
		NOMBRE: 'Mula',
	},
	{
		CPRO: '30',
		CMUN: '030',
		DC: '8',
		NOMBRE: 'Murcia',
	},
	{
		CPRO: '30',
		CMUN: '031',
		DC: '5',
		NOMBRE: 'Ojós',
	},
	{
		CPRO: '30',
		CMUN: '032',
		DC: '0',
		NOMBRE: 'Pliego',
	},
	{
		CPRO: '30',
		CMUN: '033',
		DC: '6',
		NOMBRE: 'Puerto Lumbreras',
	},
	{
		CPRO: '30',
		CMUN: '034',
		DC: '1',
		NOMBRE: 'Ricote',
	},
	{
		CPRO: '30',
		CMUN: '035',
		DC: '4',
		NOMBRE: 'San Javier',
	},
	{
		CPRO: '30',
		CMUN: '036',
		DC: '7',
		NOMBRE: 'San Pedro del Pinatar',
	},
	{
		CPRO: '30',
		CMUN: '901',
		DC: '2',
		NOMBRE: 'Santomera',
	},
	{
		CPRO: '30',
		CMUN: '037',
		DC: '3',
		NOMBRE: 'Torre-Pacheco',
	},
	{
		CPRO: '30',
		CMUN: '038',
		DC: '9',
		NOMBRE: 'Torres de Cotillas, Las',
	},
	{
		CPRO: '30',
		CMUN: '039',
		DC: '2',
		NOMBRE: 'Totana',
	},
	{
		CPRO: '30',
		CMUN: '040',
		DC: '6',
		NOMBRE: 'Ulea',
	},
	{
		CPRO: '30',
		CMUN: '041',
		DC: '3',
		NOMBRE: 'Unión, La',
	},
	{
		CPRO: '30',
		CMUN: '042',
		DC: '8',
		NOMBRE: 'Villanueva del Río Segura',
	},
	{
		CPRO: '30',
		CMUN: '043',
		DC: '4',
		NOMBRE: 'Yecla',
	},
	{
		CPRO: '31',
		CMUN: '001',
		DC: '8',
		NOMBRE: 'Abáigar',
	},
	{
		CPRO: '31',
		CMUN: '002',
		DC: '3',
		NOMBRE: 'Abárzuza',
	},
	{
		CPRO: '31',
		CMUN: '003',
		DC: '9',
		NOMBRE: 'Abaurregaina/Abaurrea Alta',
	},
	{
		CPRO: '31',
		CMUN: '004',
		DC: '4',
		NOMBRE: 'Abaurrepea/Abaurrea Baja',
	},
	{
		CPRO: '31',
		CMUN: '005',
		DC: '7',
		NOMBRE: 'Aberin',
	},
	{
		CPRO: '31',
		CMUN: '006',
		DC: '0',
		NOMBRE: 'Ablitas',
	},
	{
		CPRO: '31',
		CMUN: '007',
		DC: '6',
		NOMBRE: 'Adiós',
	},
	{
		CPRO: '31',
		CMUN: '008',
		DC: '2',
		NOMBRE: 'Aguilar de Codés',
	},
	{
		CPRO: '31',
		CMUN: '009',
		DC: '5',
		NOMBRE: 'Aibar/Oibar',
	},
	{
		CPRO: '31',
		CMUN: '011',
		DC: '6',
		NOMBRE: 'Allín',
	},
	{
		CPRO: '31',
		CMUN: '012',
		DC: '1',
		NOMBRE: 'Allo',
	},
	{
		CPRO: '31',
		CMUN: '010',
		DC: '9',
		NOMBRE: 'Altsasu/Alsasua',
	},
	{
		CPRO: '31',
		CMUN: '013',
		DC: '7',
		NOMBRE: 'Améscoa Baja',
	},
	{
		CPRO: '31',
		CMUN: '014',
		DC: '2',
		NOMBRE: 'Ancín/Antzin',
	},
	{
		CPRO: '31',
		CMUN: '015',
		DC: '5',
		NOMBRE: 'Andosilla',
	},
	{
		CPRO: '31',
		CMUN: '016',
		DC: '8',
		NOMBRE: 'Ansoáin/Antsoain',
	},
	{
		CPRO: '31',
		CMUN: '017',
		DC: '4',
		NOMBRE: 'Anue',
	},
	{
		CPRO: '31',
		CMUN: '018',
		DC: '0',
		NOMBRE: 'Añorbe',
	},
	{
		CPRO: '31',
		CMUN: '019',
		DC: '3',
		NOMBRE: 'Aoiz/Agoitz',
	},
	{
		CPRO: '31',
		CMUN: '020',
		DC: '7',
		NOMBRE: 'Araitz',
	},
	{
		CPRO: '31',
		CMUN: '025',
		DC: '3',
		NOMBRE: 'Arakil',
	},
	{
		CPRO: '31',
		CMUN: '021',
		DC: '4',
		NOMBRE: 'Aranarache/Aranaratxe',
	},
	{
		CPRO: '31',
		CMUN: '023',
		DC: '5',
		NOMBRE: 'Aranguren',
	},
	{
		CPRO: '31',
		CMUN: '024',
		DC: '0',
		NOMBRE: 'Arano',
	},
	{
		CPRO: '31',
		CMUN: '022',
		DC: '9',
		NOMBRE: 'Arantza',
	},
	{
		CPRO: '31',
		CMUN: '026',
		DC: '6',
		NOMBRE: 'Aras',
	},
	{
		CPRO: '31',
		CMUN: '027',
		DC: '2',
		NOMBRE: 'Arbizu',
	},
	{
		CPRO: '31',
		CMUN: '028',
		DC: '8',
		NOMBRE: 'Arce/Artzi',
	},
	{
		CPRO: '31',
		CMUN: '029',
		DC: '1',
		NOMBRE: 'Arcos, Los',
	},
	{
		CPRO: '31',
		CMUN: '030',
		DC: '5',
		NOMBRE: 'Arellano',
	},
	{
		CPRO: '31',
		CMUN: '031',
		DC: '2',
		NOMBRE: 'Areso',
	},
	{
		CPRO: '31',
		CMUN: '032',
		DC: '7',
		NOMBRE: 'Arguedas',
	},
	{
		CPRO: '31',
		CMUN: '033',
		DC: '3',
		NOMBRE: 'Aria',
	},
	{
		CPRO: '31',
		CMUN: '034',
		DC: '8',
		NOMBRE: 'Aribe',
	},
	{
		CPRO: '31',
		CMUN: '035',
		DC: '1',
		NOMBRE: 'Armañanzas',
	},
	{
		CPRO: '31',
		CMUN: '036',
		DC: '4',
		NOMBRE: 'Arróniz',
	},
	{
		CPRO: '31',
		CMUN: '037',
		DC: '0',
		NOMBRE: 'Arruazu',
	},
	{
		CPRO: '31',
		CMUN: '038',
		DC: '6',
		NOMBRE: 'Artajona',
	},
	{
		CPRO: '31',
		CMUN: '039',
		DC: '9',
		NOMBRE: 'Artazu',
	},
	{
		CPRO: '31',
		CMUN: '040',
		DC: '3',
		NOMBRE: 'Atez',
	},
	{
		CPRO: '31',
		CMUN: '058',
		DC: '1',
		NOMBRE: 'Auritz/Burguete',
	},
	{
		CPRO: '31',
		CMUN: '041',
		DC: '0',
		NOMBRE: 'Ayegui/Aiegi',
	},
	{
		CPRO: '31',
		CMUN: '042',
		DC: '5',
		NOMBRE: 'Azagra',
	},
	{
		CPRO: '31',
		CMUN: '043',
		DC: '1',
		NOMBRE: 'Azuelo',
	},
	{
		CPRO: '31',
		CMUN: '044',
		DC: '6',
		NOMBRE: 'Bakaiku',
	},
	{
		CPRO: '31',
		CMUN: '901',
		DC: '9',
		NOMBRE: 'Barañain',
	},
	{
		CPRO: '31',
		CMUN: '045',
		DC: '9',
		NOMBRE: 'Barásoain',
	},
	{
		CPRO: '31',
		CMUN: '046',
		DC: '2',
		NOMBRE: 'Barbarin',
	},
	{
		CPRO: '31',
		CMUN: '047',
		DC: '8',
		NOMBRE: 'Bargota',
	},
	{
		CPRO: '31',
		CMUN: '048',
		DC: '4',
		NOMBRE: 'Barillas',
	},
	{
		CPRO: '31',
		CMUN: '049',
		DC: '7',
		NOMBRE: 'Basaburua',
	},
	{
		CPRO: '31',
		CMUN: '050',
		DC: '0',
		NOMBRE: 'Baztan',
	},
	{
		CPRO: '31',
		CMUN: '137',
		DC: '9',
		NOMBRE: 'Beintza-Labaien',
	},
	{
		CPRO: '31',
		CMUN: '051',
		DC: '7',
		NOMBRE: 'Beire',
	},
	{
		CPRO: '31',
		CMUN: '052',
		DC: '2',
		NOMBRE: 'Belascoáin',
	},
	{
		CPRO: '31',
		CMUN: '250',
		DC: '8',
		NOMBRE: 'Bera',
	},
	{
		CPRO: '31',
		CMUN: '053',
		DC: '8',
		NOMBRE: 'Berbinzana',
	},
	{
		CPRO: '31',
		CMUN: '905',
		DC: '8',
		NOMBRE: 'Beriáin',
	},
	{
		CPRO: '31',
		CMUN: '902',
		DC: '4',
		NOMBRE: 'Berrioplano',
	},
	{
		CPRO: '31',
		CMUN: '903',
		DC: '0',
		NOMBRE: 'Berriozar',
	},
	{
		CPRO: '31',
		CMUN: '054',
		DC: '3',
		NOMBRE: 'Bertizarana',
	},
	{
		CPRO: '31',
		CMUN: '055',
		DC: '6',
		NOMBRE: 'Betelu',
	},
	{
		CPRO: '31',
		CMUN: '253',
		DC: '6',
		NOMBRE: 'Bidaurreta',
	},
	{
		CPRO: '31',
		CMUN: '056',
		DC: '9',
		NOMBRE: 'Biurrun-Olcoz',
	},
	{
		CPRO: '31',
		CMUN: '057',
		DC: '5',
		NOMBRE: 'Buñuel',
	},
	{
		CPRO: '31',
		CMUN: '059',
		DC: '4',
		NOMBRE: 'Burgui/Burgi',
	},
	{
		CPRO: '31',
		CMUN: '060',
		DC: '8',
		NOMBRE: 'Burlada/Burlata',
	},
	{
		CPRO: '31',
		CMUN: '061',
		DC: '5',
		NOMBRE: 'Busto, El',
	},
	{
		CPRO: '31',
		CMUN: '062',
		DC: '0',
		NOMBRE: 'Cabanillas',
	},
	{
		CPRO: '31',
		CMUN: '063',
		DC: '6',
		NOMBRE: 'Cabredo',
	},
	{
		CPRO: '31',
		CMUN: '064',
		DC: '1',
		NOMBRE: 'Cadreita',
	},
	{
		CPRO: '31',
		CMUN: '065',
		DC: '4',
		NOMBRE: 'Caparroso',
	},
	{
		CPRO: '31',
		CMUN: '066',
		DC: '7',
		NOMBRE: 'Cárcar',
	},
	{
		CPRO: '31',
		CMUN: '067',
		DC: '3',
		NOMBRE: 'Carcastillo',
	},
	{
		CPRO: '31',
		CMUN: '068',
		DC: '9',
		NOMBRE: 'Cascante',
	},
	{
		CPRO: '31',
		CMUN: '069',
		DC: '2',
		NOMBRE: 'Cáseda',
	},
	{
		CPRO: '31',
		CMUN: '070',
		DC: '6',
		NOMBRE: 'Castejón',
	},
	{
		CPRO: '31',
		CMUN: '071',
		DC: '3',
		NOMBRE: 'Castillonuevo',
	},
	{
		CPRO: '31',
		CMUN: '193',
		DC: '9',
		NOMBRE: 'Cendea de Olza/Oltza Zendea',
	},
	{
		CPRO: '31',
		CMUN: '072',
		DC: '8',
		NOMBRE: 'Cintruénigo',
	},
	{
		CPRO: '31',
		CMUN: '074',
		DC: '9',
		NOMBRE: 'Cirauqui/Zirauki',
	},
	{
		CPRO: '31',
		CMUN: '075',
		DC: '2',
		NOMBRE: 'Ciriza',
	},
	{
		CPRO: '31',
		CMUN: '076',
		DC: '5',
		NOMBRE: 'Cizur',
	},
	{
		CPRO: '31',
		CMUN: '077',
		DC: '1',
		NOMBRE: 'Corella',
	},
	{
		CPRO: '31',
		CMUN: '078',
		DC: '7',
		NOMBRE: 'Cortes',
	},
	{
		CPRO: '31',
		CMUN: '079',
		DC: '0',
		NOMBRE: 'Desojo',
	},
	{
		CPRO: '31',
		CMUN: '080',
		DC: '4',
		NOMBRE: 'Dicastillo',
	},
	{
		CPRO: '31',
		CMUN: '081',
		DC: '1',
		NOMBRE: 'Donamaria',
	},
	{
		CPRO: '31',
		CMUN: '221',
		DC: '2',
		NOMBRE: 'Doneztebe/Santesteban',
	},
	{
		CPRO: '31',
		CMUN: '083',
		DC: '2',
		NOMBRE: 'Echarri',
	},
	{
		CPRO: '31',
		CMUN: '086',
		DC: '3',
		NOMBRE: 'Egüés',
	},
	{
		CPRO: '31',
		CMUN: '087',
		DC: '9',
		NOMBRE: 'Elgorriaga',
	},
	{
		CPRO: '31',
		CMUN: '089',
		DC: '8',
		NOMBRE: 'Enériz/Eneritz',
	},
	{
		CPRO: '31',
		CMUN: '090',
		DC: '2',
		NOMBRE: 'Eratsun',
	},
	{
		CPRO: '31',
		CMUN: '091',
		DC: '9',
		NOMBRE: 'Ergoiena',
	},
	{
		CPRO: '31',
		CMUN: '092',
		DC: '4',
		NOMBRE: 'Erro',
	},
	{
		CPRO: '31',
		CMUN: '094',
		DC: '5',
		NOMBRE: 'Eslava',
	},
	{
		CPRO: '31',
		CMUN: '095',
		DC: '8',
		NOMBRE: 'Esparza de Salazar/Espartza Zaraitzu',
	},
	{
		CPRO: '31',
		CMUN: '096',
		DC: '1',
		NOMBRE: 'Espronceda',
	},
	{
		CPRO: '31',
		CMUN: '097',
		DC: '7',
		NOMBRE: 'Estella/Lizarra',
	},
	{
		CPRO: '31',
		CMUN: '098',
		DC: '3',
		NOMBRE: 'Esteribar',
	},
	{
		CPRO: '31',
		CMUN: '099',
		DC: '6',
		NOMBRE: 'Etayo',
	},
	{
		CPRO: '31',
		CMUN: '082',
		DC: '6',
		NOMBRE: 'Etxalar',
	},
	{
		CPRO: '31',
		CMUN: '084',
		DC: '7',
		NOMBRE: 'Etxarri-Aranatz',
	},
	{
		CPRO: '31',
		CMUN: '085',
		DC: '0',
		NOMBRE: 'Etxauri',
	},
	{
		CPRO: '31',
		CMUN: '100',
		DC: '0',
		NOMBRE: 'Eulate',
	},
	{
		CPRO: '31',
		CMUN: '101',
		DC: '7',
		NOMBRE: 'Ezcabarte',
	},
	{
		CPRO: '31',
		CMUN: '093',
		DC: '0',
		NOMBRE: 'Ezcároz/Ezkaroze',
	},
	{
		CPRO: '31',
		CMUN: '102',
		DC: '2',
		NOMBRE: 'Ezkurra',
	},
	{
		CPRO: '31',
		CMUN: '103',
		DC: '8',
		NOMBRE: 'Ezprogui',
	},
	{
		CPRO: '31',
		CMUN: '104',
		DC: '3',
		NOMBRE: 'Falces',
	},
	{
		CPRO: '31',
		CMUN: '105',
		DC: '6',
		NOMBRE: 'Fitero',
	},
	{
		CPRO: '31',
		CMUN: '106',
		DC: '9',
		NOMBRE: 'Fontellas',
	},
	{
		CPRO: '31',
		CMUN: '107',
		DC: '5',
		NOMBRE: 'Funes',
	},
	{
		CPRO: '31',
		CMUN: '108',
		DC: '1',
		NOMBRE: 'Fustiñana',
	},
	{
		CPRO: '31',
		CMUN: '109',
		DC: '4',
		NOMBRE: 'Galar',
	},
	{
		CPRO: '31',
		CMUN: '110',
		DC: '8',
		NOMBRE: 'Gallipienzo/Galipentzu',
	},
	{
		CPRO: '31',
		CMUN: '111',
		DC: '5',
		NOMBRE: 'Gallués/Galoze',
	},
	{
		CPRO: '31',
		CMUN: '112',
		DC: '0',
		NOMBRE: 'Garaioa',
	},
	{
		CPRO: '31',
		CMUN: '113',
		DC: '6',
		NOMBRE: 'Garde',
	},
	{
		CPRO: '31',
		CMUN: '114',
		DC: '1',
		NOMBRE: 'Garínoain',
	},
	{
		CPRO: '31',
		CMUN: '115',
		DC: '4',
		NOMBRE: 'Garralda',
	},
	{
		CPRO: '31',
		CMUN: '116',
		DC: '7',
		NOMBRE: 'Genevilla',
	},
	{
		CPRO: '31',
		CMUN: '117',
		DC: '3',
		NOMBRE: 'Goizueta',
	},
	{
		CPRO: '31',
		CMUN: '118',
		DC: '9',
		NOMBRE: 'Goñi',
	},
	{
		CPRO: '31',
		CMUN: '119',
		DC: '2',
		NOMBRE: 'Güesa/Gorza',
	},
	{
		CPRO: '31',
		CMUN: '120',
		DC: '6',
		NOMBRE: 'Guesálaz/Gesalatz',
	},
	{
		CPRO: '31',
		CMUN: '121',
		DC: '3',
		NOMBRE: 'Guirguillano',
	},
	{
		CPRO: '31',
		CMUN: '256',
		DC: '7',
		NOMBRE: 'Hiriberri/Villanueva de Aezkoa',
	},
	{
		CPRO: '31',
		CMUN: '122',
		DC: '8',
		NOMBRE: 'Huarte/Uharte',
	},
	{
		CPRO: '31',
		CMUN: '124',
		DC: '9',
		NOMBRE: 'Ibargoiti',
	},
	{
		CPRO: '31',
		CMUN: '259',
		DC: '2',
		NOMBRE: 'Igantzi',
	},
	{
		CPRO: '31',
		CMUN: '125',
		DC: '2',
		NOMBRE: 'Igúzquiza',
	},
	{
		CPRO: '31',
		CMUN: '126',
		DC: '5',
		NOMBRE: 'Imotz',
	},
	{
		CPRO: '31',
		CMUN: '127',
		DC: '1',
		NOMBRE: 'Irañeta',
	},
	{
		CPRO: '31',
		CMUN: '904',
		DC: '5',
		NOMBRE: 'Irurtzun',
	},
	{
		CPRO: '31',
		CMUN: '128',
		DC: '7',
		NOMBRE: 'Isaba/Izaba',
	},
	{
		CPRO: '31',
		CMUN: '129',
		DC: '0',
		NOMBRE: 'Ituren',
	},
	{
		CPRO: '31',
		CMUN: '130',
		DC: '4',
		NOMBRE: 'Iturmendi',
	},
	{
		CPRO: '31',
		CMUN: '131',
		DC: '1',
		NOMBRE: 'Iza/Itza',
	},
	{
		CPRO: '31',
		CMUN: '132',
		DC: '6',
		NOMBRE: 'Izagaondoa',
	},
	{
		CPRO: '31',
		CMUN: '133',
		DC: '2',
		NOMBRE: 'Izalzu/Itzaltzu',
	},
	{
		CPRO: '31',
		CMUN: '134',
		DC: '7',
		NOMBRE: 'Jaurrieta',
	},
	{
		CPRO: '31',
		CMUN: '135',
		DC: '0',
		NOMBRE: 'Javier',
	},
	{
		CPRO: '31',
		CMUN: '136',
		DC: '3',
		NOMBRE: 'Juslapeña',
	},
	{
		CPRO: '31',
		CMUN: '138',
		DC: '5',
		NOMBRE: 'Lakuntza',
	},
	{
		CPRO: '31',
		CMUN: '139',
		DC: '8',
		NOMBRE: 'Lana',
	},
	{
		CPRO: '31',
		CMUN: '140',
		DC: '2',
		NOMBRE: 'Lantz',
	},
	{
		CPRO: '31',
		CMUN: '141',
		DC: '9',
		NOMBRE: 'Lapoblación',
	},
	{
		CPRO: '31',
		CMUN: '142',
		DC: '4',
		NOMBRE: 'Larraga',
	},
	{
		CPRO: '31',
		CMUN: '143',
		DC: '0',
		NOMBRE: 'Larraona',
	},
	{
		CPRO: '31',
		CMUN: '144',
		DC: '5',
		NOMBRE: 'Larraun',
	},
	{
		CPRO: '31',
		CMUN: '145',
		DC: '8',
		NOMBRE: 'Lazagurría',
	},
	{
		CPRO: '31',
		CMUN: '146',
		DC: '1',
		NOMBRE: 'Leache',
	},
	{
		CPRO: '31',
		CMUN: '147',
		DC: '7',
		NOMBRE: 'Legarda',
	},
	{
		CPRO: '31',
		CMUN: '148',
		DC: '3',
		NOMBRE: 'Legaria',
	},
	{
		CPRO: '31',
		CMUN: '149',
		DC: '6',
		NOMBRE: 'Leitza',
	},
	{
		CPRO: '31',
		CMUN: '908',
		DC: '3',
		NOMBRE: 'Lekunberri',
	},
	{
		CPRO: '31',
		CMUN: '150',
		DC: '9',
		NOMBRE: 'Leoz/Leotz',
	},
	{
		CPRO: '31',
		CMUN: '151',
		DC: '6',
		NOMBRE: 'Lerga',
	},
	{
		CPRO: '31',
		CMUN: '152',
		DC: '1',
		NOMBRE: 'Lerín',
	},
	{
		CPRO: '31',
		CMUN: '153',
		DC: '7',
		NOMBRE: 'Lesaka',
	},
	{
		CPRO: '31',
		CMUN: '154',
		DC: '2',
		NOMBRE: 'Lezáun',
	},
	{
		CPRO: '31',
		CMUN: '155',
		DC: '5',
		NOMBRE: 'Liédena',
	},
	{
		CPRO: '31',
		CMUN: '156',
		DC: '8',
		NOMBRE: 'Lizoáin-Arriasgoiti',
	},
	{
		CPRO: '31',
		CMUN: '157',
		DC: '4',
		NOMBRE: 'Lodosa',
	},
	{
		CPRO: '31',
		CMUN: '158',
		DC: '0',
		NOMBRE: 'Lónguida/Longida',
	},
	{
		CPRO: '31',
		CMUN: '159',
		DC: '3',
		NOMBRE: 'Lumbier',
	},
	{
		CPRO: '31',
		CMUN: '160',
		DC: '7',
		NOMBRE: 'Luquin',
	},
	{
		CPRO: '31',
		CMUN: '248',
		DC: '2',
		NOMBRE: 'Luzaide/Valcarlos',
	},
	{
		CPRO: '31',
		CMUN: '161',
		DC: '4',
		NOMBRE: 'Mañeru',
	},
	{
		CPRO: '31',
		CMUN: '162',
		DC: '9',
		NOMBRE: 'Marañón',
	},
	{
		CPRO: '31',
		CMUN: '163',
		DC: '5',
		NOMBRE: 'Marcilla',
	},
	{
		CPRO: '31',
		CMUN: '164',
		DC: '0',
		NOMBRE: 'Mélida',
	},
	{
		CPRO: '31',
		CMUN: '165',
		DC: '3',
		NOMBRE: 'Mendavia',
	},
	{
		CPRO: '31',
		CMUN: '166',
		DC: '6',
		NOMBRE: 'Mendaza',
	},
	{
		CPRO: '31',
		CMUN: '167',
		DC: '2',
		NOMBRE: 'Mendigorría',
	},
	{
		CPRO: '31',
		CMUN: '168',
		DC: '8',
		NOMBRE: 'Metauten',
	},
	{
		CPRO: '31',
		CMUN: '169',
		DC: '1',
		NOMBRE: 'Milagro',
	},
	{
		CPRO: '31',
		CMUN: '170',
		DC: '5',
		NOMBRE: 'Mirafuentes',
	},
	{
		CPRO: '31',
		CMUN: '171',
		DC: '2',
		NOMBRE: 'Miranda de Arga',
	},
	{
		CPRO: '31',
		CMUN: '172',
		DC: '7',
		NOMBRE: 'Monreal',
	},
	{
		CPRO: '31',
		CMUN: '173',
		DC: '3',
		NOMBRE: 'Monteagudo',
	},
	{
		CPRO: '31',
		CMUN: '174',
		DC: '8',
		NOMBRE: 'Morentin',
	},
	{
		CPRO: '31',
		CMUN: '175',
		DC: '1',
		NOMBRE: 'Mues',
	},
	{
		CPRO: '31',
		CMUN: '176',
		DC: '4',
		NOMBRE: 'Murchante',
	},
	{
		CPRO: '31',
		CMUN: '177',
		DC: '0',
		NOMBRE: 'Murieta',
	},
	{
		CPRO: '31',
		CMUN: '178',
		DC: '6',
		NOMBRE: 'Murillo el Cuende',
	},
	{
		CPRO: '31',
		CMUN: '179',
		DC: '9',
		NOMBRE: 'Murillo el Fruto',
	},
	{
		CPRO: '31',
		CMUN: '180',
		DC: '3',
		NOMBRE: 'Muruzábal',
	},
	{
		CPRO: '31',
		CMUN: '181',
		DC: '0',
		NOMBRE: 'Navascués',
	},
	{
		CPRO: '31',
		CMUN: '182',
		DC: '5',
		NOMBRE: 'Nazar',
	},
	{
		CPRO: '31',
		CMUN: '088',
		DC: '5',
		NOMBRE: 'Noáin (Valle de Elorz)/Noain (Elortzibar)',
	},
	{
		CPRO: '31',
		CMUN: '183',
		DC: '1',
		NOMBRE: 'Obanos',
	},
	{
		CPRO: '31',
		CMUN: '185',
		DC: '9',
		NOMBRE: 'Ochagavía/Otsagabia',
	},
	{
		CPRO: '31',
		CMUN: '184',
		DC: '6',
		NOMBRE: 'Oco',
	},
	{
		CPRO: '31',
		CMUN: '186',
		DC: '2',
		NOMBRE: 'Odieta',
	},
	{
		CPRO: '31',
		CMUN: '187',
		DC: '8',
		NOMBRE: 'Oitz',
	},
	{
		CPRO: '31',
		CMUN: '188',
		DC: '4',
		NOMBRE: 'Olaibar',
	},
	{
		CPRO: '31',
		CMUN: '189',
		DC: '7',
		NOMBRE: 'Olazti/Olazagutía',
	},
	{
		CPRO: '31',
		CMUN: '190',
		DC: '1',
		NOMBRE: 'Olejua',
	},
	{
		CPRO: '31',
		CMUN: '191',
		DC: '8',
		NOMBRE: 'Olite/Erriberri',
	},
	{
		CPRO: '31',
		CMUN: '194',
		DC: '4',
		NOMBRE: 'Ollo',
	},
	{
		CPRO: '31',
		CMUN: '192',
		DC: '3',
		NOMBRE: 'Olóriz/Oloritz',
	},
	{
		CPRO: '31',
		CMUN: '195',
		DC: '7',
		NOMBRE: 'Orbaitzeta',
	},
	{
		CPRO: '31',
		CMUN: '196',
		DC: '0',
		NOMBRE: 'Orbara',
	},
	{
		CPRO: '31',
		CMUN: '197',
		DC: '6',
		NOMBRE: 'Orísoain',
	},
	{
		CPRO: '31',
		CMUN: '906',
		DC: '1',
		NOMBRE: 'Orkoien',
	},
	{
		CPRO: '31',
		CMUN: '198',
		DC: '2',
		NOMBRE: 'Oronz/Orontze',
	},
	{
		CPRO: '31',
		CMUN: '199',
		DC: '5',
		NOMBRE: 'Oroz-Betelu/Orotz-Betelu',
	},
	{
		CPRO: '31',
		CMUN: '211',
		DC: '4',
		NOMBRE: 'Orreaga/Roncesvalles',
	},
	{
		CPRO: '31',
		CMUN: '200',
		DC: '9',
		NOMBRE: 'Oteiza',
	},
	{
		CPRO: '31',
		CMUN: '201',
		DC: '6',
		NOMBRE: 'Pamplona/Iruña',
	},
	{
		CPRO: '31',
		CMUN: '202',
		DC: '1',
		NOMBRE: 'Peralta/Azkoien',
	},
	{
		CPRO: '31',
		CMUN: '203',
		DC: '7',
		NOMBRE: 'Petilla de Aragón',
	},
	{
		CPRO: '31',
		CMUN: '204',
		DC: '2',
		NOMBRE: 'Piedramillera',
	},
	{
		CPRO: '31',
		CMUN: '205',
		DC: '5',
		NOMBRE: 'Pitillas',
	},
	{
		CPRO: '31',
		CMUN: '206',
		DC: '8',
		NOMBRE: 'Puente la Reina/Gares',
	},
	{
		CPRO: '31',
		CMUN: '207',
		DC: '4',
		NOMBRE: 'Pueyo',
	},
	{
		CPRO: '31',
		CMUN: '208',
		DC: '0',
		NOMBRE: 'Ribaforada',
	},
	{
		CPRO: '31',
		CMUN: '209',
		DC: '3',
		NOMBRE: 'Romanzado',
	},
	{
		CPRO: '31',
		CMUN: '210',
		DC: '7',
		NOMBRE: 'Roncal/Erronkari',
	},
	{
		CPRO: '31',
		CMUN: '212',
		DC: '9',
		NOMBRE: 'Sada',
	},
	{
		CPRO: '31',
		CMUN: '213',
		DC: '5',
		NOMBRE: 'Saldías',
	},
	{
		CPRO: '31',
		CMUN: '214',
		DC: '0',
		NOMBRE: 'Salinas de Oro/Jaitz',
	},
	{
		CPRO: '31',
		CMUN: '215',
		DC: '3',
		NOMBRE: 'San Adrián',
	},
	{
		CPRO: '31',
		CMUN: '217',
		DC: '2',
		NOMBRE: 'San Martín de Unx',
	},
	{
		CPRO: '31',
		CMUN: '216',
		DC: '6',
		NOMBRE: 'Sangüesa/Zangoza',
	},
	{
		CPRO: '31',
		CMUN: '219',
		DC: '1',
		NOMBRE: 'Sansol',
	},
	{
		CPRO: '31',
		CMUN: '220',
		DC: '5',
		NOMBRE: 'Santacara',
	},
	{
		CPRO: '31',
		CMUN: '222',
		DC: '7',
		NOMBRE: 'Sarriés/Sartze',
	},
	{
		CPRO: '31',
		CMUN: '223',
		DC: '3',
		NOMBRE: 'Sartaguda',
	},
	{
		CPRO: '31',
		CMUN: '224',
		DC: '8',
		NOMBRE: 'Sesma',
	},
	{
		CPRO: '31',
		CMUN: '225',
		DC: '1',
		NOMBRE: 'Sorlada',
	},
	{
		CPRO: '31',
		CMUN: '226',
		DC: '4',
		NOMBRE: 'Sunbilla',
	},
	{
		CPRO: '31',
		CMUN: '227',
		DC: '0',
		NOMBRE: 'Tafalla',
	},
	{
		CPRO: '31',
		CMUN: '228',
		DC: '6',
		NOMBRE: 'Tiebas-Muruarte de Reta',
	},
	{
		CPRO: '31',
		CMUN: '229',
		DC: '9',
		NOMBRE: 'Tirapu',
	},
	{
		CPRO: '31',
		CMUN: '230',
		DC: '3',
		NOMBRE: 'Torralba del Río',
	},
	{
		CPRO: '31',
		CMUN: '231',
		DC: '0',
		NOMBRE: 'Torres del Río',
	},
	{
		CPRO: '31',
		CMUN: '232',
		DC: '5',
		NOMBRE: 'Tudela',
	},
	{
		CPRO: '31',
		CMUN: '233',
		DC: '1',
		NOMBRE: 'Tulebras',
	},
	{
		CPRO: '31',
		CMUN: '234',
		DC: '6',
		NOMBRE: 'Ucar',
	},
	{
		CPRO: '31',
		CMUN: '123',
		DC: '4',
		NOMBRE: 'Uharte-Arakil',
	},
	{
		CPRO: '31',
		CMUN: '235',
		DC: '9',
		NOMBRE: 'Ujué',
	},
	{
		CPRO: '31',
		CMUN: '236',
		DC: '2',
		NOMBRE: 'Ultzama',
	},
	{
		CPRO: '31',
		CMUN: '237',
		DC: '8',
		NOMBRE: 'Unciti',
	},
	{
		CPRO: '31',
		CMUN: '238',
		DC: '4',
		NOMBRE: 'Unzué',
	},
	{
		CPRO: '31',
		CMUN: '239',
		DC: '7',
		NOMBRE: 'Urdazubi/Urdax',
	},
	{
		CPRO: '31',
		CMUN: '240',
		DC: '1',
		NOMBRE: 'Urdiain',
	},
	{
		CPRO: '31',
		CMUN: '241',
		DC: '8',
		NOMBRE: 'Urraul Alto',
	},
	{
		CPRO: '31',
		CMUN: '242',
		DC: '3',
		NOMBRE: 'Urraul Bajo',
	},
	{
		CPRO: '31',
		CMUN: '244',
		DC: '4',
		NOMBRE: 'Urrotz',
	},
	{
		CPRO: '31',
		CMUN: '243',
		DC: '9',
		NOMBRE: 'Urroz-Villa',
	},
	{
		CPRO: '31',
		CMUN: '245',
		DC: '7',
		NOMBRE: 'Urzainqui/Urzainki',
	},
	{
		CPRO: '31',
		CMUN: '246',
		DC: '0',
		NOMBRE: 'Uterga',
	},
	{
		CPRO: '31',
		CMUN: '247',
		DC: '6',
		NOMBRE: 'Uztárroz/Uztarroze',
	},
	{
		CPRO: '31',
		CMUN: '260',
		DC: '6',
		NOMBRE: 'Valle de Yerri/Deierri',
	},
	{
		CPRO: '31',
		CMUN: '249',
		DC: '5',
		NOMBRE: 'Valtierra',
	},
	{
		CPRO: '31',
		CMUN: '251',
		DC: '5',
		NOMBRE: 'Viana',
	},
	{
		CPRO: '31',
		CMUN: '252',
		DC: '0',
		NOMBRE: 'Vidángoz/Bidankoze',
	},
	{
		CPRO: '31',
		CMUN: '254',
		DC: '1',
		NOMBRE: 'Villafranca',
	},
	{
		CPRO: '31',
		CMUN: '255',
		DC: '4',
		NOMBRE: 'Villamayor de Monjardín',
	},
	{
		CPRO: '31',
		CMUN: '257',
		DC: '3',
		NOMBRE: 'Villatuerta',
	},
	{
		CPRO: '31',
		CMUN: '258',
		DC: '9',
		NOMBRE: 'Villava/Atarrabia',
	},
	{
		CPRO: '31',
		CMUN: '261',
		DC: '3',
		NOMBRE: 'Yesa',
	},
	{
		CPRO: '31',
		CMUN: '262',
		DC: '8',
		NOMBRE: 'Zabalza/Zabaltza',
	},
	{
		CPRO: '31',
		CMUN: '073',
		DC: '4',
		NOMBRE: 'Ziordia',
	},
	{
		CPRO: '31',
		CMUN: '907',
		DC: '7',
		NOMBRE: 'Zizur Mayor/Zizur Nagusia',
	},
	{
		CPRO: '31',
		CMUN: '263',
		DC: '4',
		NOMBRE: 'Zubieta',
	},
	{
		CPRO: '31',
		CMUN: '264',
		DC: '9',
		NOMBRE: 'Zugarramurdi',
	},
	{
		CPRO: '31',
		CMUN: '265',
		DC: '2',
		NOMBRE: 'Zúñiga',
	},
	{
		CPRO: '32',
		CMUN: '001',
		DC: '3',
		NOMBRE: 'Allariz',
	},
	{
		CPRO: '32',
		CMUN: '002',
		DC: '8',
		NOMBRE: 'Amoeiro',
	},
	{
		CPRO: '32',
		CMUN: '003',
		DC: '4',
		NOMBRE: 'Arnoia, A',
	},
	{
		CPRO: '32',
		CMUN: '004',
		DC: '9',
		NOMBRE: 'Avión',
	},
	{
		CPRO: '32',
		CMUN: '005',
		DC: '2',
		NOMBRE: 'Baltar',
	},
	{
		CPRO: '32',
		CMUN: '006',
		DC: '5',
		NOMBRE: 'Bande',
	},
	{
		CPRO: '32',
		CMUN: '007',
		DC: '1',
		NOMBRE: 'Baños de Molgas',
	},
	{
		CPRO: '32',
		CMUN: '008',
		DC: '7',
		NOMBRE: 'Barbadás',
	},
	{
		CPRO: '32',
		CMUN: '009',
		DC: '0',
		NOMBRE: 'Barco de Valdeorras, O',
	},
	{
		CPRO: '32',
		CMUN: '010',
		DC: '4',
		NOMBRE: 'Beade',
	},
	{
		CPRO: '32',
		CMUN: '011',
		DC: '1',
		NOMBRE: 'Beariz',
	},
	{
		CPRO: '32',
		CMUN: '012',
		DC: '6',
		NOMBRE: 'Blancos, Os',
	},
	{
		CPRO: '32',
		CMUN: '013',
		DC: '2',
		NOMBRE: 'Boborás',
	},
	{
		CPRO: '32',
		CMUN: '014',
		DC: '7',
		NOMBRE: 'Bola, A',
	},
	{
		CPRO: '32',
		CMUN: '015',
		DC: '0',
		NOMBRE: 'Bolo, O',
	},
	{
		CPRO: '32',
		CMUN: '016',
		DC: '3',
		NOMBRE: 'Calvos de Randín',
	},
	{
		CPRO: '32',
		CMUN: '018',
		DC: '5',
		NOMBRE: 'Carballeda de Avia',
	},
	{
		CPRO: '32',
		CMUN: '017',
		DC: '9',
		NOMBRE: 'Carballeda de Valdeorras',
	},
	{
		CPRO: '32',
		CMUN: '019',
		DC: '8',
		NOMBRE: 'Carballiño, O',
	},
	{
		CPRO: '32',
		CMUN: '020',
		DC: '2',
		NOMBRE: 'Cartelle',
	},
	{
		CPRO: '32',
		CMUN: '022',
		DC: '4',
		NOMBRE: 'Castrelo de Miño',
	},
	{
		CPRO: '32',
		CMUN: '021',
		DC: '9',
		NOMBRE: 'Castrelo do Val',
	},
	{
		CPRO: '32',
		CMUN: '023',
		DC: '0',
		NOMBRE: 'Castro Caldelas',
	},
	{
		CPRO: '32',
		CMUN: '024',
		DC: '5',
		NOMBRE: 'Celanova',
	},
	{
		CPRO: '32',
		CMUN: '025',
		DC: '8',
		NOMBRE: 'Cenlle',
	},
	{
		CPRO: '32',
		CMUN: '029',
		DC: '6',
		NOMBRE: 'Chandrexa de Queixa',
	},
	{
		CPRO: '32',
		CMUN: '026',
		DC: '1',
		NOMBRE: 'Coles',
	},
	{
		CPRO: '32',
		CMUN: '027',
		DC: '7',
		NOMBRE: 'Cortegada',
	},
	{
		CPRO: '32',
		CMUN: '028',
		DC: '3',
		NOMBRE: 'Cualedro',
	},
	{
		CPRO: '32',
		CMUN: '030',
		DC: '0',
		NOMBRE: 'Entrimo',
	},
	{
		CPRO: '32',
		CMUN: '031',
		DC: '7',
		NOMBRE: 'Esgos',
	},
	{
		CPRO: '32',
		CMUN: '033',
		DC: '8',
		NOMBRE: 'Gomesende',
	},
	{
		CPRO: '32',
		CMUN: '034',
		DC: '3',
		NOMBRE: 'Gudiña, A',
	},
	{
		CPRO: '32',
		CMUN: '035',
		DC: '6',
		NOMBRE: 'Irixo, O',
	},
	{
		CPRO: '32',
		CMUN: '038',
		DC: '1',
		NOMBRE: 'Larouco',
	},
	{
		CPRO: '32',
		CMUN: '039',
		DC: '4',
		NOMBRE: 'Laza',
	},
	{
		CPRO: '32',
		CMUN: '040',
		DC: '8',
		NOMBRE: 'Leiro',
	},
	{
		CPRO: '32',
		CMUN: '041',
		DC: '5',
		NOMBRE: 'Lobeira',
	},
	{
		CPRO: '32',
		CMUN: '042',
		DC: '0',
		NOMBRE: 'Lobios',
	},
	{
		CPRO: '32',
		CMUN: '043',
		DC: '6',
		NOMBRE: 'Maceda',
	},
	{
		CPRO: '32',
		CMUN: '044',
		DC: '1',
		NOMBRE: 'Manzaneda',
	},
	{
		CPRO: '32',
		CMUN: '045',
		DC: '4',
		NOMBRE: 'Maside',
	},
	{
		CPRO: '32',
		CMUN: '046',
		DC: '7',
		NOMBRE: 'Melón',
	},
	{
		CPRO: '32',
		CMUN: '047',
		DC: '3',
		NOMBRE: 'Merca, A',
	},
	{
		CPRO: '32',
		CMUN: '048',
		DC: '9',
		NOMBRE: 'Mezquita, A',
	},
	{
		CPRO: '32',
		CMUN: '049',
		DC: '2',
		NOMBRE: 'Montederramo',
	},
	{
		CPRO: '32',
		CMUN: '050',
		DC: '5',
		NOMBRE: 'Monterrei',
	},
	{
		CPRO: '32',
		CMUN: '051',
		DC: '2',
		NOMBRE: 'Muíños',
	},
	{
		CPRO: '32',
		CMUN: '052',
		DC: '7',
		NOMBRE: 'Nogueira de Ramuín',
	},
	{
		CPRO: '32',
		CMUN: '053',
		DC: '3',
		NOMBRE: 'Oímbra',
	},
	{
		CPRO: '32',
		CMUN: '054',
		DC: '8',
		NOMBRE: 'Ourense',
	},
	{
		CPRO: '32',
		CMUN: '055',
		DC: '1',
		NOMBRE: 'Paderne de Allariz',
	},
	{
		CPRO: '32',
		CMUN: '056',
		DC: '4',
		NOMBRE: 'Padrenda',
	},
	{
		CPRO: '32',
		CMUN: '057',
		DC: '0',
		NOMBRE: 'Parada de Sil',
	},
	{
		CPRO: '32',
		CMUN: '058',
		DC: '6',
		NOMBRE: 'Pereiro de Aguiar, O',
	},
	{
		CPRO: '32',
		CMUN: '059',
		DC: '9',
		NOMBRE: 'Peroxa, A',
	},
	{
		CPRO: '32',
		CMUN: '060',
		DC: '3',
		NOMBRE: 'Petín',
	},
	{
		CPRO: '32',
		CMUN: '061',
		DC: '0',
		NOMBRE: 'Piñor',
	},
	{
		CPRO: '32',
		CMUN: '063',
		DC: '1',
		NOMBRE: 'Pobra de Trives, A',
	},
	{
		CPRO: '32',
		CMUN: '064',
		DC: '6',
		NOMBRE: 'Pontedeva',
	},
	{
		CPRO: '32',
		CMUN: '062',
		DC: '5',
		NOMBRE: 'Porqueira',
	},
	{
		CPRO: '32',
		CMUN: '065',
		DC: '9',
		NOMBRE: 'Punxín',
	},
	{
		CPRO: '32',
		CMUN: '066',
		DC: '2',
		NOMBRE: 'Quintela de Leirado',
	},
	{
		CPRO: '32',
		CMUN: '067',
		DC: '8',
		NOMBRE: 'Rairiz de Veiga',
	},
	{
		CPRO: '32',
		CMUN: '068',
		DC: '4',
		NOMBRE: 'Ramirás',
	},
	{
		CPRO: '32',
		CMUN: '069',
		DC: '7',
		NOMBRE: 'Ribadavia',
	},
	{
		CPRO: '32',
		CMUN: '071',
		DC: '8',
		NOMBRE: 'Riós',
	},
	{
		CPRO: '32',
		CMUN: '072',
		DC: '3',
		NOMBRE: 'Rúa, A',
	},
	{
		CPRO: '32',
		CMUN: '073',
		DC: '9',
		NOMBRE: 'Rubiá',
	},
	{
		CPRO: '32',
		CMUN: '074',
		DC: '4',
		NOMBRE: 'San Amaro',
	},
	{
		CPRO: '32',
		CMUN: '075',
		DC: '7',
		NOMBRE: 'San Cibrao das Viñas',
	},
	{
		CPRO: '32',
		CMUN: '076',
		DC: '0',
		NOMBRE: 'San Cristovo de Cea',
	},
	{
		CPRO: '32',
		CMUN: '070',
		DC: '1',
		NOMBRE: 'San Xoán de Río',
	},
	{
		CPRO: '32',
		CMUN: '077',
		DC: '6',
		NOMBRE: 'Sandiás',
	},
	{
		CPRO: '32',
		CMUN: '078',
		DC: '2',
		NOMBRE: 'Sarreaus',
	},
	{
		CPRO: '32',
		CMUN: '079',
		DC: '5',
		NOMBRE: 'Taboadela',
	},
	{
		CPRO: '32',
		CMUN: '080',
		DC: '9',
		NOMBRE: 'Teixeira, A',
	},
	{
		CPRO: '32',
		CMUN: '081',
		DC: '6',
		NOMBRE: 'Toén',
	},
	{
		CPRO: '32',
		CMUN: '082',
		DC: '1',
		NOMBRE: 'Trasmiras',
	},
	{
		CPRO: '32',
		CMUN: '083',
		DC: '7',
		NOMBRE: 'Veiga, A',
	},
	{
		CPRO: '32',
		CMUN: '084',
		DC: '2',
		NOMBRE: 'Verea',
	},
	{
		CPRO: '32',
		CMUN: '085',
		DC: '5',
		NOMBRE: 'Verín',
	},
	{
		CPRO: '32',
		CMUN: '086',
		DC: '8',
		NOMBRE: 'Viana do Bolo',
	},
	{
		CPRO: '32',
		CMUN: '087',
		DC: '4',
		NOMBRE: 'Vilamarín',
	},
	{
		CPRO: '32',
		CMUN: '088',
		DC: '0',
		NOMBRE: 'Vilamartín de Valdeorras',
	},
	{
		CPRO: '32',
		CMUN: '089',
		DC: '3',
		NOMBRE: 'Vilar de Barrio',
	},
	{
		CPRO: '32',
		CMUN: '090',
		DC: '7',
		NOMBRE: 'Vilar de Santos',
	},
	{
		CPRO: '32',
		CMUN: '091',
		DC: '4',
		NOMBRE: 'Vilardevós',
	},
	{
		CPRO: '32',
		CMUN: '092',
		DC: '9',
		NOMBRE: 'Vilariño de Conso',
	},
	{
		CPRO: '32',
		CMUN: '032',
		DC: '2',
		NOMBRE: 'Xinzo de Limia',
	},
	{
		CPRO: '32',
		CMUN: '036',
		DC: '9',
		NOMBRE: 'Xunqueira de Ambía',
	},
	{
		CPRO: '32',
		CMUN: '037',
		DC: '5',
		NOMBRE: 'Xunqueira de Espadanedo',
	},
	{
		CPRO: '33',
		CMUN: '001',
		DC: '9',
		NOMBRE: 'Allande',
	},
	{
		CPRO: '33',
		CMUN: '002',
		DC: '4',
		NOMBRE: 'Aller',
	},
	{
		CPRO: '33',
		CMUN: '003',
		DC: '0',
		NOMBRE: 'Amieva',
	},
	{
		CPRO: '33',
		CMUN: '004',
		DC: '5',
		NOMBRE: 'Avilés',
	},
	{
		CPRO: '33',
		CMUN: '005',
		DC: '8',
		NOMBRE: 'Belmonte de Miranda',
	},
	{
		CPRO: '33',
		CMUN: '006',
		DC: '1',
		NOMBRE: 'Bimenes',
	},
	{
		CPRO: '33',
		CMUN: '007',
		DC: '7',
		NOMBRE: 'Boal',
	},
	{
		CPRO: '33',
		CMUN: '008',
		DC: '3',
		NOMBRE: 'Cabrales',
	},
	{
		CPRO: '33',
		CMUN: '009',
		DC: '6',
		NOMBRE: 'Cabranes',
	},
	{
		CPRO: '33',
		CMUN: '010',
		DC: '0',
		NOMBRE: 'Candamo',
	},
	{
		CPRO: '33',
		CMUN: '012',
		DC: '2',
		NOMBRE: 'Cangas de Onís',
	},
	{
		CPRO: '33',
		CMUN: '011',
		DC: '7',
		NOMBRE: 'Cangas del Narcea',
	},
	{
		CPRO: '33',
		CMUN: '013',
		DC: '8',
		NOMBRE: 'Caravia',
	},
	{
		CPRO: '33',
		CMUN: '014',
		DC: '3',
		NOMBRE: 'Carreño',
	},
	{
		CPRO: '33',
		CMUN: '015',
		DC: '6',
		NOMBRE: 'Caso',
	},
	{
		CPRO: '33',
		CMUN: '016',
		DC: '9',
		NOMBRE: 'Castrillón',
	},
	{
		CPRO: '33',
		CMUN: '017',
		DC: '5',
		NOMBRE: 'Castropol',
	},
	{
		CPRO: '33',
		CMUN: '018',
		DC: '1',
		NOMBRE: 'Coaña',
	},
	{
		CPRO: '33',
		CMUN: '019',
		DC: '4',
		NOMBRE: 'Colunga',
	},
	{
		CPRO: '33',
		CMUN: '020',
		DC: '8',
		NOMBRE: 'Corvera de Asturias',
	},
	{
		CPRO: '33',
		CMUN: '021',
		DC: '5',
		NOMBRE: 'Cudillero',
	},
	{
		CPRO: '33',
		CMUN: '022',
		DC: '0',
		NOMBRE: 'Degaña',
	},
	{
		CPRO: '33',
		CMUN: '023',
		DC: '6',
		NOMBRE: 'Franco, El',
	},
	{
		CPRO: '33',
		CMUN: '024',
		DC: '1',
		NOMBRE: 'Gijón',
	},
	{
		CPRO: '33',
		CMUN: '025',
		DC: '4',
		NOMBRE: 'Gozón',
	},
	{
		CPRO: '33',
		CMUN: '026',
		DC: '7',
		NOMBRE: 'Grado',
	},
	{
		CPRO: '33',
		CMUN: '027',
		DC: '3',
		NOMBRE: 'Grandas de Salime',
	},
	{
		CPRO: '33',
		CMUN: '028',
		DC: '9',
		NOMBRE: 'Ibias',
	},
	{
		CPRO: '33',
		CMUN: '029',
		DC: '2',
		NOMBRE: 'Illano',
	},
	{
		CPRO: '33',
		CMUN: '030',
		DC: '6',
		NOMBRE: 'Illas',
	},
	{
		CPRO: '33',
		CMUN: '031',
		DC: '3',
		NOMBRE: 'Langreo',
	},
	{
		CPRO: '33',
		CMUN: '032',
		DC: '8',
		NOMBRE: 'Laviana',
	},
	{
		CPRO: '33',
		CMUN: '033',
		DC: '4',
		NOMBRE: 'Lena',
	},
	{
		CPRO: '33',
		CMUN: '035',
		DC: '2',
		NOMBRE: 'Llanera',
	},
	{
		CPRO: '33',
		CMUN: '036',
		DC: '5',
		NOMBRE: 'Llanes',
	},
	{
		CPRO: '33',
		CMUN: '037',
		DC: '1',
		NOMBRE: 'Mieres',
	},
	{
		CPRO: '33',
		CMUN: '038',
		DC: '7',
		NOMBRE: 'Morcín',
	},
	{
		CPRO: '33',
		CMUN: '039',
		DC: '0',
		NOMBRE: 'Muros de Nalón',
	},
	{
		CPRO: '33',
		CMUN: '040',
		DC: '4',
		NOMBRE: 'Nava',
	},
	{
		CPRO: '33',
		CMUN: '041',
		DC: '1',
		NOMBRE: 'Navia',
	},
	{
		CPRO: '33',
		CMUN: '042',
		DC: '6',
		NOMBRE: 'Noreña',
	},
	{
		CPRO: '33',
		CMUN: '043',
		DC: '2',
		NOMBRE: 'Onís',
	},
	{
		CPRO: '33',
		CMUN: '044',
		DC: '7',
		NOMBRE: 'Oviedo',
	},
	{
		CPRO: '33',
		CMUN: '045',
		DC: '0',
		NOMBRE: 'Parres',
	},
	{
		CPRO: '33',
		CMUN: '046',
		DC: '3',
		NOMBRE: 'Peñamellera Alta',
	},
	{
		CPRO: '33',
		CMUN: '047',
		DC: '9',
		NOMBRE: 'Peñamellera Baja',
	},
	{
		CPRO: '33',
		CMUN: '048',
		DC: '5',
		NOMBRE: 'Pesoz',
	},
	{
		CPRO: '33',
		CMUN: '049',
		DC: '8',
		NOMBRE: 'Piloña',
	},
	{
		CPRO: '33',
		CMUN: '050',
		DC: '1',
		NOMBRE: 'Ponga',
	},
	{
		CPRO: '33',
		CMUN: '051',
		DC: '8',
		NOMBRE: 'Pravia',
	},
	{
		CPRO: '33',
		CMUN: '052',
		DC: '3',
		NOMBRE: 'Proaza',
	},
	{
		CPRO: '33',
		CMUN: '053',
		DC: '9',
		NOMBRE: 'Quirós',
	},
	{
		CPRO: '33',
		CMUN: '054',
		DC: '4',
		NOMBRE: 'Regueras, Las',
	},
	{
		CPRO: '33',
		CMUN: '055',
		DC: '7',
		NOMBRE: 'Ribadedeva',
	},
	{
		CPRO: '33',
		CMUN: '056',
		DC: '0',
		NOMBRE: 'Ribadesella',
	},
	{
		CPRO: '33',
		CMUN: '057',
		DC: '6',
		NOMBRE: 'Ribera de Arriba',
	},
	{
		CPRO: '33',
		CMUN: '058',
		DC: '2',
		NOMBRE: 'Riosa',
	},
	{
		CPRO: '33',
		CMUN: '059',
		DC: '5',
		NOMBRE: 'Salas',
	},
	{
		CPRO: '33',
		CMUN: '061',
		DC: '6',
		NOMBRE: 'San Martín de Oscos',
	},
	{
		CPRO: '33',
		CMUN: '060',
		DC: '9',
		NOMBRE: 'San Martín del Rey Aurelio',
	},
	{
		CPRO: '33',
		CMUN: '063',
		DC: '7',
		NOMBRE: 'San Tirso de Abres',
	},
	{
		CPRO: '33',
		CMUN: '062',
		DC: '1',
		NOMBRE: 'Santa Eulalia de Oscos',
	},
	{
		CPRO: '33',
		CMUN: '064',
		DC: '2',
		NOMBRE: 'Santo Adriano',
	},
	{
		CPRO: '33',
		CMUN: '065',
		DC: '5',
		NOMBRE: 'Sariego',
	},
	{
		CPRO: '33',
		CMUN: '066',
		DC: '8',
		NOMBRE: 'Siero',
	},
	{
		CPRO: '33',
		CMUN: '067',
		DC: '4',
		NOMBRE: 'Sobrescobio',
	},
	{
		CPRO: '33',
		CMUN: '068',
		DC: '0',
		NOMBRE: 'Somiedo',
	},
	{
		CPRO: '33',
		CMUN: '069',
		DC: '3',
		NOMBRE: 'Soto del Barco',
	},
	{
		CPRO: '33',
		CMUN: '070',
		DC: '7',
		NOMBRE: 'Tapia de Casariego',
	},
	{
		CPRO: '33',
		CMUN: '071',
		DC: '4',
		NOMBRE: 'Taramundi',
	},
	{
		CPRO: '33',
		CMUN: '072',
		DC: '9',
		NOMBRE: 'Teverga',
	},
	{
		CPRO: '33',
		CMUN: '073',
		DC: '5',
		NOMBRE: 'Tineo',
	},
	{
		CPRO: '33',
		CMUN: '034',
		DC: '9',
		NOMBRE: 'Valdés',
	},
	{
		CPRO: '33',
		CMUN: '074',
		DC: '0',
		NOMBRE: 'Vegadeo',
	},
	{
		CPRO: '33',
		CMUN: '075',
		DC: '3',
		NOMBRE: 'Villanueva de Oscos',
	},
	{
		CPRO: '33',
		CMUN: '076',
		DC: '6',
		NOMBRE: 'Villaviciosa',
	},
	{
		CPRO: '33',
		CMUN: '077',
		DC: '2',
		NOMBRE: 'Villayón',
	},
	{
		CPRO: '33',
		CMUN: '078',
		DC: '8',
		NOMBRE: 'Yernes y Tameza',
	},
	{
		CPRO: '34',
		CMUN: '001',
		DC: '4',
		NOMBRE: 'Abarca de Campos',
	},
	{
		CPRO: '34',
		CMUN: '003',
		DC: '5',
		NOMBRE: 'Abia de las Torres',
	},
	{
		CPRO: '34',
		CMUN: '004',
		DC: '0',
		NOMBRE: 'Aguilar de Campoo',
	},
	{
		CPRO: '34',
		CMUN: '005',
		DC: '3',
		NOMBRE: 'Alar del Rey',
	},
	{
		CPRO: '34',
		CMUN: '006',
		DC: '6',
		NOMBRE: 'Alba de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '009',
		DC: '1',
		NOMBRE: 'Amayuelas de Arriba',
	},
	{
		CPRO: '34',
		CMUN: '010',
		DC: '5',
		NOMBRE: 'Ampudia',
	},
	{
		CPRO: '34',
		CMUN: '011',
		DC: '2',
		NOMBRE: 'Amusco',
	},
	{
		CPRO: '34',
		CMUN: '012',
		DC: '7',
		NOMBRE: 'Antigüedad',
	},
	{
		CPRO: '34',
		CMUN: '015',
		DC: '1',
		NOMBRE: 'Arconada',
	},
	{
		CPRO: '34',
		CMUN: '017',
		DC: '0',
		NOMBRE: 'Astudillo',
	},
	{
		CPRO: '34',
		CMUN: '018',
		DC: '6',
		NOMBRE: 'Autilla del Pino',
	},
	{
		CPRO: '34',
		CMUN: '019',
		DC: '9',
		NOMBRE: 'Autillo de Campos',
	},
	{
		CPRO: '34',
		CMUN: '020',
		DC: '3',
		NOMBRE: 'Ayuela',
	},
	{
		CPRO: '34',
		CMUN: '022',
		DC: '5',
		NOMBRE: 'Baltanás',
	},
	{
		CPRO: '34',
		CMUN: '024',
		DC: '6',
		NOMBRE: 'Baquerín de Campos',
	},
	{
		CPRO: '34',
		CMUN: '025',
		DC: '9',
		NOMBRE: 'Bárcena de Campos',
	},
	{
		CPRO: '34',
		CMUN: '027',
		DC: '8',
		NOMBRE: 'Barruelo de Santullán',
	},
	{
		CPRO: '34',
		CMUN: '028',
		DC: '4',
		NOMBRE: 'Báscones de Ojeda',
	},
	{
		CPRO: '34',
		CMUN: '029',
		DC: '7',
		NOMBRE: 'Becerril de Campos',
	},
	{
		CPRO: '34',
		CMUN: '031',
		DC: '8',
		NOMBRE: 'Belmonte de Campos',
	},
	{
		CPRO: '34',
		CMUN: '032',
		DC: '3',
		NOMBRE: 'Berzosilla',
	},
	{
		CPRO: '34',
		CMUN: '033',
		DC: '9',
		NOMBRE: 'Boada de Campos',
	},
	{
		CPRO: '34',
		CMUN: '035',
		DC: '7',
		NOMBRE: 'Boadilla de Rioseco',
	},
	{
		CPRO: '34',
		CMUN: '034',
		DC: '4',
		NOMBRE: 'Boadilla del Camino',
	},
	{
		CPRO: '34',
		CMUN: '036',
		DC: '0',
		NOMBRE: 'Brañosera',
	},
	{
		CPRO: '34',
		CMUN: '037',
		DC: '6',
		NOMBRE: 'Buenavista de Valdavia',
	},
	{
		CPRO: '34',
		CMUN: '038',
		DC: '2',
		NOMBRE: 'Bustillo de la Vega',
	},
	{
		CPRO: '34',
		CMUN: '039',
		DC: '5',
		NOMBRE: 'Bustillo del Páramo de Carrión',
	},
	{
		CPRO: '34',
		CMUN: '041',
		DC: '6',
		NOMBRE: 'Calahorra de Boedo',
	},
	{
		CPRO: '34',
		CMUN: '042',
		DC: '1',
		NOMBRE: 'Calzada de los Molinos',
	},
	{
		CPRO: '34',
		CMUN: '045',
		DC: '5',
		NOMBRE: 'Capillas',
	},
	{
		CPRO: '34',
		CMUN: '046',
		DC: '8',
		NOMBRE: 'Cardeñosa de Volpejera',
	},
	{
		CPRO: '34',
		CMUN: '047',
		DC: '4',
		NOMBRE: 'Carrión de los Condes',
	},
	{
		CPRO: '34',
		CMUN: '048',
		DC: '0',
		NOMBRE: 'Castil de Vela',
	},
	{
		CPRO: '34',
		CMUN: '049',
		DC: '3',
		NOMBRE: 'Castrejón de la Peña',
	},
	{
		CPRO: '34',
		CMUN: '050',
		DC: '6',
		NOMBRE: 'Castrillo de Don Juan',
	},
	{
		CPRO: '34',
		CMUN: '051',
		DC: '3',
		NOMBRE: 'Castrillo de Onielo',
	},
	{
		CPRO: '34',
		CMUN: '052',
		DC: '8',
		NOMBRE: 'Castrillo de Villavega',
	},
	{
		CPRO: '34',
		CMUN: '053',
		DC: '4',
		NOMBRE: 'Castromocho',
	},
	{
		CPRO: '34',
		CMUN: '055',
		DC: '2',
		NOMBRE: 'Cervatos de la Cueza',
	},
	{
		CPRO: '34',
		CMUN: '056',
		DC: '5',
		NOMBRE: 'Cervera de Pisuerga',
	},
	{
		CPRO: '34',
		CMUN: '057',
		DC: '1',
		NOMBRE: 'Cevico de la Torre',
	},
	{
		CPRO: '34',
		CMUN: '058',
		DC: '7',
		NOMBRE: 'Cevico Navero',
	},
	{
		CPRO: '34',
		CMUN: '059',
		DC: '0',
		NOMBRE: 'Cisneros',
	},
	{
		CPRO: '34',
		CMUN: '060',
		DC: '4',
		NOMBRE: 'Cobos de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '061',
		DC: '1',
		NOMBRE: 'Collazos de Boedo',
	},
	{
		CPRO: '34',
		CMUN: '062',
		DC: '6',
		NOMBRE: 'Congosto de Valdavia',
	},
	{
		CPRO: '34',
		CMUN: '063',
		DC: '2',
		NOMBRE: 'Cordovilla la Real',
	},
	{
		CPRO: '34',
		CMUN: '066',
		DC: '3',
		NOMBRE: 'Cubillas de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '067',
		DC: '9',
		NOMBRE: 'Dehesa de Montejo',
	},
	{
		CPRO: '34',
		CMUN: '068',
		DC: '5',
		NOMBRE: 'Dehesa de Romanos',
	},
	{
		CPRO: '34',
		CMUN: '069',
		DC: '8',
		NOMBRE: 'Dueñas',
	},
	{
		CPRO: '34',
		CMUN: '070',
		DC: '2',
		NOMBRE: 'Espinosa de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '071',
		DC: '9',
		NOMBRE: 'Espinosa de Villagonzalo',
	},
	{
		CPRO: '34',
		CMUN: '072',
		DC: '4',
		NOMBRE: 'Frechilla',
	},
	{
		CPRO: '34',
		CMUN: '073',
		DC: '0',
		NOMBRE: 'Fresno del Río',
	},
	{
		CPRO: '34',
		CMUN: '074',
		DC: '5',
		NOMBRE: 'Frómista',
	},
	{
		CPRO: '34',
		CMUN: '076',
		DC: '1',
		NOMBRE: 'Fuentes de Nava',
	},
	{
		CPRO: '34',
		CMUN: '077',
		DC: '7',
		NOMBRE: 'Fuentes de Valdepero',
	},
	{
		CPRO: '34',
		CMUN: '079',
		DC: '6',
		NOMBRE: 'Grijota',
	},
	{
		CPRO: '34',
		CMUN: '080',
		DC: '0',
		NOMBRE: 'Guardo',
	},
	{
		CPRO: '34',
		CMUN: '081',
		DC: '7',
		NOMBRE: 'Guaza de Campos',
	},
	{
		CPRO: '34',
		CMUN: '082',
		DC: '2',
		NOMBRE: 'Hérmedes de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '083',
		DC: '8',
		NOMBRE: 'Herrera de Pisuerga',
	},
	{
		CPRO: '34',
		CMUN: '084',
		DC: '3',
		NOMBRE: 'Herrera de Valdecañas',
	},
	{
		CPRO: '34',
		CMUN: '086',
		DC: '9',
		NOMBRE: 'Hontoria de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '087',
		DC: '5',
		NOMBRE: 'Hornillos de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '088',
		DC: '1',
		NOMBRE: 'Husillos',
	},
	{
		CPRO: '34',
		CMUN: '089',
		DC: '4',
		NOMBRE: 'Itero de la Vega',
	},
	{
		CPRO: '34',
		CMUN: '091',
		DC: '5',
		NOMBRE: 'Lagartos',
	},
	{
		CPRO: '34',
		CMUN: '092',
		DC: '0',
		NOMBRE: 'Lantadilla',
	},
	{
		CPRO: '34',
		CMUN: '094',
		DC: '1',
		NOMBRE: 'Ledigos',
	},
	{
		CPRO: '34',
		CMUN: '903',
		DC: '6',
		NOMBRE: 'Loma de Ucieza',
	},
	{
		CPRO: '34',
		CMUN: '096',
		DC: '7',
		NOMBRE: 'Lomas',
	},
	{
		CPRO: '34',
		CMUN: '098',
		DC: '9',
		NOMBRE: 'Magaz de Pisuerga',
	},
	{
		CPRO: '34',
		CMUN: '099',
		DC: '2',
		NOMBRE: 'Manquillos',
	},
	{
		CPRO: '34',
		CMUN: '100',
		DC: '6',
		NOMBRE: 'Mantinos',
	},
	{
		CPRO: '34',
		CMUN: '101',
		DC: '3',
		NOMBRE: 'Marcilla de Campos',
	},
	{
		CPRO: '34',
		CMUN: '102',
		DC: '8',
		NOMBRE: 'Mazariegos',
	},
	{
		CPRO: '34',
		CMUN: '103',
		DC: '4',
		NOMBRE: 'Mazuecos de Valdeginate',
	},
	{
		CPRO: '34',
		CMUN: '104',
		DC: '9',
		NOMBRE: 'Melgar de Yuso',
	},
	{
		CPRO: '34',
		CMUN: '106',
		DC: '5',
		NOMBRE: 'Meneses de Campos',
	},
	{
		CPRO: '34',
		CMUN: '107',
		DC: '1',
		NOMBRE: 'Micieces de Ojeda',
	},
	{
		CPRO: '34',
		CMUN: '108',
		DC: '7',
		NOMBRE: 'Monzón de Campos',
	},
	{
		CPRO: '34',
		CMUN: '109',
		DC: '0',
		NOMBRE: 'Moratinos',
	},
	{
		CPRO: '34',
		CMUN: '110',
		DC: '4',
		NOMBRE: 'Mudá',
	},
	{
		CPRO: '34',
		CMUN: '112',
		DC: '6',
		NOMBRE: 'Nogal de las Huertas',
	},
	{
		CPRO: '34',
		CMUN: '113',
		DC: '2',
		NOMBRE: 'Olea de Boedo',
	},
	{
		CPRO: '34',
		CMUN: '114',
		DC: '7',
		NOMBRE: 'Olmos de Ojeda',
	},
	{
		CPRO: '34',
		CMUN: '116',
		DC: '3',
		NOMBRE: 'Osornillo',
	},
	{
		CPRO: '34',
		CMUN: '901',
		DC: '5',
		NOMBRE: 'Osorno la Mayor',
	},
	{
		CPRO: '34',
		CMUN: '120',
		DC: '2',
		NOMBRE: 'Palencia',
	},
	{
		CPRO: '34',
		CMUN: '121',
		DC: '9',
		NOMBRE: 'Palenzuela',
	},
	{
		CPRO: '34',
		CMUN: '122',
		DC: '4',
		NOMBRE: 'Páramo de Boedo',
	},
	{
		CPRO: '34',
		CMUN: '123',
		DC: '0',
		NOMBRE: 'Paredes de Nava',
	},
	{
		CPRO: '34',
		CMUN: '124',
		DC: '5',
		NOMBRE: 'Payo de Ojeda',
	},
	{
		CPRO: '34',
		CMUN: '125',
		DC: '8',
		NOMBRE: 'Pedraza de Campos',
	},
	{
		CPRO: '34',
		CMUN: '126',
		DC: '1',
		NOMBRE: 'Pedrosa de la Vega',
	},
	{
		CPRO: '34',
		CMUN: '127',
		DC: '7',
		NOMBRE: 'Perales',
	},
	{
		CPRO: '34',
		CMUN: '904',
		DC: '1',
		NOMBRE: 'Pernía, La',
	},
	{
		CPRO: '34',
		CMUN: '129',
		DC: '6',
		NOMBRE: 'Pino del Río',
	},
	{
		CPRO: '34',
		CMUN: '130',
		DC: '0',
		NOMBRE: 'Piña de Campos',
	},
	{
		CPRO: '34',
		CMUN: '131',
		DC: '7',
		NOMBRE: 'Población de Arroyo',
	},
	{
		CPRO: '34',
		CMUN: '132',
		DC: '2',
		NOMBRE: 'Población de Campos',
	},
	{
		CPRO: '34',
		CMUN: '133',
		DC: '8',
		NOMBRE: 'Población de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '134',
		DC: '3',
		NOMBRE: 'Polentinos',
	},
	{
		CPRO: '34',
		CMUN: '135',
		DC: '6',
		NOMBRE: 'Pomar de Valdivia',
	},
	{
		CPRO: '34',
		CMUN: '136',
		DC: '9',
		NOMBRE: 'Poza de la Vega',
	},
	{
		CPRO: '34',
		CMUN: '137',
		DC: '5',
		NOMBRE: 'Pozo de Urama',
	},
	{
		CPRO: '34',
		CMUN: '139',
		DC: '4',
		NOMBRE: 'Prádanos de Ojeda',
	},
	{
		CPRO: '34',
		CMUN: '140',
		DC: '8',
		NOMBRE: 'Puebla de Valdavia, La',
	},
	{
		CPRO: '34',
		CMUN: '141',
		DC: '5',
		NOMBRE: 'Quintana del Puente',
	},
	{
		CPRO: '34',
		CMUN: '143',
		DC: '6',
		NOMBRE: 'Quintanilla de Onsoña',
	},
	{
		CPRO: '34',
		CMUN: '146',
		DC: '7',
		NOMBRE: 'Reinoso de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '147',
		DC: '3',
		NOMBRE: 'Renedo de la Vega',
	},
	{
		CPRO: '34',
		CMUN: '149',
		DC: '2',
		NOMBRE: 'Requena de Campos',
	},
	{
		CPRO: '34',
		CMUN: '151',
		DC: '2',
		NOMBRE: 'Respenda de la Peña',
	},
	{
		CPRO: '34',
		CMUN: '152',
		DC: '7',
		NOMBRE: 'Revenga de Campos',
	},
	{
		CPRO: '34',
		CMUN: '154',
		DC: '8',
		NOMBRE: 'Revilla de Collazos',
	},
	{
		CPRO: '34',
		CMUN: '155',
		DC: '1',
		NOMBRE: 'Ribas de Campos',
	},
	{
		CPRO: '34',
		CMUN: '156',
		DC: '4',
		NOMBRE: 'Riberos de la Cueza',
	},
	{
		CPRO: '34',
		CMUN: '157',
		DC: '0',
		NOMBRE: 'Saldaña',
	},
	{
		CPRO: '34',
		CMUN: '158',
		DC: '6',
		NOMBRE: 'Salinas de Pisuerga',
	},
	{
		CPRO: '34',
		CMUN: '159',
		DC: '9',
		NOMBRE: 'San Cebrián de Campos',
	},
	{
		CPRO: '34',
		CMUN: '160',
		DC: '3',
		NOMBRE: 'San Cebrián de Mudá',
	},
	{
		CPRO: '34',
		CMUN: '161',
		DC: '0',
		NOMBRE: 'San Cristóbal de Boedo',
	},
	{
		CPRO: '34',
		CMUN: '163',
		DC: '1',
		NOMBRE: 'San Mamés de Campos',
	},
	{
		CPRO: '34',
		CMUN: '165',
		DC: '9',
		NOMBRE: 'San Román de la Cuba',
	},
	{
		CPRO: '34',
		CMUN: '167',
		DC: '8',
		NOMBRE: 'Santa Cecilia del Alcor',
	},
	{
		CPRO: '34',
		CMUN: '168',
		DC: '4',
		NOMBRE: 'Santa Cruz de Boedo',
	},
	{
		CPRO: '34',
		CMUN: '169',
		DC: '7',
		NOMBRE: 'Santervás de la Vega',
	},
	{
		CPRO: '34',
		CMUN: '170',
		DC: '1',
		NOMBRE: 'Santibáñez de Ecla',
	},
	{
		CPRO: '34',
		CMUN: '171',
		DC: '8',
		NOMBRE: 'Santibáñez de la Peña',
	},
	{
		CPRO: '34',
		CMUN: '174',
		DC: '4',
		NOMBRE: 'Santoyo',
	},
	{
		CPRO: '34',
		CMUN: '175',
		DC: '7',
		NOMBRE: 'Serna, La',
	},
	{
		CPRO: '34',
		CMUN: '177',
		DC: '6',
		NOMBRE: 'Soto de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '176',
		DC: '0',
		NOMBRE: 'Sotobañado y Priorato',
	},
	{
		CPRO: '34',
		CMUN: '178',
		DC: '2',
		NOMBRE: 'Tabanera de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '179',
		DC: '5',
		NOMBRE: 'Tabanera de Valdavia',
	},
	{
		CPRO: '34',
		CMUN: '180',
		DC: '9',
		NOMBRE: 'Támara de Campos',
	},
	{
		CPRO: '34',
		CMUN: '181',
		DC: '6',
		NOMBRE: 'Tariego de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '182',
		DC: '1',
		NOMBRE: 'Torquemada',
	},
	{
		CPRO: '34',
		CMUN: '184',
		DC: '2',
		NOMBRE: 'Torremormojón',
	},
	{
		CPRO: '34',
		CMUN: '185',
		DC: '5',
		NOMBRE: 'Triollo',
	},
	{
		CPRO: '34',
		CMUN: '186',
		DC: '8',
		NOMBRE: 'Valbuena de Pisuerga',
	},
	{
		CPRO: '34',
		CMUN: '189',
		DC: '3',
		NOMBRE: 'Valdeolmillos',
	},
	{
		CPRO: '34',
		CMUN: '190',
		DC: '7',
		NOMBRE: 'Valderrábano',
	},
	{
		CPRO: '34',
		CMUN: '192',
		DC: '9',
		NOMBRE: 'Valde-Ucieza',
	},
	{
		CPRO: '34',
		CMUN: '196',
		DC: '6',
		NOMBRE: 'Valle de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '902',
		DC: '0',
		NOMBRE: 'Valle del Retortillo',
	},
	{
		CPRO: '34',
		CMUN: '199',
		DC: '1',
		NOMBRE: 'Velilla del Río Carrión',
	},
	{
		CPRO: '34',
		CMUN: '023',
		DC: '1',
		NOMBRE: 'Venta de Baños',
	},
	{
		CPRO: '34',
		CMUN: '201',
		DC: '2',
		NOMBRE: 'Vertavillo',
	},
	{
		CPRO: '34',
		CMUN: '093',
		DC: '6',
		NOMBRE: 'Vid de Ojeda, La',
	},
	{
		CPRO: '34',
		CMUN: '202',
		DC: '7',
		NOMBRE: 'Villabasta de Valdavia',
	},
	{
		CPRO: '34',
		CMUN: '204',
		DC: '8',
		NOMBRE: 'Villacidaler',
	},
	{
		CPRO: '34',
		CMUN: '205',
		DC: '1',
		NOMBRE: 'Villaconancio',
	},
	{
		CPRO: '34',
		CMUN: '206',
		DC: '4',
		NOMBRE: 'Villada',
	},
	{
		CPRO: '34',
		CMUN: '208',
		DC: '6',
		NOMBRE: 'Villaeles de Valdavia',
	},
	{
		CPRO: '34',
		CMUN: '210',
		DC: '3',
		NOMBRE: 'Villahán',
	},
	{
		CPRO: '34',
		CMUN: '211',
		DC: '0',
		NOMBRE: 'Villaherreros',
	},
	{
		CPRO: '34',
		CMUN: '213',
		DC: '1',
		NOMBRE: 'Villalaco',
	},
	{
		CPRO: '34',
		CMUN: '214',
		DC: '6',
		NOMBRE: 'Villalba de Guardo',
	},
	{
		CPRO: '34',
		CMUN: '215',
		DC: '9',
		NOMBRE: 'Villalcázar de Sirga',
	},
	{
		CPRO: '34',
		CMUN: '216',
		DC: '2',
		NOMBRE: 'Villalcón',
	},
	{
		CPRO: '34',
		CMUN: '217',
		DC: '8',
		NOMBRE: 'Villalobón',
	},
	{
		CPRO: '34',
		CMUN: '218',
		DC: '4',
		NOMBRE: 'Villaluenga de la Vega',
	},
	{
		CPRO: '34',
		CMUN: '220',
		DC: '1',
		NOMBRE: 'Villamartín de Campos',
	},
	{
		CPRO: '34',
		CMUN: '221',
		DC: '8',
		NOMBRE: 'Villamediana',
	},
	{
		CPRO: '34',
		CMUN: '222',
		DC: '3',
		NOMBRE: 'Villameriel',
	},
	{
		CPRO: '34',
		CMUN: '223',
		DC: '9',
		NOMBRE: 'Villamoronta',
	},
	{
		CPRO: '34',
		CMUN: '224',
		DC: '4',
		NOMBRE: 'Villamuera de la Cueza',
	},
	{
		CPRO: '34',
		CMUN: '225',
		DC: '7',
		NOMBRE: 'Villamuriel de Cerrato',
	},
	{
		CPRO: '34',
		CMUN: '227',
		DC: '6',
		NOMBRE: 'Villanueva del Rebollar',
	},
	{
		CPRO: '34',
		CMUN: '228',
		DC: '2',
		NOMBRE: 'Villanuño de Valdavia',
	},
	{
		CPRO: '34',
		CMUN: '229',
		DC: '5',
		NOMBRE: 'Villaprovedo',
	},
	{
		CPRO: '34',
		CMUN: '230',
		DC: '9',
		NOMBRE: 'Villarmentero de Campos',
	},
	{
		CPRO: '34',
		CMUN: '231',
		DC: '6',
		NOMBRE: 'Villarrabé',
	},
	{
		CPRO: '34',
		CMUN: '232',
		DC: '1',
		NOMBRE: 'Villarramiel',
	},
	{
		CPRO: '34',
		CMUN: '233',
		DC: '7',
		NOMBRE: 'Villasarracino',
	},
	{
		CPRO: '34',
		CMUN: '234',
		DC: '2',
		NOMBRE: 'Villasila de Valdavia',
	},
	{
		CPRO: '34',
		CMUN: '236',
		DC: '8',
		NOMBRE: 'Villaturde',
	},
	{
		CPRO: '34',
		CMUN: '237',
		DC: '4',
		NOMBRE: 'Villaumbrales',
	},
	{
		CPRO: '34',
		CMUN: '238',
		DC: '0',
		NOMBRE: 'Villaviudas',
	},
	{
		CPRO: '34',
		CMUN: '240',
		DC: '7',
		NOMBRE: 'Villerías de Campos',
	},
	{
		CPRO: '34',
		CMUN: '241',
		DC: '4',
		NOMBRE: 'Villodre',
	},
	{
		CPRO: '34',
		CMUN: '242',
		DC: '9',
		NOMBRE: 'Villodrigo',
	},
	{
		CPRO: '34',
		CMUN: '243',
		DC: '5',
		NOMBRE: 'Villoldo',
	},
	{
		CPRO: '34',
		CMUN: '245',
		DC: '3',
		NOMBRE: 'Villota del Páramo',
	},
	{
		CPRO: '34',
		CMUN: '246',
		DC: '6',
		NOMBRE: 'Villovieco',
	},
	{
		CPRO: '35',
		CMUN: '001',
		DC: '7',
		NOMBRE: 'Agaete',
	},
	{
		CPRO: '35',
		CMUN: '002',
		DC: '2',
		NOMBRE: 'Agüimes',
	},
	{
		CPRO: '35',
		CMUN: '020',
		DC: '6',
		NOMBRE: 'Aldea de San Nicolás, La',
	},
	{
		CPRO: '35',
		CMUN: '003',
		DC: '8',
		NOMBRE: 'Antigua',
	},
	{
		CPRO: '35',
		CMUN: '004',
		DC: '3',
		NOMBRE: 'Arrecife',
	},
	{
		CPRO: '35',
		CMUN: '005',
		DC: '6',
		NOMBRE: 'Artenara',
	},
	{
		CPRO: '35',
		CMUN: '006',
		DC: '9',
		NOMBRE: 'Arucas',
	},
	{
		CPRO: '35',
		CMUN: '007',
		DC: '5',
		NOMBRE: 'Betancuria',
	},
	{
		CPRO: '35',
		CMUN: '008',
		DC: '1',
		NOMBRE: 'Firgas',
	},
	{
		CPRO: '35',
		CMUN: '009',
		DC: '4',
		NOMBRE: 'Gáldar',
	},
	{
		CPRO: '35',
		CMUN: '010',
		DC: '8',
		NOMBRE: 'Haría',
	},
	{
		CPRO: '35',
		CMUN: '011',
		DC: '5',
		NOMBRE: 'Ingenio',
	},
	{
		CPRO: '35',
		CMUN: '012',
		DC: '0',
		NOMBRE: 'Mogán',
	},
	{
		CPRO: '35',
		CMUN: '013',
		DC: '6',
		NOMBRE: 'Moya',
	},
	{
		CPRO: '35',
		CMUN: '014',
		DC: '1',
		NOMBRE: 'Oliva, La',
	},
	{
		CPRO: '35',
		CMUN: '015',
		DC: '4',
		NOMBRE: 'Pájara',
	},
	{
		CPRO: '35',
		CMUN: '016',
		DC: '7',
		NOMBRE: 'Palmas de Gran Canaria, Las',
	},
	{
		CPRO: '35',
		CMUN: '017',
		DC: '3',
		NOMBRE: 'Puerto del Rosario',
	},
	{
		CPRO: '35',
		CMUN: '018',
		DC: '9',
		NOMBRE: 'San Bartolomé',
	},
	{
		CPRO: '35',
		CMUN: '019',
		DC: '2',
		NOMBRE: 'San Bartolomé de Tirajana',
	},
	{
		CPRO: '35',
		CMUN: '021',
		DC: '3',
		NOMBRE: 'Santa Brígida',
	},
	{
		CPRO: '35',
		CMUN: '022',
		DC: '8',
		NOMBRE: 'Santa Lucía de Tirajana',
	},
	{
		CPRO: '35',
		CMUN: '023',
		DC: '4',
		NOMBRE: 'Santa María de Guía de Gran Canaria',
	},
	{
		CPRO: '35',
		CMUN: '024',
		DC: '9',
		NOMBRE: 'Teguise',
	},
	{
		CPRO: '35',
		CMUN: '025',
		DC: '2',
		NOMBRE: 'Tejeda',
	},
	{
		CPRO: '35',
		CMUN: '026',
		DC: '5',
		NOMBRE: 'Telde',
	},
	{
		CPRO: '35',
		CMUN: '027',
		DC: '1',
		NOMBRE: 'Teror',
	},
	{
		CPRO: '35',
		CMUN: '028',
		DC: '7',
		NOMBRE: 'Tías',
	},
	{
		CPRO: '35',
		CMUN: '029',
		DC: '0',
		NOMBRE: 'Tinajo',
	},
	{
		CPRO: '35',
		CMUN: '030',
		DC: '4',
		NOMBRE: 'Tuineje',
	},
	{
		CPRO: '35',
		CMUN: '032',
		DC: '6',
		NOMBRE: 'Valleseco',
	},
	{
		CPRO: '35',
		CMUN: '031',
		DC: '1',
		NOMBRE: 'Valsequillo de Gran Canaria',
	},
	{
		CPRO: '35',
		CMUN: '033',
		DC: '2',
		NOMBRE: 'Vega de San Mateo',
	},
	{
		CPRO: '35',
		CMUN: '034',
		DC: '7',
		NOMBRE: 'Yaiza',
	},
	{
		CPRO: '36',
		CMUN: '020',
		DC: '9',
		NOMBRE: 'Agolada',
	},
	{
		CPRO: '36',
		CMUN: '001',
		DC: '0',
		NOMBRE: 'Arbo',
	},
	{
		CPRO: '36',
		CMUN: '003',
		DC: '1',
		NOMBRE: 'Baiona',
	},
	{
		CPRO: '36',
		CMUN: '002',
		DC: '5',
		NOMBRE: 'Barro',
	},
	{
		CPRO: '36',
		CMUN: '004',
		DC: '6',
		NOMBRE: 'Bueu',
	},
	{
		CPRO: '36',
		CMUN: '005',
		DC: '9',
		NOMBRE: 'Caldas de Reis',
	},
	{
		CPRO: '36',
		CMUN: '006',
		DC: '2',
		NOMBRE: 'Cambados',
	},
	{
		CPRO: '36',
		CMUN: '007',
		DC: '8',
		NOMBRE: 'Campo Lameiro',
	},
	{
		CPRO: '36',
		CMUN: '008',
		DC: '4',
		NOMBRE: 'Cangas',
	},
	{
		CPRO: '36',
		CMUN: '009',
		DC: '7',
		NOMBRE: 'Cañiza, A',
	},
	{
		CPRO: '36',
		CMUN: '010',
		DC: '1',
		NOMBRE: 'Catoira',
	},
	{
		CPRO: '36',
		CMUN: '011',
		DC: '8',
		NOMBRE: 'Cerdedo',
	},
	{
		CPRO: '36',
		CMUN: '012',
		DC: '3',
		NOMBRE: 'Cotobade',
	},
	{
		CPRO: '36',
		CMUN: '013',
		DC: '9',
		NOMBRE: 'Covelo',
	},
	{
		CPRO: '36',
		CMUN: '014',
		DC: '4',
		NOMBRE: 'Crecente',
	},
	{
		CPRO: '36',
		CMUN: '015',
		DC: '7',
		NOMBRE: 'Cuntis',
	},
	{
		CPRO: '36',
		CMUN: '016',
		DC: '0',
		NOMBRE: 'Dozón',
	},
	{
		CPRO: '36',
		CMUN: '017',
		DC: '6',
		NOMBRE: 'Estrada, A',
	},
	{
		CPRO: '36',
		CMUN: '018',
		DC: '2',
		NOMBRE: 'Forcarei',
	},
	{
		CPRO: '36',
		CMUN: '019',
		DC: '5',
		NOMBRE: 'Fornelos de Montes',
	},
	{
		CPRO: '36',
		CMUN: '021',
		DC: '6',
		NOMBRE: 'Gondomar',
	},
	{
		CPRO: '36',
		CMUN: '022',
		DC: '1',
		NOMBRE: 'Grove, O',
	},
	{
		CPRO: '36',
		CMUN: '023',
		DC: '7',
		NOMBRE: 'Guarda, A',
	},
	{
		CPRO: '36',
		CMUN: '901',
		DC: '1',
		NOMBRE: 'Illa de Arousa, A',
	},
	{
		CPRO: '36',
		CMUN: '024',
		DC: '2',
		NOMBRE: 'Lalín',
	},
	{
		CPRO: '36',
		CMUN: '025',
		DC: '5',
		NOMBRE: 'Lama, A',
	},
	{
		CPRO: '36',
		CMUN: '026',
		DC: '8',
		NOMBRE: 'Marín',
	},
	{
		CPRO: '36',
		CMUN: '027',
		DC: '4',
		NOMBRE: 'Meaño',
	},
	{
		CPRO: '36',
		CMUN: '028',
		DC: '0',
		NOMBRE: 'Meis',
	},
	{
		CPRO: '36',
		CMUN: '029',
		DC: '3',
		NOMBRE: 'Moaña',
	},
	{
		CPRO: '36',
		CMUN: '030',
		DC: '7',
		NOMBRE: 'Mondariz',
	},
	{
		CPRO: '36',
		CMUN: '031',
		DC: '4',
		NOMBRE: 'Mondariz-Balneario',
	},
	{
		CPRO: '36',
		CMUN: '032',
		DC: '9',
		NOMBRE: 'Moraña',
	},
	{
		CPRO: '36',
		CMUN: '033',
		DC: '5',
		NOMBRE: 'Mos',
	},
	{
		CPRO: '36',
		CMUN: '034',
		DC: '0',
		NOMBRE: 'Neves, As',
	},
	{
		CPRO: '36',
		CMUN: '035',
		DC: '3',
		NOMBRE: 'Nigrán',
	},
	{
		CPRO: '36',
		CMUN: '036',
		DC: '6',
		NOMBRE: 'Oia',
	},
	{
		CPRO: '36',
		CMUN: '037',
		DC: '2',
		NOMBRE: 'Pazos de Borbén',
	},
	{
		CPRO: '36',
		CMUN: '041',
		DC: '2',
		NOMBRE: 'Poio',
	},
	{
		CPRO: '36',
		CMUN: '043',
		DC: '3',
		NOMBRE: 'Ponte Caldelas',
	},
	{
		CPRO: '36',
		CMUN: '042',
		DC: '7',
		NOMBRE: 'Ponteareas',
	},
	{
		CPRO: '36',
		CMUN: '044',
		DC: '8',
		NOMBRE: 'Pontecesures',
	},
	{
		CPRO: '36',
		CMUN: '038',
		DC: '8',
		NOMBRE: 'Pontevedra',
	},
	{
		CPRO: '36',
		CMUN: '039',
		DC: '1',
		NOMBRE: 'Porriño, O',
	},
	{
		CPRO: '36',
		CMUN: '040',
		DC: '5',
		NOMBRE: 'Portas',
	},
	{
		CPRO: '36',
		CMUN: '045',
		DC: '1',
		NOMBRE: 'Redondela',
	},
	{
		CPRO: '36',
		CMUN: '046',
		DC: '4',
		NOMBRE: 'Ribadumia',
	},
	{
		CPRO: '36',
		CMUN: '047',
		DC: '0',
		NOMBRE: 'Rodeiro',
	},
	{
		CPRO: '36',
		CMUN: '048',
		DC: '6',
		NOMBRE: 'Rosal, O',
	},
	{
		CPRO: '36',
		CMUN: '049',
		DC: '9',
		NOMBRE: 'Salceda de Caselas',
	},
	{
		CPRO: '36',
		CMUN: '050',
		DC: '2',
		NOMBRE: 'Salvaterra de Miño',
	},
	{
		CPRO: '36',
		CMUN: '051',
		DC: '9',
		NOMBRE: 'Sanxenxo',
	},
	{
		CPRO: '36',
		CMUN: '052',
		DC: '4',
		NOMBRE: 'Silleda',
	},
	{
		CPRO: '36',
		CMUN: '053',
		DC: '0',
		NOMBRE: 'Soutomaior',
	},
	{
		CPRO: '36',
		CMUN: '054',
		DC: '5',
		NOMBRE: 'Tomiño',
	},
	{
		CPRO: '36',
		CMUN: '055',
		DC: '8',
		NOMBRE: 'Tui',
	},
	{
		CPRO: '36',
		CMUN: '056',
		DC: '1',
		NOMBRE: 'Valga',
	},
	{
		CPRO: '36',
		CMUN: '057',
		DC: '7',
		NOMBRE: 'Vigo',
	},
	{
		CPRO: '36',
		CMUN: '059',
		DC: '6',
		NOMBRE: 'Vila de Cruces',
	},
	{
		CPRO: '36',
		CMUN: '058',
		DC: '3',
		NOMBRE: 'Vilaboa',
	},
	{
		CPRO: '36',
		CMUN: '060',
		DC: '0',
		NOMBRE: 'Vilagarcía de Arousa',
	},
	{
		CPRO: '36',
		CMUN: '061',
		DC: '7',
		NOMBRE: 'Vilanova de Arousa',
	},
	{
		CPRO: '37',
		CMUN: '001',
		DC: '6',
		NOMBRE: 'Abusejo',
	},
	{
		CPRO: '37',
		CMUN: '002',
		DC: '1',
		NOMBRE: 'Agallas',
	},
	{
		CPRO: '37',
		CMUN: '003',
		DC: '7',
		NOMBRE: 'Ahigal de los Aceiteros',
	},
	{
		CPRO: '37',
		CMUN: '004',
		DC: '2',
		NOMBRE: 'Ahigal de Villarino',
	},
	{
		CPRO: '37',
		CMUN: '005',
		DC: '5',
		NOMBRE: 'Alameda de Gardón, La',
	},
	{
		CPRO: '37',
		CMUN: '006',
		DC: '8',
		NOMBRE: 'Alamedilla, La',
	},
	{
		CPRO: '37',
		CMUN: '007',
		DC: '4',
		NOMBRE: 'Alaraz',
	},
	{
		CPRO: '37',
		CMUN: '008',
		DC: '0',
		NOMBRE: 'Alba de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '009',
		DC: '3',
		NOMBRE: 'Alba de Yeltes',
	},
	{
		CPRO: '37',
		CMUN: '010',
		DC: '7',
		NOMBRE: 'Alberca, La',
	},
	{
		CPRO: '37',
		CMUN: '011',
		DC: '4',
		NOMBRE: 'Alberguería de Argañán, La',
	},
	{
		CPRO: '37',
		CMUN: '012',
		DC: '9',
		NOMBRE: 'Alconada',
	},
	{
		CPRO: '37',
		CMUN: '015',
		DC: '3',
		NOMBRE: 'Aldea del Obispo',
	},
	{
		CPRO: '37',
		CMUN: '013',
		DC: '5',
		NOMBRE: 'Aldeacipreste',
	},
	{
		CPRO: '37',
		CMUN: '014',
		DC: '0',
		NOMBRE: 'Aldeadávila de la Ribera',
	},
	{
		CPRO: '37',
		CMUN: '016',
		DC: '6',
		NOMBRE: 'Aldealengua',
	},
	{
		CPRO: '37',
		CMUN: '017',
		DC: '2',
		NOMBRE: 'Aldeanueva de Figueroa',
	},
	{
		CPRO: '37',
		CMUN: '018',
		DC: '8',
		NOMBRE: 'Aldeanueva de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '019',
		DC: '1',
		NOMBRE: 'Aldearrodrigo',
	},
	{
		CPRO: '37',
		CMUN: '020',
		DC: '5',
		NOMBRE: 'Aldearrubia',
	},
	{
		CPRO: '37',
		CMUN: '021',
		DC: '2',
		NOMBRE: 'Aldeaseca de Alba',
	},
	{
		CPRO: '37',
		CMUN: '022',
		DC: '7',
		NOMBRE: 'Aldeaseca de la Frontera',
	},
	{
		CPRO: '37',
		CMUN: '023',
		DC: '3',
		NOMBRE: 'Aldeatejada',
	},
	{
		CPRO: '37',
		CMUN: '024',
		DC: '8',
		NOMBRE: 'Aldeavieja de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '025',
		DC: '1',
		NOMBRE: 'Aldehuela de la Bóveda',
	},
	{
		CPRO: '37',
		CMUN: '026',
		DC: '4',
		NOMBRE: 'Aldehuela de Yeltes',
	},
	{
		CPRO: '37',
		CMUN: '027',
		DC: '0',
		NOMBRE: 'Almenara de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '028',
		DC: '6',
		NOMBRE: 'Almendra',
	},
	{
		CPRO: '37',
		CMUN: '029',
		DC: '9',
		NOMBRE: 'Anaya de Alba',
	},
	{
		CPRO: '37',
		CMUN: '030',
		DC: '3',
		NOMBRE: 'Añover de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '031',
		DC: '0',
		NOMBRE: 'Arabayona de Mógica',
	},
	{
		CPRO: '37',
		CMUN: '032',
		DC: '5',
		NOMBRE: 'Arapiles',
	},
	{
		CPRO: '37',
		CMUN: '033',
		DC: '1',
		NOMBRE: 'Arcediano',
	},
	{
		CPRO: '37',
		CMUN: '034',
		DC: '6',
		NOMBRE: 'Arco, El',
	},
	{
		CPRO: '37',
		CMUN: '035',
		DC: '9',
		NOMBRE: 'Armenteros',
	},
	{
		CPRO: '37',
		CMUN: '037',
		DC: '8',
		NOMBRE: 'Atalaya, La',
	},
	{
		CPRO: '37',
		CMUN: '038',
		DC: '4',
		NOMBRE: 'Babilafuente',
	},
	{
		CPRO: '37',
		CMUN: '039',
		DC: '7',
		NOMBRE: 'Bañobárez',
	},
	{
		CPRO: '37',
		CMUN: '040',
		DC: '1',
		NOMBRE: 'Barbadillo',
	},
	{
		CPRO: '37',
		CMUN: '041',
		DC: '8',
		NOMBRE: 'Barbalos',
	},
	{
		CPRO: '37',
		CMUN: '042',
		DC: '3',
		NOMBRE: 'Barceo',
	},
	{
		CPRO: '37',
		CMUN: '044',
		DC: '4',
		NOMBRE: 'Barruecopardo',
	},
	{
		CPRO: '37',
		CMUN: '045',
		DC: '7',
		NOMBRE: 'Bastida, La',
	},
	{
		CPRO: '37',
		CMUN: '046',
		DC: '0',
		NOMBRE: 'Béjar',
	},
	{
		CPRO: '37',
		CMUN: '047',
		DC: '6',
		NOMBRE: 'Beleña',
	},
	{
		CPRO: '37',
		CMUN: '049',
		DC: '5',
		NOMBRE: 'Bermellar',
	},
	{
		CPRO: '37',
		CMUN: '050',
		DC: '8',
		NOMBRE: 'Berrocal de Huebra',
	},
	{
		CPRO: '37',
		CMUN: '051',
		DC: '5',
		NOMBRE: 'Berrocal de Salvatierra',
	},
	{
		CPRO: '37',
		CMUN: '052',
		DC: '0',
		NOMBRE: 'Boada',
	},
	{
		CPRO: '37',
		CMUN: '054',
		DC: '1',
		NOMBRE: 'Bodón, El',
	},
	{
		CPRO: '37',
		CMUN: '055',
		DC: '4',
		NOMBRE: 'Bogajo',
	},
	{
		CPRO: '37',
		CMUN: '056',
		DC: '7',
		NOMBRE: 'Bouza, La',
	},
	{
		CPRO: '37',
		CMUN: '057',
		DC: '3',
		NOMBRE: 'Bóveda del Río Almar',
	},
	{
		CPRO: '37',
		CMUN: '058',
		DC: '9',
		NOMBRE: 'Brincones',
	},
	{
		CPRO: '37',
		CMUN: '059',
		DC: '2',
		NOMBRE: 'Buenamadre',
	},
	{
		CPRO: '37',
		CMUN: '060',
		DC: '6',
		NOMBRE: 'Buenavista',
	},
	{
		CPRO: '37',
		CMUN: '061',
		DC: '3',
		NOMBRE: 'Cabaco, El',
	},
	{
		CPRO: '37',
		CMUN: '063',
		DC: '4',
		NOMBRE: 'Cabeza de Béjar, La',
	},
	{
		CPRO: '37',
		CMUN: '065',
		DC: '2',
		NOMBRE: 'Cabeza del Caballo',
	},
	{
		CPRO: '37',
		CMUN: '062',
		DC: '8',
		NOMBRE: 'Cabezabellosa de la Calzada',
	},
	{
		CPRO: '37',
		CMUN: '067',
		DC: '1',
		NOMBRE: 'Cabrerizos',
	},
	{
		CPRO: '37',
		CMUN: '068',
		DC: '7',
		NOMBRE: 'Cabrillas',
	},
	{
		CPRO: '37',
		CMUN: '069',
		DC: '0',
		NOMBRE: 'Calvarrasa de Abajo',
	},
	{
		CPRO: '37',
		CMUN: '070',
		DC: '4',
		NOMBRE: 'Calvarrasa de Arriba',
	},
	{
		CPRO: '37',
		CMUN: '071',
		DC: '1',
		NOMBRE: 'Calzada de Béjar, La',
	},
	{
		CPRO: '37',
		CMUN: '072',
		DC: '6',
		NOMBRE: 'Calzada de Don Diego',
	},
	{
		CPRO: '37',
		CMUN: '073',
		DC: '2',
		NOMBRE: 'Calzada de Valdunciel',
	},
	{
		CPRO: '37',
		CMUN: '074',
		DC: '7',
		NOMBRE: 'Campillo de Azaba',
	},
	{
		CPRO: '37',
		CMUN: '077',
		DC: '9',
		NOMBRE: 'Campo de Peñaranda, El',
	},
	{
		CPRO: '37',
		CMUN: '078',
		DC: '5',
		NOMBRE: 'Candelario',
	},
	{
		CPRO: '37',
		CMUN: '079',
		DC: '8',
		NOMBRE: 'Canillas de Abajo',
	},
	{
		CPRO: '37',
		CMUN: '080',
		DC: '2',
		NOMBRE: 'Cantagallo',
	},
	{
		CPRO: '37',
		CMUN: '081',
		DC: '9',
		NOMBRE: 'Cantalapiedra',
	},
	{
		CPRO: '37',
		CMUN: '082',
		DC: '4',
		NOMBRE: 'Cantalpino',
	},
	{
		CPRO: '37',
		CMUN: '083',
		DC: '0',
		NOMBRE: 'Cantaracillo',
	},
	{
		CPRO: '37',
		CMUN: '085',
		DC: '8',
		NOMBRE: 'Carbajosa de la Sagrada',
	},
	{
		CPRO: '37',
		CMUN: '086',
		DC: '1',
		NOMBRE: 'Carpio de Azaba',
	},
	{
		CPRO: '37',
		CMUN: '087',
		DC: '7',
		NOMBRE: 'Carrascal de Barregas',
	},
	{
		CPRO: '37',
		CMUN: '088',
		DC: '3',
		NOMBRE: 'Carrascal del Obispo',
	},
	{
		CPRO: '37',
		CMUN: '089',
		DC: '6',
		NOMBRE: 'Casafranca',
	},
	{
		CPRO: '37',
		CMUN: '090',
		DC: '0',
		NOMBRE: 'Casas del Conde, Las',
	},
	{
		CPRO: '37',
		CMUN: '091',
		DC: '7',
		NOMBRE: 'Casillas de Flores',
	},
	{
		CPRO: '37',
		CMUN: '092',
		DC: '2',
		NOMBRE: 'Castellanos de Moriscos',
	},
	{
		CPRO: '37',
		CMUN: '185',
		DC: '7',
		NOMBRE: 'Castellanos de Villiquera',
	},
	{
		CPRO: '37',
		CMUN: '096',
		DC: '9',
		NOMBRE: 'Castillejo de Martín Viejo',
	},
	{
		CPRO: '37',
		CMUN: '097',
		DC: '5',
		NOMBRE: 'Castraz',
	},
	{
		CPRO: '37',
		CMUN: '098',
		DC: '1',
		NOMBRE: 'Cepeda',
	},
	{
		CPRO: '37',
		CMUN: '099',
		DC: '4',
		NOMBRE: 'Cereceda de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '100',
		DC: '8',
		NOMBRE: 'Cerezal de Peñahorcada',
	},
	{
		CPRO: '37',
		CMUN: '101',
		DC: '5',
		NOMBRE: 'Cerralbo',
	},
	{
		CPRO: '37',
		CMUN: '102',
		DC: '0',
		NOMBRE: 'Cerro, El',
	},
	{
		CPRO: '37',
		CMUN: '103',
		DC: '6',
		NOMBRE: 'Cespedosa de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '114',
		DC: '9',
		NOMBRE: 'Chagarcía Medianero',
	},
	{
		CPRO: '37',
		CMUN: '104',
		DC: '1',
		NOMBRE: 'Cilleros de la Bastida',
	},
	{
		CPRO: '37',
		CMUN: '106',
		DC: '7',
		NOMBRE: 'Cipérez',
	},
	{
		CPRO: '37',
		CMUN: '107',
		DC: '3',
		NOMBRE: 'Ciudad Rodrigo',
	},
	{
		CPRO: '37',
		CMUN: '108',
		DC: '9',
		NOMBRE: 'Coca de Alba',
	},
	{
		CPRO: '37',
		CMUN: '109',
		DC: '2',
		NOMBRE: 'Colmenar de Montemayor',
	},
	{
		CPRO: '37',
		CMUN: '110',
		DC: '6',
		NOMBRE: 'Cordovilla',
	},
	{
		CPRO: '37',
		CMUN: '112',
		DC: '8',
		NOMBRE: 'Cristóbal',
	},
	{
		CPRO: '37',
		CMUN: '113',
		DC: '4',
		NOMBRE: 'Cubo de Don Sancho, El',
	},
	{
		CPRO: '37',
		CMUN: '115',
		DC: '2',
		NOMBRE: 'Dios le Guarde',
	},
	{
		CPRO: '37',
		CMUN: '116',
		DC: '5',
		NOMBRE: 'Doñinos de Ledesma',
	},
	{
		CPRO: '37',
		CMUN: '117',
		DC: '1',
		NOMBRE: 'Doñinos de Salamanca',
	},
	{
		CPRO: '37',
		CMUN: '118',
		DC: '7',
		NOMBRE: 'Ejeme',
	},
	{
		CPRO: '37',
		CMUN: '119',
		DC: '0',
		NOMBRE: 'Encina, La',
	},
	{
		CPRO: '37',
		CMUN: '120',
		DC: '4',
		NOMBRE: 'Encina de San Silvestre',
	},
	{
		CPRO: '37',
		CMUN: '121',
		DC: '1',
		NOMBRE: 'Encinas de Abajo',
	},
	{
		CPRO: '37',
		CMUN: '122',
		DC: '6',
		NOMBRE: 'Encinas de Arriba',
	},
	{
		CPRO: '37',
		CMUN: '123',
		DC: '2',
		NOMBRE: 'Encinasola de los Comendadores',
	},
	{
		CPRO: '37',
		CMUN: '124',
		DC: '7',
		NOMBRE: 'Endrinal',
	},
	{
		CPRO: '37',
		CMUN: '125',
		DC: '0',
		NOMBRE: 'Escurial de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '126',
		DC: '3',
		NOMBRE: 'Espadaña',
	},
	{
		CPRO: '37',
		CMUN: '127',
		DC: '9',
		NOMBRE: 'Espeja',
	},
	{
		CPRO: '37',
		CMUN: '128',
		DC: '5',
		NOMBRE: 'Espino de la Orbada',
	},
	{
		CPRO: '37',
		CMUN: '129',
		DC: '8',
		NOMBRE: 'Florida de Liébana',
	},
	{
		CPRO: '37',
		CMUN: '130',
		DC: '2',
		NOMBRE: 'Forfoleda',
	},
	{
		CPRO: '37',
		CMUN: '131',
		DC: '9',
		NOMBRE: 'Frades de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '132',
		DC: '4',
		NOMBRE: 'Fregeneda, La',
	},
	{
		CPRO: '37',
		CMUN: '133',
		DC: '0',
		NOMBRE: 'Fresnedoso',
	},
	{
		CPRO: '37',
		CMUN: '134',
		DC: '5',
		NOMBRE: 'Fresno Alhándiga',
	},
	{
		CPRO: '37',
		CMUN: '135',
		DC: '8',
		NOMBRE: 'Fuente de San Esteban, La',
	},
	{
		CPRO: '37',
		CMUN: '136',
		DC: '1',
		NOMBRE: 'Fuenteguinaldo',
	},
	{
		CPRO: '37',
		CMUN: '137',
		DC: '7',
		NOMBRE: 'Fuenteliante',
	},
	{
		CPRO: '37',
		CMUN: '138',
		DC: '3',
		NOMBRE: 'Fuenterroble de Salvatierra',
	},
	{
		CPRO: '37',
		CMUN: '139',
		DC: '6',
		NOMBRE: 'Fuentes de Béjar',
	},
	{
		CPRO: '37',
		CMUN: '140',
		DC: '0',
		NOMBRE: 'Fuentes de Oñoro',
	},
	{
		CPRO: '37',
		CMUN: '141',
		DC: '7',
		NOMBRE: 'Gajates',
	},
	{
		CPRO: '37',
		CMUN: '142',
		DC: '2',
		NOMBRE: 'Galindo y Perahuy',
	},
	{
		CPRO: '37',
		CMUN: '143',
		DC: '8',
		NOMBRE: 'Galinduste',
	},
	{
		CPRO: '37',
		CMUN: '144',
		DC: '3',
		NOMBRE: 'Galisancho',
	},
	{
		CPRO: '37',
		CMUN: '145',
		DC: '6',
		NOMBRE: 'Gallegos de Argañán',
	},
	{
		CPRO: '37',
		CMUN: '146',
		DC: '9',
		NOMBRE: 'Gallegos de Solmirón',
	},
	{
		CPRO: '37',
		CMUN: '147',
		DC: '5',
		NOMBRE: 'Garcibuey',
	},
	{
		CPRO: '37',
		CMUN: '148',
		DC: '1',
		NOMBRE: 'Garcihernández',
	},
	{
		CPRO: '37',
		CMUN: '149',
		DC: '4',
		NOMBRE: 'Garcirrey',
	},
	{
		CPRO: '37',
		CMUN: '150',
		DC: '7',
		NOMBRE: 'Gejuelo del Barro',
	},
	{
		CPRO: '37',
		CMUN: '151',
		DC: '4',
		NOMBRE: 'Golpejas',
	},
	{
		CPRO: '37',
		CMUN: '152',
		DC: '9',
		NOMBRE: 'Gomecello',
	},
	{
		CPRO: '37',
		CMUN: '154',
		DC: '0',
		NOMBRE: 'Guadramiro',
	},
	{
		CPRO: '37',
		CMUN: '155',
		DC: '3',
		NOMBRE: 'Guijo de Ávila',
	},
	{
		CPRO: '37',
		CMUN: '156',
		DC: '6',
		NOMBRE: 'Guijuelo',
	},
	{
		CPRO: '37',
		CMUN: '157',
		DC: '2',
		NOMBRE: 'Herguijuela de Ciudad Rodrigo',
	},
	{
		CPRO: '37',
		CMUN: '158',
		DC: '8',
		NOMBRE: 'Herguijuela de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '159',
		DC: '1',
		NOMBRE: 'Herguijuela del Campo',
	},
	{
		CPRO: '37',
		CMUN: '160',
		DC: '5',
		NOMBRE: 'Hinojosa de Duero',
	},
	{
		CPRO: '37',
		CMUN: '161',
		DC: '2',
		NOMBRE: 'Horcajo de Montemayor',
	},
	{
		CPRO: '37',
		CMUN: '162',
		DC: '7',
		NOMBRE: 'Horcajo Medianero',
	},
	{
		CPRO: '37',
		CMUN: '163',
		DC: '3',
		NOMBRE: 'Hoya, La',
	},
	{
		CPRO: '37',
		CMUN: '164',
		DC: '8',
		NOMBRE: 'Huerta',
	},
	{
		CPRO: '37',
		CMUN: '165',
		DC: '1',
		NOMBRE: 'Iruelos',
	},
	{
		CPRO: '37',
		CMUN: '166',
		DC: '4',
		NOMBRE: 'Ituero de Azaba',
	},
	{
		CPRO: '37',
		CMUN: '167',
		DC: '0',
		NOMBRE: 'Juzbado',
	},
	{
		CPRO: '37',
		CMUN: '168',
		DC: '6',
		NOMBRE: 'Lagunilla',
	},
	{
		CPRO: '37',
		CMUN: '169',
		DC: '9',
		NOMBRE: 'Larrodrigo',
	},
	{
		CPRO: '37',
		CMUN: '170',
		DC: '3',
		NOMBRE: 'Ledesma',
	},
	{
		CPRO: '37',
		CMUN: '171',
		DC: '0',
		NOMBRE: 'Ledrada',
	},
	{
		CPRO: '37',
		CMUN: '172',
		DC: '5',
		NOMBRE: 'Linares de Riofrío',
	},
	{
		CPRO: '37',
		CMUN: '173',
		DC: '1',
		NOMBRE: 'Lumbrales',
	},
	{
		CPRO: '37',
		CMUN: '175',
		DC: '9',
		NOMBRE: 'Machacón',
	},
	{
		CPRO: '37',
		CMUN: '174',
		DC: '6',
		NOMBRE: 'Macotera',
	},
	{
		CPRO: '37',
		CMUN: '176',
		DC: '2',
		NOMBRE: 'Madroñal',
	},
	{
		CPRO: '37',
		CMUN: '177',
		DC: '8',
		NOMBRE: 'Maíllo, El',
	},
	{
		CPRO: '37',
		CMUN: '178',
		DC: '4',
		NOMBRE: 'Malpartida',
	},
	{
		CPRO: '37',
		CMUN: '179',
		DC: '7',
		NOMBRE: 'Mancera de Abajo',
	},
	{
		CPRO: '37',
		CMUN: '180',
		DC: '1',
		NOMBRE: 'Manzano, El',
	},
	{
		CPRO: '37',
		CMUN: '181',
		DC: '8',
		NOMBRE: 'Martiago',
	},
	{
		CPRO: '37',
		CMUN: '183',
		DC: '9',
		NOMBRE: 'Martín de Yeltes',
	},
	{
		CPRO: '37',
		CMUN: '182',
		DC: '3',
		NOMBRE: 'Martinamor',
	},
	{
		CPRO: '37',
		CMUN: '184',
		DC: '4',
		NOMBRE: 'Masueco',
	},
	{
		CPRO: '37',
		CMUN: '186',
		DC: '0',
		NOMBRE: 'Mata de Ledesma, La',
	},
	{
		CPRO: '37',
		CMUN: '187',
		DC: '6',
		NOMBRE: 'Matilla de los Caños del Río',
	},
	{
		CPRO: '37',
		CMUN: '188',
		DC: '2',
		NOMBRE: 'Maya, La',
	},
	{
		CPRO: '37',
		CMUN: '189',
		DC: '5',
		NOMBRE: 'Membribe de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '190',
		DC: '9',
		NOMBRE: 'Mieza',
	},
	{
		CPRO: '37',
		CMUN: '191',
		DC: '6',
		NOMBRE: 'Milano, El',
	},
	{
		CPRO: '37',
		CMUN: '192',
		DC: '1',
		NOMBRE: 'Miranda de Azán',
	},
	{
		CPRO: '37',
		CMUN: '193',
		DC: '7',
		NOMBRE: 'Miranda del Castañar',
	},
	{
		CPRO: '37',
		CMUN: '194',
		DC: '2',
		NOMBRE: 'Mogarraz',
	},
	{
		CPRO: '37',
		CMUN: '195',
		DC: '5',
		NOMBRE: 'Molinillo',
	},
	{
		CPRO: '37',
		CMUN: '196',
		DC: '8',
		NOMBRE: 'Monforte de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '197',
		DC: '4',
		NOMBRE: 'Monleón',
	},
	{
		CPRO: '37',
		CMUN: '198',
		DC: '0',
		NOMBRE: 'Monleras',
	},
	{
		CPRO: '37',
		CMUN: '199',
		DC: '3',
		NOMBRE: 'Monsagro',
	},
	{
		CPRO: '37',
		CMUN: '200',
		DC: '7',
		NOMBRE: 'Montejo',
	},
	{
		CPRO: '37',
		CMUN: '201',
		DC: '4',
		NOMBRE: 'Montemayor del Río',
	},
	{
		CPRO: '37',
		CMUN: '202',
		DC: '9',
		NOMBRE: 'Monterrubio de Armuña',
	},
	{
		CPRO: '37',
		CMUN: '203',
		DC: '5',
		NOMBRE: 'Monterrubio de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '204',
		DC: '0',
		NOMBRE: 'Morasverdes',
	},
	{
		CPRO: '37',
		CMUN: '205',
		DC: '3',
		NOMBRE: 'Morille',
	},
	{
		CPRO: '37',
		CMUN: '206',
		DC: '6',
		NOMBRE: 'Moríñigo',
	},
	{
		CPRO: '37',
		CMUN: '207',
		DC: '2',
		NOMBRE: 'Moriscos',
	},
	{
		CPRO: '37',
		CMUN: '208',
		DC: '8',
		NOMBRE: 'Moronta',
	},
	{
		CPRO: '37',
		CMUN: '209',
		DC: '1',
		NOMBRE: 'Mozárbez',
	},
	{
		CPRO: '37',
		CMUN: '211',
		DC: '2',
		NOMBRE: 'Narros de Matalayegua',
	},
	{
		CPRO: '37',
		CMUN: '213',
		DC: '3',
		NOMBRE: 'Nava de Béjar',
	},
	{
		CPRO: '37',
		CMUN: '214',
		DC: '8',
		NOMBRE: 'Nava de Francia',
	},
	{
		CPRO: '37',
		CMUN: '215',
		DC: '1',
		NOMBRE: 'Nava de Sotrobal',
	},
	{
		CPRO: '37',
		CMUN: '212',
		DC: '7',
		NOMBRE: 'Navacarros',
	},
	{
		CPRO: '37',
		CMUN: '216',
		DC: '4',
		NOMBRE: 'Navales',
	},
	{
		CPRO: '37',
		CMUN: '217',
		DC: '0',
		NOMBRE: 'Navalmoral de Béjar',
	},
	{
		CPRO: '37',
		CMUN: '218',
		DC: '6',
		NOMBRE: 'Navamorales',
	},
	{
		CPRO: '37',
		CMUN: '219',
		DC: '9',
		NOMBRE: 'Navarredonda de la Rinconada',
	},
	{
		CPRO: '37',
		CMUN: '221',
		DC: '0',
		NOMBRE: 'Navasfrías',
	},
	{
		CPRO: '37',
		CMUN: '222',
		DC: '5',
		NOMBRE: 'Negrilla de Palencia',
	},
	{
		CPRO: '37',
		CMUN: '223',
		DC: '1',
		NOMBRE: 'Olmedo de Camaces',
	},
	{
		CPRO: '37',
		CMUN: '224',
		DC: '6',
		NOMBRE: 'Orbada, La',
	},
	{
		CPRO: '37',
		CMUN: '225',
		DC: '9',
		NOMBRE: 'Pajares de la Laguna',
	},
	{
		CPRO: '37',
		CMUN: '226',
		DC: '2',
		NOMBRE: 'Palacios del Arzobispo',
	},
	{
		CPRO: '37',
		CMUN: '228',
		DC: '4',
		NOMBRE: 'Palaciosrubios',
	},
	{
		CPRO: '37',
		CMUN: '229',
		DC: '7',
		NOMBRE: 'Palencia de Negrilla',
	},
	{
		CPRO: '37',
		CMUN: '230',
		DC: '1',
		NOMBRE: 'Parada de Arriba',
	},
	{
		CPRO: '37',
		CMUN: '231',
		DC: '8',
		NOMBRE: 'Parada de Rubiales',
	},
	{
		CPRO: '37',
		CMUN: '232',
		DC: '3',
		NOMBRE: 'Paradinas de San Juan',
	},
	{
		CPRO: '37',
		CMUN: '233',
		DC: '9',
		NOMBRE: 'Pastores',
	},
	{
		CPRO: '37',
		CMUN: '234',
		DC: '4',
		NOMBRE: 'Payo, El',
	},
	{
		CPRO: '37',
		CMUN: '235',
		DC: '7',
		NOMBRE: 'Pedraza de Alba',
	},
	{
		CPRO: '37',
		CMUN: '236',
		DC: '0',
		NOMBRE: 'Pedrosillo de Alba',
	},
	{
		CPRO: '37',
		CMUN: '237',
		DC: '6',
		NOMBRE: 'Pedrosillo de los Aires',
	},
	{
		CPRO: '37',
		CMUN: '238',
		DC: '2',
		NOMBRE: 'Pedrosillo el Ralo',
	},
	{
		CPRO: '37',
		CMUN: '239',
		DC: '5',
		NOMBRE: 'Pedroso de la Armuña, El',
	},
	{
		CPRO: '37',
		CMUN: '240',
		DC: '9',
		NOMBRE: 'Pelabravo',
	},
	{
		CPRO: '37',
		CMUN: '241',
		DC: '6',
		NOMBRE: 'Pelarrodríguez',
	},
	{
		CPRO: '37',
		CMUN: '242',
		DC: '1',
		NOMBRE: 'Pelayos',
	},
	{
		CPRO: '37',
		CMUN: '243',
		DC: '7',
		NOMBRE: 'Peña, La',
	},
	{
		CPRO: '37',
		CMUN: '244',
		DC: '2',
		NOMBRE: 'Peñacaballera',
	},
	{
		CPRO: '37',
		CMUN: '245',
		DC: '5',
		NOMBRE: 'Peñaparda',
	},
	{
		CPRO: '37',
		CMUN: '246',
		DC: '8',
		NOMBRE: 'Peñaranda de Bracamonte',
	},
	{
		CPRO: '37',
		CMUN: '247',
		DC: '4',
		NOMBRE: 'Peñarandilla',
	},
	{
		CPRO: '37',
		CMUN: '248',
		DC: '0',
		NOMBRE: 'Peralejos de Abajo',
	},
	{
		CPRO: '37',
		CMUN: '249',
		DC: '3',
		NOMBRE: 'Peralejos de Arriba',
	},
	{
		CPRO: '37',
		CMUN: '250',
		DC: '6',
		NOMBRE: 'Pereña de la Ribera',
	},
	{
		CPRO: '37',
		CMUN: '251',
		DC: '3',
		NOMBRE: 'Peromingo',
	},
	{
		CPRO: '37',
		CMUN: '252',
		DC: '8',
		NOMBRE: 'Pinedas',
	},
	{
		CPRO: '37',
		CMUN: '253',
		DC: '4',
		NOMBRE: 'Pino de Tormes, El',
	},
	{
		CPRO: '37',
		CMUN: '254',
		DC: '9',
		NOMBRE: 'Pitiegua',
	},
	{
		CPRO: '37',
		CMUN: '255',
		DC: '2',
		NOMBRE: 'Pizarral',
	},
	{
		CPRO: '37',
		CMUN: '256',
		DC: '5',
		NOMBRE: 'Poveda de las Cintas',
	},
	{
		CPRO: '37',
		CMUN: '257',
		DC: '1',
		NOMBRE: 'Pozos de Hinojo',
	},
	{
		CPRO: '37',
		CMUN: '258',
		DC: '7',
		NOMBRE: 'Puebla de Azaba',
	},
	{
		CPRO: '37',
		CMUN: '259',
		DC: '0',
		NOMBRE: 'Puebla de San Medel',
	},
	{
		CPRO: '37',
		CMUN: '260',
		DC: '4',
		NOMBRE: 'Puebla de Yeltes',
	},
	{
		CPRO: '37',
		CMUN: '261',
		DC: '1',
		NOMBRE: 'Puente del Congosto',
	},
	{
		CPRO: '37',
		CMUN: '262',
		DC: '6',
		NOMBRE: 'Puertas',
	},
	{
		CPRO: '37',
		CMUN: '263',
		DC: '2',
		NOMBRE: 'Puerto de Béjar',
	},
	{
		CPRO: '37',
		CMUN: '264',
		DC: '7',
		NOMBRE: 'Puerto Seguro',
	},
	{
		CPRO: '37',
		CMUN: '265',
		DC: '0',
		NOMBRE: 'Rágama',
	},
	{
		CPRO: '37',
		CMUN: '266',
		DC: '3',
		NOMBRE: 'Redonda, La',
	},
	{
		CPRO: '37',
		CMUN: '267',
		DC: '9',
		NOMBRE: 'Retortillo',
	},
	{
		CPRO: '37',
		CMUN: '268',
		DC: '5',
		NOMBRE: 'Rinconada de la Sierra, La',
	},
	{
		CPRO: '37',
		CMUN: '269',
		DC: '8',
		NOMBRE: 'Robleda',
	},
	{
		CPRO: '37',
		CMUN: '270',
		DC: '2',
		NOMBRE: 'Robliza de Cojos',
	},
	{
		CPRO: '37',
		CMUN: '271',
		DC: '9',
		NOMBRE: 'Rollán',
	},
	{
		CPRO: '37',
		CMUN: '272',
		DC: '4',
		NOMBRE: 'Saelices el Chico',
	},
	{
		CPRO: '37',
		CMUN: '273',
		DC: '0',
		NOMBRE: 'Sagrada, La',
	},
	{
		CPRO: '37',
		CMUN: '303',
		DC: '4',
		NOMBRE: 'Sahugo, El',
	},
	{
		CPRO: '37',
		CMUN: '274',
		DC: '5',
		NOMBRE: 'Salamanca',
	},
	{
		CPRO: '37',
		CMUN: '275',
		DC: '8',
		NOMBRE: 'Saldeana',
	},
	{
		CPRO: '37',
		CMUN: '276',
		DC: '1',
		NOMBRE: 'Salmoral',
	},
	{
		CPRO: '37',
		CMUN: '277',
		DC: '7',
		NOMBRE: 'Salvatierra de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '278',
		DC: '3',
		NOMBRE: 'San Cristóbal de la Cuesta',
	},
	{
		CPRO: '37',
		CMUN: '284',
		DC: '3',
		NOMBRE: 'San Esteban de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '285',
		DC: '6',
		NOMBRE: 'San Felices de los Gallegos',
	},
	{
		CPRO: '37',
		CMUN: '286',
		DC: '9',
		NOMBRE: 'San Martín del Castañar',
	},
	{
		CPRO: '37',
		CMUN: '287',
		DC: '5',
		NOMBRE: 'San Miguel de Valero',
	},
	{
		CPRO: '37',
		CMUN: '036',
		DC: '2',
		NOMBRE: 'San Miguel del Robledo',
	},
	{
		CPRO: '37',
		CMUN: '288',
		DC: '1',
		NOMBRE: 'San Morales',
	},
	{
		CPRO: '37',
		CMUN: '289',
		DC: '4',
		NOMBRE: 'San Muñoz',
	},
	{
		CPRO: '37',
		CMUN: '291',
		DC: '5',
		NOMBRE: 'San Pedro de Rozados',
	},
	{
		CPRO: '37',
		CMUN: '290',
		DC: '8',
		NOMBRE: 'San Pedro del Valle',
	},
	{
		CPRO: '37',
		CMUN: '292',
		DC: '0',
		NOMBRE: 'San Pelayo de Guareña',
	},
	{
		CPRO: '37',
		CMUN: '280',
		DC: '0',
		NOMBRE: 'Sanchón de la Ribera',
	},
	{
		CPRO: '37',
		CMUN: '281',
		DC: '7',
		NOMBRE: 'Sanchón de la Sagrada',
	},
	{
		CPRO: '37',
		CMUN: '282',
		DC: '2',
		NOMBRE: 'Sanchotello',
	},
	{
		CPRO: '37',
		CMUN: '279',
		DC: '6',
		NOMBRE: 'Sancti-Spíritus',
	},
	{
		CPRO: '37',
		CMUN: '283',
		DC: '8',
		NOMBRE: 'Sando',
	},
	{
		CPRO: '37',
		CMUN: '293',
		DC: '6',
		NOMBRE: 'Santa María de Sando',
	},
	{
		CPRO: '37',
		CMUN: '294',
		DC: '1',
		NOMBRE: 'Santa Marta de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '296',
		DC: '7',
		NOMBRE: 'Santiago de la Puebla',
	},
	{
		CPRO: '37',
		CMUN: '297',
		DC: '3',
		NOMBRE: 'Santibáñez de Béjar',
	},
	{
		CPRO: '37',
		CMUN: '298',
		DC: '9',
		NOMBRE: 'Santibáñez de la Sierra',
	},
	{
		CPRO: '37',
		CMUN: '299',
		DC: '2',
		NOMBRE: 'Santiz',
	},
	{
		CPRO: '37',
		CMUN: '300',
		DC: '6',
		NOMBRE: 'Santos, Los',
	},
	{
		CPRO: '37',
		CMUN: '301',
		DC: '3',
		NOMBRE: 'Sardón de los Frailes',
	},
	{
		CPRO: '37',
		CMUN: '302',
		DC: '8',
		NOMBRE: 'Saucelle',
	},
	{
		CPRO: '37',
		CMUN: '304',
		DC: '9',
		NOMBRE: 'Sepulcro-Hilario',
	},
	{
		CPRO: '37',
		CMUN: '305',
		DC: '2',
		NOMBRE: 'Sequeros',
	},
	{
		CPRO: '37',
		CMUN: '306',
		DC: '5',
		NOMBRE: 'Serradilla del Arroyo',
	},
	{
		CPRO: '37',
		CMUN: '307',
		DC: '1',
		NOMBRE: 'Serradilla del Llano',
	},
	{
		CPRO: '37',
		CMUN: '309',
		DC: '0',
		NOMBRE: 'Sierpe, La',
	},
	{
		CPRO: '37',
		CMUN: '310',
		DC: '4',
		NOMBRE: 'Sieteiglesias de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '311',
		DC: '1',
		NOMBRE: 'Sobradillo',
	},
	{
		CPRO: '37',
		CMUN: '312',
		DC: '6',
		NOMBRE: 'Sorihuela',
	},
	{
		CPRO: '37',
		CMUN: '313',
		DC: '2',
		NOMBRE: 'Sotoserrano',
	},
	{
		CPRO: '37',
		CMUN: '314',
		DC: '7',
		NOMBRE: 'Tabera de Abajo',
	},
	{
		CPRO: '37',
		CMUN: '315',
		DC: '0',
		NOMBRE: 'Tala, La',
	},
	{
		CPRO: '37',
		CMUN: '316',
		DC: '3',
		NOMBRE: 'Tamames',
	},
	{
		CPRO: '37',
		CMUN: '317',
		DC: '9',
		NOMBRE: 'Tarazona de Guareña',
	},
	{
		CPRO: '37',
		CMUN: '318',
		DC: '5',
		NOMBRE: 'Tardáguila',
	},
	{
		CPRO: '37',
		CMUN: '319',
		DC: '8',
		NOMBRE: 'Tejado, El',
	},
	{
		CPRO: '37',
		CMUN: '320',
		DC: '2',
		NOMBRE: 'Tejeda y Segoyuela',
	},
	{
		CPRO: '37',
		CMUN: '321',
		DC: '9',
		NOMBRE: 'Tenebrón',
	},
	{
		CPRO: '37',
		CMUN: '322',
		DC: '4',
		NOMBRE: 'Terradillos',
	},
	{
		CPRO: '37',
		CMUN: '323',
		DC: '0',
		NOMBRE: 'Topas',
	},
	{
		CPRO: '37',
		CMUN: '324',
		DC: '5',
		NOMBRE: 'Tordillos',
	},
	{
		CPRO: '37',
		CMUN: '325',
		DC: '8',
		NOMBRE: 'Tornadizo, El',
	},
	{
		CPRO: '37',
		CMUN: '327',
		DC: '7',
		NOMBRE: 'Torresmenudas',
	},
	{
		CPRO: '37',
		CMUN: '328',
		DC: '3',
		NOMBRE: 'Trabanca',
	},
	{
		CPRO: '37',
		CMUN: '329',
		DC: '6',
		NOMBRE: 'Tremedal de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '330',
		DC: '0',
		NOMBRE: 'Valdecarros',
	},
	{
		CPRO: '37',
		CMUN: '331',
		DC: '7',
		NOMBRE: 'Valdefuentes de Sangusín',
	},
	{
		CPRO: '37',
		CMUN: '332',
		DC: '2',
		NOMBRE: 'Valdehijaderos',
	},
	{
		CPRO: '37',
		CMUN: '333',
		DC: '8',
		NOMBRE: 'Valdelacasa',
	},
	{
		CPRO: '37',
		CMUN: '334',
		DC: '3',
		NOMBRE: 'Valdelageve',
	},
	{
		CPRO: '37',
		CMUN: '335',
		DC: '6',
		NOMBRE: 'Valdelosa',
	},
	{
		CPRO: '37',
		CMUN: '336',
		DC: '9',
		NOMBRE: 'Valdemierque',
	},
	{
		CPRO: '37',
		CMUN: '337',
		DC: '5',
		NOMBRE: 'Valderrodrigo',
	},
	{
		CPRO: '37',
		CMUN: '338',
		DC: '1',
		NOMBRE: 'Valdunciel',
	},
	{
		CPRO: '37',
		CMUN: '339',
		DC: '4',
		NOMBRE: 'Valero',
	},
	{
		CPRO: '37',
		CMUN: '343',
		DC: '6',
		NOMBRE: 'Vallejera de Riofrío',
	},
	{
		CPRO: '37',
		CMUN: '340',
		DC: '8',
		NOMBRE: 'Valsalabroso',
	},
	{
		CPRO: '37',
		CMUN: '341',
		DC: '5',
		NOMBRE: 'Valverde de Valdelacasa',
	},
	{
		CPRO: '37',
		CMUN: '342',
		DC: '0',
		NOMBRE: 'Valverdón',
	},
	{
		CPRO: '37',
		CMUN: '344',
		DC: '1',
		NOMBRE: 'Vecinos',
	},
	{
		CPRO: '37',
		CMUN: '345',
		DC: '4',
		NOMBRE: 'Vega de Tirados',
	},
	{
		CPRO: '37',
		CMUN: '346',
		DC: '7',
		NOMBRE: 'Veguillas, Las',
	},
	{
		CPRO: '37',
		CMUN: '347',
		DC: '3',
		NOMBRE: 'Vellés, La',
	},
	{
		CPRO: '37',
		CMUN: '348',
		DC: '9',
		NOMBRE: 'Ventosa del Río Almar',
	},
	{
		CPRO: '37',
		CMUN: '349',
		DC: '2',
		NOMBRE: 'Vídola, La',
	},
	{
		CPRO: '37',
		CMUN: '351',
		DC: '2',
		NOMBRE: 'Villaflores',
	},
	{
		CPRO: '37',
		CMUN: '352',
		DC: '7',
		NOMBRE: 'Villagonzalo de Tormes',
	},
	{
		CPRO: '37',
		CMUN: '353',
		DC: '3',
		NOMBRE: 'Villalba de los Llanos',
	},
	{
		CPRO: '37',
		CMUN: '354',
		DC: '8',
		NOMBRE: 'Villamayor',
	},
	{
		CPRO: '37',
		CMUN: '355',
		DC: '1',
		NOMBRE: 'Villanueva del Conde',
	},
	{
		CPRO: '37',
		CMUN: '356',
		DC: '4',
		NOMBRE: 'Villar de Argañán',
	},
	{
		CPRO: '37',
		CMUN: '357',
		DC: '0',
		NOMBRE: 'Villar de Ciervo',
	},
	{
		CPRO: '37',
		CMUN: '358',
		DC: '6',
		NOMBRE: 'Villar de Gallimazo',
	},
	{
		CPRO: '37',
		CMUN: '359',
		DC: '9',
		NOMBRE: 'Villar de la Yegua',
	},
	{
		CPRO: '37',
		CMUN: '360',
		DC: '3',
		NOMBRE: 'Villar de Peralonso',
	},
	{
		CPRO: '37',
		CMUN: '361',
		DC: '0',
		NOMBRE: 'Villar de Samaniego',
	},
	{
		CPRO: '37',
		CMUN: '362',
		DC: '5',
		NOMBRE: 'Villares de la Reina',
	},
	{
		CPRO: '37',
		CMUN: '363',
		DC: '1',
		NOMBRE: 'Villares de Yeltes',
	},
	{
		CPRO: '37',
		CMUN: '364',
		DC: '6',
		NOMBRE: 'Villarino de los Aires',
	},
	{
		CPRO: '37',
		CMUN: '365',
		DC: '9',
		NOMBRE: 'Villarmayor',
	},
	{
		CPRO: '37',
		CMUN: '366',
		DC: '2',
		NOMBRE: 'Villarmuerto',
	},
	{
		CPRO: '37',
		CMUN: '367',
		DC: '8',
		NOMBRE: 'Villasbuenas',
	},
	{
		CPRO: '37',
		CMUN: '368',
		DC: '4',
		NOMBRE: 'Villasdardo',
	},
	{
		CPRO: '37',
		CMUN: '369',
		DC: '7',
		NOMBRE: 'Villaseco de los Gamitos',
	},
	{
		CPRO: '37',
		CMUN: '370',
		DC: '1',
		NOMBRE: 'Villaseco de los Reyes',
	},
	{
		CPRO: '37',
		CMUN: '371',
		DC: '8',
		NOMBRE: 'Villasrubias',
	},
	{
		CPRO: '37',
		CMUN: '372',
		DC: '3',
		NOMBRE: 'Villaverde de Guareña',
	},
	{
		CPRO: '37',
		CMUN: '373',
		DC: '9',
		NOMBRE: 'Villavieja de Yeltes',
	},
	{
		CPRO: '37',
		CMUN: '374',
		DC: '4',
		NOMBRE: 'Villoria',
	},
	{
		CPRO: '37',
		CMUN: '375',
		DC: '7',
		NOMBRE: 'Villoruela',
	},
	{
		CPRO: '37',
		CMUN: '350',
		DC: '5',
		NOMBRE: 'Vilvestre',
	},
	{
		CPRO: '37',
		CMUN: '376',
		DC: '0',
		NOMBRE: 'Vitigudino',
	},
	{
		CPRO: '37',
		CMUN: '377',
		DC: '6',
		NOMBRE: 'Yecla de Yeltes',
	},
	{
		CPRO: '37',
		CMUN: '378',
		DC: '2',
		NOMBRE: 'Zamarra',
	},
	{
		CPRO: '37',
		CMUN: '379',
		DC: '5',
		NOMBRE: 'Zamayón',
	},
	{
		CPRO: '37',
		CMUN: '380',
		DC: '9',
		NOMBRE: 'Zarapicos',
	},
	{
		CPRO: '37',
		CMUN: '381',
		DC: '6',
		NOMBRE: 'Zarza de Pumareda, La',
	},
	{
		CPRO: '37',
		CMUN: '382',
		DC: '1',
		NOMBRE: 'Zorita de la Frontera',
	},
	{
		CPRO: '38',
		CMUN: '001',
		DC: '2',
		NOMBRE: 'Adeje',
	},
	{
		CPRO: '38',
		CMUN: '002',
		DC: '7',
		NOMBRE: 'Agulo',
	},
	{
		CPRO: '38',
		CMUN: '003',
		DC: '3',
		NOMBRE: 'Alajeró',
	},
	{
		CPRO: '38',
		CMUN: '004',
		DC: '8',
		NOMBRE: 'Arafo',
	},
	{
		CPRO: '38',
		CMUN: '005',
		DC: '1',
		NOMBRE: 'Arico',
	},
	{
		CPRO: '38',
		CMUN: '006',
		DC: '4',
		NOMBRE: 'Arona',
	},
	{
		CPRO: '38',
		CMUN: '007',
		DC: '0',
		NOMBRE: 'Barlovento',
	},
	{
		CPRO: '38',
		CMUN: '008',
		DC: '6',
		NOMBRE: 'Breña Alta',
	},
	{
		CPRO: '38',
		CMUN: '009',
		DC: '9',
		NOMBRE: 'Breña Baja',
	},
	{
		CPRO: '38',
		CMUN: '010',
		DC: '3',
		NOMBRE: 'Buenavista del Norte',
	},
	{
		CPRO: '38',
		CMUN: '011',
		DC: '0',
		NOMBRE: 'Candelaria',
	},
	{
		CPRO: '38',
		CMUN: '012',
		DC: '5',
		NOMBRE: 'Fasnia',
	},
	{
		CPRO: '38',
		CMUN: '013',
		DC: '1',
		NOMBRE: 'Frontera',
	},
	{
		CPRO: '38',
		CMUN: '014',
		DC: '6',
		NOMBRE: 'Fuencaliente de la Palma',
	},
	{
		CPRO: '38',
		CMUN: '015',
		DC: '9',
		NOMBRE: 'Garachico',
	},
	{
		CPRO: '38',
		CMUN: '016',
		DC: '2',
		NOMBRE: 'Garafía',
	},
	{
		CPRO: '38',
		CMUN: '017',
		DC: '8',
		NOMBRE: 'Granadilla de Abona',
	},
	{
		CPRO: '38',
		CMUN: '018',
		DC: '4',
		NOMBRE: 'Guancha, La',
	},
	{
		CPRO: '38',
		CMUN: '019',
		DC: '7',
		NOMBRE: 'Guía de Isora',
	},
	{
		CPRO: '38',
		CMUN: '020',
		DC: '1',
		NOMBRE: 'Güímar',
	},
	{
		CPRO: '38',
		CMUN: '021',
		DC: '8',
		NOMBRE: 'Hermigua',
	},
	{
		CPRO: '38',
		CMUN: '022',
		DC: '3',
		NOMBRE: 'Icod de los Vinos',
	},
	{
		CPRO: '38',
		CMUN: '024',
		DC: '4',
		NOMBRE: 'Llanos de Aridane, Los',
	},
	{
		CPRO: '38',
		CMUN: '025',
		DC: '7',
		NOMBRE: 'Matanza de Acentejo, La',
	},
	{
		CPRO: '38',
		CMUN: '026',
		DC: '0',
		NOMBRE: 'Orotava, La',
	},
	{
		CPRO: '38',
		CMUN: '027',
		DC: '6',
		NOMBRE: 'Paso, El',
	},
	{
		CPRO: '38',
		CMUN: '901',
		DC: '3',
		NOMBRE: 'Pinar de El Hierro, El',
	},
	{
		CPRO: '38',
		CMUN: '028',
		DC: '2',
		NOMBRE: 'Puerto de la Cruz',
	},
	{
		CPRO: '38',
		CMUN: '029',
		DC: '5',
		NOMBRE: 'Puntagorda',
	},
	{
		CPRO: '38',
		CMUN: '030',
		DC: '9',
		NOMBRE: 'Puntallana',
	},
	{
		CPRO: '38',
		CMUN: '031',
		DC: '6',
		NOMBRE: 'Realejos, Los',
	},
	{
		CPRO: '38',
		CMUN: '032',
		DC: '1',
		NOMBRE: 'Rosario, El',
	},
	{
		CPRO: '38',
		CMUN: '033',
		DC: '7',
		NOMBRE: 'San Andrés y Sauces',
	},
	{
		CPRO: '38',
		CMUN: '023',
		DC: '9',
		NOMBRE: 'San Cristóbal de La Laguna',
	},
	{
		CPRO: '38',
		CMUN: '034',
		DC: '2',
		NOMBRE: 'San Juan de la Rambla',
	},
	{
		CPRO: '38',
		CMUN: '035',
		DC: '5',
		NOMBRE: 'San Miguel de Abona',
	},
	{
		CPRO: '38',
		CMUN: '036',
		DC: '8',
		NOMBRE: 'San Sebastián de la Gomera',
	},
	{
		CPRO: '38',
		CMUN: '037',
		DC: '4',
		NOMBRE: 'Santa Cruz de la Palma',
	},
	{
		CPRO: '38',
		CMUN: '038',
		DC: '0',
		NOMBRE: 'Santa Cruz de Tenerife',
	},
	{
		CPRO: '38',
		CMUN: '039',
		DC: '3',
		NOMBRE: 'Santa Úrsula',
	},
	{
		CPRO: '38',
		CMUN: '040',
		DC: '7',
		NOMBRE: 'Santiago del Teide',
	},
	{
		CPRO: '38',
		CMUN: '041',
		DC: '4',
		NOMBRE: 'Sauzal, El',
	},
	{
		CPRO: '38',
		CMUN: '042',
		DC: '9',
		NOMBRE: 'Silos, Los',
	},
	{
		CPRO: '38',
		CMUN: '043',
		DC: '5',
		NOMBRE: 'Tacoronte',
	},
	{
		CPRO: '38',
		CMUN: '044',
		DC: '0',
		NOMBRE: 'Tanque, El',
	},
	{
		CPRO: '38',
		CMUN: '045',
		DC: '3',
		NOMBRE: 'Tazacorte',
	},
	{
		CPRO: '38',
		CMUN: '046',
		DC: '6',
		NOMBRE: 'Tegueste',
	},
	{
		CPRO: '38',
		CMUN: '047',
		DC: '2',
		NOMBRE: 'Tijarafe',
	},
	{
		CPRO: '38',
		CMUN: '049',
		DC: '1',
		NOMBRE: 'Valle Gran Rey',
	},
	{
		CPRO: '38',
		CMUN: '050',
		DC: '4',
		NOMBRE: 'Vallehermoso',
	},
	{
		CPRO: '38',
		CMUN: '048',
		DC: '8',
		NOMBRE: 'Valverde',
	},
	{
		CPRO: '38',
		CMUN: '051',
		DC: '1',
		NOMBRE: 'Victoria de Acentejo, La',
	},
	{
		CPRO: '38',
		CMUN: '052',
		DC: '6',
		NOMBRE: 'Vilaflor',
	},
	{
		CPRO: '38',
		CMUN: '053',
		DC: '2',
		NOMBRE: 'Villa de Mazo',
	},
	{
		CPRO: '39',
		CMUN: '001',
		DC: '5',
		NOMBRE: 'Alfoz de Lloredo',
	},
	{
		CPRO: '39',
		CMUN: '002',
		DC: '0',
		NOMBRE: 'Ampuero',
	},
	{
		CPRO: '39',
		CMUN: '003',
		DC: '6',
		NOMBRE: 'Anievas',
	},
	{
		CPRO: '39',
		CMUN: '004',
		DC: '1',
		NOMBRE: 'Arenas de Iguña',
	},
	{
		CPRO: '39',
		CMUN: '005',
		DC: '4',
		NOMBRE: 'Argoños',
	},
	{
		CPRO: '39',
		CMUN: '006',
		DC: '7',
		NOMBRE: 'Arnuero',
	},
	{
		CPRO: '39',
		CMUN: '007',
		DC: '3',
		NOMBRE: 'Arredondo',
	},
	{
		CPRO: '39',
		CMUN: '008',
		DC: '9',
		NOMBRE: 'Astillero, El',
	},
	{
		CPRO: '39',
		CMUN: '009',
		DC: '2',
		NOMBRE: 'Bárcena de Cicero',
	},
	{
		CPRO: '39',
		CMUN: '010',
		DC: '6',
		NOMBRE: 'Bárcena de Pie de Concha',
	},
	{
		CPRO: '39',
		CMUN: '011',
		DC: '3',
		NOMBRE: 'Bareyo',
	},
	{
		CPRO: '39',
		CMUN: '012',
		DC: '8',
		NOMBRE: 'Cabezón de la Sal',
	},
	{
		CPRO: '39',
		CMUN: '013',
		DC: '4',
		NOMBRE: 'Cabezón de Liébana',
	},
	{
		CPRO: '39',
		CMUN: '014',
		DC: '9',
		NOMBRE: 'Cabuérniga',
	},
	{
		CPRO: '39',
		CMUN: '015',
		DC: '2',
		NOMBRE: 'Camaleño',
	},
	{
		CPRO: '39',
		CMUN: '016',
		DC: '5',
		NOMBRE: 'Camargo',
	},
	{
		CPRO: '39',
		CMUN: '027',
		DC: '9',
		NOMBRE: 'Campoo de Enmedio',
	},
	{
		CPRO: '39',
		CMUN: '017',
		DC: '1',
		NOMBRE: 'Campoo de Yuso',
	},
	{
		CPRO: '39',
		CMUN: '018',
		DC: '7',
		NOMBRE: 'Cartes',
	},
	{
		CPRO: '39',
		CMUN: '019',
		DC: '0',
		NOMBRE: 'Castañeda',
	},
	{
		CPRO: '39',
		CMUN: '020',
		DC: '4',
		NOMBRE: 'Castro-Urdiales',
	},
	{
		CPRO: '39',
		CMUN: '021',
		DC: '1',
		NOMBRE: 'Cieza',
	},
	{
		CPRO: '39',
		CMUN: '022',
		DC: '6',
		NOMBRE: 'Cillorigo de Liébana',
	},
	{
		CPRO: '39',
		CMUN: '023',
		DC: '2',
		NOMBRE: 'Colindres',
	},
	{
		CPRO: '39',
		CMUN: '024',
		DC: '7',
		NOMBRE: 'Comillas',
	},
	{
		CPRO: '39',
		CMUN: '025',
		DC: '0',
		NOMBRE: 'Corrales de Buelna, Los',
	},
	{
		CPRO: '39',
		CMUN: '026',
		DC: '3',
		NOMBRE: 'Corvera de Toranzo',
	},
	{
		CPRO: '39',
		CMUN: '028',
		DC: '5',
		NOMBRE: 'Entrambasaguas',
	},
	{
		CPRO: '39',
		CMUN: '029',
		DC: '8',
		NOMBRE: 'Escalante',
	},
	{
		CPRO: '39',
		CMUN: '030',
		DC: '2',
		NOMBRE: 'Guriezo',
	},
	{
		CPRO: '39',
		CMUN: '031',
		DC: '9',
		NOMBRE: 'Hazas de Cesto',
	},
	{
		CPRO: '39',
		CMUN: '032',
		DC: '4',
		NOMBRE: 'Hermandad de Campoo de Suso',
	},
	{
		CPRO: '39',
		CMUN: '033',
		DC: '0',
		NOMBRE: 'Herrerías',
	},
	{
		CPRO: '39',
		CMUN: '034',
		DC: '5',
		NOMBRE: 'Lamasón',
	},
	{
		CPRO: '39',
		CMUN: '035',
		DC: '8',
		NOMBRE: 'Laredo',
	},
	{
		CPRO: '39',
		CMUN: '036',
		DC: '1',
		NOMBRE: 'Liendo',
	},
	{
		CPRO: '39',
		CMUN: '037',
		DC: '7',
		NOMBRE: 'Liérganes',
	},
	{
		CPRO: '39',
		CMUN: '038',
		DC: '3',
		NOMBRE: 'Limpias',
	},
	{
		CPRO: '39',
		CMUN: '039',
		DC: '6',
		NOMBRE: 'Luena',
	},
	{
		CPRO: '39',
		CMUN: '040',
		DC: '0',
		NOMBRE: 'Marina de Cudeyo',
	},
	{
		CPRO: '39',
		CMUN: '041',
		DC: '7',
		NOMBRE: 'Mazcuerras',
	},
	{
		CPRO: '39',
		CMUN: '042',
		DC: '2',
		NOMBRE: 'Medio Cudeyo',
	},
	{
		CPRO: '39',
		CMUN: '043',
		DC: '8',
		NOMBRE: 'Meruelo',
	},
	{
		CPRO: '39',
		CMUN: '044',
		DC: '3',
		NOMBRE: 'Miengo',
	},
	{
		CPRO: '39',
		CMUN: '045',
		DC: '6',
		NOMBRE: 'Miera',
	},
	{
		CPRO: '39',
		CMUN: '046',
		DC: '9',
		NOMBRE: 'Molledo',
	},
	{
		CPRO: '39',
		CMUN: '047',
		DC: '5',
		NOMBRE: 'Noja',
	},
	{
		CPRO: '39',
		CMUN: '048',
		DC: '1',
		NOMBRE: 'Penagos',
	},
	{
		CPRO: '39',
		CMUN: '049',
		DC: '4',
		NOMBRE: 'Peñarrubia',
	},
	{
		CPRO: '39',
		CMUN: '050',
		DC: '7',
		NOMBRE: 'Pesaguero',
	},
	{
		CPRO: '39',
		CMUN: '051',
		DC: '4',
		NOMBRE: 'Pesquera',
	},
	{
		CPRO: '39',
		CMUN: '052',
		DC: '9',
		NOMBRE: 'Piélagos',
	},
	{
		CPRO: '39',
		CMUN: '053',
		DC: '5',
		NOMBRE: 'Polaciones',
	},
	{
		CPRO: '39',
		CMUN: '054',
		DC: '0',
		NOMBRE: 'Polanco',
	},
	{
		CPRO: '39',
		CMUN: '055',
		DC: '3',
		NOMBRE: 'Potes',
	},
	{
		CPRO: '39',
		CMUN: '056',
		DC: '6',
		NOMBRE: 'Puente Viesgo',
	},
	{
		CPRO: '39',
		CMUN: '057',
		DC: '2',
		NOMBRE: 'Ramales de la Victoria',
	},
	{
		CPRO: '39',
		CMUN: '058',
		DC: '8',
		NOMBRE: 'Rasines',
	},
	{
		CPRO: '39',
		CMUN: '059',
		DC: '1',
		NOMBRE: 'Reinosa',
	},
	{
		CPRO: '39',
		CMUN: '060',
		DC: '5',
		NOMBRE: 'Reocín',
	},
	{
		CPRO: '39',
		CMUN: '061',
		DC: '2',
		NOMBRE: 'Ribamontán al Mar',
	},
	{
		CPRO: '39',
		CMUN: '062',
		DC: '7',
		NOMBRE: 'Ribamontán al Monte',
	},
	{
		CPRO: '39',
		CMUN: '063',
		DC: '3',
		NOMBRE: 'Rionansa',
	},
	{
		CPRO: '39',
		CMUN: '064',
		DC: '8',
		NOMBRE: 'Riotuerto',
	},
	{
		CPRO: '39',
		CMUN: '065',
		DC: '1',
		NOMBRE: 'Rozas de Valdearroyo, Las',
	},
	{
		CPRO: '39',
		CMUN: '066',
		DC: '4',
		NOMBRE: 'Ruente',
	},
	{
		CPRO: '39',
		CMUN: '067',
		DC: '0',
		NOMBRE: 'Ruesga',
	},
	{
		CPRO: '39',
		CMUN: '068',
		DC: '6',
		NOMBRE: 'Ruiloba',
	},
	{
		CPRO: '39',
		CMUN: '069',
		DC: '9',
		NOMBRE: 'San Felices de Buelna',
	},
	{
		CPRO: '39',
		CMUN: '070',
		DC: '3',
		NOMBRE: 'San Miguel de Aguayo',
	},
	{
		CPRO: '39',
		CMUN: '071',
		DC: '0',
		NOMBRE: 'San Pedro del Romeral',
	},
	{
		CPRO: '39',
		CMUN: '072',
		DC: '5',
		NOMBRE: 'San Roque de Riomiera',
	},
	{
		CPRO: '39',
		CMUN: '080',
		DC: '1',
		NOMBRE: 'San Vicente de la Barquera',
	},
	{
		CPRO: '39',
		CMUN: '073',
		DC: '1',
		NOMBRE: 'Santa Cruz de Bezana',
	},
	{
		CPRO: '39',
		CMUN: '074',
		DC: '6',
		NOMBRE: 'Santa María de Cayón',
	},
	{
		CPRO: '39',
		CMUN: '075',
		DC: '9',
		NOMBRE: 'Santander',
	},
	{
		CPRO: '39',
		CMUN: '076',
		DC: '2',
		NOMBRE: 'Santillana del Mar',
	},
	{
		CPRO: '39',
		CMUN: '077',
		DC: '8',
		NOMBRE: 'Santiurde de Reinosa',
	},
	{
		CPRO: '39',
		CMUN: '078',
		DC: '4',
		NOMBRE: 'Santiurde de Toranzo',
	},
	{
		CPRO: '39',
		CMUN: '079',
		DC: '7',
		NOMBRE: 'Santoña',
	},
	{
		CPRO: '39',
		CMUN: '081',
		DC: '8',
		NOMBRE: 'Saro',
	},
	{
		CPRO: '39',
		CMUN: '082',
		DC: '3',
		NOMBRE: 'Selaya',
	},
	{
		CPRO: '39',
		CMUN: '083',
		DC: '9',
		NOMBRE: 'Soba',
	},
	{
		CPRO: '39',
		CMUN: '084',
		DC: '4',
		NOMBRE: 'Solórzano',
	},
	{
		CPRO: '39',
		CMUN: '085',
		DC: '7',
		NOMBRE: 'Suances',
	},
	{
		CPRO: '39',
		CMUN: '086',
		DC: '0',
		NOMBRE: 'Tojos, Los',
	},
	{
		CPRO: '39',
		CMUN: '087',
		DC: '6',
		NOMBRE: 'Torrelavega',
	},
	{
		CPRO: '39',
		CMUN: '088',
		DC: '2',
		NOMBRE: 'Tresviso',
	},
	{
		CPRO: '39',
		CMUN: '089',
		DC: '5',
		NOMBRE: 'Tudanca',
	},
	{
		CPRO: '39',
		CMUN: '090',
		DC: '9',
		NOMBRE: 'Udías',
	},
	{
		CPRO: '39',
		CMUN: '095',
		DC: '5',
		NOMBRE: 'Val de San Vicente',
	},
	{
		CPRO: '39',
		CMUN: '091',
		DC: '6',
		NOMBRE: 'Valdáliga',
	},
	{
		CPRO: '39',
		CMUN: '092',
		DC: '1',
		NOMBRE: 'Valdeolea',
	},
	{
		CPRO: '39',
		CMUN: '093',
		DC: '7',
		NOMBRE: 'Valdeprado del Río',
	},
	{
		CPRO: '39',
		CMUN: '094',
		DC: '2',
		NOMBRE: 'Valderredible',
	},
	{
		CPRO: '39',
		CMUN: '101',
		DC: '4',
		NOMBRE: 'Valle de Villaverde',
	},
	{
		CPRO: '39',
		CMUN: '096',
		DC: '8',
		NOMBRE: 'Vega de Liébana',
	},
	{
		CPRO: '39',
		CMUN: '097',
		DC: '4',
		NOMBRE: 'Vega de Pas',
	},
	{
		CPRO: '39',
		CMUN: '098',
		DC: '0',
		NOMBRE: 'Villacarriedo',
	},
	{
		CPRO: '39',
		CMUN: '099',
		DC: '3',
		NOMBRE: 'Villaescusa',
	},
	{
		CPRO: '39',
		CMUN: '100',
		DC: '7',
		NOMBRE: 'Villafufre',
	},
	{
		CPRO: '39',
		CMUN: '102',
		DC: '9',
		NOMBRE: 'Voto',
	},
	{
		CPRO: '40',
		CMUN: '001',
		DC: '9',
		NOMBRE: 'Abades',
	},
	{
		CPRO: '40',
		CMUN: '002',
		DC: '4',
		NOMBRE: 'Adrada de Pirón',
	},
	{
		CPRO: '40',
		CMUN: '003',
		DC: '0',
		NOMBRE: 'Adrados',
	},
	{
		CPRO: '40',
		CMUN: '004',
		DC: '5',
		NOMBRE: 'Aguilafuente',
	},
	{
		CPRO: '40',
		CMUN: '005',
		DC: '8',
		NOMBRE: 'Alconada de Maderuelo',
	},
	{
		CPRO: '40',
		CMUN: '012',
		DC: '2',
		NOMBRE: 'Aldea Real',
	},
	{
		CPRO: '40',
		CMUN: '006',
		DC: '1',
		NOMBRE: 'Aldealcorvo',
	},
	{
		CPRO: '40',
		CMUN: '007',
		DC: '7',
		NOMBRE: 'Aldealengua de Pedraza',
	},
	{
		CPRO: '40',
		CMUN: '008',
		DC: '3',
		NOMBRE: 'Aldealengua de Santa María',
	},
	{
		CPRO: '40',
		CMUN: '009',
		DC: '6',
		NOMBRE: 'Aldeanueva de la Serrezuela',
	},
	{
		CPRO: '40',
		CMUN: '010',
		DC: '0',
		NOMBRE: 'Aldeanueva del Codonal',
	},
	{
		CPRO: '40',
		CMUN: '013',
		DC: '8',
		NOMBRE: 'Aldeasoña',
	},
	{
		CPRO: '40',
		CMUN: '014',
		DC: '3',
		NOMBRE: 'Aldehorno',
	},
	{
		CPRO: '40',
		CMUN: '015',
		DC: '6',
		NOMBRE: 'Aldehuela del Codonal',
	},
	{
		CPRO: '40',
		CMUN: '016',
		DC: '9',
		NOMBRE: 'Aldeonte',
	},
	{
		CPRO: '40',
		CMUN: '017',
		DC: '5',
		NOMBRE: 'Anaya',
	},
	{
		CPRO: '40',
		CMUN: '018',
		DC: '1',
		NOMBRE: 'Añe',
	},
	{
		CPRO: '40',
		CMUN: '019',
		DC: '4',
		NOMBRE: 'Arahuetes',
	},
	{
		CPRO: '40',
		CMUN: '020',
		DC: '8',
		NOMBRE: 'Arcones',
	},
	{
		CPRO: '40',
		CMUN: '021',
		DC: '5',
		NOMBRE: 'Arevalillo de Cega',
	},
	{
		CPRO: '40',
		CMUN: '022',
		DC: '0',
		NOMBRE: 'Armuña',
	},
	{
		CPRO: '40',
		CMUN: '024',
		DC: '1',
		NOMBRE: 'Ayllón',
	},
	{
		CPRO: '40',
		CMUN: '025',
		DC: '4',
		NOMBRE: 'Barbolla',
	},
	{
		CPRO: '40',
		CMUN: '026',
		DC: '7',
		NOMBRE: 'Basardilla',
	},
	{
		CPRO: '40',
		CMUN: '028',
		DC: '9',
		NOMBRE: 'Bercial',
	},
	{
		CPRO: '40',
		CMUN: '029',
		DC: '2',
		NOMBRE: 'Bercimuel',
	},
	{
		CPRO: '40',
		CMUN: '030',
		DC: '6',
		NOMBRE: 'Bernardos',
	},
	{
		CPRO: '40',
		CMUN: '031',
		DC: '3',
		NOMBRE: 'Bernuy de Porreros',
	},
	{
		CPRO: '40',
		CMUN: '032',
		DC: '8',
		NOMBRE: 'Boceguillas',
	},
	{
		CPRO: '40',
		CMUN: '033',
		DC: '4',
		NOMBRE: 'Brieva',
	},
	{
		CPRO: '40',
		CMUN: '034',
		DC: '9',
		NOMBRE: 'Caballar',
	},
	{
		CPRO: '40',
		CMUN: '035',
		DC: '2',
		NOMBRE: 'Cabañas de Polendos',
	},
	{
		CPRO: '40',
		CMUN: '036',
		DC: '5',
		NOMBRE: 'Cabezuela',
	},
	{
		CPRO: '40',
		CMUN: '037',
		DC: '1',
		NOMBRE: 'Calabazas de Fuentidueña',
	},
	{
		CPRO: '40',
		CMUN: '039',
		DC: '0',
		NOMBRE: 'Campo de San Pedro',
	},
	{
		CPRO: '40',
		CMUN: '040',
		DC: '4',
		NOMBRE: 'Cantalejo',
	},
	{
		CPRO: '40',
		CMUN: '041',
		DC: '1',
		NOMBRE: 'Cantimpalos',
	},
	{
		CPRO: '40',
		CMUN: '043',
		DC: '2',
		NOMBRE: 'Carbonero el Mayor',
	},
	{
		CPRO: '40',
		CMUN: '044',
		DC: '7',
		NOMBRE: 'Carrascal del Río',
	},
	{
		CPRO: '40',
		CMUN: '045',
		DC: '0',
		NOMBRE: 'Casla',
	},
	{
		CPRO: '40',
		CMUN: '046',
		DC: '3',
		NOMBRE: 'Castillejo de Mesleón',
	},
	{
		CPRO: '40',
		CMUN: '047',
		DC: '9',
		NOMBRE: 'Castro de Fuentidueña',
	},
	{
		CPRO: '40',
		CMUN: '048',
		DC: '5',
		NOMBRE: 'Castrojimeno',
	},
	{
		CPRO: '40',
		CMUN: '049',
		DC: '8',
		NOMBRE: 'Castroserna de Abajo',
	},
	{
		CPRO: '40',
		CMUN: '051',
		DC: '8',
		NOMBRE: 'Castroserracín',
	},
	{
		CPRO: '40',
		CMUN: '052',
		DC: '3',
		NOMBRE: 'Cedillo de la Torre',
	},
	{
		CPRO: '40',
		CMUN: '053',
		DC: '9',
		NOMBRE: 'Cerezo de Abajo',
	},
	{
		CPRO: '40',
		CMUN: '054',
		DC: '4',
		NOMBRE: 'Cerezo de Arriba',
	},
	{
		CPRO: '40',
		CMUN: '065',
		DC: '5',
		NOMBRE: 'Chañe',
	},
	{
		CPRO: '40',
		CMUN: '055',
		DC: '7',
		NOMBRE: 'Cilleruelo de San Mamés',
	},
	{
		CPRO: '40',
		CMUN: '056',
		DC: '0',
		NOMBRE: 'Cobos de Fuentidueña',
	},
	{
		CPRO: '40',
		CMUN: '057',
		DC: '6',
		NOMBRE: 'Coca',
	},
	{
		CPRO: '40',
		CMUN: '058',
		DC: '2',
		NOMBRE: 'Codorniz',
	},
	{
		CPRO: '40',
		CMUN: '059',
		DC: '5',
		NOMBRE: 'Collado Hermoso',
	},
	{
		CPRO: '40',
		CMUN: '060',
		DC: '9',
		NOMBRE: 'Condado de Castilnovo',
	},
	{
		CPRO: '40',
		CMUN: '061',
		DC: '6',
		NOMBRE: 'Corral de Ayllón',
	},
	{
		CPRO: '40',
		CMUN: '902',
		DC: '5',
		NOMBRE: 'Cozuelos de Fuentidueña',
	},
	{
		CPRO: '40',
		CMUN: '062',
		DC: '1',
		NOMBRE: 'Cubillo',
	},
	{
		CPRO: '40',
		CMUN: '063',
		DC: '7',
		NOMBRE: 'Cuéllar',
	},
	{
		CPRO: '40',
		CMUN: '905',
		DC: '9',
		NOMBRE: 'Cuevas de Provanco',
	},
	{
		CPRO: '40',
		CMUN: '068',
		DC: '0',
		NOMBRE: 'Domingo García',
	},
	{
		CPRO: '40',
		CMUN: '069',
		DC: '3',
		NOMBRE: 'Donhierro',
	},
	{
		CPRO: '40',
		CMUN: '070',
		DC: '7',
		NOMBRE: 'Duruelo',
	},
	{
		CPRO: '40',
		CMUN: '071',
		DC: '4',
		NOMBRE: 'Encinas',
	},
	{
		CPRO: '40',
		CMUN: '072',
		DC: '9',
		NOMBRE: 'Encinillas',
	},
	{
		CPRO: '40',
		CMUN: '073',
		DC: '5',
		NOMBRE: 'Escalona del Prado',
	},
	{
		CPRO: '40',
		CMUN: '074',
		DC: '0',
		NOMBRE: 'Escarabajosa de Cabezas',
	},
	{
		CPRO: '40',
		CMUN: '075',
		DC: '3',
		NOMBRE: 'Escobar de Polendos',
	},
	{
		CPRO: '40',
		CMUN: '076',
		DC: '6',
		NOMBRE: 'Espinar, El',
	},
	{
		CPRO: '40',
		CMUN: '077',
		DC: '2',
		NOMBRE: 'Espirdo',
	},
	{
		CPRO: '40',
		CMUN: '078',
		DC: '8',
		NOMBRE: 'Fresneda de Cuéllar',
	},
	{
		CPRO: '40',
		CMUN: '079',
		DC: '1',
		NOMBRE: 'Fresno de Cantespino',
	},
	{
		CPRO: '40',
		CMUN: '080',
		DC: '5',
		NOMBRE: 'Fresno de la Fuente',
	},
	{
		CPRO: '40',
		CMUN: '081',
		DC: '2',
		NOMBRE: 'Frumales',
	},
	{
		CPRO: '40',
		CMUN: '082',
		DC: '7',
		NOMBRE: 'Fuente de Santa Cruz',
	},
	{
		CPRO: '40',
		CMUN: '083',
		DC: '3',
		NOMBRE: 'Fuente el Olmo de Fuentidueña',
	},
	{
		CPRO: '40',
		CMUN: '084',
		DC: '8',
		NOMBRE: 'Fuente el Olmo de Íscar',
	},
	{
		CPRO: '40',
		CMUN: '086',
		DC: '4',
		NOMBRE: 'Fuentepelayo',
	},
	{
		CPRO: '40',
		CMUN: '087',
		DC: '0',
		NOMBRE: 'Fuentepiñel',
	},
	{
		CPRO: '40',
		CMUN: '088',
		DC: '6',
		NOMBRE: 'Fuenterrebollo',
	},
	{
		CPRO: '40',
		CMUN: '089',
		DC: '9',
		NOMBRE: 'Fuentesaúco de Fuentidueña',
	},
	{
		CPRO: '40',
		CMUN: '091',
		DC: '0',
		NOMBRE: 'Fuentesoto',
	},
	{
		CPRO: '40',
		CMUN: '092',
		DC: '5',
		NOMBRE: 'Fuentidueña',
	},
	{
		CPRO: '40',
		CMUN: '093',
		DC: '1',
		NOMBRE: 'Gallegos',
	},
	{
		CPRO: '40',
		CMUN: '094',
		DC: '6',
		NOMBRE: 'Garcillán',
	},
	{
		CPRO: '40',
		CMUN: '095',
		DC: '9',
		NOMBRE: 'Gomezserracín',
	},
	{
		CPRO: '40',
		CMUN: '097',
		DC: '8',
		NOMBRE: 'Grajera',
	},
	{
		CPRO: '40',
		CMUN: '099',
		DC: '7',
		NOMBRE: 'Honrubia de la Cuesta',
	},
	{
		CPRO: '40',
		CMUN: '100',
		DC: '1',
		NOMBRE: 'Hontalbilla',
	},
	{
		CPRO: '40',
		CMUN: '101',
		DC: '8',
		NOMBRE: 'Hontanares de Eresma',
	},
	{
		CPRO: '40',
		CMUN: '103',
		DC: '9',
		NOMBRE: 'Huertos, Los',
	},
	{
		CPRO: '40',
		CMUN: '104',
		DC: '4',
		NOMBRE: 'Ituero y Lama',
	},
	{
		CPRO: '40',
		CMUN: '105',
		DC: '7',
		NOMBRE: 'Juarros de Riomoros',
	},
	{
		CPRO: '40',
		CMUN: '106',
		DC: '0',
		NOMBRE: 'Juarros de Voltoya',
	},
	{
		CPRO: '40',
		CMUN: '107',
		DC: '6',
		NOMBRE: 'Labajos',
	},
	{
		CPRO: '40',
		CMUN: '108',
		DC: '2',
		NOMBRE: 'Laguna de Contreras',
	},
	{
		CPRO: '40',
		CMUN: '109',
		DC: '5',
		NOMBRE: 'Languilla',
	},
	{
		CPRO: '40',
		CMUN: '110',
		DC: '9',
		NOMBRE: 'Lastras de Cuéllar',
	},
	{
		CPRO: '40',
		CMUN: '111',
		DC: '6',
		NOMBRE: 'Lastras del Pozo',
	},
	{
		CPRO: '40',
		CMUN: '112',
		DC: '1',
		NOMBRE: 'Lastrilla, La',
	},
	{
		CPRO: '40',
		CMUN: '113',
		DC: '7',
		NOMBRE: 'Losa, La',
	},
	{
		CPRO: '40',
		CMUN: '115',
		DC: '5',
		NOMBRE: 'Maderuelo',
	},
	{
		CPRO: '40',
		CMUN: '903',
		DC: '1',
		NOMBRE: 'Marazoleja',
	},
	{
		CPRO: '40',
		CMUN: '118',
		DC: '0',
		NOMBRE: 'Marazuela',
	},
	{
		CPRO: '40',
		CMUN: '119',
		DC: '3',
		NOMBRE: 'Martín Miguel',
	},
	{
		CPRO: '40',
		CMUN: '120',
		DC: '7',
		NOMBRE: 'Martín Muñoz de la Dehesa',
	},
	{
		CPRO: '40',
		CMUN: '121',
		DC: '4',
		NOMBRE: 'Martín Muñoz de las Posadas',
	},
	{
		CPRO: '40',
		CMUN: '122',
		DC: '9',
		NOMBRE: 'Marugán',
	},
	{
		CPRO: '40',
		CMUN: '124',
		DC: '0',
		NOMBRE: 'Mata de Cuéllar',
	},
	{
		CPRO: '40',
		CMUN: '123',
		DC: '5',
		NOMBRE: 'Matabuena',
	},
	{
		CPRO: '40',
		CMUN: '125',
		DC: '3',
		NOMBRE: 'Matilla, La',
	},
	{
		CPRO: '40',
		CMUN: '126',
		DC: '6',
		NOMBRE: 'Melque de Cercos',
	},
	{
		CPRO: '40',
		CMUN: '127',
		DC: '2',
		NOMBRE: 'Membibre de la Hoz',
	},
	{
		CPRO: '40',
		CMUN: '128',
		DC: '8',
		NOMBRE: 'Migueláñez',
	},
	{
		CPRO: '40',
		CMUN: '129',
		DC: '1',
		NOMBRE: 'Montejo de Arévalo',
	},
	{
		CPRO: '40',
		CMUN: '130',
		DC: '5',
		NOMBRE: 'Montejo de la Vega de la Serrezuela',
	},
	{
		CPRO: '40',
		CMUN: '131',
		DC: '2',
		NOMBRE: 'Monterrubio',
	},
	{
		CPRO: '40',
		CMUN: '132',
		DC: '7',
		NOMBRE: 'Moral de Hornuez',
	},
	{
		CPRO: '40',
		CMUN: '134',
		DC: '8',
		NOMBRE: 'Mozoncillo',
	},
	{
		CPRO: '40',
		CMUN: '135',
		DC: '1',
		NOMBRE: 'Muñopedro',
	},
	{
		CPRO: '40',
		CMUN: '136',
		DC: '4',
		NOMBRE: 'Muñoveros',
	},
	{
		CPRO: '40',
		CMUN: '138',
		DC: '6',
		NOMBRE: 'Nava de la Asunción',
	},
	{
		CPRO: '40',
		CMUN: '139',
		DC: '9',
		NOMBRE: 'Navafría',
	},
	{
		CPRO: '40',
		CMUN: '140',
		DC: '3',
		NOMBRE: 'Navalilla',
	},
	{
		CPRO: '40',
		CMUN: '141',
		DC: '0',
		NOMBRE: 'Navalmanzano',
	},
	{
		CPRO: '40',
		CMUN: '142',
		DC: '5',
		NOMBRE: 'Navares de Ayuso',
	},
	{
		CPRO: '40',
		CMUN: '143',
		DC: '1',
		NOMBRE: 'Navares de Enmedio',
	},
	{
		CPRO: '40',
		CMUN: '144',
		DC: '6',
		NOMBRE: 'Navares de las Cuevas',
	},
	{
		CPRO: '40',
		CMUN: '145',
		DC: '9',
		NOMBRE: 'Navas de Oro',
	},
	{
		CPRO: '40',
		CMUN: '904',
		DC: '6',
		NOMBRE: 'Navas de Riofrío',
	},
	{
		CPRO: '40',
		CMUN: '146',
		DC: '2',
		NOMBRE: 'Navas de San Antonio',
	},
	{
		CPRO: '40',
		CMUN: '148',
		DC: '4',
		NOMBRE: 'Nieva',
	},
	{
		CPRO: '40',
		CMUN: '149',
		DC: '7',
		NOMBRE: 'Olombrada',
	},
	{
		CPRO: '40',
		CMUN: '150',
		DC: '0',
		NOMBRE: 'Orejana',
	},
	{
		CPRO: '40',
		CMUN: '151',
		DC: '7',
		NOMBRE: 'Ortigosa de Pestaño',
	},
	{
		CPRO: '40',
		CMUN: '901',
		DC: '0',
		NOMBRE: 'Ortigosa del Monte',
	},
	{
		CPRO: '40',
		CMUN: '152',
		DC: '2',
		NOMBRE: 'Otero de Herreros',
	},
	{
		CPRO: '40',
		CMUN: '154',
		DC: '3',
		NOMBRE: 'Pajarejos',
	},
	{
		CPRO: '40',
		CMUN: '155',
		DC: '6',
		NOMBRE: 'Palazuelos de Eresma',
	},
	{
		CPRO: '40',
		CMUN: '156',
		DC: '9',
		NOMBRE: 'Pedraza',
	},
	{
		CPRO: '40',
		CMUN: '157',
		DC: '5',
		NOMBRE: 'Pelayos del Arroyo',
	},
	{
		CPRO: '40',
		CMUN: '158',
		DC: '1',
		NOMBRE: 'Perosillo',
	},
	{
		CPRO: '40',
		CMUN: '159',
		DC: '4',
		NOMBRE: 'Pinarejos',
	},
	{
		CPRO: '40',
		CMUN: '160',
		DC: '8',
		NOMBRE: 'Pinarnegrillo',
	},
	{
		CPRO: '40',
		CMUN: '161',
		DC: '5',
		NOMBRE: 'Pradales',
	},
	{
		CPRO: '40',
		CMUN: '162',
		DC: '0',
		NOMBRE: 'Prádena',
	},
	{
		CPRO: '40',
		CMUN: '163',
		DC: '6',
		NOMBRE: 'Puebla de Pedraza',
	},
	{
		CPRO: '40',
		CMUN: '164',
		DC: '1',
		NOMBRE: 'Rapariegos',
	},
	{
		CPRO: '40',
		CMUN: '181',
		DC: '1',
		NOMBRE: 'Real Sitio de San Ildefonso',
	},
	{
		CPRO: '40',
		CMUN: '165',
		DC: '4',
		NOMBRE: 'Rebollo',
	},
	{
		CPRO: '40',
		CMUN: '166',
		DC: '7',
		NOMBRE: 'Remondo',
	},
	{
		CPRO: '40',
		CMUN: '168',
		DC: '9',
		NOMBRE: 'Riaguas de San Bartolomé',
	},
	{
		CPRO: '40',
		CMUN: '170',
		DC: '6',
		NOMBRE: 'Riaza',
	},
	{
		CPRO: '40',
		CMUN: '171',
		DC: '3',
		NOMBRE: 'Ribota',
	},
	{
		CPRO: '40',
		CMUN: '172',
		DC: '8',
		NOMBRE: 'Riofrío de Riaza',
	},
	{
		CPRO: '40',
		CMUN: '173',
		DC: '4',
		NOMBRE: 'Roda de Eresma',
	},
	{
		CPRO: '40',
		CMUN: '174',
		DC: '9',
		NOMBRE: 'Sacramenia',
	},
	{
		CPRO: '40',
		CMUN: '176',
		DC: '5',
		NOMBRE: 'Samboal',
	},
	{
		CPRO: '40',
		CMUN: '177',
		DC: '1',
		NOMBRE: 'San Cristóbal de Cuéllar',
	},
	{
		CPRO: '40',
		CMUN: '178',
		DC: '7',
		NOMBRE: 'San Cristóbal de la Vega',
	},
	{
		CPRO: '40',
		CMUN: '906',
		DC: '2',
		NOMBRE: 'San Cristóbal de Segovia',
	},
	{
		CPRO: '40',
		CMUN: '182',
		DC: '6',
		NOMBRE: 'San Martín y Mudrián',
	},
	{
		CPRO: '40',
		CMUN: '183',
		DC: '2',
		NOMBRE: 'San Miguel de Bernuy',
	},
	{
		CPRO: '40',
		CMUN: '184',
		DC: '7',
		NOMBRE: 'San Pedro de Gaíllos',
	},
	{
		CPRO: '40',
		CMUN: '179',
		DC: '0',
		NOMBRE: 'Sanchonuño',
	},
	{
		CPRO: '40',
		CMUN: '180',
		DC: '4',
		NOMBRE: 'Sangarcía',
	},
	{
		CPRO: '40',
		CMUN: '185',
		DC: '0',
		NOMBRE: 'Santa María la Real de Nieva',
	},
	{
		CPRO: '40',
		CMUN: '186',
		DC: '3',
		NOMBRE: 'Santa Marta del Cerro',
	},
	{
		CPRO: '40',
		CMUN: '188',
		DC: '5',
		NOMBRE: 'Santiuste de Pedraza',
	},
	{
		CPRO: '40',
		CMUN: '189',
		DC: '8',
		NOMBRE: 'Santiuste de San Juan Bautista',
	},
	{
		CPRO: '40',
		CMUN: '190',
		DC: '2',
		NOMBRE: 'Santo Domingo de Pirón',
	},
	{
		CPRO: '40',
		CMUN: '191',
		DC: '9',
		NOMBRE: 'Santo Tomé del Puerto',
	},
	{
		CPRO: '40',
		CMUN: '192',
		DC: '4',
		NOMBRE: 'Sauquillo de Cabezas',
	},
	{
		CPRO: '40',
		CMUN: '193',
		DC: '0',
		NOMBRE: 'Sebúlcor',
	},
	{
		CPRO: '40',
		CMUN: '194',
		DC: '5',
		NOMBRE: 'Segovia',
	},
	{
		CPRO: '40',
		CMUN: '195',
		DC: '8',
		NOMBRE: 'Sepúlveda',
	},
	{
		CPRO: '40',
		CMUN: '196',
		DC: '1',
		NOMBRE: 'Sequera de Fresno',
	},
	{
		CPRO: '40',
		CMUN: '198',
		DC: '3',
		NOMBRE: 'Sotillo',
	},
	{
		CPRO: '40',
		CMUN: '199',
		DC: '6',
		NOMBRE: 'Sotosalbos',
	},
	{
		CPRO: '40',
		CMUN: '200',
		DC: '0',
		NOMBRE: 'Tabanera la Luenga',
	},
	{
		CPRO: '40',
		CMUN: '201',
		DC: '7',
		NOMBRE: 'Tolocirio',
	},
	{
		CPRO: '40',
		CMUN: '206',
		DC: '9',
		NOMBRE: 'Torre Val de San Pedro',
	},
	{
		CPRO: '40',
		CMUN: '202',
		DC: '2',
		NOMBRE: 'Torreadrada',
	},
	{
		CPRO: '40',
		CMUN: '203',
		DC: '8',
		NOMBRE: 'Torrecaballeros',
	},
	{
		CPRO: '40',
		CMUN: '204',
		DC: '3',
		NOMBRE: 'Torrecilla del Pinar',
	},
	{
		CPRO: '40',
		CMUN: '205',
		DC: '6',
		NOMBRE: 'Torreiglesias',
	},
	{
		CPRO: '40',
		CMUN: '207',
		DC: '5',
		NOMBRE: 'Trescasas',
	},
	{
		CPRO: '40',
		CMUN: '208',
		DC: '1',
		NOMBRE: 'Turégano',
	},
	{
		CPRO: '40',
		CMUN: '210',
		DC: '8',
		NOMBRE: 'Urueñas',
	},
	{
		CPRO: '40',
		CMUN: '211',
		DC: '5',
		NOMBRE: 'Valdeprados',
	},
	{
		CPRO: '40',
		CMUN: '212',
		DC: '0',
		NOMBRE: 'Valdevacas de Montejo',
	},
	{
		CPRO: '40',
		CMUN: '213',
		DC: '6',
		NOMBRE: 'Valdevacas y Guijar',
	},
	{
		CPRO: '40',
		CMUN: '218',
		DC: '9',
		NOMBRE: 'Valle de Tabladillo',
	},
	{
		CPRO: '40',
		CMUN: '219',
		DC: '2',
		NOMBRE: 'Vallelado',
	},
	{
		CPRO: '40',
		CMUN: '220',
		DC: '6',
		NOMBRE: 'Valleruela de Pedraza',
	},
	{
		CPRO: '40',
		CMUN: '221',
		DC: '3',
		NOMBRE: 'Valleruela de Sepúlveda',
	},
	{
		CPRO: '40',
		CMUN: '214',
		DC: '1',
		NOMBRE: 'Valseca',
	},
	{
		CPRO: '40',
		CMUN: '215',
		DC: '4',
		NOMBRE: 'Valtiendas',
	},
	{
		CPRO: '40',
		CMUN: '216',
		DC: '7',
		NOMBRE: 'Valverde del Majano',
	},
	{
		CPRO: '40',
		CMUN: '222',
		DC: '8',
		NOMBRE: 'Veganzones',
	},
	{
		CPRO: '40',
		CMUN: '223',
		DC: '4',
		NOMBRE: 'Vegas de Matute',
	},
	{
		CPRO: '40',
		CMUN: '224',
		DC: '9',
		NOMBRE: 'Ventosilla y Tejadilla',
	},
	{
		CPRO: '40',
		CMUN: '225',
		DC: '2',
		NOMBRE: 'Villacastín',
	},
	{
		CPRO: '40',
		CMUN: '228',
		DC: '7',
		NOMBRE: 'Villaverde de Íscar',
	},
	{
		CPRO: '40',
		CMUN: '229',
		DC: '0',
		NOMBRE: 'Villaverde de Montejo',
	},
	{
		CPRO: '40',
		CMUN: '230',
		DC: '4',
		NOMBRE: 'Villeguillo',
	},
	{
		CPRO: '40',
		CMUN: '231',
		DC: '1',
		NOMBRE: 'Yanguas de Eresma',
	},
	{
		CPRO: '40',
		CMUN: '233',
		DC: '2',
		NOMBRE: 'Zarzuela del Monte',
	},
	{
		CPRO: '40',
		CMUN: '234',
		DC: '7',
		NOMBRE: 'Zarzuela del Pinar',
	},
	{
		CPRO: '41',
		CMUN: '001',
		DC: '6',
		NOMBRE: 'Aguadulce',
	},
	{
		CPRO: '41',
		CMUN: '002',
		DC: '1',
		NOMBRE: 'Alanís',
	},
	{
		CPRO: '41',
		CMUN: '003',
		DC: '7',
		NOMBRE: 'Albaida del Aljarafe',
	},
	{
		CPRO: '41',
		CMUN: '004',
		DC: '2',
		NOMBRE: 'Alcalá de Guadaíra',
	},
	{
		CPRO: '41',
		CMUN: '005',
		DC: '5',
		NOMBRE: 'Alcalá del Río',
	},
	{
		CPRO: '41',
		CMUN: '006',
		DC: '8',
		NOMBRE: 'Alcolea del Río',
	},
	{
		CPRO: '41',
		CMUN: '007',
		DC: '4',
		NOMBRE: 'Algaba, La',
	},
	{
		CPRO: '41',
		CMUN: '008',
		DC: '0',
		NOMBRE: 'Algámitas',
	},
	{
		CPRO: '41',
		CMUN: '009',
		DC: '3',
		NOMBRE: 'Almadén de la Plata',
	},
	{
		CPRO: '41',
		CMUN: '010',
		DC: '7',
		NOMBRE: 'Almensilla',
	},
	{
		CPRO: '41',
		CMUN: '011',
		DC: '4',
		NOMBRE: 'Arahal',
	},
	{
		CPRO: '41',
		CMUN: '012',
		DC: '9',
		NOMBRE: 'Aznalcázar',
	},
	{
		CPRO: '41',
		CMUN: '013',
		DC: '5',
		NOMBRE: 'Aznalcóllar',
	},
	{
		CPRO: '41',
		CMUN: '014',
		DC: '0',
		NOMBRE: 'Badolatosa',
	},
	{
		CPRO: '41',
		CMUN: '015',
		DC: '3',
		NOMBRE: 'Benacazón',
	},
	{
		CPRO: '41',
		CMUN: '016',
		DC: '6',
		NOMBRE: 'Bollullos de la Mitación',
	},
	{
		CPRO: '41',
		CMUN: '017',
		DC: '2',
		NOMBRE: 'Bormujos',
	},
	{
		CPRO: '41',
		CMUN: '018',
		DC: '8',
		NOMBRE: 'Brenes',
	},
	{
		CPRO: '41',
		CMUN: '019',
		DC: '1',
		NOMBRE: 'Burguillos',
	},
	{
		CPRO: '41',
		CMUN: '020',
		DC: '5',
		NOMBRE: 'Cabezas de San Juan, Las',
	},
	{
		CPRO: '41',
		CMUN: '021',
		DC: '2',
		NOMBRE: 'Camas',
	},
	{
		CPRO: '41',
		CMUN: '022',
		DC: '7',
		NOMBRE: 'Campana, La',
	},
	{
		CPRO: '41',
		CMUN: '023',
		DC: '3',
		NOMBRE: 'Cantillana',
	},
	{
		CPRO: '41',
		CMUN: '901',
		DC: '7',
		NOMBRE: 'Cañada Rosal',
	},
	{
		CPRO: '41',
		CMUN: '024',
		DC: '8',
		NOMBRE: 'Carmona',
	},
	{
		CPRO: '41',
		CMUN: '025',
		DC: '1',
		NOMBRE: 'Carrión de los Céspedes',
	},
	{
		CPRO: '41',
		CMUN: '026',
		DC: '4',
		NOMBRE: 'Casariche',
	},
	{
		CPRO: '41',
		CMUN: '027',
		DC: '0',
		NOMBRE: 'Castilblanco de los Arroyos',
	},
	{
		CPRO: '41',
		CMUN: '028',
		DC: '6',
		NOMBRE: 'Castilleja de Guzmán',
	},
	{
		CPRO: '41',
		CMUN: '029',
		DC: '9',
		NOMBRE: 'Castilleja de la Cuesta',
	},
	{
		CPRO: '41',
		CMUN: '030',
		DC: '3',
		NOMBRE: 'Castilleja del Campo',
	},
	{
		CPRO: '41',
		CMUN: '031',
		DC: '0',
		NOMBRE: 'Castillo de las Guardas, El',
	},
	{
		CPRO: '41',
		CMUN: '032',
		DC: '5',
		NOMBRE: 'Cazalla de la Sierra',
	},
	{
		CPRO: '41',
		CMUN: '033',
		DC: '1',
		NOMBRE: 'Constantina',
	},
	{
		CPRO: '41',
		CMUN: '034',
		DC: '6',
		NOMBRE: 'Coria del Río',
	},
	{
		CPRO: '41',
		CMUN: '035',
		DC: '9',
		NOMBRE: 'Coripe',
	},
	{
		CPRO: '41',
		CMUN: '036',
		DC: '2',
		NOMBRE: 'Coronil, El',
	},
	{
		CPRO: '41',
		CMUN: '037',
		DC: '8',
		NOMBRE: 'Corrales, Los',
	},
	{
		CPRO: '41',
		CMUN: '903',
		DC: '8',
		NOMBRE: 'Cuervo de Sevilla, El',
	},
	{
		CPRO: '41',
		CMUN: '038',
		DC: '4',
		NOMBRE: 'Dos Hermanas',
	},
	{
		CPRO: '41',
		CMUN: '039',
		DC: '7',
		NOMBRE: 'Écija',
	},
	{
		CPRO: '41',
		CMUN: '040',
		DC: '1',
		NOMBRE: 'Espartinas',
	},
	{
		CPRO: '41',
		CMUN: '041',
		DC: '8',
		NOMBRE: 'Estepa',
	},
	{
		CPRO: '41',
		CMUN: '042',
		DC: '3',
		NOMBRE: 'Fuentes de Andalucía',
	},
	{
		CPRO: '41',
		CMUN: '043',
		DC: '9',
		NOMBRE: 'Garrobo, El',
	},
	{
		CPRO: '41',
		CMUN: '044',
		DC: '4',
		NOMBRE: 'Gelves',
	},
	{
		CPRO: '41',
		CMUN: '045',
		DC: '7',
		NOMBRE: 'Gerena',
	},
	{
		CPRO: '41',
		CMUN: '046',
		DC: '0',
		NOMBRE: 'Gilena',
	},
	{
		CPRO: '41',
		CMUN: '047',
		DC: '6',
		NOMBRE: 'Gines',
	},
	{
		CPRO: '41',
		CMUN: '048',
		DC: '2',
		NOMBRE: 'Guadalcanal',
	},
	{
		CPRO: '41',
		CMUN: '049',
		DC: '5',
		NOMBRE: 'Guillena',
	},
	{
		CPRO: '41',
		CMUN: '050',
		DC: '8',
		NOMBRE: 'Herrera',
	},
	{
		CPRO: '41',
		CMUN: '051',
		DC: '5',
		NOMBRE: 'Huévar del Aljarafe',
	},
	{
		CPRO: '41',
		CMUN: '902',
		DC: '2',
		NOMBRE: 'Isla Mayor',
	},
	{
		CPRO: '41',
		CMUN: '052',
		DC: '0',
		NOMBRE: 'Lantejuela, La',
	},
	{
		CPRO: '41',
		CMUN: '053',
		DC: '6',
		NOMBRE: 'Lebrija',
	},
	{
		CPRO: '41',
		CMUN: '054',
		DC: '1',
		NOMBRE: 'Lora de Estepa',
	},
	{
		CPRO: '41',
		CMUN: '055',
		DC: '4',
		NOMBRE: 'Lora del Río',
	},
	{
		CPRO: '41',
		CMUN: '056',
		DC: '7',
		NOMBRE: 'Luisiana, La',
	},
	{
		CPRO: '41',
		CMUN: '057',
		DC: '3',
		NOMBRE: 'Madroño, El',
	},
	{
		CPRO: '41',
		CMUN: '058',
		DC: '9',
		NOMBRE: 'Mairena del Alcor',
	},
	{
		CPRO: '41',
		CMUN: '059',
		DC: '2',
		NOMBRE: 'Mairena del Aljarafe',
	},
	{
		CPRO: '41',
		CMUN: '060',
		DC: '6',
		NOMBRE: 'Marchena',
	},
	{
		CPRO: '41',
		CMUN: '061',
		DC: '3',
		NOMBRE: 'Marinaleda',
	},
	{
		CPRO: '41',
		CMUN: '062',
		DC: '8',
		NOMBRE: 'Martín de la Jara',
	},
	{
		CPRO: '41',
		CMUN: '063',
		DC: '4',
		NOMBRE: 'Molares, Los',
	},
	{
		CPRO: '41',
		CMUN: '064',
		DC: '9',
		NOMBRE: 'Montellano',
	},
	{
		CPRO: '41',
		CMUN: '065',
		DC: '2',
		NOMBRE: 'Morón de la Frontera',
	},
	{
		CPRO: '41',
		CMUN: '066',
		DC: '5',
		NOMBRE: 'Navas de la Concepción, Las',
	},
	{
		CPRO: '41',
		CMUN: '067',
		DC: '1',
		NOMBRE: 'Olivares',
	},
	{
		CPRO: '41',
		CMUN: '068',
		DC: '7',
		NOMBRE: 'Osuna',
	},
	{
		CPRO: '41',
		CMUN: '069',
		DC: '0',
		NOMBRE: 'Palacios y Villafranca, Los',
	},
	{
		CPRO: '41',
		CMUN: '070',
		DC: '4',
		NOMBRE: 'Palomares del Río',
	},
	{
		CPRO: '41',
		CMUN: '071',
		DC: '1',
		NOMBRE: 'Paradas',
	},
	{
		CPRO: '41',
		CMUN: '072',
		DC: '6',
		NOMBRE: 'Pedrera',
	},
	{
		CPRO: '41',
		CMUN: '073',
		DC: '2',
		NOMBRE: 'Pedroso, El',
	},
	{
		CPRO: '41',
		CMUN: '074',
		DC: '7',
		NOMBRE: 'Peñaflor',
	},
	{
		CPRO: '41',
		CMUN: '075',
		DC: '0',
		NOMBRE: 'Pilas',
	},
	{
		CPRO: '41',
		CMUN: '076',
		DC: '3',
		NOMBRE: 'Pruna',
	},
	{
		CPRO: '41',
		CMUN: '077',
		DC: '9',
		NOMBRE: 'Puebla de Cazalla, La',
	},
	{
		CPRO: '41',
		CMUN: '078',
		DC: '5',
		NOMBRE: 'Puebla de los Infantes, La',
	},
	{
		CPRO: '41',
		CMUN: '079',
		DC: '8',
		NOMBRE: 'Puebla del Río, La',
	},
	{
		CPRO: '41',
		CMUN: '080',
		DC: '2',
		NOMBRE: 'Real de la Jara, El',
	},
	{
		CPRO: '41',
		CMUN: '081',
		DC: '9',
		NOMBRE: 'Rinconada, La',
	},
	{
		CPRO: '41',
		CMUN: '082',
		DC: '4',
		NOMBRE: 'Roda de Andalucía, La',
	},
	{
		CPRO: '41',
		CMUN: '083',
		DC: '0',
		NOMBRE: 'Ronquillo, El',
	},
	{
		CPRO: '41',
		CMUN: '084',
		DC: '5',
		NOMBRE: 'Rubio, El',
	},
	{
		CPRO: '41',
		CMUN: '085',
		DC: '8',
		NOMBRE: 'Salteras',
	},
	{
		CPRO: '41',
		CMUN: '086',
		DC: '1',
		NOMBRE: 'San Juan de Aznalfarache',
	},
	{
		CPRO: '41',
		CMUN: '088',
		DC: '3',
		NOMBRE: 'San Nicolás del Puerto',
	},
	{
		CPRO: '41',
		CMUN: '087',
		DC: '7',
		NOMBRE: 'Sanlúcar la Mayor',
	},
	{
		CPRO: '41',
		CMUN: '089',
		DC: '6',
		NOMBRE: 'Santiponce',
	},
	{
		CPRO: '41',
		CMUN: '090',
		DC: '0',
		NOMBRE: 'Saucejo, El',
	},
	{
		CPRO: '41',
		CMUN: '091',
		DC: '7',
		NOMBRE: 'Sevilla',
	},
	{
		CPRO: '41',
		CMUN: '092',
		DC: '2',
		NOMBRE: 'Tocina',
	},
	{
		CPRO: '41',
		CMUN: '093',
		DC: '8',
		NOMBRE: 'Tomares',
	},
	{
		CPRO: '41',
		CMUN: '094',
		DC: '3',
		NOMBRE: 'Umbrete',
	},
	{
		CPRO: '41',
		CMUN: '095',
		DC: '6',
		NOMBRE: 'Utrera',
	},
	{
		CPRO: '41',
		CMUN: '096',
		DC: '9',
		NOMBRE: 'Valencina de la Concepción',
	},
	{
		CPRO: '41',
		CMUN: '097',
		DC: '5',
		NOMBRE: 'Villamanrique de la Condesa',
	},
	{
		CPRO: '41',
		CMUN: '100',
		DC: '8',
		NOMBRE: 'Villanueva de San Juan',
	},
	{
		CPRO: '41',
		CMUN: '098',
		DC: '1',
		NOMBRE: 'Villanueva del Ariscal',
	},
	{
		CPRO: '41',
		CMUN: '099',
		DC: '4',
		NOMBRE: 'Villanueva del Río y Minas',
	},
	{
		CPRO: '41',
		CMUN: '101',
		DC: '5',
		NOMBRE: 'Villaverde del Río',
	},
	{
		CPRO: '41',
		CMUN: '102',
		DC: '0',
		NOMBRE: 'Viso del Alcor, El',
	},
	{
		CPRO: '42',
		CMUN: '001',
		DC: '1',
		NOMBRE: 'Abejar',
	},
	{
		CPRO: '42',
		CMUN: '003',
		DC: '2',
		NOMBRE: 'Adradas',
	},
	{
		CPRO: '42',
		CMUN: '004',
		DC: '7',
		NOMBRE: 'Ágreda',
	},
	{
		CPRO: '42',
		CMUN: '006',
		DC: '3',
		NOMBRE: 'Alconaba',
	},
	{
		CPRO: '42',
		CMUN: '007',
		DC: '9',
		NOMBRE: 'Alcubilla de Avellaneda',
	},
	{
		CPRO: '42',
		CMUN: '008',
		DC: '5',
		NOMBRE: 'Alcubilla de las Peñas',
	},
	{
		CPRO: '42',
		CMUN: '009',
		DC: '8',
		NOMBRE: 'Aldealafuente',
	},
	{
		CPRO: '42',
		CMUN: '010',
		DC: '2',
		NOMBRE: 'Aldealices',
	},
	{
		CPRO: '42',
		CMUN: '011',
		DC: '9',
		NOMBRE: 'Aldealpozo',
	},
	{
		CPRO: '42',
		CMUN: '012',
		DC: '4',
		NOMBRE: 'Aldealseñor',
	},
	{
		CPRO: '42',
		CMUN: '013',
		DC: '0',
		NOMBRE: 'Aldehuela de Periáñez',
	},
	{
		CPRO: '42',
		CMUN: '014',
		DC: '5',
		NOMBRE: 'Aldehuelas, Las',
	},
	{
		CPRO: '42',
		CMUN: '015',
		DC: '8',
		NOMBRE: 'Alentisque',
	},
	{
		CPRO: '42',
		CMUN: '016',
		DC: '1',
		NOMBRE: 'Aliud',
	},
	{
		CPRO: '42',
		CMUN: '017',
		DC: '7',
		NOMBRE: 'Almajano',
	},
	{
		CPRO: '42',
		CMUN: '018',
		DC: '3',
		NOMBRE: 'Almaluez',
	},
	{
		CPRO: '42',
		CMUN: '019',
		DC: '6',
		NOMBRE: 'Almarza',
	},
	{
		CPRO: '42',
		CMUN: '020',
		DC: '0',
		NOMBRE: 'Almazán',
	},
	{
		CPRO: '42',
		CMUN: '021',
		DC: '7',
		NOMBRE: 'Almazul',
	},
	{
		CPRO: '42',
		CMUN: '022',
		DC: '2',
		NOMBRE: 'Almenar de Soria',
	},
	{
		CPRO: '42',
		CMUN: '023',
		DC: '8',
		NOMBRE: 'Alpanseque',
	},
	{
		CPRO: '42',
		CMUN: '024',
		DC: '3',
		NOMBRE: 'Arancón',
	},
	{
		CPRO: '42',
		CMUN: '025',
		DC: '6',
		NOMBRE: 'Arcos de Jalón',
	},
	{
		CPRO: '42',
		CMUN: '026',
		DC: '9',
		NOMBRE: 'Arenillas',
	},
	{
		CPRO: '42',
		CMUN: '027',
		DC: '5',
		NOMBRE: 'Arévalo de la Sierra',
	},
	{
		CPRO: '42',
		CMUN: '028',
		DC: '1',
		NOMBRE: 'Ausejo de la Sierra',
	},
	{
		CPRO: '42',
		CMUN: '029',
		DC: '4',
		NOMBRE: 'Baraona',
	},
	{
		CPRO: '42',
		CMUN: '030',
		DC: '8',
		NOMBRE: 'Barca',
	},
	{
		CPRO: '42',
		CMUN: '031',
		DC: '5',
		NOMBRE: 'Barcones',
	},
	{
		CPRO: '42',
		CMUN: '032',
		DC: '0',
		NOMBRE: 'Bayubas de Abajo',
	},
	{
		CPRO: '42',
		CMUN: '033',
		DC: '6',
		NOMBRE: 'Bayubas de Arriba',
	},
	{
		CPRO: '42',
		CMUN: '034',
		DC: '1',
		NOMBRE: 'Beratón',
	},
	{
		CPRO: '42',
		CMUN: '035',
		DC: '4',
		NOMBRE: 'Berlanga de Duero',
	},
	{
		CPRO: '42',
		CMUN: '036',
		DC: '7',
		NOMBRE: 'Blacos',
	},
	{
		CPRO: '42',
		CMUN: '037',
		DC: '3',
		NOMBRE: 'Bliecos',
	},
	{
		CPRO: '42',
		CMUN: '038',
		DC: '9',
		NOMBRE: 'Borjabad',
	},
	{
		CPRO: '42',
		CMUN: '039',
		DC: '2',
		NOMBRE: 'Borobia',
	},
	{
		CPRO: '42',
		CMUN: '041',
		DC: '3',
		NOMBRE: 'Buberos',
	},
	{
		CPRO: '42',
		CMUN: '042',
		DC: '8',
		NOMBRE: 'Buitrago',
	},
	{
		CPRO: '42',
		CMUN: '043',
		DC: '4',
		NOMBRE: 'Burgo de Osma-Ciudad de Osma',
	},
	{
		CPRO: '42',
		CMUN: '044',
		DC: '9',
		NOMBRE: 'Cabrejas del Campo',
	},
	{
		CPRO: '42',
		CMUN: '045',
		DC: '2',
		NOMBRE: 'Cabrejas del Pinar',
	},
	{
		CPRO: '42',
		CMUN: '046',
		DC: '5',
		NOMBRE: 'Calatañazor',
	},
	{
		CPRO: '42',
		CMUN: '048',
		DC: '7',
		NOMBRE: 'Caltojar',
	},
	{
		CPRO: '42',
		CMUN: '049',
		DC: '0',
		NOMBRE: 'Candilichera',
	},
	{
		CPRO: '42',
		CMUN: '050',
		DC: '3',
		NOMBRE: 'Cañamaque',
	},
	{
		CPRO: '42',
		CMUN: '051',
		DC: '0',
		NOMBRE: 'Carabantes',
	},
	{
		CPRO: '42',
		CMUN: '052',
		DC: '5',
		NOMBRE: 'Caracena',
	},
	{
		CPRO: '42',
		CMUN: '053',
		DC: '1',
		NOMBRE: 'Carrascosa de Abajo',
	},
	{
		CPRO: '42',
		CMUN: '054',
		DC: '6',
		NOMBRE: 'Carrascosa de la Sierra',
	},
	{
		CPRO: '42',
		CMUN: '055',
		DC: '9',
		NOMBRE: 'Casarejos',
	},
	{
		CPRO: '42',
		CMUN: '056',
		DC: '2',
		NOMBRE: 'Castilfrío de la Sierra',
	},
	{
		CPRO: '42',
		CMUN: '058',
		DC: '4',
		NOMBRE: 'Castillejo de Robledo',
	},
	{
		CPRO: '42',
		CMUN: '057',
		DC: '8',
		NOMBRE: 'Castilruiz',
	},
	{
		CPRO: '42',
		CMUN: '059',
		DC: '7',
		NOMBRE: 'Centenera de Andaluz',
	},
	{
		CPRO: '42',
		CMUN: '060',
		DC: '1',
		NOMBRE: 'Cerbón',
	},
	{
		CPRO: '42',
		CMUN: '061',
		DC: '8',
		NOMBRE: 'Cidones',
	},
	{
		CPRO: '42',
		CMUN: '062',
		DC: '3',
		NOMBRE: 'Cigudosa',
	},
	{
		CPRO: '42',
		CMUN: '063',
		DC: '9',
		NOMBRE: 'Cihuela',
	},
	{
		CPRO: '42',
		CMUN: '064',
		DC: '4',
		NOMBRE: 'Ciria',
	},
	{
		CPRO: '42',
		CMUN: '065',
		DC: '7',
		NOMBRE: 'Cirujales del Río',
	},
	{
		CPRO: '42',
		CMUN: '068',
		DC: '2',
		NOMBRE: 'Coscurita',
	},
	{
		CPRO: '42',
		CMUN: '069',
		DC: '5',
		NOMBRE: 'Covaleda',
	},
	{
		CPRO: '42',
		CMUN: '070',
		DC: '9',
		NOMBRE: 'Cubilla',
	},
	{
		CPRO: '42',
		CMUN: '071',
		DC: '6',
		NOMBRE: 'Cubo de la Solana',
	},
	{
		CPRO: '42',
		CMUN: '073',
		DC: '7',
		NOMBRE: 'Cueva de Ágreda',
	},
	{
		CPRO: '42',
		CMUN: '075',
		DC: '5',
		NOMBRE: 'Dévanos',
	},
	{
		CPRO: '42',
		CMUN: '076',
		DC: '8',
		NOMBRE: 'Deza',
	},
	{
		CPRO: '42',
		CMUN: '078',
		DC: '0',
		NOMBRE: 'Duruelo de la Sierra',
	},
	{
		CPRO: '42',
		CMUN: '079',
		DC: '3',
		NOMBRE: 'Escobosa de Almazán',
	},
	{
		CPRO: '42',
		CMUN: '080',
		DC: '7',
		NOMBRE: 'Espeja de San Marcelino',
	},
	{
		CPRO: '42',
		CMUN: '081',
		DC: '4',
		NOMBRE: 'Espejón',
	},
	{
		CPRO: '42',
		CMUN: '082',
		DC: '9',
		NOMBRE: 'Estepa de San Juan',
	},
	{
		CPRO: '42',
		CMUN: '083',
		DC: '5',
		NOMBRE: 'Frechilla de Almazán',
	},
	{
		CPRO: '42',
		CMUN: '084',
		DC: '0',
		NOMBRE: 'Fresno de Caracena',
	},
	{
		CPRO: '42',
		CMUN: '085',
		DC: '3',
		NOMBRE: 'Fuentearmegil',
	},
	{
		CPRO: '42',
		CMUN: '086',
		DC: '6',
		NOMBRE: 'Fuentecambrón',
	},
	{
		CPRO: '42',
		CMUN: '087',
		DC: '2',
		NOMBRE: 'Fuentecantos',
	},
	{
		CPRO: '42',
		CMUN: '088',
		DC: '8',
		NOMBRE: 'Fuentelmonge',
	},
	{
		CPRO: '42',
		CMUN: '089',
		DC: '1',
		NOMBRE: 'Fuentelsaz de Soria',
	},
	{
		CPRO: '42',
		CMUN: '090',
		DC: '5',
		NOMBRE: 'Fuentepinilla',
	},
	{
		CPRO: '42',
		CMUN: '092',
		DC: '7',
		NOMBRE: 'Fuentes de Magaña',
	},
	{
		CPRO: '42',
		CMUN: '093',
		DC: '3',
		NOMBRE: 'Fuentestrún',
	},
	{
		CPRO: '42',
		CMUN: '094',
		DC: '8',
		NOMBRE: 'Garray',
	},
	{
		CPRO: '42',
		CMUN: '095',
		DC: '1',
		NOMBRE: 'Golmayo',
	},
	{
		CPRO: '42',
		CMUN: '096',
		DC: '4',
		NOMBRE: 'Gómara',
	},
	{
		CPRO: '42',
		CMUN: '097',
		DC: '0',
		NOMBRE: 'Gormaz',
	},
	{
		CPRO: '42',
		CMUN: '098',
		DC: '6',
		NOMBRE: 'Herrera de Soria',
	},
	{
		CPRO: '42',
		CMUN: '100',
		DC: '3',
		NOMBRE: 'Hinojosa del Campo',
	},
	{
		CPRO: '42',
		CMUN: '103',
		DC: '1',
		NOMBRE: 'Langa de Duero',
	},
	{
		CPRO: '42',
		CMUN: '105',
		DC: '9',
		NOMBRE: 'Liceras',
	},
	{
		CPRO: '42',
		CMUN: '106',
		DC: '2',
		NOMBRE: 'Losilla, La',
	},
	{
		CPRO: '42',
		CMUN: '107',
		DC: '8',
		NOMBRE: 'Magaña',
	},
	{
		CPRO: '42',
		CMUN: '108',
		DC: '4',
		NOMBRE: 'Maján',
	},
	{
		CPRO: '42',
		CMUN: '110',
		DC: '1',
		NOMBRE: 'Matalebreras',
	},
	{
		CPRO: '42',
		CMUN: '111',
		DC: '8',
		NOMBRE: 'Matamala de Almazán',
	},
	{
		CPRO: '42',
		CMUN: '113',
		DC: '9',
		NOMBRE: 'Medinaceli',
	},
	{
		CPRO: '42',
		CMUN: '115',
		DC: '7',
		NOMBRE: 'Miño de Medinaceli',
	},
	{
		CPRO: '42',
		CMUN: '116',
		DC: '0',
		NOMBRE: 'Miño de San Esteban',
	},
	{
		CPRO: '42',
		CMUN: '117',
		DC: '6',
		NOMBRE: 'Molinos de Duero',
	},
	{
		CPRO: '42',
		CMUN: '118',
		DC: '2',
		NOMBRE: 'Momblona',
	},
	{
		CPRO: '42',
		CMUN: '119',
		DC: '5',
		NOMBRE: 'Monteagudo de las Vicarías',
	},
	{
		CPRO: '42',
		CMUN: '120',
		DC: '9',
		NOMBRE: 'Montejo de Tiermes',
	},
	{
		CPRO: '42',
		CMUN: '121',
		DC: '6',
		NOMBRE: 'Montenegro de Cameros',
	},
	{
		CPRO: '42',
		CMUN: '123',
		DC: '7',
		NOMBRE: 'Morón de Almazán',
	},
	{
		CPRO: '42',
		CMUN: '124',
		DC: '2',
		NOMBRE: 'Muriel de la Fuente',
	},
	{
		CPRO: '42',
		CMUN: '125',
		DC: '5',
		NOMBRE: 'Muriel Viejo',
	},
	{
		CPRO: '42',
		CMUN: '127',
		DC: '4',
		NOMBRE: 'Nafría de Ucero',
	},
	{
		CPRO: '42',
		CMUN: '128',
		DC: '0',
		NOMBRE: 'Narros',
	},
	{
		CPRO: '42',
		CMUN: '129',
		DC: '3',
		NOMBRE: 'Navaleno',
	},
	{
		CPRO: '42',
		CMUN: '130',
		DC: '7',
		NOMBRE: 'Nepas',
	},
	{
		CPRO: '42',
		CMUN: '131',
		DC: '4',
		NOMBRE: 'Nolay',
	},
	{
		CPRO: '42',
		CMUN: '132',
		DC: '9',
		NOMBRE: 'Noviercas',
	},
	{
		CPRO: '42',
		CMUN: '134',
		DC: '0',
		NOMBRE: 'Ólvega',
	},
	{
		CPRO: '42',
		CMUN: '135',
		DC: '3',
		NOMBRE: 'Oncala',
	},
	{
		CPRO: '42',
		CMUN: '139',
		DC: '1',
		NOMBRE: 'Pinilla del Campo',
	},
	{
		CPRO: '42',
		CMUN: '140',
		DC: '5',
		NOMBRE: 'Portillo de Soria',
	},
	{
		CPRO: '42',
		CMUN: '141',
		DC: '2',
		NOMBRE: 'Póveda de Soria, La',
	},
	{
		CPRO: '42',
		CMUN: '142',
		DC: '7',
		NOMBRE: 'Pozalmuro',
	},
	{
		CPRO: '42',
		CMUN: '144',
		DC: '8',
		NOMBRE: 'Quintana Redonda',
	},
	{
		CPRO: '42',
		CMUN: '145',
		DC: '1',
		NOMBRE: 'Quintanas de Gormaz',
	},
	{
		CPRO: '42',
		CMUN: '148',
		DC: '6',
		NOMBRE: 'Quiñonería',
	},
	{
		CPRO: '42',
		CMUN: '149',
		DC: '9',
		NOMBRE: 'Rábanos, Los',
	},
	{
		CPRO: '42',
		CMUN: '151',
		DC: '9',
		NOMBRE: 'Rebollar',
	},
	{
		CPRO: '42',
		CMUN: '152',
		DC: '4',
		NOMBRE: 'Recuerda',
	},
	{
		CPRO: '42',
		CMUN: '153',
		DC: '0',
		NOMBRE: 'Rello',
	},
	{
		CPRO: '42',
		CMUN: '154',
		DC: '5',
		NOMBRE: 'Renieblas',
	},
	{
		CPRO: '42',
		CMUN: '155',
		DC: '8',
		NOMBRE: 'Retortillo de Soria',
	},
	{
		CPRO: '42',
		CMUN: '156',
		DC: '1',
		NOMBRE: 'Reznos',
	},
	{
		CPRO: '42',
		CMUN: '157',
		DC: '7',
		NOMBRE: 'Riba de Escalote, La',
	},
	{
		CPRO: '42',
		CMUN: '158',
		DC: '3',
		NOMBRE: 'Rioseco de Soria',
	},
	{
		CPRO: '42',
		CMUN: '159',
		DC: '6',
		NOMBRE: 'Rollamienta',
	},
	{
		CPRO: '42',
		CMUN: '160',
		DC: '0',
		NOMBRE: 'Royo, El',
	},
	{
		CPRO: '42',
		CMUN: '161',
		DC: '7',
		NOMBRE: 'Salduero',
	},
	{
		CPRO: '42',
		CMUN: '162',
		DC: '2',
		NOMBRE: 'San Esteban de Gormaz',
	},
	{
		CPRO: '42',
		CMUN: '163',
		DC: '8',
		NOMBRE: 'San Felices',
	},
	{
		CPRO: '42',
		CMUN: '164',
		DC: '3',
		NOMBRE: 'San Leonardo de Yagüe',
	},
	{
		CPRO: '42',
		CMUN: '165',
		DC: '6',
		NOMBRE: 'San Pedro Manrique',
	},
	{
		CPRO: '42',
		CMUN: '166',
		DC: '9',
		NOMBRE: 'Santa Cruz de Yanguas',
	},
	{
		CPRO: '42',
		CMUN: '167',
		DC: '5',
		NOMBRE: 'Santa María de Huerta',
	},
	{
		CPRO: '42',
		CMUN: '168',
		DC: '1',
		NOMBRE: 'Santa María de las Hoyas',
	},
	{
		CPRO: '42',
		CMUN: '171',
		DC: '5',
		NOMBRE: 'Serón de Nágima',
	},
	{
		CPRO: '42',
		CMUN: '172',
		DC: '0',
		NOMBRE: 'Soliedra',
	},
	{
		CPRO: '42',
		CMUN: '173',
		DC: '6',
		NOMBRE: 'Soria',
	},
	{
		CPRO: '42',
		CMUN: '174',
		DC: '1',
		NOMBRE: 'Sotillo del Rincón',
	},
	{
		CPRO: '42',
		CMUN: '175',
		DC: '4',
		NOMBRE: 'Suellacabras',
	},
	{
		CPRO: '42',
		CMUN: '176',
		DC: '7',
		NOMBRE: 'Tajahuerce',
	},
	{
		CPRO: '42',
		CMUN: '177',
		DC: '3',
		NOMBRE: 'Tajueco',
	},
	{
		CPRO: '42',
		CMUN: '178',
		DC: '9',
		NOMBRE: 'Talveila',
	},
	{
		CPRO: '42',
		CMUN: '181',
		DC: '3',
		NOMBRE: 'Tardelcuende',
	},
	{
		CPRO: '42',
		CMUN: '182',
		DC: '8',
		NOMBRE: 'Taroda',
	},
	{
		CPRO: '42',
		CMUN: '183',
		DC: '4',
		NOMBRE: 'Tejado',
	},
	{
		CPRO: '42',
		CMUN: '184',
		DC: '9',
		NOMBRE: 'Torlengua',
	},
	{
		CPRO: '42',
		CMUN: '185',
		DC: '2',
		NOMBRE: 'Torreblacos',
	},
	{
		CPRO: '42',
		CMUN: '187',
		DC: '1',
		NOMBRE: 'Torrubia de Soria',
	},
	{
		CPRO: '42',
		CMUN: '188',
		DC: '7',
		NOMBRE: 'Trévago',
	},
	{
		CPRO: '42',
		CMUN: '189',
		DC: '0',
		NOMBRE: 'Ucero',
	},
	{
		CPRO: '42',
		CMUN: '190',
		DC: '4',
		NOMBRE: 'Vadillo',
	},
	{
		CPRO: '42',
		CMUN: '191',
		DC: '1',
		NOMBRE: 'Valdeavellano de Tera',
	},
	{
		CPRO: '42',
		CMUN: '192',
		DC: '6',
		NOMBRE: 'Valdegeña',
	},
	{
		CPRO: '42',
		CMUN: '193',
		DC: '2',
		NOMBRE: 'Valdelagua del Cerro',
	},
	{
		CPRO: '42',
		CMUN: '194',
		DC: '7',
		NOMBRE: 'Valdemaluque',
	},
	{
		CPRO: '42',
		CMUN: '195',
		DC: '0',
		NOMBRE: 'Valdenebro',
	},
	{
		CPRO: '42',
		CMUN: '196',
		DC: '3',
		NOMBRE: 'Valdeprado',
	},
	{
		CPRO: '42',
		CMUN: '197',
		DC: '9',
		NOMBRE: 'Valderrodilla',
	},
	{
		CPRO: '42',
		CMUN: '198',
		DC: '5',
		NOMBRE: 'Valtajeros',
	},
	{
		CPRO: '42',
		CMUN: '200',
		DC: '2',
		NOMBRE: 'Velamazán',
	},
	{
		CPRO: '42',
		CMUN: '201',
		DC: '9',
		NOMBRE: 'Velilla de la Sierra',
	},
	{
		CPRO: '42',
		CMUN: '202',
		DC: '4',
		NOMBRE: 'Velilla de los Ajos',
	},
	{
		CPRO: '42',
		CMUN: '204',
		DC: '5',
		NOMBRE: 'Viana de Duero',
	},
	{
		CPRO: '42',
		CMUN: '205',
		DC: '8',
		NOMBRE: 'Villaciervos',
	},
	{
		CPRO: '42',
		CMUN: '206',
		DC: '1',
		NOMBRE: 'Villanueva de Gormaz',
	},
	{
		CPRO: '42',
		CMUN: '207',
		DC: '7',
		NOMBRE: 'Villar del Ala',
	},
	{
		CPRO: '42',
		CMUN: '208',
		DC: '3',
		NOMBRE: 'Villar del Campo',
	},
	{
		CPRO: '42',
		CMUN: '209',
		DC: '6',
		NOMBRE: 'Villar del Río',
	},
	{
		CPRO: '42',
		CMUN: '211',
		DC: '7',
		NOMBRE: 'Villares de Soria, Los',
	},
	{
		CPRO: '42',
		CMUN: '212',
		DC: '2',
		NOMBRE: 'Villasayas',
	},
	{
		CPRO: '42',
		CMUN: '213',
		DC: '8',
		NOMBRE: 'Villaseca de Arciel',
	},
	{
		CPRO: '42',
		CMUN: '215',
		DC: '6',
		NOMBRE: 'Vinuesa',
	},
	{
		CPRO: '42',
		CMUN: '216',
		DC: '9',
		NOMBRE: 'Vizmanos',
	},
	{
		CPRO: '42',
		CMUN: '217',
		DC: '5',
		NOMBRE: 'Vozmediano',
	},
	{
		CPRO: '42',
		CMUN: '218',
		DC: '1',
		NOMBRE: 'Yanguas',
	},
	{
		CPRO: '42',
		CMUN: '219',
		DC: '4',
		NOMBRE: 'Yelo',
	},
	{
		CPRO: '43',
		CMUN: '001',
		DC: '7',
		NOMBRE: 'Aiguamúrcia',
	},
	{
		CPRO: '43',
		CMUN: '002',
		DC: '2',
		NOMBRE: 'Albinyana',
	},
	{
		CPRO: '43',
		CMUN: '003',
		DC: '8',
		NOMBRE: "Albiol, L'",
	},
	{
		CPRO: '43',
		CMUN: '004',
		DC: '3',
		NOMBRE: 'Alcanar',
	},
	{
		CPRO: '43',
		CMUN: '005',
		DC: '6',
		NOMBRE: 'Alcover',
	},
	{
		CPRO: '43',
		CMUN: '904',
		DC: '4',
		NOMBRE: "Aldea, L'",
	},
	{
		CPRO: '43',
		CMUN: '006',
		DC: '9',
		NOMBRE: 'Aldover',
	},
	{
		CPRO: '43',
		CMUN: '007',
		DC: '5',
		NOMBRE: "Aleixar, L'",
	},
	{
		CPRO: '43',
		CMUN: '008',
		DC: '1',
		NOMBRE: 'Alfara de Carles',
	},
	{
		CPRO: '43',
		CMUN: '009',
		DC: '4',
		NOMBRE: 'Alforja',
	},
	{
		CPRO: '43',
		CMUN: '010',
		DC: '8',
		NOMBRE: 'Alió',
	},
	{
		CPRO: '43',
		CMUN: '011',
		DC: '5',
		NOMBRE: 'Almoster',
	},
	{
		CPRO: '43',
		CMUN: '012',
		DC: '0',
		NOMBRE: 'Altafulla',
	},
	{
		CPRO: '43',
		CMUN: '013',
		DC: '6',
		NOMBRE: "Ametlla de Mar, L'",
	},
	{
		CPRO: '43',
		CMUN: '906',
		DC: '0',
		NOMBRE: "Ampolla, L'",
	},
	{
		CPRO: '43',
		CMUN: '014',
		DC: '1',
		NOMBRE: 'Amposta',
	},
	{
		CPRO: '43',
		CMUN: '016',
		DC: '7',
		NOMBRE: "Arboç, L'",
	},
	{
		CPRO: '43',
		CMUN: '015',
		DC: '4',
		NOMBRE: 'Arbolí',
	},
	{
		CPRO: '43',
		CMUN: '017',
		DC: '3',
		NOMBRE: "Argentera, L'",
	},
	{
		CPRO: '43',
		CMUN: '018',
		DC: '9',
		NOMBRE: 'Arnes',
	},
	{
		CPRO: '43',
		CMUN: '019',
		DC: '2',
		NOMBRE: 'Ascó',
	},
	{
		CPRO: '43',
		CMUN: '020',
		DC: '6',
		NOMBRE: 'Banyeres del Penedès',
	},
	{
		CPRO: '43',
		CMUN: '021',
		DC: '3',
		NOMBRE: 'Barberà de la Conca',
	},
	{
		CPRO: '43',
		CMUN: '022',
		DC: '8',
		NOMBRE: 'Batea',
	},
	{
		CPRO: '43',
		CMUN: '023',
		DC: '4',
		NOMBRE: 'Bellmunt del Priorat',
	},
	{
		CPRO: '43',
		CMUN: '024',
		DC: '9',
		NOMBRE: 'Bellvei',
	},
	{
		CPRO: '43',
		CMUN: '025',
		DC: '2',
		NOMBRE: 'Benifallet',
	},
	{
		CPRO: '43',
		CMUN: '026',
		DC: '5',
		NOMBRE: 'Benissanet',
	},
	{
		CPRO: '43',
		CMUN: '027',
		DC: '1',
		NOMBRE: 'Bisbal de Falset, La',
	},
	{
		CPRO: '43',
		CMUN: '028',
		DC: '7',
		NOMBRE: 'Bisbal del Penedès, La',
	},
	{
		CPRO: '43',
		CMUN: '029',
		DC: '0',
		NOMBRE: 'Blancafort',
	},
	{
		CPRO: '43',
		CMUN: '030',
		DC: '4',
		NOMBRE: 'Bonastre',
	},
	{
		CPRO: '43',
		CMUN: '031',
		DC: '1',
		NOMBRE: 'Borges del Camp, Les',
	},
	{
		CPRO: '43',
		CMUN: '032',
		DC: '6',
		NOMBRE: 'Bot',
	},
	{
		CPRO: '43',
		CMUN: '033',
		DC: '2',
		NOMBRE: 'Botarell',
	},
	{
		CPRO: '43',
		CMUN: '034',
		DC: '7',
		NOMBRE: 'Bràfim',
	},
	{
		CPRO: '43',
		CMUN: '035',
		DC: '0',
		NOMBRE: 'Cabacés',
	},
	{
		CPRO: '43',
		CMUN: '036',
		DC: '3',
		NOMBRE: 'Cabra del Camp',
	},
	{
		CPRO: '43',
		CMUN: '037',
		DC: '9',
		NOMBRE: 'Calafell',
	},
	{
		CPRO: '43',
		CMUN: '903',
		DC: '9',
		NOMBRE: 'Camarles',
	},
	{
		CPRO: '43',
		CMUN: '038',
		DC: '5',
		NOMBRE: 'Cambrils',
	},
	{
		CPRO: '43',
		CMUN: '907',
		DC: '6',
		NOMBRE: 'Canonja, La',
	},
	{
		CPRO: '43',
		CMUN: '039',
		DC: '8',
		NOMBRE: 'Capafonts',
	},
	{
		CPRO: '43',
		CMUN: '040',
		DC: '2',
		NOMBRE: 'Capçanes',
	},
	{
		CPRO: '43',
		CMUN: '041',
		DC: '9',
		NOMBRE: 'Caseres',
	},
	{
		CPRO: '43',
		CMUN: '042',
		DC: '4',
		NOMBRE: 'Castellvell del Camp',
	},
	{
		CPRO: '43',
		CMUN: '043',
		DC: '0',
		NOMBRE: 'Catllar, El',
	},
	{
		CPRO: '43',
		CMUN: '045',
		DC: '8',
		NOMBRE: 'Colldejou',
	},
	{
		CPRO: '43',
		CMUN: '046',
		DC: '1',
		NOMBRE: 'Conesa',
	},
	{
		CPRO: '43',
		CMUN: '047',
		DC: '7',
		NOMBRE: 'Constantí',
	},
	{
		CPRO: '43',
		CMUN: '048',
		DC: '3',
		NOMBRE: "Corbera d'Ebre",
	},
	{
		CPRO: '43',
		CMUN: '049',
		DC: '6',
		NOMBRE: 'Cornudella de Montsant',
	},
	{
		CPRO: '43',
		CMUN: '050',
		DC: '9',
		NOMBRE: 'Creixell',
	},
	{
		CPRO: '43',
		CMUN: '051',
		DC: '6',
		NOMBRE: 'Cunit',
	},
	{
		CPRO: '43',
		CMUN: '901',
		DC: '8',
		NOMBRE: 'Deltebre',
	},
	{
		CPRO: '43',
		CMUN: '053',
		DC: '7',
		NOMBRE: 'Duesaigües',
	},
	{
		CPRO: '43',
		CMUN: '054',
		DC: '2',
		NOMBRE: "Espluga de Francolí, L'",
	},
	{
		CPRO: '43',
		CMUN: '055',
		DC: '5',
		NOMBRE: 'Falset',
	},
	{
		CPRO: '43',
		CMUN: '056',
		DC: '8',
		NOMBRE: 'Fatarella, La',
	},
	{
		CPRO: '43',
		CMUN: '057',
		DC: '4',
		NOMBRE: 'Febró, La',
	},
	{
		CPRO: '43',
		CMUN: '058',
		DC: '0',
		NOMBRE: 'Figuera, La',
	},
	{
		CPRO: '43',
		CMUN: '059',
		DC: '3',
		NOMBRE: 'Figuerola del Camp',
	},
	{
		CPRO: '43',
		CMUN: '060',
		DC: '7',
		NOMBRE: 'Flix',
	},
	{
		CPRO: '43',
		CMUN: '061',
		DC: '4',
		NOMBRE: 'Forès',
	},
	{
		CPRO: '43',
		CMUN: '062',
		DC: '9',
		NOMBRE: 'Freginals',
	},
	{
		CPRO: '43',
		CMUN: '063',
		DC: '5',
		NOMBRE: 'Galera, La',
	},
	{
		CPRO: '43',
		CMUN: '064',
		DC: '0',
		NOMBRE: 'Gandesa',
	},
	{
		CPRO: '43',
		CMUN: '065',
		DC: '3',
		NOMBRE: 'Garcia',
	},
	{
		CPRO: '43',
		CMUN: '066',
		DC: '6',
		NOMBRE: 'Garidells, Els',
	},
	{
		CPRO: '43',
		CMUN: '067',
		DC: '2',
		NOMBRE: 'Ginestar',
	},
	{
		CPRO: '43',
		CMUN: '068',
		DC: '8',
		NOMBRE: 'Godall',
	},
	{
		CPRO: '43',
		CMUN: '069',
		DC: '1',
		NOMBRE: 'Gratallops',
	},
	{
		CPRO: '43',
		CMUN: '070',
		DC: '5',
		NOMBRE: 'Guiamets, Els',
	},
	{
		CPRO: '43',
		CMUN: '071',
		DC: '2',
		NOMBRE: 'Horta de Sant Joan',
	},
	{
		CPRO: '43',
		CMUN: '072',
		DC: '7',
		NOMBRE: 'Lloar, El',
	},
	{
		CPRO: '43',
		CMUN: '073',
		DC: '3',
		NOMBRE: 'Llorac',
	},
	{
		CPRO: '43',
		CMUN: '074',
		DC: '8',
		NOMBRE: 'Llorenç del Penedès',
	},
	{
		CPRO: '43',
		CMUN: '076',
		DC: '4',
		NOMBRE: 'Marçà',
	},
	{
		CPRO: '43',
		CMUN: '075',
		DC: '1',
		NOMBRE: 'Margalef',
	},
	{
		CPRO: '43',
		CMUN: '077',
		DC: '0',
		NOMBRE: 'Mas de Barberans',
	},
	{
		CPRO: '43',
		CMUN: '078',
		DC: '6',
		NOMBRE: 'Masdenverge',
	},
	{
		CPRO: '43',
		CMUN: '079',
		DC: '9',
		NOMBRE: 'Masllorenç',
	},
	{
		CPRO: '43',
		CMUN: '080',
		DC: '3',
		NOMBRE: 'Masó, La',
	},
	{
		CPRO: '43',
		CMUN: '081',
		DC: '0',
		NOMBRE: 'Maspujols',
	},
	{
		CPRO: '43',
		CMUN: '082',
		DC: '5',
		NOMBRE: 'Masroig, El',
	},
	{
		CPRO: '43',
		CMUN: '083',
		DC: '1',
		NOMBRE: 'Milà, El',
	},
	{
		CPRO: '43',
		CMUN: '084',
		DC: '6',
		NOMBRE: 'Miravet',
	},
	{
		CPRO: '43',
		CMUN: '085',
		DC: '9',
		NOMBRE: 'Molar, El',
	},
	{
		CPRO: '43',
		CMUN: '086',
		DC: '2',
		NOMBRE: 'Montblanc',
	},
	{
		CPRO: '43',
		CMUN: '088',
		DC: '4',
		NOMBRE: 'Montbrió del Camp',
	},
	{
		CPRO: '43',
		CMUN: '089',
		DC: '7',
		NOMBRE: 'Montferri',
	},
	{
		CPRO: '43',
		CMUN: '090',
		DC: '1',
		NOMBRE: 'Montmell, El',
	},
	{
		CPRO: '43',
		CMUN: '091',
		DC: '8',
		NOMBRE: 'Mont-ral',
	},
	{
		CPRO: '43',
		CMUN: '092',
		DC: '3',
		NOMBRE: 'Mont-roig del Camp',
	},
	{
		CPRO: '43',
		CMUN: '093',
		DC: '9',
		NOMBRE: "Móra d'Ebre",
	},
	{
		CPRO: '43',
		CMUN: '094',
		DC: '4',
		NOMBRE: 'Móra la Nova',
	},
	{
		CPRO: '43',
		CMUN: '095',
		DC: '7',
		NOMBRE: 'Morell, El',
	},
	{
		CPRO: '43',
		CMUN: '096',
		DC: '0',
		NOMBRE: 'Morera de Montsant, La',
	},
	{
		CPRO: '43',
		CMUN: '097',
		DC: '6',
		NOMBRE: 'Nou de Gaià, La',
	},
	{
		CPRO: '43',
		CMUN: '098',
		DC: '2',
		NOMBRE: 'Nulles',
	},
	{
		CPRO: '43',
		CMUN: '100',
		DC: '9',
		NOMBRE: 'Pallaresos, Els',
	},
	{
		CPRO: '43',
		CMUN: '099',
		DC: '5',
		NOMBRE: "Palma d'Ebre, La",
	},
	{
		CPRO: '43',
		CMUN: '101',
		DC: '6',
		NOMBRE: 'Passanant i Belltall',
	},
	{
		CPRO: '43',
		CMUN: '102',
		DC: '1',
		NOMBRE: 'Paüls',
	},
	{
		CPRO: '43',
		CMUN: '103',
		DC: '7',
		NOMBRE: 'Perafort',
	},
	{
		CPRO: '43',
		CMUN: '104',
		DC: '2',
		NOMBRE: 'Perelló, El',
	},
	{
		CPRO: '43',
		CMUN: '105',
		DC: '5',
		NOMBRE: 'Piles, Les',
	},
	{
		CPRO: '43',
		CMUN: '106',
		DC: '8',
		NOMBRE: 'Pinell de Brai, El',
	},
	{
		CPRO: '43',
		CMUN: '107',
		DC: '4',
		NOMBRE: 'Pira',
	},
	{
		CPRO: '43',
		CMUN: '108',
		DC: '0',
		NOMBRE: 'Pla de Santa Maria, El',
	},
	{
		CPRO: '43',
		CMUN: '109',
		DC: '3',
		NOMBRE: 'Pobla de Mafumet, La',
	},
	{
		CPRO: '43',
		CMUN: '110',
		DC: '7',
		NOMBRE: 'Pobla de Massaluca, La',
	},
	{
		CPRO: '43',
		CMUN: '111',
		DC: '4',
		NOMBRE: 'Pobla de Montornès, La',
	},
	{
		CPRO: '43',
		CMUN: '112',
		DC: '9',
		NOMBRE: 'Poboleda',
	},
	{
		CPRO: '43',
		CMUN: '113',
		DC: '5',
		NOMBRE: "Pont d'Armentera, El",
	},
	{
		CPRO: '43',
		CMUN: '141',
		DC: '8',
		NOMBRE: 'Pontils',
	},
	{
		CPRO: '43',
		CMUN: '114',
		DC: '0',
		NOMBRE: 'Porrera',
	},
	{
		CPRO: '43',
		CMUN: '115',
		DC: '3',
		NOMBRE: 'Pradell de la Teixeta',
	},
	{
		CPRO: '43',
		CMUN: '116',
		DC: '6',
		NOMBRE: 'Prades',
	},
	{
		CPRO: '43',
		CMUN: '117',
		DC: '2',
		NOMBRE: 'Prat de Comte',
	},
	{
		CPRO: '43',
		CMUN: '118',
		DC: '8',
		NOMBRE: 'Pratdip',
	},
	{
		CPRO: '43',
		CMUN: '119',
		DC: '1',
		NOMBRE: 'Puigpelat',
	},
	{
		CPRO: '43',
		CMUN: '120',
		DC: '5',
		NOMBRE: 'Querol',
	},
	{
		CPRO: '43',
		CMUN: '121',
		DC: '2',
		NOMBRE: 'Rasquera',
	},
	{
		CPRO: '43',
		CMUN: '122',
		DC: '7',
		NOMBRE: 'Renau',
	},
	{
		CPRO: '43',
		CMUN: '123',
		DC: '3',
		NOMBRE: 'Reus',
	},
	{
		CPRO: '43',
		CMUN: '124',
		DC: '8',
		NOMBRE: 'Riba, La',
	},
	{
		CPRO: '43',
		CMUN: '125',
		DC: '1',
		NOMBRE: "Riba-roja d'Ebre",
	},
	{
		CPRO: '43',
		CMUN: '126',
		DC: '4',
		NOMBRE: 'Riera de Gaià, La',
	},
	{
		CPRO: '43',
		CMUN: '127',
		DC: '0',
		NOMBRE: 'Riudecanyes',
	},
	{
		CPRO: '43',
		CMUN: '128',
		DC: '6',
		NOMBRE: 'Riudecols',
	},
	{
		CPRO: '43',
		CMUN: '129',
		DC: '9',
		NOMBRE: 'Riudoms',
	},
	{
		CPRO: '43',
		CMUN: '130',
		DC: '3',
		NOMBRE: 'Rocafort de Queralt',
	},
	{
		CPRO: '43',
		CMUN: '131',
		DC: '0',
		NOMBRE: 'Roda de Barà',
	},
	{
		CPRO: '43',
		CMUN: '132',
		DC: '5',
		NOMBRE: 'Rodonyà',
	},
	{
		CPRO: '43',
		CMUN: '133',
		DC: '1',
		NOMBRE: 'Roquetes',
	},
	{
		CPRO: '43',
		CMUN: '134',
		DC: '6',
		NOMBRE: 'Rourell, El',
	},
	{
		CPRO: '43',
		CMUN: '135',
		DC: '9',
		NOMBRE: 'Salomó',
	},
	{
		CPRO: '43',
		CMUN: '905',
		DC: '7',
		NOMBRE: 'Salou',
	},
	{
		CPRO: '43',
		CMUN: '136',
		DC: '2',
		NOMBRE: 'Sant Carles de la Ràpita',
	},
	{
		CPRO: '43',
		CMUN: '137',
		DC: '8',
		NOMBRE: 'Sant Jaume dels Domenys',
	},
	{
		CPRO: '43',
		CMUN: '902',
		DC: '3',
		NOMBRE: "Sant Jaume d'Enveja",
	},
	{
		CPRO: '43',
		CMUN: '138',
		DC: '4',
		NOMBRE: 'Santa Bàrbara',
	},
	{
		CPRO: '43',
		CMUN: '139',
		DC: '7',
		NOMBRE: 'Santa Coloma de Queralt',
	},
	{
		CPRO: '43',
		CMUN: '140',
		DC: '1',
		NOMBRE: 'Santa Oliva',
	},
	{
		CPRO: '43',
		CMUN: '142',
		DC: '3',
		NOMBRE: 'Sarral',
	},
	{
		CPRO: '43',
		CMUN: '143',
		DC: '9',
		NOMBRE: 'Savallà del Comtat',
	},
	{
		CPRO: '43',
		CMUN: '144',
		DC: '4',
		NOMBRE: 'Secuita, La',
	},
	{
		CPRO: '43',
		CMUN: '145',
		DC: '7',
		NOMBRE: 'Selva del Camp, La',
	},
	{
		CPRO: '43',
		CMUN: '146',
		DC: '0',
		NOMBRE: 'Senan',
	},
	{
		CPRO: '43',
		CMUN: '044',
		DC: '5',
		NOMBRE: 'Sénia, La',
	},
	{
		CPRO: '43',
		CMUN: '147',
		DC: '6',
		NOMBRE: 'Solivella',
	},
	{
		CPRO: '43',
		CMUN: '148',
		DC: '2',
		NOMBRE: 'Tarragona',
	},
	{
		CPRO: '43',
		CMUN: '149',
		DC: '5',
		NOMBRE: 'Tivenys',
	},
	{
		CPRO: '43',
		CMUN: '150',
		DC: '8',
		NOMBRE: 'Tivissa',
	},
	{
		CPRO: '43',
		CMUN: '151',
		DC: '5',
		NOMBRE: 'Torre de Fontaubella, La',
	},
	{
		CPRO: '43',
		CMUN: '152',
		DC: '0',
		NOMBRE: "Torre de l'Espanyol, La",
	},
	{
		CPRO: '43',
		CMUN: '153',
		DC: '6',
		NOMBRE: 'Torredembarra',
	},
	{
		CPRO: '43',
		CMUN: '154',
		DC: '1',
		NOMBRE: 'Torroja del Priorat',
	},
	{
		CPRO: '43',
		CMUN: '155',
		DC: '4',
		NOMBRE: 'Tortosa',
	},
	{
		CPRO: '43',
		CMUN: '156',
		DC: '7',
		NOMBRE: 'Ulldecona',
	},
	{
		CPRO: '43',
		CMUN: '157',
		DC: '3',
		NOMBRE: 'Ulldemolins',
	},
	{
		CPRO: '43',
		CMUN: '158',
		DC: '9',
		NOMBRE: 'Vallclara',
	},
	{
		CPRO: '43',
		CMUN: '159',
		DC: '2',
		NOMBRE: 'Vallfogona de Riucorb',
	},
	{
		CPRO: '43',
		CMUN: '160',
		DC: '6',
		NOMBRE: 'Vallmoll',
	},
	{
		CPRO: '43',
		CMUN: '161',
		DC: '3',
		NOMBRE: 'Valls',
	},
	{
		CPRO: '43',
		CMUN: '162',
		DC: '8',
		NOMBRE: "Vandellòs i l'Hospitalet de l'Infant",
	},
	{
		CPRO: '43',
		CMUN: '163',
		DC: '4',
		NOMBRE: 'Vendrell, El',
	},
	{
		CPRO: '43',
		CMUN: '164',
		DC: '9',
		NOMBRE: 'Vespella de Gaià',
	},
	{
		CPRO: '43',
		CMUN: '165',
		DC: '2',
		NOMBRE: 'Vilabella',
	},
	{
		CPRO: '43',
		CMUN: '175',
		DC: '0',
		NOMBRE: 'Vilalba dels Arcs',
	},
	{
		CPRO: '43',
		CMUN: '166',
		DC: '5',
		NOMBRE: 'Vilallonga del Camp',
	},
	{
		CPRO: '43',
		CMUN: '168',
		DC: '7',
		NOMBRE: 'Vilanova de Prades',
	},
	{
		CPRO: '43',
		CMUN: '167',
		DC: '1',
		NOMBRE: "Vilanova d'Escornalbou",
	},
	{
		CPRO: '43',
		CMUN: '169',
		DC: '0',
		NOMBRE: 'Vilaplana',
	},
	{
		CPRO: '43',
		CMUN: '170',
		DC: '4',
		NOMBRE: 'Vila-rodona',
	},
	{
		CPRO: '43',
		CMUN: '171',
		DC: '1',
		NOMBRE: 'Vila-seca',
	},
	{
		CPRO: '43',
		CMUN: '172',
		DC: '6',
		NOMBRE: 'Vilaverd',
	},
	{
		CPRO: '43',
		CMUN: '173',
		DC: '2',
		NOMBRE: 'Vilella Alta, La',
	},
	{
		CPRO: '43',
		CMUN: '174',
		DC: '7',
		NOMBRE: 'Vilella Baixa, La',
	},
	{
		CPRO: '43',
		CMUN: '176',
		DC: '3',
		NOMBRE: 'Vimbodí i Poblet',
	},
	{
		CPRO: '43',
		CMUN: '177',
		DC: '9',
		NOMBRE: 'Vinebre',
	},
	{
		CPRO: '43',
		CMUN: '178',
		DC: '5',
		NOMBRE: 'Vinyols i els Arcs',
	},
	{
		CPRO: '43',
		CMUN: '052',
		DC: '1',
		NOMBRE: 'Xerta',
	},
	{
		CPRO: '44',
		CMUN: '001',
		DC: '2',
		NOMBRE: 'Ababuj',
	},
	{
		CPRO: '44',
		CMUN: '002',
		DC: '7',
		NOMBRE: 'Abejuela',
	},
	{
		CPRO: '44',
		CMUN: '003',
		DC: '3',
		NOMBRE: 'Aguatón',
	},
	{
		CPRO: '44',
		CMUN: '004',
		DC: '8',
		NOMBRE: 'Aguaviva',
	},
	{
		CPRO: '44',
		CMUN: '005',
		DC: '1',
		NOMBRE: 'Aguilar del Alfambra',
	},
	{
		CPRO: '44',
		CMUN: '006',
		DC: '4',
		NOMBRE: 'Alacón',
	},
	{
		CPRO: '44',
		CMUN: '007',
		DC: '0',
		NOMBRE: 'Alba',
	},
	{
		CPRO: '44',
		CMUN: '008',
		DC: '6',
		NOMBRE: 'Albalate del Arzobispo',
	},
	{
		CPRO: '44',
		CMUN: '009',
		DC: '9',
		NOMBRE: 'Albarracín',
	},
	{
		CPRO: '44',
		CMUN: '010',
		DC: '3',
		NOMBRE: 'Albentosa',
	},
	{
		CPRO: '44',
		CMUN: '011',
		DC: '0',
		NOMBRE: 'Alcaine',
	},
	{
		CPRO: '44',
		CMUN: '012',
		DC: '5',
		NOMBRE: 'Alcalá de la Selva',
	},
	{
		CPRO: '44',
		CMUN: '013',
		DC: '1',
		NOMBRE: 'Alcañiz',
	},
	{
		CPRO: '44',
		CMUN: '014',
		DC: '6',
		NOMBRE: 'Alcorisa',
	},
	{
		CPRO: '44',
		CMUN: '016',
		DC: '2',
		NOMBRE: 'Alfambra',
	},
	{
		CPRO: '44',
		CMUN: '017',
		DC: '8',
		NOMBRE: 'Aliaga',
	},
	{
		CPRO: '44',
		CMUN: '021',
		DC: '8',
		NOMBRE: 'Allepuz',
	},
	{
		CPRO: '44',
		CMUN: '022',
		DC: '3',
		NOMBRE: 'Alloza',
	},
	{
		CPRO: '44',
		CMUN: '023',
		DC: '9',
		NOMBRE: 'Allueva',
	},
	{
		CPRO: '44',
		CMUN: '018',
		DC: '4',
		NOMBRE: 'Almohaja',
	},
	{
		CPRO: '44',
		CMUN: '019',
		DC: '7',
		NOMBRE: 'Alobras',
	},
	{
		CPRO: '44',
		CMUN: '020',
		DC: '1',
		NOMBRE: 'Alpeñés',
	},
	{
		CPRO: '44',
		CMUN: '024',
		DC: '4',
		NOMBRE: 'Anadón',
	},
	{
		CPRO: '44',
		CMUN: '025',
		DC: '7',
		NOMBRE: 'Andorra',
	},
	{
		CPRO: '44',
		CMUN: '026',
		DC: '0',
		NOMBRE: 'Arcos de las Salinas',
	},
	{
		CPRO: '44',
		CMUN: '027',
		DC: '6',
		NOMBRE: 'Arens de Lledó',
	},
	{
		CPRO: '44',
		CMUN: '028',
		DC: '2',
		NOMBRE: 'Argente',
	},
	{
		CPRO: '44',
		CMUN: '029',
		DC: '5',
		NOMBRE: 'Ariño',
	},
	{
		CPRO: '44',
		CMUN: '031',
		DC: '6',
		NOMBRE: 'Azaila',
	},
	{
		CPRO: '44',
		CMUN: '032',
		DC: '1',
		NOMBRE: 'Bádenas',
	},
	{
		CPRO: '44',
		CMUN: '033',
		DC: '7',
		NOMBRE: 'Báguena',
	},
	{
		CPRO: '44',
		CMUN: '034',
		DC: '2',
		NOMBRE: 'Bañón',
	},
	{
		CPRO: '44',
		CMUN: '035',
		DC: '5',
		NOMBRE: 'Barrachina',
	},
	{
		CPRO: '44',
		CMUN: '036',
		DC: '8',
		NOMBRE: 'Bea',
	},
	{
		CPRO: '44',
		CMUN: '037',
		DC: '4',
		NOMBRE: 'Beceite',
	},
	{
		CPRO: '44',
		CMUN: '039',
		DC: '3',
		NOMBRE: 'Bello',
	},
	{
		CPRO: '44',
		CMUN: '038',
		DC: '0',
		NOMBRE: 'Belmonte de San José',
	},
	{
		CPRO: '44',
		CMUN: '040',
		DC: '7',
		NOMBRE: 'Berge',
	},
	{
		CPRO: '44',
		CMUN: '041',
		DC: '4',
		NOMBRE: 'Bezas',
	},
	{
		CPRO: '44',
		CMUN: '042',
		DC: '9',
		NOMBRE: 'Blancas',
	},
	{
		CPRO: '44',
		CMUN: '043',
		DC: '5',
		NOMBRE: 'Blesa',
	},
	{
		CPRO: '44',
		CMUN: '044',
		DC: '0',
		NOMBRE: 'Bordón',
	},
	{
		CPRO: '44',
		CMUN: '045',
		DC: '3',
		NOMBRE: 'Bronchales',
	},
	{
		CPRO: '44',
		CMUN: '046',
		DC: '6',
		NOMBRE: 'Bueña',
	},
	{
		CPRO: '44',
		CMUN: '047',
		DC: '2',
		NOMBRE: 'Burbáguena',
	},
	{
		CPRO: '44',
		CMUN: '048',
		DC: '8',
		NOMBRE: 'Cabra de Mora',
	},
	{
		CPRO: '44',
		CMUN: '049',
		DC: '1',
		NOMBRE: 'Calaceite',
	},
	{
		CPRO: '44',
		CMUN: '050',
		DC: '4',
		NOMBRE: 'Calamocha',
	},
	{
		CPRO: '44',
		CMUN: '051',
		DC: '1',
		NOMBRE: 'Calanda',
	},
	{
		CPRO: '44',
		CMUN: '052',
		DC: '6',
		NOMBRE: 'Calomarde',
	},
	{
		CPRO: '44',
		CMUN: '053',
		DC: '2',
		NOMBRE: 'Camañas',
	},
	{
		CPRO: '44',
		CMUN: '054',
		DC: '7',
		NOMBRE: 'Camarena de la Sierra',
	},
	{
		CPRO: '44',
		CMUN: '055',
		DC: '0',
		NOMBRE: 'Camarillas',
	},
	{
		CPRO: '44',
		CMUN: '056',
		DC: '3',
		NOMBRE: 'Caminreal',
	},
	{
		CPRO: '44',
		CMUN: '059',
		DC: '8',
		NOMBRE: 'Cantavieja',
	},
	{
		CPRO: '44',
		CMUN: '060',
		DC: '2',
		NOMBRE: 'Cañada de Benatanduz',
	},
	{
		CPRO: '44',
		CMUN: '061',
		DC: '9',
		NOMBRE: 'Cañada de Verich, La',
	},
	{
		CPRO: '44',
		CMUN: '062',
		DC: '4',
		NOMBRE: 'Cañada Vellida',
	},
	{
		CPRO: '44',
		CMUN: '063',
		DC: '0',
		NOMBRE: 'Cañizar del Olivar',
	},
	{
		CPRO: '44',
		CMUN: '064',
		DC: '5',
		NOMBRE: 'Cascante del Río',
	},
	{
		CPRO: '44',
		CMUN: '065',
		DC: '8',
		NOMBRE: 'Castejón de Tornos',
	},
	{
		CPRO: '44',
		CMUN: '066',
		DC: '1',
		NOMBRE: 'Castel de Cabra',
	},
	{
		CPRO: '44',
		CMUN: '070',
		DC: '0',
		NOMBRE: 'Castellar, El',
	},
	{
		CPRO: '44',
		CMUN: '071',
		DC: '7',
		NOMBRE: 'Castellote',
	},
	{
		CPRO: '44',
		CMUN: '067',
		DC: '7',
		NOMBRE: 'Castelnou',
	},
	{
		CPRO: '44',
		CMUN: '068',
		DC: '3',
		NOMBRE: 'Castelserás',
	},
	{
		CPRO: '44',
		CMUN: '074',
		DC: '3',
		NOMBRE: 'Cedrillas',
	},
	{
		CPRO: '44',
		CMUN: '075',
		DC: '6',
		NOMBRE: 'Celadas',
	},
	{
		CPRO: '44',
		CMUN: '076',
		DC: '9',
		NOMBRE: 'Cella',
	},
	{
		CPRO: '44',
		CMUN: '077',
		DC: '5',
		NOMBRE: 'Cerollera, La',
	},
	{
		CPRO: '44',
		CMUN: '080',
		DC: '8',
		NOMBRE: 'Codoñera, La',
	},
	{
		CPRO: '44',
		CMUN: '082',
		DC: '0',
		NOMBRE: 'Corbalán',
	},
	{
		CPRO: '44',
		CMUN: '084',
		DC: '1',
		NOMBRE: 'Cortes de Aragón',
	},
	{
		CPRO: '44',
		CMUN: '085',
		DC: '4',
		NOMBRE: 'Cosa',
	},
	{
		CPRO: '44',
		CMUN: '086',
		DC: '7',
		NOMBRE: 'Cretas',
	},
	{
		CPRO: '44',
		CMUN: '087',
		DC: '3',
		NOMBRE: 'Crivillén',
	},
	{
		CPRO: '44',
		CMUN: '088',
		DC: '9',
		NOMBRE: 'Cuba, La',
	},
	{
		CPRO: '44',
		CMUN: '089',
		DC: '2',
		NOMBRE: 'Cubla',
	},
	{
		CPRO: '44',
		CMUN: '090',
		DC: '6',
		NOMBRE: 'Cucalón',
	},
	{
		CPRO: '44',
		CMUN: '092',
		DC: '8',
		NOMBRE: 'Cuervo, El',
	},
	{
		CPRO: '44',
		CMUN: '093',
		DC: '4',
		NOMBRE: 'Cuevas de Almudén',
	},
	{
		CPRO: '44',
		CMUN: '094',
		DC: '9',
		NOMBRE: 'Cuevas Labradas',
	},
	{
		CPRO: '44',
		CMUN: '096',
		DC: '5',
		NOMBRE: 'Ejulve',
	},
	{
		CPRO: '44',
		CMUN: '097',
		DC: '1',
		NOMBRE: 'Escorihuela',
	},
	{
		CPRO: '44',
		CMUN: '099',
		DC: '0',
		NOMBRE: 'Escucha',
	},
	{
		CPRO: '44',
		CMUN: '100',
		DC: '4',
		NOMBRE: 'Estercuel',
	},
	{
		CPRO: '44',
		CMUN: '101',
		DC: '1',
		NOMBRE: 'Ferreruela de Huerva',
	},
	{
		CPRO: '44',
		CMUN: '102',
		DC: '6',
		NOMBRE: 'Fonfría',
	},
	{
		CPRO: '44',
		CMUN: '103',
		DC: '2',
		NOMBRE: 'Formiche Alto',
	},
	{
		CPRO: '44',
		CMUN: '105',
		DC: '0',
		NOMBRE: 'Fórnoles',
	},
	{
		CPRO: '44',
		CMUN: '106',
		DC: '3',
		NOMBRE: 'Fortanete',
	},
	{
		CPRO: '44',
		CMUN: '107',
		DC: '9',
		NOMBRE: 'Foz-Calanda',
	},
	{
		CPRO: '44',
		CMUN: '108',
		DC: '5',
		NOMBRE: 'Fresneda, La',
	},
	{
		CPRO: '44',
		CMUN: '109',
		DC: '8',
		NOMBRE: 'Frías de Albarracín',
	},
	{
		CPRO: '44',
		CMUN: '110',
		DC: '2',
		NOMBRE: 'Fuenferrada',
	},
	{
		CPRO: '44',
		CMUN: '111',
		DC: '9',
		NOMBRE: 'Fuentes Calientes',
	},
	{
		CPRO: '44',
		CMUN: '112',
		DC: '4',
		NOMBRE: 'Fuentes Claras',
	},
	{
		CPRO: '44',
		CMUN: '113',
		DC: '0',
		NOMBRE: 'Fuentes de Rubielos',
	},
	{
		CPRO: '44',
		CMUN: '114',
		DC: '5',
		NOMBRE: 'Fuentespalda',
	},
	{
		CPRO: '44',
		CMUN: '115',
		DC: '8',
		NOMBRE: 'Galve',
	},
	{
		CPRO: '44',
		CMUN: '116',
		DC: '1',
		NOMBRE: 'Gargallo',
	},
	{
		CPRO: '44',
		CMUN: '117',
		DC: '7',
		NOMBRE: 'Gea de Albarracín',
	},
	{
		CPRO: '44',
		CMUN: '118',
		DC: '3',
		NOMBRE: 'Ginebrosa, La',
	},
	{
		CPRO: '44',
		CMUN: '119',
		DC: '6',
		NOMBRE: 'Griegos',
	},
	{
		CPRO: '44',
		CMUN: '120',
		DC: '0',
		NOMBRE: 'Guadalaviar',
	},
	{
		CPRO: '44',
		CMUN: '121',
		DC: '7',
		NOMBRE: 'Gúdar',
	},
	{
		CPRO: '44',
		CMUN: '122',
		DC: '2',
		NOMBRE: 'Híjar',
	},
	{
		CPRO: '44',
		CMUN: '123',
		DC: '8',
		NOMBRE: 'Hinojosa de Jarque',
	},
	{
		CPRO: '44',
		CMUN: '124',
		DC: '3',
		NOMBRE: 'Hoz de la Vieja, La',
	},
	{
		CPRO: '44',
		CMUN: '125',
		DC: '6',
		NOMBRE: 'Huesa del Común',
	},
	{
		CPRO: '44',
		CMUN: '126',
		DC: '9',
		NOMBRE: 'Iglesuela del Cid, La',
	},
	{
		CPRO: '44',
		CMUN: '127',
		DC: '5',
		NOMBRE: 'Jabaloyas',
	},
	{
		CPRO: '44',
		CMUN: '128',
		DC: '1',
		NOMBRE: 'Jarque de la Val',
	},
	{
		CPRO: '44',
		CMUN: '129',
		DC: '4',
		NOMBRE: 'Jatiel',
	},
	{
		CPRO: '44',
		CMUN: '130',
		DC: '8',
		NOMBRE: 'Jorcas',
	},
	{
		CPRO: '44',
		CMUN: '131',
		DC: '5',
		NOMBRE: 'Josa',
	},
	{
		CPRO: '44',
		CMUN: '132',
		DC: '0',
		NOMBRE: 'Lagueruela',
	},
	{
		CPRO: '44',
		CMUN: '133',
		DC: '6',
		NOMBRE: 'Lanzuela',
	},
	{
		CPRO: '44',
		CMUN: '135',
		DC: '4',
		NOMBRE: 'Libros',
	},
	{
		CPRO: '44',
		CMUN: '136',
		DC: '7',
		NOMBRE: 'Lidón',
	},
	{
		CPRO: '44',
		CMUN: '137',
		DC: '3',
		NOMBRE: 'Linares de Mora',
	},
	{
		CPRO: '44',
		CMUN: '141',
		DC: '3',
		NOMBRE: 'Lledó',
	},
	{
		CPRO: '44',
		CMUN: '138',
		DC: '9',
		NOMBRE: 'Loscos',
	},
	{
		CPRO: '44',
		CMUN: '142',
		DC: '8',
		NOMBRE: 'Maicas',
	},
	{
		CPRO: '44',
		CMUN: '143',
		DC: '4',
		NOMBRE: 'Manzanera',
	},
	{
		CPRO: '44',
		CMUN: '144',
		DC: '9',
		NOMBRE: 'Martín del Río',
	},
	{
		CPRO: '44',
		CMUN: '145',
		DC: '2',
		NOMBRE: 'Mas de las Matas',
	},
	{
		CPRO: '44',
		CMUN: '146',
		DC: '5',
		NOMBRE: 'Mata de los Olmos, La',
	},
	{
		CPRO: '44',
		CMUN: '147',
		DC: '1',
		NOMBRE: 'Mazaleón',
	},
	{
		CPRO: '44',
		CMUN: '148',
		DC: '7',
		NOMBRE: 'Mezquita de Jarque',
	},
	{
		CPRO: '44',
		CMUN: '149',
		DC: '0',
		NOMBRE: 'Mirambel',
	},
	{
		CPRO: '44',
		CMUN: '150',
		DC: '3',
		NOMBRE: 'Miravete de la Sierra',
	},
	{
		CPRO: '44',
		CMUN: '151',
		DC: '0',
		NOMBRE: 'Molinos',
	},
	{
		CPRO: '44',
		CMUN: '152',
		DC: '5',
		NOMBRE: 'Monforte de Moyuela',
	},
	{
		CPRO: '44',
		CMUN: '153',
		DC: '1',
		NOMBRE: 'Monreal del Campo',
	},
	{
		CPRO: '44',
		CMUN: '154',
		DC: '6',
		NOMBRE: 'Monroyo',
	},
	{
		CPRO: '44',
		CMUN: '155',
		DC: '9',
		NOMBRE: 'Montalbán',
	},
	{
		CPRO: '44',
		CMUN: '156',
		DC: '2',
		NOMBRE: 'Monteagudo del Castillo',
	},
	{
		CPRO: '44',
		CMUN: '157',
		DC: '8',
		NOMBRE: 'Monterde de Albarracín',
	},
	{
		CPRO: '44',
		CMUN: '158',
		DC: '4',
		NOMBRE: 'Mora de Rubielos',
	},
	{
		CPRO: '44',
		CMUN: '159',
		DC: '7',
		NOMBRE: 'Moscardón',
	},
	{
		CPRO: '44',
		CMUN: '160',
		DC: '1',
		NOMBRE: 'Mosqueruela',
	},
	{
		CPRO: '44',
		CMUN: '161',
		DC: '8',
		NOMBRE: 'Muniesa',
	},
	{
		CPRO: '44',
		CMUN: '163',
		DC: '9',
		NOMBRE: 'Noguera de Albarracín',
	},
	{
		CPRO: '44',
		CMUN: '164',
		DC: '4',
		NOMBRE: 'Nogueras',
	},
	{
		CPRO: '44',
		CMUN: '165',
		DC: '7',
		NOMBRE: 'Nogueruelas',
	},
	{
		CPRO: '44',
		CMUN: '167',
		DC: '6',
		NOMBRE: 'Obón',
	},
	{
		CPRO: '44',
		CMUN: '168',
		DC: '2',
		NOMBRE: 'Odón',
	},
	{
		CPRO: '44',
		CMUN: '169',
		DC: '5',
		NOMBRE: 'Ojos Negros',
	},
	{
		CPRO: '44',
		CMUN: '171',
		DC: '6',
		NOMBRE: 'Olba',
	},
	{
		CPRO: '44',
		CMUN: '172',
		DC: '1',
		NOMBRE: 'Oliete',
	},
	{
		CPRO: '44',
		CMUN: '173',
		DC: '7',
		NOMBRE: 'Olmos, Los',
	},
	{
		CPRO: '44',
		CMUN: '174',
		DC: '2',
		NOMBRE: 'Orihuela del Tremedal',
	},
	{
		CPRO: '44',
		CMUN: '175',
		DC: '5',
		NOMBRE: 'Orrios',
	},
	{
		CPRO: '44',
		CMUN: '176',
		DC: '8',
		NOMBRE: 'Palomar de Arroyos',
	},
	{
		CPRO: '44',
		CMUN: '177',
		DC: '4',
		NOMBRE: 'Pancrudo',
	},
	{
		CPRO: '44',
		CMUN: '178',
		DC: '0',
		NOMBRE: 'Parras de Castellote, Las',
	},
	{
		CPRO: '44',
		CMUN: '179',
		DC: '3',
		NOMBRE: 'Peñarroya de Tastavins',
	},
	{
		CPRO: '44',
		CMUN: '180',
		DC: '7',
		NOMBRE: 'Peracense',
	},
	{
		CPRO: '44',
		CMUN: '181',
		DC: '4',
		NOMBRE: 'Peralejos',
	},
	{
		CPRO: '44',
		CMUN: '182',
		DC: '9',
		NOMBRE: 'Perales del Alfambra',
	},
	{
		CPRO: '44',
		CMUN: '183',
		DC: '5',
		NOMBRE: 'Pitarque',
	},
	{
		CPRO: '44',
		CMUN: '184',
		DC: '0',
		NOMBRE: 'Plou',
	},
	{
		CPRO: '44',
		CMUN: '185',
		DC: '3',
		NOMBRE: 'Pobo, El',
	},
	{
		CPRO: '44',
		CMUN: '187',
		DC: '2',
		NOMBRE: 'Portellada, La',
	},
	{
		CPRO: '44',
		CMUN: '189',
		DC: '1',
		NOMBRE: 'Pozondón',
	},
	{
		CPRO: '44',
		CMUN: '190',
		DC: '5',
		NOMBRE: 'Pozuel del Campo',
	},
	{
		CPRO: '44',
		CMUN: '191',
		DC: '2',
		NOMBRE: 'Puebla de Híjar, La',
	},
	{
		CPRO: '44',
		CMUN: '192',
		DC: '7',
		NOMBRE: 'Puebla de Valverde, La',
	},
	{
		CPRO: '44',
		CMUN: '193',
		DC: '3',
		NOMBRE: 'Puertomingalvo',
	},
	{
		CPRO: '44',
		CMUN: '194',
		DC: '8',
		NOMBRE: 'Ráfales',
	},
	{
		CPRO: '44',
		CMUN: '195',
		DC: '1',
		NOMBRE: 'Rillo',
	},
	{
		CPRO: '44',
		CMUN: '196',
		DC: '4',
		NOMBRE: 'Riodeva',
	},
	{
		CPRO: '44',
		CMUN: '197',
		DC: '0',
		NOMBRE: 'Ródenas',
	},
	{
		CPRO: '44',
		CMUN: '198',
		DC: '6',
		NOMBRE: 'Royuela',
	},
	{
		CPRO: '44',
		CMUN: '199',
		DC: '9',
		NOMBRE: 'Rubiales',
	},
	{
		CPRO: '44',
		CMUN: '200',
		DC: '3',
		NOMBRE: 'Rubielos de la Cérida',
	},
	{
		CPRO: '44',
		CMUN: '201',
		DC: '0',
		NOMBRE: 'Rubielos de Mora',
	},
	{
		CPRO: '44',
		CMUN: '203',
		DC: '1',
		NOMBRE: 'Salcedillo',
	},
	{
		CPRO: '44',
		CMUN: '204',
		DC: '6',
		NOMBRE: 'Saldón',
	},
	{
		CPRO: '44',
		CMUN: '205',
		DC: '9',
		NOMBRE: 'Samper de Calanda',
	},
	{
		CPRO: '44',
		CMUN: '206',
		DC: '2',
		NOMBRE: 'San Agustín',
	},
	{
		CPRO: '44',
		CMUN: '207',
		DC: '8',
		NOMBRE: 'San Martín del Río',
	},
	{
		CPRO: '44',
		CMUN: '208',
		DC: '4',
		NOMBRE: 'Santa Cruz de Nogueras',
	},
	{
		CPRO: '44',
		CMUN: '209',
		DC: '7',
		NOMBRE: 'Santa Eulalia',
	},
	{
		CPRO: '44',
		CMUN: '210',
		DC: '1',
		NOMBRE: 'Sarrión',
	},
	{
		CPRO: '44',
		CMUN: '211',
		DC: '8',
		NOMBRE: 'Segura de los Baños',
	},
	{
		CPRO: '44',
		CMUN: '212',
		DC: '3',
		NOMBRE: 'Seno',
	},
	{
		CPRO: '44',
		CMUN: '213',
		DC: '9',
		NOMBRE: 'Singra',
	},
	{
		CPRO: '44',
		CMUN: '215',
		DC: '7',
		NOMBRE: 'Terriente',
	},
	{
		CPRO: '44',
		CMUN: '216',
		DC: '0',
		NOMBRE: 'Teruel',
	},
	{
		CPRO: '44',
		CMUN: '217',
		DC: '6',
		NOMBRE: 'Toril y Masegoso',
	},
	{
		CPRO: '44',
		CMUN: '218',
		DC: '2',
		NOMBRE: 'Tormón',
	},
	{
		CPRO: '44',
		CMUN: '219',
		DC: '5',
		NOMBRE: 'Tornos',
	},
	{
		CPRO: '44',
		CMUN: '220',
		DC: '9',
		NOMBRE: 'Torralba de los Sisones',
	},
	{
		CPRO: '44',
		CMUN: '223',
		DC: '7',
		NOMBRE: 'Torre de Arcas',
	},
	{
		CPRO: '44',
		CMUN: '224',
		DC: '2',
		NOMBRE: 'Torre de las Arcas',
	},
	{
		CPRO: '44',
		CMUN: '225',
		DC: '5',
		NOMBRE: 'Torre del Compte',
	},
	{
		CPRO: '44',
		CMUN: '227',
		DC: '4',
		NOMBRE: 'Torre los Negros',
	},
	{
		CPRO: '44',
		CMUN: '221',
		DC: '6',
		NOMBRE: 'Torrecilla de Alcañiz',
	},
	{
		CPRO: '44',
		CMUN: '222',
		DC: '1',
		NOMBRE: 'Torrecilla del Rebollar',
	},
	{
		CPRO: '44',
		CMUN: '226',
		DC: '8',
		NOMBRE: 'Torrelacárcel',
	},
	{
		CPRO: '44',
		CMUN: '228',
		DC: '0',
		NOMBRE: 'Torremocha de Jiloca',
	},
	{
		CPRO: '44',
		CMUN: '229',
		DC: '3',
		NOMBRE: 'Torres de Albarracín',
	},
	{
		CPRO: '44',
		CMUN: '230',
		DC: '7',
		NOMBRE: 'Torrevelilla',
	},
	{
		CPRO: '44',
		CMUN: '231',
		DC: '4',
		NOMBRE: 'Torrijas',
	},
	{
		CPRO: '44',
		CMUN: '232',
		DC: '9',
		NOMBRE: 'Torrijo del Campo',
	},
	{
		CPRO: '44',
		CMUN: '234',
		DC: '0',
		NOMBRE: 'Tramacastiel',
	},
	{
		CPRO: '44',
		CMUN: '235',
		DC: '3',
		NOMBRE: 'Tramacastilla',
	},
	{
		CPRO: '44',
		CMUN: '236',
		DC: '6',
		NOMBRE: 'Tronchón',
	},
	{
		CPRO: '44',
		CMUN: '237',
		DC: '2',
		NOMBRE: 'Urrea de Gaén',
	},
	{
		CPRO: '44',
		CMUN: '238',
		DC: '8',
		NOMBRE: 'Utrillas',
	},
	{
		CPRO: '44',
		CMUN: '239',
		DC: '1',
		NOMBRE: 'Valacloche',
	},
	{
		CPRO: '44',
		CMUN: '240',
		DC: '5',
		NOMBRE: 'Valbona',
	},
	{
		CPRO: '44',
		CMUN: '241',
		DC: '2',
		NOMBRE: 'Valdealgorfa',
	},
	{
		CPRO: '44',
		CMUN: '243',
		DC: '3',
		NOMBRE: 'Valdecuenca',
	},
	{
		CPRO: '44',
		CMUN: '244',
		DC: '8',
		NOMBRE: 'Valdelinares',
	},
	{
		CPRO: '44',
		CMUN: '245',
		DC: '1',
		NOMBRE: 'Valdeltormo',
	},
	{
		CPRO: '44',
		CMUN: '246',
		DC: '4',
		NOMBRE: 'Valderrobres',
	},
	{
		CPRO: '44',
		CMUN: '247',
		DC: '0',
		NOMBRE: 'Valjunquera',
	},
	{
		CPRO: '44',
		CMUN: '249',
		DC: '9',
		NOMBRE: 'Vallecillo, El',
	},
	{
		CPRO: '44',
		CMUN: '250',
		DC: '2',
		NOMBRE: 'Veguillas de la Sierra',
	},
	{
		CPRO: '44',
		CMUN: '251',
		DC: '9',
		NOMBRE: 'Villafranca del Campo',
	},
	{
		CPRO: '44',
		CMUN: '252',
		DC: '4',
		NOMBRE: 'Villahermosa del Campo',
	},
	{
		CPRO: '44',
		CMUN: '256',
		DC: '1',
		NOMBRE: 'Villanueva del Rebollar de la Sierra',
	},
	{
		CPRO: '44',
		CMUN: '257',
		DC: '7',
		NOMBRE: 'Villar del Cobo',
	},
	{
		CPRO: '44',
		CMUN: '258',
		DC: '3',
		NOMBRE: 'Villar del Salz',
	},
	{
		CPRO: '44',
		CMUN: '260',
		DC: '0',
		NOMBRE: 'Villarluengo',
	},
	{
		CPRO: '44',
		CMUN: '261',
		DC: '7',
		NOMBRE: 'Villarquemado',
	},
	{
		CPRO: '44',
		CMUN: '262',
		DC: '2',
		NOMBRE: 'Villarroya de los Pinares',
	},
	{
		CPRO: '44',
		CMUN: '263',
		DC: '8',
		NOMBRE: 'Villastar',
	},
	{
		CPRO: '44',
		CMUN: '264',
		DC: '3',
		NOMBRE: 'Villel',
	},
	{
		CPRO: '44',
		CMUN: '265',
		DC: '6',
		NOMBRE: 'Vinaceite',
	},
	{
		CPRO: '44',
		CMUN: '266',
		DC: '9',
		NOMBRE: 'Visiedo',
	},
	{
		CPRO: '44',
		CMUN: '267',
		DC: '5',
		NOMBRE: 'Vivel del Río Martín',
	},
	{
		CPRO: '44',
		CMUN: '268',
		DC: '1',
		NOMBRE: 'Zoma, La',
	},
	{
		CPRO: '45',
		CMUN: '001',
		DC: '5',
		NOMBRE: 'Ajofrín',
	},
	{
		CPRO: '45',
		CMUN: '002',
		DC: '0',
		NOMBRE: 'Alameda de la Sagra',
	},
	{
		CPRO: '45',
		CMUN: '003',
		DC: '6',
		NOMBRE: 'Albarreal de Tajo',
	},
	{
		CPRO: '45',
		CMUN: '004',
		DC: '1',
		NOMBRE: 'Alcabón',
	},
	{
		CPRO: '45',
		CMUN: '005',
		DC: '4',
		NOMBRE: 'Alcañizo',
	},
	{
		CPRO: '45',
		CMUN: '006',
		DC: '7',
		NOMBRE: 'Alcaudete de la Jara',
	},
	{
		CPRO: '45',
		CMUN: '007',
		DC: '3',
		NOMBRE: 'Alcolea de Tajo',
	},
	{
		CPRO: '45',
		CMUN: '008',
		DC: '9',
		NOMBRE: 'Aldea en Cabo',
	},
	{
		CPRO: '45',
		CMUN: '009',
		DC: '2',
		NOMBRE: 'Aldeanueva de Barbarroya',
	},
	{
		CPRO: '45',
		CMUN: '010',
		DC: '6',
		NOMBRE: 'Aldeanueva de San Bartolomé',
	},
	{
		CPRO: '45',
		CMUN: '011',
		DC: '3',
		NOMBRE: 'Almendral de la Cañada',
	},
	{
		CPRO: '45',
		CMUN: '012',
		DC: '8',
		NOMBRE: 'Almonacid de Toledo',
	},
	{
		CPRO: '45',
		CMUN: '013',
		DC: '4',
		NOMBRE: 'Almorox',
	},
	{
		CPRO: '45',
		CMUN: '014',
		DC: '9',
		NOMBRE: 'Añover de Tajo',
	},
	{
		CPRO: '45',
		CMUN: '015',
		DC: '2',
		NOMBRE: 'Arcicóllar',
	},
	{
		CPRO: '45',
		CMUN: '016',
		DC: '5',
		NOMBRE: 'Argés',
	},
	{
		CPRO: '45',
		CMUN: '017',
		DC: '1',
		NOMBRE: 'Azután',
	},
	{
		CPRO: '45',
		CMUN: '018',
		DC: '7',
		NOMBRE: 'Barcience',
	},
	{
		CPRO: '45',
		CMUN: '019',
		DC: '0',
		NOMBRE: 'Bargas',
	},
	{
		CPRO: '45',
		CMUN: '020',
		DC: '4',
		NOMBRE: 'Belvís de la Jara',
	},
	{
		CPRO: '45',
		CMUN: '021',
		DC: '1',
		NOMBRE: 'Borox',
	},
	{
		CPRO: '45',
		CMUN: '022',
		DC: '6',
		NOMBRE: 'Buenaventura',
	},
	{
		CPRO: '45',
		CMUN: '023',
		DC: '2',
		NOMBRE: 'Burguillos de Toledo',
	},
	{
		CPRO: '45',
		CMUN: '024',
		DC: '7',
		NOMBRE: 'Burujón',
	},
	{
		CPRO: '45',
		CMUN: '025',
		DC: '0',
		NOMBRE: 'Cabañas de la Sagra',
	},
	{
		CPRO: '45',
		CMUN: '026',
		DC: '3',
		NOMBRE: 'Cabañas de Yepes',
	},
	{
		CPRO: '45',
		CMUN: '027',
		DC: '9',
		NOMBRE: 'Cabezamesada',
	},
	{
		CPRO: '45',
		CMUN: '028',
		DC: '5',
		NOMBRE: 'Calera y Chozas',
	},
	{
		CPRO: '45',
		CMUN: '029',
		DC: '8',
		NOMBRE: 'Caleruela',
	},
	{
		CPRO: '45',
		CMUN: '030',
		DC: '2',
		NOMBRE: 'Calzada de Oropesa',
	},
	{
		CPRO: '45',
		CMUN: '031',
		DC: '9',
		NOMBRE: 'Camarena',
	},
	{
		CPRO: '45',
		CMUN: '032',
		DC: '4',
		NOMBRE: 'Camarenilla',
	},
	{
		CPRO: '45',
		CMUN: '033',
		DC: '0',
		NOMBRE: 'Campillo de la Jara, El',
	},
	{
		CPRO: '45',
		CMUN: '034',
		DC: '5',
		NOMBRE: 'Camuñas',
	},
	{
		CPRO: '45',
		CMUN: '035',
		DC: '8',
		NOMBRE: 'Cardiel de los Montes',
	},
	{
		CPRO: '45',
		CMUN: '036',
		DC: '1',
		NOMBRE: 'Carmena',
	},
	{
		CPRO: '45',
		CMUN: '037',
		DC: '7',
		NOMBRE: 'Carpio de Tajo, El',
	},
	{
		CPRO: '45',
		CMUN: '038',
		DC: '3',
		NOMBRE: 'Carranque',
	},
	{
		CPRO: '45',
		CMUN: '039',
		DC: '6',
		NOMBRE: 'Carriches',
	},
	{
		CPRO: '45',
		CMUN: '040',
		DC: '0',
		NOMBRE: 'Casar de Escalona, El',
	},
	{
		CPRO: '45',
		CMUN: '041',
		DC: '7',
		NOMBRE: 'Casarrubios del Monte',
	},
	{
		CPRO: '45',
		CMUN: '042',
		DC: '2',
		NOMBRE: 'Casasbuenas',
	},
	{
		CPRO: '45',
		CMUN: '043',
		DC: '8',
		NOMBRE: 'Castillo de Bayuela',
	},
	{
		CPRO: '45',
		CMUN: '045',
		DC: '6',
		NOMBRE: 'Cazalegas',
	},
	{
		CPRO: '45',
		CMUN: '046',
		DC: '9',
		NOMBRE: 'Cebolla',
	},
	{
		CPRO: '45',
		CMUN: '047',
		DC: '5',
		NOMBRE: 'Cedillo del Condado',
	},
	{
		CPRO: '45',
		CMUN: '048',
		DC: '1',
		NOMBRE: 'Cerralbos, Los',
	},
	{
		CPRO: '45',
		CMUN: '049',
		DC: '4',
		NOMBRE: 'Cervera de los Montes',
	},
	{
		CPRO: '45',
		CMUN: '056',
		DC: '6',
		NOMBRE: 'Chozas de Canales',
	},
	{
		CPRO: '45',
		CMUN: '057',
		DC: '2',
		NOMBRE: 'Chueca',
	},
	{
		CPRO: '45',
		CMUN: '050',
		DC: '7',
		NOMBRE: 'Ciruelos',
	},
	{
		CPRO: '45',
		CMUN: '051',
		DC: '4',
		NOMBRE: 'Cobeja',
	},
	{
		CPRO: '45',
		CMUN: '052',
		DC: '9',
		NOMBRE: 'Cobisa',
	},
	{
		CPRO: '45',
		CMUN: '053',
		DC: '5',
		NOMBRE: 'Consuegra',
	},
	{
		CPRO: '45',
		CMUN: '054',
		DC: '0',
		NOMBRE: 'Corral de Almaguer',
	},
	{
		CPRO: '45',
		CMUN: '055',
		DC: '3',
		NOMBRE: 'Cuerva',
	},
	{
		CPRO: '45',
		CMUN: '058',
		DC: '8',
		NOMBRE: 'Domingo Pérez',
	},
	{
		CPRO: '45',
		CMUN: '059',
		DC: '1',
		NOMBRE: 'Dosbarrios',
	},
	{
		CPRO: '45',
		CMUN: '060',
		DC: '5',
		NOMBRE: 'Erustes',
	},
	{
		CPRO: '45',
		CMUN: '061',
		DC: '2',
		NOMBRE: 'Escalona',
	},
	{
		CPRO: '45',
		CMUN: '062',
		DC: '7',
		NOMBRE: 'Escalonilla',
	},
	{
		CPRO: '45',
		CMUN: '063',
		DC: '3',
		NOMBRE: 'Espinoso del Rey',
	},
	{
		CPRO: '45',
		CMUN: '064',
		DC: '8',
		NOMBRE: 'Esquivias',
	},
	{
		CPRO: '45',
		CMUN: '065',
		DC: '1',
		NOMBRE: 'Estrella, La',
	},
	{
		CPRO: '45',
		CMUN: '066',
		DC: '4',
		NOMBRE: 'Fuensalida',
	},
	{
		CPRO: '45',
		CMUN: '067',
		DC: '0',
		NOMBRE: 'Gálvez',
	},
	{
		CPRO: '45',
		CMUN: '068',
		DC: '6',
		NOMBRE: 'Garciotum',
	},
	{
		CPRO: '45',
		CMUN: '069',
		DC: '9',
		NOMBRE: 'Gerindote',
	},
	{
		CPRO: '45',
		CMUN: '070',
		DC: '3',
		NOMBRE: 'Guadamur',
	},
	{
		CPRO: '45',
		CMUN: '071',
		DC: '0',
		NOMBRE: 'Guardia, La',
	},
	{
		CPRO: '45',
		CMUN: '072',
		DC: '5',
		NOMBRE: 'Herencias, Las',
	},
	{
		CPRO: '45',
		CMUN: '073',
		DC: '1',
		NOMBRE: 'Herreruela de Oropesa',
	},
	{
		CPRO: '45',
		CMUN: '074',
		DC: '6',
		NOMBRE: 'Hinojosa de San Vicente',
	},
	{
		CPRO: '45',
		CMUN: '075',
		DC: '9',
		NOMBRE: 'Hontanar',
	},
	{
		CPRO: '45',
		CMUN: '076',
		DC: '2',
		NOMBRE: 'Hormigos',
	},
	{
		CPRO: '45',
		CMUN: '077',
		DC: '8',
		NOMBRE: 'Huecas',
	},
	{
		CPRO: '45',
		CMUN: '078',
		DC: '4',
		NOMBRE: 'Huerta de Valdecarábanos',
	},
	{
		CPRO: '45',
		CMUN: '079',
		DC: '7',
		NOMBRE: 'Iglesuela, La',
	},
	{
		CPRO: '45',
		CMUN: '080',
		DC: '1',
		NOMBRE: 'Illán de Vacas',
	},
	{
		CPRO: '45',
		CMUN: '081',
		DC: '8',
		NOMBRE: 'Illescas',
	},
	{
		CPRO: '45',
		CMUN: '082',
		DC: '3',
		NOMBRE: 'Lagartera',
	},
	{
		CPRO: '45',
		CMUN: '083',
		DC: '9',
		NOMBRE: 'Layos',
	},
	{
		CPRO: '45',
		CMUN: '084',
		DC: '4',
		NOMBRE: 'Lillo',
	},
	{
		CPRO: '45',
		CMUN: '085',
		DC: '7',
		NOMBRE: 'Lominchar',
	},
	{
		CPRO: '45',
		CMUN: '086',
		DC: '0',
		NOMBRE: 'Lucillos',
	},
	{
		CPRO: '45',
		CMUN: '087',
		DC: '6',
		NOMBRE: 'Madridejos',
	},
	{
		CPRO: '45',
		CMUN: '088',
		DC: '2',
		NOMBRE: 'Magán',
	},
	{
		CPRO: '45',
		CMUN: '089',
		DC: '5',
		NOMBRE: 'Malpica de Tajo',
	},
	{
		CPRO: '45',
		CMUN: '090',
		DC: '9',
		NOMBRE: 'Manzaneque',
	},
	{
		CPRO: '45',
		CMUN: '091',
		DC: '6',
		NOMBRE: 'Maqueda',
	},
	{
		CPRO: '45',
		CMUN: '092',
		DC: '1',
		NOMBRE: 'Marjaliza',
	},
	{
		CPRO: '45',
		CMUN: '093',
		DC: '7',
		NOMBRE: 'Marrupe',
	},
	{
		CPRO: '45',
		CMUN: '094',
		DC: '2',
		NOMBRE: 'Mascaraque',
	},
	{
		CPRO: '45',
		CMUN: '095',
		DC: '5',
		NOMBRE: 'Mata, La',
	},
	{
		CPRO: '45',
		CMUN: '096',
		DC: '8',
		NOMBRE: 'Mazarambroz',
	},
	{
		CPRO: '45',
		CMUN: '097',
		DC: '4',
		NOMBRE: 'Mejorada',
	},
	{
		CPRO: '45',
		CMUN: '098',
		DC: '0',
		NOMBRE: 'Menasalbas',
	},
	{
		CPRO: '45',
		CMUN: '099',
		DC: '3',
		NOMBRE: 'Méntrida',
	},
	{
		CPRO: '45',
		CMUN: '100',
		DC: '7',
		NOMBRE: 'Mesegar de Tajo',
	},
	{
		CPRO: '45',
		CMUN: '101',
		DC: '4',
		NOMBRE: 'Miguel Esteban',
	},
	{
		CPRO: '45',
		CMUN: '102',
		DC: '9',
		NOMBRE: 'Mocejón',
	},
	{
		CPRO: '45',
		CMUN: '103',
		DC: '5',
		NOMBRE: 'Mohedas de la Jara',
	},
	{
		CPRO: '45',
		CMUN: '104',
		DC: '0',
		NOMBRE: 'Montearagón',
	},
	{
		CPRO: '45',
		CMUN: '105',
		DC: '3',
		NOMBRE: 'Montesclaros',
	},
	{
		CPRO: '45',
		CMUN: '106',
		DC: '6',
		NOMBRE: 'Mora',
	},
	{
		CPRO: '45',
		CMUN: '107',
		DC: '2',
		NOMBRE: 'Nambroca',
	},
	{
		CPRO: '45',
		CMUN: '108',
		DC: '8',
		NOMBRE: 'Nava de Ricomalillo, La',
	},
	{
		CPRO: '45',
		CMUN: '109',
		DC: '1',
		NOMBRE: 'Navahermosa',
	},
	{
		CPRO: '45',
		CMUN: '110',
		DC: '5',
		NOMBRE: 'Navalcán',
	},
	{
		CPRO: '45',
		CMUN: '111',
		DC: '2',
		NOMBRE: 'Navalmoralejo',
	},
	{
		CPRO: '45',
		CMUN: '112',
		DC: '7',
		NOMBRE: 'Navalmorales, Los',
	},
	{
		CPRO: '45',
		CMUN: '113',
		DC: '3',
		NOMBRE: 'Navalucillos, Los',
	},
	{
		CPRO: '45',
		CMUN: '114',
		DC: '8',
		NOMBRE: 'Navamorcuende',
	},
	{
		CPRO: '45',
		CMUN: '115',
		DC: '1',
		NOMBRE: 'Noblejas',
	},
	{
		CPRO: '45',
		CMUN: '116',
		DC: '4',
		NOMBRE: 'Noez',
	},
	{
		CPRO: '45',
		CMUN: '117',
		DC: '0',
		NOMBRE: 'Nombela',
	},
	{
		CPRO: '45',
		CMUN: '118',
		DC: '6',
		NOMBRE: 'Novés',
	},
	{
		CPRO: '45',
		CMUN: '119',
		DC: '9',
		NOMBRE: 'Numancia de la Sagra',
	},
	{
		CPRO: '45',
		CMUN: '120',
		DC: '3',
		NOMBRE: 'Nuño Gómez',
	},
	{
		CPRO: '45',
		CMUN: '121',
		DC: '0',
		NOMBRE: 'Ocaña',
	},
	{
		CPRO: '45',
		CMUN: '122',
		DC: '5',
		NOMBRE: 'Olías del Rey',
	},
	{
		CPRO: '45',
		CMUN: '123',
		DC: '1',
		NOMBRE: 'Ontígola',
	},
	{
		CPRO: '45',
		CMUN: '124',
		DC: '6',
		NOMBRE: 'Orgaz',
	},
	{
		CPRO: '45',
		CMUN: '125',
		DC: '9',
		NOMBRE: 'Oropesa',
	},
	{
		CPRO: '45',
		CMUN: '126',
		DC: '2',
		NOMBRE: 'Otero',
	},
	{
		CPRO: '45',
		CMUN: '127',
		DC: '8',
		NOMBRE: 'Palomeque',
	},
	{
		CPRO: '45',
		CMUN: '128',
		DC: '4',
		NOMBRE: 'Pantoja',
	},
	{
		CPRO: '45',
		CMUN: '129',
		DC: '7',
		NOMBRE: 'Paredes de Escalona',
	},
	{
		CPRO: '45',
		CMUN: '130',
		DC: '1',
		NOMBRE: 'Parrillas',
	},
	{
		CPRO: '45',
		CMUN: '131',
		DC: '8',
		NOMBRE: 'Pelahustán',
	},
	{
		CPRO: '45',
		CMUN: '132',
		DC: '3',
		NOMBRE: 'Pepino',
	},
	{
		CPRO: '45',
		CMUN: '133',
		DC: '9',
		NOMBRE: 'Polán',
	},
	{
		CPRO: '45',
		CMUN: '134',
		DC: '4',
		NOMBRE: 'Portillo de Toledo',
	},
	{
		CPRO: '45',
		CMUN: '135',
		DC: '7',
		NOMBRE: 'Puebla de Almoradiel, La',
	},
	{
		CPRO: '45',
		CMUN: '136',
		DC: '0',
		NOMBRE: 'Puebla de Montalbán, La',
	},
	{
		CPRO: '45',
		CMUN: '137',
		DC: '6',
		NOMBRE: 'Pueblanueva, La',
	},
	{
		CPRO: '45',
		CMUN: '138',
		DC: '2',
		NOMBRE: 'Puente del Arzobispo, El',
	},
	{
		CPRO: '45',
		CMUN: '139',
		DC: '5',
		NOMBRE: 'Puerto de San Vicente',
	},
	{
		CPRO: '45',
		CMUN: '140',
		DC: '9',
		NOMBRE: 'Pulgar',
	},
	{
		CPRO: '45',
		CMUN: '141',
		DC: '6',
		NOMBRE: 'Quero',
	},
	{
		CPRO: '45',
		CMUN: '142',
		DC: '1',
		NOMBRE: 'Quintanar de la Orden',
	},
	{
		CPRO: '45',
		CMUN: '143',
		DC: '7',
		NOMBRE: 'Quismondo',
	},
	{
		CPRO: '45',
		CMUN: '144',
		DC: '2',
		NOMBRE: 'Real de San Vicente, El',
	},
	{
		CPRO: '45',
		CMUN: '145',
		DC: '5',
		NOMBRE: 'Recas',
	},
	{
		CPRO: '45',
		CMUN: '146',
		DC: '8',
		NOMBRE: 'Retamoso de la Jara',
	},
	{
		CPRO: '45',
		CMUN: '147',
		DC: '4',
		NOMBRE: 'Rielves',
	},
	{
		CPRO: '45',
		CMUN: '148',
		DC: '0',
		NOMBRE: 'Robledo del Mazo',
	},
	{
		CPRO: '45',
		CMUN: '149',
		DC: '3',
		NOMBRE: 'Romeral, El',
	},
	{
		CPRO: '45',
		CMUN: '150',
		DC: '6',
		NOMBRE: 'San Bartolomé de las Abiertas',
	},
	{
		CPRO: '45',
		CMUN: '151',
		DC: '3',
		NOMBRE: 'San Martín de Montalbán',
	},
	{
		CPRO: '45',
		CMUN: '152',
		DC: '8',
		NOMBRE: 'San Martín de Pusa',
	},
	{
		CPRO: '45',
		CMUN: '153',
		DC: '4',
		NOMBRE: 'San Pablo de los Montes',
	},
	{
		CPRO: '45',
		CMUN: '154',
		DC: '9',
		NOMBRE: 'San Román de los Montes',
	},
	{
		CPRO: '45',
		CMUN: '155',
		DC: '2',
		NOMBRE: 'Santa Ana de Pusa',
	},
	{
		CPRO: '45',
		CMUN: '156',
		DC: '5',
		NOMBRE: 'Santa Cruz de la Zarza',
	},
	{
		CPRO: '45',
		CMUN: '157',
		DC: '1',
		NOMBRE: 'Santa Cruz del Retamar',
	},
	{
		CPRO: '45',
		CMUN: '158',
		DC: '7',
		NOMBRE: 'Santa Olalla',
	},
	{
		CPRO: '45',
		CMUN: '901',
		DC: '6',
		NOMBRE: 'Santo Domingo-Caudilla',
	},
	{
		CPRO: '45',
		CMUN: '159',
		DC: '0',
		NOMBRE: 'Sartajada',
	},
	{
		CPRO: '45',
		CMUN: '160',
		DC: '4',
		NOMBRE: 'Segurilla',
	},
	{
		CPRO: '45',
		CMUN: '161',
		DC: '1',
		NOMBRE: 'Seseña',
	},
	{
		CPRO: '45',
		CMUN: '162',
		DC: '6',
		NOMBRE: 'Sevilleja de la Jara',
	},
	{
		CPRO: '45',
		CMUN: '163',
		DC: '2',
		NOMBRE: 'Sonseca',
	},
	{
		CPRO: '45',
		CMUN: '164',
		DC: '7',
		NOMBRE: 'Sotillo de las Palomas',
	},
	{
		CPRO: '45',
		CMUN: '165',
		DC: '0',
		NOMBRE: 'Talavera de la Reina',
	},
	{
		CPRO: '45',
		CMUN: '166',
		DC: '3',
		NOMBRE: 'Tembleque',
	},
	{
		CPRO: '45',
		CMUN: '167',
		DC: '9',
		NOMBRE: 'Toboso, El',
	},
	{
		CPRO: '45',
		CMUN: '168',
		DC: '5',
		NOMBRE: 'Toledo',
	},
	{
		CPRO: '45',
		CMUN: '169',
		DC: '8',
		NOMBRE: 'Torralba de Oropesa',
	},
	{
		CPRO: '45',
		CMUN: '171',
		DC: '9',
		NOMBRE: 'Torre de Esteban Hambrán, La',
	},
	{
		CPRO: '45',
		CMUN: '170',
		DC: '2',
		NOMBRE: 'Torrecilla de la Jara',
	},
	{
		CPRO: '45',
		CMUN: '172',
		DC: '4',
		NOMBRE: 'Torrico',
	},
	{
		CPRO: '45',
		CMUN: '173',
		DC: '0',
		NOMBRE: 'Torrijos',
	},
	{
		CPRO: '45',
		CMUN: '174',
		DC: '5',
		NOMBRE: 'Totanés',
	},
	{
		CPRO: '45',
		CMUN: '175',
		DC: '8',
		NOMBRE: 'Turleque',
	},
	{
		CPRO: '45',
		CMUN: '176',
		DC: '1',
		NOMBRE: 'Ugena',
	},
	{
		CPRO: '45',
		CMUN: '177',
		DC: '7',
		NOMBRE: 'Urda',
	},
	{
		CPRO: '45',
		CMUN: '179',
		DC: '6',
		NOMBRE: 'Valdeverdeja',
	},
	{
		CPRO: '45',
		CMUN: '180',
		DC: '0',
		NOMBRE: 'Valmojado',
	},
	{
		CPRO: '45',
		CMUN: '181',
		DC: '7',
		NOMBRE: 'Velada',
	},
	{
		CPRO: '45',
		CMUN: '182',
		DC: '2',
		NOMBRE: 'Ventas con Peña Aguilera, Las',
	},
	{
		CPRO: '45',
		CMUN: '183',
		DC: '8',
		NOMBRE: 'Ventas de Retamosa, Las',
	},
	{
		CPRO: '45',
		CMUN: '184',
		DC: '3',
		NOMBRE: 'Ventas de San Julián, Las',
	},
	{
		CPRO: '45',
		CMUN: '186',
		DC: '9',
		NOMBRE: 'Villa de Don Fadrique, La',
	},
	{
		CPRO: '45',
		CMUN: '185',
		DC: '6',
		NOMBRE: 'Villacañas',
	},
	{
		CPRO: '45',
		CMUN: '187',
		DC: '5',
		NOMBRE: 'Villafranca de los Caballeros',
	},
	{
		CPRO: '45',
		CMUN: '188',
		DC: '1',
		NOMBRE: 'Villaluenga de la Sagra',
	},
	{
		CPRO: '45',
		CMUN: '189',
		DC: '4',
		NOMBRE: 'Villamiel de Toledo',
	},
	{
		CPRO: '45',
		CMUN: '190',
		DC: '8',
		NOMBRE: 'Villaminaya',
	},
	{
		CPRO: '45',
		CMUN: '191',
		DC: '5',
		NOMBRE: 'Villamuelas',
	},
	{
		CPRO: '45',
		CMUN: '192',
		DC: '0',
		NOMBRE: 'Villanueva de Alcardete',
	},
	{
		CPRO: '45',
		CMUN: '193',
		DC: '6',
		NOMBRE: 'Villanueva de Bogas',
	},
	{
		CPRO: '45',
		CMUN: '194',
		DC: '1',
		NOMBRE: 'Villarejo de Montalbán',
	},
	{
		CPRO: '45',
		CMUN: '195',
		DC: '4',
		NOMBRE: 'Villarrubia de Santiago',
	},
	{
		CPRO: '45',
		CMUN: '196',
		DC: '7',
		NOMBRE: 'Villaseca de la Sagra',
	},
	{
		CPRO: '45',
		CMUN: '197',
		DC: '3',
		NOMBRE: 'Villasequilla',
	},
	{
		CPRO: '45',
		CMUN: '198',
		DC: '9',
		NOMBRE: 'Villatobas',
	},
	{
		CPRO: '45',
		CMUN: '199',
		DC: '2',
		NOMBRE: 'Viso de San Juan, El',
	},
	{
		CPRO: '45',
		CMUN: '200',
		DC: '6',
		NOMBRE: 'Yébenes, Los',
	},
	{
		CPRO: '45',
		CMUN: '201',
		DC: '3',
		NOMBRE: 'Yeles',
	},
	{
		CPRO: '45',
		CMUN: '202',
		DC: '8',
		NOMBRE: 'Yepes',
	},
	{
		CPRO: '45',
		CMUN: '203',
		DC: '4',
		NOMBRE: 'Yuncler',
	},
	{
		CPRO: '45',
		CMUN: '204',
		DC: '9',
		NOMBRE: 'Yunclillos',
	},
	{
		CPRO: '45',
		CMUN: '205',
		DC: '2',
		NOMBRE: 'Yuncos',
	},
	{
		CPRO: '46',
		CMUN: '001',
		DC: '8',
		NOMBRE: 'Ademuz',
	},
	{
		CPRO: '46',
		CMUN: '002',
		DC: '3',
		NOMBRE: 'Ador',
	},
	{
		CPRO: '46',
		CMUN: '004',
		DC: '4',
		NOMBRE: 'Agullent',
	},
	{
		CPRO: '46',
		CMUN: '042',
		DC: '5',
		NOMBRE: 'Aielo de Malferit',
	},
	{
		CPRO: '46',
		CMUN: '043',
		DC: '1',
		NOMBRE: 'Aielo de Rugat',
	},
	{
		CPRO: '46',
		CMUN: '005',
		DC: '7',
		NOMBRE: 'Alaquàs',
	},
	{
		CPRO: '46',
		CMUN: '006',
		DC: '0',
		NOMBRE: 'Albaida',
	},
	{
		CPRO: '46',
		CMUN: '007',
		DC: '6',
		NOMBRE: 'Albal',
	},
	{
		CPRO: '46',
		CMUN: '008',
		DC: '2',
		NOMBRE: 'Albalat de la Ribera',
	},
	{
		CPRO: '46',
		CMUN: '009',
		DC: '5',
		NOMBRE: 'Albalat dels Sorells',
	},
	{
		CPRO: '46',
		CMUN: '010',
		DC: '9',
		NOMBRE: 'Albalat dels Tarongers',
	},
	{
		CPRO: '46',
		CMUN: '011',
		DC: '6',
		NOMBRE: 'Alberic',
	},
	{
		CPRO: '46',
		CMUN: '012',
		DC: '1',
		NOMBRE: 'Alborache',
	},
	{
		CPRO: '46',
		CMUN: '013',
		DC: '7',
		NOMBRE: 'Alboraya',
	},
	{
		CPRO: '46',
		CMUN: '014',
		DC: '2',
		NOMBRE: 'Albuixech',
	},
	{
		CPRO: '46',
		CMUN: '016',
		DC: '8',
		NOMBRE: 'Alcàntera de Xúquer',
	},
	{
		CPRO: '46',
		CMUN: '015',
		DC: '5',
		NOMBRE: 'Alcàsser',
	},
	{
		CPRO: '46',
		CMUN: '018',
		DC: '0',
		NOMBRE: 'Alcublas',
	},
	{
		CPRO: '46',
		CMUN: '019',
		DC: '3',
		NOMBRE: "Alcúdia, l'",
	},
	{
		CPRO: '46',
		CMUN: '020',
		DC: '7',
		NOMBRE: "Alcúdia de Crespins, l'",
	},
	{
		CPRO: '46',
		CMUN: '021',
		DC: '4',
		NOMBRE: 'Aldaia',
	},
	{
		CPRO: '46',
		CMUN: '022',
		DC: '9',
		NOMBRE: 'Alfafar',
	},
	{
		CPRO: '46',
		CMUN: '024',
		DC: '0',
		NOMBRE: 'Alfara de la Baronia',
	},
	{
		CPRO: '46',
		CMUN: '025',
		DC: '3',
		NOMBRE: 'Alfara del Patriarca',
	},
	{
		CPRO: '46',
		CMUN: '026',
		DC: '6',
		NOMBRE: 'Alfarp',
	},
	{
		CPRO: '46',
		CMUN: '027',
		DC: '2',
		NOMBRE: 'Alfarrasí',
	},
	{
		CPRO: '46',
		CMUN: '023',
		DC: '5',
		NOMBRE: 'Alfauir',
	},
	{
		CPRO: '46',
		CMUN: '028',
		DC: '8',
		NOMBRE: 'Algar de Palancia',
	},
	{
		CPRO: '46',
		CMUN: '029',
		DC: '1',
		NOMBRE: 'Algemesí',
	},
	{
		CPRO: '46',
		CMUN: '030',
		DC: '5',
		NOMBRE: 'Algimia de Alfara',
	},
	{
		CPRO: '46',
		CMUN: '031',
		DC: '2',
		NOMBRE: 'Alginet',
	},
	{
		CPRO: '46',
		CMUN: '032',
		DC: '7',
		NOMBRE: 'Almàssera',
	},
	{
		CPRO: '46',
		CMUN: '033',
		DC: '3',
		NOMBRE: 'Almiserà',
	},
	{
		CPRO: '46',
		CMUN: '034',
		DC: '8',
		NOMBRE: 'Almoines',
	},
	{
		CPRO: '46',
		CMUN: '035',
		DC: '1',
		NOMBRE: 'Almussafes',
	},
	{
		CPRO: '46',
		CMUN: '036',
		DC: '4',
		NOMBRE: 'Alpuente',
	},
	{
		CPRO: '46',
		CMUN: '037',
		DC: '0',
		NOMBRE: "Alqueria de la Comtessa, l'",
	},
	{
		CPRO: '46',
		CMUN: '017',
		DC: '4',
		NOMBRE: 'Alzira',
	},
	{
		CPRO: '46',
		CMUN: '038',
		DC: '6',
		NOMBRE: 'Andilla',
	},
	{
		CPRO: '46',
		CMUN: '039',
		DC: '9',
		NOMBRE: 'Anna',
	},
	{
		CPRO: '46',
		CMUN: '040',
		DC: '3',
		NOMBRE: 'Antella',
	},
	{
		CPRO: '46',
		CMUN: '041',
		DC: '0',
		NOMBRE: 'Aras de los Olmos',
	},
	{
		CPRO: '46',
		CMUN: '003',
		DC: '9',
		NOMBRE: "Atzeneta d'Albaida",
	},
	{
		CPRO: '46',
		CMUN: '044',
		DC: '6',
		NOMBRE: 'Ayora',
	},
	{
		CPRO: '46',
		CMUN: '046',
		DC: '2',
		NOMBRE: 'Barx',
	},
	{
		CPRO: '46',
		CMUN: '045',
		DC: '9',
		NOMBRE: 'Barxeta',
	},
	{
		CPRO: '46',
		CMUN: '047',
		DC: '8',
		NOMBRE: 'Bèlgida',
	},
	{
		CPRO: '46',
		CMUN: '048',
		DC: '4',
		NOMBRE: 'Bellreguard',
	},
	{
		CPRO: '46',
		CMUN: '049',
		DC: '7',
		NOMBRE: 'Bellús',
	},
	{
		CPRO: '46',
		CMUN: '050',
		DC: '0',
		NOMBRE: 'Benagéber',
	},
	{
		CPRO: '46',
		CMUN: '051',
		DC: '7',
		NOMBRE: 'Benaguasil',
	},
	{
		CPRO: '46',
		CMUN: '052',
		DC: '2',
		NOMBRE: 'Benavites',
	},
	{
		CPRO: '46',
		CMUN: '053',
		DC: '8',
		NOMBRE: 'Beneixida',
	},
	{
		CPRO: '46',
		CMUN: '054',
		DC: '3',
		NOMBRE: 'Benetússer',
	},
	{
		CPRO: '46',
		CMUN: '055',
		DC: '6',
		NOMBRE: 'Beniarjó',
	},
	{
		CPRO: '46',
		CMUN: '056',
		DC: '9',
		NOMBRE: 'Beniatjar',
	},
	{
		CPRO: '46',
		CMUN: '057',
		DC: '5',
		NOMBRE: 'Benicolet',
	},
	{
		CPRO: '46',
		CMUN: '904',
		DC: '5',
		NOMBRE: 'Benicull de Xúquer',
	},
	{
		CPRO: '46',
		CMUN: '060',
		DC: '8',
		NOMBRE: 'Benifaió',
	},
	{
		CPRO: '46',
		CMUN: '059',
		DC: '4',
		NOMBRE: 'Benifairó de la Valldigna',
	},
	{
		CPRO: '46',
		CMUN: '058',
		DC: '1',
		NOMBRE: 'Benifairó de les Valls',
	},
	{
		CPRO: '46',
		CMUN: '061',
		DC: '5',
		NOMBRE: 'Beniflá',
	},
	{
		CPRO: '46',
		CMUN: '062',
		DC: '0',
		NOMBRE: 'Benigánim',
	},
	{
		CPRO: '46',
		CMUN: '063',
		DC: '6',
		NOMBRE: 'Benimodo',
	},
	{
		CPRO: '46',
		CMUN: '064',
		DC: '1',
		NOMBRE: 'Benimuslem',
	},
	{
		CPRO: '46',
		CMUN: '065',
		DC: '4',
		NOMBRE: 'Beniparrell',
	},
	{
		CPRO: '46',
		CMUN: '066',
		DC: '7',
		NOMBRE: 'Benirredrà',
	},
	{
		CPRO: '46',
		CMUN: '067',
		DC: '3',
		NOMBRE: 'Benisanó',
	},
	{
		CPRO: '46',
		CMUN: '068',
		DC: '9',
		NOMBRE: 'Benissoda',
	},
	{
		CPRO: '46',
		CMUN: '069',
		DC: '2',
		NOMBRE: 'Benisuera',
	},
	{
		CPRO: '46',
		CMUN: '070',
		DC: '6',
		NOMBRE: 'Bétera',
	},
	{
		CPRO: '46',
		CMUN: '071',
		DC: '3',
		NOMBRE: 'Bicorp',
	},
	{
		CPRO: '46',
		CMUN: '072',
		DC: '8',
		NOMBRE: 'Bocairent',
	},
	{
		CPRO: '46',
		CMUN: '073',
		DC: '4',
		NOMBRE: 'Bolbaite',
	},
	{
		CPRO: '46',
		CMUN: '074',
		DC: '9',
		NOMBRE: 'Bonrepòs i Mirambell',
	},
	{
		CPRO: '46',
		CMUN: '075',
		DC: '2',
		NOMBRE: 'Bufali',
	},
	{
		CPRO: '46',
		CMUN: '076',
		DC: '5',
		NOMBRE: 'Bugarra',
	},
	{
		CPRO: '46',
		CMUN: '077',
		DC: '1',
		NOMBRE: 'Buñol',
	},
	{
		CPRO: '46',
		CMUN: '078',
		DC: '7',
		NOMBRE: 'Burjassot',
	},
	{
		CPRO: '46',
		CMUN: '079',
		DC: '0',
		NOMBRE: 'Calles',
	},
	{
		CPRO: '46',
		CMUN: '080',
		DC: '4',
		NOMBRE: 'Camporrobles',
	},
	{
		CPRO: '46',
		CMUN: '081',
		DC: '1',
		NOMBRE: 'Canals',
	},
	{
		CPRO: '46',
		CMUN: '082',
		DC: '6',
		NOMBRE: "Canet d'En Berenguer",
	},
	{
		CPRO: '46',
		CMUN: '083',
		DC: '2',
		NOMBRE: 'Carcaixent',
	},
	{
		CPRO: '46',
		CMUN: '084',
		DC: '7',
		NOMBRE: 'Càrcer',
	},
	{
		CPRO: '46',
		CMUN: '085',
		DC: '0',
		NOMBRE: 'Carlet',
	},
	{
		CPRO: '46',
		CMUN: '086',
		DC: '3',
		NOMBRE: 'Carrícola',
	},
	{
		CPRO: '46',
		CMUN: '087',
		DC: '9',
		NOMBRE: 'Casas Altas',
	},
	{
		CPRO: '46',
		CMUN: '088',
		DC: '5',
		NOMBRE: 'Casas Bajas',
	},
	{
		CPRO: '46',
		CMUN: '089',
		DC: '8',
		NOMBRE: 'Casinos',
	},
	{
		CPRO: '46',
		CMUN: '090',
		DC: '2',
		NOMBRE: 'Castelló de Rugat',
	},
	{
		CPRO: '46',
		CMUN: '091',
		DC: '9',
		NOMBRE: 'Castellonet de la Conquesta',
	},
	{
		CPRO: '46',
		CMUN: '092',
		DC: '4',
		NOMBRE: 'Castielfabib',
	},
	{
		CPRO: '46',
		CMUN: '093',
		DC: '0',
		NOMBRE: 'Catadau',
	},
	{
		CPRO: '46',
		CMUN: '094',
		DC: '5',
		NOMBRE: 'Catarroja',
	},
	{
		CPRO: '46',
		CMUN: '095',
		DC: '8',
		NOMBRE: 'Caudete de las Fuentes',
	},
	{
		CPRO: '46',
		CMUN: '096',
		DC: '1',
		NOMBRE: 'Cerdà',
	},
	{
		CPRO: '46',
		CMUN: '107',
		DC: '5',
		NOMBRE: 'Chella',
	},
	{
		CPRO: '46',
		CMUN: '106',
		DC: '9',
		NOMBRE: 'Chelva',
	},
	{
		CPRO: '46',
		CMUN: '108',
		DC: '1',
		NOMBRE: 'Chera',
	},
	{
		CPRO: '46',
		CMUN: '109',
		DC: '4',
		NOMBRE: 'Cheste',
	},
	{
		CPRO: '46',
		CMUN: '111',
		DC: '5',
		NOMBRE: 'Chiva',
	},
	{
		CPRO: '46',
		CMUN: '112',
		DC: '0',
		NOMBRE: 'Chulilla',
	},
	{
		CPRO: '46',
		CMUN: '097',
		DC: '7',
		NOMBRE: 'Cofrentes',
	},
	{
		CPRO: '46',
		CMUN: '098',
		DC: '3',
		NOMBRE: 'Corbera',
	},
	{
		CPRO: '46',
		CMUN: '099',
		DC: '6',
		NOMBRE: 'Cortes de Pallás',
	},
	{
		CPRO: '46',
		CMUN: '100',
		DC: '0',
		NOMBRE: 'Cotes',
	},
	{
		CPRO: '46',
		CMUN: '105',
		DC: '6',
		NOMBRE: 'Cullera',
	},
	{
		CPRO: '46',
		CMUN: '113',
		DC: '6',
		NOMBRE: 'Daimús',
	},
	{
		CPRO: '46',
		CMUN: '114',
		DC: '1',
		NOMBRE: 'Domeño',
	},
	{
		CPRO: '46',
		CMUN: '115',
		DC: '4',
		NOMBRE: 'Dos Aguas',
	},
	{
		CPRO: '46',
		CMUN: '116',
		DC: '7',
		NOMBRE: "Eliana, l'",
	},
	{
		CPRO: '46',
		CMUN: '117',
		DC: '3',
		NOMBRE: 'Emperador',
	},
	{
		CPRO: '46',
		CMUN: '118',
		DC: '9',
		NOMBRE: 'Enguera',
	},
	{
		CPRO: '46',
		CMUN: '119',
		DC: '2',
		NOMBRE: "Ènova, l'",
	},
	{
		CPRO: '46',
		CMUN: '120',
		DC: '6',
		NOMBRE: 'Estivella',
	},
	{
		CPRO: '46',
		CMUN: '121',
		DC: '3',
		NOMBRE: 'Estubeny',
	},
	{
		CPRO: '46',
		CMUN: '122',
		DC: '8',
		NOMBRE: 'Faura',
	},
	{
		CPRO: '46',
		CMUN: '123',
		DC: '4',
		NOMBRE: 'Favara',
	},
	{
		CPRO: '46',
		CMUN: '126',
		DC: '5',
		NOMBRE: 'Foios',
	},
	{
		CPRO: '46',
		CMUN: '128',
		DC: '7',
		NOMBRE: 'Font de la Figuera, la',
	},
	{
		CPRO: '46',
		CMUN: '127',
		DC: '1',
		NOMBRE: "Font d'En Carròs, la",
	},
	{
		CPRO: '46',
		CMUN: '124',
		DC: '9',
		NOMBRE: 'Fontanars dels Alforins',
	},
	{
		CPRO: '46',
		CMUN: '125',
		DC: '2',
		NOMBRE: 'Fortaleny',
	},
	{
		CPRO: '46',
		CMUN: '129',
		DC: '0',
		NOMBRE: 'Fuenterrobles',
	},
	{
		CPRO: '46',
		CMUN: '131',
		DC: '1',
		NOMBRE: 'Gandia',
	},
	{
		CPRO: '46',
		CMUN: '902',
		DC: '4',
		NOMBRE: 'Gátova',
	},
	{
		CPRO: '46',
		CMUN: '130',
		DC: '4',
		NOMBRE: 'Gavarda',
	},
	{
		CPRO: '46',
		CMUN: '132',
		DC: '6',
		NOMBRE: 'Genovés',
	},
	{
		CPRO: '46',
		CMUN: '133',
		DC: '2',
		NOMBRE: 'Gestalgar',
	},
	{
		CPRO: '46',
		CMUN: '134',
		DC: '7',
		NOMBRE: 'Gilet',
	},
	{
		CPRO: '46',
		CMUN: '135',
		DC: '0',
		NOMBRE: 'Godella',
	},
	{
		CPRO: '46',
		CMUN: '136',
		DC: '3',
		NOMBRE: 'Godelleta',
	},
	{
		CPRO: '46',
		CMUN: '137',
		DC: '9',
		NOMBRE: 'Granja de la Costera, la',
	},
	{
		CPRO: '46',
		CMUN: '138',
		DC: '5',
		NOMBRE: 'Guadasequies',
	},
	{
		CPRO: '46',
		CMUN: '139',
		DC: '8',
		NOMBRE: 'Guadassuar',
	},
	{
		CPRO: '46',
		CMUN: '140',
		DC: '2',
		NOMBRE: 'Guardamar de la Safor',
	},
	{
		CPRO: '46',
		CMUN: '141',
		DC: '9',
		NOMBRE: 'Higueruelas',
	},
	{
		CPRO: '46',
		CMUN: '142',
		DC: '4',
		NOMBRE: 'Jalance',
	},
	{
		CPRO: '46',
		CMUN: '144',
		DC: '5',
		NOMBRE: 'Jarafuel',
	},
	{
		CPRO: '46',
		CMUN: '154',
		DC: '2',
		NOMBRE: 'Llanera de Ranes',
	},
	{
		CPRO: '46',
		CMUN: '155',
		DC: '5',
		NOMBRE: 'Llaurí',
	},
	{
		CPRO: '46',
		CMUN: '147',
		DC: '7',
		NOMBRE: 'Llíria',
	},
	{
		CPRO: '46',
		CMUN: '152',
		DC: '1',
		NOMBRE: 'Llocnou de la Corona',
	},
	{
		CPRO: '46',
		CMUN: '153',
		DC: '7',
		NOMBRE: 'Llocnou de Sant Jeroni',
	},
	{
		CPRO: '46',
		CMUN: '151',
		DC: '6',
		NOMBRE: "Llocnou d'En Fenollet",
	},
	{
		CPRO: '46',
		CMUN: '156',
		DC: '8',
		NOMBRE: 'Llombai',
	},
	{
		CPRO: '46',
		CMUN: '157',
		DC: '4',
		NOMBRE: 'Llosa de Ranes, la',
	},
	{
		CPRO: '46',
		CMUN: '150',
		DC: '9',
		NOMBRE: 'Llutxent',
	},
	{
		CPRO: '46',
		CMUN: '148',
		DC: '3',
		NOMBRE: 'Loriguilla',
	},
	{
		CPRO: '46',
		CMUN: '149',
		DC: '6',
		NOMBRE: 'Losa del Obispo',
	},
	{
		CPRO: '46',
		CMUN: '158',
		DC: '0',
		NOMBRE: 'Macastre',
	},
	{
		CPRO: '46',
		CMUN: '159',
		DC: '3',
		NOMBRE: 'Manises',
	},
	{
		CPRO: '46',
		CMUN: '160',
		DC: '7',
		NOMBRE: 'Manuel',
	},
	{
		CPRO: '46',
		CMUN: '161',
		DC: '4',
		NOMBRE: 'Marines',
	},
	{
		CPRO: '46',
		CMUN: '162',
		DC: '9',
		NOMBRE: 'Massalavés',
	},
	{
		CPRO: '46',
		CMUN: '163',
		DC: '5',
		NOMBRE: 'Massalfassar',
	},
	{
		CPRO: '46',
		CMUN: '164',
		DC: '0',
		NOMBRE: 'Massamagrell',
	},
	{
		CPRO: '46',
		CMUN: '165',
		DC: '3',
		NOMBRE: 'Massanassa',
	},
	{
		CPRO: '46',
		CMUN: '166',
		DC: '6',
		NOMBRE: 'Meliana',
	},
	{
		CPRO: '46',
		CMUN: '167',
		DC: '2',
		NOMBRE: 'Millares',
	},
	{
		CPRO: '46',
		CMUN: '168',
		DC: '8',
		NOMBRE: 'Miramar',
	},
	{
		CPRO: '46',
		CMUN: '169',
		DC: '1',
		NOMBRE: 'Mislata',
	},
	{
		CPRO: '46',
		CMUN: '170',
		DC: '5',
		NOMBRE: 'Mogente/Moixent',
	},
	{
		CPRO: '46',
		CMUN: '171',
		DC: '2',
		NOMBRE: 'Moncada',
	},
	{
		CPRO: '46',
		CMUN: '173',
		DC: '3',
		NOMBRE: 'Montaverner',
	},
	{
		CPRO: '46',
		CMUN: '174',
		DC: '8',
		NOMBRE: 'Montesa',
	},
	{
		CPRO: '46',
		CMUN: '175',
		DC: '1',
		NOMBRE: 'Montitxelvo/Montichelvo',
	},
	{
		CPRO: '46',
		CMUN: '176',
		DC: '4',
		NOMBRE: 'Montroy',
	},
	{
		CPRO: '46',
		CMUN: '172',
		DC: '7',
		NOMBRE: 'Montserrat',
	},
	{
		CPRO: '46',
		CMUN: '177',
		DC: '0',
		NOMBRE: 'Museros',
	},
	{
		CPRO: '46',
		CMUN: '178',
		DC: '6',
		NOMBRE: 'Náquera',
	},
	{
		CPRO: '46',
		CMUN: '179',
		DC: '9',
		NOMBRE: 'Navarrés',
	},
	{
		CPRO: '46',
		CMUN: '180',
		DC: '3',
		NOMBRE: 'Novelé/Novetlè',
	},
	{
		CPRO: '46',
		CMUN: '181',
		DC: '0',
		NOMBRE: 'Oliva',
	},
	{
		CPRO: '46',
		CMUN: '183',
		DC: '1',
		NOMBRE: "Olleria, l'",
	},
	{
		CPRO: '46',
		CMUN: '182',
		DC: '5',
		NOMBRE: 'Olocau',
	},
	{
		CPRO: '46',
		CMUN: '184',
		DC: '6',
		NOMBRE: 'Ontinyent',
	},
	{
		CPRO: '46',
		CMUN: '185',
		DC: '9',
		NOMBRE: 'Otos',
	},
	{
		CPRO: '46',
		CMUN: '186',
		DC: '2',
		NOMBRE: 'Paiporta',
	},
	{
		CPRO: '46',
		CMUN: '187',
		DC: '8',
		NOMBRE: 'Palma de Gandía',
	},
	{
		CPRO: '46',
		CMUN: '188',
		DC: '4',
		NOMBRE: 'Palmera',
	},
	{
		CPRO: '46',
		CMUN: '189',
		DC: '7',
		NOMBRE: 'Palomar, el',
	},
	{
		CPRO: '46',
		CMUN: '190',
		DC: '1',
		NOMBRE: 'Paterna',
	},
	{
		CPRO: '46',
		CMUN: '191',
		DC: '8',
		NOMBRE: 'Pedralba',
	},
	{
		CPRO: '46',
		CMUN: '192',
		DC: '3',
		NOMBRE: 'Petrés',
	},
	{
		CPRO: '46',
		CMUN: '193',
		DC: '9',
		NOMBRE: 'Picanya',
	},
	{
		CPRO: '46',
		CMUN: '194',
		DC: '4',
		NOMBRE: 'Picassent',
	},
	{
		CPRO: '46',
		CMUN: '195',
		DC: '7',
		NOMBRE: 'Piles',
	},
	{
		CPRO: '46',
		CMUN: '196',
		DC: '0',
		NOMBRE: 'Pinet',
	},
	{
		CPRO: '46',
		CMUN: '199',
		DC: '5',
		NOMBRE: 'Pobla de Farnals, la',
	},
	{
		CPRO: '46',
		CMUN: '202',
		DC: '1',
		NOMBRE: 'Pobla de Vallbona, la',
	},
	{
		CPRO: '46',
		CMUN: '200',
		DC: '9',
		NOMBRE: 'Pobla del Duc, la',
	},
	{
		CPRO: '46',
		CMUN: '203',
		DC: '7',
		NOMBRE: 'Pobla Llarga, la',
	},
	{
		CPRO: '46',
		CMUN: '197',
		DC: '6',
		NOMBRE: 'Polinyà de Xúquer',
	},
	{
		CPRO: '46',
		CMUN: '198',
		DC: '2',
		NOMBRE: 'Potríes',
	},
	{
		CPRO: '46',
		CMUN: '205',
		DC: '5',
		NOMBRE: 'Puçol',
	},
	{
		CPRO: '46',
		CMUN: '201',
		DC: '6',
		NOMBRE: 'Puebla de San Miguel',
	},
	{
		CPRO: '46',
		CMUN: '204',
		DC: '2',
		NOMBRE: 'Puig',
	},
	{
		CPRO: '46',
		CMUN: '101',
		DC: '7',
		NOMBRE: 'Quart de les Valls',
	},
	{
		CPRO: '46',
		CMUN: '102',
		DC: '2',
		NOMBRE: 'Quart de Poblet',
	},
	{
		CPRO: '46',
		CMUN: '103',
		DC: '8',
		NOMBRE: 'Quartell',
	},
	{
		CPRO: '46',
		CMUN: '104',
		DC: '3',
		NOMBRE: 'Quatretonda',
	},
	{
		CPRO: '46',
		CMUN: '206',
		DC: '8',
		NOMBRE: 'Quesa',
	},
	{
		CPRO: '46',
		CMUN: '207',
		DC: '4',
		NOMBRE: 'Rafelbuñol/Rafelbunyol',
	},
	{
		CPRO: '46',
		CMUN: '208',
		DC: '0',
		NOMBRE: 'Rafelcofer',
	},
	{
		CPRO: '46',
		CMUN: '209',
		DC: '3',
		NOMBRE: 'Rafelguaraf',
	},
	{
		CPRO: '46',
		CMUN: '210',
		DC: '7',
		NOMBRE: 'Ráfol de Salem',
	},
	{
		CPRO: '46',
		CMUN: '212',
		DC: '9',
		NOMBRE: 'Real',
	},
	{
		CPRO: '46',
		CMUN: '211',
		DC: '4',
		NOMBRE: 'Real de Gandía',
	},
	{
		CPRO: '46',
		CMUN: '213',
		DC: '5',
		NOMBRE: 'Requena',
	},
	{
		CPRO: '46',
		CMUN: '214',
		DC: '0',
		NOMBRE: 'Riba-roja de Túria',
	},
	{
		CPRO: '46',
		CMUN: '215',
		DC: '3',
		NOMBRE: 'Riola',
	},
	{
		CPRO: '46',
		CMUN: '216',
		DC: '6',
		NOMBRE: 'Rocafort',
	},
	{
		CPRO: '46',
		CMUN: '217',
		DC: '2',
		NOMBRE: 'Rotglà i Corberà',
	},
	{
		CPRO: '46',
		CMUN: '218',
		DC: '8',
		NOMBRE: 'Rótova',
	},
	{
		CPRO: '46',
		CMUN: '219',
		DC: '1',
		NOMBRE: 'Rugat',
	},
	{
		CPRO: '46',
		CMUN: '220',
		DC: '5',
		NOMBRE: 'Sagunto/Sagunt',
	},
	{
		CPRO: '46',
		CMUN: '221',
		DC: '2',
		NOMBRE: 'Salem',
	},
	{
		CPRO: '46',
		CMUN: '903',
		DC: '0',
		NOMBRE: 'San Antonio de Benagéber',
	},
	{
		CPRO: '46',
		CMUN: '222',
		DC: '7',
		NOMBRE: 'Sant Joanet',
	},
	{
		CPRO: '46',
		CMUN: '223',
		DC: '3',
		NOMBRE: 'Sedaví',
	},
	{
		CPRO: '46',
		CMUN: '224',
		DC: '8',
		NOMBRE: 'Segart',
	},
	{
		CPRO: '46',
		CMUN: '225',
		DC: '1',
		NOMBRE: 'Sellent',
	},
	{
		CPRO: '46',
		CMUN: '226',
		DC: '4',
		NOMBRE: 'Sempere',
	},
	{
		CPRO: '46',
		CMUN: '227',
		DC: '0',
		NOMBRE: 'Senyera',
	},
	{
		CPRO: '46',
		CMUN: '228',
		DC: '6',
		NOMBRE: 'Serra',
	},
	{
		CPRO: '46',
		CMUN: '229',
		DC: '9',
		NOMBRE: 'Siete Aguas',
	},
	{
		CPRO: '46',
		CMUN: '230',
		DC: '3',
		NOMBRE: 'Silla',
	},
	{
		CPRO: '46',
		CMUN: '231',
		DC: '0',
		NOMBRE: 'Simat de la Valldigna',
	},
	{
		CPRO: '46',
		CMUN: '232',
		DC: '5',
		NOMBRE: 'Sinarcas',
	},
	{
		CPRO: '46',
		CMUN: '233',
		DC: '1',
		NOMBRE: 'Sollana',
	},
	{
		CPRO: '46',
		CMUN: '234',
		DC: '6',
		NOMBRE: 'Sot de Chera',
	},
	{
		CPRO: '46',
		CMUN: '235',
		DC: '9',
		NOMBRE: 'Sueca',
	},
	{
		CPRO: '46',
		CMUN: '236',
		DC: '2',
		NOMBRE: 'Sumacàrcer',
	},
	{
		CPRO: '46',
		CMUN: '237',
		DC: '8',
		NOMBRE: 'Tavernes Blanques',
	},
	{
		CPRO: '46',
		CMUN: '238',
		DC: '4',
		NOMBRE: 'Tavernes de la Valldigna',
	},
	{
		CPRO: '46',
		CMUN: '239',
		DC: '7',
		NOMBRE: 'Teresa de Cofrentes',
	},
	{
		CPRO: '46',
		CMUN: '240',
		DC: '1',
		NOMBRE: 'Terrateig',
	},
	{
		CPRO: '46',
		CMUN: '241',
		DC: '8',
		NOMBRE: 'Titaguas',
	},
	{
		CPRO: '46',
		CMUN: '242',
		DC: '3',
		NOMBRE: 'Torrebaja',
	},
	{
		CPRO: '46',
		CMUN: '243',
		DC: '9',
		NOMBRE: 'Torrella',
	},
	{
		CPRO: '46',
		CMUN: '244',
		DC: '4',
		NOMBRE: 'Torrent',
	},
	{
		CPRO: '46',
		CMUN: '245',
		DC: '7',
		NOMBRE: 'Torres Torres',
	},
	{
		CPRO: '46',
		CMUN: '246',
		DC: '0',
		NOMBRE: 'Tous',
	},
	{
		CPRO: '46',
		CMUN: '247',
		DC: '6',
		NOMBRE: 'Tuéjar',
	},
	{
		CPRO: '46',
		CMUN: '248',
		DC: '2',
		NOMBRE: 'Turís',
	},
	{
		CPRO: '46',
		CMUN: '249',
		DC: '5',
		NOMBRE: 'Utiel',
	},
	{
		CPRO: '46',
		CMUN: '250',
		DC: '8',
		NOMBRE: 'Valencia',
	},
	{
		CPRO: '46',
		CMUN: '251',
		DC: '5',
		NOMBRE: 'Vallada',
	},
	{
		CPRO: '46',
		CMUN: '252',
		DC: '0',
		NOMBRE: 'Vallanca',
	},
	{
		CPRO: '46',
		CMUN: '253',
		DC: '6',
		NOMBRE: 'Vallés',
	},
	{
		CPRO: '46',
		CMUN: '254',
		DC: '1',
		NOMBRE: 'Venta del Moro',
	},
	{
		CPRO: '46',
		CMUN: '256',
		DC: '7',
		NOMBRE: 'Vilamarxant',
	},
	{
		CPRO: '46',
		CMUN: '255',
		DC: '4',
		NOMBRE: 'Villalonga',
	},
	{
		CPRO: '46',
		CMUN: '257',
		DC: '3',
		NOMBRE: 'Villanueva de Castellón',
	},
	{
		CPRO: '46',
		CMUN: '258',
		DC: '9',
		NOMBRE: 'Villar del Arzobispo',
	},
	{
		CPRO: '46',
		CMUN: '259',
		DC: '2',
		NOMBRE: 'Villargordo del Cabriel',
	},
	{
		CPRO: '46',
		CMUN: '260',
		DC: '6',
		NOMBRE: 'Vinalesa',
	},
	{
		CPRO: '46',
		CMUN: '145',
		DC: '8',
		NOMBRE: 'Xàtiva',
	},
	{
		CPRO: '46',
		CMUN: '143',
		DC: '0',
		NOMBRE: 'Xeraco',
	},
	{
		CPRO: '46',
		CMUN: '146',
		DC: '1',
		NOMBRE: 'Xeresa',
	},
	{
		CPRO: '46',
		CMUN: '110',
		DC: '8',
		NOMBRE: 'Xirivella',
	},
	{
		CPRO: '46',
		CMUN: '261',
		DC: '3',
		NOMBRE: 'Yátova',
	},
	{
		CPRO: '46',
		CMUN: '262',
		DC: '8',
		NOMBRE: 'Yesa, La',
	},
	{
		CPRO: '46',
		CMUN: '263',
		DC: '4',
		NOMBRE: 'Zarra',
	},
	{
		CPRO: '47',
		CMUN: '001',
		DC: '4',
		NOMBRE: 'Adalia',
	},
	{
		CPRO: '47',
		CMUN: '002',
		DC: '9',
		NOMBRE: 'Aguasal',
	},
	{
		CPRO: '47',
		CMUN: '003',
		DC: '5',
		NOMBRE: 'Aguilar de Campos',
	},
	{
		CPRO: '47',
		CMUN: '004',
		DC: '0',
		NOMBRE: 'Alaejos',
	},
	{
		CPRO: '47',
		CMUN: '005',
		DC: '3',
		NOMBRE: 'Alcazarén',
	},
	{
		CPRO: '47',
		CMUN: '006',
		DC: '6',
		NOMBRE: 'Aldea de San Miguel',
	},
	{
		CPRO: '47',
		CMUN: '007',
		DC: '2',
		NOMBRE: 'Aldeamayor de San Martín',
	},
	{
		CPRO: '47',
		CMUN: '008',
		DC: '8',
		NOMBRE: 'Almenara de Adaja',
	},
	{
		CPRO: '47',
		CMUN: '009',
		DC: '1',
		NOMBRE: 'Amusquillo',
	},
	{
		CPRO: '47',
		CMUN: '010',
		DC: '5',
		NOMBRE: 'Arroyo de la Encomienda',
	},
	{
		CPRO: '47',
		CMUN: '011',
		DC: '2',
		NOMBRE: 'Ataquines',
	},
	{
		CPRO: '47',
		CMUN: '012',
		DC: '7',
		NOMBRE: 'Bahabón',
	},
	{
		CPRO: '47',
		CMUN: '013',
		DC: '3',
		NOMBRE: 'Barcial de la Loma',
	},
	{
		CPRO: '47',
		CMUN: '014',
		DC: '8',
		NOMBRE: 'Barruelo del Valle',
	},
	{
		CPRO: '47',
		CMUN: '015',
		DC: '1',
		NOMBRE: 'Becilla de Valderaduey',
	},
	{
		CPRO: '47',
		CMUN: '016',
		DC: '4',
		NOMBRE: 'Benafarces',
	},
	{
		CPRO: '47',
		CMUN: '017',
		DC: '0',
		NOMBRE: 'Bercero',
	},
	{
		CPRO: '47',
		CMUN: '018',
		DC: '6',
		NOMBRE: 'Berceruelo',
	},
	{
		CPRO: '47',
		CMUN: '019',
		DC: '9',
		NOMBRE: 'Berrueces',
	},
	{
		CPRO: '47',
		CMUN: '020',
		DC: '3',
		NOMBRE: 'Bobadilla del Campo',
	},
	{
		CPRO: '47',
		CMUN: '021',
		DC: '0',
		NOMBRE: 'Bocigas',
	},
	{
		CPRO: '47',
		CMUN: '022',
		DC: '5',
		NOMBRE: 'Bocos de Duero',
	},
	{
		CPRO: '47',
		CMUN: '023',
		DC: '1',
		NOMBRE: 'Boecillo',
	},
	{
		CPRO: '47',
		CMUN: '024',
		DC: '6',
		NOMBRE: 'Bolaños de Campos',
	},
	{
		CPRO: '47',
		CMUN: '025',
		DC: '9',
		NOMBRE: 'Brahojos de Medina',
	},
	{
		CPRO: '47',
		CMUN: '026',
		DC: '2',
		NOMBRE: 'Bustillo de Chaves',
	},
	{
		CPRO: '47',
		CMUN: '027',
		DC: '8',
		NOMBRE: 'Cabezón de Pisuerga',
	},
	{
		CPRO: '47',
		CMUN: '028',
		DC: '4',
		NOMBRE: 'Cabezón de Valderaduey',
	},
	{
		CPRO: '47',
		CMUN: '029',
		DC: '7',
		NOMBRE: 'Cabreros del Monte',
	},
	{
		CPRO: '47',
		CMUN: '030',
		DC: '1',
		NOMBRE: 'Campaspero',
	},
	{
		CPRO: '47',
		CMUN: '031',
		DC: '8',
		NOMBRE: 'Campillo, El',
	},
	{
		CPRO: '47',
		CMUN: '032',
		DC: '3',
		NOMBRE: 'Camporredondo',
	},
	{
		CPRO: '47',
		CMUN: '033',
		DC: '9',
		NOMBRE: 'Canalejas de Peñafiel',
	},
	{
		CPRO: '47',
		CMUN: '034',
		DC: '4',
		NOMBRE: 'Canillas de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '035',
		DC: '7',
		NOMBRE: 'Carpio',
	},
	{
		CPRO: '47',
		CMUN: '036',
		DC: '0',
		NOMBRE: 'Casasola de Arión',
	},
	{
		CPRO: '47',
		CMUN: '037',
		DC: '6',
		NOMBRE: 'Castrejón de Trabancos',
	},
	{
		CPRO: '47',
		CMUN: '038',
		DC: '2',
		NOMBRE: 'Castrillo de Duero',
	},
	{
		CPRO: '47',
		CMUN: '039',
		DC: '5',
		NOMBRE: 'Castrillo-Tejeriego',
	},
	{
		CPRO: '47',
		CMUN: '040',
		DC: '9',
		NOMBRE: 'Castrobol',
	},
	{
		CPRO: '47',
		CMUN: '041',
		DC: '6',
		NOMBRE: 'Castrodeza',
	},
	{
		CPRO: '47',
		CMUN: '042',
		DC: '1',
		NOMBRE: 'Castromembibre',
	},
	{
		CPRO: '47',
		CMUN: '043',
		DC: '7',
		NOMBRE: 'Castromonte',
	},
	{
		CPRO: '47',
		CMUN: '044',
		DC: '2',
		NOMBRE: 'Castronuevo de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '045',
		DC: '5',
		NOMBRE: 'Castronuño',
	},
	{
		CPRO: '47',
		CMUN: '046',
		DC: '8',
		NOMBRE: 'Castroponce',
	},
	{
		CPRO: '47',
		CMUN: '047',
		DC: '4',
		NOMBRE: 'Castroverde de Cerrato',
	},
	{
		CPRO: '47',
		CMUN: '048',
		DC: '0',
		NOMBRE: 'Ceinos de Campos',
	},
	{
		CPRO: '47',
		CMUN: '049',
		DC: '3',
		NOMBRE: 'Cervillego de la Cruz',
	},
	{
		CPRO: '47',
		CMUN: '050',
		DC: '6',
		NOMBRE: 'Cigales',
	},
	{
		CPRO: '47',
		CMUN: '051',
		DC: '3',
		NOMBRE: 'Ciguñuela',
	},
	{
		CPRO: '47',
		CMUN: '052',
		DC: '8',
		NOMBRE: 'Cistérniga',
	},
	{
		CPRO: '47',
		CMUN: '053',
		DC: '4',
		NOMBRE: 'Cogeces de Íscar',
	},
	{
		CPRO: '47',
		CMUN: '054',
		DC: '9',
		NOMBRE: 'Cogeces del Monte',
	},
	{
		CPRO: '47',
		CMUN: '055',
		DC: '2',
		NOMBRE: 'Corcos',
	},
	{
		CPRO: '47',
		CMUN: '056',
		DC: '5',
		NOMBRE: 'Corrales de Duero',
	},
	{
		CPRO: '47',
		CMUN: '057',
		DC: '1',
		NOMBRE: 'Cubillas de Santa Marta',
	},
	{
		CPRO: '47',
		CMUN: '058',
		DC: '7',
		NOMBRE: 'Cuenca de Campos',
	},
	{
		CPRO: '47',
		CMUN: '059',
		DC: '0',
		NOMBRE: 'Curiel de Duero',
	},
	{
		CPRO: '47',
		CMUN: '060',
		DC: '4',
		NOMBRE: 'Encinas de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '061',
		DC: '1',
		NOMBRE: 'Esguevillas de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '062',
		DC: '6',
		NOMBRE: 'Fombellida',
	},
	{
		CPRO: '47',
		CMUN: '063',
		DC: '2',
		NOMBRE: 'Fompedraza',
	},
	{
		CPRO: '47',
		CMUN: '064',
		DC: '7',
		NOMBRE: 'Fontihoyuelo',
	},
	{
		CPRO: '47',
		CMUN: '065',
		DC: '0',
		NOMBRE: 'Fresno el Viejo',
	},
	{
		CPRO: '47',
		CMUN: '066',
		DC: '3',
		NOMBRE: 'Fuensaldaña',
	},
	{
		CPRO: '47',
		CMUN: '067',
		DC: '9',
		NOMBRE: 'Fuente el Sol',
	},
	{
		CPRO: '47',
		CMUN: '068',
		DC: '5',
		NOMBRE: 'Fuente-Olmedo',
	},
	{
		CPRO: '47',
		CMUN: '069',
		DC: '8',
		NOMBRE: 'Gallegos de Hornija',
	},
	{
		CPRO: '47',
		CMUN: '070',
		DC: '2',
		NOMBRE: 'Gatón de Campos',
	},
	{
		CPRO: '47',
		CMUN: '071',
		DC: '9',
		NOMBRE: 'Geria',
	},
	{
		CPRO: '47',
		CMUN: '073',
		DC: '0',
		NOMBRE: 'Herrín de Campos',
	},
	{
		CPRO: '47',
		CMUN: '074',
		DC: '5',
		NOMBRE: 'Hornillos de Eresma',
	},
	{
		CPRO: '47',
		CMUN: '075',
		DC: '8',
		NOMBRE: 'Íscar',
	},
	{
		CPRO: '47',
		CMUN: '076',
		DC: '1',
		NOMBRE: 'Laguna de Duero',
	},
	{
		CPRO: '47',
		CMUN: '077',
		DC: '7',
		NOMBRE: 'Langayo',
	},
	{
		CPRO: '47',
		CMUN: '079',
		DC: '6',
		NOMBRE: 'Llano de Olmedo',
	},
	{
		CPRO: '47',
		CMUN: '078',
		DC: '3',
		NOMBRE: 'Lomoviejo',
	},
	{
		CPRO: '47',
		CMUN: '080',
		DC: '0',
		NOMBRE: 'Manzanillo',
	},
	{
		CPRO: '47',
		CMUN: '081',
		DC: '7',
		NOMBRE: 'Marzales',
	},
	{
		CPRO: '47',
		CMUN: '082',
		DC: '2',
		NOMBRE: 'Matapozuelos',
	},
	{
		CPRO: '47',
		CMUN: '083',
		DC: '8',
		NOMBRE: 'Matilla de los Caños',
	},
	{
		CPRO: '47',
		CMUN: '084',
		DC: '3',
		NOMBRE: 'Mayorga',
	},
	{
		CPRO: '47',
		CMUN: '086',
		DC: '9',
		NOMBRE: 'Medina de Rioseco',
	},
	{
		CPRO: '47',
		CMUN: '085',
		DC: '6',
		NOMBRE: 'Medina del Campo',
	},
	{
		CPRO: '47',
		CMUN: '087',
		DC: '5',
		NOMBRE: 'Megeces',
	},
	{
		CPRO: '47',
		CMUN: '088',
		DC: '1',
		NOMBRE: 'Melgar de Abajo',
	},
	{
		CPRO: '47',
		CMUN: '089',
		DC: '4',
		NOMBRE: 'Melgar de Arriba',
	},
	{
		CPRO: '47',
		CMUN: '090',
		DC: '8',
		NOMBRE: 'Mojados',
	},
	{
		CPRO: '47',
		CMUN: '091',
		DC: '5',
		NOMBRE: 'Monasterio de Vega',
	},
	{
		CPRO: '47',
		CMUN: '092',
		DC: '0',
		NOMBRE: 'Montealegre de Campos',
	},
	{
		CPRO: '47',
		CMUN: '093',
		DC: '6',
		NOMBRE: 'Montemayor de Pililla',
	},
	{
		CPRO: '47',
		CMUN: '094',
		DC: '1',
		NOMBRE: 'Moral de la Reina',
	},
	{
		CPRO: '47',
		CMUN: '095',
		DC: '4',
		NOMBRE: 'Moraleja de las Panaderas',
	},
	{
		CPRO: '47',
		CMUN: '096',
		DC: '7',
		NOMBRE: 'Morales de Campos',
	},
	{
		CPRO: '47',
		CMUN: '097',
		DC: '3',
		NOMBRE: 'Mota del Marqués',
	},
	{
		CPRO: '47',
		CMUN: '098',
		DC: '9',
		NOMBRE: 'Mucientes',
	},
	{
		CPRO: '47',
		CMUN: '099',
		DC: '2',
		NOMBRE: 'Mudarra, La',
	},
	{
		CPRO: '47',
		CMUN: '100',
		DC: '6',
		NOMBRE: 'Muriel',
	},
	{
		CPRO: '47',
		CMUN: '101',
		DC: '3',
		NOMBRE: 'Nava del Rey',
	},
	{
		CPRO: '47',
		CMUN: '102',
		DC: '8',
		NOMBRE: 'Nueva Villa de las Torres',
	},
	{
		CPRO: '47',
		CMUN: '103',
		DC: '4',
		NOMBRE: 'Olivares de Duero',
	},
	{
		CPRO: '47',
		CMUN: '104',
		DC: '9',
		NOMBRE: 'Olmedo',
	},
	{
		CPRO: '47',
		CMUN: '105',
		DC: '2',
		NOMBRE: 'Olmos de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '106',
		DC: '5',
		NOMBRE: 'Olmos de Peñafiel',
	},
	{
		CPRO: '47',
		CMUN: '109',
		DC: '0',
		NOMBRE: 'Palazuelo de Vedija',
	},
	{
		CPRO: '47',
		CMUN: '110',
		DC: '4',
		NOMBRE: 'Parrilla, La',
	},
	{
		CPRO: '47',
		CMUN: '111',
		DC: '1',
		NOMBRE: 'Pedraja de Portillo, La',
	},
	{
		CPRO: '47',
		CMUN: '112',
		DC: '6',
		NOMBRE: 'Pedrajas de San Esteban',
	},
	{
		CPRO: '47',
		CMUN: '113',
		DC: '2',
		NOMBRE: 'Pedrosa del Rey',
	},
	{
		CPRO: '47',
		CMUN: '114',
		DC: '7',
		NOMBRE: 'Peñafiel',
	},
	{
		CPRO: '47',
		CMUN: '115',
		DC: '0',
		NOMBRE: 'Peñaflor de Hornija',
	},
	{
		CPRO: '47',
		CMUN: '116',
		DC: '3',
		NOMBRE: 'Pesquera de Duero',
	},
	{
		CPRO: '47',
		CMUN: '117',
		DC: '9',
		NOMBRE: 'Piña de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '118',
		DC: '5',
		NOMBRE: 'Piñel de Abajo',
	},
	{
		CPRO: '47',
		CMUN: '119',
		DC: '8',
		NOMBRE: 'Piñel de Arriba',
	},
	{
		CPRO: '47',
		CMUN: '121',
		DC: '9',
		NOMBRE: 'Pollos',
	},
	{
		CPRO: '47',
		CMUN: '122',
		DC: '4',
		NOMBRE: 'Portillo',
	},
	{
		CPRO: '47',
		CMUN: '123',
		DC: '0',
		NOMBRE: 'Pozal de Gallinas',
	},
	{
		CPRO: '47',
		CMUN: '124',
		DC: '5',
		NOMBRE: 'Pozaldez',
	},
	{
		CPRO: '47',
		CMUN: '125',
		DC: '8',
		NOMBRE: 'Pozuelo de la Orden',
	},
	{
		CPRO: '47',
		CMUN: '126',
		DC: '1',
		NOMBRE: 'Puras',
	},
	{
		CPRO: '47',
		CMUN: '127',
		DC: '7',
		NOMBRE: 'Quintanilla de Arriba',
	},
	{
		CPRO: '47',
		CMUN: '129',
		DC: '6',
		NOMBRE: 'Quintanilla de Onésimo',
	},
	{
		CPRO: '47',
		CMUN: '130',
		DC: '0',
		NOMBRE: 'Quintanilla de Trigueros',
	},
	{
		CPRO: '47',
		CMUN: '128',
		DC: '3',
		NOMBRE: 'Quintanilla del Molar',
	},
	{
		CPRO: '47',
		CMUN: '131',
		DC: '7',
		NOMBRE: 'Rábano',
	},
	{
		CPRO: '47',
		CMUN: '132',
		DC: '2',
		NOMBRE: 'Ramiro',
	},
	{
		CPRO: '47',
		CMUN: '133',
		DC: '8',
		NOMBRE: 'Renedo de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '134',
		DC: '3',
		NOMBRE: 'Roales de Campos',
	},
	{
		CPRO: '47',
		CMUN: '135',
		DC: '6',
		NOMBRE: 'Robladillo',
	},
	{
		CPRO: '47',
		CMUN: '137',
		DC: '5',
		NOMBRE: 'Roturas',
	},
	{
		CPRO: '47',
		CMUN: '138',
		DC: '1',
		NOMBRE: 'Rubí de Bracamonte',
	},
	{
		CPRO: '47',
		CMUN: '139',
		DC: '4',
		NOMBRE: 'Rueda',
	},
	{
		CPRO: '47',
		CMUN: '140',
		DC: '8',
		NOMBRE: 'Saelices de Mayorga',
	},
	{
		CPRO: '47',
		CMUN: '141',
		DC: '5',
		NOMBRE: 'Salvador de Zapardiel',
	},
	{
		CPRO: '47',
		CMUN: '142',
		DC: '0',
		NOMBRE: 'San Cebrián de Mazote',
	},
	{
		CPRO: '47',
		CMUN: '143',
		DC: '6',
		NOMBRE: 'San Llorente',
	},
	{
		CPRO: '47',
		CMUN: '144',
		DC: '1',
		NOMBRE: 'San Martín de Valvení',
	},
	{
		CPRO: '47',
		CMUN: '145',
		DC: '4',
		NOMBRE: 'San Miguel del Arroyo',
	},
	{
		CPRO: '47',
		CMUN: '146',
		DC: '7',
		NOMBRE: 'San Miguel del Pino',
	},
	{
		CPRO: '47',
		CMUN: '147',
		DC: '3',
		NOMBRE: 'San Pablo de la Moraleja',
	},
	{
		CPRO: '47',
		CMUN: '148',
		DC: '9',
		NOMBRE: 'San Pedro de Latarce',
	},
	{
		CPRO: '47',
		CMUN: '149',
		DC: '2',
		NOMBRE: 'San Pelayo',
	},
	{
		CPRO: '47',
		CMUN: '150',
		DC: '5',
		NOMBRE: 'San Román de Hornija',
	},
	{
		CPRO: '47',
		CMUN: '151',
		DC: '2',
		NOMBRE: 'San Salvador',
	},
	{
		CPRO: '47',
		CMUN: '156',
		DC: '4',
		NOMBRE: 'San Vicente del Palacio',
	},
	{
		CPRO: '47',
		CMUN: '152',
		DC: '7',
		NOMBRE: 'Santa Eufemia del Arroyo',
	},
	{
		CPRO: '47',
		CMUN: '153',
		DC: '3',
		NOMBRE: 'Santervás de Campos',
	},
	{
		CPRO: '47',
		CMUN: '154',
		DC: '8',
		NOMBRE: 'Santibáñez de Valcorba',
	},
	{
		CPRO: '47',
		CMUN: '155',
		DC: '1',
		NOMBRE: 'Santovenia de Pisuerga',
	},
	{
		CPRO: '47',
		CMUN: '157',
		DC: '0',
		NOMBRE: 'Sardón de Duero',
	},
	{
		CPRO: '47',
		CMUN: '158',
		DC: '6',
		NOMBRE: 'Seca, La',
	},
	{
		CPRO: '47',
		CMUN: '159',
		DC: '9',
		NOMBRE: 'Serrada',
	},
	{
		CPRO: '47',
		CMUN: '160',
		DC: '3',
		NOMBRE: 'Siete Iglesias de Trabancos',
	},
	{
		CPRO: '47',
		CMUN: '161',
		DC: '0',
		NOMBRE: 'Simancas',
	},
	{
		CPRO: '47',
		CMUN: '162',
		DC: '5',
		NOMBRE: 'Tamariz de Campos',
	},
	{
		CPRO: '47',
		CMUN: '163',
		DC: '1',
		NOMBRE: 'Tiedra',
	},
	{
		CPRO: '47',
		CMUN: '164',
		DC: '6',
		NOMBRE: 'Tordehumos',
	},
	{
		CPRO: '47',
		CMUN: '165',
		DC: '9',
		NOMBRE: 'Tordesillas',
	},
	{
		CPRO: '47',
		CMUN: '169',
		DC: '7',
		NOMBRE: 'Torre de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '170',
		DC: '1',
		NOMBRE: 'Torre de Peñafiel',
	},
	{
		CPRO: '47',
		CMUN: '166',
		DC: '2',
		NOMBRE: 'Torrecilla de la Abadesa',
	},
	{
		CPRO: '47',
		CMUN: '167',
		DC: '8',
		NOMBRE: 'Torrecilla de la Orden',
	},
	{
		CPRO: '47',
		CMUN: '168',
		DC: '4',
		NOMBRE: 'Torrecilla de la Torre',
	},
	{
		CPRO: '47',
		CMUN: '171',
		DC: '8',
		NOMBRE: 'Torrelobatón',
	},
	{
		CPRO: '47',
		CMUN: '172',
		DC: '3',
		NOMBRE: 'Torrescárcela',
	},
	{
		CPRO: '47',
		CMUN: '173',
		DC: '9',
		NOMBRE: 'Traspinedo',
	},
	{
		CPRO: '47',
		CMUN: '174',
		DC: '4',
		NOMBRE: 'Trigueros del Valle',
	},
	{
		CPRO: '47',
		CMUN: '175',
		DC: '7',
		NOMBRE: 'Tudela de Duero',
	},
	{
		CPRO: '47',
		CMUN: '176',
		DC: '0',
		NOMBRE: 'Unión de Campos, La',
	},
	{
		CPRO: '47',
		CMUN: '177',
		DC: '6',
		NOMBRE: 'Urones de Castroponce',
	},
	{
		CPRO: '47',
		CMUN: '178',
		DC: '2',
		NOMBRE: 'Urueña',
	},
	{
		CPRO: '47',
		CMUN: '179',
		DC: '5',
		NOMBRE: 'Valbuena de Duero',
	},
	{
		CPRO: '47',
		CMUN: '180',
		DC: '9',
		NOMBRE: 'Valdearcos de la Vega',
	},
	{
		CPRO: '47',
		CMUN: '181',
		DC: '6',
		NOMBRE: 'Valdenebro de los Valles',
	},
	{
		CPRO: '47',
		CMUN: '182',
		DC: '1',
		NOMBRE: 'Valdestillas',
	},
	{
		CPRO: '47',
		CMUN: '183',
		DC: '7',
		NOMBRE: 'Valdunquillo',
	},
	{
		CPRO: '47',
		CMUN: '186',
		DC: '8',
		NOMBRE: 'Valladolid',
	},
	{
		CPRO: '47',
		CMUN: '184',
		DC: '2',
		NOMBRE: 'Valoria la Buena',
	},
	{
		CPRO: '47',
		CMUN: '185',
		DC: '5',
		NOMBRE: 'Valverde de Campos',
	},
	{
		CPRO: '47',
		CMUN: '187',
		DC: '4',
		NOMBRE: 'Vega de Ruiponce',
	},
	{
		CPRO: '47',
		CMUN: '188',
		DC: '0',
		NOMBRE: 'Vega de Valdetronco',
	},
	{
		CPRO: '47',
		CMUN: '189',
		DC: '3',
		NOMBRE: 'Velascálvaro',
	},
	{
		CPRO: '47',
		CMUN: '190',
		DC: '7',
		NOMBRE: 'Velilla',
	},
	{
		CPRO: '47',
		CMUN: '191',
		DC: '4',
		NOMBRE: 'Velliza',
	},
	{
		CPRO: '47',
		CMUN: '192',
		DC: '9',
		NOMBRE: 'Ventosa de la Cuesta',
	},
	{
		CPRO: '47',
		CMUN: '193',
		DC: '5',
		NOMBRE: 'Viana de Cega',
	},
	{
		CPRO: '47',
		CMUN: '195',
		DC: '3',
		NOMBRE: 'Villabáñez',
	},
	{
		CPRO: '47',
		CMUN: '196',
		DC: '6',
		NOMBRE: 'Villabaruz de Campos',
	},
	{
		CPRO: '47',
		CMUN: '197',
		DC: '2',
		NOMBRE: 'Villabrágima',
	},
	{
		CPRO: '47',
		CMUN: '198',
		DC: '8',
		NOMBRE: 'Villacarralón',
	},
	{
		CPRO: '47',
		CMUN: '199',
		DC: '1',
		NOMBRE: 'Villacid de Campos',
	},
	{
		CPRO: '47',
		CMUN: '200',
		DC: '5',
		NOMBRE: 'Villaco',
	},
	{
		CPRO: '47',
		CMUN: '203',
		DC: '3',
		NOMBRE: 'Villafrades de Campos',
	},
	{
		CPRO: '47',
		CMUN: '204',
		DC: '8',
		NOMBRE: 'Villafranca de Duero',
	},
	{
		CPRO: '47',
		CMUN: '205',
		DC: '1',
		NOMBRE: 'Villafrechós',
	},
	{
		CPRO: '47',
		CMUN: '206',
		DC: '4',
		NOMBRE: 'Villafuerte',
	},
	{
		CPRO: '47',
		CMUN: '207',
		DC: '0',
		NOMBRE: 'Villagarcía de Campos',
	},
	{
		CPRO: '47',
		CMUN: '208',
		DC: '6',
		NOMBRE: 'Villagómez la Nueva',
	},
	{
		CPRO: '47',
		CMUN: '209',
		DC: '9',
		NOMBRE: 'Villalán de Campos',
	},
	{
		CPRO: '47',
		CMUN: '210',
		DC: '3',
		NOMBRE: 'Villalar de los Comuneros',
	},
	{
		CPRO: '47',
		CMUN: '211',
		DC: '0',
		NOMBRE: 'Villalba de la Loma',
	},
	{
		CPRO: '47',
		CMUN: '212',
		DC: '5',
		NOMBRE: 'Villalba de los Alcores',
	},
	{
		CPRO: '47',
		CMUN: '213',
		DC: '1',
		NOMBRE: 'Villalbarba',
	},
	{
		CPRO: '47',
		CMUN: '214',
		DC: '6',
		NOMBRE: 'Villalón de Campos',
	},
	{
		CPRO: '47',
		CMUN: '215',
		DC: '9',
		NOMBRE: 'Villamuriel de Campos',
	},
	{
		CPRO: '47',
		CMUN: '216',
		DC: '2',
		NOMBRE: 'Villán de Tordesillas',
	},
	{
		CPRO: '47',
		CMUN: '217',
		DC: '8',
		NOMBRE: 'Villanubla',
	},
	{
		CPRO: '47',
		CMUN: '218',
		DC: '4',
		NOMBRE: 'Villanueva de Duero',
	},
	{
		CPRO: '47',
		CMUN: '219',
		DC: '7',
		NOMBRE: 'Villanueva de la Condesa',
	},
	{
		CPRO: '47',
		CMUN: '220',
		DC: '1',
		NOMBRE: 'Villanueva de los Caballeros',
	},
	{
		CPRO: '47',
		CMUN: '221',
		DC: '8',
		NOMBRE: 'Villanueva de los Infantes',
	},
	{
		CPRO: '47',
		CMUN: '222',
		DC: '3',
		NOMBRE: 'Villanueva de San Mancio',
	},
	{
		CPRO: '47',
		CMUN: '223',
		DC: '9',
		NOMBRE: 'Villardefrades',
	},
	{
		CPRO: '47',
		CMUN: '224',
		DC: '4',
		NOMBRE: 'Villarmentero de Esgueva',
	},
	{
		CPRO: '47',
		CMUN: '225',
		DC: '7',
		NOMBRE: 'Villasexmir',
	},
	{
		CPRO: '47',
		CMUN: '226',
		DC: '0',
		NOMBRE: 'Villavaquerín',
	},
	{
		CPRO: '47',
		CMUN: '227',
		DC: '6',
		NOMBRE: 'Villavellid',
	},
	{
		CPRO: '47',
		CMUN: '228',
		DC: '2',
		NOMBRE: 'Villaverde de Medina',
	},
	{
		CPRO: '47',
		CMUN: '229',
		DC: '5',
		NOMBRE: 'Villavicencio de los Caballeros',
	},
	{
		CPRO: '47',
		CMUN: '194',
		DC: '0',
		NOMBRE: 'Viloria',
	},
	{
		CPRO: '47',
		CMUN: '230',
		DC: '9',
		NOMBRE: 'Wamba',
	},
	{
		CPRO: '47',
		CMUN: '231',
		DC: '6',
		NOMBRE: 'Zaratán',
	},
	{
		CPRO: '47',
		CMUN: '232',
		DC: '1',
		NOMBRE: 'Zarza, La',
	},
	{
		CPRO: '48',
		CMUN: '001',
		DC: '0',
		NOMBRE: 'Abadiño',
	},
	{
		CPRO: '48',
		CMUN: '002',
		DC: '5',
		NOMBRE: 'Abanto y Ciérvana-Abanto Zierbena',
	},
	{
		CPRO: '48',
		CMUN: '911',
		DC: '9',
		NOMBRE: 'Ajangiz',
	},
	{
		CPRO: '48',
		CMUN: '912',
		DC: '4',
		NOMBRE: 'Alonsotegi',
	},
	{
		CPRO: '48',
		CMUN: '003',
		DC: '1',
		NOMBRE: 'Amorebieta-Etxano',
	},
	{
		CPRO: '48',
		CMUN: '004',
		DC: '6',
		NOMBRE: 'Amoroto',
	},
	{
		CPRO: '48',
		CMUN: '005',
		DC: '9',
		NOMBRE: 'Arakaldo',
	},
	{
		CPRO: '48',
		CMUN: '006',
		DC: '2',
		NOMBRE: 'Arantzazu',
	},
	{
		CPRO: '48',
		CMUN: '093',
		DC: '2',
		NOMBRE: 'Areatza',
	},
	{
		CPRO: '48',
		CMUN: '009',
		DC: '7',
		NOMBRE: 'Arrankudiaga',
	},
	{
		CPRO: '48',
		CMUN: '914',
		DC: '5',
		NOMBRE: 'Arratzu',
	},
	{
		CPRO: '48',
		CMUN: '010',
		DC: '1',
		NOMBRE: 'Arrieta',
	},
	{
		CPRO: '48',
		CMUN: '011',
		DC: '8',
		NOMBRE: 'Arrigorriaga',
	},
	{
		CPRO: '48',
		CMUN: '023',
		DC: '7',
		NOMBRE: 'Artea',
	},
	{
		CPRO: '48',
		CMUN: '008',
		DC: '4',
		NOMBRE: 'Artzentales',
	},
	{
		CPRO: '48',
		CMUN: '091',
		DC: '1',
		NOMBRE: 'Atxondo',
	},
	{
		CPRO: '48',
		CMUN: '070',
		DC: '8',
		NOMBRE: 'Aulesti',
	},
	{
		CPRO: '48',
		CMUN: '012',
		DC: '3',
		NOMBRE: 'Bakio',
	},
	{
		CPRO: '48',
		CMUN: '090',
		DC: '4',
		NOMBRE: 'Balmaseda',
	},
	{
		CPRO: '48',
		CMUN: '013',
		DC: '9',
		NOMBRE: 'Barakaldo',
	},
	{
		CPRO: '48',
		CMUN: '014',
		DC: '4',
		NOMBRE: 'Barrika',
	},
	{
		CPRO: '48',
		CMUN: '015',
		DC: '7',
		NOMBRE: 'Basauri',
	},
	{
		CPRO: '48',
		CMUN: '092',
		DC: '6',
		NOMBRE: 'Bedia',
	},
	{
		CPRO: '48',
		CMUN: '016',
		DC: '0',
		NOMBRE: 'Berango',
	},
	{
		CPRO: '48',
		CMUN: '017',
		DC: '6',
		NOMBRE: 'Bermeo',
	},
	{
		CPRO: '48',
		CMUN: '018',
		DC: '2',
		NOMBRE: 'Berriatua',
	},
	{
		CPRO: '48',
		CMUN: '019',
		DC: '5',
		NOMBRE: 'Berriz',
	},
	{
		CPRO: '48',
		CMUN: '020',
		DC: '9',
		NOMBRE: 'Bilbao',
	},
	{
		CPRO: '48',
		CMUN: '021',
		DC: '6',
		NOMBRE: 'Busturia',
	},
	{
		CPRO: '48',
		CMUN: '901',
		DC: '1',
		NOMBRE: 'Derio',
	},
	{
		CPRO: '48',
		CMUN: '026',
		DC: '8',
		NOMBRE: 'Dima',
	},
	{
		CPRO: '48',
		CMUN: '027',
		DC: '4',
		NOMBRE: 'Durango',
	},
	{
		CPRO: '48',
		CMUN: '028',
		DC: '0',
		NOMBRE: 'Ea',
	},
	{
		CPRO: '48',
		CMUN: '031',
		DC: '4',
		NOMBRE: 'Elantxobe',
	},
	{
		CPRO: '48',
		CMUN: '032',
		DC: '9',
		NOMBRE: 'Elorrio',
	},
	{
		CPRO: '48',
		CMUN: '902',
		DC: '6',
		NOMBRE: 'Erandio',
	},
	{
		CPRO: '48',
		CMUN: '033',
		DC: '5',
		NOMBRE: 'Ereño',
	},
	{
		CPRO: '48',
		CMUN: '034',
		DC: '0',
		NOMBRE: 'Ermua',
	},
	{
		CPRO: '48',
		CMUN: '079',
		DC: '2',
		NOMBRE: 'Errigoiti',
	},
	{
		CPRO: '48',
		CMUN: '029',
		DC: '3',
		NOMBRE: 'Etxebarri',
	},
	{
		CPRO: '48',
		CMUN: '030',
		DC: '7',
		NOMBRE: 'Etxebarria',
	},
	{
		CPRO: '48',
		CMUN: '906',
		DC: '3',
		NOMBRE: 'Forua',
	},
	{
		CPRO: '48',
		CMUN: '035',
		DC: '3',
		NOMBRE: 'Fruiz',
	},
	{
		CPRO: '48',
		CMUN: '036',
		DC: '6',
		NOMBRE: 'Galdakao',
	},
	{
		CPRO: '48',
		CMUN: '037',
		DC: '2',
		NOMBRE: 'Galdames',
	},
	{
		CPRO: '48',
		CMUN: '038',
		DC: '8',
		NOMBRE: 'Gamiz-Fika',
	},
	{
		CPRO: '48',
		CMUN: '039',
		DC: '1',
		NOMBRE: 'Garai',
	},
	{
		CPRO: '48',
		CMUN: '040',
		DC: '5',
		NOMBRE: 'Gatika',
	},
	{
		CPRO: '48',
		CMUN: '041',
		DC: '2',
		NOMBRE: 'Gautegiz Arteaga',
	},
	{
		CPRO: '48',
		CMUN: '046',
		DC: '4',
		NOMBRE: 'Gernika-Lumo',
	},
	{
		CPRO: '48',
		CMUN: '044',
		DC: '8',
		NOMBRE: 'Getxo',
	},
	{
		CPRO: '48',
		CMUN: '047',
		DC: '0',
		NOMBRE: 'Gizaburuaga',
	},
	{
		CPRO: '48',
		CMUN: '042',
		DC: '7',
		NOMBRE: 'Gordexola',
	},
	{
		CPRO: '48',
		CMUN: '043',
		DC: '3',
		NOMBRE: 'Gorliz',
	},
	{
		CPRO: '48',
		CMUN: '045',
		DC: '1',
		NOMBRE: 'Güeñes',
	},
	{
		CPRO: '48',
		CMUN: '048',
		DC: '6',
		NOMBRE: 'Ibarrangelu',
	},
	{
		CPRO: '48',
		CMUN: '094',
		DC: '7',
		NOMBRE: 'Igorre',
	},
	{
		CPRO: '48',
		CMUN: '049',
		DC: '9',
		NOMBRE: 'Ispaster',
	},
	{
		CPRO: '48',
		CMUN: '910',
		DC: '2',
		NOMBRE: 'Iurreta',
	},
	{
		CPRO: '48',
		CMUN: '050',
		DC: '2',
		NOMBRE: 'Izurtza',
	},
	{
		CPRO: '48',
		CMUN: '022',
		DC: '1',
		NOMBRE: 'Karrantza Harana/Valle de Carranza',
	},
	{
		CPRO: '48',
		CMUN: '907',
		DC: '9',
		NOMBRE: 'Kortezubi',
	},
	{
		CPRO: '48',
		CMUN: '051',
		DC: '9',
		NOMBRE: 'Lanestosa',
	},
	{
		CPRO: '48',
		CMUN: '052',
		DC: '4',
		NOMBRE: 'Larrabetzu',
	},
	{
		CPRO: '48',
		CMUN: '053',
		DC: '0',
		NOMBRE: 'Laukiz',
	},
	{
		CPRO: '48',
		CMUN: '054',
		DC: '5',
		NOMBRE: 'Leioa',
	},
	{
		CPRO: '48',
		CMUN: '057',
		DC: '7',
		NOMBRE: 'Lekeitio',
	},
	{
		CPRO: '48',
		CMUN: '055',
		DC: '8',
		NOMBRE: 'Lemoa',
	},
	{
		CPRO: '48',
		CMUN: '056',
		DC: '1',
		NOMBRE: 'Lemoiz',
	},
	{
		CPRO: '48',
		CMUN: '081',
		DC: '3',
		NOMBRE: 'Lezama',
	},
	{
		CPRO: '48',
		CMUN: '903',
		DC: '2',
		NOMBRE: 'Loiu',
	},
	{
		CPRO: '48',
		CMUN: '058',
		DC: '3',
		NOMBRE: 'Mallabia',
	},
	{
		CPRO: '48',
		CMUN: '059',
		DC: '6',
		NOMBRE: 'Mañaria',
	},
	{
		CPRO: '48',
		CMUN: '060',
		DC: '0',
		NOMBRE: 'Markina-Xemein',
	},
	{
		CPRO: '48',
		CMUN: '061',
		DC: '7',
		NOMBRE: 'Maruri-Jatabe',
	},
	{
		CPRO: '48',
		CMUN: '062',
		DC: '2',
		NOMBRE: 'Mendata',
	},
	{
		CPRO: '48',
		CMUN: '063',
		DC: '8',
		NOMBRE: 'Mendexa',
	},
	{
		CPRO: '48',
		CMUN: '064',
		DC: '3',
		NOMBRE: 'Meñaka',
	},
	{
		CPRO: '48',
		CMUN: '066',
		DC: '9',
		NOMBRE: 'Morga',
	},
	{
		CPRO: '48',
		CMUN: '068',
		DC: '1',
		NOMBRE: 'Mundaka',
	},
	{
		CPRO: '48',
		CMUN: '069',
		DC: '4',
		NOMBRE: 'Mungia',
	},
	{
		CPRO: '48',
		CMUN: '007',
		DC: '8',
		NOMBRE: 'Munitibar-Arbatzegi Gerrikaitz',
	},
	{
		CPRO: '48',
		CMUN: '908',
		DC: '5',
		NOMBRE: 'Murueta',
	},
	{
		CPRO: '48',
		CMUN: '071',
		DC: '5',
		NOMBRE: 'Muskiz',
	},
	{
		CPRO: '48',
		CMUN: '067',
		DC: '5',
		NOMBRE: 'Muxika',
	},
	{
		CPRO: '48',
		CMUN: '909',
		DC: '8',
		NOMBRE: 'Nabarniz',
	},
	{
		CPRO: '48',
		CMUN: '073',
		DC: '6',
		NOMBRE: 'Ondarroa',
	},
	{
		CPRO: '48',
		CMUN: '075',
		DC: '4',
		NOMBRE: 'Orozko',
	},
	{
		CPRO: '48',
		CMUN: '083',
		DC: '4',
		NOMBRE: 'Ortuella',
	},
	{
		CPRO: '48',
		CMUN: '072',
		DC: '0',
		NOMBRE: 'Otxandio',
	},
	{
		CPRO: '48',
		CMUN: '077',
		DC: '3',
		NOMBRE: 'Plentzia',
	},
	{
		CPRO: '48',
		CMUN: '078',
		DC: '9',
		NOMBRE: 'Portugalete',
	},
	{
		CPRO: '48',
		CMUN: '082',
		DC: '8',
		NOMBRE: 'Santurtzi',
	},
	{
		CPRO: '48',
		CMUN: '084',
		DC: '9',
		NOMBRE: 'Sestao',
	},
	{
		CPRO: '48',
		CMUN: '904',
		DC: '7',
		NOMBRE: 'Sondika',
	},
	{
		CPRO: '48',
		CMUN: '085',
		DC: '2',
		NOMBRE: 'Sopelana',
	},
	{
		CPRO: '48',
		CMUN: '086',
		DC: '5',
		NOMBRE: 'Sopuerta',
	},
	{
		CPRO: '48',
		CMUN: '076',
		DC: '7',
		NOMBRE: 'Sukarrieta',
	},
	{
		CPRO: '48',
		CMUN: '087',
		DC: '1',
		NOMBRE: 'Trucios-Turtzioz',
	},
	{
		CPRO: '48',
		CMUN: '088',
		DC: '7',
		NOMBRE: 'Ubide',
	},
	{
		CPRO: '48',
		CMUN: '065',
		DC: '6',
		NOMBRE: 'Ugao-Miraballes',
	},
	{
		CPRO: '48',
		CMUN: '089',
		DC: '0',
		NOMBRE: 'Urduliz',
	},
	{
		CPRO: '48',
		CMUN: '074',
		DC: '1',
		NOMBRE: 'Urduña/Orduña',
	},
	{
		CPRO: '48',
		CMUN: '080',
		DC: '6',
		NOMBRE: 'Valle de Trápaga-Trapagaran',
	},
	{
		CPRO: '48',
		CMUN: '095',
		DC: '0',
		NOMBRE: 'Zaldibar',
	},
	{
		CPRO: '48',
		CMUN: '096',
		DC: '3',
		NOMBRE: 'Zalla',
	},
	{
		CPRO: '48',
		CMUN: '905',
		DC: '0',
		NOMBRE: 'Zamudio',
	},
	{
		CPRO: '48',
		CMUN: '097',
		DC: '9',
		NOMBRE: 'Zaratamo',
	},
	{
		CPRO: '48',
		CMUN: '024',
		DC: '2',
		NOMBRE: 'Zeanuri',
	},
	{
		CPRO: '48',
		CMUN: '025',
		DC: '5',
		NOMBRE: 'Zeberio',
	},
	{
		CPRO: '48',
		CMUN: '913',
		DC: '0',
		NOMBRE: 'Zierbena',
	},
	{
		CPRO: '48',
		CMUN: '915',
		DC: '8',
		NOMBRE: 'Ziortza-Bolibar',
	},
	{
		CPRO: '49',
		CMUN: '002',
		DC: '8',
		NOMBRE: 'Abezames',
	},
	{
		CPRO: '49',
		CMUN: '003',
		DC: '4',
		NOMBRE: 'Alcañices',
	},
	{
		CPRO: '49',
		CMUN: '004',
		DC: '9',
		NOMBRE: 'Alcubilla de Nogales',
	},
	{
		CPRO: '49',
		CMUN: '005',
		DC: '2',
		NOMBRE: 'Alfaraz de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '006',
		DC: '5',
		NOMBRE: 'Algodre',
	},
	{
		CPRO: '49',
		CMUN: '007',
		DC: '1',
		NOMBRE: 'Almaraz de Duero',
	},
	{
		CPRO: '49',
		CMUN: '008',
		DC: '7',
		NOMBRE: 'Almeida de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '009',
		DC: '0',
		NOMBRE: 'Andavías',
	},
	{
		CPRO: '49',
		CMUN: '010',
		DC: '4',
		NOMBRE: 'Arcenillas',
	},
	{
		CPRO: '49',
		CMUN: '011',
		DC: '1',
		NOMBRE: 'Arcos de la Polvorosa',
	},
	{
		CPRO: '49',
		CMUN: '012',
		DC: '6',
		NOMBRE: 'Argañín',
	},
	{
		CPRO: '49',
		CMUN: '013',
		DC: '2',
		NOMBRE: 'Argujillo',
	},
	{
		CPRO: '49',
		CMUN: '014',
		DC: '7',
		NOMBRE: 'Arquillinos',
	},
	{
		CPRO: '49',
		CMUN: '015',
		DC: '0',
		NOMBRE: 'Arrabalde',
	},
	{
		CPRO: '49',
		CMUN: '016',
		DC: '3',
		NOMBRE: 'Aspariegos',
	},
	{
		CPRO: '49',
		CMUN: '017',
		DC: '9',
		NOMBRE: 'Asturianos',
	},
	{
		CPRO: '49',
		CMUN: '018',
		DC: '5',
		NOMBRE: 'Ayoó de Vidriales',
	},
	{
		CPRO: '49',
		CMUN: '019',
		DC: '8',
		NOMBRE: 'Barcial del Barco',
	},
	{
		CPRO: '49',
		CMUN: '020',
		DC: '2',
		NOMBRE: 'Belver de los Montes',
	},
	{
		CPRO: '49',
		CMUN: '021',
		DC: '9',
		NOMBRE: 'Benavente',
	},
	{
		CPRO: '49',
		CMUN: '022',
		DC: '4',
		NOMBRE: 'Benegiles',
	},
	{
		CPRO: '49',
		CMUN: '023',
		DC: '0',
		NOMBRE: 'Bermillo de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '024',
		DC: '5',
		NOMBRE: 'Bóveda de Toro, La',
	},
	{
		CPRO: '49',
		CMUN: '025',
		DC: '8',
		NOMBRE: 'Bretó',
	},
	{
		CPRO: '49',
		CMUN: '026',
		DC: '1',
		NOMBRE: 'Bretocino',
	},
	{
		CPRO: '49',
		CMUN: '027',
		DC: '7',
		NOMBRE: 'Brime de Sog',
	},
	{
		CPRO: '49',
		CMUN: '028',
		DC: '3',
		NOMBRE: 'Brime de Urz',
	},
	{
		CPRO: '49',
		CMUN: '029',
		DC: '6',
		NOMBRE: 'Burganes de Valverde',
	},
	{
		CPRO: '49',
		CMUN: '030',
		DC: '0',
		NOMBRE: 'Bustillo del Oro',
	},
	{
		CPRO: '49',
		CMUN: '031',
		DC: '7',
		NOMBRE: 'Cabañas de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '032',
		DC: '2',
		NOMBRE: 'Calzadilla de Tera',
	},
	{
		CPRO: '49',
		CMUN: '033',
		DC: '8',
		NOMBRE: 'Camarzana de Tera',
	},
	{
		CPRO: '49',
		CMUN: '034',
		DC: '3',
		NOMBRE: 'Cañizal',
	},
	{
		CPRO: '49',
		CMUN: '035',
		DC: '6',
		NOMBRE: 'Cañizo',
	},
	{
		CPRO: '49',
		CMUN: '036',
		DC: '9',
		NOMBRE: 'Carbajales de Alba',
	},
	{
		CPRO: '49',
		CMUN: '037',
		DC: '5',
		NOMBRE: 'Carbellino',
	},
	{
		CPRO: '49',
		CMUN: '038',
		DC: '1',
		NOMBRE: 'Casaseca de Campeán',
	},
	{
		CPRO: '49',
		CMUN: '039',
		DC: '4',
		NOMBRE: 'Casaseca de las Chanas',
	},
	{
		CPRO: '49',
		CMUN: '040',
		DC: '8',
		NOMBRE: 'Castrillo de la Guareña',
	},
	{
		CPRO: '49',
		CMUN: '041',
		DC: '5',
		NOMBRE: 'Castrogonzalo',
	},
	{
		CPRO: '49',
		CMUN: '042',
		DC: '0',
		NOMBRE: 'Castronuevo',
	},
	{
		CPRO: '49',
		CMUN: '043',
		DC: '6',
		NOMBRE: 'Castroverde de Campos',
	},
	{
		CPRO: '49',
		CMUN: '044',
		DC: '1',
		NOMBRE: 'Cazurra',
	},
	{
		CPRO: '49',
		CMUN: '046',
		DC: '7',
		NOMBRE: 'Cerecinos de Campos',
	},
	{
		CPRO: '49',
		CMUN: '047',
		DC: '3',
		NOMBRE: 'Cerecinos del Carrizal',
	},
	{
		CPRO: '49',
		CMUN: '048',
		DC: '9',
		NOMBRE: 'Cernadilla',
	},
	{
		CPRO: '49',
		CMUN: '050',
		DC: '5',
		NOMBRE: 'Cobreros',
	},
	{
		CPRO: '49',
		CMUN: '052',
		DC: '7',
		NOMBRE: 'Coomonte',
	},
	{
		CPRO: '49',
		CMUN: '053',
		DC: '3',
		NOMBRE: 'Coreses',
	},
	{
		CPRO: '49',
		CMUN: '054',
		DC: '8',
		NOMBRE: 'Corrales del Vino',
	},
	{
		CPRO: '49',
		CMUN: '055',
		DC: '1',
		NOMBRE: 'Cotanes del Monte',
	},
	{
		CPRO: '49',
		CMUN: '056',
		DC: '4',
		NOMBRE: 'Cubillos',
	},
	{
		CPRO: '49',
		CMUN: '057',
		DC: '0',
		NOMBRE: 'Cubo de Benavente',
	},
	{
		CPRO: '49',
		CMUN: '058',
		DC: '6',
		NOMBRE: 'Cubo de Tierra del Vino, El',
	},
	{
		CPRO: '49',
		CMUN: '059',
		DC: '9',
		NOMBRE: 'Cuelgamures',
	},
	{
		CPRO: '49',
		CMUN: '061',
		DC: '0',
		NOMBRE: 'Entrala',
	},
	{
		CPRO: '49',
		CMUN: '062',
		DC: '5',
		NOMBRE: 'Espadañedo',
	},
	{
		CPRO: '49',
		CMUN: '063',
		DC: '1',
		NOMBRE: 'Faramontanos de Tábara',
	},
	{
		CPRO: '49',
		CMUN: '064',
		DC: '6',
		NOMBRE: 'Fariza',
	},
	{
		CPRO: '49',
		CMUN: '065',
		DC: '9',
		NOMBRE: 'Fermoselle',
	},
	{
		CPRO: '49',
		CMUN: '066',
		DC: '2',
		NOMBRE: 'Ferreras de Abajo',
	},
	{
		CPRO: '49',
		CMUN: '067',
		DC: '8',
		NOMBRE: 'Ferreras de Arriba',
	},
	{
		CPRO: '49',
		CMUN: '068',
		DC: '4',
		NOMBRE: 'Ferreruela',
	},
	{
		CPRO: '49',
		CMUN: '069',
		DC: '7',
		NOMBRE: 'Figueruela de Arriba',
	},
	{
		CPRO: '49',
		CMUN: '071',
		DC: '8',
		NOMBRE: 'Fonfría',
	},
	{
		CPRO: '49',
		CMUN: '075',
		DC: '7',
		NOMBRE: 'Fresno de la Polvorosa',
	},
	{
		CPRO: '49',
		CMUN: '076',
		DC: '0',
		NOMBRE: 'Fresno de la Ribera',
	},
	{
		CPRO: '49',
		CMUN: '077',
		DC: '6',
		NOMBRE: 'Fresno de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '078',
		DC: '2',
		NOMBRE: 'Friera de Valverde',
	},
	{
		CPRO: '49',
		CMUN: '079',
		DC: '5',
		NOMBRE: 'Fuente Encalada',
	},
	{
		CPRO: '49',
		CMUN: '080',
		DC: '9',
		NOMBRE: 'Fuentelapeña',
	},
	{
		CPRO: '49',
		CMUN: '082',
		DC: '1',
		NOMBRE: 'Fuentes de Ropel',
	},
	{
		CPRO: '49',
		CMUN: '081',
		DC: '6',
		NOMBRE: 'Fuentesaúco',
	},
	{
		CPRO: '49',
		CMUN: '083',
		DC: '7',
		NOMBRE: 'Fuentesecas',
	},
	{
		CPRO: '49',
		CMUN: '084',
		DC: '2',
		NOMBRE: 'Fuentespreadas',
	},
	{
		CPRO: '49',
		CMUN: '085',
		DC: '5',
		NOMBRE: 'Galende',
	},
	{
		CPRO: '49',
		CMUN: '086',
		DC: '8',
		NOMBRE: 'Gallegos del Pan',
	},
	{
		CPRO: '49',
		CMUN: '087',
		DC: '4',
		NOMBRE: 'Gallegos del Río',
	},
	{
		CPRO: '49',
		CMUN: '088',
		DC: '0',
		NOMBRE: 'Gamones',
	},
	{
		CPRO: '49',
		CMUN: '090',
		DC: '7',
		NOMBRE: 'Gema',
	},
	{
		CPRO: '49',
		CMUN: '091',
		DC: '4',
		NOMBRE: 'Granja de Moreruela',
	},
	{
		CPRO: '49',
		CMUN: '092',
		DC: '9',
		NOMBRE: 'Granucillo',
	},
	{
		CPRO: '49',
		CMUN: '093',
		DC: '5',
		NOMBRE: 'Guarrate',
	},
	{
		CPRO: '49',
		CMUN: '094',
		DC: '0',
		NOMBRE: 'Hermisende',
	},
	{
		CPRO: '49',
		CMUN: '095',
		DC: '3',
		NOMBRE: 'Hiniesta, La',
	},
	{
		CPRO: '49',
		CMUN: '096',
		DC: '6',
		NOMBRE: 'Jambrina',
	},
	{
		CPRO: '49',
		CMUN: '097',
		DC: '2',
		NOMBRE: 'Justel',
	},
	{
		CPRO: '49',
		CMUN: '098',
		DC: '8',
		NOMBRE: 'Losacino',
	},
	{
		CPRO: '49',
		CMUN: '099',
		DC: '1',
		NOMBRE: 'Losacio',
	},
	{
		CPRO: '49',
		CMUN: '100',
		DC: '5',
		NOMBRE: 'Lubián',
	},
	{
		CPRO: '49',
		CMUN: '101',
		DC: '2',
		NOMBRE: 'Luelmo',
	},
	{
		CPRO: '49',
		CMUN: '102',
		DC: '7',
		NOMBRE: 'Maderal, El',
	},
	{
		CPRO: '49',
		CMUN: '103',
		DC: '3',
		NOMBRE: 'Madridanos',
	},
	{
		CPRO: '49',
		CMUN: '104',
		DC: '8',
		NOMBRE: 'Mahide',
	},
	{
		CPRO: '49',
		CMUN: '105',
		DC: '1',
		NOMBRE: 'Maire de Castroponce',
	},
	{
		CPRO: '49',
		CMUN: '107',
		DC: '0',
		NOMBRE: 'Malva',
	},
	{
		CPRO: '49',
		CMUN: '108',
		DC: '6',
		NOMBRE: 'Manganeses de la Lampreana',
	},
	{
		CPRO: '49',
		CMUN: '109',
		DC: '9',
		NOMBRE: 'Manganeses de la Polvorosa',
	},
	{
		CPRO: '49',
		CMUN: '110',
		DC: '3',
		NOMBRE: 'Manzanal de Arriba',
	},
	{
		CPRO: '49',
		CMUN: '112',
		DC: '5',
		NOMBRE: 'Manzanal de los Infantes',
	},
	{
		CPRO: '49',
		CMUN: '111',
		DC: '0',
		NOMBRE: 'Manzanal del Barco',
	},
	{
		CPRO: '49',
		CMUN: '113',
		DC: '1',
		NOMBRE: 'Matilla de Arzón',
	},
	{
		CPRO: '49',
		CMUN: '114',
		DC: '6',
		NOMBRE: 'Matilla la Seca',
	},
	{
		CPRO: '49',
		CMUN: '115',
		DC: '9',
		NOMBRE: 'Mayalde',
	},
	{
		CPRO: '49',
		CMUN: '116',
		DC: '2',
		NOMBRE: 'Melgar de Tera',
	},
	{
		CPRO: '49',
		CMUN: '117',
		DC: '8',
		NOMBRE: 'Micereces de Tera',
	},
	{
		CPRO: '49',
		CMUN: '118',
		DC: '4',
		NOMBRE: 'Milles de la Polvorosa',
	},
	{
		CPRO: '49',
		CMUN: '119',
		DC: '7',
		NOMBRE: 'Molacillos',
	},
	{
		CPRO: '49',
		CMUN: '120',
		DC: '1',
		NOMBRE: 'Molezuelas de la Carballeda',
	},
	{
		CPRO: '49',
		CMUN: '121',
		DC: '8',
		NOMBRE: 'Mombuey',
	},
	{
		CPRO: '49',
		CMUN: '122',
		DC: '3',
		NOMBRE: 'Monfarracinos',
	},
	{
		CPRO: '49',
		CMUN: '123',
		DC: '9',
		NOMBRE: 'Montamarta',
	},
	{
		CPRO: '49',
		CMUN: '124',
		DC: '4',
		NOMBRE: 'Moral de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '126',
		DC: '0',
		NOMBRE: 'Moraleja de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '125',
		DC: '7',
		NOMBRE: 'Moraleja del Vino',
	},
	{
		CPRO: '49',
		CMUN: '128',
		DC: '2',
		NOMBRE: 'Morales de Rey',
	},
	{
		CPRO: '49',
		CMUN: '129',
		DC: '5',
		NOMBRE: 'Morales de Toro',
	},
	{
		CPRO: '49',
		CMUN: '130',
		DC: '9',
		NOMBRE: 'Morales de Valverde',
	},
	{
		CPRO: '49',
		CMUN: '127',
		DC: '6',
		NOMBRE: 'Morales del Vino',
	},
	{
		CPRO: '49',
		CMUN: '131',
		DC: '6',
		NOMBRE: 'Moralina',
	},
	{
		CPRO: '49',
		CMUN: '132',
		DC: '1',
		NOMBRE: 'Moreruela de los Infanzones',
	},
	{
		CPRO: '49',
		CMUN: '133',
		DC: '7',
		NOMBRE: 'Moreruela de Tábara',
	},
	{
		CPRO: '49',
		CMUN: '134',
		DC: '2',
		NOMBRE: 'Muelas de los Caballeros',
	},
	{
		CPRO: '49',
		CMUN: '135',
		DC: '5',
		NOMBRE: 'Muelas del Pan',
	},
	{
		CPRO: '49',
		CMUN: '136',
		DC: '8',
		NOMBRE: 'Muga de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '137',
		DC: '4',
		NOMBRE: 'Navianos de Valverde',
	},
	{
		CPRO: '49',
		CMUN: '138',
		DC: '0',
		NOMBRE: 'Olmillos de Castro',
	},
	{
		CPRO: '49',
		CMUN: '139',
		DC: '3',
		NOMBRE: 'Otero de Bodas',
	},
	{
		CPRO: '49',
		CMUN: '141',
		DC: '4',
		NOMBRE: 'Pajares de la Lampreana',
	},
	{
		CPRO: '49',
		CMUN: '143',
		DC: '5',
		NOMBRE: 'Palacios de Sanabria',
	},
	{
		CPRO: '49',
		CMUN: '142',
		DC: '9',
		NOMBRE: 'Palacios del Pan',
	},
	{
		CPRO: '49',
		CMUN: '145',
		DC: '3',
		NOMBRE: 'Pedralba de la Pradería',
	},
	{
		CPRO: '49',
		CMUN: '146',
		DC: '6',
		NOMBRE: 'Pego, El',
	},
	{
		CPRO: '49',
		CMUN: '147',
		DC: '2',
		NOMBRE: 'Peleagonzalo',
	},
	{
		CPRO: '49',
		CMUN: '148',
		DC: '8',
		NOMBRE: 'Peleas de Abajo',
	},
	{
		CPRO: '49',
		CMUN: '149',
		DC: '1',
		NOMBRE: 'Peñausende',
	},
	{
		CPRO: '49',
		CMUN: '150',
		DC: '4',
		NOMBRE: 'Peque',
	},
	{
		CPRO: '49',
		CMUN: '151',
		DC: '1',
		NOMBRE: 'Perdigón, El',
	},
	{
		CPRO: '49',
		CMUN: '152',
		DC: '6',
		NOMBRE: 'Pereruela',
	},
	{
		CPRO: '49',
		CMUN: '153',
		DC: '2',
		NOMBRE: 'Perilla de Castro',
	},
	{
		CPRO: '49',
		CMUN: '154',
		DC: '7',
		NOMBRE: 'Pías',
	},
	{
		CPRO: '49',
		CMUN: '155',
		DC: '0',
		NOMBRE: 'Piedrahita de Castro',
	},
	{
		CPRO: '49',
		CMUN: '156',
		DC: '3',
		NOMBRE: 'Pinilla de Toro',
	},
	{
		CPRO: '49',
		CMUN: '157',
		DC: '9',
		NOMBRE: 'Pino del Oro',
	},
	{
		CPRO: '49',
		CMUN: '158',
		DC: '5',
		NOMBRE: 'Piñero, El',
	},
	{
		CPRO: '49',
		CMUN: '160',
		DC: '2',
		NOMBRE: 'Pobladura de Valderaduey',
	},
	{
		CPRO: '49',
		CMUN: '159',
		DC: '8',
		NOMBRE: 'Pobladura del Valle',
	},
	{
		CPRO: '49',
		CMUN: '162',
		DC: '4',
		NOMBRE: 'Porto',
	},
	{
		CPRO: '49',
		CMUN: '163',
		DC: '0',
		NOMBRE: 'Pozoantiguo',
	},
	{
		CPRO: '49',
		CMUN: '164',
		DC: '5',
		NOMBRE: 'Pozuelo de Tábara',
	},
	{
		CPRO: '49',
		CMUN: '165',
		DC: '8',
		NOMBRE: 'Prado',
	},
	{
		CPRO: '49',
		CMUN: '166',
		DC: '1',
		NOMBRE: 'Puebla de Sanabria',
	},
	{
		CPRO: '49',
		CMUN: '167',
		DC: '7',
		NOMBRE: 'Pueblica de Valverde',
	},
	{
		CPRO: '49',
		CMUN: '170',
		DC: '0',
		NOMBRE: 'Quintanilla de Urz',
	},
	{
		CPRO: '49',
		CMUN: '168',
		DC: '3',
		NOMBRE: 'Quintanilla del Monte',
	},
	{
		CPRO: '49',
		CMUN: '169',
		DC: '6',
		NOMBRE: 'Quintanilla del Olmo',
	},
	{
		CPRO: '49',
		CMUN: '171',
		DC: '7',
		NOMBRE: 'Quiruelas de Vidriales',
	},
	{
		CPRO: '49',
		CMUN: '172',
		DC: '2',
		NOMBRE: 'Rabanales',
	},
	{
		CPRO: '49',
		CMUN: '173',
		DC: '8',
		NOMBRE: 'Rábano de Aliste',
	},
	{
		CPRO: '49',
		CMUN: '174',
		DC: '3',
		NOMBRE: 'Requejo',
	},
	{
		CPRO: '49',
		CMUN: '175',
		DC: '6',
		NOMBRE: 'Revellinos',
	},
	{
		CPRO: '49',
		CMUN: '176',
		DC: '9',
		NOMBRE: 'Riofrío de Aliste',
	},
	{
		CPRO: '49',
		CMUN: '177',
		DC: '5',
		NOMBRE: 'Rionegro del Puente',
	},
	{
		CPRO: '49',
		CMUN: '178',
		DC: '1',
		NOMBRE: 'Roales',
	},
	{
		CPRO: '49',
		CMUN: '179',
		DC: '4',
		NOMBRE: 'Robleda-Cervantes',
	},
	{
		CPRO: '49',
		CMUN: '180',
		DC: '8',
		NOMBRE: 'Roelos de Sayago',
	},
	{
		CPRO: '49',
		CMUN: '181',
		DC: '5',
		NOMBRE: 'Rosinos de la Requejada',
	},
	{
		CPRO: '49',
		CMUN: '183',
		DC: '6',
		NOMBRE: 'Salce',
	},
	{
		CPRO: '49',
		CMUN: '184',
		DC: '1',
		NOMBRE: 'Samir de los Caños',
	},
	{
		CPRO: '49',
		CMUN: '185',
		DC: '4',
		NOMBRE: 'San Agustín del Pozo',
	},
	{
		CPRO: '49',
		CMUN: '186',
		DC: '7',
		NOMBRE: 'San Cebrián de Castro',
	},
	{
		CPRO: '49',
		CMUN: '187',
		DC: '3',
		NOMBRE: 'San Cristóbal de Entreviñas',
	},
	{
		CPRO: '49',
		CMUN: '188',
		DC: '9',
		NOMBRE: 'San Esteban del Molar',
	},
	{
		CPRO: '49',
		CMUN: '189',
		DC: '2',
		NOMBRE: 'San Justo',
	},
	{
		CPRO: '49',
		CMUN: '190',
		DC: '6',
		NOMBRE: 'San Martín de Valderaduey',
	},
	{
		CPRO: '49',
		CMUN: '191',
		DC: '3',
		NOMBRE: 'San Miguel de la Ribera',
	},
	{
		CPRO: '49',
		CMUN: '192',
		DC: '8',
		NOMBRE: 'San Miguel del Valle',
	},
	{
		CPRO: '49',
		CMUN: '193',
		DC: '4',
		NOMBRE: 'San Pedro de Ceque',
	},
	{
		CPRO: '49',
		CMUN: '194',
		DC: '9',
		NOMBRE: 'San Pedro de la Nave-Almendra',
	},
	{
		CPRO: '49',
		CMUN: '208',
		DC: '5',
		NOMBRE: 'San Vicente de la Cabeza',
	},
	{
		CPRO: '49',
		CMUN: '209',
		DC: '8',
		NOMBRE: 'San Vitero',
	},
	{
		CPRO: '49',
		CMUN: '197',
		DC: '1',
		NOMBRE: 'Santa Clara de Avedillo',
	},
	{
		CPRO: '49',
		CMUN: '199',
		DC: '0',
		NOMBRE: 'Santa Colomba de las Monjas',
	},
	{
		CPRO: '49',
		CMUN: '200',
		DC: '4',
		NOMBRE: 'Santa Cristina de la Polvorosa',
	},
	{
		CPRO: '49',
		CMUN: '201',
		DC: '1',
		NOMBRE: 'Santa Croya de Tera',
	},
	{
		CPRO: '49',
		CMUN: '202',
		DC: '6',
		NOMBRE: 'Santa Eufemia del Barco',
	},
	{
		CPRO: '49',
		CMUN: '203',
		DC: '2',
		NOMBRE: 'Santa María de la Vega',
	},
	{
		CPRO: '49',
		CMUN: '204',
		DC: '7',
		NOMBRE: 'Santa María de Valverde',
	},
	{
		CPRO: '49',
		CMUN: '205',
		DC: '0',
		NOMBRE: 'Santibáñez de Tera',
	},
	{
		CPRO: '49',
		CMUN: '206',
		DC: '3',
		NOMBRE: 'Santibáñez de Vidriales',
	},
	{
		CPRO: '49',
		CMUN: '207',
		DC: '9',
		NOMBRE: 'Santovenia',
	},
	{
		CPRO: '49',
		CMUN: '210',
		DC: '2',
		NOMBRE: 'Sanzoles',
	},
	{
		CPRO: '49',
		CMUN: '214',
		DC: '5',
		NOMBRE: 'Tábara',
	},
	{
		CPRO: '49',
		CMUN: '216',
		DC: '1',
		NOMBRE: 'Tapioles',
	},
	{
		CPRO: '49',
		CMUN: '219',
		DC: '6',
		NOMBRE: 'Toro',
	},
	{
		CPRO: '49',
		CMUN: '220',
		DC: '0',
		NOMBRE: 'Torre del Valle, La',
	},
	{
		CPRO: '49',
		CMUN: '221',
		DC: '7',
		NOMBRE: 'Torregamones',
	},
	{
		CPRO: '49',
		CMUN: '222',
		DC: '2',
		NOMBRE: 'Torres del Carrizal',
	},
	{
		CPRO: '49',
		CMUN: '223',
		DC: '8',
		NOMBRE: 'Trabazos',
	},
	{
		CPRO: '49',
		CMUN: '224',
		DC: '3',
		NOMBRE: 'Trefacio',
	},
	{
		CPRO: '49',
		CMUN: '225',
		DC: '6',
		NOMBRE: 'Uña de Quintana',
	},
	{
		CPRO: '49',
		CMUN: '226',
		DC: '9',
		NOMBRE: 'Vadillo de la Guareña',
	},
	{
		CPRO: '49',
		CMUN: '227',
		DC: '5',
		NOMBRE: 'Valcabado',
	},
	{
		CPRO: '49',
		CMUN: '228',
		DC: '1',
		NOMBRE: 'Valdefinjas',
	},
	{
		CPRO: '49',
		CMUN: '229',
		DC: '4',
		NOMBRE: 'Valdescorriel',
	},
	{
		CPRO: '49',
		CMUN: '230',
		DC: '8',
		NOMBRE: 'Vallesa de la Guareña',
	},
	{
		CPRO: '49',
		CMUN: '231',
		DC: '5',
		NOMBRE: 'Vega de Tera',
	},
	{
		CPRO: '49',
		CMUN: '232',
		DC: '0',
		NOMBRE: 'Vega de Villalobos',
	},
	{
		CPRO: '49',
		CMUN: '233',
		DC: '6',
		NOMBRE: 'Vegalatrave',
	},
	{
		CPRO: '49',
		CMUN: '234',
		DC: '1',
		NOMBRE: 'Venialbo',
	},
	{
		CPRO: '49',
		CMUN: '235',
		DC: '4',
		NOMBRE: 'Vezdemarbán',
	},
	{
		CPRO: '49',
		CMUN: '236',
		DC: '7',
		NOMBRE: 'Vidayanes',
	},
	{
		CPRO: '49',
		CMUN: '237',
		DC: '3',
		NOMBRE: 'Videmala',
	},
	{
		CPRO: '49',
		CMUN: '238',
		DC: '9',
		NOMBRE: 'Villabrázaro',
	},
	{
		CPRO: '49',
		CMUN: '239',
		DC: '2',
		NOMBRE: 'Villabuena del Puente',
	},
	{
		CPRO: '49',
		CMUN: '240',
		DC: '6',
		NOMBRE: 'Villadepera',
	},
	{
		CPRO: '49',
		CMUN: '241',
		DC: '3',
		NOMBRE: 'Villaescusa',
	},
	{
		CPRO: '49',
		CMUN: '242',
		DC: '8',
		NOMBRE: 'Villafáfila',
	},
	{
		CPRO: '49',
		CMUN: '243',
		DC: '4',
		NOMBRE: 'Villaferrueña',
	},
	{
		CPRO: '49',
		CMUN: '244',
		DC: '9',
		NOMBRE: 'Villageriz',
	},
	{
		CPRO: '49',
		CMUN: '245',
		DC: '2',
		NOMBRE: 'Villalazán',
	},
	{
		CPRO: '49',
		CMUN: '246',
		DC: '5',
		NOMBRE: 'Villalba de la Lampreana',
	},
	{
		CPRO: '49',
		CMUN: '247',
		DC: '1',
		NOMBRE: 'Villalcampo',
	},
	{
		CPRO: '49',
		CMUN: '248',
		DC: '7',
		NOMBRE: 'Villalobos',
	},
	{
		CPRO: '49',
		CMUN: '249',
		DC: '0',
		NOMBRE: 'Villalonso',
	},
	{
		CPRO: '49',
		CMUN: '250',
		DC: '3',
		NOMBRE: 'Villalpando',
	},
	{
		CPRO: '49',
		CMUN: '251',
		DC: '0',
		NOMBRE: 'Villalube',
	},
	{
		CPRO: '49',
		CMUN: '252',
		DC: '5',
		NOMBRE: 'Villamayor de Campos',
	},
	{
		CPRO: '49',
		CMUN: '255',
		DC: '9',
		NOMBRE: 'Villamor de los Escuderos',
	},
	{
		CPRO: '49',
		CMUN: '256',
		DC: '2',
		NOMBRE: 'Villanázar',
	},
	{
		CPRO: '49',
		CMUN: '257',
		DC: '8',
		NOMBRE: 'Villanueva de Azoague',
	},
	{
		CPRO: '49',
		CMUN: '258',
		DC: '4',
		NOMBRE: 'Villanueva de Campeán',
	},
	{
		CPRO: '49',
		CMUN: '259',
		DC: '7',
		NOMBRE: 'Villanueva de las Peras',
	},
	{
		CPRO: '49',
		CMUN: '260',
		DC: '1',
		NOMBRE: 'Villanueva del Campo',
	},
	{
		CPRO: '49',
		CMUN: '263',
		DC: '9',
		NOMBRE: 'Villar de Fallaves',
	},
	{
		CPRO: '49',
		CMUN: '264',
		DC: '4',
		NOMBRE: 'Villar del Buey',
	},
	{
		CPRO: '49',
		CMUN: '261',
		DC: '8',
		NOMBRE: 'Villaralbo',
	},
	{
		CPRO: '49',
		CMUN: '262',
		DC: '3',
		NOMBRE: 'Villardeciervos',
	},
	{
		CPRO: '49',
		CMUN: '265',
		DC: '7',
		NOMBRE: 'Villardiegua de la Ribera',
	},
	{
		CPRO: '49',
		CMUN: '266',
		DC: '0',
		NOMBRE: 'Villárdiga',
	},
	{
		CPRO: '49',
		CMUN: '267',
		DC: '6',
		NOMBRE: 'Villardondiego',
	},
	{
		CPRO: '49',
		CMUN: '268',
		DC: '2',
		NOMBRE: 'Villarrín de Campos',
	},
	{
		CPRO: '49',
		CMUN: '269',
		DC: '5',
		NOMBRE: 'Villaseco del Pan',
	},
	{
		CPRO: '49',
		CMUN: '270',
		DC: '9',
		NOMBRE: 'Villavendimio',
	},
	{
		CPRO: '49',
		CMUN: '272',
		DC: '1',
		NOMBRE: 'Villaveza de Valverde',
	},
	{
		CPRO: '49',
		CMUN: '271',
		DC: '6',
		NOMBRE: 'Villaveza del Agua',
	},
	{
		CPRO: '49',
		CMUN: '273',
		DC: '7',
		NOMBRE: 'Viñas',
	},
	{
		CPRO: '49',
		CMUN: '275',
		DC: '5',
		NOMBRE: 'Zamora',
	},
	{
		CPRO: '50',
		CMUN: '001',
		DC: '6',
		NOMBRE: 'Abanto',
	},
	{
		CPRO: '50',
		CMUN: '002',
		DC: '1',
		NOMBRE: 'Acered',
	},
	{
		CPRO: '50',
		CMUN: '003',
		DC: '7',
		NOMBRE: 'Agón',
	},
	{
		CPRO: '50',
		CMUN: '004',
		DC: '2',
		NOMBRE: 'Aguarón',
	},
	{
		CPRO: '50',
		CMUN: '005',
		DC: '5',
		NOMBRE: 'Aguilón',
	},
	{
		CPRO: '50',
		CMUN: '006',
		DC: '8',
		NOMBRE: 'Ainzón',
	},
	{
		CPRO: '50',
		CMUN: '007',
		DC: '4',
		NOMBRE: 'Aladrén',
	},
	{
		CPRO: '50',
		CMUN: '008',
		DC: '0',
		NOMBRE: 'Alagón',
	},
	{
		CPRO: '50',
		CMUN: '009',
		DC: '3',
		NOMBRE: 'Alarba',
	},
	{
		CPRO: '50',
		CMUN: '010',
		DC: '7',
		NOMBRE: 'Alberite de San Juan',
	},
	{
		CPRO: '50',
		CMUN: '011',
		DC: '4',
		NOMBRE: 'Albeta',
	},
	{
		CPRO: '50',
		CMUN: '012',
		DC: '9',
		NOMBRE: 'Alborge',
	},
	{
		CPRO: '50',
		CMUN: '013',
		DC: '5',
		NOMBRE: 'Alcalá de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '014',
		DC: '0',
		NOMBRE: 'Alcalá de Moncayo',
	},
	{
		CPRO: '50',
		CMUN: '015',
		DC: '3',
		NOMBRE: 'Alconchel de Ariza',
	},
	{
		CPRO: '50',
		CMUN: '016',
		DC: '6',
		NOMBRE: 'Aldehuela de Liestos',
	},
	{
		CPRO: '50',
		CMUN: '017',
		DC: '2',
		NOMBRE: 'Alfajarín',
	},
	{
		CPRO: '50',
		CMUN: '018',
		DC: '8',
		NOMBRE: 'Alfamén',
	},
	{
		CPRO: '50',
		CMUN: '019',
		DC: '1',
		NOMBRE: 'Alforque',
	},
	{
		CPRO: '50',
		CMUN: '020',
		DC: '5',
		NOMBRE: 'Alhama de Aragón',
	},
	{
		CPRO: '50',
		CMUN: '021',
		DC: '2',
		NOMBRE: 'Almochuel',
	},
	{
		CPRO: '50',
		CMUN: '022',
		DC: '7',
		NOMBRE: 'Almolda, La',
	},
	{
		CPRO: '50',
		CMUN: '023',
		DC: '3',
		NOMBRE: 'Almonacid de la Cuba',
	},
	{
		CPRO: '50',
		CMUN: '024',
		DC: '8',
		NOMBRE: 'Almonacid de la Sierra',
	},
	{
		CPRO: '50',
		CMUN: '025',
		DC: '1',
		NOMBRE: 'Almunia de Doña Godina, La',
	},
	{
		CPRO: '50',
		CMUN: '026',
		DC: '4',
		NOMBRE: 'Alpartir',
	},
	{
		CPRO: '50',
		CMUN: '027',
		DC: '0',
		NOMBRE: 'Ambel',
	},
	{
		CPRO: '50',
		CMUN: '028',
		DC: '6',
		NOMBRE: 'Anento',
	},
	{
		CPRO: '50',
		CMUN: '029',
		DC: '9',
		NOMBRE: 'Aniñón',
	},
	{
		CPRO: '50',
		CMUN: '030',
		DC: '3',
		NOMBRE: 'Añón de Moncayo',
	},
	{
		CPRO: '50',
		CMUN: '031',
		DC: '0',
		NOMBRE: 'Aranda de Moncayo',
	},
	{
		CPRO: '50',
		CMUN: '032',
		DC: '5',
		NOMBRE: 'Arándiga',
	},
	{
		CPRO: '50',
		CMUN: '033',
		DC: '1',
		NOMBRE: 'Ardisa',
	},
	{
		CPRO: '50',
		CMUN: '034',
		DC: '6',
		NOMBRE: 'Ariza',
	},
	{
		CPRO: '50',
		CMUN: '035',
		DC: '9',
		NOMBRE: 'Artieda',
	},
	{
		CPRO: '50',
		CMUN: '036',
		DC: '2',
		NOMBRE: 'Asín',
	},
	{
		CPRO: '50',
		CMUN: '037',
		DC: '8',
		NOMBRE: 'Atea',
	},
	{
		CPRO: '50',
		CMUN: '038',
		DC: '4',
		NOMBRE: 'Ateca',
	},
	{
		CPRO: '50',
		CMUN: '039',
		DC: '7',
		NOMBRE: 'Azuara',
	},
	{
		CPRO: '50',
		CMUN: '040',
		DC: '1',
		NOMBRE: 'Badules',
	},
	{
		CPRO: '50',
		CMUN: '041',
		DC: '8',
		NOMBRE: 'Bagüés',
	},
	{
		CPRO: '50',
		CMUN: '042',
		DC: '3',
		NOMBRE: 'Balconchán',
	},
	{
		CPRO: '50',
		CMUN: '043',
		DC: '9',
		NOMBRE: 'Bárboles',
	},
	{
		CPRO: '50',
		CMUN: '044',
		DC: '4',
		NOMBRE: 'Bardallur',
	},
	{
		CPRO: '50',
		CMUN: '045',
		DC: '7',
		NOMBRE: 'Belchite',
	},
	{
		CPRO: '50',
		CMUN: '046',
		DC: '0',
		NOMBRE: 'Belmonte de Gracián',
	},
	{
		CPRO: '50',
		CMUN: '047',
		DC: '6',
		NOMBRE: 'Berdejo',
	},
	{
		CPRO: '50',
		CMUN: '048',
		DC: '2',
		NOMBRE: 'Berrueco',
	},
	{
		CPRO: '50',
		CMUN: '901',
		DC: '7',
		NOMBRE: 'Biel',
	},
	{
		CPRO: '50',
		CMUN: '050',
		DC: '8',
		NOMBRE: 'Bijuesca',
	},
	{
		CPRO: '50',
		CMUN: '051',
		DC: '5',
		NOMBRE: 'Biota',
	},
	{
		CPRO: '50',
		CMUN: '052',
		DC: '0',
		NOMBRE: 'Bisimbre',
	},
	{
		CPRO: '50',
		CMUN: '053',
		DC: '6',
		NOMBRE: 'Boquiñeni',
	},
	{
		CPRO: '50',
		CMUN: '054',
		DC: '1',
		NOMBRE: 'Bordalba',
	},
	{
		CPRO: '50',
		CMUN: '055',
		DC: '4',
		NOMBRE: 'Borja',
	},
	{
		CPRO: '50',
		CMUN: '056',
		DC: '7',
		NOMBRE: 'Botorrita',
	},
	{
		CPRO: '50',
		CMUN: '057',
		DC: '3',
		NOMBRE: 'Brea de Aragón',
	},
	{
		CPRO: '50',
		CMUN: '058',
		DC: '9',
		NOMBRE: 'Bubierca',
	},
	{
		CPRO: '50',
		CMUN: '059',
		DC: '2',
		NOMBRE: 'Bujaraloz',
	},
	{
		CPRO: '50',
		CMUN: '060',
		DC: '6',
		NOMBRE: 'Bulbuente',
	},
	{
		CPRO: '50',
		CMUN: '061',
		DC: '3',
		NOMBRE: 'Bureta',
	},
	{
		CPRO: '50',
		CMUN: '062',
		DC: '8',
		NOMBRE: 'Burgo de Ebro, El',
	},
	{
		CPRO: '50',
		CMUN: '063',
		DC: '4',
		NOMBRE: 'Buste, El',
	},
	{
		CPRO: '50',
		CMUN: '064',
		DC: '9',
		NOMBRE: 'Cabañas de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '065',
		DC: '2',
		NOMBRE: 'Cabolafuente',
	},
	{
		CPRO: '50',
		CMUN: '066',
		DC: '5',
		NOMBRE: 'Cadrete',
	},
	{
		CPRO: '50',
		CMUN: '067',
		DC: '1',
		NOMBRE: 'Calatayud',
	},
	{
		CPRO: '50',
		CMUN: '068',
		DC: '7',
		NOMBRE: 'Calatorao',
	},
	{
		CPRO: '50',
		CMUN: '069',
		DC: '0',
		NOMBRE: 'Calcena',
	},
	{
		CPRO: '50',
		CMUN: '070',
		DC: '4',
		NOMBRE: 'Calmarza',
	},
	{
		CPRO: '50',
		CMUN: '071',
		DC: '1',
		NOMBRE: 'Campillo de Aragón',
	},
	{
		CPRO: '50',
		CMUN: '072',
		DC: '6',
		NOMBRE: 'Carenas',
	},
	{
		CPRO: '50',
		CMUN: '073',
		DC: '2',
		NOMBRE: 'Cariñena',
	},
	{
		CPRO: '50',
		CMUN: '074',
		DC: '7',
		NOMBRE: 'Caspe',
	},
	{
		CPRO: '50',
		CMUN: '075',
		DC: '0',
		NOMBRE: 'Castejón de Alarba',
	},
	{
		CPRO: '50',
		CMUN: '076',
		DC: '3',
		NOMBRE: 'Castejón de las Armas',
	},
	{
		CPRO: '50',
		CMUN: '077',
		DC: '9',
		NOMBRE: 'Castejón de Valdejasa',
	},
	{
		CPRO: '50',
		CMUN: '078',
		DC: '5',
		NOMBRE: 'Castiliscar',
	},
	{
		CPRO: '50',
		CMUN: '079',
		DC: '8',
		NOMBRE: 'Cervera de la Cañada',
	},
	{
		CPRO: '50',
		CMUN: '080',
		DC: '2',
		NOMBRE: 'Cerveruela',
	},
	{
		CPRO: '50',
		CMUN: '081',
		DC: '9',
		NOMBRE: 'Cetina',
	},
	{
		CPRO: '50',
		CMUN: '092',
		DC: '2',
		NOMBRE: 'Chiprana',
	},
	{
		CPRO: '50',
		CMUN: '093',
		DC: '8',
		NOMBRE: 'Chodes',
	},
	{
		CPRO: '50',
		CMUN: '082',
		DC: '4',
		NOMBRE: 'Cimballa',
	},
	{
		CPRO: '50',
		CMUN: '083',
		DC: '0',
		NOMBRE: 'Cinco Olivas',
	},
	{
		CPRO: '50',
		CMUN: '084',
		DC: '5',
		NOMBRE: 'Clarés de Ribota',
	},
	{
		CPRO: '50',
		CMUN: '085',
		DC: '8',
		NOMBRE: 'Codo',
	},
	{
		CPRO: '50',
		CMUN: '086',
		DC: '1',
		NOMBRE: 'Codos',
	},
	{
		CPRO: '50',
		CMUN: '087',
		DC: '7',
		NOMBRE: 'Contamina',
	},
	{
		CPRO: '50',
		CMUN: '088',
		DC: '3',
		NOMBRE: 'Cosuenda',
	},
	{
		CPRO: '50',
		CMUN: '089',
		DC: '6',
		NOMBRE: 'Cuarte de Huerva',
	},
	{
		CPRO: '50',
		CMUN: '090',
		DC: '0',
		NOMBRE: 'Cubel',
	},
	{
		CPRO: '50',
		CMUN: '091',
		DC: '7',
		NOMBRE: 'Cuerlas, Las',
	},
	{
		CPRO: '50',
		CMUN: '094',
		DC: '3',
		NOMBRE: 'Daroca',
	},
	{
		CPRO: '50',
		CMUN: '095',
		DC: '6',
		NOMBRE: 'Ejea de los Caballeros',
	},
	{
		CPRO: '50',
		CMUN: '096',
		DC: '9',
		NOMBRE: 'Embid de Ariza',
	},
	{
		CPRO: '50',
		CMUN: '098',
		DC: '1',
		NOMBRE: 'Encinacorba',
	},
	{
		CPRO: '50',
		CMUN: '099',
		DC: '4',
		NOMBRE: 'Épila',
	},
	{
		CPRO: '50',
		CMUN: '100',
		DC: '8',
		NOMBRE: 'Erla',
	},
	{
		CPRO: '50',
		CMUN: '101',
		DC: '5',
		NOMBRE: 'Escatrón',
	},
	{
		CPRO: '50',
		CMUN: '102',
		DC: '0',
		NOMBRE: 'Fabara',
	},
	{
		CPRO: '50',
		CMUN: '104',
		DC: '1',
		NOMBRE: 'Farlete',
	},
	{
		CPRO: '50',
		CMUN: '105',
		DC: '4',
		NOMBRE: 'Fayón',
	},
	{
		CPRO: '50',
		CMUN: '106',
		DC: '7',
		NOMBRE: 'Fayos, Los',
	},
	{
		CPRO: '50',
		CMUN: '107',
		DC: '3',
		NOMBRE: 'Figueruelas',
	},
	{
		CPRO: '50',
		CMUN: '108',
		DC: '9',
		NOMBRE: 'Fombuena',
	},
	{
		CPRO: '50',
		CMUN: '109',
		DC: '2',
		NOMBRE: 'Frago, El',
	},
	{
		CPRO: '50',
		CMUN: '110',
		DC: '6',
		NOMBRE: 'Frasno, El',
	},
	{
		CPRO: '50',
		CMUN: '111',
		DC: '3',
		NOMBRE: 'Fréscano',
	},
	{
		CPRO: '50',
		CMUN: '113',
		DC: '4',
		NOMBRE: 'Fuendejalón',
	},
	{
		CPRO: '50',
		CMUN: '114',
		DC: '9',
		NOMBRE: 'Fuendetodos',
	},
	{
		CPRO: '50',
		CMUN: '115',
		DC: '2',
		NOMBRE: 'Fuentes de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '116',
		DC: '5',
		NOMBRE: 'Fuentes de Jiloca',
	},
	{
		CPRO: '50',
		CMUN: '117',
		DC: '1',
		NOMBRE: 'Gallocanta',
	},
	{
		CPRO: '50',
		CMUN: '118',
		DC: '7',
		NOMBRE: 'Gallur',
	},
	{
		CPRO: '50',
		CMUN: '119',
		DC: '0',
		NOMBRE: 'Gelsa',
	},
	{
		CPRO: '50',
		CMUN: '120',
		DC: '4',
		NOMBRE: 'Godojos',
	},
	{
		CPRO: '50',
		CMUN: '121',
		DC: '1',
		NOMBRE: 'Gotor',
	},
	{
		CPRO: '50',
		CMUN: '122',
		DC: '6',
		NOMBRE: 'Grisel',
	},
	{
		CPRO: '50',
		CMUN: '123',
		DC: '2',
		NOMBRE: 'Grisén',
	},
	{
		CPRO: '50',
		CMUN: '124',
		DC: '7',
		NOMBRE: 'Herrera de los Navarros',
	},
	{
		CPRO: '50',
		CMUN: '125',
		DC: '0',
		NOMBRE: 'Ibdes',
	},
	{
		CPRO: '50',
		CMUN: '126',
		DC: '3',
		NOMBRE: 'Illueca',
	},
	{
		CPRO: '50',
		CMUN: '128',
		DC: '5',
		NOMBRE: 'Isuerre',
	},
	{
		CPRO: '50',
		CMUN: '129',
		DC: '8',
		NOMBRE: 'Jaraba',
	},
	{
		CPRO: '50',
		CMUN: '130',
		DC: '2',
		NOMBRE: 'Jarque',
	},
	{
		CPRO: '50',
		CMUN: '131',
		DC: '9',
		NOMBRE: 'Jaulín',
	},
	{
		CPRO: '50',
		CMUN: '132',
		DC: '4',
		NOMBRE: 'Joyosa, La',
	},
	{
		CPRO: '50',
		CMUN: '133',
		DC: '0',
		NOMBRE: 'Lagata',
	},
	{
		CPRO: '50',
		CMUN: '134',
		DC: '5',
		NOMBRE: 'Langa del Castillo',
	},
	{
		CPRO: '50',
		CMUN: '135',
		DC: '8',
		NOMBRE: 'Layana',
	},
	{
		CPRO: '50',
		CMUN: '136',
		DC: '1',
		NOMBRE: 'Lécera',
	},
	{
		CPRO: '50',
		CMUN: '138',
		DC: '3',
		NOMBRE: 'Lechón',
	},
	{
		CPRO: '50',
		CMUN: '137',
		DC: '7',
		NOMBRE: 'Leciñena',
	},
	{
		CPRO: '50',
		CMUN: '139',
		DC: '6',
		NOMBRE: 'Letux',
	},
	{
		CPRO: '50',
		CMUN: '140',
		DC: '0',
		NOMBRE: 'Litago',
	},
	{
		CPRO: '50',
		CMUN: '141',
		DC: '7',
		NOMBRE: 'Lituénigo',
	},
	{
		CPRO: '50',
		CMUN: '142',
		DC: '2',
		NOMBRE: 'Lobera de Onsella',
	},
	{
		CPRO: '50',
		CMUN: '143',
		DC: '8',
		NOMBRE: 'Longares',
	},
	{
		CPRO: '50',
		CMUN: '144',
		DC: '3',
		NOMBRE: 'Longás',
	},
	{
		CPRO: '50',
		CMUN: '146',
		DC: '9',
		NOMBRE: 'Lucena de Jalón',
	},
	{
		CPRO: '50',
		CMUN: '147',
		DC: '5',
		NOMBRE: 'Luceni',
	},
	{
		CPRO: '50',
		CMUN: '148',
		DC: '1',
		NOMBRE: 'Luesia',
	},
	{
		CPRO: '50',
		CMUN: '149',
		DC: '4',
		NOMBRE: 'Luesma',
	},
	{
		CPRO: '50',
		CMUN: '150',
		DC: '7',
		NOMBRE: 'Lumpiaque',
	},
	{
		CPRO: '50',
		CMUN: '151',
		DC: '4',
		NOMBRE: 'Luna',
	},
	{
		CPRO: '50',
		CMUN: '152',
		DC: '9',
		NOMBRE: 'Maella',
	},
	{
		CPRO: '50',
		CMUN: '153',
		DC: '5',
		NOMBRE: 'Magallón',
	},
	{
		CPRO: '50',
		CMUN: '154',
		DC: '0',
		NOMBRE: 'Mainar',
	},
	{
		CPRO: '50',
		CMUN: '155',
		DC: '3',
		NOMBRE: 'Malanquilla',
	},
	{
		CPRO: '50',
		CMUN: '156',
		DC: '6',
		NOMBRE: 'Maleján',
	},
	{
		CPRO: '50',
		CMUN: '160',
		DC: '5',
		NOMBRE: 'Mallén',
	},
	{
		CPRO: '50',
		CMUN: '157',
		DC: '2',
		NOMBRE: 'Malón',
	},
	{
		CPRO: '50',
		CMUN: '159',
		DC: '1',
		NOMBRE: 'Maluenda',
	},
	{
		CPRO: '50',
		CMUN: '161',
		DC: '2',
		NOMBRE: 'Manchones',
	},
	{
		CPRO: '50',
		CMUN: '162',
		DC: '7',
		NOMBRE: 'Mara',
	},
	{
		CPRO: '50',
		CMUN: '163',
		DC: '3',
		NOMBRE: 'María de Huerva',
	},
	{
		CPRO: '50',
		CMUN: '902',
		DC: '2',
		NOMBRE: 'Marracos',
	},
	{
		CPRO: '50',
		CMUN: '164',
		DC: '8',
		NOMBRE: 'Mediana de Aragón',
	},
	{
		CPRO: '50',
		CMUN: '165',
		DC: '1',
		NOMBRE: 'Mequinenza',
	},
	{
		CPRO: '50',
		CMUN: '166',
		DC: '4',
		NOMBRE: 'Mesones de Isuela',
	},
	{
		CPRO: '50',
		CMUN: '167',
		DC: '0',
		NOMBRE: 'Mezalocha',
	},
	{
		CPRO: '50',
		CMUN: '168',
		DC: '6',
		NOMBRE: 'Mianos',
	},
	{
		CPRO: '50',
		CMUN: '169',
		DC: '9',
		NOMBRE: 'Miedes de Aragón',
	},
	{
		CPRO: '50',
		CMUN: '170',
		DC: '3',
		NOMBRE: 'Monegrillo',
	},
	{
		CPRO: '50',
		CMUN: '171',
		DC: '0',
		NOMBRE: 'Moneva',
	},
	{
		CPRO: '50',
		CMUN: '172',
		DC: '5',
		NOMBRE: 'Monreal de Ariza',
	},
	{
		CPRO: '50',
		CMUN: '173',
		DC: '1',
		NOMBRE: 'Monterde',
	},
	{
		CPRO: '50',
		CMUN: '174',
		DC: '6',
		NOMBRE: 'Montón',
	},
	{
		CPRO: '50',
		CMUN: '175',
		DC: '9',
		NOMBRE: 'Morata de Jalón',
	},
	{
		CPRO: '50',
		CMUN: '176',
		DC: '2',
		NOMBRE: 'Morata de Jiloca',
	},
	{
		CPRO: '50',
		CMUN: '177',
		DC: '8',
		NOMBRE: 'Morés',
	},
	{
		CPRO: '50',
		CMUN: '178',
		DC: '4',
		NOMBRE: 'Moros',
	},
	{
		CPRO: '50',
		CMUN: '179',
		DC: '7',
		NOMBRE: 'Moyuela',
	},
	{
		CPRO: '50',
		CMUN: '180',
		DC: '1',
		NOMBRE: 'Mozota',
	},
	{
		CPRO: '50',
		CMUN: '181',
		DC: '8',
		NOMBRE: 'Muel',
	},
	{
		CPRO: '50',
		CMUN: '182',
		DC: '3',
		NOMBRE: 'Muela, La',
	},
	{
		CPRO: '50',
		CMUN: '183',
		DC: '9',
		NOMBRE: 'Munébrega',
	},
	{
		CPRO: '50',
		CMUN: '184',
		DC: '4',
		NOMBRE: 'Murero',
	},
	{
		CPRO: '50',
		CMUN: '185',
		DC: '7',
		NOMBRE: 'Murillo de Gállego',
	},
	{
		CPRO: '50',
		CMUN: '186',
		DC: '0',
		NOMBRE: 'Navardún',
	},
	{
		CPRO: '50',
		CMUN: '187',
		DC: '6',
		NOMBRE: 'Nigüella',
	},
	{
		CPRO: '50',
		CMUN: '188',
		DC: '2',
		NOMBRE: 'Nombrevilla',
	},
	{
		CPRO: '50',
		CMUN: '189',
		DC: '5',
		NOMBRE: 'Nonaspe',
	},
	{
		CPRO: '50',
		CMUN: '190',
		DC: '9',
		NOMBRE: 'Novallas',
	},
	{
		CPRO: '50',
		CMUN: '191',
		DC: '6',
		NOMBRE: 'Novillas',
	},
	{
		CPRO: '50',
		CMUN: '192',
		DC: '1',
		NOMBRE: 'Nuévalos',
	},
	{
		CPRO: '50',
		CMUN: '193',
		DC: '7',
		NOMBRE: 'Nuez de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '194',
		DC: '2',
		NOMBRE: 'Olvés',
	},
	{
		CPRO: '50',
		CMUN: '195',
		DC: '5',
		NOMBRE: 'Orcajo',
	},
	{
		CPRO: '50',
		CMUN: '196',
		DC: '8',
		NOMBRE: 'Orera',
	},
	{
		CPRO: '50',
		CMUN: '197',
		DC: '4',
		NOMBRE: 'Orés',
	},
	{
		CPRO: '50',
		CMUN: '198',
		DC: '0',
		NOMBRE: 'Oseja',
	},
	{
		CPRO: '50',
		CMUN: '199',
		DC: '3',
		NOMBRE: 'Osera de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '200',
		DC: '7',
		NOMBRE: 'Paniza',
	},
	{
		CPRO: '50',
		CMUN: '201',
		DC: '4',
		NOMBRE: 'Paracuellos de Jiloca',
	},
	{
		CPRO: '50',
		CMUN: '202',
		DC: '9',
		NOMBRE: 'Paracuellos de la Ribera',
	},
	{
		CPRO: '50',
		CMUN: '203',
		DC: '5',
		NOMBRE: 'Pastriz',
	},
	{
		CPRO: '50',
		CMUN: '204',
		DC: '0',
		NOMBRE: 'Pedrola',
	},
	{
		CPRO: '50',
		CMUN: '205',
		DC: '3',
		NOMBRE: 'Pedrosas, Las',
	},
	{
		CPRO: '50',
		CMUN: '206',
		DC: '6',
		NOMBRE: 'Perdiguera',
	},
	{
		CPRO: '50',
		CMUN: '207',
		DC: '2',
		NOMBRE: 'Piedratajada',
	},
	{
		CPRO: '50',
		CMUN: '208',
		DC: '8',
		NOMBRE: 'Pina de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '209',
		DC: '1',
		NOMBRE: 'Pinseque',
	},
	{
		CPRO: '50',
		CMUN: '210',
		DC: '5',
		NOMBRE: 'Pintanos, Los',
	},
	{
		CPRO: '50',
		CMUN: '211',
		DC: '2',
		NOMBRE: 'Plasencia de Jalón',
	},
	{
		CPRO: '50',
		CMUN: '212',
		DC: '7',
		NOMBRE: 'Pleitas',
	},
	{
		CPRO: '50',
		CMUN: '213',
		DC: '3',
		NOMBRE: 'Plenas',
	},
	{
		CPRO: '50',
		CMUN: '214',
		DC: '8',
		NOMBRE: 'Pomer',
	},
	{
		CPRO: '50',
		CMUN: '215',
		DC: '1',
		NOMBRE: 'Pozuel de Ariza',
	},
	{
		CPRO: '50',
		CMUN: '216',
		DC: '4',
		NOMBRE: 'Pozuelo de Aragón',
	},
	{
		CPRO: '50',
		CMUN: '217',
		DC: '0',
		NOMBRE: 'Pradilla de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '218',
		DC: '6',
		NOMBRE: 'Puebla de Albortón',
	},
	{
		CPRO: '50',
		CMUN: '219',
		DC: '9',
		NOMBRE: 'Puebla de Alfindén, La',
	},
	{
		CPRO: '50',
		CMUN: '220',
		DC: '3',
		NOMBRE: 'Puendeluna',
	},
	{
		CPRO: '50',
		CMUN: '221',
		DC: '0',
		NOMBRE: 'Purujosa',
	},
	{
		CPRO: '50',
		CMUN: '222',
		DC: '5',
		NOMBRE: 'Quinto',
	},
	{
		CPRO: '50',
		CMUN: '223',
		DC: '1',
		NOMBRE: 'Remolinos',
	},
	{
		CPRO: '50',
		CMUN: '224',
		DC: '6',
		NOMBRE: 'Retascón',
	},
	{
		CPRO: '50',
		CMUN: '225',
		DC: '9',
		NOMBRE: 'Ricla',
	},
	{
		CPRO: '50',
		CMUN: '227',
		DC: '8',
		NOMBRE: 'Romanos',
	},
	{
		CPRO: '50',
		CMUN: '228',
		DC: '4',
		NOMBRE: 'Rueda de Jalón',
	},
	{
		CPRO: '50',
		CMUN: '229',
		DC: '7',
		NOMBRE: 'Ruesca',
	},
	{
		CPRO: '50',
		CMUN: '241',
		DC: '6',
		NOMBRE: 'Sabiñán',
	},
	{
		CPRO: '50',
		CMUN: '230',
		DC: '1',
		NOMBRE: 'Sádaba',
	},
	{
		CPRO: '50',
		CMUN: '231',
		DC: '8',
		NOMBRE: 'Salillas de Jalón',
	},
	{
		CPRO: '50',
		CMUN: '232',
		DC: '3',
		NOMBRE: 'Salvatierra de Esca',
	},
	{
		CPRO: '50',
		CMUN: '233',
		DC: '9',
		NOMBRE: 'Samper del Salz',
	},
	{
		CPRO: '50',
		CMUN: '234',
		DC: '4',
		NOMBRE: 'San Martín de la Virgen de Moncayo',
	},
	{
		CPRO: '50',
		CMUN: '235',
		DC: '7',
		NOMBRE: 'San Mateo de Gállego',
	},
	{
		CPRO: '50',
		CMUN: '236',
		DC: '0',
		NOMBRE: 'Santa Cruz de Grío',
	},
	{
		CPRO: '50',
		CMUN: '237',
		DC: '6',
		NOMBRE: 'Santa Cruz de Moncayo',
	},
	{
		CPRO: '50',
		CMUN: '238',
		DC: '2',
		NOMBRE: 'Santa Eulalia de Gállego',
	},
	{
		CPRO: '50',
		CMUN: '239',
		DC: '5',
		NOMBRE: 'Santed',
	},
	{
		CPRO: '50',
		CMUN: '240',
		DC: '9',
		NOMBRE: 'Sástago',
	},
	{
		CPRO: '50',
		CMUN: '242',
		DC: '1',
		NOMBRE: 'Sediles',
	},
	{
		CPRO: '50',
		CMUN: '243',
		DC: '7',
		NOMBRE: 'Sestrica',
	},
	{
		CPRO: '50',
		CMUN: '244',
		DC: '2',
		NOMBRE: 'Sierra de Luna',
	},
	{
		CPRO: '50',
		CMUN: '245',
		DC: '5',
		NOMBRE: 'Sigüés',
	},
	{
		CPRO: '50',
		CMUN: '246',
		DC: '8',
		NOMBRE: 'Sisamón',
	},
	{
		CPRO: '50',
		CMUN: '247',
		DC: '4',
		NOMBRE: 'Sobradiel',
	},
	{
		CPRO: '50',
		CMUN: '248',
		DC: '0',
		NOMBRE: 'Sos del Rey Católico',
	},
	{
		CPRO: '50',
		CMUN: '249',
		DC: '3',
		NOMBRE: 'Tabuenca',
	},
	{
		CPRO: '50',
		CMUN: '250',
		DC: '6',
		NOMBRE: 'Talamantes',
	},
	{
		CPRO: '50',
		CMUN: '251',
		DC: '3',
		NOMBRE: 'Tarazona',
	},
	{
		CPRO: '50',
		CMUN: '252',
		DC: '8',
		NOMBRE: 'Tauste',
	},
	{
		CPRO: '50',
		CMUN: '253',
		DC: '4',
		NOMBRE: 'Terrer',
	},
	{
		CPRO: '50',
		CMUN: '254',
		DC: '9',
		NOMBRE: 'Tierga',
	},
	{
		CPRO: '50',
		CMUN: '255',
		DC: '2',
		NOMBRE: 'Tobed',
	},
	{
		CPRO: '50',
		CMUN: '256',
		DC: '5',
		NOMBRE: 'Torralba de los Frailes',
	},
	{
		CPRO: '50',
		CMUN: '257',
		DC: '1',
		NOMBRE: 'Torralba de Ribota',
	},
	{
		CPRO: '50',
		CMUN: '258',
		DC: '7',
		NOMBRE: 'Torralbilla',
	},
	{
		CPRO: '50',
		CMUN: '259',
		DC: '0',
		NOMBRE: 'Torrehermosa',
	},
	{
		CPRO: '50',
		CMUN: '260',
		DC: '4',
		NOMBRE: 'Torrelapaja',
	},
	{
		CPRO: '50',
		CMUN: '261',
		DC: '1',
		NOMBRE: 'Torrellas',
	},
	{
		CPRO: '50',
		CMUN: '262',
		DC: '6',
		NOMBRE: 'Torres de Berrellén',
	},
	{
		CPRO: '50',
		CMUN: '263',
		DC: '2',
		NOMBRE: 'Torrijo de la Cañada',
	},
	{
		CPRO: '50',
		CMUN: '264',
		DC: '7',
		NOMBRE: 'Tosos',
	},
	{
		CPRO: '50',
		CMUN: '265',
		DC: '0',
		NOMBRE: 'Trasmoz',
	},
	{
		CPRO: '50',
		CMUN: '266',
		DC: '3',
		NOMBRE: 'Trasobares',
	},
	{
		CPRO: '50',
		CMUN: '267',
		DC: '9',
		NOMBRE: 'Uncastillo',
	},
	{
		CPRO: '50',
		CMUN: '268',
		DC: '5',
		NOMBRE: 'Undués de Lerda',
	},
	{
		CPRO: '50',
		CMUN: '269',
		DC: '8',
		NOMBRE: 'Urrea de Jalón',
	},
	{
		CPRO: '50',
		CMUN: '270',
		DC: '2',
		NOMBRE: 'Urriés',
	},
	{
		CPRO: '50',
		CMUN: '271',
		DC: '9',
		NOMBRE: 'Used',
	},
	{
		CPRO: '50',
		CMUN: '272',
		DC: '4',
		NOMBRE: 'Utebo',
	},
	{
		CPRO: '50',
		CMUN: '274',
		DC: '5',
		NOMBRE: 'Val de San Martín',
	},
	{
		CPRO: '50',
		CMUN: '273',
		DC: '0',
		NOMBRE: 'Valdehorna',
	},
	{
		CPRO: '50',
		CMUN: '275',
		DC: '8',
		NOMBRE: 'Valmadrid',
	},
	{
		CPRO: '50',
		CMUN: '276',
		DC: '1',
		NOMBRE: 'Valpalmas',
	},
	{
		CPRO: '50',
		CMUN: '277',
		DC: '7',
		NOMBRE: 'Valtorres',
	},
	{
		CPRO: '50',
		CMUN: '278',
		DC: '3',
		NOMBRE: 'Velilla de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '279',
		DC: '6',
		NOMBRE: 'Velilla de Jiloca',
	},
	{
		CPRO: '50',
		CMUN: '280',
		DC: '0',
		NOMBRE: 'Vera de Moncayo',
	},
	{
		CPRO: '50',
		CMUN: '281',
		DC: '7',
		NOMBRE: 'Vierlas',
	},
	{
		CPRO: '50',
		CMUN: '283',
		DC: '8',
		NOMBRE: 'Villadoz',
	},
	{
		CPRO: '50',
		CMUN: '284',
		DC: '3',
		NOMBRE: 'Villafeliche',
	},
	{
		CPRO: '50',
		CMUN: '285',
		DC: '6',
		NOMBRE: 'Villafranca de Ebro',
	},
	{
		CPRO: '50',
		CMUN: '286',
		DC: '9',
		NOMBRE: 'Villalba de Perejil',
	},
	{
		CPRO: '50',
		CMUN: '287',
		DC: '5',
		NOMBRE: 'Villalengua',
	},
	{
		CPRO: '50',
		CMUN: '903',
		DC: '8',
		NOMBRE: 'Villamayor de Gállego',
	},
	{
		CPRO: '50',
		CMUN: '288',
		DC: '1',
		NOMBRE: 'Villanueva de Gállego',
	},
	{
		CPRO: '50',
		CMUN: '290',
		DC: '8',
		NOMBRE: 'Villanueva de Huerva',
	},
	{
		CPRO: '50',
		CMUN: '289',
		DC: '4',
		NOMBRE: 'Villanueva de Jiloca',
	},
	{
		CPRO: '50',
		CMUN: '291',
		DC: '5',
		NOMBRE: 'Villar de los Navarros',
	},
	{
		CPRO: '50',
		CMUN: '292',
		DC: '0',
		NOMBRE: 'Villarreal de Huerva',
	},
	{
		CPRO: '50',
		CMUN: '293',
		DC: '6',
		NOMBRE: 'Villarroya de la Sierra',
	},
	{
		CPRO: '50',
		CMUN: '294',
		DC: '1',
		NOMBRE: 'Villarroya del Campo',
	},
	{
		CPRO: '50',
		CMUN: '282',
		DC: '2',
		NOMBRE: 'Vilueña, La',
	},
	{
		CPRO: '50',
		CMUN: '295',
		DC: '4',
		NOMBRE: 'Vistabella',
	},
	{
		CPRO: '50',
		CMUN: '296',
		DC: '7',
		NOMBRE: 'Zaida, La',
	},
	{
		CPRO: '50',
		CMUN: '297',
		DC: '3',
		NOMBRE: 'Zaragoza',
	},
	{
		CPRO: '50',
		CMUN: '298',
		DC: '9',
		NOMBRE: 'Zuera',
	},
	{
		CPRO: '51',
		CMUN: '001',
		DC: '3',
		NOMBRE: 'Ceuta',
	},
	{
		CPRO: '52',
		CMUN: '001',
		DC: '8',
		NOMBRE: 'Melilla',
	},
];

export const municipalitiesTypes = municipalitiesList.reduce((acc, item) => {
	acc[item.CMUN] = item.NOMBRE;
	return acc;
}, {});
