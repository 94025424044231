import {
  EnvelopesWrapper,
  InputCheck,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import Download from 'assets/images/svgDynamic/Download';
import Pdf from 'assets/images/svgDynamic/Pdf';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { MainModal } from 'components/MainModal/MainModal';
import DocView from 'components/MyDocView/MyDocView';
import { CONFIG } from 'config/config';
import { downloadDoc } from 'helpers/downloadDoc';
import { useGetError } from 'hooks/useGetError';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Header from 'layout/components/Header/Header';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Footer from '../../layout/components/Footer/Footer';
import * as S from './styles';

export const SignatureRead = PickerHOC(
	{
		envelope: {
			sourcePath: 'envelopes',
			sourceWrapper: EnvelopesWrapper,
			arrayItemMatcher: {
				type: 'param',
				paramName: 'envelopeId',
				itemPropertyPath: 'id'
			}
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { getPreSignedDocumentUrl } = EnvelopesWrapper.use();
	const { envelope, user, target } = PickerHOC.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;
	const { getError } = useGetError();

	// ESTADO
	const [loading, setLoading] = useState();
	const appStep = useMemo(() => 12, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	const [documents, setDocuments] = useState(envelope.documents);
	const [currentDoc, setCurrentDoc] = useState(undefined);
	const [modalDocOpen, setModalDocOpen] = useState(false);
	const [read, setRead] = useState(
		(documents || []).reduce(
			(acc, act) => ({
				...acc,
				[act.id]: envelope.signStatus !== 'PENDING' ? true : false
			}),
			{}
		)
	);

	useEffect(() => {
		async function fetchPreSignedUrls() {
			let docs = envelope.documents
				.filter((d) => d.name !== 'documento_identidad_01')
				.filter((d) => d.name !== 'documento_identidad_02');

			const docsWithPresignedUrl = await Promise.all(
				docs.map(async (doc) => {
					const { url: preSignedDocumentUrl } = await getPreSignedDocumentUrl(doc.id);
					return {
						...doc,
						preSignedDocumentUrl
					};
				})
			);
			setDocuments(docsWithPresignedUrl);
		}
		fetchPreSignedUrls();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// NAVIGATION
	const back = async () => {
		goBack();
	};
	const next = async () => {
		try {
			setLoading('Guardando...');
			await updateUser({
				...user,
				metadata: {
					...user?.metadata,
					docsRead: true
				}
			});
			CONFIG.routing.signatureRead.next(navigateTo);
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-SignatureRead-KO'
			});
		}
	};
	const isDisabled = useCallback(() => {
		return documents && documents.find((d) => !read?.[d.id]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [read]);

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		console.log('📌 contributionData', contributionData);
		console.log('📌 envelope', envelope);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [envelope]);

	useEffect(() => {
		console.log('🧿 DOCUMENTS READ ', read);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [read]);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={75} title={sectionTitle} subtitle={sectionSubTitle}>
				<View rowSt marginBottomSmSt>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.primary,
							flex: 'none',
							order: 0,
							flexGrow: 0
						}}
					/>
				</View>
				<Text headingSecondarySt fullWidthSt marginBottomLgSt>
					Documentos
				</Text>
				<View innerWrapperSt>
					{(documents || []).map((d) => (
						<S.DocumentWrapper key={d.id}>
							<S.DocumentWrapperText>{CONFIG.views.signatureRead.docNames[d.name] || d.name}</S.DocumentWrapperText>
							<S.DocumentWrapperButtons>
								<Pdf
									style={{
										cursor: 'pointer',
										fill: read?.[d.id] ? CONFIG.colors.primary : CONFIG.colors.grey
									}}
									onClick={() => {
										//OPEN IN MODAL
										setCurrentDoc(d);
										setModalDocOpen(true);
									}}
								/>
								<Download
									style={{
										cursor: 'pointer',
										fill: read?.[d.id] ? CONFIG.colors.primary : CONFIG.colors.grey
									}}
									onClick={() => downloadDoc(d.preSignedDocumentUrl, CONFIG.views.signatureRead.docNames[d.name])}
								/>
								<InputCheck
									style={{
										padding: 4
									}}
									//disabled={true}
									checkedIcon={<CheckBoxOutlinedIcon />}
									value={read?.[d.id]}
									onChange={() => {
										//OPEN IN MODAL
										setCurrentDoc(d);
										setModalDocOpen(true);
									}}
								/>
							</S.DocumentWrapperButtons>
						</S.DocumentWrapper>
					))}
				</View>

				{modalDocOpen && (
					<DocView
						open={modalDocOpen}
						title={CONFIG.views.signatureRead.docNames[currentDoc.name] || currentDoc.name}
						onClose={() => {
							setModalDocOpen(false);
							setRead((prevState) => {
								return { ...prevState, [currentDoc.id]: true };
							});
						}}
						url={currentDoc.preSignedDocumentUrl}
						styleType="white"
						disk
					/>
				)}

				<BackNextButton onClickBack={back} onClickNext={next} nextText={'Firmar'} isNextDisabled={isDisabled()} />
			</Wrapper>
			<Footer />
		</>
	);
});
