import { isMobile } from 'react-device-detect';

const spacing = {
	xs: isMobile ? '2px' : '5px',
	sm: isMobile ? '5px' : '10px',
	md: isMobile ? '10px' : '20px',
	lg: isMobile ? '20px' : '40px',
	xl: isMobile ? '30px' : '50px',
};
export default spacing
