import { Text, View } from '@coinscrap/webapp-core';
import { CONFIG } from 'config/config';
import React from 'react';
import { isMobile } from 'react-device-detect';

import { MainButton } from '../MainButton/MainButton';
import { BackNextButtonIcon } from './BackNextButtonIcon';

const BackNextButton = ({
	marginTop = CONFIG.components.backNextButton.margins.top,
	marginBottom = CONFIG.components.backNextButton.margins.bottom,
	marginBetween = CONFIG.components.backNextButton.margins.between,
	onClickBack = () => console.log(`Click en → ${CONFIG.components.backNextButton.defaultText.back}`),
	onClickNext = () => console.log(`Click en → ${CONFIG.components.backNextButton.defaultText.next}`),
	backText = CONFIG.components.backNextButton.defaultText.back,
	backMode = 'button',
	nextText = CONFIG.components.backNextButton.defaultText.next,
	nextMode = 'button',
	justifyContent = CONFIG.components.backNextButton.justifyContent,
	hasIcon = CONFIG.components.backNextButton.icon.show,
	fontFamily = undefined,
	isBackShown = true,
	isNextShown = true,
	isBackClickable = true,
	isNextClickable = true,
	isBackDisabled = false,
	isNextDisabled = false,
	backWidth = isMobile ? '100%' : 'initial',
	nextWidth = isMobile ? '100%' : 'initial',
	wrapperWidth = '100%',
	backColor = CONFIG.colors.white,
	backTextColor = CONFIG.colors.secondary,
	nextColor = CONFIG.colors.secondary,
	nextTextColor = CONFIG.colors.white,
	//Los loaders mejor que sean undefined por defecto para que los gestione el boton si el usuario no especifica false o true
	isBackLoading,
	isNextLoading
}) => {
	const backCursor = isBackDisabled ? 'inherit' : 'pointer';
	const nextCursor = isNextDisabled ? 'inherit' : 'pointer';

	return (
		<View
			rowSt
			justifyCenterSt={justifyContent === 'center' ? true : false}
			justifyBetweenSt={justifyContent === 'between' ? true : false}
			justifyEndSt={justifyContent === 'end' ? true : false}
			justifyStartSt={justifyContent === 'start' ? true : false}
			justifyEvenlySt={justifyContent === 'evenly' ? true : false}
			style={{
				marginTop: marginTop,
				marginBottom: marginBottom,
				width: wrapperWidth ?? '100%',
				gap: marginBetween
			}}
		>
			{isBackShown && backMode === 'button' && (
				<MainButton
					disabled={isBackDisabled}
					loading={isBackLoading}
					onClick={isBackClickable && onClickBack}
					style={{
						width: backWidth,
						cursor: backCursor,
						backgroundColor: backColor,
						borderColor: backColor,
						textColor: backTextColor,
						placeContent: hasIcon ? 'start' : 'center',
						fontFamily,
						disabled: isBackDisabled
					}}
					backButtonSt
				>
					{hasIcon && (
						<BackNextButtonIcon
							hasIcon={hasIcon}
							variant="left"
							iconColor={{
								icon: backColor,
								bg: backTextColor
							}}
						/>
					)}
					{backText}
				</MainButton>
			)}
			{isBackShown && backMode === 'string' && (
				<Text
					onClick={isBackClickable && onClickBack}
					style={{
						width: backWidth,
						cursor: backCursor,
						backgroundColor: backColor,
						color: backTextColor,
						placeContent: hasIcon ? 'start' : 'center',
						fontFamily,
						disabled: isBackDisabled,
						textAlign: 'center'
					}}
				>
					{hasIcon && (
						<BackNextButtonIcon
							hasIcon={hasIcon}
							variant="left"
							iconColor={{
								icon: backColor,
								bg: backTextColor
							}}
						/>
					)}
					{'\uFF1C '}
					{backText}
				</Text>
			)}
			{isNextShown && nextMode === 'button' && (
				<MainButton
					disabled={isNextDisabled}
					loading={isNextLoading}
					onClick={isNextClickable && onClickNext}
					style={{
						width: nextWidth,
						cursor: nextCursor,
						marginLeft: justifyContent === 'between' ? 'auto' : 0,
						backgroundColor: nextColor,
						borderColor: nextColor,
						textColor: nextTextColor,
						fontFamily,
						disabled: isNextDisabled
					}}
				>
					{nextText}
					{hasIcon && (
						<BackNextButtonIcon
							hasIcon={hasIcon}
							variant="right"
							iconColor={{
								icon: nextColor,
								bg: nextTextColor
							}}
						/>
					)}
				</MainButton>
			)}
		</View>
	);
};

export default BackNextButton;
