import { IdentityDocumentsWrapper, NavigationWrapper, PickerHOC, UiWrapper, UserWrapper } from '@coinscrap/webapp-core';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { useGetError } from 'hooks/useGetError';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Header from '../../layout/components/Header/Header';

export const IdentityBiometricUpsert = PickerHOC({
	dniDocuments: {
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	// CORE WRAPPERS
	const {
		createIdentityDocument,
		changeIdentityDocumentValidationMode,
		setMainIdentityDocument,
		loadUserIdentityDocuments
	} = IdentityDocumentsWrapper.use();
	const { dniDocuments, user } = PickerHOC.use();
	const { updateUser } = UserWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const { getError } = useGetError();

	// ESTADO
	const [loading, setLoading] = useState();
	const appStep = useMemo(() => 8, []);

	const mainUserDni = useMemo(() => dniDocuments.find((d) => d.main) || undefined, [dniDocuments]);
	const docToScan = useMemo(() => user?.metadata?.documentTypeIDS || 'dni', [user]);

	// NAVIGATION
	const next = async () => {
		try {
			setLoading('Empezando el proceso...');
			CONFIG.routing.identityBiometricUpsert.identityBiometric(navigateTo);
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-IdentityBiometricUpsert-KO'
			});
		}
	};

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 dniDocuments', dniDocuments);
		console.log('📌 mainUserDni', mainUserDni);
		console.log('📌 docToScan', docToScan);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [triggerCheck, settriggerCheck] = useState(0);

	useEffect(() => {
		const newDoc = async () => {
			setLoading('Empezando el proceso...');
			const dniHanlded = await createIdentityDocument(docToScan, true);
			await new Promise((r) => setTimeout(r, 2000));
			console.log('📌 dniHanlded', dniHanlded);
			await changeIdentityDocumentValidationMode(dniHanlded.id, true);
			await new Promise((r) => setTimeout(r, 1000));
			await setMainIdentityDocument(dniHanlded.id);
			await new Promise((r) => setTimeout(r, 1000));
			await loadUserIdentityDocuments(user.id);
			const userPayload = {
				...user,
				metadata: {
					...user?.metadata,
					identityBiometricError: null
				}
			};
			await updateUser(userPayload);
		};

		const upsertDoc = async () => {
			if (!mainUserDni) {
				await newDoc();
				settriggerCheck((val) => val + 1);
			} else if (
				user?.metadata?.identityBiometricError !== undefined &&
				user?.metadata?.identityBiometricError !== null
			) {
				await newDoc();
				settriggerCheck((val) => val + 1);
			} else if (mainUserDni && mainUserDni?.verificationStatus === 'VALID') {
				setLoading('El usuario ya ha añadido su documento...');
				CONFIG.routing.identityBiometricUpsert.activateProduct(navigateTo);
			} else {
				await next();
			}
		};

		upsertDoc();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerCheck]);

	return <Wrapper showProgressBar step={appStep} title={''} subtitle={''} />;
});
