import React from 'react';

import {
	ApplicationWrapper,
	BackendWrapper,
	InternationalizationWrapper,
	NavigationWrapper,
	SessionWrapper,
	UiWrapper
} from '@coinscrap/webapp-core';

import routes from 'config/config/routes';

import config from 'config/componentsCore';
import { CONFIG } from 'config/config';

import { EntityWrappers } from './WrapEntitiesHandlers';

const WrappersComponent = ({ children, lang }) => {
	return (
		<ApplicationWrapper>
			<InternationalizationWrapper
				languageResources={{
					es: {
						translation: CONFIG.translations.es.main
					}
				}}
				defaultLang={lang || 'es'}
				options={{
					useLanguageDetector: true, // true || false
					nsSeparator: undefined, // false || undefined
					keySeparator: undefined, // false || undefined
					fallbackLng: 'es', // 'lang' || undefined
					debug: false, // true || false,
					debugMissing: false // true || false,
				}}
			>
				<BackendWrapper>
					<UiWrapper
						config={{
							...config,
							fontFamily: CONFIG.fonts.regular,
							colors: {
								main: CONFIG.colors.primary,
								secondary: CONFIG.colors.secondary,
								defaultFontColor: CONFIG.colors.black
							}
						}}
					>
						<NavigationWrapper>
							<SessionWrapper
								entryPoint={{
									pageIfValid: routes.loginAgent,
									pageIfInvalid: routes.loginAgent
								}}
							>
								<EntityWrappers>{children}</EntityWrappers>
							</SessionWrapper>
						</NavigationWrapper>
					</UiWrapper>
				</BackendWrapper>
			</InternationalizationWrapper>
		</ApplicationWrapper>
	);
};

export default WrappersComponent;
