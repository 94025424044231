import React from 'react';

import { CONFIG } from 'config/config';

import CercleArrow from 'assets/images/svgDynamic/CercleArrow';
import SimpleArrow from 'assets/images/svgDynamic/SimpleArrow';

export const BackNextButtonIcon = ({
	hasIcon,
	side,
	variant = CONFIG.components.backNextButton.icon.variant,
	height = CONFIG.components.backNextButton.icon.size.height,
	width = CONFIG.components.backNextButton.icon.size.width,
	addMargin = true,
}) => {
	const style = {
		height,
		width,
		marginRight: addMargin && side === 'left' ? CONFIG.components.backNextButton.icon.margin : 0,
		marginLeft: !addMargin || side === 'left' ? 0 : CONFIG.components.backNextButton.icon.margin,
	};

	return hasIcon && variant === 'simpleArrow' ? (
		<SimpleArrow variant={side} color={CONFIG.components.backNextButton.icon.color} style={style} />
	) : hasIcon && variant === 'cercleArrow' ? (
		<CercleArrow variant={side} color={CONFIG.components.backNextButton.icon.color} style={style} />
	) : null;
};
