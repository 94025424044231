import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';

export const ButtonsWrapper = styled.div`
    display: ${isMobile ? 'flex' : 'grid'};
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    color: rgb(32, 37, 43);
    box-sizing: border-box;
    font-family: Lato-Regular;  
    margin-bottom: 8px;
    gap: 25px 20px;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    margin-top: 40px;
`;
export const InputWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 5px;
	position: relative;
	width: 100%;
`;

export const InputInner = styled.div`
	align-items: center;
	display: flex;
`;

export const InputLabel = styled.p`
	color: rgb(102, 102, 102);
	font-size: 16px;
	margin-bottom: 12px;
	text-align: center;
`;