import {
  InstitutionsWrapper,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Footer from 'layout/components/Footer/Footer';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import BackNextButton from '../../components/BackNextButton/BackNextButton';
import InputLabel from '../../components/InputLabel/InputLabel';
import { caserOnError } from '../../helpers/caserOnError';
import { useContribution } from '../../hooks/useContribution';
import { useGetError } from '../../hooks/useGetError';
import { useInitialScrollToTop } from '../../hooks/useInitialScrollToTop';
import { useManagers } from '../../hooks/useManagers';
import { usePlans } from '../../hooks/usePlans';

export const BringPlan = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		institution: {
			arrayItemMatcher: {
				itemPropertyPath: 'identifier',
				type: 'value',
				value: 'caser'
			},
			sourcePath: 'institutions',
			sourceWrapper: InstitutionsWrapper
		},
		//Include the "loading" pick data functionality, to load needed additional data
		sources: {
			useHook: useManagers
		},
		plans: {
			useHook: usePlans
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { navigateTo } = NavigationWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { user } = UserWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { target, sources, plans, institution } = PickerHOC.use();
	const { getError } = useGetError();

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);

	const [trigger, setTrigger] = useState(0);

	const onError = (e) => {
		caserOnError(e, 'SIMULATE_PRODUCT', openModal, navigateTo, setTrigger, 0, 0, false);
	};

	const [
		// eslint-disable-next-line no-unused-vars
		setInitialContributionData,
		setSourceData,
		setStartProcess,
		savedContributionData
	] = useContribution(institution, target.id, user, onError, trigger);

	// ESTADO
	const [loading, setLoading] = useState(undefined);
	const appStep = useMemo(() => 3, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	const [listPlansNames, setListPlanNames] = useState([]);
	const [source, setSource] = useState(creationData?.sourcePlan?.managerId ?? null);
	const [plan, setPlan] = useState(creationData?.sourcePlan ?? null);
	useEffect(() => {
		if (source) {
			setListPlanNames(plans.filter((plan) => plan.managerId === source));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [source]);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.bringPlan.selectPlan(navigateTo);
	};
	const nextBeneficiaries = async () => {
		try {
			const targetPayload = {
				...creationData,
				sourcePlan: plan
			};
			await saveTargetProperty(target.id, 'creationData', targetPayload);

			setSourceData(plan);
			setStartProcess(true);
			return;
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-BringPlan-KO'
			});
		}
	};
	useEffect(() => {
		if (savedContributionData) {
			CONFIG.routing.bringPlan.beneficiaries(navigateTo);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savedContributionData]);
	const isDisabled = () => {
		return !plan;
	};

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={25} title={sectionTitle} subtitle={sectionSubTitle}>
				<View rowSt marginBottomSmSt>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.primary,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0
						}}
					/>
				</View>
				<Text headingSecondarySt fullWidthSt marginBottomLgSt>
					{'Trae tu plan de pensiones'}
				</Text>
				<View innerWrapperSt>
					<View inputRowSt style={{ maxWidth: isMobile ? '100%' : '40%' }}>
						<InputLabel
							showLabel={true}
							label="¿Cúal es la gestora de origen?"
							value={source || null}
							onChange={(source) => {
								setSource(source);
								setPlan(undefined);
							}}
							type="autoSuggest"
							selectOptions={sources.map((item) => {
								return {
									label: item.name,
									value: item.id
								};
							})}
							disabled={sources === null}
						/>
					</View>
					<View inputRowSt style={{ maxWidth: isMobile ? '100%' : '40%' }}>
						{listPlansNames.length > 0 && (
							<InputLabel
								showLabel={true}
								label="¿Cúal es el plan de origen?"
								value={plan?.id || null}
								onChange={(plan) => {
									const sourcePlanSelected = listPlansNames.find((item) => item.id === plan);
									setPlan(sourcePlanSelected);
								}}
								type="autoSuggest"
								selectOptions={listPlansNames.map((item) => {
									return {
										label: item.name,
										value: item.id
									};
								})}
							/>
						)}
					</View>
				</View>
				<BackNextButton
					onClickNext={nextBeneficiaries}
					onClickBack={back}
					isBackShown={true}
					isNextDisabled={isDisabled()}
					nextText={'Siguiente'}
					justifyContent={'center'}
				/>
			</Wrapper>
			<Footer />
		</>
	);
});
