import React from 'react';

import { CONFIG } from 'config/config';

import { View } from '@coinscrap/webapp-core';

import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import RoundedProgressBar from 'components/ProgressBar/RoundedProgressBar/RoundedProgressBar';
import TextProgressBar from 'components/ProgressBar/TextProgressBar/TextProgressBar';

export const ProgressBar = ({
	step,
	progressPercent,
	variant = CONFIG.components.progressBar.variant,
	iconInternalStep,
	grow,
}) => {
	const icon =
		iconInternalStep !== undefined
			? CONFIG.project.steps.stepIcons[step - 1][iconInternalStep]
			: CONFIG.project.steps.stepIcons[step - 1];

	step = variant === 'static' ? progressPercent : step


	return variant ? (
		variant === 'text' ? (
			<TextProgressBar step={step} />
		) : variant === 'static' ? (
			<HorizontalProgressBar percent={step} />
		) : variant === 'roundedWithLabel' ? (
			<RoundedProgressBar showLabel={true} step={step} styleWrapper={{ maxWidth: 450 }} />
		) : variant === 'roundedWithoutLabel' ? (
			<RoundedProgressBar showLabel={false} step={step} styleWrapper={{ maxWidth: 450 }} />
		) : variant === 'onlyIcon' ? (
			<>
				{icon && (
					<View rowSt justifyCenterSt growSt={grow} marginBottomMdSt>
						{React.isValidElement(icon) ? (
							icon
						) : (
							<img
								src={icon}
								alt=""
								style={{
									width: 118,
									height: 'auto',
									marginBottom: 35,
									marginTop: 0,
								}}
							/>
						)}
					</View>
				)}
			</>
		) : null
	) : null;
};
