import 'aliceonboarding/dist/aliceonboarding.css';

import {
	Button,
	IdentityDocumentsWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { useIsLocalDev } from 'hooks/useIsLocalDev';
import { Wrapper } from 'layout/components/Body/Wrapper';
import React, { useEffect, useMemo, useRef } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { aliceOnError } from '../../helpers/aliceOnError';
import { useInitialScrollToTop } from '../../hooks/useInitialScrollToTop';
import Footer from '../../layout/components/Footer/Footer';
import Header from '../../layout/components/Header/Header';

//import IframeResizer from 'iframe-resizer-react';

export const IdentityBiometric = PickerHOC({
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	target: {
		sourceWrapper: TargetsWrapper,
		sourcePath: 'targets',
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id'
		}
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	// CORE WRAPPERS
	const { dniData, user, target } = PickerHOC.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const { updateUser } = UserWrapper.use();
	const issuingCountry = user?.metadata?.nationalityIDS?.toUpperCase() || 'ESP';
	const isLocalDev = useIsLocalDev();

	// ESTADO
	const appStep = useMemo(() => 9, []);
	let currentLang = 'es';
	const scanManaggerReference = useRef();

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(false);
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 dniData', dniData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={75} title={''} subtitle={''}>
				<View innerWrapperSt justifyCenterSt alignItemsCenterSt>
					<IdentityDocumentsWrapper.ScanManager
						ref={scanManaggerReference}
						style={{
							marginTop: 30,
							width: '100%',
							height: isMobile ? '500px' : '100%',
							minHeight: isMobile ? '500px' : '750px',
							overflow: 'hidden'
						}}
						identityDocumentId={dniData?.id}
						mockEnabled={false}
						scanConfig={{
							providerIdentifier: window?.__CLIENT_ENV?.CLIENT_ENV_IDENTITY_MOCK === 'true' ? 'mock' : undefined,
							additionalStages: ['selfie'],
							completeTimeout: 2000,
							// Custom "Scan" dependant parameters (for this case is Alice)
							metadata: {
								isIOS: isIOS,
								issuingCountry,
								adjustHeigths: true,
								typographyTheme: 'corporate',
								iconsTheme: 'modern',
								iconsVariant: 'shape',
								language: currentLang,
								documentCapturingType: 'all',
								documentAutoCapturing: true,
								customMessages: {
									es: {
										onboarding_stages_title: 'Documento de identidad',
										onboarding_sides_title_id_card: 'Documento de identidad',
										onboarding_stages_id_card: 'Documento de identidad',
										onboarding_stages_description: 'Proceso de identificación por vídeo'
									},
									onboarding_country_selector_start: 'CONTINUAR'
								},
								customTheme: {
									alice_color_primary: CONFIG.colors.primary,
									alice_color_secondary: CONFIG.colors.grey,
									alice_color_accent: CONFIG.colors.tertiary
								}
							}
						}}
						onSuccess={async (data) => {
							// Respuesta OK, continuamos con lo que venga
							const aliceDNI = data?.value?.data?.report?.documents[0]?.summary_fields?.find(
								(sf) => sf?.name === 'id_number'
							)?.value;
							const sameBenefDNI = target?.creationData?.details?.beneficiaries?.find(
								(be) => be?.dni.toUpperCase() === aliceDNI.toUpperCase()
							);
							const diferentToReceived = user?.metadata?.document && aliceDNI !== user?.metadata?.document;

							if (sameBenefDNI) {
								const userPayload = {
									...user,
									metadata: {
										...user?.metadata,
										identityBiometricError: 'sameBeneficiary'
									}
								};
								await updateUser(userPayload);
								openModal(
									(close) => (
										<MainModal>
											<Text headingPrimarySt>Se ha encontrado un error en el listado de beneficiarios</Text>
											<Text headingSecondarySt>No puedes añadirte tú mismo como beneficiario</Text>
											<Button
												style={{ marginTop: 30, marginBottom: 0 }}
												onClick={() => {
													close();
													CONFIG.routing.identityBiometric.beneficiaries(navigateTo);
												}}
											>
												Modificar
											</Button>
										</MainModal>
									),
									{
										disableClickAway: true,
										closeButton: false,
										executeOnClose: () => {
											CONFIG.routing.identityBiometric.ko(navigateTo);
										},
										modalClass: isMobile ? 'modal-mobile' : 'modal-main',
										key: 'modal-alice-sameBenef'
									}
								);
							} else if (diferentToReceived) {
								const userPayload = {
									...user,
									metadata: {
										...user?.metadata,
										identityBiometricError: 'diferentToInitial'
									}
								};
								await updateUser(userPayload);
								openModal(
									(close) => (
										<MainModal>
											<Text headingPrimarySt>Se ha encontrado un error en el documento añadido</Text>
											<Text headingSecondarySt>
												El número del documento no coincide con el que se inició el proceso.{' '}
											</Text>

											{isLocalDev && (
												<Text headingSecondarySt>
													aliceDNI → {aliceDNI} | initialDNI →{user?.metadata?.document}
												</Text>
											)}
											<Button
												style={{ marginTop: 30, marginBottom: 0 }}
												onClick={() => {
													close();
													CONFIG.routing.identityBiometric.identityBiometricSteps(navigateTo);
												}}
											>
												Modificar
											</Button>
										</MainModal>
									),
									{
										disableClickAway: true,
										closeButton: false,
										modalClass: isMobile ? 'modal-mobile' : 'modal-main',
										key: 'modal-alice-diferent'
									}
								);
							} else {
								CONFIG.routing.identityBiometric.activateProduct(navigateTo);
							}
						}}
						onFailure={(data) => {
							aliceOnError(data, openModal, navigateTo, scanManaggerReference);
						}}
						onCancel={(data) => {
							console.log('💥 onCancel alice data', data);
							CONFIG.routing.identityBiometric.bankAccount(navigateTo);
						}}
					/>
				</View>
			</Wrapper>
			<Footer />
		</>
	);
});
