import {
	AnalyticsWrapper,
	EnvelopesWrapper,
	PickerHOC,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { CONFIG } from 'config/config';
import { downloadDoc } from 'helpers/downloadDoc';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Footer from '../../layout/components/Footer/Footer';

export const ProductCreationFinished = PickerHOC(
	{
		envelope: {
			sourcePath: 'envelopes',
			sourceWrapper: EnvelopesWrapper,
			arrayItemMatcher: {
				type: 'param',
				paramName: 'envelopeId',
				itemPropertyPath: 'id'
			}
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { useHeader, useLoading } = UiWrapper.use();
	const { pageView, emitEvent, updateData } = AnalyticsWrapper.use();
	const { envelope, user, target } = PickerHOC.use();
	const { getPreSignedCombinedSignedEnvelopeUrl } = EnvelopesWrapper.use();

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;

	// ESTADO
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState();
	const appStep = useMemo(() => 14, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	const [urlSigned, setUrlSigned] = useState();

	useEffect(() => {
		async function fetchPreSignedUrl() {
			const { url: preSignedDocumentUrl } = await getPreSignedCombinedSignedEnvelopeUrl(envelope?.id);
			setUrlSigned(preSignedDocumentUrl);
		}
		fetchPreSignedUrl();
	}, [envelope, getPreSignedCombinedSignedEnvelopeUrl]);

	useEffect(() => {
		// TEALIUM PAGE VIEW
		const tealiumData = {
			page_type: 'payment confirmation',
			process_name: 'particulares planes de pensiones',
			process_step: 'cierre contratacion',
			process_type: 'contratacion'
		};

		pageView({
			pageType: tealiumData.page_type,
			processName: tealiumData.process_name,
			processStep: tealiumData.process_step,
			processType: tealiumData.process_type
		});

		updateData(tealiumData);

		// TEALIUM EVENT
		emitEvent({
			tealium_event: 'quoter_purchase_success',
			// billing_cycle: getPeriod(contributionData?.chargePeriod),
			company_id: '0001',
			// protocol_id: '36000367',
			product_id: creationData.productCode,
			product_name: target.productDefinitionIdentifier,
			// product_price: contributionData.amountToCover,
			insurance_collective_number: '36000367',
			// product_certificate_id: 'xxxxxx',
			// order_id: creationData.quoteSimulationId,
			transaction_id: 'xxxxxx'
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	// EFFECTS
	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		console.log('📌 contributionData', contributionData);
		console.log('📌 envelope', envelope);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={100} title={sectionTitle} subtitle={sectionSubTitle}>
				<View innerWrapperSt>
					<Text headingTertiarySt fullWidthSt marginBottomLgSt>
						Para cualquier duda, puedes llamar al{' '}
						<a href={`tel:${CONFIG.project.helpPhone}`}>{CONFIG.project.helpPhoneFormatted}</a>.
					</Text>
					<Text headingTertiarySt fullWidthSt marginBottomLgSt>
						Recuerda que dándote de alta en el{' '}
						<a href={`https://www.caser.es/ecliente/acceso`} target="_blank" rel="noreferrer">
							Área de Clientes de Caser Seguros
						</a>
						, tendrás a tu disposición toda la documentación en cualquier momento.
					</Text>
				</View>
				<BackNextButton
					isBackShown={false}
					onClickNext={() => downloadDoc(urlSigned, 'signed')}
					nextText="Descargar contrato"
				/>
			</Wrapper>
			<Footer />
		</>
	);
});
