import React from 'react';

import { CONFIG } from 'config/config';

import { isMobile } from 'react-device-detect';

import { Text, View } from '@coinscrap/webapp-core';

import { DashedLines } from 'components/DashedLines/DashedLines';

export const SectionTitle = ({ title, style, tooltip = false, grow }) => {
	if (CONFIG.layout.topSection.sectionTitle.variant === 'titleAndLine') {
		return (
			<View alignItemsStartSt style={style}>
				<View rowSt justifyStartSt>
					<Text headingPrimarySt>{title}</Text> {tooltip}
				</View>
				<DashedLines />
			</View>
		);
	} else {
		return (
			<View alignItemsCenterSt marginBottomSmSt style={style} growSt={grow}>
				<Text headingPrimarySt centerSt={isMobile}>
					{title}
				</Text>{' '}
				{tooltip}
			</View>
		);
	}
};
