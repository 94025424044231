import { BackendWrapper } from '@coinscrap/webapp-core';
import { useEffect, useState } from 'react';

export const usePlans = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const [loading, setLoading] = useState(true);
	const [plans, setPlans] = useState();

	useEffect(() => {
		try {
			(async () => {
				const plans = await getInstanceApi().call(
					'indicators/indicatorDefinitions/identifier/planPensionsList/output',
					'GET',
					{
						format: 'json',
						input: { search: '' },
					},
				);
				setPlans(plans);
			})();
		} catch (e) {
			setPlans(null)
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading, payload: plans };
};
