import {
  NavigationWrapper,
  Page,
  PickerHOC,
  TestResponsesWrapper,
  TestsWrapper,
  Text,
  UiWrapper,
  UserWrapper,
} from '@coinscrap/webapp-core';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { CONFIG } from 'config/config';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import BackNextButton from '../../components/BackNextButton/BackNextButton';
import TextSpan from '../../components/TextSpan/TextSpan';
import Footer from '../../layout/components/Footer/Footer';
import QuestionnaireBox from './components/QuestionnaireBox';

const testIdentifier = 'pbc';

export const Pbc = PickerHOC(
	{
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		},
		test: {
			sourceWrapper: TestsWrapper,
			sourcePath: 'tests',
			arrayItemMatcher: {
				type: 'value',
				itemPropertyPath: 'identifier',
				value: testIdentifier
			}
		}
	},
	LoadingScreen
)(() => {
	const { saveResponse, getResponse, registerTestCompletion } = TestResponsesWrapper.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const { updateUser } = UserWrapper.use();
	let { query } = Page.use();
	const internalStep = useMemo(() => {
		return query?.internalStep;
	}, [query]);

	const { user, test } = PickerHOC.use();

	const [loading, setLoading] = useState();

	// ESTADO
	const appStep = useMemo(() => 5, []);
	const [pbcDataStep, setPbcDataStep] = useState(internalStep ? +internalStep : 1);
	console.log('pbcDataStep', pbcDataStep);
	useEffect(() => {
		if (internalStep) {
			setPbcDataStep(internalStep);
		}
	}, [internalStep]);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	// NAVIGATION
	const back = async () => {
		if (pbcDataStep > 1) {
			setPbcDataStep(pbcDataStep - 1);
		} else {
			CONFIG.routing.pbc.beneficiaries(navigateTo);
		}
	};

	const next = async (testValue) => {
		if (pbcDataStep >= test.testQuestions.length) {
			try {
				await registerTestCompletion(testIdentifier);
				setLoading('Guardando...');
				await updateUser({
					...user,
					metadata: {
						...user?.metadata,
						pbcOK: true
					}
				});
				CONFIG.routing.pbc.bankAccount(navigateTo);
			} catch (e) {
				setLoading('Guardando...');
				await updateUser({
					...user,
					metadata: {
						...user?.metadata,
						pbcOK: false
					}
				});
				CONFIG.routing.pbc.ko(navigateTo);
			}
		} else {
			if (testValue !== false) {
				setLoading('Guardando...');
				await updateUser({
					...user,
					metadata: {
						...user?.metadata,
						pbcOK: false
					}
				});
				CONFIG.routing.pbc.ko(navigateTo);
			}
			setPbcDataStep(parseInt(pbcDataStep) + 1);
		}
	};

	const handleSaveResponse = async (qId, value) => {
		console.log(value);
		await saveResponse(qId, value);
		await next(value);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper
				showProgressBar
				step={appStep}
				progressPercent={47 + pbcDataStep * 3}
				title={sectionTitle}
				subtitle={sectionSubTitle}
			>
				<Text headingTertiarySt fullWidthSt marginBottomLgSt>
					<TextSpan style={{ fontFamily: CONFIG.fonts.bold }}>{pbcDataStep.toString()}</TextSpan>/
					{test.testQuestions.length.toString()} preguntas
				</Text>
				{test.testQuestions?.map((tq, index) => {
					if (index === pbcDataStep - 1) {
						return (
							<QuestionnaireBox
								text={tq.title}
								listWithTooltips={true}
								tooltipText={tq.metadata?.tooltip}
								onChange={async (v) => await handleSaveResponse(tq.id, v)}
								value={getResponse(tq.id)}
							/>
						);
					}
					return null;
				})}

				<BackNextButton
					onClickBack={back}
					backMode={'string'}
					backText={pbcDataStep === '1' ? 'Volver' : 'Ir a la pregunta anterior'}
					isBackShown={pbcDataStep === '1' ? false : true}
					isNextShown={false}
					backTextColor={CONFIG.colors.primary}
					marginTop="30px"
				/>
			</Wrapper>
			<Footer />
		</>
	);
});
