import React from 'react';

import { CONFIG } from 'config/config';

import { isMobile } from 'react-device-detect';

import { Text, UiWrapper, View } from '@coinscrap/webapp-core';

import BeneficiaryBox from 'components/BeneficiaryBox/BeneficiaryBox';
import BeneficiaryModal from 'components/BeneficiaryModal/BeneficiaryModal';
import TextSpan from 'components/TextSpan/TextSpan';

export const BeneficiaryList = ({ beneficiaries, setBeneficiaries, totalPercentage }) => {
	const { openModal } = UiWrapper.use();

	return (
		<View>
			<Text labelInputSt style={{ alignSelf: 'flex-start' }}>
				Beneficiarios añadidos
				{totalPercentage < 100 && (
					<TextSpan
						style={{
							marginLeft: 10,
							color: CONFIG.colors.danger,
						}}
					>
						Debes asignar un 100 %
					</TextSpan>
				)}
			</Text>

			{beneficiaries.map((ben, index) => {
				return (
					console.log() || (
						<BeneficiaryBox
							key={`ben-${ben.dni}`}
							ben={ben}
							onDelete={() => {
								setBeneficiaries(
									beneficiaries.filter(function (benf) {
										return benf.dni !== ben.dni;
									}),
								);
							}}
							onEdit={() => {
								openModal(
									(close) => (
										<BeneficiaryModal
											close={close}
											beneficiaries={beneficiaries}
											setBeneficiaries={setBeneficiaries}
											beneficiary={ben}
										/>
									),
									{
										disableClickAway: true,
										closeButton: false,
										modalClass: isMobile ? 'modal-mobile' : 'modal-main',
										key: 'modal-anyadir-beneficiaries',
									},
								);
							}}
						/>
					)
				);
			})}
		</View>
	);
};
