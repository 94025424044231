import colors from 'config/config/colors';
import fonts from 'config/config/fonts';
import fontSizes from 'config/config/fontSizes';

const inputs = {
	showLabels: false,
	maxWidth: { default: '100%', iban: '300px' },
	color: colors.black,
	placeholderColor: colors.greyLight,
	fontFamily: fonts.regular,
	fontSize: fontSizes.md,
	padding: {
		default: '16px 12px',
		button: '14px 12px',
		date: '0px 0px 0px 12px !important',
		select: '10px 0px 10px 0px',
		currency: '0px',
	},
	border: {
		default: `1px solid ${colors.greyLight}`,
		filled: `1px solid ${colors.primary}`,
		radius: '3px',
	},
	placeholder: {
		color: colors.greyLight,
		fontFamily: fonts.regular,
		fontSize: fontSizes.md,
	},
	focus: {
		borderBottom: { full: `5px solid ${colors.primary}`, color: colors.primary },
	},
	selectOptions: {
		color: colors.grey,
		fontFamily: fonts.regular,
		fontSize: fontSizes.md,
	},
};
export default inputs
