import React from 'react';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import { InputCheck, Text } from '@coinscrap/webapp-core';

import * as S from './styles';

export const Conditions = ({ value, onChange, ConditionText, style }) => {
	return (
		<S.ConditionsWrapper style={{ marginTop: 24, ...style }} onClick={onChange}>
			<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={value} />
			{ConditionText && <Text>{ConditionText}</Text>}
		</S.ConditionsWrapper>
	);
};
