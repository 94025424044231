import { Button, Text } from '@coinscrap/webapp-core';
import { CONFIG } from 'config/config';
import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';

import TextSpan from '../TextSpan/TextSpan';
import * as S from './styles';

const BeneficiaryBox = ({ ben, onDelete, onEdit }) => {
	return (
		<S.PrimaryWrapper>
			<S.QuestionnaireBoxWrapper>
				<Text benefKeySt>
					Nombre:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10
						}}
					>
						{ben.name || ''}
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Apellidos:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 5
						}}
					>
						{ben.surname || ''}
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Documento:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10
						}}
					>
						{ben.dni || ''}
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Porcentaje:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10
						}}
					>
						{`${ben.percentage} %` || ''}
					</TextSpan>
				</Text>
			</S.QuestionnaireBoxWrapper>
			<S.QuestionnaireBoxButtonWrapper>
				<Button beneficiaryCircleOptionSt onClick={onEdit}>
					<FiEdit style={{ color: CONFIG.colors.primary }} size={24} />
				</Button>
				<Button beneficiaryCircleOptionSt onClick={onDelete}>
					<RiDeleteBin6Line style={{ color: CONFIG.colors.primary }} size={24} />
				</Button>
			</S.QuestionnaireBoxButtonWrapper>
		</S.PrimaryWrapper>
	);
};

export default BeneficiaryBox;
