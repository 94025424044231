import {
	AnalyticsWrapper,
	BackendWrapper,
	NavigationWrapper,
	PickerHOC,
	SessionWrapper,
	TargetsUtilsWrapper,
	TargetsWrapper,
	TargetTypesWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import InputLabel from 'components/InputLabel/InputLabel';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import routes from 'config/config/routes';
import { gendersList } from 'const/genders';
import { municipalitiesList } from 'const/municipalities';
import { provincesList } from 'const/provinces';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Footer from 'layout/components/Footer/Footer';
import Header from 'layout/components/Header/Header';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import isEmail from 'validator/lib/isEmail';

import CommercialActionsModal from '../../components/CommercialActionsModal/CommercialActionsModal';
import { Conditions } from '../../components/Conditions/Conditions';
import { BirthDate } from '../../components/InputLabel/BirthDate';
import TextSpan from '../../components/TextSpan/TextSpan';
import { useGetError } from '../../hooks/useGetError';
import { useInitialScrollToTop } from '../../hooks/useInitialScrollToTop';

export const LoginClient = PickerHOC({})(() => {
	// CORE WRAPPERS
	const { getInstanceApi } = BackendWrapper.use();
	const { startSession, clearSession } = SessionWrapper.use();
	const { navigateTo, query } = NavigationWrapper.use();
	const { transports, pageView, emitEvent, updateData } = AnalyticsWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { user, updateUser } = UserWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { targetTypes } = TargetTypesWrapper.use();
	const { instanciateNewTarget, userTargets } = TargetsUtilsWrapper.use();
	const { getError } = useGetError();

	// START PROCESS STATE
	const [creatingResources, setCreatingResources] = useState(false);
	const [targetType, setTargetType] = useState(undefined);
	const [currentTarget, setCurrentTarget] = useState(userTargets?.[0] ?? undefined);
	const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
	const [dataSaved, setDataSaved] = useState(false);

	// TARGET CREATION DATA
	const creationData = useMemo(() => {
		return currentTarget?.targetProperties?.find((x) => x.name === 'creationData')?.value;
	}, [currentTarget]);

	// PRODUCT
	const [product, setproduct] = useState(undefined);
	useEffect(() => {
		const producto = query?.producto ?? creationData?.productCode ?? undefined;
		if (producto) {
			setproduct(producto);
		} else {
			let messageError = 'No se ha indicado el producto a contratar. Vuelva a acceder por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-productoNotDefined'
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query?.producto]);

	// ESTADO
	const [loading, setLoading] = useState();
	const [error, setError] = useState();
	const appStep = useMemo(() => 1, []);
	const [loginClientStep, setLoginClientStep] = useState(1);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	// STEP 1
	const [name, setName] = useState(user?.metadata?.name || '');
	const [surname, setSurname] = useState(user?.metadata?.surname || '');
	const [birthDate, setBirthDate] = useState(user?.metadata?.birthDate || null);
	const [gender, setGender] = useState(user?.metadata?.gender || '');

	// STEP 2
	const [address, setAddress] = useState(user?.metadata?.address?.address || '');
	const [postalCode, setPostalCode] = useState(user?.metadata?.address?.postalCode || '');
	const [isPostalCorrect, setIsPostalCorrect] = useState(true);
	const [province, setProvince] = useState(user?.metadata?.address?.province || '');
	const [country, setCountry] = useState(user?.metadata?.address?.country || '');
	const [municipality, setMunicipality] = useState(user?.metadata?.address?.municipality || '');
	const [municipalitiesProvinceList, setMunicipalitiesProvinceList] = useState([]);
	const [email, setEmail] = useState(user?.email?.trim() || '');
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
	const [commercialActions, setCommercialActionsAccepted] = useState(user?.metadata?.commercialActions || false);

	// MUNICIPALITIES
	useEffect(() => {
		const findProvince = provincesList.find(
			(item) => item.VALUE.toString().substr(0, 2) === postalCode.toString().substr(0, 2)
		);
		if (findProvince) {
			setProvince(findProvince.NAME);
			setCountry('España');

			const listMunicipalities = municipalitiesList.filter((item) => item.CPRO === findProvince.VALUE);
			setMunicipalitiesProvinceList(listMunicipalities);
		} else {
			setProvince('');
			setCountry('');
			setMunicipalitiesProvinceList([]);
		}
		return () => {};
	}, [postalCode, user]);

	// CREATE RESOURCES ON BACKGROUND
	useEffect(() => {
		if (!creatingResources) {
			setCreatingResources(true);
			(async () => {
				await nextCreateUser();
			})();
		}
		// eslint-disable-next-line
	}, [creatingResources]);

	// NEXT FUNCTIONS
	const nextCreateUser = async () => {
		try {
			if (user?.metadata?.name && user?.metadata?.surname && user?.metadata?.birthDate) {
			} else {
				await clearSession();
				localStorage.removeItem('B2Flow');
				localStorage.setItem('B2Flow', 'B2C');

				const response = await getInstanceApi().loginUser(
					{
						source: query?.source || '000'
					},
					'anonymous'
				);

				await startSession({ token: response.accessToken });
			}
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Vuelva a acceder por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-StartClient'
				}
			);
		}
	};

	const nextCreateTarget = async (userId, targetTypeId) => {
		try {
			const createdTarget = await instanciateNewTarget({
				targetType: targetTypeId
			});
			setCurrentTarget(createdTarget);
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Reinicie el proceso por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-UpsertProductError'
				}
			);
		}
	};

	const nextCheckAnalytics = async () => {
		// console.log('🕵️‍♀️ CHECKING ANALYTICS LOADED FOR USER');
		try {
			// ESPERAMOS QUE SE HAYAN CARGADO TODOS LOS ANALYTICS EN EL CORE
			// setLoading('Comprobando...');
			const lastEngine = transports[transports?.length - 1 || 0]?.engine || '';
			if (user?.metadata?.analytics?.[lastEngine]) {
				setAnalyticsLoaded(true);

				const tealiumData = {
					page_type: 'quoting',
					process_name: 'particulares planes de pensiones',
					process_step: 'datos contratacion',
					process_type: 'contratacion'
				};

				pageView({
					pageType: tealiumData.page_type,
					processName: tealiumData.process_name,
					processStep: tealiumData.process_step,
					processType: tealiumData.process_type
				});

				updateData(tealiumData);
			}
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Reinicie el proceso por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-UpsertAnalyticsError'
				}
			);
		}
	};

	// ESPERAMOS QUE EL CORE SE ENTERE DE LOS TARGETS TYPES PARA OBTENER EL DE CASER
	useEffect(() => {
		if (!error && !targetType && targetTypes?.length) {
			const selectedTargetType = targetTypes.find((t) => t?.identifier === 'CASER_PENSIONES');
			if (selectedTargetType) {
				setTargetType(selectedTargetType);
			}
		}
	}, [error, targetTypes, targetType]);

	// ESPERAMOS QUE HAYA USER Y TARGET TYPE PARA CREAR UN NUEVO TARGET
	useEffect(() => {
		if (!error && targetType && user && Array.isArray(userTargets) && userTargets?.length === 0 && product) {
			// eslint-disable-next-line no-console
			nextCreateTarget(user.id, targetType.id).catch(console.error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, targetType, user, userTargets, product]);

	// ESPERAMOS QUE HAYA USER PARA COMPROBAR LA CARGA DE ANALYTICS
	useEffect(() => {
		if (!error && user && !analyticsLoaded) {
			// eslint-disable-next-line no-console
			nextCheckAnalytics().catch(console.error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, user, analyticsLoaded]);

	// ESPERAMOS QUE SE HAYAN CARGADO LOS ANALYTICS Y SE HAYAN GUARDADO LOS DATOS
	useEffect(() => {
		if (!error && analyticsLoaded && dataSaved) {
			// eslint-disable-next-line no-console
			nextNavigation(currentTarget.id).catch(console.error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, dataSaved, analyticsLoaded]);

	// NAVIGATION
	const nextSaveData = async (targetId) => {
		try {
			switch (loginClientStep) {
				case 1:
					setLoading('Guardando...');
					setLoginClientStep(2);
					setLoading(undefined);
					return;
				case 2:
					try {
						setLoading('Guardando...');
						const currentAge = moment().diff(moment(birthDate), 'years');
						const userPayload = {
							...user,
							username: {
								name,
								surname
							},
							name,
							surname,
							email,
							phoneNumber,
							metadata: {
								...user?.metadata,
								name,
								surname,
								birthDate,
								gender,
								address: {
									...user?.metadata?.address,
									address,
									postalCode,
									municipality,
									province,
									country
								},
								currentAge,
								commercialActions
							}
						};
						// GUARDAMOS LOS DATOS PREVIOS DE SIMULACIÓN RELATIVOS AL USUARIO
						await updateUser(userPayload);

						// GUARDAMOS LOS DATOS PREVIOS DE SIMULACIÓN RELATIVOS AL TARGET
						setLoading('Actualizando...');
						const targetPayload = {
							...creationData,
							productCode: product
						};
						await saveTargetProperty(targetId, 'creationData', targetPayload);

						if (currentAge > 69) {
							navigateTo(routes.cannotConfirm, {
								clearItems: ['producto'],
								queryParams: {
									fromReason: 'CLIENT_DATA',
									fromView: 'loginClient'
								}
							});
						} else {
							// TEALIUM EVENT (QUOTER CALCULATE)
							emitEvent({
								tealium_event: 'quoter_purchase',
								user_birthdate: birthDate,
								user_full_name: `${name} ${surname}`,
								user_phone_number: phoneNumber,
								user_email_addres: email,
								user_zip_code: postalCode,
								user_province: province,
								user_city: municipality
							});
							setDataSaved(true);
						}
						return;
					} catch (e) {
						setLoading(undefined);
						openModal(() => <MainModal type="error" text={getError(e)} />, {
							disableClickAway: true,
							closeButton: true,
							modalClass: isMobile ? 'modal-mobile' : 'modal-main',
							key: 'modal-loginClient-KO'
						});
					}
					break;
				default:
					setLoading(undefined);
					openModal(
						() => (
							<MainModal
								type="error"
								text={getError(new Error(`loginClientStep no válido: loginClientStep ${loginClientStep}`))}
							/>
						),
						{
							disableClickAway: true,
							closeButton: true,
							modalClass: isMobile ? 'modal-mobile' : 'modal-main',
							key: 'modal-SimulationData_defaultKO'
						}
					);
					break;
			}
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Reinicie el proceso por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-SimulationDataError'
				}
			);
		}
	};
	const nextNavigation = async (targetId) => {
		try {
			if (dataSaved && analyticsLoaded) {
				CONFIG.routing.loginClient.selectPlan(navigateTo, targetId);
			}
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Reinicie el proceso por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-NextNavigationaError'
				}
			);
		}
	};
	const isDisabled = () => {
		if (loginClientStep === 1) {
			return (
				!name ||
				!surname ||
				!birthDate ||
				moment(birthDate).unix() > moment().subtract(18, 'years').unix() ||
				!currentTarget ||
				!gender
			);
		} else if (loginClientStep === 2) {
			return (
				!name ||
				!surname ||
				!birthDate ||
				moment(birthDate).unix() > moment().subtract(18, 'years').unix() ||
				!currentTarget ||
				!gender ||
				!address ||
				!postalCode ||
				postalCode.length !== 5 ||
				!province ||
				!country ||
				!municipality ||
				!email ||
				!phoneNumber ||
				phoneNumber.length !== 12 ||
				!isEmailCorrect
			);
		}
	};
	useHeader(<Header mobile={isMobile} buttonEnabled={analyticsLoaded} />, [analyticsLoaded]);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	const handleInputChange = (setValue) => (e) => {
		const inputValue = e.target.value;
		if (/^[A-Za-z\s]+$/.test(inputValue)) {
			setValue(inputValue);
		}
	};

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', currentTarget);
		console.log('📌 creationData', creationData);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={0} title={sectionTitle} subtitle={sectionSubTitle}>
				<View rowSt marginBottomSmSt>
					<div
						style={{
							width: 45,
							height: 12,
							background: loginClientStep === 1 ? CONFIG.colors.primary : CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: loginClientStep === 2 ? CONFIG.colors.primary : CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0
						}}
					/>
				</View>
				<Text headingSecondarySt fullWidthSt marginBottomLgSt>
					{loginClientStep === 1 ? 'Datos personales' : 'Datos de contacto'}
				</Text>
				<View innerWrapperSt>
					{
						{
							1: (
								<>
									<View inputRowSt>
										<InputLabel
											value={name}
											onChange={handleInputChange(setName)}
											label={'Nombre'}
										/>
									</View>
									<View inputRowSt>
										<InputLabel
											value={surname}
											onChange={handleInputChange(setSurname)}
											label={'Apellidos'}
										/>
									</View>
									<View inputRowSt>
										<BirthDate birthDate={birthDate} setBirthDate={setBirthDate} />
									</View>
									<View inputRowSt>
										<InputLabel
											showLabel={true}
											label="¿Cuál es tu género?"
											value={gender || ''}
											onChange={(e) => {
												setGender(e.target.value);
											}}
											type="select"
											selectOptions={gendersList.map((item) => {
												return {
													label: item.NAME,
													value: item.VALUE
												};
											})}
										/>
									</View>
								</>
							),
							2: (
								<>
									<View inputRowSt>
										<InputLabel
											value={address}
											onChange={(e) => {
												setAddress(e.target.value);
											}}
											label={'Dirección'}
										/>
									</View>
									<View inputRowSt>
										<InputLabel
											value={postalCode}
											onChange={(e) => {
												if (isNaN(+e.target.value) || e.target.value.length > 5) return;

												if (!isPostalCorrect) {
													setIsPostalCorrect(e.target.value.length === 5);
												}
												setPostalCode(e.target.value);
											}}
											onBlur={() => {
												setIsPostalCorrect(postalCode.length === 5);
											}}
											label={'Código postal'}
											errorMsg={!isPostalCorrect && 'Introduce un código postal válido'}
										/>
									</View>
									<View inputRowSt>
										<InputLabel disabled value={province} label={'Provincia'} />
									</View>
									<View inputRowSt style={{ marginBottom: municipalitiesProvinceList.length ? 20 : 0 }}>
										{municipalitiesProvinceList.length > 0 && (
											<InputLabel
												value={municipalitiesProvinceList.length ? municipality || '' : ''}
												onChange={(value) => {
													setMunicipality(value);
												}}
												label={'Municipio'}
												type="autoSuggest"
												selectOptions={municipalitiesProvinceList.map((item) => {
													return {
														label: item.NOMBRE,
														value: item.NOMBRE
													};
												})}
											/>
										)}
									</View>
									<View inputRowSt>
										<InputLabel
											onBlur={() => {
												setIsEmailCorrect(isEmail(email));
											}}
											value={email}
											onChange={(e) => {
												if (!isEmailCorrect) {
													setIsEmailCorrect(isEmail(e.target.value));
												}
												setEmail(e.target.value);
											}}
											label={'Correo electrónico'}
											errorMsg={!isEmailCorrect && `Correo electrónico inválido`}
										/>
									</View>
									<View inputRowSt>
										<InputLabel
											value={phoneNumber}
											onChange={(value) => {
												setPhoneNumber(value);
											}}
											label={'Teléfono'}
											type="phone"
										/>
									</View>
									<View inputRowSt>
										<Conditions
											value={commercialActions}
											onChange={() => {
												setCommercialActionsAccepted(!commercialActions);
											}}
											ConditionText={
												<Text>
													Acepto recibir{' '}
													<TextSpan
														style={{
															color: CONFIG.colors.black,
															textDecoration: 'underline',
															cursor: 'pointer'
														}}
														onClick={() => {
															openModal((close) => <CommercialActionsModal isOpen={true} onClose={close} />, {
																mode: 'old'
															});
														}}
													>
														llamadas comerciales
													</TextSpan>
												</Text>
											}
										/>
									</View>
								</>
							)
						}[loginClientStep]
					}
					{!isDisabled() && (
						<Text headingSecondarySt marginTopMdSt>
							¡Ya queda menos para terminar!
						</Text>
					)}
				</View>
				<BackNextButton
					onClickNext={() => nextSaveData(currentTarget?.id)}
					isBackShown={false}
					isNextDisabled={isDisabled()}
					nextText={'Siguiente'}
					justifyContent={'center'}
				/>
			</Wrapper>
			<Footer />
		</>
	);
});
