import React from 'react';

import { MockContext } from 'App';

export const useGetError = () => {
	const isMock = React.useContext(MockContext);

	const getError = (error, customText) => {
		let messageError = error?.payload?.caserError?.error?.MENSAJE || error?.message?.toString();
		if (!isMock) {
			messageError =
				customText || 'Ha ocurrido un error temporal. Recarga la página por favor.';
		} else {
			console.log('❌ ERROR', error);
		}
		console.log('❌ ERROR', messageError);
		return messageError;
	};

	const getCaserError = (error, customText) => {
		let messageError = error?.payload?.caserError?.error?.MENSAJE || error?.message?.toString();

		const knownErrors = {
			cannotSaveAccountInfoError: 'El IBAN utilizado no es válido',
			errorEnLaGeneracionDelRecibo: 'Error en el WS de Caser',
			errorAlGuardarDatos: 'Error en el WS de Caser',
			cumulos: 'Error por exceso de cúmulos',
			unknown: 'Ha ocurrido un error desconocido',
		};
		const knownCannotConfirmErrors = {
			cannotSaveAccountInfoError: 'BAD_CHARGEACCOUNT',
			errorEnLaGeneracionDelRecibo: 'CASER_ERROR',
			errorAlGuardarDatos: 'CASER_ERROR',
			cumulos: 'CUMULOS',
			unknown: 'UNKNOWN',
		};

		let ko_error = '';
		let knownError;
		if (messageError.includes("Expected response 'output' and received '-'")) {
			knownError = knownErrors['unknown'];
			ko_error = knownCannotConfirmErrors['unknown'];
		} else if (messageError.includes('cannotSaveAccountInfoError')) {
			knownError = knownErrors['cannotSaveAccountInfoError'];
			ko_error = knownCannotConfirmErrors['cannotSaveAccountInfoError'];
		} else if (messageError.includes('ERROR EN LA GENERACIÓN DEL RECIBO')) {
			knownError = knownErrors['errorEnLaGeneracionDelRecibo'];
			ko_error = knownCannotConfirmErrors['errorEnLaGeneracionDelRecibo'];
		} else if (messageError.includes('AL GUARDAR DATOS DE CUENTA')) {
			knownError = knownErrors['errorAlGuardarDatos'];
			ko_error = knownCannotConfirmErrors['errorAlGuardarDatos'];
		} else if (messageError.includes('cúmulos') || messageError.includes('cumulos')) {
			knownError = knownErrors['cumulos'];
			ko_error = knownCannotConfirmErrors['cumulos'];
		} else if (messageError.includes('FECHA PRUEBAS MEDICAS')) {
			knownError = knownErrors['cumulos'];
			ko_error = knownCannotConfirmErrors['cumulos'];
		} else {
			knownError = messageError;
			ko_error = knownCannotConfirmErrors['unknown'];
		}

		if (isMock) {
			knownError += ` || ${messageError}`;
		} else {
			knownError = customText || knownError;
		}

		console.log('❌ ERROR', knownError);
		return { knownError, ko_error };
	};

	return { getError, getCaserError };
};
