import React from 'react';

import { CONFIG } from 'config/config';


import styled from 'styled-components/macro';

const MainBtn = styled.button`
	min-width: 150px;
	width: ${(props) => {
		if (!props.width) {
			return '150px';
		} else if (props.width === 'initial') {
			return '150px';
		} else if (props.width === 'auto') {
			return 'auto';
		} else {
			return ` ${props.width}px`;
		}
	}};
	cursor: pointer;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	font-size: ${CONFIG.fontSizes.md};
	transition: 0.2s all;
	outline: none;
	padding: 10px 30px;
	border-radius: 3px;
	line-height: 24px;
	border: ${(props) => {
		return `2px solid ${props?.style?.borderColor ?? CONFIG.colors.primary}`;
	}};
	background-color: ${(props) => {
		return props?.style?.backgroundColor ?? CONFIG.colors.primary;
	}};
	font-family: ${(props) => {
		return props?.style?.fontFamily ?? CONFIG.fonts.semiBold;
	}};
	color: ${(props) => {
		return props?.style?.textColor ?? CONFIG.colors.white;
	}};
	text-align: center;

	:disabled {
		opacity: 0.5;
		cursor: default;
	}

	:hover {
		${(props) => {
		if (!props?.style?.disabled) {
			return `color: ${CONFIG.colors.secondary} !important;
					background-color: ${CONFIG.colors.white} !important;
					border-color: ${CONFIG.colors.secondary} !important;
					`
		}
	}}
	};

`;

export const MainButton = ({ disabled, loading, style, width, onClick, children }) => {
	return (
		<MainBtn
			disabled={disabled}
			loading={loading}
			style={style}
			onClick={onClick}
			width={width || style?.width || 150}
		>
			{children}
		</MainBtn>
	);
};
