import React, {useState} from 'react';

import moment from 'moment/moment';

import {View} from '@coinscrap/webapp-core';

import InputLabel from 'components/InputLabel/InputLabel';

export const LoanDate = ({loanDate, setLoanDate, isValidDate}) => {
	const [error, setError] = useState('');
	return (
		<View inputFreeRowSt>
			<InputLabel
				style={{marginTop: 0}}
				value={loanDate}
				onChange={(e) => {
					setLoanDate(e);
					if (!isValidDate(e)) {
						setError('La fecha introducida ha expirado.');
					} else {
						setError('');
					}
				}}
				placeholder='Fecha de vencimiento'
				label={'Fecha de vencimiento'}
				type='date'
				errorMsg={error && error}
				minDate={moment()}
				maxDate={moment().add(100, 'years')}
			/>
		</View>
	);
};
