import {
  Button,
  Image,
  NavigationWrapper,
  PickerHOC,
  ProductCreatorWrapper,
  TargetsWrapper,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';
import aportacion from 'assets/images/png/aportacion.png';
import traerplan from 'assets/images/png/traerplan.png';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Footer from 'layout/components/Footer/Footer';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import { useGetError } from '../../hooks/useGetError';
import { useInitialScrollToTop } from '../../hooks/useInitialScrollToTop';

export const SelectPlan = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		productDefinitions: {
			sourceWrapper: ProductCreatorWrapper,
			sourcePath: 'productDefinitions'
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { navigateTo } = NavigationWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { user } = UserWrapper.use();
	const { saveTargetProperty, saveTargetProductData } = TargetsWrapper.use();
	const { target, productDefinitions } = PickerHOC.use();
	const { getError } = useGetError();

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);

	// ESTADO
	const [loading, setLoading] = useState(undefined);
	const appStep = useMemo(() => 2, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	const [productDefinitionUpdated, setProductDefinitionUpdated] = useState(false);
	useEffect(() => {
		// declare the async data fetching function
		const updateProductDefinition = async () => {
			if (target && creationData.productCode && productDefinitions.length > 0 && !productDefinitionUpdated) {
				const productDefinition = productDefinitions.find((def) => {
					return def.additionalData.code === parseInt(creationData.productCode);
				});
				console.log('💥 productDefinition', productDefinition);
				if (!productDefinition) {
					let messageError = 'Ha ocurrido un error interno. Vuelva a acceder por favor.';
					setLoading(undefined);

					openModal(
						() => (
							<ErrorModal>
								<Text headingPrimarySt>{messageError}</Text>
							</ErrorModal>
						),
						{
							disableClickAway: true,
							closeButton: false,
							modalClass: isMobile ? 'modal-xl' : 'modal-main',
							key: 'modal-productoNotDefined'
						}
					);
				} else {
					await saveTargetProductData(target.id, { definition: productDefinition.identifier });
					setProductDefinitionUpdated(true);
				}
			}
		};

		// call the function
		updateProductDefinition()
			// make sure to catch any error
			.catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [target, productDefinitions]);

	// NAVIGATION
	const nextMakeContribution = async () => {
		try {
			const targetPayload = {
				...creationData,
				selectedPlan: 'makeContribution'
			};
			await saveTargetProperty(target.id, 'creationData', targetPayload);
			CONFIG.routing.selectPlan.makeContribution(navigateTo);
			return;
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-SelectPlan-KO'
			});
		}
	};
	const nextBringPlan = async () => {
		try {
			const targetPayload = {
				...creationData,
				selectedPlan: 'bringPlan'
			};
			await saveTargetProperty(target.id, 'creationData', targetPayload);
			CONFIG.routing.selectPlan.bringPlan(navigateTo);
			return;
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-ClientData-Step1-KO'
			});
		}
	};

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={25} title={sectionTitle} subtitle={sectionSubTitle}>
				<View innerWrapperSt>
					<View rowDoubleColumnSt={!isMobile} style={{ maxWidth: isMobile ? '100%' : '75%' }}>
						<View cardSt style={{ marginBottom: isMobile ? 20 : 0 }}>
							{!isMobile && <Image src={aportacion} alt="Caser Pensiones" width={'100%'} height={200} />}
							<View cardInfoSt>
								<Text headingSecondarySt leftSt>
									Haz una aportación inicial a tu plan de pensiones de CASER
								</Text>
								<Button
									primarySt
									style={{ marginTop: 20, width: '100%' }}
									onClick={() => {
										nextMakeContribution();
									}}
								>
									Hacer aportación
								</Button>
							</View>
						</View>
						<View cardSt>
							{!isMobile && <Image src={traerplan} alt="Caser Pensiones" width={'100%'} height={200} />}
							<View cardInfoSt>
								<Text headingSecondarySt leftSt>
									Trae tu plan actual de pensiones a CASER
								</Text>
								<Button
									primarySt
									style={{ marginTop: 20, width: '100%' }}
									onClick={() => {
										nextBringPlan();
									}}
								>
									Traspasar plan
								</Button>
							</View>
						</View>
					</View>
				</View>
			</Wrapper>
			<Footer />
		</>
	);
});
