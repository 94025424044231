const project = {
	template: 'caser', // caser or allianz
	helpPhone: '+34915909738',
	helpPhoneFormatted: '915 909 738',
	helpEmail: 'caser@caser.es',
	helpEmailFormatted: 'garante@garante.es',
	agent: { autoLogin: true, autoNewSimulation: true, addReceivedData: true },
	steps: {
		stepFollowUp: [
		],
		stepTitles: [
			['Contrata un plan de pensiones con CASER'],
			['Contrata un plan de pensiones con CASER'],
			['Contrata un plan de pensiones con CASER'],
			['Designación de beneficiarios'],
			['Cuestionario'],
			['Cuenta bancaria'],
			[''],
			[''],
			['Documento de identidad'],
			['¡Ya casi estamos! Tu póliza se está procesando'],
			['Firma de forma rápida, fácil  y segura'],
			['Documentos'],
			['A una sola firma'],
			['¡Enhorabuena! Contrato firmado con éxito']
		],
		stepSubtitles: [
			[
				'Para poder contratar tu plan de pensiones necesitamos que rellenes los siguientes campos.',
			],
			[
				'Para contratar tu plan de pensiones necesitamos que indiques cuál será tu aportación inicial o, si lo prefieres, puedes traspasar tu plan actual a Caser.',
			],
			['Para poder contratar tu plan de pensiones necesitamos que contestes a unas preguntas. '],
			['Para poder contratar tu plan de pensiones necesitamos que contestes a unas preguntas. '],
			['Para poder contratar tu plan de pensiones necesitamos que contestes a unas preguntas. '],
			['Introduce los datos de la cuenta bancaria en la que quieres domiciliar la póliza. Esta cuenta de cargo debe ser de tu titularidad.'],
			[''],
			[''],
			['Por favor, sigue las indicaciones que se te muestran en la pantalla.'],
			['Estamos trabajando en finalizar el contrato de tu seguro. El proceso puede tardar hasta 30 segundos. Por favor, no cierres la ventana.'],
			['Sigue los siquientes pasos para terminar la contratación de tu plan de pensiones'],
			['Lee con detenimiento los siguientes documentos para terminar la contratación de tu plan de pensiones'],
			['Lee con detenimiento los siguientes documentos para terminar la contratación de tu plan de pensiones'],
			['Recibirás un correo electrónico con toda la información sobre tu plan de pensiones.']
		],
		stepIcons: [
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
		],
	},
};
export default project
