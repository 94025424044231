import styled from 'styled-components/macro';

export const FooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5px;
`;

export const ConditionsWrapper = styled.div`
	display: flex;
	justify-content: center;

	align-items: ${(isMobile) => {
		if (isMobile) {
			return 'center';
		} else {
			return 'center';
		}
	}};

	width: ${(isMobile) => {
		if (isMobile) {
			return '100%';
		} else {
			return '60%';
		}
	}};

	// flex-grow: 1;
`;

export const ConditionsText = styled.p`
	font-size: 12px;
`;
