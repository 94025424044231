import React from 'react';

import { View } from '@coinscrap/webapp-core';

import moment from 'moment';

import InputLabel from 'components/InputLabel/InputLabel';

export const BirthDate = ({ birthDate, setBirthDate }) => {
	return (
		<View inputFreeRowSt>
			<InputLabel
				style={{ marginTop: 0 }}
				value={birthDate}
				onChange={(e) => {
					setBirthDate(e);
				}}
				placeholder="Fecha de nacimiento"
				label={'Fecha de nacimiento'}
				type="date"
				errorMsg={
					(birthDate &&
						moment(birthDate).unix() > moment().subtract(18, 'years').unix() &&
						'La edad mínima de contratación es de 18 años') ||
					(birthDate &&
						moment(birthDate).unix() < moment().subtract(67, 'years').unix() &&
						'La edad máxima de contratación es de 67 años')
				}
			/>
		</View>
	);
};
