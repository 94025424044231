/*
Archivo de configuración donde se asocia una acción [key] con el nombre [valor]
Los valores que asignemos simplemente son nombres cortos de las pantallas para que la app las use internamente,
Las navegaciones no se realizan en base a paths completos si no a nombres cortos
*/

//BLOQUES DE RUTAS
//  1→ AGENTE
//  2→ ACCESO B2B PARA CLIENTE DESDE SMS o EMAIL DE CONTINUE ENVIADO POR EL AGENTE
//  3→ ACCESO B2C PARA CLIENTE DIRECTO
//  4→ CLIENTE

const routes = {
	continue: 'continue',
	loadTarget: 'loadTarget',

	loginClient: 'loginClient',
	landing: 'landing',

	selectPlan: 'selectPlan',
	makeContribution: 'makeContribution',
	bringPlan: 'bringPlan',
	beneficiaries: 'beneficiaries',

	pbc: 'pbc',

	bankAccount: 'bankAccount',

	identityBiometricSteps: 'identityBiometricSteps',
	identityBiometricUpsert: 'identityBiometricUpsert',
	identityBiometric: 'identityBiometric',

	activateProduct: 'activateProduct',

	signatureSteps: 'signatureSteps',
	signatureRead: 'signatureRead',
	signatureContract: 'signatureContract',

	productCreationFinished: 'productCreationFinished',

	cannotConfirm: 'cannotConfirm',
};

export default routes;

export const routing = {
	continue: {
		next: (nav) => {
			navigate(nav, routes.loadTarget, {
				clearItems: ['uniqueIdentifier', 't'],
				handleRedirection: true,
			});
		}
	},
	loadTarget: {
		next: (nav, route, params) => {
			navigate(nav, route, params);
		},
	},
	loginClient: {
		selectPlan: (nav, targetId) => {
			navigate(nav, routes.selectPlan, {
				clearItems: ['producto'],
				routeParams: { targetId }
			});
		}
	},
	selectPlan: {
		makeContribution: (nav) => {
			navigate(nav, routes.makeContribution);
		},
		bringPlan: (nav) => {
			navigate(nav, routes.bringPlan);
		},
		loginClient: (nav) => {
			navigate(nav, routes.beneficiaries);
		},
	},
	makeContribution: {
		beneficiaries: (nav) => {
			navigate(nav, routes.beneficiaries);
		},
		selectPlan: (nav) => {
			navigate(nav, routes.selectPlan);
		},
	},
	bringPlan: {
		beneficiaries: (nav) => {
			navigate(nav, routes.beneficiaries);
		},
		selectPlan: (nav) => {
			navigate(nav, routes.selectPlan);
		},
	},
	beneficiaries: {
		pbc: (nav) => {
			navigate(nav, routes.pbc, {
				queryParams: { internalStep: 1 },
			});
		},
		back: (goBack) => {
			navigateBack(goBack);
		},
	},
	pbc: {
		bankAccount: (nav) => {
			navigate(nav, routes.bankAccount, {
				clearItems: ['internalStep']
			});
		},
		beneficiaries: (nav) => {
			navigate(nav, routes.beneficiaries, {
				clearItems: ['internalStep']
			});
		},
		ko: (nav) => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					fromReason: 'PBC',
					fromView: 'pbc'
				},
			});
		},
	},
	bankAccount: {
		identityBiometricSteps: (nav) => {
			navigate(nav, routes.identityBiometricSteps);
		},
		pbc: (nav) => {
			navigate(nav, routes.pbc, {
				queryParams: { internalStep: 4 },
			});
		},
		ko: (nav) => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					fromReason: 'NOTOWNER_CHARGEACCOUNT',
					fromView: 'bankAccount'
				},
			});
		},
	},
	identityBiometricSteps: {
		identityBiometricUpsert: (nav) => {
			navigate(nav, routes.identityBiometricUpsert);
		},
		activateProduct: (nav) => {
			navigate(nav, routes.activateProduct);
		},
	},
	identityBiometricUpsert: {
		activateProduct: (nav) => {
			navigate(nav, routes.activateProduct);
		},
		identityBiometric: (nav) => {
			navigate(nav, routes.identityBiometric);
		},
	},
	identityBiometric: {
		activateProduct: (nav) => {
			navigate(nav, routes.activateProduct);
		},
		beneficiaries: (nav) => {
			navigate(nav, routes.beneficiaries);
		},
		identityBiometricSteps: (nav) => {
			navigate(nav, routes.identityBiometricSteps);
		},
		bankAccount: (nav) => {
			navigate(nav, routes.bankAccount);
		},
		ko: (nav) => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					fromReason: 'IDENTITY',
					fromView: 'identityBiometric'
				},
			});
		},
	},
	activateProduct: {
		signatureSteps: (nav, envelopeId) => {
			navigate(nav, routes.signatureSteps, {
				mode: 'replace',
				routeParams: { envelopeId },
			});
		},
	},
	signatureSteps: {
		next: (nav) => {
			navigate(nav, routes.signatureRead);
		},
		back: (nav) => {
			navigate(nav, routes.identityBiometric);
		},
	},
	signatureRead: {
		next: (nav) => {
			navigate(nav, routes.signatureContract);
		},
		back: (goBack) => {
			navigateBack(goBack);
		},
	},
	signatureContract: {
		next: (nav) => {
			navigate(nav, routes.productCreationFinished);
		},
		ko: (nav) => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					fromReason: 'SIGN_CONTRACT',
					fromView: 'signatureContract'
				},
			});
		},
		back: (goBack) => {
			navigateBack(goBack);
		},
	},
	productCreationFinished: {},
	cannotConfirm: {
		back: (goBack) => {
			navigateBack(goBack);
		},
	},
	header: {
		ko: (nav) => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					fromReason: 'CALL',
				},
			});
		},
		backClient: (goBack) => {
			navigateBack(goBack);
		},
	},
	footer: {
		nextPrivacity: (nav) => {
			navigate(nav, routes.faq);
		},
		nextConditions: (nav) => {
			navigate(nav, routes.faq);
		},
		nextFaq: (nav) => {
			navigate(nav, routes.faq);
		},
	},
};

const navigate = (nav, ruta, params = {}) => {
	logNavigation(ruta);
	nav(ruta, params);
};

const navigateBack = (goBack) => {
	logNavigation('BACK');
	goBack();
};

const logNavigation = (ruta) => {
	console.log(`🚀 NAVIGATING TO ${ruta}`);
};
