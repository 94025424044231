//ESPAÑOL
import { alcoholTypesES, smokeTypesES } from './translations/es/drugsTypes.js';
import es from './translations/es/translation.json';

//RUSO
//UCRANIANO
const translations = {
	es: {
		main: es,
		drugs: {
			smoke: smokeTypesES,
			alcohol: alcoholTypesES,
		},
	}
};

export default translations;
