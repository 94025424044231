import React from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { CONFIG } from '../../../config/config';

export const ClearButton = ({ show, onClick }) => {
	return (
		<CloseIcon
			style={{
				height: 20,
				width: 'auto',
				color: CONFIG.inputs.color,
				cursor: 'pointer',
				visibility: show ? 'visible' : 'hidden',
				transition: '.2s all',
				paddingLeft: 5,
			}}
			onClick={onClick}
		/>
	);
};
