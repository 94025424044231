import React from 'react';

import {CONFIG} from 'config/config';

import {FiEdit} from 'react-icons/fi';
import {RiDeleteBin6Line} from 'react-icons/ri';

import {Button, Text} from '@coinscrap/webapp-core';

import {getCurrency} from '../../helpers/currency';
import TextSpan from '../TextSpan/TextSpan';
import * as S from './styles';

const BeneficiaryBoxBank = ({ben, onDelete, onEdit}) => {
	console.log('ben', ben);
	return (
		<S.PrimaryWrapper>
			<S.QuestionnaireBoxWrapper>
				<Text benefKeySt>
					Nº de préstamo:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10,
						}}
						beneficiaryBoxBankSt
					>
						{ben.loan || ''}
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Tipo:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10,
						}}
						beneficiaryBoxBankSt
					>
						{ben.loanType.charAt(0).toUpperCase() + ben.loanType.slice(1) || ''}
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Entidad:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10,
						}}
						beneficiaryBoxBankSt
					>
						{ben.externalLoanBankCompany || ''}
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Importe:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10,
						}}
						beneficiaryBoxBankSt
					>
						{getCurrency(ben.loanCost, 0, true) || ''} €
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Fecha de vencimiento:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10,
						}}
						beneficiaryBoxBankSt
					>
						{ben.loanExpirationDate || ''}
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Interés:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10,
						}}
						beneficiaryBoxBankSt
					>
						{`${ben.typeOfInterest}` || ''} %
					</TextSpan>
				</Text>
				<Text benefKeySt>
					Periodo de amortización:
					<TextSpan
						style={{
							fontFamily: CONFIG.fonts.light,
							color: CONFIG.colors.primary,
							marginLeft: 10,
						}}
						beneficiaryBoxBankSt
					>
						{ben.amortizationPeriod.charAt(0).toUpperCase() + ben.amortizationPeriod.slice(1) || ''}
					</TextSpan>
				</Text>
			</S.QuestionnaireBoxWrapper>
			<S.QuestionnaireBoxButtonWrapper>
				<Button beneficiaryCircleOptionSt onClick={onEdit}>
					<FiEdit style={{color: CONFIG.colors.primary}} size={24} />
				</Button>
				<Button beneficiaryCircleOptionSt onClick={onDelete}>
					<RiDeleteBin6Line style={{color: CONFIG.colors.primary}} size={24} />
				</Button>
			</S.QuestionnaireBoxButtonWrapper>
		</S.PrimaryWrapper>
	);
};

export default BeneficiaryBoxBank;
