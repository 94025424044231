import { CONFIG } from 'config/config';

import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';

export const InputLabelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-between;
	width: 100%;
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	width: ${(props) => {
		if (!props.mountExtraSpace) {
			return '100%';
		} else {
			return '85%';
		}
	}};
`;

export const InputLabelWrapperSuffix = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	margin-bottom: ${isMobile ? '15px' : '0px'};
`;

export const InputLabelSuffix = styled.span`
	padding: 6px 12px;
	font-size: 16px;
	color: ${CONFIG.colors.black};
	text-align: center;
	background-color: ${CONFIG.colors.white};
	border: 1px solid ${CONFIG.colors.white};
	white-space: nowrap;
	vertical-align: middle;
	display: flex;
	height: 36px;
	align-items: inherit;
	justify-content: center;
	border-radius: 3px;
`;

export const PasswordInputWrapper = styled.div`
	display: flex;
	align-items: center;
	max-width: ${CONFIG.inputs.maxWidth.default};
`;

export const CurrencyInputWrapper = styled.div`
	max-width: ${CONFIG.inputs.maxWidth.default};
	color: ${CONFIG.inputs.color};
	font-family: ${CONFIG.inputs.fontFamily};
	font-size: ${CONFIG.inputs.fontSize};
	padding: ${CONFIG.inputs.padding.currency};

	border: ${(props) => {
		if (!props.value) {
			return CONFIG.inputs.border.default;
		} else {
			return CONFIG.inputs.border.filled;
		}
	}};
	border-radius: ${CONFIG.inputs.border.radius};

	display: flex;
	justify-content: flex-start;
	align-items: center;

	transition: 0.2s all;
	&:focus-within {
		border-bottom: ${CONFIG.inputs.focus.borderBottom.full};
	}
`;

export const SelectWrapper = styled.div`
	max-width: ${CONFIG.inputs.maxWidth.default};
	display: flex;
	flex-direction: row;
	align-items: center;
	border-color: ${(props) => {
		if (!props.value) {
			return CONFIG.colors.greyLight;
		} else {
			return CONFIG.colors.primary;
		}
	}} !important;;
`;

export const DateWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	width: ${(props) => {
		if (!props.mountClearButton) {
			return '100%';
		} else {
			return '85%';
		}
	}};
`;

export const PasswordInputIcon = styled.div``;
