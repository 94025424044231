import { CONFIG } from 'config/config';

import { isMobile } from 'react-device-detect';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('button', {
	styles: {
		default: {
			minWidth: isMobile ? 200 : 150,
			boxSizing: 'border-box',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			cursor: 'pointer',
			transition: '0.2s all',
			outline: 'none',
			border: `1px solid ${CONFIG.colors.secondary}`,
			borderRadius: '3px',
			padding: '10px 30px',
			backgroundColor: CONFIG.colors.secondary,
			textComponent: {
				color: CONFIG.colors.white,
				fontSize: CONFIG.fontSizes.md,
				fontFamily: CONFIG.fonts.semiBold,
				textAlign: 'center',
				display: 'flex',
				alignItems: 'center',
			},
			disabledMode: {
				opacity: 0.5,
			},
		},
		primarySt: {
			border: `0px solid ${CONFIG.colors.primary}`,
			backgroundColor: CONFIG.colors.primary,
			textComponent: {
				color: CONFIG.colors.white,
			},
		},
		secondarySt: {
			border: `1px solid ${CONFIG.colors.white}`,
			backgroundColor: CONFIG.colors.white,
			textComponent: {
				color: CONFIG.colors.secondary,
			},
		},

		pillSt: {
			backgroundColor: CONFIG.colors.primaryLighter,
			borderColor: CONFIG.colors.white,
			borderRadius: 50,
			textComponent: {
				color: CONFIG.colors.primary,
			},
		},

		transparentSt: {
			backgroundColor: 'transparent',
			borderColor: CONFIG.colors.white,
			textComponent: {
				color: CONFIG.colors.primary,
			},
		},

		smallSt: {
			maxWidth: 150,
		},

		bigTextSt: {
			textComponent: {
				fontSize: CONFIG.fontSizes.lg,
			},
		},

		packagesDownloadPdfSt: {
			height: 50,
			flexGrow: 1,
		},

		sameHeightAsInputsSt: {
			padding: `${CONFIG.inputs.padding.button}`,
		},

		notSelectedSt: {
			backgroundColor: CONFIG.colors.white,
			textComponent: {
				color: CONFIG.colors.secondary,
				fontWeight: CONFIG.fonts.light,
			},
		},

		beneficiaryCircleOptionSt: {
			backgroundColor: CONFIG.colors.white,
			borderColor: CONFIG.colors.white,
			color: CONFIG.colors.white,
			borderRadius: 50,
			height: 45,
			margin: 5,
			width: 45,
			minWidth: 45
		},

		headerSt: {
			backgroundColor: CONFIG.colors.secondary,
			borderColor: CONFIG.colors.secondary,
			borderRadius: 5,
			height: 40,
			margin: 5,
			textComponent: {
				color: CONFIG.colors.white,
				fontFamily: CONFIG.fonts.regular,
				fontSize: 16
			},
			padding: '10px 20px',
			width: '100%'
		},

		...CONFIG.commonStyles.styles,
	},
});
