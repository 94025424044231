import { CONFIG } from 'config/config';

import { isMobile } from 'react-device-detect';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('text', {
	styles: {
		// BASIC
		default: {
			color: CONFIG.colors.black,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.md,
		},

		headerSt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.light,
			fontSize: 18
		},

		headingPrimarySt: {
			color: CONFIG.colors.grey,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.xl,
			textAlign: isMobile ? 'center' : 'center',
		},

		headingSecondarySt: {
			color: CONFIG.colors.grey,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.lg,
			textAlign: 'center',
		},

		headingTertiarySt: {
			color: CONFIG.colors.grey,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.lg,
			textAlign: 'center',
		},

		headingCuartarySt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.lg,
			textAlign: 'center',
		},

		stepsSt: {
			color: CONFIG.colors.grey,
			fontFamily: CONFIG.fonts.light,
			fontSize: CONFIG.fontSizes.lg,
			textAlign: 'center',
		},

		newStepsSt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.xl,
			textAlign: 'center',
			marginRight: CONFIG.spacing.md
		},

		tooltipTitleSt: {
			color: CONFIG.colors.black,
			marginBottom: 10,
			fontFamily: CONFIG.fonts.bold,
			fontSize: isMobile ? 14 : 16,
			textAlign: 'left'
		},
		tooltipTextSt: {
			color: CONFIG.colors.black,
			marginBottom: 10,
			fontFamily: CONFIG.fonts.regular,
			fontSize: isMobile ? 14 : 16,
			textAlign: 'left'
		},

		errorSt: {
			color: CONFIG.colors.danger,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.lg,
			textAlign: 'center',
		},

		errorSecondarySt: {
			color: CONFIG.colors.danger,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.lg,
			textAlign: 'center',
		},

		// INPUTS
		labelInputSt: {
			color: CONFIG.colors.grey,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.md,
			marginBottom: 12,
			marginTop: isMobile ? 12 : 12,
		},

		labelInputLanguageSt: {
			color: CONFIG.colors.grey,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.md,
			marginBottom: 0,
			marginTop: 0,
		},

		inputSelectPlaceholderSt: {
			color: CONFIG.inputs.placeholderColor,
			fontSize: CONFIG.inputs.selectOptions.fontSize,
			fontFamily: CONFIG.inputs.selectOptions.fontFamily,
			paddingLeft: 12
		},

		inputSelectOptionsSt: {
			color: CONFIG.inputs.selectOptions.color,
			fontSize: CONFIG.inputs.selectOptions.fontSize,
			fontFamily: CONFIG.inputs.selectOptions.fontFamily,
			paddingLeft: 12
		},

		inputSelectedOptionsSt: {
			color: CONFIG.inputs.color,
			fontSize: CONFIG.inputs.fontSize,
			fontFamily: CONFIG.inputs.fontFamily,
		},

		errorInputSt: {
			color: CONFIG.colors.danger,
			fontSize: CONFIG.fontSizes.sm,
			fontFamily: CONFIG.fonts.semiBold,
			marginTop: 0,
			alignSelf: 'flex-start',
		},

		errorLinkInputSt: {
			textDecoration: 'underline',
			cursor: 'pointer',
			marginLeft: 4,
		},

		// LANDING
		landingHeaderSt: {
			color: CONFIG.colors.white,
			fontFamily: CONFIG.fonts.semiBold,
			fontSize: 52,
			textAlign: 'center',
		},

		// BENEFICIARY LIST
		benefKeySt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.semiBold,
			fontSize: CONFIG.fontSizes.md,
			textAlign: 'left',
		},

		// PACKAGES TABLE
		coverageFirstCellSt: {
			color: CONFIG.colors.black,
			fontSize: CONFIG.fontSizes.md,
			width: '100%',
			padding: 20,
		},
		coverageCellSt: {
			color: CONFIG.colors.primary,
			fontSize: CONFIG.fontSizes.md,
			width: '100%',
			paddingRight: 10,
		},
		packHeaderCellSt: {
			color: CONFIG.colors.white,
			fontSize: CONFIG.fontSizes.md,
			fontFamily: CONFIG.fonts.light,
			width: '100%',
			padding: 20,
			textAlign: 'center',
		},
		packCellSt: {
			color: CONFIG.colors.success,
			fontSize: CONFIG.fontSizes.lg,
			fontFamily: CONFIG.fonts.semiBold,
		},
		packReceiptSt: {
			color: CONFIG.colors.black,
			fontSize: CONFIG.fontSizes.lg,
			fontFamily: CONFIG.fonts.semiBold,
			width: '100%',
			paddingTop: 10,
			textAlign: 'center',
		},
		packSubReceiptSt: {
			color: CONFIG.colors.black,
			fontSize: CONFIG.fontSizes.md,
			fontFamily: CONFIG.fonts.semiBold,
			width: '100%',
			paddingBottom: 10,
			textAlign: 'center',
		},

		// POLÍTICA DE PRIVACIDAD
		policyDetailTitleSt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.bold,
		},
		policyDetailDescriptionSt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.regular,
		},
		indentedSt: {
			marginLeft: 30,
		},
		underlineSt: {
			textDecoration: 'underline',
		},

		// DASHBOARD
		progressTextSt: {
			color: CONFIG.colors.grey,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.lg,
		},

		activeProgressTextSt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.semiBold,
			fontSize: CONFIG.fontSizes.lg,
		},

		...CONFIG.commonStyles.styles,
	},
});
