import backNextButton from '../components/BackNextButton';
import progressBar from '../components/ProgressBar';

const components = {
	mainWrapper: { width: '65%' },
	progressBar: { ...progressBar },
	backNextButton: { ...backNextButton },
};

export default components;
