import { BackendWrapper } from '@coinscrap/webapp-core';
import moment from 'moment';
import { useCallback } from 'react';


export const useSendEmailContact = (clientId) => {
	const { getInstanceApi } = BackendWrapper.use();
	const apiInstance = getInstanceApi();

	const send = useCallback(
		async (name, phoneNumber, email, prevPage) => {
			if (apiInstance) {
				const definitions = await apiInstance.microserviceCall('Ns')(
					'/notification-definitions',
					'GET',
					{
						filter: { where: { identifier: 'EMAIL_CONTACT' } },
					},
				);

				const eventCreated = await apiInstance.microserviceCall('Ns')(
					`/users/${clientId}/events`,
					'POST',
					{
						notificationDefinitionId: definitions[0]?.id,
						eventDefinitionIdentifier: 'HTTP',
						payload: {
							userName: name,
							phoneNumber: phoneNumber,
							email: email,
							lastPageOfUser: prevPage,
						},
					},
				);

				let status = eventCreated.status;
				let finishDate = moment().add(20, 'seconds').toDate();
				while (status !== 'NOTIFICATIONS_CREATED' || +new Date() > +finishDate) {
					await new Promise((r) => setTimeout(r, 1000));
					const eventPooled = await apiInstance.microserviceCall('Ns')(
						`/events/${eventCreated.id}`,
						'GET',
					);
					status = eventPooled.status;
				}
				if (status !== 'NOTIFICATIONS_CREATED') {
					throw new Error('Timeout reached creating notifications');
				}
				let notifications;
				let notResolvedNotification = undefined;
				do {
					await new Promise((r) => setTimeout(r, 500));
					notifications = await apiInstance.microserviceCall('Ns')(
						`/events/${eventCreated.id}/notifications`,
						'GET',
					);
					notResolvedNotification = notifications?.find(
						(x) => x.status !== 'SENDING_RESOLVED' && !x.errorDate,
					);
				} while (notResolvedNotification);
				const errorNotification = notifications?.find((x) => x.errorDate);
				if (errorNotification) {
					throw new Error(errorNotification.error || 'Error on notification send');
				}
				const sendingData = notifications?.find((x) => x.status === 'SENDING_RESOLVED')
					.sendingData;
				if (sendingData) {
					if (sendingData.publishRes) {
						try {
							const info = sendingData.publishRes;
							if (info.mockEnabled) {
								console.log('📧 EMAIL', sendingData.text);
								// alert(sendingData.text);
							}
						} catch (e) {
							throw new Error('Error on notification parse');
						}
					}
				}
			}
		},
		[apiInstance, clientId],
	);

	return send;
};
