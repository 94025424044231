import {
  IdentityDocumentsWrapper,
  NavigationWrapper,
  PickerHOC,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';
import arrowRigth from 'assets/images/svgStatic/arrowRigth.svg';
import documentIcon from 'assets/images/svgStatic/document.svg';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { useGetError } from 'hooks/useGetError';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { SectionSubTitle } from '../../components/SectionSubTitle/SectionSubTitle';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';
import Footer from '../../layout/components/Footer/Footer';
import * as S from './styles';

export const IdentityBiometricSteps = PickerHOC({
	dniDocuments: {
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	user: {
		sourceWrapper: UserWrapper,
		sourcePath: 'user'
	}
})(() => {
	const { dniDocuments, user } = PickerHOC.use();
	const mainUserDni = dniDocuments.find((d) => d.main);
	const mainUserDniId = mainUserDni?.id;
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const { getError } = useGetError();

	// ESTADO
	const [loading, setLoading] = useState();
	const appStep = useMemo(() => 7, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	// NAVIGATION
	const next = async () => {
		try {
			setLoading('Empezando el proceso...');
			CONFIG.routing.identityBiometricSteps.identityBiometricUpsert(navigateTo);
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-IdentityBiometricSteps-KO'
			});
		}
	};

	useEffect(() => {
		(async () => {
			if (
				mainUserDni &&
				mainUserDni?.verificationStatus === 'VALID' &&
				(user?.metadata?.identityBiometricError === undefined || user?.metadata?.identityBiometricError === null)
			) {
				setLoading('El usuario ya ha añadido su documento...');
				CONFIG.routing.identityBiometricSteps.activateProduct(navigateTo);
			} else if (CONFIG.views.identityBiometrics.steps.identityPre === 'autoNext') {
				setLoading('Cargando...');
				await next();
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainUserDni, mainUserDniId, navigateTo]);

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 dniDocuments', dniDocuments);
		console.log('📌 mainUserDni', mainUserDni);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (CONFIG.views.identityBiometrics.steps.identityPre === 'autoNext') {
		return (
			<Wrapper showProgressBar step={appStep} progressPercent={75} title={sectionTitle} subtitle={sectionSubTitle} />
		);
	}

	if (CONFIG.views.identityBiometrics.steps.identityPre === 'steps') {
		return (
			<Wrapper showProgressBar step={appStep} progressPercent={75} title={sectionTitle} subtitle={sectionSubTitle}>
				<View innerWrapperSt>
					<View itemStepSt>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text stepsSt>Hazte un selfie</Text>
					</View>
					<View itemStepSt>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text stepsSt>Captura el DNI/NIE por ambas caras</Text>
					</View>
				</View>
				<BackNextButton isBackShown={false} onClickNext={next} />
			</Wrapper>
		);
	}

	return (
		<>
			<Wrapper
				showProgressBar
				step={appStep}
				progressPercent={75}
				title={''}
				subtitle={''}
				style={{ justifyContent: 'center' }}
			>
				<View columnSt justifyCenterSt style={{ flexGrow: 1 }}>
					<img
						src={documentIcon}
						alt=""
						style={{
							width: 118,
							height: 'auto',
							marginBottom: 35,
							marginTop: 0
						}}
					/>
					<SectionTitle title={sectionTitle} />
					<SectionSubTitle subtitle={sectionSubTitle} />
				</View>
				<BackNextButton isBackShown={false} onClickNext={next} />
			</Wrapper>
			<Footer />
		</>
	);
});
