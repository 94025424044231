import React, { useState } from 'react';

import { CONFIG } from 'config/config';

import { isMobile } from 'react-device-detect';

import { Text, Tooltip, View } from '@coinscrap/webapp-core';

import Info from 'assets/images/svgDynamic/Info';

export default function TooltipSimple({ ...props }) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<span
			onClick={() => setIsOpen(!isOpen)}
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
		>
			<Tooltip
				open={isOpen}
				withArrow
				placement="right"
				style={{
					containerComponent: {
						display: 'inline-block',
						cursor: 'pointer',
					},
					popperContentComponent: {
						backgroundColor: CONFIG.colors.white,
						borderRadius: 5,
						padding: 18,
						boxShadow: `${CONFIG.colors.primary} 0px 0px 2px 1px`,
						marginLeft: 24,
						border: 0,
						width: isMobile ? null : 600,
						maxWidth: 600,
					},
					arrowComponent: {
						color: CONFIG.colors.primary,
					},
				}}
				title={''}
				content={
					<View fullWidthSt alignItemsStartSt>
						<Text exampleNormalSt style={{ color: CONFIG.colors.primary }}>
							{props.children}
						</Text>
					</View>
				}
			>
				<Info
					style={{ height: 20, width: 20, cursor: 'pointer' }}
					color={{
						icon: CONFIG.colors.primary,
					}}
				/>
			</Tooltip>
		</span>
	);
}
