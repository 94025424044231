import React from 'react';

const CercleArrow = ({ variant, color, style }) => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ ...style }}
		>
			<circle cx="6.75659" cy="6.65924" r="6.5" fill={color.bg} />
			<path
				transform={variant === 'left' ? 'rotate(-180 6.8 6.5)' : undefined}
				d="M6.2236 9.63312L9.19751 6.65921L6.2236 3.6853L5.72766 4.18089L8.20632 6.65921L5.72766 9.13752L6.2236 9.63312Z"
				fill={color.icon}
			/>
		</svg>
	);
};

export default CercleArrow;
