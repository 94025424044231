import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('radioOption', {
	styles: {
		default: {
			textComponent: {
				fontSize: '15px',
				marginTop: 12,
			},
			containerComponent: {
				alignItems: 'flex-start',
				color: CONFIG.colors.white,
			},
			radioComponent: {
				color: CONFIG.colors.white,
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
