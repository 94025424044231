import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('enfatize', {
	styles: {
		default: {
			color: CONFIG.colors.black,
			fontFamily: CONFIG.fonts.semiBold,
		},

		...CONFIG.commonStyles.styles,
	},
});
