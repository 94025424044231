import { BackendWrapper, OperationsWrapper, TargetsWrapper } from '@coinscrap/webapp-core';
import { useEffect, useState } from 'react';

export const useContribution = (
	institution,
	targetId,
	user,
	onError,
	trigger,
) => {
	const { createOperation } = OperationsWrapper.use();
	const { getInstanceApi } = BackendWrapper.use();
	const apiInstance = getInstanceApi();
	const { loadTargets } = TargetsWrapper.use();

	const [savedContributionData, setSavedContributionData] = useState(false);

	const [initialContribution, setInitialContribution] = useState(undefined);
	const [source, setSource] = useState(undefined);

	const [startProcess, setStartProcess] = useState(false);
	const [credentialsValid, setCredentialsValid] = useState();
	const [institutionAccountId, setInstitutionAccountId] = useState(undefined);

	// Crear la institution si no existe
	useEffect(() => {
		if (!institutionAccountId) {
			(async () => {
				const operation = await createOperation(
					'CREATE_INSTITUTION_ACCOUNT',
					{
						institutionIdentifier: institution.identifier,
					},
					undefined,
					undefined,
					{ userId: user.id },
				);
				console.log('🧿 CREATE_INSTITUTION_ACCOUNT', operation);
				setInstitutionAccountId(operation.institutionAccountId);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Validar credenciales
	useEffect(() => {
		if (institutionAccountId) {
			(async () => {
				const operation = await createOperation(
					'VALIDATE_CREDENTIALS',
					{
						institutionAccountId: institutionAccountId,
						payload: {
							value: {
								authenticationMethodIdentifier: 'none',
								authenticationMethodFieldsValue: {},
							},
						},
					},
					undefined,
					undefined,
					{ userId: user.id },
				);
				console.log('🧿 VALIDATE_CREDENTIALS', operation);
				setCredentialsValid(true);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [institutionAccountId]);

	const saveContributionData = async (institutionAccountId) => {
		if (!institutionAccountId) return;
		try {
			const policyConfiguration = {
				initialContribution: initialContribution,
				source: source
			};

			let operation;
			try {
				operation = await apiInstance.saveTargetContributionData(
					targetId,
					policyConfiguration,
				);
			} catch (e) {
				console.log('❌ ERROR', e);
				onError && onError(e);
				return;
			}

			await loadTargets([targetId]);
			await new Promise(r => setTimeout(r, 500));
			console.log('✅ SAVE_CONTRIBUTION_DATA', operation);
			setSavedContributionData(true)
		} catch (e) {
			onError && onError(e);
		} finally {
			setStartProcess(false)
		}
	};

	useEffect(() => {
		if (credentialsValid && institutionAccountId && startProcess) {
			saveContributionData(institutionAccountId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startProcess, credentialsValid, institutionAccountId, trigger]);

	return [
		(newInitialContribution) => {
			console.log('SET setInitialContribution', newInitialContribution);
			setInitialContribution(newInitialContribution);
		},
		(newSource) => {
			console.log('SET setSource', newSource);
			setSource(newSource);
		},
		(boolean) => {
			console.log('SET setStartProcess', boolean);
			setStartProcess(boolean);
		},
		savedContributionData
	];
};
