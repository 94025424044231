import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('imageSelector', {
	styles: {
		default: {
			imageComponent: { margin: 15 },
			cameraComponent: {
				height: 50,
				width: 50,
				color: CONFIG.colors.yellow,
			},
			buttonComponent: {
				width: 'auto',
				minWidth: 150,
				border: '1px solid ' + CONFIG.colors.yellow,
				backgroundColor: 'transparent',
				borderRadius: 30,
				paddingTop: 15,
				paddingBottom: 15,
				textComponent: {
					textTransform: 'uppercase',
					color: CONFIG.colors.white,
					fontSize: '16px',
				},
				disabledMode: {
					border: '1px solid ' + CONFIG.colors.grey,
					textComponent: {
						color: CONFIG.colors.grey,
					},
				},
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
