import colors from 'config/config/colors';
import { isMobile } from 'react-device-detect';

const BackNextButton = {
	width: '100%',
	justifyContent: 'center', // center, between, end, start or evenly
	icon: {
		show: false,
		variant: 'cercleArrow', // simpleArrow or cercleArrow
		color: {
			icon: colors.primary,
			bg: colors.white,
		},
		size: {
			height: 20,
			width: 20,
		},
		margin: 12,
	},
	margins: {
		top: isMobile ? '20px' : '30px',
		bottom: isMobile ? '20px' : '10px',
		between: isMobile ? '10px' : '30px',
	},
	defaultText: {
		back: 'Volver',
		next: 'Siguiente',
	},
};
export default BackNextButton
