import React, { useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { Button, Text, View } from '@coinscrap/webapp-core';

import InputLabel from 'components/InputLabel/InputLabel';

import { CONFIG } from '../../config/config';
import { LoanDate } from '../../views/04_Beneficiaries/components/LoanDate';

let proptypes = {
	close: PropTypes.func
};

const loanTypes = [
	{ label: 'Hipotecario', value: 'hipotecario' },
	{ label: 'Consumo', value: 'consumo' },
	{ label: 'Personal', value: 'personal' }
];
const amortizationPeriodTypes = [
	{ label: 'Anual', value: 'anual' },
	{ label: 'Semestral', value: 'semestral' },
	{ label: 'Trimestral', value: 'trimestral' },
	{ label: 'Mensual', value: 'mensual' }
];

const convertDateToBasicFormat = (date) => {
	//converts a DD-MM-YYYY to YYYY-MM-DD.
	let dateArray = date.split('-');
	return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
};

export const BenefeciaryBank = ({ close, beneficiariesBank, setBeneficiariesBank, beneficiaryBank = undefined }) => {
	const [state, setState] = React.useState(
		beneficiaryBank || {
			loan: '',
			loanType: '',
			externalLoanBankCompany: '',
			loanCost: '',
			typeOfInterest: '',
			amortizationPeriod: ''
		}
	);
	const [loanExpirationDate, setLoanExpirationDate] = useState(
		beneficiaryBank?.loanExpirationDate ? convertDateToBasicFormat(beneficiaryBank?.loanExpirationDate) : null
	);

	const isValidDate = (loanExpirationDate) => {
		return moment(loanExpirationDate).unix() > moment().unix();
	};

	const isDisabled = () => {
		return (
			!state.loan ||
			!state.loanType ||
			!state.loanCost ||
			!loanExpirationDate ||
			!state.externalLoanBankCompany ||
			!state.typeOfInterest ||
			!state.amortizationPeriod ||
			isValidDate(loanExpirationDate) === false
		);
	};

	return (
		<View
			style={{
				padding: isMobile ? 28 : 20,
				background: CONFIG.colors.white
			}}
		>
			<Text headingPrimarySt marginBottomLgSt>
				Datos del préstamo
			</Text>
			<View inputRowSt>
				<form autoComplete="off" style={{ display: 'contents' }}>
					<InputLabel
						value={state.loan || ''}
						onChange={(e) => {
							if (e.target.value.length <= 25) {
								setState({ ...state, loan: e.target.value });
							}
						}}
						label={'Número de préstamo'}
					/>
					{loanTypes.length && (
						<InputLabel
							showLabel={true}
							label="Tipo de préstamo"
							value={state.loanType || ''}
							onChange={(e) => setState({ ...state, loanType: e.target.value })}
							type="select"
							selectOptions={loanTypes.map((item) => {
								return {
									label: item.label,
									value: item.value
								};
							})}
						/>
					)}
					<InputLabel
						value={state.externalLoanBankCompany || ''}
						onChange={(e) => {
							if (e.target.value.length <= 50) {
								setState({ ...state, externalLoanBankCompany: e.target.value });
							}
						}}
						label={'Entidad externa'}
					/>
					<InputLabel
						value={state.loanCost || ''}
						onChange={(e) => {
							if (e.target.value.length <= 14) {
								setState({ ...state, loanCost: e.target.value.replace(/[^\d\n.]/, '') });
							}
						}}
						label={'Importe del préstamo'}
						type={'suffix'}
						suffixText={'€'}
					/>
					<LoanDate loanDate={loanExpirationDate} setLoanDate={setLoanExpirationDate} isValidDate={isValidDate} />
					<InputLabel
						value={state.typeOfInterest}
						onChange={(e) => {
							if (e.target.value.length <= 6)
								setState({ ...state, typeOfInterest: e.target.value.replace(/[^\d\n.]/, '') });
						}}
						label={'Tipo de interés (%)'}
						type={'suffix'}
						suffixText={'%'}
					/>

					{amortizationPeriodTypes.length && (
						<InputLabel
							showLabel={true}
							label="Periodo de amortización"
							value={state.amortizationPeriod || ''}
							onChange={(e) => setState({ ...state, amortizationPeriod: e.target.value })}
							type="select"
							selectOptions={amortizationPeriodTypes.map((item) => {
								return {
									label: item.label,
									value: item.value
								};
							})}
						/>
					)}
				</form>
			</View>
			<View inputRowSt marginTopLgSt fullWidthMobileSt>
				<Button
					secondarySt
					smallSt
					onClick={() => {
						close();
					}}
				>
					Cerrar
				</Button>
				<Button
					smallSt
					disabled={isDisabled()}
					onClick={() => {
						const newBenBank = {
							source: 'bank',
							loan: state.loan,
							loanType: state.loanType.toLowerCase(),
							loanCost: Number(state.loanCost),
							loanExpirationDate: moment(loanExpirationDate).format('DD-MM-YYYY'),
							externalLoanBankCompany: state.externalLoanBankCompany,
							typeOfInterest: Number(state.typeOfInterest),
							amortizationPeriod: state.amortizationPeriod.toLowerCase()
						};
						if (beneficiaryBank) {
							beneficiariesBank[0] = newBenBank;
						} else {
							beneficiariesBank.push(newBenBank);
						}
						setBeneficiariesBank(beneficiariesBank);
						close();
					}}
				>
					{beneficiaryBank ? 'Modificar' : 'Añadir'}
				</Button>
			</View>
		</View>
	);
};

BenefeciaryBank.propTypes = proptypes;
