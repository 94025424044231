import 'react-step-progress-bar/styles.css';

import { Text, View } from '@coinscrap/webapp-core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { BsClipboard2Pulse } from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';
import { HiOutlineDocumentText } from 'react-icons/hi2';
import { LuFileSignature, LuPackageOpen } from 'react-icons/lu';
import { ProgressBar, Step } from 'react-step-progress-bar';

import { CONFIG } from '../../config/config';

class StepProgressBar extends React.Component {
	render() {
		return (
			<View
				rowSt
				style={{
					width: this.props.idsView ? '80%' : '100%',
					marginTop: isMobile ? -10 : 45,
					marginBottom: isMobile ? 30 : 50
				}}
			>
				<ProgressBar
					percent={this.props.percent}
					filledBackground={CONFIG.colors.primary}
					unfilledBackground={CONFIG.colors.greyLight}
					height={12}
					width={'100%'}
				>
					<Step transition="scale">
						{({ accomplished }) => (
							<View colSt>
								{!isMobile && (
									<Text mb2St mediumSt primarySt font18St style={{ marginTop: -35 }}>
										Tus datos personales
									</Text>
								)}
								<div
									style={{
										backgroundColor: `${accomplished ? CONFIG.colors.primary : CONFIG.colors.greyLight}`,
										borderRadius: '50%',
										height: `${isMobile ? '30px' : '50px'}`,
										width: `${isMobile ? '30px' : '50px'}`,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<FaUser style={{ color: 'white' }} size={isMobile ? 14 : 24} />
								</div>
							</View>
						)}
					</Step>
					<Step transition="scale">
						{({ accomplished }) => (
							<View colSt>
								{!isMobile && (
									<Text mb2St mediumSt primarySt font18St style={{ marginTop: -35 }}>
										Personaliza tu plan
									</Text>
								)}
								<div
									style={{
										backgroundColor: `${accomplished ? CONFIG.colors.primary : CONFIG.colors.greyLight}`,
										borderRadius: '50%',
										height: `${isMobile ? '30px' : '50px'}`,
										width: `${isMobile ? '30px' : '50px'}`,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<LuPackageOpen style={{ color: 'white' }} size={isMobile ? 14 : 24} />
								</div>
							</View>
						)}
					</Step>
					<Step transition="scale">
						{({ accomplished }) => (
							<View colSt>
								{!isMobile && (
									<Text mb2St mediumSt primarySt font18St style={{ marginTop: -35 }}>
										Formulario de idoneidad
									</Text>
								)}
								<div
									style={{
										backgroundColor: `${accomplished ? CONFIG.colors.primary : CONFIG.colors.greyLight}`,
										borderRadius: '50%',
										height: `${isMobile ? '30px' : '50px'}`,
										width: `${isMobile ? '30px' : '50px'}`,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<BsClipboard2Pulse style={{ color: 'white' }} size={isMobile ? 18 : 28} />
								</div>
							</View>
						)}
					</Step>
					<Step transition="scale">
						{({ accomplished }) => (
							<View colSt>
								{!isMobile && (
									<Text mb2St mediumSt primarySt font18St style={{ marginTop: -35 }}>
										Documentos
									</Text>
								)}
								<div
									style={{
										backgroundColor: `${accomplished ? CONFIG.colors.primary : CONFIG.colors.greyLight}`,
										borderRadius: '50%',
										height: `${isMobile ? '30px' : '50px'}`,
										width: `${isMobile ? '30px' : '50px'}`,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<HiOutlineDocumentText style={{ color: 'white' }} size={isMobile ? 18 : 28} />
								</div>
							</View>
						)}
					</Step>
					<Step transition="scale">
						{({ accomplished }) => (
							<View colSt>
								{!isMobile && (
									<Text mb2St mediumSt primarySt font18St style={{ marginTop: -35 }}>
										Firma
									</Text>
								)}
								<div
									style={{
										backgroundColor: `${accomplished ? CONFIG.colors.primary : CONFIG.colors.greyLight}`,
										borderRadius: '50%',
										height: `${isMobile ? '30px' : '50px'}`,
										width: `${isMobile ? '30px' : '50px'}`,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<LuFileSignature style={{ color: 'white' }} size={isMobile ? 18 : 28} />
								</div>
							</View>
						)}
					</Step>
				</ProgressBar>
			</View>
		);
	}
}

export default StepProgressBar;
