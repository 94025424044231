import { CONFIG } from 'config/config';

import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';

export const StepCircle = styled.div`
	height: ${CONFIG.views.stepCircles.height};
	width: ${CONFIG.views.stepCircles.width};
	background-color: ${CONFIG.colors.primary};
	border-radius: 50%;
	margin-right: 15px;
	font-size: ${isMobile ? '16px' : '17px'};
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
`;
