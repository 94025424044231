import React from 'react';

import { CONFIG } from 'config/config';

import * as S from './styles';

const TextProgressBar = ({ step }) => {
	return (
		<S.TextProgressBarWrapper>
			<S.TextProgressBar>
				{CONFIG.project.steps.stepTitles.map((item, index, list) => {
					return (
						<S.TextProgressBarItem key={`pbi-${index}`} activeStep={index === step - 1}>
							<S.TextProgressBarLabel
								showLabel={false}
								activeStep={index === step - 1}
							>{`${index + 1}. ${item}`}</S.TextProgressBarLabel>
						</S.TextProgressBarItem>
					);
				})}
			</S.TextProgressBar>
		</S.TextProgressBarWrapper>
	);
};

export default TextProgressBar;
