import 'config/componentsCore/modal.css';

import { Button, Text, View } from '@coinscrap/webapp-core';
import InputLabel from 'components/InputLabel/InputLabel';
import { CONFIG } from 'config/config';
import { ValidateSpanishID } from 'helpers/dni';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import * as S from './styles';

const BeneficiaryModal = ({ close, beneficiaries, setBeneficiaries, beneficiary = undefined }) => {
	let availablePercentage = 0;

	const totalPercentage = beneficiary
		? beneficiaries.reduce(function (acc, ben) {
				return acc + ben.percentage;
		  }, 0) - beneficiary.percentage
		: beneficiaries.reduce(function (acc, ben) {
				return acc + ben.percentage;
		  }, 0);
	//console.log(totalPercentage);

	availablePercentage = 100 - totalPercentage;
	//console.log(availablePercentage);

	const [state, setState] = useState(
		beneficiary || {
			name: '',
			surname: '',
			dni: '',
			percentage: availablePercentage
		}
	);

	const [benToEdit, setBenToEdit] = useState(-1);

	const [percentageInput, setPercentageInput] = useState(state?.percentage ? `${state.percentage} %` : '');

	// CHECKS
	const [isValidDNI, setIsValidDNI] = useState(true);
	const [invalidDNIreason, setInvalidDNIreason] = useState('Documento inválido');
	const [isValidPercentage, setIsValidPercentage] = useState(true);

	const isDisabled = () => {
		return !state?.name || !state?.surname || !state?.dni || !state?.percentage || !isValidDNI || !isValidPercentage;
	};

	const noAnyadido = (dni) => {
		if (beneficiaries.some((ben) => ben.dni === dni)) {
			setInvalidDNIreason('El documento ya ha sido añadido a la lista de beneficiarios.');
			return false;
		}
		return true;
	};

	useEffect(() => {
		if (beneficiary) {
			let benAmodificar = beneficiaries.find((ben) => ben.dni === beneficiary.dni);
			setBenToEdit(beneficiaries.indexOf(benAmodificar));
		}

		return () => {};
	}, [beneficiaries, beneficiary]);

	return (
		<View
			style={{
				padding: isMobile ? 28 : 20,
				background: CONFIG.colors.white
			}}
		>
			<Text headingPrimarySt marginBottomLgSt>
				{'Datos del beneficiario'}
			</Text>
			<View inputRowSt>
				<InputLabel
					value={state?.name || ''}
					onChange={(e) => {
						const inputValue = e.target.value;
						if (/^[A-Za-z\s]*$/.test(inputValue)) {
							setState({ ...state, name: inputValue });
						}
					}}
					label={'Nombre'}
				/>
				<InputLabel
					value={state?.surname || ''}
					onChange={(e) => {
						const inputValue = e.target.value;
						if (/^[A-Za-z\s]*$/.test(inputValue)) {
							setState({ ...state, surname: inputValue });
						}
					}}
					label={'Apellidos'}
				/>
				<InputLabel
					value={state?.dni || ''}
					onBlur={() => {
						setIsValidDNI(ValidateSpanishID(state?.dni).valid && noAnyadido(state?.dni));
					}}
					onChange={(e) => {
						if (!isValidDNI) {
							setIsValidDNI(ValidateSpanishID(e.target.value).valid && noAnyadido(e.target.value));
						}
						setState({ ...state, dni: e.target.value });
					}}
					label={'DNI'}
					errorMsg={!isValidDNI && invalidDNIreason}
				/>
				<InputLabel
					value={percentageInput || ''}
					onChange={(e) => {
						if (!isValidPercentage) {
							setIsValidPercentage(parseFloat(e.target.value) <= availablePercentage && e.target.value > 0);
						}
						if (e.target.value === '') {
							setState({ ...state, percentage: 0 });
							setPercentageInput('');
						} else {
							setState({
								...state,
								percentage: e.target.value.replace(/\D/g, '')
							});
							setPercentageInput(e.target.value.replace(/\D/g, ''));
						}
					}}
					onBlur={() => {
						setIsValidPercentage(state?.percentage <= availablePercentage && state?.percentage > 0);
						if (state.percentage === '') {
							setState({ ...state, percentage: 0 });
							setPercentageInput('');
						} else {
							setState({
								...state,
								percentage: state.percentage
							});
							setPercentageInput(`${state.percentage} %`);
						}
					}}
					label={'Porcentaje'}
					errorMsg={!isValidPercentage && `Máximo asignable: ${availablePercentage} %`}
				/>
			</View>
			<S.ButtonsWrapper>
				<Button
					secondarySt
					sameHeightAsInputsSt
					onClick={() => {
						close();
					}}
				>
					Cerrar
				</Button>
				<Button
					sameHeightAsInputsSt
					disabled={isDisabled()}
					onClick={() => {
						const newBen = {
							source: 'designed',
							name: state.name,
							surname: state.surname,
							dni: state.dni,
							percentage: parseFloat(state.percentage)
						};
						if (beneficiary) {
							beneficiaries[benToEdit] = newBen;
						} else {
							beneficiaries.push(newBen);
						}
						setBeneficiaries(beneficiaries);
						close();
					}}
				>
					{beneficiary ? 'Modificar' : 'Añadir'}
				</Button>
			</S.ButtonsWrapper>
		</View>
	);
};

export default BeneficiaryModal;
