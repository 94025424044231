import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('selectItem', {
	styles: {
		default: {
			textComponent: {
				fontSize: '15px',
				color: CONFIG.colors.black,
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
