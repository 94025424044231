const colors = {
	primary: '#29877f',
	primaryLight: '#F5FAFA',
	primaryLighter: '#E5F1EF',
	primaryDark: '#001967',
	primaryDarker: '#000C45',

	secondary: '#EC7A08',
	secondaryLight: '#ffb268',
	secondaryLighter: '#ffffff',
	secondaryDark: '#ffffff',
	secondaryDarker: '#ffffff',

	tertiary: '#666666',
	tertiaryLight: '#DEDEDE',
	tertiaryLighter: '#C4C4C4',
	tertiaryDark: '#24191B',
	tertiaryDarker: '#18090E',

	white: '#FFFFFF',
	greyLight: '#D4D4D7',
	grey: '#585C5F',
	greyDark: '#4a4a4a',
	black: '#20252B',

	danger: '#29877f',
	success: '#649544'
};
export default colors
