import { countriesEuropeES } from 'const/countriesEurope';

const views = {
	activateProduct: {
		showBars: true,
		showDots: false,
		showLoader: true,
	},
	signatureRead: {
		docNames: {
			SEPA: 'Mandato SEPA',
			CCPP: 'Solicitud de seguro',
			caser_pbc: 'Formulario de conocimiento de cliente',
		},
	},
	identityBiometrics: {
		steps: {
			identityPre: 'autoNext', // steps or onlyText or autoNext
			selectDocumentCountry: false,
			selectDocumentType: {
				include: false,
				validDocumentCountries: countriesEuropeES,
				validDocumentTypes: [
					{ value: 'dni', label: 'DNI' },
					{ value: 'driverLicense', label: 'Carnet de conducir' },
				],
			},
			provider: 'alice', // alice or manual
		},
	},
	cannotConfirm: {
		showPreviousCallMe: true,
		conditionsType: 'check', // check or button
	},
};
export default views
