import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';

import { Button, Text, View } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

const DocViewHeader = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 24px;
	background-color: ${CONFIG.colors.primaryLighter};
`;
const DocViewFooter = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 16px;
	margin-top: 30px;
	margin-bottom: 20px;
`;

const DocView = (props) => {
	const [numPages, setNumPages] = useState(null);

	const docRef = useRef();

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	let backgroundImage = 'url(/images/bg_body.png)';
	if (props.styleType === 'white') {
		backgroundImage = '';
	}

	const theme = createTheme({
		overrides: {
			MuiDialog: {
				paper: {
					background: backgroundImage
				}
			}
		}
	});

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog fullScreen open={props.open} onClose={() => props.onClose()} TransitionComponent={Transition}>
				<DocViewHeader>
					<Text headingPrimarySt style={{ fontSize: '26px', marginBottom: 0 }}>
						{props.title}
					</Text>
					{!isMobile && (
						<View rowSt justifyEndSt style={{ position: 'absolute', right: '16px' }}>
							Lee todo el documento antes de continuar
						</View>
					)}
				</DocViewHeader>
				<Document
					className={'docViewDoc'}
					loading={'Cargando documento...'}
					error={'Ha ocurrido un error al cargar el documento.'}
					file={{ url: props.url }}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					{[...Array(numPages).keys()].map((p, index) => (
						<Page
							key={`PDF_page_${index}`}
							className={'docViewPage'}
							loading={'Cargando página...'}
							error={'Ha ocurrido un error al cargar la página.'}
							pageNumber={p + 1}
							width={isMobile ? 300 : 1000}
						/>
					))}
				</Document>
				<View style={{ height: 10 }}>
					<View ref={docRef} />
				</View>
				<ArrowForwardIosIcon
					onClick={() => {
						docRef.current.scrollIntoView({ behavior: 'smooth' });
					}}
					style={{
						position: 'fixed',
						bottom: isMobile ? 25 : 30,
						right: 30,
						width: 45,
						height: 'auto',
						transform: 'rotate(90deg)',
						opacity: 1,
						cursor: 'pointer',
						fill: CONFIG.colors.primary,
						display: numPages === null ? 'none' : undefined
					}}
				/>
				<DocViewFooter>
					<Button disabled={numPages === null} onClick={() => props.onClose()}>
						Marcar como leído
						<CloseIcon style={{ marginLeft: 10 }} />
					</Button>
				</DocViewFooter>
			</Dialog>
		</MuiThemeProvider>
	);
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default DocView;
