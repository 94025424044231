import {
  EnvelopesWrapper,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { MainModal } from 'components/MainModal/MainModal';
import { CONFIG } from 'config/config';
import { useGetError } from 'hooks/useGetError';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Footer from '../../layout/components/Footer/Footer';
import * as S from './styles';

export const SignatureSteps = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { navigateTo } = NavigationWrapper.use();
	const { loadUserEnvelopes } = EnvelopesWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { user, target } = PickerHOC.use();

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;
	const { getError } = useGetError();

	// ESTADO
	const [loading, setLoading] = useState();
	const appStep = useMemo(() => 11, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	// NAVIGATION
	const next = async () => {
		try {
			await loadUserEnvelopes(user.id);
			setLoading('Empezando el proceso...');
			CONFIG.routing.signatureSteps.next(navigateTo);
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-SignatureSteps-KO'
			});
		}
	};

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		console.log('📌 contributionData', contributionData);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={75} title={sectionTitle} subtitle={sectionSubTitle}>
				<View rowSt marginBottomSmSt>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.primary,
							flex: 'none',
							order: 0,
							flexGrow: 0
						}}
					/>
				</View>
				<Text headingSecondarySt fullWidthSt>
					Documentos
				</Text>
				<View innerWrapperSt style={{ maxWidth: 300 }}>
					<S.StepWrapper>
						<S.StepWrapperItem>
							<Text newStepsSt>01</Text>
							<Text headingTertiarySt>Abre los documentos</Text>
						</S.StepWrapperItem>
						<S.StepWrapperItem>
							<Text newStepsSt>02</Text>
							<Text headingTertiarySt>Márcalos como leídos</Text>
						</S.StepWrapperItem>
						<S.StepWrapperItem>
							<Text newStepsSt>03</Text>
							<Text headingTertiarySt>Pulsa para continuar</Text>
						</S.StepWrapperItem>
						<S.StepWrapperItem>
							<Text newStepsSt>04</Text>
							<Text headingTertiarySt>Firma los documentos</Text>
						</S.StepWrapperItem>
					</S.StepWrapper>
				</View>
				<BackNextButton isBackShown={false} onClickNext={next} />
			</Wrapper>
			<Footer />
		</>
	);
});
