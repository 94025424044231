import { BackendWrapper } from '@coinscrap/webapp-core';
import { useEffect, useState } from 'react';

export const useManagers = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const [loading, setLoading] = useState(true);
	const [sources, setSources] = useState();

	useEffect(() => {
		try {
			(async () => {
				const sourcesResponse = await getInstanceApi().call(
					'indicators/indicatorDefinitions/identifier/managerList/output',
					'GET',
					{
						format: 'json',
					},
				);
				setSources(sourcesResponse);
			})();
		} catch (e) {
			setSources(null)
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading, payload: sources };
};
