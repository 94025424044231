import { NavigationWrapper, PickerHOC, TargetsWrapper, Text, UiWrapper, UserWrapper, View } from '@coinscrap/webapp-core';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { Conditions } from 'components/Conditions/Conditions';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import TextSpan from 'components/TextSpan/TextSpan';
import { CONFIG } from 'config/config';
import { cleanIBANNumber, isValidIBANNumber } from 'helpers/Iban';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Footer from '../../layout/components/Footer/Footer';
import * as S from './styles';

export const BankAccount = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { target, user } = PickerHOC.use();

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;

	// ESTADO
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState();
	const appStep = useMemo(() => 6, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	const [chargeAccount, setChargeAccount] = useState(creationData?.chargeAccount || '');
	const [ownChargeAccount, setOwnChargeAccount] = useState(creationData?.ownChargeAccount || undefined);
	const [ibanError, setIbanError] = useState(false);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.bankAccount.pbc(navigateTo);
	};
	const next = async () => {
		await saveTargetProperty(target.id, 'creationData', {
			...creationData,
			chargeAccount: chargeAccount.replace(/\s+/g, ''),
			ownChargeAccount
		});

		if (ownChargeAccount === false) {
			CONFIG.routing.bankAccount.ko(navigateTo);
		} else {
			CONFIG.routing.bankAccount.identityBiometricSteps(navigateTo);
		}
	};
	const isDisabled = () => {
		if (CONFIG.project.template === 'allianz') {
			return !chargeAccount || ibanError || !ownChargeAccount === undefined;
		}
		return !chargeAccount || ibanError || !ownChargeAccount;
	};

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		console.log('📌 contributionData', contributionData);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={75} title={sectionTitle} subtitle={sectionSubTitle}>
				<View rowSt marginBottomSmSt>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.primary,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0,
							marginRight: 5
						}}
					/>
					<div
						style={{
							width: 45,
							height: 12,
							background: CONFIG.colors.greyLight,
							flex: 'none',
							order: 0,
							flexGrow: 0
						}}
					/>
				</View>
				<Text headingSecondarySt fullWidthSt>
					Cuenta de cargo
				</Text>
				<View innerWrapperSt>
					<View inputRowOneColumnSt justifyItemsCenterSt marginTopLgSt>
						<InputLabel
							type="custom"
							style={{
								marginTop: CONFIG.project.template === 'allianz' ? -20 : 0,
								alignItems: 'center'
							}}
							label={'IBAN'}
						>
							<S.IbanInput
								maxLength="24"
								placeholder="ESXX XXXX XXXX XXXX XXXX XXXX"
								value={chargeAccount}
								onChange={(e) => {
									if (ibanError) {
										setIbanError(false);
									}
									setChargeAccount(cleanIBANNumber(e.target.value.toUpperCase()));
								}}
								onBlur={() => {
									const validateIban = isValidIBANNumber(chargeAccount);
									let error = false;
									if (validateIban === 1) {
										error = false;
									} else {
										error = true;
									}
									setIbanError(error);
								}}
							/>
						</InputLabel>

						{ibanError && <Text errorInputSt>Introduce un nº de cuenta válido</Text>}
					</View>

					<Conditions
						value={ownChargeAccount}
						onChange={() => {
							setOwnChargeAccount(!ownChargeAccount);
						}}
						ConditionText={
							<>
								<TextSpan>Declaro que la cuenta introducida es de mi titularidad</TextSpan>
							</>
						}
					/>
				</View>
				<BackNextButton onClickBack={back} onClickNext={next} isNextDisabled={isDisabled()} />
			</Wrapper>
			<Footer />
		</>
	);
});
