import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { InputDate, Text, View } from '@coinscrap/webapp-core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import moment from 'moment';

import calendarIcon from 'assets/images/svgStatic/calendar.svg';
import * as InputS from 'components/Input/styles';
import { CONFIG } from '../../config/config';
import Input from '../Input/Input';
import { SuggestOption } from '../Input/styles';
import { ClearButton } from './components/ClearButton';
import keyboardArrowDown from './svgIcons/KeyboardArrowDownIcon';

import { countriesFlags } from 'const/countriesFlags';

import * as S from './styles';

const InputLabel = ({
	label,
	showLabel = true,
	onChange,
	value,
	type = 'input',
	selectOptions = [],
	children,
	disabled,
	style,
	styleLabel = {},
	placeholder = '',
	onBlur,
	errorMsg,
	selectType,
	customTextLabel = false,
	tooltip = undefined,
	currencyItem,
	minDate,
	maxDate,
	mountClearButton,
	showClearButton,
	onClickClearButton,
	suffixText = ''
}) => {
	const [passwordVisibility, setPasswordVisibility] = useState(false);

	// custom autoSuggest
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [input, setInput] = useState(
		type === 'autoSuggest' ? selectOptions.find((item) => item.value === value)?.label || '' : ''
	);

	const onChangeSuggest = (e) => {
		const userInput = e.target.value;

		// Filter our suggestions that don't contain the user's input
		const unLinked = selectOptions
			.map((item) => {
				return {
					label: item.label,
					id: item.value
				};
			})
			.filter((suggestion) => suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1);

		setInput(e.target.value);
		setFilteredSuggestions(unLinked);
		setShowSuggestions(true);
	};

	const onClickSuggest = (e) => {
		const text = e || value;
		const findSuggest = selectOptions.find((item) => item.value === text);
		setFilteredSuggestions([]);
		setInput(findSuggest?.label);
		onChange(findSuggest?.value);
		setShowSuggestions(false);
	};

	const SuggestionsListComponent = () => {
		return filteredSuggestions.length ? (
			<ul className="suggestions" id="suggestBox">
				{filteredSuggestions.map((suggestion, index) => {
					//console.log({ suggestion });
					let className;
					// Flag the active suggestion with a class
					if (suggestion.id === value) {
						className = 'suggestion-active';
					}
					return (
						<SuggestOption
							id={'suggestOption_' + index}
							tabIndex={index}
							className={className}
							key={index}
							onClick={(e) => onClickSuggest(suggestion.id, index)}
							onKeyDown={(e) => {
								if (e.key === 'Tab' || e.key === 'ArrowDown') {
									e.preventDefault();
									const el = document.getElementById('suggestOption_' + (index + 1));
									el && el.focus();
								} else if (e.key === 'ArrowUp') {
									e.preventDefault();
									const el = document.getElementById('suggestOption_' + (index - 1));
									el && el.focus();
								} else if (e.key === 'Enter') {
									e.preventDefault();
									onClickSuggest(suggestion.id, index);
								}
							}}
						>
							{suggestion.label}
						</SuggestOption>
					);
				})}
			</ul>
		) : (
			<View rowSt justifyStartSt>
				<Text errorInputSt>No hay resultados</Text>
			</View>
		);
	};

	return (
		<S.InputLabelWrapper style={style}>
			{showLabel && CONFIG.inputs.showLabels ? (
				<View rowSt justifyStartSt>
					{customTextLabel ? (
						<Text labelInputLanguageSt style={styleLabel}>
							{label}
						</Text>
					) : (
						<Text labelInputSt style={styleLabel}>
							{label}
						</Text>
					)}
					{tooltip && tooltip}
				</View>
			) : null}

			{type === 'input' && (
				<S.InputWrapper mountClearButton={mountClearButton}>
					<Input
						disabled={disabled}
						value={value}
						onChange={onChange}
						placeholder={label}
						onBlur={onBlur}
						style={style?.input}
					/>
					{mountClearButton && <ClearButton show={showClearButton} onClick={onClickClearButton} />}
				</S.InputWrapper>
			)}

			{type === 'date' && (
				<S.DateWrapper mountClearButton={mountClearButton}>
					<InputDate
						filledSt={value === null ? false : true}
						disabled={disabled}
						value={value === null ? null : moment(value)}
						onChange={onChange}
						pickOnSelect={false}
						minDate={minDate}
						placeholder={placeholder}
						maxDate={maxDate || moment().subtract(18, 'years')}
						dateIcon={<img src={calendarIcon} alt="" />}
					/>
					<InputS.InputLabel value={value === null ? false : true}>{placeholder}</InputS.InputLabel>
					{mountClearButton && <ClearButton show={showClearButton} onClick={onClickClearButton} />}
				</S.DateWrapper>
			)}

			{type === 'select' && (
				<S.SelectWrapper value={value} mountClearButton={mountClearButton}>
					<Select
						disabled={disabled}
						displayEmpty
						labelId="label"
						id="select"
						value={value}
						onChange={onChange}
						IconComponent={keyboardArrowDown}
						input={<InputS.SelectWrapper value={value} />}
					>
						<MenuItem value={''} disabled>
							<Text inputSelectPlaceholderSt>{label}</Text>
						</MenuItem>

						{selectOptions.map((option, index) => (
							<MenuItem key={index} value={option.value}>
								<Text inputSelectOptionsSt inputSelectedOptionsSt={option.value === value}>
									{option.label}
								</Text>
							</MenuItem>
						))}
					</Select>
					<InputS.InputLabel value={value}>{label}</InputS.InputLabel>
					{mountClearButton && <ClearButton show={showClearButton} onClick={onClickClearButton} />}
				</S.SelectWrapper>
			)}

			{type === 'selectCountry' && (
				<S.SelectWrapper value={value}>
					<Select
						disabled={disabled}
						labelId="label"
						id="select"
						value={value}
						onChange={onChange}
						IconComponent={keyboardArrowDown}
					>
						<MenuItem value={0} disabled fullWidthSt>
							<Text inputSelectOptionsSt>{label}</Text>
						</MenuItem>

						{selectOptions.map((option) => {
							const img = countriesFlags[option?.alpha2];
							return (
								<MenuItem key={option.value} value={option.value} fullWidthSt>
									<img src={img} alt="" />
									<Text marginLeftSmSt inputSelectOptionsSt>
										{option.label}
									</Text>
								</MenuItem>
							);
						})}
					</Select>
				</S.SelectWrapper>
			)}

			{type === 'suffix' && (
				<S.InputLabelWrapperSuffix>
					<Input
						disabled={disabled}
						value={value}
						onChange={onChange}
						placeholder={label}
						onBlur={onBlur}
						name={label}
					/>
					<S.InputLabelSuffix>{suffixText}</S.InputLabelSuffix>
				</S.InputLabelWrapperSuffix>
			)}

			{type === 'autoSuggest' && (
				<>
					<Input
						onKeyDown={(e) => {
							if (e.key === 'Tab') {
								const el = document.getElementById('suggestOption_0');
								if (el) {
									e.preventDefault();
									el.focus();
								}
							}
						}}
						disabled={disabled}
						value={input}
						onChange={onChangeSuggest}
						// onKeyDown={onKeyDown}
						placeholder={label}
					/>
					{showSuggestions && input && <SuggestionsListComponent />}
				</>
			)}

			{type === 'currency' && (
				<S.CurrencyInputWrapper>
					<Input
						disabled={disabled}
						value={value}
						onChange={onChange}
						placeholder={label}
						onBlur={onBlur}
						style={style?.input}
					/>
					{currencyItem}
				</S.CurrencyInputWrapper>
			)}

			{type === 'phone' && (
				<>
					<PhoneInput
						disabled={disabled}
						value={value}
						onChange={onChange}
						placeholder={label}
						defaultCountry="ES"
						international={false}
						onBlur={onBlur}
						inputComponent={InputS.InputPhoneWrapper}
					/>
					<InputS.InputLabel value={value}>{label}</InputS.InputLabel>
				</>
			)}

			{type === 'password' && (
				<S.PasswordInputWrapper>
					<Input
						disabled={disabled}
						type={!passwordVisibility ? 'password' : 'text'}
						value={value}
						onChange={onChange}
						placeholder={label}
						style={{ ...style, marginRight: 20 }}
					/>
					<S.PasswordInputIcon>
						{!passwordVisibility ? (
							<VisibilityIcon
								style={{
									width: 30,
									height: 30,
									cursor: 'pointer'
								}}
								onClick={() => {
									setPasswordVisibility((prevState) => !prevState);
								}}
							/>
						) : (
							<VisibilityOffIcon
								style={{
									width: 30,
									height: 30,
									cursor: 'pointer'
								}}
								onClick={() => {
									setPasswordVisibility((prevState) => !prevState);
								}}
							/>
						)}
					</S.PasswordInputIcon>
				</S.PasswordInputWrapper>
			)}

			{type === 'custom' && children}

			{errorMsg && (
				<Text errorSt smSt leftSt style={{ marginTop: 10 }}>
					{errorMsg}
				</Text>
			)}
		</S.InputLabelWrapper>
	);
};

export default InputLabel;
