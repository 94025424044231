import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('inputImage', {
	styles: {
		default: {
			backgroundSize: 'cover',
			marginBottom: '10px',
			textComponent: {
				fontWeight: 600,
			},
			containerComponent: {
				height: 120,
				width: 200,
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
