import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('textCurrency', {
	styles: {
		default: {
			entireComponent: {
				lineHeight: 1.1,
				color: CONFIG.colors.yellow,
				fontWeight: 'bold',
				fontSize: 22,
			},
			decimalComponent: {
				color: CONFIG.colors.yellow,
				fontWeight: 'bold',
				fontSize: 20,
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
