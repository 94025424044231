import React from 'react';

import { CONFIG } from 'config/config';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import { Text } from '@coinscrap/webapp-core';

import { Modal } from 'components/Modal/Modal';

import * as S from './styles';

export const MainModal = ({ type = 'error', text, children }) => {
	return (
		<Modal>
			<S.ModalWrapper>
				{
					{
						error: (
							<ErrorIcon
								style={{
									marginBottom: 15,
									color: CONFIG.colors.danger,
									height: 45,
									width: 45,
								}}
							/>
						),
						success: (
							<CheckCircleIcon
								style={{
									marginBottom: 15,
									color: CONFIG.colors.primary,
									height: 45,
									width: 45,
								}}
							/>
						),
					}[type]
				}
				{text
					? {
							error: <Text errorSt>{text}</Text>,
							success: <Text headingPrimarySt>{text}</Text>,
					  }[type]
					: children}
			</S.ModalWrapper>
		</Modal>
	);
};
