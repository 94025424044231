import { useEffect, useState } from 'react';

export const useIsLocalDev = () => {
	const [isLocalDev, setIsLocalDev] = useState(false);
	useEffect(() => {
		if (window.location.hostname === 'webapp.localhost') {
			console.log(`💥 LOCAL DEV → ${window.location.hostname}`);
			setIsLocalDev(true);
		}
	}, []);

	return isLocalDev;
};
