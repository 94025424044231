import { isMobile } from 'react-device-detect';

import fonts from './fonts';
import fontSizes from './fontSizes';
import spacing from './spacing';

const commonStyles = {
	styles: {
		// FLEX
		rowSt: {
			flexDirection: 'row',
		},
		colSt: {
			flexDirection: 'column',
		},

		//JUSTIFY
		justifyStartSt: {
			justifyContent: 'flex-start',
		},
		justifyEndSt: {
			justifyContent: 'flex-end',
		},
		justifyEvenlySt: {
			justifyContent: 'space-evenly',
		},
		justifyBetweenSt: {
			justifyContent: 'space-between',
		},
		justifyCenterSt: {
			justifyContent: 'center',
		},

		//JUSTIFY
		justifyItemsStartSt: {
			justifyItems: 'flex-start',
		},
		justifyItemsEndSt: {
			justifyItems: 'flex-end',
		},
		justifyItemsEvenlySt: {
			justifyItems: 'space-evenly',
		},
		justifyItemsBetweenSt: {
			justifyItems: 'space-between',
		},
		justifyItemsCenterSt: {
			justifyItems: 'center',
		},

		//ALIGN
		alignItemsStartSt: {
			alignItems: 'flex-start',
		},
		alignItemsEndSt: {
			alignItems: 'flex-end',
		},
		alignItemsEvenlySt: {
			alignItems: 'space-evenly',
		},
		alignItemsBetweenSt: {
			alignItems: 'space-between',
		},
		alignItemsCenterSt: {
			alignItems: 'center',
		},

		// FONT FAMILY / WEIGHT
		lightSt: {
			fontFamily: fonts.light,
		},
		regularSt: {
			fontFamily: fonts.regular,
		},
		semiBoldSt: {
			fontFamily: fonts.semiBold,
		},
		boldSt: {
			fontFamily: fonts.bold,
		},

		// FONT SIZES
		xsSt: {
			fontSize: fontSizes.xs,
		},
		smSt: {
			fontSize: fontSizes.sm,
		},
		mdSt: {
			fontSize: fontSizes.md,
		},
		lgSt: {
			fontSize: fontSizes.lg,
		},
		xlSt: {
			fontSize: fontSizes.xl,
		},

		// TEXT ALIGN
		leftSt: {
			textAlign: 'left',
		},
		centerSt: {
			textAlign: 'center',
		},
		rightSt: {
			textAlign: 'right',
		},

		//SPACING
		marginSmSt: {
			margin: spacing.sm,
		},

		marginMdSt: {
			margin: spacing.md,
		},

		marginLgSt: {
			margin: spacing.lg,
		},

		marginXlSt: {
			margin: spacing.xl,
		},

		marginTopSmSt: {
			marginTop: spacing.sm,
		},

		marginTopMdSt: {
			marginTop: spacing.md,
		},

		marginTopLgSt: {
			marginTop: spacing.lg,
		},

		marginTopXlSt: {
			marginTop: spacing.xl,
		},

		marginRightSmSt: {
			marginRight: spacing.sm,
		},

		marginRightMdSt: {
			marginRight: spacing.md,
		},

		marginRightLgSt: {
			marginRight: spacing.lg,
		},

		marginRightXlSt: {
			marginRight: spacing.xl,
		},

		marginBottomSmSt: {
			marginBottom: spacing.sm,
		},

		marginBottomMdSt: {
			marginBottom: spacing.md,
		},

		marginBottomLgSt: {
			marginBottom: spacing.lg,
		},

		marginBottomXlSt: {
			marginBottom: spacing.xl,
		},

		marginLeftSmSt: {
			marginLeft: spacing.sm,
		},

		marginLeftMdSt: {
			marginLeft: spacing.md,
		},

		marginLeftLgSt: {
			marginLeft: spacing.lg,
		},

		marginLeftXlSt: {
			marginLeft: spacing.xl,
		},

		paddingSmSt: {
			padding: spacing.sm,
		},

		paddingMdSt: {
			padding: spacing.md,
		},

		paddingLgSt: {
			padding: spacing.lg,
		},

		paddingXlSt: {
			padding: spacing.xl,
		},

		paddingTopSmSt: {
			paddingTop: spacing.sm,
		},

		paddingTopMdSt: {
			paddingTop: spacing.md,
		},

		paddingTopLgSt: {
			paddingTop: spacing.lg,
		},

		paddingTopXlSt: {
			paddingTop: spacing.xl,
		},

		paddingRightSmSt: {
			paddingRight: spacing.sm,
		},

		paddingRightMdSt: {
			paddingRight: spacing.md,
		},

		paddingRightLgSt: {
			paddingRight: spacing.lg,
		},

		paddingRightXlSt: {
			paddingRight: spacing.xl,
		},

		paddingBottomSmSt: {
			paddingBottom: spacing.sm,
		},

		paddingBottomMdSt: {
			paddingBottom: spacing.md,
		},

		paddingBottomLgSt: {
			paddingBottom: spacing.lg,
		},

		paddingBottomXlSt: {
			paddingBottom: spacing.xl,
		},

		paddingLeftSmSt: {
			paddingLeft: spacing.sm,
		},

		paddingLeftMdSt: {
			paddingLeft: spacing.md,
		},

		paddingLeftLgSt: {
			paddingLeft: spacing.lg,
		},

		paddingLeftXlSt: {
			paddingLeft: spacing.xl,
		},

		fullHeightSt: {
			height: '100%',
		},

		fullWidthSt: {
			width: '100%',
		},

		fixedWidthSt: {
			minWidth: 200,
		},

		fullHeightMobileSt: {
			height: isMobile && '100%',
		},

		fullWidthMobileSt: {
			width: isMobile && '100%',
		},

		wInitialSt: {
			width: 'initial',
		},

		growSt: {
			flexGrow: 1,
		},

		// OTHER
		cursorPointerSt: { cursor: 'pointer' },
	},
};
export default commonStyles
