import React from 'react';

import {ReactComponent as KeyBoardDown} from 'assets/images/svgStatic/keyboardArrowDown.svg';

const keyboardArrowDown = ({...rest}) => {
	return (
		<div style={{cursor: 'pointer'}} {...rest}>
			<KeyBoardDown />
		</div>
	);
};
export default keyboardArrowDown;
