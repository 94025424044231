
const ProgressBar = {
	variant: 'static', // text, roundedWithLabel, roundedWithoutLabel, onlyIcon, static (components/HorizontalProgressBar/HorizontalProgressBar.jsx) or false
	steps: [
		'Tus datos personales',
		'Personaliza tu plan',
		'Formulario de idoneidad',
		'Documentos',
		'Firma',
	],
	textSteps: ['Calcula tu precio', 'Contrata tu seguro'],
};

export default ProgressBar;
