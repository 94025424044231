const countriesEuropeList = [
	{
		id: 724,
		alpha2: 'es',
		alpha3: 'esp',
		ar: 'إسبانيا',
		bg: 'Испания',
		cs: 'Španělsko',
		da: 'Spanien',
		de: 'Spanien',
		el: 'Ισπανία',
		en: 'Spain',
		eo: 'Hispanio',
		es: 'España',
		et: 'Hispaania',
		eu: 'Espainia',
		fi: 'Espanja',
		fr: 'Espagne',
		hu: 'Spanyolország',
		it: 'Spagna',
		ja: 'スペイン',
		ko: '스페인',
		lt: 'Ispanija',
		nl: 'Spanje',
		no: 'Spania',
		pl: 'Hiszpania',
		pt: 'Espanha',
		ro: 'Spania',
		ru: 'Испания',
		sk: 'Španielsko',
		sv: 'Spanien',
		th: 'สเปน',
		uk: 'Іспанія',
		zh: '西班牙',
		'zh-tw': '西班牙',
	},
	{
		id: 8,
		alpha2: 'al',
		alpha3: 'alb',
		ar: 'ألبانيا',
		bg: 'Албания',
		cs: 'Albánie',
		da: 'Albanien',
		de: 'Albanien',
		el: 'Αλβανία',
		en: 'Albania',
		eo: 'Albanio',
		es: 'Albania',
		et: 'Albaania',
		eu: 'Albania',
		fi: 'Albania',
		fr: 'Albanie',
		hu: 'Albánia',
		it: 'Albania',
		ja: 'アルバニア',
		ko: '알바니아',
		lt: 'Albanija',
		nl: 'Albanië',
		no: 'Albania',
		pl: 'Albania',
		pt: 'Albânia',
		ro: 'Albania',
		ru: 'Албания',
		sk: 'Albánsko',
		sv: 'Albanien',
		th: 'แอลเบเนีย',
		uk: 'Албанія',
		zh: '阿尔巴尼亚',
		'zh-tw': '阿爾巴尼亞',
	},
	{
		id: 20,
		alpha2: 'ad',
		alpha3: 'and',
		ar: 'أندورا',
		bg: 'Андора',
		cs: 'Andorra',
		da: 'Andorra',
		de: 'Andorra',
		el: 'Ανδόρρα',
		en: 'Andorra',
		eo: 'Andoro',
		es: 'Andorra',
		et: 'Andorra',
		eu: 'Andorra',
		fi: 'Andorra',
		fr: 'Andorre',
		hu: 'Andorra',
		it: 'Andorra',
		ja: 'アンドラ',
		ko: '안도라',
		lt: 'Andora',
		nl: 'Andorra',
		no: 'Andorra',
		pl: 'Andora',
		pt: 'Andorra',
		ro: 'Andorra',
		ru: 'Андорра',
		sk: 'Andorra',
		sv: 'Andorra',
		th: 'อันดอร์รา',
		uk: 'Андорра',
		zh: '安道尔',
		'zh-tw': '安道爾',
	},
	{
		id: 40,
		alpha2: 'at',
		alpha3: 'aut',
		ar: 'النمسا',
		bg: 'Австрия',
		cs: 'Rakousko',
		da: 'Østrig',
		de: 'Österreich',
		el: 'Αυστρία',
		en: 'Austria',
		eo: 'Aŭstrio',
		es: 'Austria',
		et: 'Austria',
		eu: 'Austria',
		fi: 'Itävalta',
		fr: 'Autriche',
		hu: 'Ausztria',
		it: 'Austria',
		ja: 'オーストリア',
		ko: '오스트리아',
		lt: 'Austrija',
		nl: 'Oostenrijk',
		no: 'Østerrike',
		pl: 'Austria',
		pt: 'Áustria',
		ro: 'Austria',
		ru: 'Австрия',
		sk: 'Rakúsko',
		sv: 'Österrike',
		th: 'ออสเตรีย',
		uk: 'Австрія',
		zh: '奥地利',
		'zh-tw': '奧地利',
	},
	{
		id: 112,
		alpha2: 'by',
		alpha3: 'blr',
		ar: 'بيلاروس',
		bg: 'Беларус',
		cs: 'Bělorusko',
		da: 'Hviderusland',
		de: 'Belarus',
		el: 'Λευκορωσία',
		en: 'Belarus',
		eo: 'Belorusio',
		es: 'Bielorrusia',
		et: 'Valgevene',
		eu: 'Bielorrusia',
		fi: 'Valko-Venäjä',
		fr: 'Biélorussie',
		hu: 'Fehéroroszország',
		it: 'Bielorussia',
		ja: 'ベラルーシ',
		ko: '벨라루스',
		lt: 'Baltarusija',
		nl: 'Wit-Rusland',
		no: 'Hviterussland',
		pl: 'Białoruś',
		pt: 'Bielorrússia',
		ro: 'Belarus',
		ru: 'Белоруссия',
		sk: 'Bielorusko',
		sv: 'Belarus',
		th: 'เบลารุส',
		uk: 'Білорусь',
		zh: '白俄罗斯',
		'zh-tw': '白俄羅斯',
	},
	{
		id: 56,
		alpha2: 'be',
		alpha3: 'bel',
		ar: 'بلجيكا',
		bg: 'Белгия',
		cs: 'Belgie',
		da: 'Belgien',
		de: 'Belgien',
		el: 'Βέλγιο',
		en: 'Belgium',
		eo: 'Belgio',
		es: 'Bélgica',
		et: 'Belgia',
		eu: 'Belgika',
		fi: 'Belgia',
		fr: 'Belgique',
		hu: 'Belgium',
		it: 'Belgio',
		ja: 'ベルギー',
		ko: '벨기에',
		lt: 'Belgija',
		nl: 'België',
		no: 'Belgia',
		pl: 'Belgia',
		pt: 'Bélgica',
		ro: 'Belgia',
		ru: 'Бельгия',
		sk: 'Belgicko',
		sv: 'Belgien',
		th: 'เบลเยียม',
		uk: 'Бельгія',
		zh: '比利时',
		'zh-tw': '比利時',
	},
	{
		id: 70,
		alpha2: 'ba',
		alpha3: 'bih',
		ar: 'البوسنة والهرسك',
		bg: 'Босна и Херцеговина',
		cs: 'Bosna a Hercegovina',
		da: 'Bosnien-Hercegovina',
		de: 'Bosnien und Herzegowina',
		el: 'Βοσνία-Ερζεγοβίνη',
		en: 'Bosnia and Herzegovina',
		eo: 'Bosnio kaj Hercegovino',
		es: 'Bosnia y Herzegovina',
		et: 'Bosnia ja Hertsegoviina',
		eu: 'Bosnia-Herzegovina',
		fi: 'Bosnia ja Hertsegovina',
		fr: 'Bosnie-Herzégovine',
		hu: 'Bosznia-Hercegovina',
		it: 'Bosnia ed Erzegovina',
		ja: 'ボスニア・ヘルツェゴビナ',
		ko: '보스니아 헤르체고비나',
		lt: 'Bosnija ir Hercegovina',
		nl: 'Bosnië en Herzegovina',
		no: 'Bosnia-Hercegovina',
		pl: 'Bośnia i Hercegowina',
		pt: 'Bósnia e Herzegovina',
		ro: 'Bosnia și Herțegovina',
		ru: 'Босния и Герцеговина',
		sk: 'Bosna a Hercegovina',
		sv: 'Bosnien och Hercegovina',
		th: 'บอสเนียและเฮอร์เซโกวีนา',
		uk: 'Боснія і Герцеговина',
		zh: '波黑',
		'zh-tw': '波士尼亞與赫塞哥維納',
	},
	{
		id: 100,
		alpha2: 'bg',
		alpha3: 'bgr',
		ar: 'بلغاريا',
		bg: 'България',
		cs: 'Bulharsko',
		da: 'Bulgarien',
		de: 'Bulgarien',
		el: 'Βουλγαρία',
		en: 'Bulgaria',
		eo: 'Bulgario',
		es: 'Bulgaria',
		et: 'Bulgaaria',
		eu: 'Bulgaria',
		fi: 'Bulgaria',
		fr: 'Bulgarie',
		hu: 'Bulgária',
		it: 'Bulgaria',
		ja: 'ブルガリア',
		ko: '불가리아',
		lt: 'Bulgarija',
		nl: 'Bulgarije',
		no: 'Bulgaria',
		pl: 'Bułgaria',
		pt: 'Bulgária',
		ro: 'Bulgaria',
		ru: 'Болгария',
		sk: 'Bulharsko',
		sv: 'Bulgarien',
		th: 'บัลแกเรีย',
		uk: 'Болгарія',
		zh: '保加利亚',
		'zh-tw': '保加利亞',
	},
	{
		id: 191,
		alpha2: 'hr',
		alpha3: 'hrv',
		ar: 'كرواتيا',
		bg: 'Хърватия',
		cs: 'Chorvatsko',
		da: 'Kroatien',
		de: 'Kroatien',
		el: 'Κροατία',
		en: 'Croatia',
		eo: 'Kroatio',
		es: 'Croacia',
		et: 'Horvaatia',
		eu: 'Kroazia',
		fi: 'Kroatia',
		fr: 'Croatie',
		hu: 'Horvátország',
		it: 'Croazia',
		ja: 'クロアチア',
		ko: '크로아티아',
		lt: 'Kroatija',
		nl: 'Kroatië',
		no: 'Kroatia',
		pl: 'Chorwacja',
		pt: 'Croácia',
		ro: 'Croația',
		ru: 'Хорватия',
		sk: 'Chorvátsko',
		sv: 'Kroatien',
		th: 'โครเอเชีย',
		uk: 'Хорватія',
		zh: '克罗地亚',
		'zh-tw': '克羅埃西亞',
	},
	{
		id: 203,
		alpha2: 'cz',
		alpha3: 'cze',
		ar: 'جمهورية التشيك',
		bg: 'Чехия',
		cs: 'Česko',
		da: 'Tjekkiet',
		de: 'Tschechien',
		el: 'Τσεχία',
		en: 'Czechia',
		eo: 'Ĉeĥio',
		es: 'República Checa',
		et: 'Tšehhi',
		eu: 'Txekia',
		fi: 'Tšekki',
		fr: 'Tchéquie',
		hu: 'Csehország',
		it: 'Rep. Ceca',
		ja: 'チェコ',
		ko: '체코',
		lt: 'Čekija',
		nl: 'Tsjechië',
		no: 'Tsjekkia',
		pl: 'Czechy',
		pt: 'Chéquia',
		ro: 'Cehia',
		ru: 'Чехия',
		sk: 'Česko',
		sv: 'Tjeckien',
		th: 'เช็กเกีย',
		uk: 'Чехія',
		zh: '捷克',
		'zh-tw': '捷克',
	},
	{
		id: 208,
		alpha2: 'dk',
		alpha3: 'dnk',
		ar: 'الدنمارك',
		bg: 'Дания',
		cs: 'Dánsko',
		da: 'Danmark',
		de: 'Dänemark',
		el: 'Δανία',
		en: 'Denmark',
		eo: 'Danio',
		es: 'Dinamarca',
		et: 'Taani',
		eu: 'Danimarka',
		fi: 'Tanska',
		fr: 'Danemark',
		hu: 'Dánia',
		it: 'Danimarca',
		ja: 'デンマーク',
		ko: '덴마크',
		lt: 'Danija',
		nl: 'Denemarken',
		no: 'Danmark',
		pl: 'Dania',
		pt: 'Dinamarca',
		ro: 'Danemarca',
		ru: 'Дания',
		sk: 'Dánsko',
		sv: 'Danmark',
		th: 'เดนมาร์ก',
		uk: 'Данія',
		zh: '丹麦',
		'zh-tw': '丹麥',
	},
	{
		id: 233,
		alpha2: 'ee',
		alpha3: 'est',
		ar: 'إستونيا',
		bg: 'Естония',
		cs: 'Estonsko',
		da: 'Estland',
		de: 'Estland',
		el: 'Εσθονία',
		en: 'Estonia',
		eo: 'Estonio',
		es: 'Estonia',
		et: 'Eesti',
		eu: 'Estonia',
		fi: 'Viro',
		fr: 'Estonie',
		hu: 'Észtország',
		it: 'Estonia',
		ja: 'エストニア',
		ko: '에스토니아',
		lt: 'Estija',
		nl: 'Estland',
		no: 'Estland',
		pl: 'Estonia',
		pt: 'Estónia',
		ro: 'Estonia',
		ru: 'Эстония',
		sk: 'Estónsko',
		sv: 'Estland',
		th: 'เอสโตเนีย',
		uk: 'Естонія',
		zh: '爱沙尼亚',
		'zh-tw': '愛沙尼亞',
	},
	{
		id: 246,
		alpha2: 'fi',
		alpha3: 'fin',
		ar: 'فنلندا',
		bg: 'Финландия',
		cs: 'Finsko',
		da: 'Finland',
		de: 'Finnland',
		el: 'Φινλανδία',
		en: 'Finland',
		eo: 'Finnlando',
		es: 'Finlandia',
		et: 'Soome',
		eu: 'Finlandia',
		fi: 'Suomi',
		fr: 'Finlande',
		hu: 'Finnország',
		it: 'Finlandia',
		ja: 'フィンランド',
		ko: '핀란드',
		lt: 'Suomija',
		nl: 'Finland',
		no: 'Finland',
		pl: 'Finlandia',
		pt: 'Finlândia',
		ro: 'Finlanda',
		ru: 'Финляндия',
		sk: 'Fínsko',
		sv: 'Finland',
		th: 'ฟินแลนด์',
		uk: 'Фінляндія',
		zh: '芬兰',
		'zh-tw': '芬蘭',
	},
	{
		id: 250,
		alpha2: 'fr',
		alpha3: 'fra',
		ar: 'فرنسا',
		bg: 'Франция',
		cs: 'Francie',
		da: 'Frankrig',
		de: 'Frankreich',
		el: 'Γαλλία',
		en: 'France',
		eo: 'Francio',
		es: 'Francia',
		et: 'Prantsusmaa',
		eu: 'Frantzia',
		fi: 'Ranska',
		fr: 'France',
		hu: 'Franciaország',
		it: 'Francia',
		ja: 'フランス',
		ko: '프랑스',
		lt: 'Prancūzija',
		nl: 'Frankrijk',
		no: 'Frankrike',
		pl: 'Francja',
		pt: 'França',
		ro: 'Franța',
		ru: 'Франция',
		sk: 'Francúzsko',
		sv: 'Frankrike',
		th: 'ฝรั่งเศส',
		uk: 'Франція',
		zh: '法国',
		'zh-tw': '法國',
	},
	{
		id: 276,
		alpha2: 'de',
		alpha3: 'deu',
		ar: 'ألمانيا',
		bg: 'Германия',
		cs: 'Německo',
		da: 'Tyskland',
		de: 'Deutschland',
		el: 'Γερμανία',
		en: 'Germany',
		eo: 'Germanio',
		es: 'Alemania',
		et: 'Saksamaa',
		eu: 'Alemania',
		fi: 'Saksa',
		fr: 'Allemagne',
		hu: 'Németország',
		it: 'Germania',
		ja: 'ドイツ',
		ko: '독일',
		lt: 'Vokietija',
		nl: 'Duitsland',
		no: 'Tyskland',
		pl: 'Niemcy',
		pt: 'Alemanha',
		ro: 'Germania',
		ru: 'Германия',
		sk: 'Nemecko',
		sv: 'Tyskland',
		th: 'เยอรมนี',
		uk: 'Німеччина',
		zh: '德国',
		'zh-tw': '德國',
	},
	{
		id: 292,
		alpha2: 'gi',
		alpha3: 'gib',
		ar: 'جبل طارق',
		bg: 'Гибралтар',
		cs: 'Gibraltar',
		da: 'Gibraltar',
		de: 'Gibraltar',
		el: 'Γιβραλτάρ',
		en: 'Gibraltar',
		eo: 'Ĝibraltaro',
		es: 'Gibraltar',
		et: 'Gibraltar',
		eu: 'Gibraltar',
		fi: 'Gibraltar',
		fr: 'Gibraltar',
		hu: 'Gibraltár',
		it: 'Gibilterra',
		ja: 'ジブラルタル',
		ko: '지브롤터',
		lt: 'Gibraltaras',
		nl: 'Gibraltar',
		no: 'Gibraltar',
		pl: 'Gibraltar',
		pt: 'Gibraltar',
		ro: 'Gibraltar',
		ru: 'Гибралтар',
		sk: 'Gibraltár',
		sv: 'Gibraltar',
		th: 'ยิบรอลตาร์',
		uk: 'Гібралтар',
		zh: '直布罗陀',
		'zh-tw': '直布羅陀',
	},
	{
		id: 300,
		alpha2: 'gr',
		alpha3: 'grc',
		ar: 'اليونان',
		bg: 'Гърция',
		cs: 'Řecko',
		da: 'Grækenland',
		de: 'Griechenland',
		el: 'Ελλάδα',
		en: 'Greece',
		eo: 'Grekio',
		es: 'Grecia',
		et: 'Kreeka',
		eu: 'Grezia',
		fi: 'Kreikka',
		fr: 'Grèce',
		hu: 'Görögország',
		it: 'Grecia',
		ja: 'ギリシャ',
		ko: '그리스',
		lt: 'Graikija',
		nl: 'Griekenland',
		no: 'Hellas',
		pl: 'Grecja',
		pt: 'Grécia',
		ro: 'Grecia',
		ru: 'Греция',
		sk: 'Grécko',
		sv: 'Grekland',
		th: 'กรีซ',
		uk: 'Греція',
		zh: '希腊',
		'zh-tw': '希臘',
	},
	{
		id: 348,
		alpha2: 'hu',
		alpha3: 'hun',
		ar: 'المجر',
		bg: 'Унгария',
		cs: 'Maďarsko',
		da: 'Ungarn',
		de: 'Ungarn',
		el: 'Ουγγαρία',
		en: 'Hungary',
		eo: 'Hungario',
		es: 'Hungría',
		et: 'Ungari',
		eu: 'Hungaria',
		fi: 'Unkari',
		fr: 'Hongrie',
		hu: 'Magyarország',
		it: 'Ungheria',
		ja: 'ハンガリー',
		ko: '헝가리',
		lt: 'Vengrija',
		nl: 'Hongarije',
		no: 'Ungarn',
		pl: 'Węgry',
		pt: 'Hungria',
		ro: 'Ungaria',
		ru: 'Венгрия',
		sk: 'Maďarsko',
		sv: 'Ungern',
		th: 'ฮังการี',
		uk: 'Угорщина',
		zh: '匈牙利',
		'zh-tw': '匈牙利',
	},
	{
		id: 352,
		alpha2: 'is',
		alpha3: 'isl',
		ar: 'آيسلندا',
		bg: 'Исландия',
		cs: 'Island',
		da: 'Island',
		de: 'Island',
		el: 'Ισλανδία',
		en: 'Iceland',
		eo: 'Islando',
		es: 'Islandia',
		et: 'Island',
		eu: 'Islandia',
		fi: 'Islanti',
		fr: 'Islande',
		hu: 'Izland',
		it: 'Islanda',
		ja: 'アイスランド',
		ko: '아이슬란드',
		lt: 'Islandija',
		nl: 'IJsland',
		no: 'Island',
		pl: 'Islandia',
		pt: 'Islândia',
		ro: 'Islanda',
		ru: 'Исландия',
		sk: 'Island',
		sv: 'Island',
		th: 'ไอซ์แลนด์',
		uk: 'Ісландія',
		zh: '冰岛',
		'zh-tw': '冰島',
	},
	{
		id: 372,
		alpha2: 'ie',
		alpha3: 'irl',
		ar: 'أيرلندا',
		bg: 'Ирландия',
		cs: 'Irsko',
		da: 'Irland',
		de: 'Irland',
		el: 'Ιρλανδία',
		en: 'Ireland',
		eo: 'Irlando',
		es: 'Irlanda',
		et: 'Iirimaa',
		eu: 'Irlandako Errepublika',
		fi: 'Irlanti',
		fr: 'Irlande',
		hu: 'Írország',
		it: 'Irlanda',
		ja: 'アイルランド',
		ko: '아일랜드',
		lt: 'Airija',
		nl: 'Ierland',
		no: 'Irland',
		pl: 'Irlandia',
		pt: 'Irlanda',
		ro: 'Republica Irlanda',
		ru: 'Ирландия',
		sk: 'Írsko',
		sv: 'Irland',
		th: 'ไอร์แลนด์',
		uk: 'Ірландія',
		zh: '爱尔兰',
		'zh-tw': '愛爾蘭',
	},
	{
		id: 380,
		alpha2: 'it',
		alpha3: 'ita',
		ar: 'إيطاليا',
		bg: 'Италия',
		cs: 'Itálie',
		da: 'Italien',
		de: 'Italien',
		el: 'Ιταλία',
		en: 'Italy',
		eo: 'Italio',
		es: 'Italia',
		et: 'Itaalia',
		eu: 'Italia',
		fi: 'Italia',
		fr: 'Italie',
		hu: 'Olaszország',
		it: 'Italia',
		ja: 'イタリア',
		ko: '이탈리아',
		lt: 'Italija',
		nl: 'Italië',
		no: 'Italia',
		pl: 'Włochy',
		pt: 'Itália',
		ro: 'Italia',
		ru: 'Италия',
		sk: 'Taliansko',
		sv: 'Italien',
		th: 'อิตาลี',
		uk: 'Італія',
		zh: '意大利',
		'zh-tw': '義大利',
	},
	{
		id: 428,
		alpha2: 'lv',
		alpha3: 'lva',
		ar: 'لاتفيا',
		bg: 'Латвия',
		cs: 'Lotyšsko',
		da: 'Letland',
		de: 'Lettland',
		el: 'Λεττονία',
		en: 'Latvia',
		eo: 'Latvio',
		es: 'Letonia',
		et: 'Läti',
		eu: 'Letonia',
		fi: 'Latvia',
		fr: 'Lettonie',
		hu: 'Lettország',
		it: 'Lettonia',
		ja: 'ラトビア',
		ko: '라트비아',
		lt: 'Latvija',
		nl: 'Letland',
		no: 'Latvia',
		pl: 'Łotwa',
		pt: 'Letônia',
		ro: 'Letonia',
		ru: 'Латвия',
		sk: 'Lotyšsko',
		sv: 'Lettland',
		th: 'ลัตเวีย',
		uk: 'Латвія',
		zh: '拉脱维亚',
		'zh-tw': '拉脫維亞',
	},
	{
		id: 438,
		alpha2: 'li',
		alpha3: 'lie',
		ar: 'ليختنشتاين',
		bg: 'Лихтенщайн',
		cs: 'Lichtenštejnsko',
		da: 'Liechtenstein',
		de: 'Liechtenstein',
		el: 'Λίχτενσταϊν',
		en: 'Liechtenstein',
		eo: 'Liĥtenŝtejno',
		es: 'Liechtenstein',
		et: 'Liechtenstein',
		eu: 'Liechtenstein',
		fi: 'Liechtenstein',
		fr: 'Liechtenstein',
		hu: 'Liechtenstein',
		it: 'Liechtenstein',
		ja: 'リヒテンシュタイン',
		ko: '리히텐슈타인',
		lt: 'Lichtenšteinas',
		nl: 'Liechtenstein',
		no: 'Liechtenstein',
		pl: 'Liechtenstein',
		pt: 'Listenstaine',
		ro: 'Liechtenstein',
		ru: 'Лихтенштейн',
		sk: 'Lichtenštajnsko',
		sv: 'Liechtenstein',
		th: 'ลิกเตนสไตน์',
		uk: 'Ліхтенштейн',
		zh: '列支敦士登',
		'zh-tw': '列支敦斯登',
	},
	{
		id: 440,
		alpha2: 'lt',
		alpha3: 'ltu',
		ar: 'ليتوانيا',
		bg: 'Литва',
		cs: 'Litva',
		da: 'Litauen',
		de: 'Litauen',
		el: 'Λιθουανία',
		en: 'Lithuania',
		eo: 'Litovio',
		es: 'Lituania',
		et: 'Leedu',
		eu: 'Lituania',
		fi: 'Liettua',
		fr: 'Lituanie',
		hu: 'Litvánia',
		it: 'Lituania',
		ja: 'リトアニア',
		ko: '리투아니아',
		lt: 'Lietuva',
		nl: 'Litouwen',
		no: 'Litauen',
		pl: 'Litwa',
		pt: 'Lituânia',
		ro: 'Lituania',
		ru: 'Литва',
		sk: 'Litva',
		sv: 'Litauen',
		th: 'ลิทัวเนีย',
		uk: 'Литва',
		zh: '立陶宛',
		'zh-tw': '立陶宛',
	},
	{
		id: 442,
		alpha2: 'lu',
		alpha3: 'lux',
		ar: 'لوكسمبورغ',
		bg: 'Люксембург',
		cs: 'Lucembursko',
		da: 'Luxembourg',
		de: 'Luxemburg',
		el: 'Λουξεμβούργο',
		en: 'Luxembourg',
		eo: 'Luksemburgo',
		es: 'Luxemburgo',
		et: 'Luksemburg',
		eu: 'Luxenburgo',
		fi: 'Luxemburg',
		fr: 'Luxembourg',
		hu: 'Luxemburg',
		it: 'Lussemburgo',
		ja: 'ルクセンブルク',
		ko: '룩셈부르크',
		lt: 'Liuksemburgas',
		nl: 'Luxemburg',
		no: 'Luxembourg',
		pl: 'Luksemburg',
		pt: 'Luxemburgo',
		ro: 'Luxemburg',
		ru: 'Люксембург',
		sk: 'Luxembursko',
		sv: 'Luxemburg',
		th: 'ลักเซมเบิร์ก',
		uk: 'Люксембург',
		zh: '卢森堡',
		'zh-tw': '盧森堡',
	},
	{
		id: 470,
		alpha2: 'mt',
		alpha3: 'mlt',
		ar: 'مالطا',
		bg: 'Малта',
		cs: 'Malta',
		da: 'Malta',
		de: 'Malta',
		el: 'Μάλτα',
		en: 'Malta',
		eo: 'Malto',
		es: 'Malta',
		et: 'Malta',
		eu: 'Malta',
		fi: 'Malta',
		fr: 'Malte',
		hu: 'Málta',
		it: 'Malta',
		ja: 'マルタ',
		ko: '몰타',
		lt: 'Malta',
		nl: 'Malta',
		no: 'Malta',
		pl: 'Malta',
		pt: 'Malta',
		ro: 'Malta',
		ru: 'Мальта',
		sk: 'Malta',
		sv: 'Malta',
		th: 'มอลตา',
		uk: 'Мальта',
		zh: '马耳他',
		'zh-tw': '馬爾他',
	},
	{
		id: 498,
		alpha2: 'md',
		alpha3: 'mda',
		ar: 'مولدوفا',
		bg: 'Молдова',
		cs: 'Moldavsko',
		da: 'Moldova',
		de: 'Moldau',
		el: 'Μολδαβία',
		en: 'Moldova, Republic of',
		eo: 'Moldavio',
		es: 'Moldavia',
		et: 'Moldova',
		eu: 'Moldavia',
		fi: 'Moldova',
		fr: 'Moldavie',
		hu: 'Moldova',
		it: 'Moldavia',
		ja: 'モルドバ共和国',
		ko: '몰도바',
		lt: 'Moldavija',
		nl: 'Moldavië',
		no: 'Moldova',
		pl: 'Mołdawia',
		pt: 'Moldávia',
		ro: 'Republica Moldova',
		ru: 'Молдавия',
		sk: 'Moldavsko',
		sv: 'Moldavien',
		th: 'มอลโดวา',
		uk: 'Молдова',
		zh: '摩尔多瓦',
		'zh-tw': '摩爾多瓦',
	},
	{
		id: 492,
		alpha2: 'mc',
		alpha3: 'mco',
		ar: 'موناكو',
		bg: 'Монако',
		cs: 'Monako',
		da: 'Monaco',
		de: 'Monaco',
		el: 'Μονακό',
		en: 'Monaco',
		eo: 'Monako',
		es: 'Mónaco',
		et: 'Monaco',
		eu: 'Monako',
		fi: 'Monaco',
		fr: 'Monaco',
		hu: 'Monaco',
		it: 'Monaco',
		ja: 'モナコ',
		ko: '모나코',
		lt: 'Monakas',
		nl: 'Monaco',
		no: 'Monaco',
		pl: 'Monako',
		pt: 'Mónaco',
		ro: 'Monaco',
		ru: 'Монако',
		sk: 'Monako',
		sv: 'Monaco',
		th: 'โมนาโก',
		uk: 'Монако',
		zh: '摩纳哥',
		'zh-tw': '摩納哥',
	},
	{
		id: 499,
		alpha2: 'me',
		alpha3: 'mne',
		ar: 'الجبل الأسود',
		bg: 'Черна гора',
		cs: 'Černá Hora',
		da: 'Montenegro',
		de: 'Montenegro',
		el: 'Μαυροβούνιο',
		en: 'Montenegro',
		eo: 'Montenegro',
		es: 'Montenegro',
		et: 'Montenegro',
		eu: 'Montenegro',
		fi: 'Montenegro',
		fr: 'Monténégro',
		hu: 'Montenegró',
		it: 'Montenegro',
		ja: 'モンテネグロ',
		ko: '몬테네그로',
		lt: 'Juodkalnija',
		nl: 'Montenegro',
		no: 'Montenegro',
		pl: 'Czarnogóra',
		pt: 'Montenegro',
		ro: 'Muntenegru',
		ru: 'Черногория',
		sk: 'Čierna Hora',
		sv: 'Montenegro',
		th: 'มอนเตเนโกร',
		uk: 'Чорногорія',
		zh: '黑山',
		'zh-tw': '蒙特內哥羅',
	},
	{
		id: 528,
		alpha2: 'nl',
		alpha3: 'nld',
		ar: 'هولندا',
		bg: 'Нидерландия',
		cs: 'Nizozemsko',
		da: 'Holland',
		de: 'Niederlande',
		el: 'Ολλανδία (Κάτω Χώρες)',
		en: 'Netherlands',
		eo: 'Nederlando',
		es: 'Países Bajos',
		et: 'Holland',
		eu: 'Herbehereak',
		fi: 'Alankomaat',
		fr: 'Pays-Bas',
		hu: 'Hollandia',
		it: 'Paesi Bassi',
		ja: 'オランダ',
		ko: '네덜란드',
		lt: 'Nyderlandai',
		nl: 'Nederland',
		no: 'Nederland',
		pl: 'Holandia',
		pt: 'Países Baixos',
		ro: 'Țările de Jos',
		ru: 'Нидерланды',
		sk: 'Holandsko',
		sv: 'Nederländerna',
		th: 'เนเธอร์แลนด์',
		uk: 'Нідерланди',
		zh: '荷兰',
		'zh-tw': '荷蘭',
	},
	{
		id: 807,
		alpha2: 'mk',
		alpha3: 'mkd',
		ar: 'مقدونيا',
		bg: 'Северна Македония',
		cs: 'Severní Makedonie',
		da: 'Nordmakedonien',
		de: 'Nordmazedonien',
		el: 'Βόρεια Μακεδονία',
		en: 'North Macedonia',
		eo: 'Nord-Makedonio',
		es: 'Macedonia del Norte',
		et: 'Põhja-Makedoonia',
		eu: 'Ipar Mazedonia',
		fi: 'Pohjois-Makedonia',
		fr: 'Macédoine du Nord',
		hu: 'Észak-Macedónia',
		it: 'Macedonia del Nord',
		ja: '北マケドニア',
		ko: '북마케도니아',
		lt: 'Makedonija',
		nl: 'Noord-Macedonië',
		no: 'Makedonia, Republikken',
		pl: 'Macedonia Północna',
		pt: 'Macedônia do Norte',
		ro: 'Republica Macedonia',
		ru: 'Северная Македония',
		sk: 'Severné Macedónsko',
		sv: 'Nordmakedonien',
		th: 'นอร์ทมาซิโดเนีย',
		uk: 'Північна Македонія',
		zh: '北马其顿',
		'zh-tw': '北馬其頓',
	},
	{
		id: 578,
		alpha2: 'no',
		alpha3: 'nor',
		ar: 'النرويج',
		bg: 'Норвегия',
		cs: 'Norsko',
		da: 'Norge',
		de: 'Norwegen',
		el: 'Νορβηγία',
		en: 'Norway',
		eo: 'Norvegio',
		es: 'Noruega',
		et: 'Norra',
		eu: 'Norvegia',
		fi: 'Norja',
		fr: 'Norvège',
		hu: 'Norvégia',
		it: 'Norvegia',
		ja: 'ノルウェー',
		ko: '노르웨이',
		lt: 'Norvegija',
		nl: 'Noorwegen',
		no: 'Norge',
		pl: 'Norwegia',
		pt: 'Noruega',
		ro: 'Norvegia',
		ru: 'Норвегия',
		sk: 'Nórsko',
		sv: 'Norge',
		th: 'นอร์เวย์',
		uk: 'Норвегія',
		zh: '挪威',
		'zh-tw': '挪威',
	},
	{
		id: 616,
		alpha2: 'pl',
		alpha3: 'pol',
		ar: 'بولندا',
		bg: 'Полша',
		cs: 'Polsko',
		da: 'Polen',
		de: 'Polen',
		el: 'Πολωνία',
		en: 'Poland',
		eo: 'Pollando',
		es: 'Polonia',
		et: 'Poola',
		eu: 'Polonia',
		fi: 'Puola',
		fr: 'Pologne',
		hu: 'Lengyelország',
		it: 'Polonia',
		ja: 'ポーランド',
		ko: '폴란드',
		lt: 'Lenkija',
		nl: 'Polen',
		no: 'Polen',
		pl: 'Polska',
		pt: 'Polónia',
		ro: 'Polonia',
		ru: 'Польша',
		sk: 'Poľsko',
		sv: 'Polen',
		th: 'โปแลนด์',
		uk: 'Польща',
		zh: '波兰',
		'zh-tw': '波蘭',
	},
	{
		id: 620,
		alpha2: 'pt',
		alpha3: 'prt',
		ar: 'البرتغال',
		bg: 'Португалия',
		cs: 'Portugalsko',
		da: 'Portugal',
		de: 'Portugal',
		el: 'Πορτογαλία',
		en: 'Portugal',
		eo: 'Portugalio',
		es: 'Portugal',
		et: 'Portugal',
		eu: 'Portugal',
		fi: 'Portugali',
		fr: 'Portugal',
		hu: 'Portugália',
		it: 'Portogallo',
		ja: 'ポルトガル',
		ko: '포르투갈',
		lt: 'Portugalija',
		nl: 'Portugal',
		no: 'Portugal',
		pl: 'Portugalia',
		pt: 'Portugal',
		ro: 'Portugalia',
		ru: 'Португалия',
		sk: 'Portugalsko',
		sv: 'Portugal',
		th: 'โปรตุเกส',
		uk: 'Португалія',
		zh: '葡萄牙',
		'zh-tw': '葡萄牙',
	},
	{
		id: 642,
		alpha2: 'ro',
		alpha3: 'rou',
		ar: 'رومانيا',
		bg: 'Румъния',
		cs: 'Rumunsko',
		da: 'Rumænien',
		de: 'Rumänien',
		el: 'Ρουμανία',
		en: 'Romania',
		eo: 'Rumanio',
		es: 'Rumania',
		et: 'Rumeenia',
		eu: 'Errumania',
		fi: 'Romania',
		fr: 'Roumanie',
		hu: 'Románia',
		it: 'Romania',
		ja: 'ルーマニア',
		ko: '루마니아',
		lt: 'Rumunija',
		nl: 'Roemenië',
		no: 'Romania',
		pl: 'Rumunia',
		pt: 'Roménia',
		ro: 'România',
		ru: 'Румыния',
		sk: 'Rumunsko',
		sv: 'Rumänien',
		th: 'โรมาเนีย',
		uk: 'Румунія',
		zh: '罗马尼亚',
		'zh-tw': '羅馬尼亞',
	},
	{
		id: 643,
		alpha2: 'ru',
		alpha3: 'rus',
		ar: 'روسيا',
		bg: 'Русия',
		cs: 'Rusko',
		da: 'Rusland',
		de: 'Russland',
		el: 'Ρωσία',
		en: 'Russian Federation',
		eo: 'Rusio',
		es: 'Rusia',
		et: 'Venemaa',
		eu: 'Errusia',
		fi: 'Venäjä',
		fr: 'Russie',
		hu: 'Oroszország',
		it: 'Russia',
		ja: 'ロシア連邦',
		ko: '러시아',
		lt: 'Rusija',
		nl: 'Rusland',
		no: 'Russland',
		pl: 'Rosja',
		pt: 'Rússia',
		ro: 'Rusia',
		ru: 'Россия',
		sk: 'Rusko',
		sv: 'Ryssland',
		th: 'รัสเซีย',
		uk: 'Росія',
		zh: '俄罗斯',
		'zh-tw': '俄羅斯',
	},
	{
		id: 674,
		alpha2: 'sm',
		alpha3: 'smr',
		ar: 'سان مارينو',
		bg: 'Сан Марино',
		cs: 'San Marino',
		da: 'San Marino',
		de: 'San Marino',
		el: 'Άγιος Μαρίνος',
		en: 'San Marino',
		eo: 'San-Marino',
		es: 'San Marino',
		et: 'San Marino',
		eu: 'San Marino',
		fi: 'San Marino',
		fr: 'Saint-Marin',
		hu: 'San Marino',
		it: 'San Marino',
		ja: 'サンマリノ',
		ko: '산마리노',
		lt: 'San Marinas',
		nl: 'San Marino',
		no: 'San Marino',
		pl: 'San Marino',
		pt: 'San Marino',
		ro: 'San Marino',
		ru: 'Сан-Марино',
		sk: 'San Maríno',
		sv: 'San Marino',
		th: 'ซานมารีโน',
		uk: 'Сан-Марино',
		zh: '圣马力诺',
		'zh-tw': '聖馬利諾',
	},
	{
		id: 688,
		alpha2: 'rs',
		alpha3: 'srb',
		ar: 'صربيا',
		bg: 'Сърбия',
		cs: 'Srbsko',
		da: 'Serbien',
		de: 'Serbien',
		el: 'Σερβία',
		en: 'Serbia',
		eo: 'Serbio',
		es: 'Serbia',
		et: 'Serbia',
		eu: 'Serbia',
		fi: 'Serbia',
		fr: 'Serbie',
		hu: 'Szerbia',
		it: 'Serbia',
		ja: 'セルビア',
		ko: '세르비아',
		lt: 'Serbija',
		nl: 'Servië',
		no: 'Serbia',
		pl: 'Serbia',
		pt: 'Sérvia',
		ro: 'Serbia',
		ru: 'Сербия',
		sk: 'Srbsko',
		sv: 'Serbien',
		th: 'เซอร์เบีย',
		uk: 'Сербія',
		zh: '塞尔维亚',
		'zh-tw': '塞爾維亞',
	},
	{
		id: 703,
		alpha2: 'sk',
		alpha3: 'svk',
		ar: 'سلوفاكيا',
		bg: 'Словакия',
		cs: 'Slovensko',
		da: 'Slovakiet',
		de: 'Slowakei',
		el: 'Σλοβακία',
		en: 'Slovakia',
		eo: 'Slovakio',
		es: 'Eslovaquia',
		et: 'Slovakkia',
		eu: 'Eslovakia',
		fi: 'Slovakia',
		fr: 'Slovaquie',
		hu: 'Szlovákia',
		it: 'Slovacchia',
		ja: 'スロバキア',
		ko: '슬로바키아',
		lt: 'Slovakija',
		nl: 'Slowakije',
		no: 'Slovakia',
		pl: 'Słowacja',
		pt: 'Eslováquia',
		ro: 'Slovacia',
		ru: 'Словакия',
		sk: 'Slovensko',
		sv: 'Slovakien',
		th: 'สโลวาเกีย',
		uk: 'Словаччина',
		zh: '斯洛伐克',
		'zh-tw': '斯洛伐克',
	},
	{
		id: 705,
		alpha2: 'si',
		alpha3: 'svn',
		ar: 'سلوفينيا',
		bg: 'Словения',
		cs: 'Slovinsko',
		da: 'Slovenien',
		de: 'Slowenien',
		el: 'Σλοβενία',
		en: 'Slovenia',
		eo: 'Slovenio',
		es: 'Eslovenia',
		et: 'Sloveenia',
		eu: 'Eslovenia',
		fi: 'Slovenia',
		fr: 'Slovénie',
		hu: 'Szlovénia',
		it: 'Slovenia',
		ja: 'スロベニア',
		ko: '슬로베니아',
		lt: 'Slovėnija',
		nl: 'Slovenië',
		no: 'Slovenia',
		pl: 'Słowenia',
		pt: 'Eslovênia',
		ro: 'Slovenia',
		ru: 'Словения',
		sk: 'Slovinsko',
		sv: 'Slovenien',
		th: 'สโลวีเนีย',
		uk: 'Словенія',
		zh: '斯洛文尼亚',
		'zh-tw': '斯洛維尼亞',
	},
	{
		id: 752,
		alpha2: 'se',
		alpha3: 'swe',
		ar: 'السويد',
		bg: 'Швеция',
		cs: 'Švédsko',
		da: 'Sverige',
		de: 'Schweden',
		el: 'Σουηδία',
		en: 'Sweden',
		eo: 'Svedio',
		es: 'Suecia',
		et: 'Rootsi',
		eu: 'Suedia',
		fi: 'Ruotsi',
		fr: 'Suède',
		hu: 'Svédország',
		it: 'Svezia',
		ja: 'スウェーデン',
		ko: '스웨덴',
		lt: 'Švedija',
		nl: 'Zweden',
		no: 'Sverige',
		pl: 'Szwecja',
		pt: 'Suécia',
		ro: 'Suedia',
		ru: 'Швеция',
		sk: 'Švédsko',
		sv: 'Sverige',
		th: 'สวีเดน',
		uk: 'Швеція',
		zh: '瑞典',
		'zh-tw': '瑞典',
	},
	{
		id: 756,
		alpha2: 'ch',
		alpha3: 'che',
		ar: 'سويسرا',
		bg: 'Швейцария',
		cs: 'Švýcarsko',
		da: 'Schweiz',
		de: 'Schweiz',
		el: 'Ελβετία',
		en: 'Switzerland',
		eo: 'Svislando',
		es: 'Suiza',
		et: 'Šveits',
		eu: 'Suitza',
		fi: 'Sveitsi',
		fr: 'Suisse',
		hu: 'Svájc',
		it: 'Svizzera',
		ja: 'スイス',
		ko: '스위스',
		lt: 'Šveicarija',
		nl: 'Zwitserland',
		no: 'Sveits',
		pl: 'Szwajcaria',
		pt: 'Suíça',
		ro: 'Elveția',
		ru: 'Швейцария',
		sk: 'Švajčiarsko',
		sv: 'Schweiz',
		th: 'สวิตเซอร์แลนด์',
		uk: 'Швейцарія',
		zh: '瑞士',
		'zh-tw': '瑞士',
	},
	{
		id: 804,
		alpha2: 'ua',
		alpha3: 'ukr',
		ar: 'أوكرانيا',
		bg: 'Украйна',
		cs: 'Ukrajina',
		da: 'Ukraine',
		de: 'Ukraine',
		el: 'Ουκρανία',
		en: 'Ukraine',
		eo: 'Ukrainio',
		es: 'Ucrania',
		et: 'Ukraina',
		eu: 'Ukraina',
		fi: 'Ukraina',
		fr: 'Ukraine',
		hu: 'Ukrajna',
		it: 'Ucraina',
		ja: 'ウクライナ',
		ko: '우크라이나',
		lt: 'Ukraina',
		nl: 'Oekraïne',
		no: 'Ukraina',
		pl: 'Ukraina',
		pt: 'Ucrânia',
		ro: 'Ucraina',
		ru: 'Украина',
		sk: 'Ukrajina',
		sv: 'Ukraina',
		th: 'ยูเครน',
		uk: 'Україна',
		zh: '乌克兰',
		'zh-tw': '烏克蘭',
	},
	{
		id: 826,
		alpha2: 'gb',
		alpha3: 'gbr',
		ar: 'المملكة المتحدة',
		bg: 'Великобритания',
		cs: 'Spojené království Velké Británie a Severního Irska',
		da: 'Storbritannien',
		de: 'Vereinigtes Königreich',
		el: 'Ηνωμένο Βασίλειο',
		en: 'United Kingdom of Great Britain and Northern Ireland',
		eo: 'Britio',
		es: 'Reino Unido',
		et: 'Suurbritannia',
		eu: 'Erresuma Batua',
		fi: 'Yhdistynyt kuningaskunta',
		fr: 'Royaume-Uni',
		hu: 'Egyesült Királyság',
		it: 'Regno Unito',
		ja: 'イギリス',
		ko: '영국',
		lt: 'Jungtinė Karalystė',
		nl: 'Verenigd Koninkrijk',
		no: 'Storbritannia',
		pl: 'Wielka Brytania',
		pt: 'Reino Unido',
		ro: 'Regatul Unit',
		ru: 'Великобритания',
		sk: 'Spojené kráľovstvo',
		sv: 'Storbritannien',
		th: 'สหราชอาณาจักร',
		uk: 'Велика Британія',
		zh: '英国',
		'zh-tw': '英國',
	},
];

export const countriesEuropeES = countriesEuropeList.map((x) => ({
	id: x.id,
	alpha2: x.alpha2,
	alpha3: x.alpha3,
	label: x.es,
	value: x.alpha3,
}));

export const countriesEuropeESTypes = countriesEuropeES.reduce((acc, item) => {
	acc[item.alpha3] = item.value;
	return acc;
}, {});

export const countriesEuropeRU = countriesEuropeList.map((x) => ({
	id: x.id,
	alpha2: x.alpha2,
	alpha3: x.alpha3,
	label: x.ru,
	value: x.alpha3,
}));

export const countriesEuropeRUTypes = countriesEuropeRU.reduce((acc, item) => {
	acc[item.alpha3] = item.value;
	return acc;
}, {});
