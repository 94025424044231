import React from 'react';

import {Text, View} from '@coinscrap/webapp-core';

import {OptionButton} from 'components/OptionButton/OptionButton';
import TooltipSimple from 'components/TooltipSimple/TooltipSimple';

import TextSpan from '../../../components/TextSpan/TextSpan';

const QuestionnaireBox = ({text, tooltipText, onChange, value = null, listWithTooltips = false}) => {
	return (
		<View questionnariesSt>
			<View wrapperQuestionnariesSt>
				<View wrapperTextQuestionnariesSt>
					<Text headingTertiarySt style={{textAlign: 'left'}}>
						{text}
						{tooltipText && (
							<TextSpan style={{marginLeft: 10}}>
								<TooltipSimple>
									<Text tooltipTextSt>{tooltipText}</Text>
								</TooltipSimple>
							</TextSpan>
						)}
					</Text>
				</View>
			</View>
			<View wrapperButtonsQuestionnariesSt>
				<OptionButton
					onClick={() => onChange(true)}
					selected={value === true}
					text={'Sí'}
					width={'50px'}
					pill={false}
				/>
				<OptionButton
					onClick={() => onChange(false)}
					selected={value === false}
					sugested={value === null}
					text={'No'}
					width={'50px'}
					pill={false}
				/>
			</View>
		</View>
	);
};

export default QuestionnaireBox;
