import {
  IdentityDocumentsWrapper,
  NavigationWrapper,
  PickerHOC,
  SoftSwitcher,
  TargetsWrapper,
  TestResponsesWrapper,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { CONFIG } from 'config/config';
import getRedirectRoute from 'helpers/redirectRoute';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export const LoadTarget = PickerHOC(
	{
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		},
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets'
		},
		testCompletions: {
			sourceWrapper: TestResponsesWrapper,
			sourcePath: 'testCompletions'
		},
		identityDocuments: {
			sourceWrapper: IdentityDocumentsWrapper,
			sourcePath: 'identityDocuments'
		}
	},
	LoadingScreen
)(() => {
	const { user, targets, testCompletions, identityDocuments } = PickerHOC.use();

	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const [targetId, setTargetId] = useState(undefined);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState();

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useInitialScrollToTop();

	const ev = !targets ? 0 : !targetId ? 1 : 2;

	useEffect(() => {
		const target = targets?.[0];
		const targetId = target?.id;

		if (user && target) {
			if (targetId) {
				setTargetId(targetId);
				const { redirectRoute, params } = getRedirectRoute(user, target, testCompletions, identityDocuments);
				console.log('redirectRoute', redirectRoute);
				console.log('params', params);
				CONFIG.routing.loadTarget.next(navigateTo, redirectRoute, params);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, targets]);

	return (
		<Wrapper title={'Accediendo'}>
			<View innerWrapperSt>
				<SoftSwitcher evaluate={ev}>
					<View alignItemsStartSt>
						<Text headingPrimarySt>Obteniendo Datos</Text>
						<Text headingSecondarySt leftSt>
							Espere mientras obtenemos los datos de su simulación.
						</Text>
					</View>
					<View alignItemsStartSt>
						<Text headingPrimarySt>Sin simulación</Text>
						<Text headingSecondarySt leftSt>
							No se han encontrado datos de simulación existentes. Por favor, póngase en contacto con su agente.
						</Text>
					</View>
					<View alignItemsStartSt>
						<Text headingPrimarySt>Redireccionando</Text>
					</View>
				</SoftSwitcher>
			</View>
		</Wrapper>
	);
});
