import React from 'react';

import FooterSVG from '../../../assets/images/svgStatic/footer.svg';

const Footer = ({ children }) => {
	return (
		<footer style={{ width: '100%' }}>
			<img
				src={FooterSVG}
				alt="Footer showing a city, with cars, trees, buildings and animals."
				style={{ width: '100%' }}
			/>
		</footer>
	);
};

export default Footer;
