import { CONFIG } from 'config/config';

import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';

// ROUNDED PROGRESS BAR
export const RoundedProgressBarWrapper = styled.div`
	align-self: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: ${isMobile ? '30px' : '45px'};
`;
export const RoundedProgressBarLabel = styled.p`
	font-size: ${CONFIG.fontSizes.md};
	fontfamily: ${CONFIG.fonts.semiBold};
	color: ${CONFIG.colors.black};
	margin-bottom: 12px;
`;
export const RoundedProgressBar = styled.div`
	width: 100%;
	height: 26px;
	border-radius: 45px;
	background: ${CONFIG.colors.greyLight};
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	position: 'relative';
`;
export const RoundedProgressBarItemActive = styled.div`
	background: ${CONFIG.colors.primary};
	border-top-left-radius: ${(props) => {
		if (props.index === 0) {
			return '45px';
		} else {
			return 0;
		}
	}};

	border-top-right-radius: ${({ index, length }) => {
		if (index === 0) {
			return '0px';
		} else if (index + 1 === length) {
			return '45px';
		} else {
			return 0;
		}
	}};
	border-bottom-right-radius: ${({ index, length }) => {
		if (index === 0) {
			return '0px';
		} else if (index + 1 === length) {
			return '45px';
		} else {
			return 0;
		}
	}};
	border-bottom-left-radius: ${({ index, length }) => {
		if (index === 0) {
			return '45px';
		} else if (index + 1 === length) {
			return '0px';
		} else {
			return '0px';
		}
	}};
	width: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	position: 'absolute';
`;
export const RoundedProgressBarItem = styled.div`
	width: 16px;
	/* border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
  background: ${CONFIG.colors.primary}; */
	/* background: transparent; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;
export const RoundedProgressBarItemDot = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;

	background: ${({ activeStep }) => {
		if (activeStep) {
			return 'white';
		} else {
			return CONFIG.colors.grey;
		}
	}};
`;
