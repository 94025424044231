/*
  Formateo de números al estilo: "12,345.00 €"
*/

export function getCurrency(num, decimales, noSymbol) {
	//console.log('Currency', num, typeof num);
	num = typeof num == 'number' ? num : parseFloat(num);
	decimales = decimales || 0;
	noSymbol = noSymbol || false;
	let result = 0;

	//console.log('Currency', num, decimales, exp, numFinal);
	if (noSymbol) {
		result = Intl.NumberFormat('de-DE', {
			useGrouping: true,
			maximumFractionDigits: decimales,
			minimumFractionDigits: decimales,
		}).format(num);
	} else {
		result = Intl.NumberFormat('de-DE', {
			style: 'currency',
			useGrouping: true,
			maximumFractionDigits: decimales,
			minimumFractionDigits: decimales,
			currency: 'EUR',
		}).format(num);
	}

	return result === 'NaN' ? false : result;
}

export function setCurrency(value) {
	value = value || '0';
	value = value + '';
	value = value.replace('.', '').replace(',', '.');

	return parseFloat(value);
}
