import { CONFIG } from 'config/config';
import routes from 'config/config/routes';

export default function RedirectRoute(user, target, testCompletions, identityDocuments) {
	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData');
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData');
	const signData = target?.targetProperties?.find((x) => x.name === 'signData');
	const mainUserDni = identityDocuments.find((d) => d.main);
	const differentTestCompletions = [...new Set(testCompletions.map((tC) => tC.testId))];

	// console.log('⭕ user', user);
	// console.log('⭕ target', target);
	// console.log('⭕ testCompletions', testCompletions);
	// console.log('⭕ differentTestCompletions', differentTestCompletions);
	// console.log('⭕ identityDocuments', identityDocuments);
	// console.log('⭕ creationData', creationData);
	// console.log('⭕ signData', signData);
	// console.log('⭕ mainUserDni', mainUserDni);

	const params = {
		routeParams: {
			targetId: target?.id,
		},
	};

	if (CONFIG.project.template === 'caser') {
		// PASO 1 - simulationData
		// No se puede dar en el caso B2B
		if (!user?.metadata) {
			return {
				step: 1,
				redirectRoute: routes.simulationData,
				params: {
					...params,
					queryParams: { internalStep: 1 },
				},
			};
		}

		// PASO 2 - simulation
		if (!user?.metadata?.createdByAgent && !contributionData?.value?.amountToCover) {
			return {
				step: 2,
				redirectRoute: routes.simulation,
				params: {
					...params,
					queryParams: { internalStep: 1 },
				},
			};
		}

		if (
			user?.metadata?.createdByAgent &&
			(user?.name === 'NEW_USER' ||
				!user?.name ||
				!user?.surname ||
				!user?.email ||
				!user?.metadata?.address?.address ||
				!user?.metadata?.address?.postalCode ||
				!user?.metadata?.address?.province ||
				!user?.metadata?.address?.municipality ||
				!user?.metadata?.profession ||
				!user?.metadata?.professionCode)
		) {
			return {
				step: 2,
				redirectRoute: routes.simulation,
				params: {
					...params,
					queryParams: { internalStep: 1 },
				},
			};
		}

		// PASO 3 - clientData
		if (
			user?.name === 'NEW_USER' ||
			!user?.name ||
			!user?.surname ||
			!user?.email ||
			!user?.metadata?.address?.address ||
			!user?.metadata?.address?.postalCode ||
			!user?.metadata?.address?.province ||
			!user?.metadata?.address?.municipality ||
			!user?.metadata?.profession ||
			!user?.metadata?.professionCode
		) {
			return {
				step: 3,
				redirectRoute: routes.clientData,
				params: {
					...params,
					queryParams: { internalStep: 1 },
				},
			};
		}

		// PASO 4 - pbc
		if (differentTestCompletions?.length < 1) {
			return {
				step: 4,
				redirectRoute: routes.pbc,
				params,
			};
		}

		// PASO 5 - health
		if (differentTestCompletions?.length < 2) {
			return {
				step: 5,
				redirectRoute: user?.metadata?.pbcOK ? routes.health : routes.pbc,
				params,
			};
		}

		// PASO 6 - bankAccount
		if (!creationData?.value?.chargeAccount) {
			return {
				step: 6,
				redirectRoute: user?.metadata?.healthOK ? routes.bankAccount : routes.health,
				params,
			};
		}

		// PASO 7 - identityBiometricUpsert
		if (!mainUserDni || !mainUserDni?.number || mainUserDni?.verificationStatus !== 'VALID') {
			return {
				step: 7,
				redirectRoute: routes.identityBiometricUpsert,
				params,
			};
		}

		// PASO 8 - activateProduct
		if (
			target?.status === 'TEMPORARY' ||
			target?.status === 'PENDING' ||
			target?.status === 'PENDING_PROCESSING' ||
			target?.status === 'PENDING_FAILED' ||
			target?.status === 'ACTIVATION_FAILED'
		) {
			return {
				step: 8,
				redirectRoute: routes.activateProduct,
				params,
			};
		}

		// PASO 9 - signatureSteps
		if (
			(target?.status === 'ACTIVATING_PROCESSING' || target?.status === 'ACTIVATING') &&
			!user?.metadata?.docsRead &&
			signData?.value?.envelopeId
		) {
			params.routeParams.envelopeId = signData?.value?.envelopeId;
			return {
				step: 9,
				redirectRoute: routes.signatureSteps,
				params: {
					routeParams: { ...params.routeParams },
					queryParams: { internalStep: 1 },
				},
			};
		}

		// PASO 10 - signatureContract
		if (target?.status === 'ACTIVATING' && user?.metadata?.docsRead) {
			params.routeParams.envelopeId = signData.value.envelopeId;
			return {
				step: 10,
				redirectRoute: routes.signatureContract,
				params: {
					routeParams: { ...params.routeParams, envelopeId: signData?.value?.envelopeId },
					queryParams: { internalStep: 2 },
				},
			};
		}

		// PASO 11 - productCreationFinishedB2B
		if (target?.status === 'ACTIVE') {
			return {
				step: 11,
				redirectRoute: routes.productCreationFinished,
				params,
			};
		}
	}

	if (CONFIG.project.template === 'allianz') {
		// PASO 1.1 - simulationData
		if (!user?.metadata?.document) {
			return {
				step: 1,
				redirectRoute: routes.simulationData,
				params: {
					...params,
					queryParams: { internalStep: 1 },
				},
			};
		}
		// PASO 1.2 - simulationData
		if (!user?.metadata?.birthDate || !user?.metadata?.profession) {
			return {
				step: 1,
				redirectRoute: routes.simulationData,
				params: {
					...params,
					queryParams: { internalStep: 2 },
				},
			};
		}

		// PASO 1.3 - simulation
		if (!contributionData?.value?.amountToCover) {
			return {
				step: 1,
				redirectRoute: routes.simulation,
				params: {
					...params,
					queryParams: { internalStep: 1 },
				},
			};
		}
		// PASO 1.4 - simulation
		if (!creationData?.value?.selectedPack) {
			return {
				step: 1,
				redirectRoute: routes.simulation,
				params: {
					...params,
					queryParams: { internalStep: 2 },
				},
			};
		}

		// PASO 2.1 - clientData
		if (!user?.name || !user?.surname || !user?.email || !user?.phoneNumber) {
			return {
				step: 2,
				redirectRoute: routes.clientData,
				params: {
					...params,
					queryParams: { internalStep: 1 },
				},
			};
		}
		// PASO 2.2 - clientData
		if (!user?.metadata?.professionalStatus) {
			return {
				step: 2,
				redirectRoute: routes.clientData,
				params: {
					...params,
					queryParams: { internalStep: 2 },
				},
			};
		}
		// PASO 2.3 - clientData
		if (!user?.metadata?.healthQuestions) {
			return {
				step: 2,
				redirectRoute: routes.clientData,
				params: {
					...params,
					queryParams: { internalStep: 3 },
				},
			};
		}
		// PASO 2.4 - clientData
		if (!creationData?.value?.details?.beneficiaryType) {
			return {
				step: 2,
				redirectRoute: routes.clientData,
				params: {
					...params,
					queryParams: { internalStep: 4 },
				},
			};
		}

		// PASO 3 - identityBiometricUpsert
		if (!mainUserDni || !mainUserDni?.number || mainUserDni?.verificationStatus !== 'VALID') {
			return {
				step: 3,
				redirectRoute: routes.identityBiometricUpsert,
				params,
			};
		}

		// PASO 4 - bankAccount
		if (!creationData?.value?.chargeAccount) {
			return {
				step: 4,
				redirectRoute: routes.bankAccount,
				params,
			};
		}

		// PASO 5 - activateProduct
		if (
			target?.status === 'TEMPORARY' ||
			target?.status === 'PENDING_PROCESSING' ||
			target?.status === 'ACTIVATING_PROCESSING' ||
			target?.status === 'ACTIVATION_FAILED' ||
			target?.status === 'PENDING_FAILED'
		) {
			return {
				step: 5,
				redirectRoute: routes.activateProduct,
				params,
			};
		}

		// PASO 6.1 - signatureSteps
		if (target?.status === 'ACTIVATING' && !user?.metadata?.docsRead) {
			return {
				step: 6,
				redirectRoute: routes.signatureSteps,
				params: {
					routeParams: { ...params.routeParams, envelopeId: signData?.value?.envelopeId },
					queryParams: { internalStep: 1 },
				},
			};
		}

		// PASO 6.2 - signatureContract
		if (
			(target?.status === 'ACTIVATING' || target?.status === 'CANCELLED') &&
			user?.metadata?.docsRead
		) {
			return {
				step: 6,
				redirectRoute: routes.signatureContract,
				params: {
					routeParams: { ...params.routeParams, envelopeId: signData?.value?.envelopeId },
					queryParams: { internalStep: 2 },
				},
			};
		}

		// PASO 7 - productCreationFinished
		if (target?.status === 'ACTIVE') {
			return {
				step: 7,
				redirectRoute: routes.productCreationFinished,
				params,
			};
		}
	}

	return {
		step: 0,
		redirectRoute: routes.loginClient,
		params,
	};
};
