import { isMobile } from 'react-device-detect';

const fontSizes = {
	xs: isMobile ? '10px' : '12px',
	sm: isMobile ? '12px' : '14px',
	md: isMobile ? '14px' : '16px',
	lg: isMobile ? '16px' : '22px',
	xl: isMobile ? '26px' : '30px',
};
export default fontSizes
