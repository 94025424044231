import React from 'react';

import { isMobile } from 'react-device-detect';

import { Text, View } from '@coinscrap/webapp-core';

import { MainModal } from 'components/MainModal/MainModal';
import { SmallButton } from 'components/SmallButton/SmallButton';

import routes from '../config/config/routes';

export const caserOnError = (
	e,
	contactReason,
	openModal,
	navigateTo,
	setTrigger,
	capitalMinimum,
	capitalMaximum,
	isAgentView = false
) => {
	let messageError = e?.safeMessage?.toString() ?? e?.message?.toString();
	let reasonError = e?.reasons?.length
		? e.reasons.length === 1
			? e.reasons[0]
			: e.reasons.reduce((acc, curr) => (acc ? `${acc}, ${curr}` : curr), '')
		: '';
	let extraMessage = '';
	let showReview = false;
	let showReviewBankAccount = false;
	let showReviewSimulation = false;
	let showRetry = false;
	let showContact = false;

	console.log('messageError', messageError);
	console.log('reasonError', reasonError);

	if (reasonError.indexOf('APORTACIONES SUPERAN LIM') !== -1) {
		contactReason = 'APORTACIONES_SUPERAN_LIM';
		messageError = 'Has superado el límite de aportaciones anual';
		extraMessage = 'Contacta con un agente para terminar la contratación';
		showContact = true;
	} else if (messageError.indexOf('policyExists') !== -1) {
		messageError = 'Ya tiene un plan de pensiones con Caser';
		extraMessage = 'Contacta con un agente para terminar la contratación';
		showContact = true;
	} else if (messageError.indexOf('cumulosLimitError') !== -1) {
		messageError = 'Los datos introducidos no permiten continuar el proceso';
		extraMessage = 'Contacta con un agente para terminar la contratación';
		showReview = true;
		showContact = true;
	} else if (messageError.indexOf('medicalTestsRejection') !== -1) {
		messageError = 'Los datos introducidos no permiten continuar el proceso';
		extraMessage = 'Contacta con un agente para terminar la contratación';
		showReview = true;
		showContact = true;
	} else if (
		messageError.indexOf('smokeRejection') !== -1 ||
		messageError.indexOf('alcoholRejection') !== -1 ||
		messageError.indexOf('imcRejection') !== -1
	) {
		messageError = 'Los datos introducidos no permiten continuar el proceso';
	} else if (messageError.indexOf('maxCapitalReached') !== -1) {
		messageError = 'El capital seleccionado no es válido';
		extraMessage = `Recuerda los límites de capital total ${
			capitalMinimum && capitalMaximum ? `(${capitalMinimum}€ - ${capitalMaximum}€) ` : ''
		}cuando configures la prima mensual/trimestral/semestral/anual para obtener una simulación correcta`;
		showReviewSimulation = true;
		showContact = true;
	} else if (messageError.indexOf('minPeriodicContributionError') !== -1) {
		messageError =
			'La simulación ha fallado porque el pago fraccionado es inferior al mínimo permitido. Selecciona otro fraccionamiento.';
		showReviewSimulation = true;
		showContact = true;
	} else if (
		messageError.indexOf('professionRejection') !== -1 ||
		messageError.indexOf('professionCoverageRejection') !== -1
	) {
		messageError = 'La profesión seleccionada no es asegurable';
		extraMessage = 'Contacta con un agente para terminar la contratación';
		showReview = true;
		showContact = true;
	} else if (messageError.indexOf('cannotSaveAccountInfoError') !== -1) {
		messageError = 'Revisa la cuenta bancaria';
		extraMessage = 'Comprueba que has introducido un IBAN válido';
		showReviewBankAccount = true;
		showContact = true;
	} else if (messageError.indexOf('ACTIVATION_FAILED') !== -1) {
		messageError = 'Ha ocurrido un error temporal';
		extraMessage = 'Contacta con un agente para terminar la contratación';
		showContact = true;
	} else {
		showReview = true;
		showRetry = true;
		showContact = true;
		messageError = 'Ha ocurrido un error desconocido';
		extraMessage = 'Revisa tus datos y vuelve a intentarlo';
	}

	console.log('❌ messageError extraMessage', messageError, extraMessage);

	openModal(
		(close) => (
			<MainModal type="error">
				<Text errorSt>{messageError || 'Error desconocido'}</Text>
				{extraMessage && (
					<Text errorSecondarySt style={{ marginTop: '1.5rem' }}>
						{extraMessage || 'Error desconocido'}
					</Text>
				)}
				<View
					style={
						isMobile
							? { display: 'flex', marginTop: '1.5rem', flexDirection: 'column' }
							: { display: 'flex', marginTop: '1.5rem', flexDirection: 'row' }
					}
				>
					{showReview && (
						<SmallButton
							onClick={() => {
								console.info('🚀 NAVIGATING TO loginClient');
								navigateTo(routes.loginClient);
								close();
							}}
						>
							Revisar Datos
						</SmallButton>
					)}
					{showReviewSimulation && (
						<SmallButton
							onClick={() => {
								close();
							}}
						>
							Revisar Simulación
						</SmallButton>
					)}
					{showReviewBankAccount && (
						<SmallButton
							onClick={() => {
								console.info('🚀 NAVIGATING TO bankAccount');
								navigateTo(routes.bankAccount);
								close();
							}}
						>
							Revisar Cuenta bancaria
						</SmallButton>
					)}
					{showRetry && (
						<SmallButton
							onClick={() => {
								setTrigger((t) => t + 1);
								close();
							}}
						>
							Reintentar
						</SmallButton>
					)}
					{showContact && (
						<SmallButton
							onClick={() => {
								console.info('🚀 NAVIGATING TO cannotConfirm');
								navigateTo(routes.cannotConfirm, {
									queryParams: {
										fromReason: contactReason,
										fromView: 'activateProduct'
									}
								});
								close();
							}}
						>
							Contactar
						</SmallButton>
					)}
				</View>
			</MainModal>
		),
		{
			disableClickAway: true,
			closeButton: false,
			modalClass: isMobile ? 'modal-mobile' : 'modal-main',
			key: 'modal-CaserError'
		}
	);
};
