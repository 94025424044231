import React from 'react';

import {
	AnalyticsWrapper,
	FinancialWrapper,
	IdentityDocumentsWrapper,
	InstitutionAccountsWrapper,
	InstitutionsWrapper,
	OperationsWrapper,
	ProductCreatorWrapper,
	TargetTypesWrapper,
	TargetsUtilsWrapper,
	TargetsWrapper,
	TestResponsesWrapper,
	TestsWrapper,
	UserWrapper,
	VinculationsWrapper
} from '@coinscrap/webapp-core';

export const EntityWrappers = ({ children, ...props }) => {
	const transportsAnalytics = [
		{
			engine: AnalyticsWrapper.TRANSPORTS.COINSCRAP,
			initProps: {
				debugEnabled: false
			}
		},
		{
			engine: AnalyticsWrapper.TRANSPORTS.WALMERIC_DELIO,
			initProps: {
				debugEnabled: true,
				useMainScript: true // Adds main script to generate leadId before making the GET request to walmeric. Debe estar configurado el micro de analytics de la misma manera con la variable → WALMERIC_DELIO_MAIN_SCRIPT
			}
		},
		{
			engine: AnalyticsWrapper.TRANSPORTS.TEALLIUM,
			initProps: {
				debugEnabled: false
			}
		}
	];

	return (
		<UserWrapper enableRoles>
			<AnalyticsWrapper transports={transportsAnalytics}>
				<TestsWrapper>
					<TestResponsesWrapper>
						<IdentityDocumentsWrapper>
							<VinculationsWrapper>
								<OperationsWrapper>
									<InstitutionsWrapper>
										<InstitutionAccountsWrapper>
											<FinancialWrapper>
												<ProductCreatorWrapper>
													<TargetTypesWrapper>
														<TargetsWrapper enableAvailableProducts>
															<TargetsUtilsWrapper>
																<TargetsUtilsWrapper>{children}</TargetsUtilsWrapper>
															</TargetsUtilsWrapper>
														</TargetsWrapper>
													</TargetTypesWrapper>
												</ProductCreatorWrapper>
											</FinancialWrapper>
										</InstitutionAccountsWrapper>
									</InstitutionsWrapper>
								</OperationsWrapper>
							</VinculationsWrapper>
						</IdentityDocumentsWrapper>
					</TestResponsesWrapper>
				</TestsWrapper>
			</AnalyticsWrapper>
		</UserWrapper>
	);
};
