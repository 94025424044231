import React from 'react';

import * as S from './styles';

const Input = ({value, onKeyDown, onChange, placeholder, disabled, type, style, onBlur, ref}) => {
	return (
		<>
			<S.InputWrapper
				disabled={disabled}
				value={value}
				onKeyDown={onKeyDown}
				onChange={onChange}
				placeholder={placeholder}
				type={type}
				onBlur={onBlur}
				style={{...style}}
				ref={ref}
			/>
			<S.InputLabel value={value}>{placeholder}</S.InputLabel>
		</>
	);
};

export default Input;
