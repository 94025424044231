import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';

import { CONFIG } from '../../../config/config';

export const Wrapper = styled.div`
	margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  margin-top: ${(props) =>
		props?.isAgentView
			? 0
			: isMobile
				? "90px"
				: (CONFIG.layout.topInsuranceCompanyImgsHeader?.height ?? 0) +
				(CONFIG.layout.header?.height ?? 0) +
				"px"};
  width: ${(props) => (isMobile ? "330px" : props.wrapperWidth || "100%")};
`;
