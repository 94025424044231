import { CONFIG } from 'config/config';

import styled from 'styled-components/macro';

// TEXT PROGRESS BAR
export const TextProgressBarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
	align-items: center;
	width: 100%;
	margin-bottom: 32px;
`;
export const TextProgressBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
`;
export const TextProgressBarItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	padding-bottom: 8px;
	background: ${CONFIG.colors.white};
	border-bottom: ${({ activeStep }) => {
		if (activeStep) {
			return `5px solid ${CONFIG.colors.primary}`;
		} else {
			return `5px solid ${CONFIG.colors.greyLight}`;
		}
	}};
`;
export const TextProgressBarLabel = styled.p`
	font-size: ${CONFIG.fontSizes.md};
	fontfamily: ${CONFIG.fonts.semiBold};
	color: ${({ activeStep }) => {
		if (activeStep) {
			return CONFIG.colors.black;
		} else {
			return CONFIG.colors.greyLight;
		}
	}};
`;
