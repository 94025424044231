import {
	NavigationWrapper,
	PickerHOC,
	Slider,
	TargetsUtilsWrapper,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import CircularProgress from '@material-ui/core/CircularProgress';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { MainModal } from 'components/MainModal/MainModal';
import { SmallButton } from 'components/SmallButton/SmallButton';
import { CONFIG } from 'config/config';
import { caserOnError } from 'helpers/caserOnError';
import { getCurrency } from 'helpers/currency';
import { useGetError } from 'hooks/useGetError';
import { useInitialScrollToTop } from 'hooks/useInitialScrollToTop';
import { Wrapper } from 'layout/components/Body/Wrapper';
import Header from 'layout/components/Header/Header';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Footer from '../../layout/components/Footer/Footer';
import * as S from './styles';

export const ActivateProduct = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	// CORE WRAPPERS
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const [trigger, setTrigger] = useState(0);
	const [trigger2, setTrigger2] = useState(0);
	const pickerValues = PickerHOC.use();
	const { activate, postTargetCustom } = TargetsUtilsWrapper.use();
	const { target, user } = pickerValues;
	const { getError } = useGetError();

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;

	// ESTADO
	const [loading, setLoading] = useState(false);
	const appStep = useMemo(() => 10, []);
	const sectionTitle = useMemo(() => CONFIG.project.steps.stepTitles[appStep - 1], [appStep]);
	const sectionSubTitle = useMemo(() => CONFIG.project.steps.stepSubtitles[appStep - 1], [appStep]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const prevTargetStatus = useMemo(() => target.status, []);
	const targetStatus = useMemo(() => target.status, [target]);
	const envelopeId = useMemo(() => target?.signData?.envelopeId, [target]);
	const [createdNow, setCreatedNow] = useState();
	const [finishedNow, setFinishedNow] = useState();

	useEffect(() => {
		console.log('🧿 CURRENT STATUS FOR CREATE', targetStatus);
		if (targetStatus === 'TEMPORARY' || targetStatus === 'PENDING_FAILED') {
			(async () => {
				try {
					setCreatedNow(true);
					await activate(target.id);
				} catch (e) {
					console.log('e', e);
					caserOnError(e, 'ACTIVATE_PRODUCT', openModal, navigateTo, setTrigger, undefined, undefined);
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetStatus, trigger]);

	useEffect(() => {
		console.log('🧿 CURRENT STATUS FOR SIGN', targetStatus, envelopeId, createdNow);
		if (prevTargetStatus !== 'ACTIVATION_FAILED' && targetStatus === 'ACTIVATION_FAILED') {
			console.log('prevTargetStatus', prevTargetStatus);
			console.log('currentTargetStatus', targetStatus);
			caserOnError(
				{ message: 'ACTIVATION_FAILED' },
				'ACTIVATE_PRODUCT',
				openModal,
				navigateTo,
				setTrigger,
				undefined,
				undefined
			);
		} else if (
			(!envelopeId || createdNow || (envelopeId && targetStatus === 'ACTIVATION_FAILED')) &&
			(targetStatus === 'PENDING' || targetStatus === 'ACTIVATION_FAILED')
		) {
			(async () => {
				try {
					await postTargetCustom(target.id, 'start-activation', {});
					setFinishedNow(true);
				} catch (e) {
					openModal(
						(close) => (
							<MainModal type="error">
								<Text headingPrimarySt errorSt>
									{'Ha ocurrido un error al preparar los documentos de firma'}
								</Text>
								<SmallButton
									onClick={() => {
										setTrigger2((t) => t + 1);
										close();
									}}
								>
									Reintentar
								</SmallButton>
							</MainModal>
						),
						{
							disableClickAway: true,
							closeButton: false,
							modalClass: isMobile ? 'modal-mobile' : 'modal-main',
							key: 'modal-ActivateProduct-StartActivation-KO'
						}
					);
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetStatus, envelopeId, createdNow, trigger2]);

	// NAVIGATION
	const next = async () => {
		try {
			CONFIG.routing.activateProduct.signatureSteps(navigateTo, envelopeId);
		} catch (e) {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={getError(e)} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-mobile' : 'modal-main',
				key: 'modal-ActivateProduct-Next-KO'
			});
		}
	};
	const isDisabled = () => {
		return (
			(targetStatus !== 'ACTIVE' && targetStatus !== 'ACTIVATING') ||
			(createdNow ? !finishedNow || !envelopeId : !envelopeId)
		);
	};

	useHeader(<Header isAgentView={false} showBackButton={false} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		console.log('📌 contributionData', contributionData);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Wrapper
				showProgressBar
				progressBarVariant={'onlyIcon'}
				progressPercent={75}
				step={appStep}
				title={sectionTitle}
				subtitle={sectionSubTitle}
				style={{ justifyContent: 'center' }}
				grow
			>
				<View innerWrapperSt>
					{CONFIG.views.activateProduct.showBars && (
						<>
							<View rowSt marginBottomSmSt>
								<div
									style={{
										width: 45,
										height: 12,
										background: CONFIG.colors.greyLight,
										flex: 'none',
										order: 0,
										flexGrow: 0,
										marginRight: 5
									}}
								/>
								<div
									style={{
										width: 45,
										height: 12,
										background: CONFIG.colors.greyLight,
										flex: 'none',
										order: 0,
										flexGrow: 0,
										marginRight: 5
									}}
								/>
								<div
									style={{
										width: 45,
										height: 12,
										background: CONFIG.colors.primary,
										flex: 'none',
										order: 0,
										flexGrow: 0,
										marginRight: 5
									}}
								/>
								<div
									style={{
										width: 45,
										height: 12,
										background: CONFIG.colors.greyLight,
										flex: 'none',
										order: 0,
										flexGrow: 0
									}}
								/>
							</View>
							<Text headingSecondarySt fullWidthSt marginBottomSmSt>
								Resumen
							</Text>
						</>
					)}
					<Slider>
						<S.SliderItem>
							<View style={{ maxWidth: 650 }}>
								<Text headingSecondarySt style={{ marginTop: 12, color: CONFIG.colors.primary }} fullWidthSt leftSt>
									Plan de pensiones:
								</Text>

								{/* TIPO APORTACIÓN */}
								<Text headingCuartarySt style={{ marginTop: 8 }} fullWidthSt leftSt>
									· Tipo de aportación:{' '}
									{creationData.selectedPlan === 'makeContribution' ? 'Aportación inicial' : 'Traer plan'}
								</Text>

								{/* makeContribution */}
								{creationData.selectedPlan === 'makeContribution' && (
									<Text headingCuartarySt style={{ marginTop: 8 }} fullWidthSt leftSt>
										· Aportación: {getCurrency(contributionData.initialContribution, 2)}
									</Text>
								)}

								{/* bringPlan */}
								{creationData.selectedPlan === 'bringPlan' && (
									<Text headingCuartarySt style={{ marginTop: 8 }} fullWidthSt leftSt>
										· Traer plan: {contributionData.source.managerName} - {contributionData.source.name}
									</Text>
								)}

								{/* BENEFICIARIOS */}
								{creationData?.beneficiaryType === 'generico' ? (
									<Text headingCuartarySt style={{ marginTop: 8 }} fullWidthSt leftSt>
										· Beneficiario: {user?.name} {user?.surname} - 100%
									</Text>
								) : creationData?.beneficiaryType === 'expreso' ? (
									<>
										<Text headingCuartarySt style={{ marginTop: 8 }} fullWidthSt leftSt>
											· Beneficiarios:{' '}
										</Text>
										{creationData?.beneficiaries.map((beneficiary, index, array) => {
											console.log('beneficiary', beneficiary);
											return (
												<Text headingCuartarySt fullWidthSt leftSt marginLeftLgSt>
													· {beneficiary?.name} {beneficiary?.surname} - ({beneficiary?.percentage?.toString() ?? 0}
													%)
												</Text>
											);
										})}
									</>
								) : (
									<Text headingCuartarySt style={{ marginTop: 8 }} fullWidthSt leftSt>
										· Beneficiario: {creationData?.beneficiaries[0].name}
									</Text>
								)}
							</View>
						</S.SliderItem>
					</Slider>
					{CONFIG.views.activateProduct.showLoader && isDisabled() && (
						<View style={{ display: 'flex', width: '100%', marginTop: '2rem', marginBottom: '2rem' }}>
							<CircularProgress
								style={{
									width: 35,
									height: 35,
									marginRight: 20,
									color: CONFIG.colors.primary
								}}
							/>
						</View>
					)}
				</View>
				<BackNextButton isBackShown={false} onClickNext={next} isNextDisabled={isDisabled()} />
			</Wrapper>
			<Footer />
		</>
	);
});
