import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('layout', {
	styles: {
		default: {
			bodyComponent: {
				backgroundColor: 'white',
				justifyContent: 'flex-start',
				paddingTop: '15px',
				paddingBottom: '50px',
			},
			bodyContentComponent: {
				overflowX: 'hidden',
				justifyContent: 'flex-start',
				maxWidth: 850,
				// flexShrink: 1,
			},

			footerComponent: {
				height: 'auto',
			},
			headerComponent: {
				height: 'auto',
			},

			modalComponent: {
				backgroundComponent: {
					backgroundColor: 'rgba(128,128,128,0.7)',
				},
				contentComponent: {
					background: 'none',
					boxShadow: 'none',
				},
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
