import React from 'react';

import { AnalyticsWrapper, Button, NavigationWrapper, Text, View } from '@coinscrap/webapp-core';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

import logoCaser from 'assets/images/png/caser_helvetia.png';
import routes from 'config/config/routes';

import { CONFIG } from 'config/config';

import * as S from './styles';

/**
 * Renders the header section of the page.
 *
 * @param {function} goBack - function to go back
 * @param {boolean} mobile - flag to check if the device is mobile
 * @param {boolean} isB2B - flag to check if the user is a B2B user
 * @param {boolean} buttonEnabled - flag to check if the button is enabled
 *
 * @returns {JSX.Element} - the header section of the page
 */
const Header = ({ goBack, mobile, isB2B, buttonEnabled = true }) => {
	const { navigateTo, getCurrentRoute } = NavigationWrapper.use();
	const { emitEvent } = AnalyticsWrapper.use();

	let currentRoute = getCurrentRoute()?.url;
	currentRoute = currentRoute.split('/')[currentRoute.split('/').length - 1];

	if (!mobile) {
		return (
			<View headerSt>
				<div className={'logos'} style={{ alignItems: 'center', display: 'flex' }}>
					<S.Logo alt="Caser logo" src={logoCaser} />
					<span
						style={{ marginLeft: 30, marginRight: 30, borderLeft: `1px solid ${CONFIG.colors.primary}`, height: 40 }}
					/>
					<Text headerSt>Asegura tu futuro con Caser. Contrata tu Plan de Pensiones hoy.</Text>
				</div>
				<div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
					<Button
						disabled={!buttonEnabled}
						headerSt
						onClick={() => {
							// TEALIUM EVENT
							emitEvent({
								tealium_event: 'cmb_open',
								form_name: 'cmb'
							});

							navigateTo(routes.cannotConfirm, {
								queryParams: {
									fromReason: 'CALL',
									fromView: currentRoute
								}
							});
						}}
					>
						¿Hablamos?
					</Button>
				</div>
			</View>
		);
	}

	return (
		<View headerSt>
			<div className={'logos'} style={{ alignItems: 'center', display: 'flex' }}>
				<S.Logo alt="Caser logo" src={logoCaser} />
			</div>
			<div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
				<LocalPhoneIcon
					onClick={() => {
						// TEALIUM EVENT
						emitEvent({
							tealium_event: 'cmb_open',
							form_name: 'cmb'
						});

						navigateTo(routes.cannotConfirm, {
							queryParams: {
								fromReason: 'CALL',
								fromView: currentRoute
							}
						});
					}}
					style={{
						color: CONFIG.colors.white,
						backgroundColor: CONFIG.colors.secondary,
						padding: 5,
						height: 35,
						width: 35,
						borderRadius: 5
					}}
				/>
			</div>
		</View>
	);
};

export default Header;
