import { CONFIG } from 'config/config';

import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('inputSwitch', {
	styles: {
		default: {
			containerComponent: {},
			switchContainerComponent: {
				padding: 0,
				height: 26,
				width: 45,
				paddingBottom: 1,
			},
			switchComponent: {
				padding: 2,
			},
			trackComponent: {
				backgroundColor: CONFIG.colors.greyLight,
				opacity: 1,
				height: '100%',
				borderRadius: 30,
				padding: 0,
			},
			thumbComponent: {
				height: 21,
				width: 21,
				padding: 0,
			},
			checkedMode: {
				trackComponent: {
					backgroundColor: CONFIG.colors.grey,
				},
			},
		},

		...CONFIG.commonStyles.styles,
	},
});
