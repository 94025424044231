import { UiWrapper } from '@coinscrap/webapp-core';
import { CONFIG } from 'config/config';
import { isMobile } from 'react-device-detect';

UiWrapper.defineComponent("view", {
	styles: {
		default: {
			width: "100%",
			color: CONFIG.colors.black,
			boxSizing: "border-box",
			fontFamily: CONFIG.fonts.regular,
		},

		// HEADER TOP
		headerTopSt: {
			position: "fixed",
			top: 0,
			height: CONFIG.layout.topInsuranceCompanyImgsHeader?.height ?? 0,
			backgroundColor: CONFIG.colors.primary,
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			borderBottom: "1px solid rgba(241, 212, 190, 0.4)",
			boxShadow: CONFIG.layout.topInsuranceCompanyImgsHeader.showShadow
				? " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
				: undefined,
			width: "100%",
			zIndex: 5,
		},
		headerTopInnerSt: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			maxWidth: "65%",
		},
		// HEADER NORMAL
		headerSt: {
			display: "flex",
			flexDirection: "row", justifyContent: 'space-between',
			alignItems: "center",
			position: "fixed",
			width: "100%",
			height: CONFIG.layout.header?.height ?? 0,
			top: CONFIG.layout.topInsuranceCompanyImgsHeader?.height ?? 0,
			zIndex: 1,
			backgroundColor: "white",
			borderBottom: "2px solid rgba(241, 212, 190, 0.4)",
			boxShadow: CONFIG.layout.header.showShadow
				? " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
				: undefined,
			paddingLeft: isMobile ? 25 : '15%',
			paddingRight: isMobile ? 25 : '15%',
		},
		headerInnerSt: {
			display: "flex",
			flexDirection: "row",
			justifyContent: isMobile ? "space-evenly" : "space-between",
			alignItems: "center",
			maxWidth: isMobile ? "90%" : CONFIG.components.mainWrapper.width,
			height: "100%",
			zIndex: 2,
		},
		headerLogosSt: {
			backgroundColor: "transparent",
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "flex-start",
			zIndex: 3,
			gap: "15px",
			width: "initial",
		},

		headerButtonsSt: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "flex-end",
			flexGrow: 1,
			width: "initial",
		},

		rowDoubleColumnSt: {
			width: '100%',
			display: 'grid',
			gap: '42px',
			gridTemplateColumns: 'repeat(2, 1fr)',
			alignItems: 'initial',
		},

		cardSt: {
			borderRadius: 5,
			boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 7px 0px',
			justifyContent: 'flex-start'
		},
		cardInfoSt: {
			justifyContent: 'space-between',
			flexGrow: 1,
			padding: '20px 30px 30px 30px'
		},

		// INPUT ROWS
		//Double grid columns for web. One-and-one grid colum for mobile devices.
		inputRowSt: {
			display: "grid",
			alignItems: "flex-start",
			justifyContent: 'center',
			marginBottom: 20,
			gap: isMobile ? 50 : 20,
			gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
			rowGap: isMobile ? 15 : 25,
			width: isMobile ? "100%" : "50%",
		},
		inputRowOneColumnSt: {
			display: "grid",
			alignItems: "flex-start",
			marginBottom: 20,
			gap: isMobile ? 50 : 20,
			gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
			rowGap: isMobile ? 5 : 12,
			width: "100%",
		},
		//Input free columns.
		inputFreeRowSt: {
			display: "flex",
			flexDirection: isMobile ? "column" : "row",
			justifyContent: "flex-start",
			marginBottom: 0,
			gap: 20,
			width: "100%",
		},

		// WRAPPERS
		totalCenterSt: {
			height: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		innerWrapperSt: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "flex-start",
			flexGrow: 1,
			width: isMobile ? "100%" : "75%",
			margin: "0 auto",
		},
		loginAgentFormWrapperSt: {
			maxWidth: "350px",
		},

		// LOADING MODAL
		loadingModalSt: {
			display: "flex",
			height: "100%",
			alignItems: "center",
			justifyContent: "center",
			backgroundColor: CONFIG.colors.loadingModalBackground,
		},

		//Smoke an Alcohol Buttons Wrapper
		smokeAndAlcoholSt: {
			alignSelf: "normal",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "flex-start",
		},

		//SimulationCapital Wrapper
		capitalInputSt: {
			border: `1px solid ${CONFIG.colors.primary}`,
			maxWidth: isMobile ? 250 : 250,
			flexDirection: 'row'
		},

		//SimulationPeriod Wrapper
		periodButtonsSt: {
			display: "grid",
			gap: "7px",
			width: "initial",
			gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
		},

		//PCB and Health questionnaries
		questionnariesSt: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			width: "100%",
			rowGap: CONFIG.spacing.lg,
		},
		beneficiaryBoxBankSt: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			marginTop: 10,
			width: "100%",
		},
		//PBC and Health wrapper
		wrapperQuestionnariesSt: {
			alignItems: "center",
			display: "flex",
			flexDirection: isMobile ? "row" : "row",
			flexGrow: 1,
			justifyContent: "space-between",
			gap: isMobile ? 10 : null,
			width: isMobile ? "60%" : "100%",
			maxWidth: 800,
		},
		//PBC and Health text wrapper
		wrapperTextQuestionnariesSt: {
			flexDirection: "row",
			justifyContent: "flex-start",
		},
		//PBC and Health buttons wrapper
		wrapperButtonsQuestionnariesSt: {
			alignItems: "center",
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			marginLeft: isMobile ? 0 : 30,
			width: isMobile ? "40%" : "10%",
		},

		//Bank Account's banner
		bannerBankAccountSt: {
			backgroundColor: CONFIG.colors.primaryLighter,
			fontFamily: CONFIG.fonts.semiBold,
			fontSize: 20,
			marginBottom: 10,
			marginTop: 20,
			padding: isMobile ? "20px 10px" : "30px 200px",
			width: "100%",
		},
		itemStepSt: {
			alignItems: "center",
			display: "flex",
			flexDirection: "row",
			justifyContent: "flex-start",
			marginBottom: 15,
			marginTop: 10,
			width: "initial",
		},

		//Agent Dashboard
		dahboardFullCenterSt: {
			width: "100vw",
			position: "relative",
			left: "calc(-50vw + 50%)",
			justifyContent: "flex-start",
		},
		agentTableRowSt: {
			display: "grid",
			backgroundColor: CONFIG.colors.primaryLighter,
			gap: 20,
			gridTemplateColumns:
				"repeat(2, minmax(0, 1fr)) 175px 175px 150px 100px minmax(0, 1fr)",
			marginBottom: 25,
			padding: "20px 50px",
			width: "100%",
		},
		isGlobalAgentTableRowSt: {
			display: "grid",
			backgroundColor: CONFIG.colors.primaryLighter,
			gap: 20,
			gridTemplateColumns:
				"repeat(2, minmax(0, 1fr)) 175px 175px 150px minmax(0, .5fr) 100px minmax(0, 1fr)",
			marginBottom: 25,
			padding: "20px 50px",
			width: "100%",
		},

		//Wrapper for FollowUp view
		progressWrapperSt: {
			alignItems: "stretch",
			display: "flex",
			flexDirection: "row",
			marginBottom: isMobile ? 10 : 0,
			marginTop: isMobile ? 0 : 0,
			width: "initial",
		},
		progressTextWrapperSt: {
			alignItems: "baseline",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-around",
			marginLeft: 30,
			width: "initial",
		},

		//Progress bar for FollowUp view
		followUpSt: {
			backgroundColor: CONFIG.colors.white,
			borderRadius: 45,
			display: "flex",
			flexDirection: "column",
			height: 470,
			position: "relative",
			width: 32,
		},
		//Dashboard follow up non-active dots
		dotsSt: {
			backgroundColor: CONFIG.colors.primary,
			borderRadius: "50%",
			height: 16,
			width: 16,
		},
		//Dashboard follow up active dots
		activeDotsSt: {
			backgroundColor: CONFIG.colors.white,
			borderRadius: "50%",
			height: 16,
			width: 16,
		},

		//Buttons wrapper modal agent notification
		notificationWrapperSt: {
			display: "flex",
			flexDirection: isMobile ? "column" : "row",
			justifyContent: "space-around",
			gap: 16,
			width: "initial",
			marginTop: 30,
		},

		//Resume agent
		agentResumeWrapperSt: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-around",
			gap: 30,
			marginTop: 30,
			marginBottom: 30,
			width: "initial",
			maxWidth: 1400,
		},
		agentResumeDoubleColumnWrapperSt: {
			width: "100%",
			display: "grid",
			gridTemplateColumns: "repeat(2, 1fr)",
			rowGap: 20,
			columnGap: 60,
			alignItems: "initial",
		},

		// PAQUETES TABLE
		tableCoverageColumnSt: {
			width: 325,
			marginRight: 2,
		},

		tablePackColumnSt: {
			width: 200,
			marginRight: 2,
		},

		tableFirstCellSt: {
			backgroundColor: CONFIG.colors.white,
			height: 355,
			marginBottom: 2,
		},

		tableFirstRowSt: {
			backgroundColor: CONFIG.colors.primaryLighter,
			height: 355,
			marginBottom: 2,
		},

		coverageCellSt: {
			backgroundColor: CONFIG.colors.primaryLighter,
			height: "130px",
			marginBottom: 2,
			padding: 20,
		},

		packHeaderCellSt: {
			backgroundColor: CONFIG.colors.primaryDarker,
			height: 50,
		},

		packCellSt: {
			backgroundColor: CONFIG.colors.primaryLighter,
			height: "130px",
			marginBottom: 2,
		},

		...CONFIG.commonStyles.styles,
	},
});
